/**
 * GQTY AUTO-GENERATED CODE: PLEASE DO NOT MODIFY MANUALLY
 */

import { SchemaUnionsKey } from "gqty";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BlockAttributesArray: any;
  BlockAttributesObject: any;
}

/** Countries supported by Gravity Forms Address Field. */
export enum AddressFieldCountryEnum {
  /** Andorra . */
  AD = "AD",
  /** United Arab Emirates . */
  AE = "AE",
  /** Afghanistan . */
  AF = "AF",
  /** Antigua and Barbuda . */
  AG = "AG",
  /** Anguilla . */
  AI = "AI",
  /** Albania . */
  AL = "AL",
  /** Armenia . */
  AM = "AM",
  /** Angola . */
  AO = "AO",
  /** Antarctica . */
  AQ = "AQ",
  /** Argentina . */
  AR = "AR",
  /** American Samoa . */
  AS = "AS",
  /** Austria . */
  AT = "AT",
  /** Australia . */
  AU = "AU",
  /** Aruba . */
  AW = "AW",
  /** Åland Islands . */
  AX = "AX",
  /** Azerbaijan . */
  AZ = "AZ",
  /** Bosnia and Herzegovina . */
  BA = "BA",
  /** Barbados . */
  BB = "BB",
  /** Bangladesh . */
  BD = "BD",
  /** Belgium . */
  BE = "BE",
  /** Burkina Faso . */
  BF = "BF",
  /** Bulgaria . */
  BG = "BG",
  /** Bahrain . */
  BH = "BH",
  /** Burundi . */
  BI = "BI",
  /** Benin . */
  BJ = "BJ",
  /** Saint Barthélemy . */
  BL = "BL",
  /** Bermuda . */
  BM = "BM",
  /** Brunei Darussalam . */
  BN = "BN",
  /** Bolivia . */
  BO = "BO",
  /** Bonaire, Sint Eustatius and Saba . */
  BQ = "BQ",
  /** Brazil . */
  BR = "BR",
  /** Bahamas . */
  BS = "BS",
  /** Bhutan . */
  BT = "BT",
  /** Bouvet Island . */
  BV = "BV",
  /** Botswana . */
  BW = "BW",
  /** Belarus . */
  BY = "BY",
  /** Belize . */
  BZ = "BZ",
  /** Canada . */
  CA = "CA",
  /** Cocos Islands . */
  CC = "CC",
  /** Congo, Democratic Republic of the . */
  CD = "CD",
  /** Central African Republic . */
  CF = "CF",
  /** Congo . */
  CG = "CG",
  /** Switzerland . */
  CH = "CH",
  /** Côte d'Ivoire . */
  CI = "CI",
  /** Cook Islands . */
  CK = "CK",
  /** Chile . */
  CL = "CL",
  /** Cameroon . */
  CM = "CM",
  /** China . */
  CN = "CN",
  /** Colombia . */
  CO = "CO",
  /** Costa Rica . */
  CR = "CR",
  /** Cuba . */
  CU = "CU",
  /** Cabo Verde . */
  CV = "CV",
  /** Curaçao . */
  CW = "CW",
  /** Christmas Island . */
  CX = "CX",
  /** Cyprus . */
  CY = "CY",
  /** Czechia . */
  CZ = "CZ",
  /** Germany . */
  DE = "DE",
  /** Djibouti . */
  DJ = "DJ",
  /** Denmark . */
  DK = "DK",
  /** Dominica . */
  DM = "DM",
  /** Dominican Republic . */
  DO = "DO",
  /** Algeria . */
  DZ = "DZ",
  /** Ecuador . */
  EC = "EC",
  /** Estonia . */
  EE = "EE",
  /** Egypt . */
  EG = "EG",
  /** Western Sahara . */
  EH = "EH",
  /** Eritrea . */
  ER = "ER",
  /** Spain . */
  ES = "ES",
  /** Ethiopia . */
  ET = "ET",
  /** Finland . */
  FI = "FI",
  /** Fiji . */
  FJ = "FJ",
  /** Falkland Islands . */
  FK = "FK",
  /** Micronesia . */
  FM = "FM",
  /** Faroe Islands . */
  FO = "FO",
  /** France . */
  FR = "FR",
  /** Gabon . */
  GA = "GA",
  /** United Kingdom . */
  GB = "GB",
  /** Grenada . */
  GD = "GD",
  /** Georgia . */
  GE = "GE",
  /** French Guiana . */
  GF = "GF",
  /** Guernsey . */
  GG = "GG",
  /** Ghana . */
  GH = "GH",
  /** Gibraltar . */
  GI = "GI",
  /** Greenland . */
  GL = "GL",
  /** Gambia . */
  GM = "GM",
  /** Guinea . */
  GN = "GN",
  /** Guadeloupe . */
  GP = "GP",
  /** Equatorial Guinea . */
  GQ = "GQ",
  /** Greece . */
  GR = "GR",
  /** South Georgia and the South Sandwich Islands . */
  GS = "GS",
  /** Guatemala . */
  GT = "GT",
  /** Guam . */
  GU = "GU",
  /** Guinea-Bissau . */
  GW = "GW",
  /** Guyana . */
  GY = "GY",
  /** Hong Kong . */
  HK = "HK",
  /** Heard Island and McDonald Islands . */
  HM = "HM",
  /** Honduras . */
  HN = "HN",
  /** Croatia . */
  HR = "HR",
  /** Haiti . */
  HT = "HT",
  /** Hungary . */
  HU = "HU",
  /** Indonesia . */
  ID = "ID",
  /** Ireland . */
  IE = "IE",
  /** Israel . */
  IL = "IL",
  /** Isle of Man . */
  IM = "IM",
  /** India . */
  IN = "IN",
  /** British Indian Ocean Territory . */
  IO = "IO",
  /** Iraq . */
  IQ = "IQ",
  /** Iran . */
  IR = "IR",
  /** Iceland . */
  IS = "IS",
  /** Italy . */
  IT = "IT",
  /** Jersey . */
  JE = "JE",
  /** Jamaica . */
  JM = "JM",
  /** Jordan . */
  JO = "JO",
  /** Japan . */
  JP = "JP",
  /** Kenya . */
  KE = "KE",
  /** Kyrgyzstan . */
  KG = "KG",
  /** Cambodia . */
  KH = "KH",
  /** Kiribati . */
  KI = "KI",
  /** Comoros . */
  KM = "KM",
  /** Saint Kitts and Nevis . */
  KN = "KN",
  /** Korea, Democratic People's Republic of . */
  KP = "KP",
  /** Korea, Republic of . */
  KR = "KR",
  /** Kuwait . */
  KW = "KW",
  /** Cayman Islands . */
  KY = "KY",
  /** Kazakhstan . */
  KZ = "KZ",
  /** Lao People's Democratic Republic . */
  LA = "LA",
  /** Lebanon . */
  LB = "LB",
  /** Saint Lucia . */
  LC = "LC",
  /** Liechtenstein . */
  LI = "LI",
  /** Sri Lanka . */
  LK = "LK",
  /** Liberia . */
  LR = "LR",
  /** Lesotho . */
  LS = "LS",
  /** Lithuania . */
  LT = "LT",
  /** Luxembourg . */
  LU = "LU",
  /** Latvia . */
  LV = "LV",
  /** Libya . */
  LY = "LY",
  /** Morocco . */
  MA = "MA",
  /** Monaco . */
  MC = "MC",
  /** Moldova . */
  MD = "MD",
  /** Montenegro . */
  ME = "ME",
  /** Saint Martin . */
  MF = "MF",
  /** Madagascar . */
  MG = "MG",
  /** Marshall Islands . */
  MH = "MH",
  /** North Macedonia . */
  MK = "MK",
  /** Mali . */
  ML = "ML",
  /** Myanmar . */
  MM = "MM",
  /** Mongolia . */
  MN = "MN",
  /** Macao . */
  MO = "MO",
  /** Northern Mariana Islands . */
  MP = "MP",
  /** Martinique . */
  MQ = "MQ",
  /** Mauritania . */
  MR = "MR",
  /** Montserrat . */
  MS = "MS",
  /** Malta . */
  MT = "MT",
  /** Mauritius . */
  MU = "MU",
  /** Maldives . */
  MV = "MV",
  /** Malawi . */
  MW = "MW",
  /** Mexico . */
  MX = "MX",
  /** Malaysia . */
  MY = "MY",
  /** Mozambique . */
  MZ = "MZ",
  /** Namibia . */
  NA = "NA",
  /** New Caledonia . */
  NC = "NC",
  /** Niger . */
  NE = "NE",
  /** Norfolk Island . */
  NF = "NF",
  /** Nigeria . */
  NG = "NG",
  /** Nicaragua . */
  NI = "NI",
  /** Netherlands . */
  NL = "NL",
  /** Norway . */
  NO = "NO",
  /** Nepal . */
  NP = "NP",
  /** Nauru . */
  NR = "NR",
  /** Niue . */
  NU = "NU",
  /** New Zealand . */
  NZ = "NZ",
  /** Oman . */
  OM = "OM",
  /** Panama . */
  PA = "PA",
  /** Peru . */
  PE = "PE",
  /** French Polynesia . */
  PF = "PF",
  /** Papua New Guinea . */
  PG = "PG",
  /** Philippines . */
  PH = "PH",
  /** Pakistan . */
  PK = "PK",
  /** Poland . */
  PL = "PL",
  /** Saint Pierre and Miquelon . */
  PM = "PM",
  /** Pitcairn . */
  PN = "PN",
  /** Puerto Rico . */
  PR = "PR",
  /** Palestine, State of . */
  PS = "PS",
  /** Portugal . */
  PT = "PT",
  /** Palau . */
  PW = "PW",
  /** Paraguay . */
  PY = "PY",
  /** Qatar . */
  QA = "QA",
  /** Réunion . */
  RE = "RE",
  /** Romania . */
  RO = "RO",
  /** Serbia . */
  RS = "RS",
  /** Russian Federation . */
  RU = "RU",
  /** Rwanda . */
  RW = "RW",
  /** Saudi Arabia . */
  SA = "SA",
  /** Solomon Islands . */
  SB = "SB",
  /** Seychelles . */
  SC = "SC",
  /** Sudan . */
  SD = "SD",
  /** Sweden . */
  SE = "SE",
  /** Singapore . */
  SG = "SG",
  /** Saint Helena, Ascension and Tristan da Cunha . */
  SH = "SH",
  /** Slovenia . */
  SI = "SI",
  /** Svalbard and Jan Mayen . */
  SJ = "SJ",
  /** Slovakia . */
  SK = "SK",
  /** Sierra Leone . */
  SL = "SL",
  /** San Marino . */
  SM = "SM",
  /** Senegal . */
  SN = "SN",
  /** Somalia . */
  SO = "SO",
  /** Suriname . */
  SR = "SR",
  /** South Sudan . */
  SS = "SS",
  /** Sao Tome and Principe . */
  ST = "ST",
  /** El Salvador . */
  SV = "SV",
  /** Sint Maarten . */
  SX = "SX",
  /** Syria Arab Republic . */
  SY = "SY",
  /** Eswatini . */
  SZ = "SZ",
  /** Turks and Caicos Islands . */
  TC = "TC",
  /** Chad . */
  TD = "TD",
  /** French Southern Territories . */
  TF = "TF",
  /** Togo . */
  TG = "TG",
  /** Thailand . */
  TH = "TH",
  /** Tajikistan . */
  TJ = "TJ",
  /** Tokelau . */
  TK = "TK",
  /** Timor-Leste . */
  TL = "TL",
  /** Turkmenistan . */
  TM = "TM",
  /** Tunisia . */
  TN = "TN",
  /** Tonga . */
  TO = "TO",
  /** Türkiye . */
  TR = "TR",
  /** Trinidad and Tobago . */
  TT = "TT",
  /** Tuvalu . */
  TV = "TV",
  /** Taiwan . */
  TW = "TW",
  /** Tanzania, the United Republic of . */
  TZ = "TZ",
  /** Ukraine . */
  UA = "UA",
  /** Uganda . */
  UG = "UG",
  /** US Minor Outlying Islands . */
  UM = "UM",
  /** United States . */
  US = "US",
  /** Uruguay . */
  UY = "UY",
  /** Uzbekistan . */
  UZ = "UZ",
  /** Holy See . */
  VA = "VA",
  /** Saint Vincent and the Grenadines . */
  VC = "VC",
  /** Venezuela . */
  VE = "VE",
  /** Virgin Islands, British . */
  VG = "VG",
  /** Virgin Islands, U.S. . */
  VI = "VI",
  /** Viet Nam . */
  VN = "VN",
  /** Vanuatu . */
  VU = "VU",
  /** Wallis and Futuna . */
  WF = "WF",
  /** Samoa . */
  WS = "WS",
  /** Yemen . */
  YE = "YE",
  /** Mayotte . */
  YT = "YT",
  /** South Africa . */
  ZA = "ZA",
  /** Zambia . */
  ZM = "ZM",
  /** Zimbabwe . */
  ZW = "ZW",
}

/** Input fields for Address FormField. */
export interface AddressFieldInput {
  /** Address city. */
  city?: InputMaybe<Scalars["String"]>;
  /** Address country. */
  country?: InputMaybe<AddressFieldCountryEnum>;
  /** Address line two. */
  lineTwo?: InputMaybe<Scalars["String"]>;
  /** Address state/region/province name. */
  state?: InputMaybe<Scalars["String"]>;
  /** Street address. */
  street?: InputMaybe<Scalars["String"]>;
  /** Address zip code. */
  zip?: InputMaybe<Scalars["String"]>;
}

/** Determines the type of address to be displayed. */
export enum AddressFieldTypeEnum {
  /** Canada address type. */
  CANADA = "CANADA",
  /** International address type. */
  INTERNATIONAL = "INTERNATIONAL",
  /** United States address type. */
  US = "US",
}

/** The AM or PM cycle in a 12-hour clock. */
export enum AmPmEnum {
  /** AM. The first 12-hour cycle of the day. */
  AM = "AM",
  /** PM. The second 12-hour cycle of the day. */
  PM = "PM",
}

/** What rating to display avatars up to. Accepts 'G', 'PG', 'R', 'X', and are judged in that order. Default is the value of the 'avatar_rating' option */
export enum AvatarRatingEnum {
  /** Indicates a G level avatar rating level. */
  G = "G",
  /** Indicates a PG level avatar rating level. */
  PG = "PG",
  /** Indicates an R level avatar rating level. */
  R = "R",
  /** Indicates an X level avatar rating level. */
  X = "X",
}

/** Arguments for filtering the BlockEditorContentNodeConnection connection */
export interface BlockEditorContentNodeConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum BlockEditorPreviewIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  ID = "ID",
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  SLUG = "SLUG",
  /** Identify a resource by the URI. */
  URI = "URI",
}

/** The position to place the (invisible) reCaptcha badge. */
export enum CaptchaFieldBadgePositionEnum {
  /** Bottom-left position. */
  BOTTOM_LEFT = "BOTTOM_LEFT",
  /** Bottom-right position. */
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
  /** Inline position. */
  INLINE = "INLINE",
}

/** The theme to be used for the reCAPTCHA field. */
export enum CaptchaFieldThemeEnum {
  /** Dark reCAPTCHA theme. */
  DARK = "DARK",
  /** Light reCAPTCHA theme. */
  LIGHT = "LIGHT",
}

/** Type of CAPTCHA field to be used. */
export enum CaptchaFieldTypeEnum {
  /** Math CAPTCHA type. */
  MATH = "MATH",
  /** reCAPTCHA type. */
  RECAPTCHA = "RECAPTCHA",
  /** Simple CAPTCHA type. */
  SIMPLE = "SIMPLE",
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CategoryIdType {
  /** The Database ID for the node */
  DATABASE_ID = "DATABASE_ID",
  /** The hashed Global ID */
  ID = "ID",
  /** The name of the node */
  NAME = "NAME",
  /** Url friendly name of the node */
  SLUG = "SLUG",
  /** The URI for the node */
  URI = "URI",
}

/** Arguments for filtering the CategoryToCategoryConnection connection */
export interface CategoryToCategoryConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
}

/** Arguments for filtering the CategoryToContentNodeConnection connection */
export interface CategoryToContentNodeConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfCategoryEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the CategoryToPostConnection connection */
export interface CategoryToPostConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Input fields for a single checkbox. */
export interface CheckboxFieldInput {
  /** Input ID. */
  inputId?: InputMaybe<Scalars["Float"]>;
  /** Input value. */
  value?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the CommentToCommentConnection connection */
export interface CommentToCommentConnectionWhereArgs {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars["String"]>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars["String"]>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars["ID"]>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars["String"]>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars["Int"]>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars["Int"]>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars["String"]>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars["String"]>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars["ID"]>;
}

/** Arguments for filtering the CommentToParentCommentConnection connection */
export interface CommentToParentCommentConnectionWhereArgs {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars["String"]>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars["String"]>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars["ID"]>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars["String"]>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars["Int"]>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars["Int"]>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars["String"]>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars["String"]>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars["ID"]>;
}

/** Options for ordering the connection */
export enum CommentsConnectionOrderbyEnum {
  /** Order by browser user agent of the commenter. */
  COMMENT_AGENT = "COMMENT_AGENT",
  /** Order by true/false approval of the comment. */
  COMMENT_APPROVED = "COMMENT_APPROVED",
  /** Order by name of the comment author. */
  COMMENT_AUTHOR = "COMMENT_AUTHOR",
  /** Order by e-mail of the comment author. */
  COMMENT_AUTHOR_EMAIL = "COMMENT_AUTHOR_EMAIL",
  /** Order by IP address of the comment author. */
  COMMENT_AUTHOR_IP = "COMMENT_AUTHOR_IP",
  /** Order by URL address of the comment author. */
  COMMENT_AUTHOR_URL = "COMMENT_AUTHOR_URL",
  /** Order by the comment contents. */
  COMMENT_CONTENT = "COMMENT_CONTENT",
  /** Order by date/time timestamp of the comment. */
  COMMENT_DATE = "COMMENT_DATE",
  /** Order by GMT timezone date/time timestamp of the comment. */
  COMMENT_DATE_GMT = "COMMENT_DATE_GMT",
  /** Order by the globally unique identifier for the comment object */
  COMMENT_ID = "COMMENT_ID",
  /** Order by the array list of comment IDs listed in the where clause. */
  COMMENT_IN = "COMMENT_IN",
  /** Order by the comment karma score. */
  COMMENT_KARMA = "COMMENT_KARMA",
  /** Order by the comment parent ID. */
  COMMENT_PARENT = "COMMENT_PARENT",
  /** Order by the post object ID. */
  COMMENT_POST_ID = "COMMENT_POST_ID",
  /** Order by the the type of comment, such as 'comment', 'pingback', or 'trackback'. */
  COMMENT_TYPE = "COMMENT_TYPE",
  /** Order by the user ID. */
  USER_ID = "USER_ID",
}

/** The type of action the conditional logic will perform. */
export enum ConditionalLogicActionTypeEnum {
  /** Text button (default). */
  HIDE = "HIDE",
  /** Image button. */
  SHOW = "SHOW",
}

/** Determines how to the rules should be evaluated. */
export enum ConditionalLogicLogicTypeEnum {
  /** Evaulate all logic rules. */
  ALL = "ALL",
  /** Evaluate any logic rule. */
  ANY = "ANY",
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ContentNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  ID = "ID",
  /** Identify a resource by the URI. */
  URI = "URI",
}

/** Allowed Content Types */
export enum ContentTypeEnum {
  /** The Type of Content object */
  ATTACHMENT = "ATTACHMENT",
  /** The Type of Content object */
  JOBS = "JOBS",
  /** The Type of Content object */
  PAGE = "PAGE",
  /** The Type of Content object */
  POST = "POST",
  /** The Type of Content object */
  SERVICES = "SERVICES",
  /** The Type of Content object */
  SPECIALTIES = "SPECIALTIES",
  /** The Type of Content object */
  TEAM = "TEAM",
  /** The Type of Content object */
  WGG_PREVIEW = "WGG_PREVIEW",
  /** The Type of Content object */
  WORK = "WORK",
  /** The Type of Content object */
  WP_BLOCK = "WP_BLOCK",
}

/** The Type of Identifier used to fetch a single Content Type node. To be used along with the "id" field. Default is "ID". */
export enum ContentTypeIdTypeEnum {
  /** The globally unique ID */
  ID = "ID",
  /** The name of the content type. */
  NAME = "NAME",
}

/** Arguments for filtering the ContentTypeToContentNodeConnection connection */
export interface ContentTypeToContentNodeConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Allowed Content Types of the Category taxonomy. */
export enum ContentTypesOfCategoryEnum {
  /** The Type of Content object */
  POST = "POST",
}

/** Allowed Content Types of the PostFormat taxonomy. */
export enum ContentTypesOfPostFormatEnum {
  /** The Type of Content object */
  POST = "POST",
}

/** Allowed Content Types of the Tag taxonomy. */
export enum ContentTypesOfTagEnum {
  /** The Type of Content object */
  POST = "POST",
}

/** Input for the createCategory mutation */
export interface CreateCategoryInput {
  /** The slug that the category will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the category object */
  description?: InputMaybe<Scalars["String"]>;
  /** The name of the category object to mutate */
  name: Scalars["String"];
  /** The ID of the category that should be set as the parent */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
}

/** Input for the createComment mutation */
export interface CreateCommentInput {
  /** The approval status of the comment. */
  approved?: InputMaybe<Scalars["String"]>;
  /** The name of the comment's author. */
  author?: InputMaybe<Scalars["String"]>;
  /** The email of the comment's author. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** The url of the comment's author. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The database ID of the post object the comment belongs to. */
  commentOn?: InputMaybe<Scalars["Int"]>;
  /** Content of the comment. */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** Parent comment ID of current comment. */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Type of comment. */
  type?: InputMaybe<Scalars["String"]>;
}

/** Input for the createJob mutation */
export interface CreateJobInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Input for the createMediaItem mutation */
export interface CreateMediaItemInput {
  /** Alternative text to display when mediaItem is not displayed */
  altText?: InputMaybe<Scalars["String"]>;
  /** The userId to assign as the author of the mediaItem */
  authorId?: InputMaybe<Scalars["ID"]>;
  /** The caption for the mediaItem */
  caption?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The comment status for the mediaItem */
  commentStatus?: InputMaybe<Scalars["String"]>;
  /** The date of the mediaItem */
  date?: InputMaybe<Scalars["String"]>;
  /** The date (in GMT zone) of the mediaItem */
  dateGmt?: InputMaybe<Scalars["String"]>;
  /** Description of the mediaItem */
  description?: InputMaybe<Scalars["String"]>;
  /** The file name of the mediaItem */
  filePath?: InputMaybe<Scalars["String"]>;
  /** The file type of the mediaItem */
  fileType?: InputMaybe<MimeTypeEnum>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The ping status for the mediaItem */
  pingStatus?: InputMaybe<Scalars["String"]>;
  /** The slug of the mediaItem */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the mediaItem */
  status?: InputMaybe<MediaItemStatusEnum>;
  /** The title of the mediaItem */
  title?: InputMaybe<Scalars["String"]>;
}

/** Input for the createPage mutation */
export interface CreatePageInput {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars["ID"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Input for the createPostFormat mutation */
export interface CreatePostFormatInput {
  /** The slug that the post_format will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the post_format object */
  description?: InputMaybe<Scalars["String"]>;
  /** The name of the post_format object to mutate */
  name: Scalars["String"];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
}

/** Input for the createPost mutation */
export interface CreatePostInput {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars["ID"]>;
  /** Set connections between the post and categories */
  categories?: InputMaybe<PostCategoriesInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars["String"]>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The ping status for the object */
  pingStatus?: InputMaybe<Scalars["String"]>;
  /** URLs that have been pinged. */
  pinged?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Set connections between the post and postFormats */
  postFormats?: InputMaybe<PostPostFormatsInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the post and tags */
  tags?: InputMaybe<PostTagsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
  /** URLs queued to be pinged. */
  toPing?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
}

/** Input for the createReusableBlock mutation */
export interface CreateReusableBlockInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Input for the createService mutation */
export interface CreateServiceInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Input for the createSpecialty mutation */
export interface CreateSpecialtyInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Input for the createTag mutation */
export interface CreateTagInput {
  /** The slug that the post_tag will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the post_tag object */
  description?: InputMaybe<Scalars["String"]>;
  /** The name of the post_tag object to mutate */
  name: Scalars["String"];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
}

/** Input for the createTeam_member mutation */
export interface CreateTeam_memberInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Input for the createUser mutation */
export interface CreateUserInput {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars["String"]>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars["String"]>;
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars["String"]>;
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars["String"]>;
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars["String"]>;
  /** The user's last name. */
  lastName?: InputMaybe<Scalars["String"]>;
  /** User's locale. */
  locale?: InputMaybe<Scalars["String"]>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars["String"]>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars["String"]>;
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars["String"]>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars["String"]>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars["String"]>;
  /** An array of roles to be assigned to the user. */
  roles?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** A string that contains the user's username for logging in. */
  username: Scalars["String"];
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars["String"]>;
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars["String"]>;
}

/** Input for the createWork mutation */
export interface CreateWorkInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** How the DateField date is displayed. */
export enum DateFieldFormatEnum {
  /** dd/mm/yyyy format. */
  DMY = "DMY",
  /** dd-mm-yyyy format. */
  DMY_DASH = "DMY_DASH",
  /** dd.mm.yyyy format. */
  DMY_DOT = "DMY_DOT",
  /** mm/dd/yyyy format. */
  MDY = "MDY",
  /** yyyy/mm/dd format. */
  YMD_DASH = "YMD_DASH",
  /** yyyy.mm.dd format. */
  YMD_DOT = "YMD_DOT",
  /** yyyy/mm/dd format. */
  YMD_SLASH = "YMD_SLASH",
}

/** Type of date field to display. */
export enum DateFieldTypeEnum {
  /** A date dropdown. */
  DROPDOWN = "DROPDOWN",
  /** A simple date field. */
  FIELD = "FIELD",
  /** A datepicker. */
  PICKER = "PICKER",
}

/** Date values */
export interface DateInput {
  /** Day of the month (from 1 to 31) */
  day?: InputMaybe<Scalars["Int"]>;
  /** Month number (from 1 to 12) */
  month?: InputMaybe<Scalars["Int"]>;
  /** 4 digit year (e.g. 2017) */
  year?: InputMaybe<Scalars["Int"]>;
}

/** Filter the connection based on input */
export interface DateQueryInput {
  /** Nodes should be returned after this date */
  after?: InputMaybe<DateInput>;
  /** Nodes should be returned before this date */
  before?: InputMaybe<DateInput>;
  /** Column to query against */
  column?: InputMaybe<PostObjectsConnectionDateColumnEnum>;
  /** For after/before, whether exact value should be matched or not */
  compare?: InputMaybe<Scalars["String"]>;
  /** Day of the month (from 1 to 31) */
  day?: InputMaybe<Scalars["Int"]>;
  /** Hour (from 0 to 23) */
  hour?: InputMaybe<Scalars["Int"]>;
  /** For after/before, whether exact value should be matched or not */
  inclusive?: InputMaybe<Scalars["Boolean"]>;
  /** Minute (from 0 to 59) */
  minute?: InputMaybe<Scalars["Int"]>;
  /** Month number (from 1 to 12) */
  month?: InputMaybe<Scalars["Int"]>;
  /** OR or AND, how the sub-arrays should be compared */
  relation?: InputMaybe<RelationEnum>;
  /** Second (0 to 59) */
  second?: InputMaybe<Scalars["Int"]>;
  /** Week of the year (from 0 to 53) */
  week?: InputMaybe<Scalars["Int"]>;
  /** 4 digit year (e.g. 2017) */
  year?: InputMaybe<Scalars["Int"]>;
}

/** Input for the deleteCategory mutation */
export interface DeleteCategoryInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The ID of the category to delete */
  id: Scalars["ID"];
}

/** Input for the deleteComment mutation */
export interface DeleteCommentInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the comment should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The deleted comment ID */
  id: Scalars["ID"];
}

/** Input for the deleteGfDraftEntry mutation */
export interface DeleteGfDraftEntryInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Either the global ID of the draft entry, or its resume token. */
  id: Scalars["ID"];
  /** The ID type for the draft entry. Defaults to `ID` . */
  idType?: InputMaybe<DraftEntryIdTypeEnum>;
}

/** Input for the deleteGfEntry mutation */
export interface DeleteGfEntryInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the entry should be force deleted instead of being moved to the trash. */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** ID of the entry to delete, either a global or database ID. */
  id: Scalars["ID"];
}

/** Input for the deleteJob mutation */
export interface DeleteJobInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the job to delete */
  id: Scalars["ID"];
}

/** Input for the deleteMediaItem mutation */
export interface DeleteMediaItemInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the mediaItem should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the mediaItem to delete */
  id: Scalars["ID"];
}

/** Input for the deletePage mutation */
export interface DeletePageInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the page to delete */
  id: Scalars["ID"];
}

/** Input for the deletePostFormat mutation */
export interface DeletePostFormatInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The ID of the postFormat to delete */
  id: Scalars["ID"];
}

/** Input for the deletePost mutation */
export interface DeletePostInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the post to delete */
  id: Scalars["ID"];
}

/** Input for the deleteReusableBlock mutation */
export interface DeleteReusableBlockInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the ReusableBlock to delete */
  id: Scalars["ID"];
}

/** Input for the deleteService mutation */
export interface DeleteServiceInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the service to delete */
  id: Scalars["ID"];
}

/** Input for the deleteSpecialty mutation */
export interface DeleteSpecialtyInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the specialty to delete */
  id: Scalars["ID"];
}

/** Input for the deleteTag mutation */
export interface DeleteTagInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The ID of the tag to delete */
  id: Scalars["ID"];
}

/** Input for the deleteTeam_member mutation */
export interface DeleteTeam_memberInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the team_member to delete */
  id: Scalars["ID"];
}

/** Input for the deleteUser mutation */
export interface DeleteUserInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The ID of the user you want to delete */
  id: Scalars["ID"];
  /** Reassign posts and links to new User ID. */
  reassignId?: InputMaybe<Scalars["ID"]>;
}

/** Input for the deleteWork mutation */
export interface DeleteWorkInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the work to delete */
  id: Scalars["ID"];
}

/** The Type of Identifier used to fetch a single resource. */
export enum DraftEntryIdTypeEnum {
  /** Unique global ID for the object. */
  ID = "ID",
  /** The resume token assigned by Gravity Forms. Used by draft entries. */
  RESUME_TOKEN = "RESUME_TOKEN",
}

/** Input fields for email field. */
export interface EmailFieldInput {
  /** Email confirmation input value. Only used when email confirmation is enabled. */
  confirmationValue?: InputMaybe<Scalars["String"]>;
  /** Email input value. */
  value?: InputMaybe<Scalars["String"]>;
}

/** Options for ordering the connection. */
export interface EntriesConnectionOrderbyInput {
  /** The field name used to sort the results. */
  field?: InputMaybe<Scalars["String"]>;
  /** Whether the sorting field's values are numeric. */
  isNumeric?: InputMaybe<Scalars["Boolean"]>;
  /** The cardinality of the order of the connection. */
  order?: InputMaybe<OrderEnum>;
}

/** Date Filters input fields for Entries queries. */
export interface EntriesDateFiltersInput {
  /** End date in Y-m-d H:i:s format. */
  endDate?: InputMaybe<Scalars["String"]>;
  /** Start date in Y-m-d H:i:s format. */
  startDate?: InputMaybe<Scalars["String"]>;
}

/** Field Filters input fields for Entries queries. */
export interface EntriesFieldFiltersInput {
  /** The field value(s) to filter by. Must be boolean values. If using this field, do not also use stringValues, intValues or floatValues. */
  boolValues?: InputMaybe<Array<InputMaybe<Scalars["Boolean"]>>>;
  /** The field value(s) to filter by. Must be float values. If using this field, do not also use stringValues, intValues or boolValues. */
  floatValues?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  /** The field value(s) to filter by. Must be integer values. If using this field, do not also use stringValues, floatValues or boolValues. */
  intValues?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  /** Optional. The entry meta key to filter by. You can use the ID of the form field, or the names of any of the columns in Gravity Form's database table for entries, such as "date_created", "is_read, "created_by", etc. If omitted, the value will be checked against all meta keys. . */
  key?: InputMaybe<Scalars["String"]>;
  /** The operator to use for filtering. */
  operator?: InputMaybe<FieldFiltersOperatorEnum>;
  /** The field value(s) to filter by. Must be string values. If using this field, do not also use intValues, floatValues or boolValues. */
  stringValues?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
}

/** The Type of Identifier used to fetch a single resource. */
export enum EntryIdTypeEnum {
  /** The database ID assigned by Gravity Forms. Used by submitted entries. */
  DATABASE_ID = "DATABASE_ID",
  /** Unique global ID for the object. */
  ID = "ID",
  /** The resume token assigned by Gravity Forms. Used by draft entries. */
  RESUME_TOKEN = "RESUME_TOKEN",
}

/** Status of entries to get. Default is ACTIVE. */
export enum EntryStatusEnum {
  /** Active entries (default). */
  ACTIVE = "ACTIVE",
  /** All entries. */
  ALL = "ALL",
  /** Spam entries. */
  SPAM = "SPAM",
  /** Entries in the trash. */
  TRASH = "TRASH",
}

/** The type of Gravity Forms entry. */
export enum EntryTypeEnum {
  /** A Gravity Forms draft entry. */
  DRAFT = "DRAFT",
  /** A Gravity Forms partial entry. */
  PARTIAL = "PARTIAL",
  /** A submitted Gravity Forms entry. */
  SUBMITTED = "SUBMITTED",
}

/** Whether to filter by ALL or ANY of the field filters. Default is ALL. */
export enum FieldFiltersModeEnum {
  /** All field filters (default). */
  ALL = "ALL",
  /** Any field filters. */
  ANY = "ANY",
}

/** The operator to use for filtering. */
export enum FieldFiltersOperatorEnum {
  /** Find field values that contain the passed value. Only one value may be passed when using this operator. SQL Equivalent: `LIKE %value%`. */
  CONTAINS = "CONTAINS",
  /** Default. Find field values that are equal to one of the values in the passed array. Default. */
  IN = "IN",
  /** Find field values that are an exact match for the passed value. Only one value may be passed when using this operator. SQL Equivalent: `=`. */
  IS = "IS",
  /** Find field values that are NOT an exact match for the passed value. Only one value may be passed when using this operator. SQL Equivalent: `NOT`. */
  IS_NOT = "IS_NOT",
  /** Find field values that are an exact match for the passed value. SQL wildcards are supported. Only one value may be passed when using this operator. SQL Equivalent: `LIKE`. */
  LIKE = "LIKE",
  /** Find field values that do NOT match those in the values array. */
  NOT_IN = "NOT_IN",
}

/** Type of button to be displayed. Default is TEXT. */
export enum FormButtonTypeEnum {
  /** Image button. */
  IMAGE = "IMAGE",
  /** Text button (default). */
  TEXT = "TEXT",
}

/** Type of form confirmation to be used. */
export enum FormConfirmationTypeEnum {
  /** Use a confirmation "message". */
  MESSAGE = "MESSAGE",
  /** Use a redirect to a different WordPress "page". */
  PAGE = "PAGE",
  /** Use a "redirect" to a given URL. */
  REDIRECT = "REDIRECT",
}

/** Determines where the field description is displayed relative to the field. */
export enum FormDescriptionPlacementEnum {
  /** The field description is displayed above the field input (i.e. immediately after the field label). */
  ABOVE = "ABOVE",
  /** The field description is displayed below the field input. */
  BELOW = "BELOW",
}

/** How the date field displays its calendar icon. */
export enum FormFieldCalendarIconTypeEnum {
  /** Default calendar icon. */
  CALENDAR = "CALENDAR",
  /** Custom calendar icon. */
  CUSTOM = "CUSTOM",
  /** No calendar icon. */
  NONE = "NONE",
}

/** Determines where the field description is displayed relative to the field. */
export enum FormFieldDescriptionPlacementEnum {
  /** The field description is displayed above the field input (i.e. immediately after the field label). */
  ABOVE = "ABOVE",
  /** The field description is displayed below the field input. */
  BELOW = "BELOW",
  /** The field description is inherited from the form default settings. */
  INHERIT = "INHERIT",
}

/** The field label position. Empty when using the form defaults or a value of "hidden_label". */
export enum FormFieldLabelPlacementEnum {
  /** Field label is hidden. */
  HIDDEN = "HIDDEN",
  /** Field label is inherited from the form defaults. */
  INHERIT = "INHERIT",
  /** Field label is displayed beside the fields and aligned to the left. */
  LEFT = "LEFT",
  /** Field label is displayed beside the fields and aligned to the right. */
  RIGHT = "RIGHT",
  /** Field label is displayed on top of the fields. */
  TOP = "TOP",
}

/** Type of indicator to use when field is required. */
export enum FormFieldRequiredIndicatorEnum {
  /** Asterisk (*) indicator. */
  ASTERISK = "ASTERISK",
  /** Custom indicator. */
  CUSTOM = "CUSTOM",
  /** Text (Required) indicator (default). */
  TEXT = "TEXT",
}

/** The size of the field when displayed on the page. */
export enum FormFieldSizeEnum {
  /** Large field size. */
  LARGE = "LARGE",
  /** Medium field size. */
  MEDIUM = "MEDIUM",
  /** Small field size. */
  SMALL = "SMALL",
}

/** Determines how sub-labels are aligned. */
export enum FormFieldSubLabelPlacementEnum {
  /** The sub-label is displayed above the sub-field input (i.e. immediately after the field label). */
  ABOVE = "ABOVE",
  /** The sub-label is displayed below the sub-field input. */
  BELOW = "BELOW",
  /** Field label is inherited from the form defaults. */
  INHERIT = "INHERIT",
}

/** Gravity Forms Field Type. */
export enum FormFieldTypeEnum {
  /** A Gravity Forms address field. */
  ADDRESS = "ADDRESS",
  /** A Gravity Forms calculation field. */
  CALCULATION = "CALCULATION",
  /** A Gravity Forms captcha field. */
  CAPTCHA = "CAPTCHA",
  /** A Gravity Forms checkbox field. */
  CHECKBOX = "CHECKBOX",
  /** A Gravity Forms consent field. */
  CONSENT = "CONSENT",
  /** A Gravity Forms date field. */
  DATE = "DATE",
  /** A Gravity Forms email field. */
  EMAIL = "EMAIL",
  /** A Gravity Forms fileupload field. */
  FILEUPLOAD = "FILEUPLOAD",
  /** A Gravity Forms hidden field. */
  HIDDEN = "HIDDEN",
  /** A Gravity Forms hiddenproduct field. */
  HIDDENPRODUCT = "HIDDENPRODUCT",
  /** A Gravity Forms html field. */
  HTML = "HTML",
  /** A Gravity Forms list field. */
  LIST = "LIST",
  /** A Gravity Forms multiselect field. */
  MULTISELECT = "MULTISELECT",
  /** A Gravity Forms name field. */
  NAME = "NAME",
  /** A Gravity Forms number field. */
  NUMBER = "NUMBER",
  /** A Gravity Forms page field. */
  PAGE = "PAGE",
  /** A Gravity Forms password field. */
  PASSWORD = "PASSWORD",
  /** A Gravity Forms phone field. */
  PHONE = "PHONE",
  /** A Gravity Forms post_category field. */
  POST_CATEGORY = "POST_CATEGORY",
  /** A Gravity Forms post_content field. */
  POST_CONTENT = "POST_CONTENT",
  /** A Gravity Forms post_custom_field field. */
  POST_CUSTOM_FIELD = "POST_CUSTOM_FIELD",
  /** A Gravity Forms post_excerpt field. */
  POST_EXCERPT = "POST_EXCERPT",
  /** A Gravity Forms post_image field. */
  POST_IMAGE = "POST_IMAGE",
  /** A Gravity Forms post_tags field. */
  POST_TAGS = "POST_TAGS",
  /** A Gravity Forms post_title field. */
  POST_TITLE = "POST_TITLE",
  /** A Gravity Forms radio field. */
  RADIO = "RADIO",
  /** A Gravity Forms section field. */
  SECTION = "SECTION",
  /** A Gravity Forms select field. */
  SELECT = "SELECT",
  /** A Gravity Forms singleproduct field. */
  SINGLEPRODUCT = "SINGLEPRODUCT",
  /** A Gravity Forms singleshipping field. */
  SINGLESHIPPING = "SINGLESHIPPING",
  /** A Gravity Forms submit field. */
  SUBMIT = "SUBMIT",
  /** A Gravity Forms text field. */
  TEXT = "TEXT",
  /** A Gravity Forms textarea field. */
  TEXTAREA = "TEXTAREA",
  /** A Gravity Forms time field. */
  TIME = "TIME",
  /** A Gravity Forms website field. */
  WEBSITE = "WEBSITE",
}

/** Field values input. Includes a field id, and a valid value Input. */
export interface FormFieldValuesInput {
  /** The form field values for Address fields. */
  addressValues?: InputMaybe<AddressFieldInput>;
  /** The form field values for Checkbox fields. */
  checkboxValues?: InputMaybe<Array<InputMaybe<CheckboxFieldInput>>>;
  /** The form field values for Email fields. */
  emailValues?: InputMaybe<EmailFieldInput>;
  /** The field id. */
  id: Scalars["Int"];
  /** The form field values for List fields. */
  listValues?: InputMaybe<Array<InputMaybe<ListFieldInput>>>;
  /** The form field values for Name fields. */
  nameValues?: InputMaybe<NameFieldInput>;
  /** The form field values for basic fields. */
  value?: InputMaybe<Scalars["String"]>;
  /** The form field values for fields that accept multiple string values. Used by MultiSelect, Post Category, Post Custom, and Post Tags fields. */
  values?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
}

/** Field visibility. */
export enum FormFieldVisibilityEnum {
  /** The field is for "administrative" use. */
  ADMINISTRATIVE = "ADMINISTRATIVE",
  /** The field is "hidden". */
  HIDDEN = "HIDDEN",
  /** The field is "visible". */
  VISIBLE = "VISIBLE",
}

/** The Type of Identifier used to fetch a single resource. */
export enum FormIdTypeEnum {
  /** The database ID assigned by Gravity Forms. */
  DATABASE_ID = "DATABASE_ID",
  /** Unique global ID for the object. */
  ID = "ID",
}

/** Determines where the field labels should be placed in relation to the field. */
export enum FormLabelPlacementEnum {
  /** Field labels are displayed beside the fields and aligned to the left. */
  LEFT = "LEFT",
  /** Field labels are displayed beside the fields and aligned to the right. */
  RIGHT = "RIGHT",
  /** Field labels are displayed on top of the fields. */
  TOP = "TOP",
}

/** When limitEntries is set to 1, this property specifies the time period during which submissions are allowed. */
export enum FormLimitEntriesPeriodEnum {
  /** Limit entries by "day". */
  DAY = "DAY",
  /** Limit entries by "month". */
  MONTH = "MONTH",
  /** Limit entries by "week". */
  WEEK = "WEEK",
  /** Limit entries by "year". */
  YEAR = "YEAR",
}

/** What to use for the notification "to". */
export enum FormNotificationToTypeEnum {
  /** Email address. */
  EMAIL = "EMAIL",
  /** Form field. */
  FIELD = "FIELD",
  /** Hidden. */
  HIDDEN = "HIDDEN",
  /** Routing using conditional rules. */
  ROUTING = "ROUTING",
}

/** Style of progress bar. */
export enum FormPageProgressStyleEnum {
  /** Blue progress bar style. */
  BLUE = "BLUE",
  /** Custom progress bar style. */
  CUSTOM = "CUSTOM",
  /** Green progress bar style. */
  GREEN = "GREEN",
  /** Grey progress bar style. */
  GREY = "GREY",
  /** Orange progress bar style. */
  ORANGE = "ORANGE",
  /** Red progress bar style. */
  RED = "RED",
}

/** Type of page progress indicator to be displayed. */
export enum FormPageProgressTypeEnum {
  /** Don't show a page progress indicator. */
  NONE = "NONE",
  /** Show page progress indicator as a percentage. */
  PERCENTAGE = "PERCENTAGE",
  /** Show page progress indicator as steps. */
  STEPS = "STEPS",
}

/** The Personal Data retention policy. */
export enum FormRetentionPolicyEnum {
  /** Entries will be deleted automatically after a specified number of days. */
  DELETE = "DELETE",
  /** Entries will be retain indefinitely. */
  RETAIN = "RETAIN",
  /** Entries will be trashed automatically after a specified number of days. */
  TRASH = "TRASH",
}

/** Operator to be used when evaluating logic rules. */
export enum FormRuleOperatorEnum {
  /** Evaluates values that CONTAIN the comparison value. */
  CONTAINS = "CONTAINS",
  /** Evaluates values that END with the comparison value. */
  ENDS_WITH = "ENDS_WITH",
  /** Evaluates values that are GREATER than the comparison value. */
  GREATER_THAN = "GREATER_THAN",
  /** Evaluates values that match the comparison value. */
  IS = "IS",
  /** Evaluates values that do NOT match the comparison value. */
  IS_NOT = "IS_NOT",
  /** Evaluates values that are LESS than the comparison value. */
  LESS_THAN = "LESS_THAN",
  /** Evaluates values that START with the comparison value. */
  STARTS_WITH = "STARTS_WITH",
}

/** Status of forms to get. Default is ACTIVE. */
export enum FormStatusEnum {
  /** Active forms (default). */
  ACTIVE = "ACTIVE",
  /** Inactive forms. */
  INACTIVE = "INACTIVE",
  /** Inactive forms in the trash. */
  INACTIVE_TRASHED = "INACTIVE_TRASHED",
  /** Active forms in the trash. */
  TRASHED = "TRASHED",
}

/** Determines how sub-labels are aligned. */
export enum FormSubLabelPlacementEnum {
  /** The sub-label is displayed above the sub-field input (i.e. immediately after the field label). */
  ABOVE = "ABOVE",
  /** The sub-label is displayed below the sub-field input. */
  BELOW = "BELOW",
}

/** Where the submit button should be located. */
export enum FormSubmitButtonLocationEnum {
  /** The submit button will be placed in a new row after all fields of the form. */
  BOTTOM = "BOTTOM",
  /** The submit button will be placed on the last row of the form where it will fill the remaining space left by field columns. */
  INLINE = "INLINE",
}

/** Submit button width. */
export enum FormSubmitButtonWidthEnum {
  /** The width is set to match that of the button text. */
  AUTO = "AUTO",
  /** The width is set to fill 100% of the container. */
  FULL = "FULL",
}

/** Options for ordering the connection. */
export interface FormsConnectionOrderbyInput {
  /** The field name used to sort the results. */
  field?: InputMaybe<Scalars["String"]>;
  /** The cardinality of the order of the connection. */
  order?: InputMaybe<OrderEnum>;
}

/** Input for the generateAuthorizationCode mutation */
export interface GenerateAuthorizationCodeInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Email for WordPress user */
  email?: InputMaybe<Scalars["String"]>;
  /** Password for WordPress user */
  password?: InputMaybe<Scalars["String"]>;
  /** Username for WordPress user */
  username?: InputMaybe<Scalars["String"]>;
}

/** Currencies supported by Gravity Forms. */
export enum GfCurrencyEnum {
  /** Australian Dollar . */
  AUD = "AUD",
  /** Brazilian Real . */
  BRL = "BRL",
  /** Canadian Dollar . */
  CAD = "CAD",
  /** Swiss Franc . */
  CHF = "CHF",
  /** Czech Koruna . */
  CZK = "CZK",
  /** Danish Krone . */
  DKK = "DKK",
  /** Euro . */
  EUR = "EUR",
  /** Pound Sterling . */
  GBP = "GBP",
  /** Hong Kong Dollar . */
  HKD = "HKD",
  /** Hungarian Forint . */
  HUF = "HUF",
  /** Israeli New Sheqel . */
  ILS = "ILS",
  /** Japanese Yen . */
  JPY = "JPY",
  /** Mexican Peso . */
  MXN = "MXN",
  /** Malaysian Ringgit . */
  MYR = "MYR",
  /** Norwegian Krone . */
  NOK = "NOK",
  /** New Zealand Dollar . */
  NZD = "NZD",
  /** Philippine Peso . */
  PHP = "PHP",
  /** Polish Zloty . */
  PLN = "PLN",
  /** Russian Ruble . */
  RUB = "RUB",
  /** Swedish Krona . */
  SEK = "SEK",
  /** Singapore Dollar . */
  SGD = "SGD",
  /** Thai Baht . */
  THB = "THB",
  /** Taiwan New Dollar . */
  TWD = "TWD",
  /** U.S. Dollar . */
  USD = "USD",
  /** South African Rand . */
  ZAR = "ZAR",
}

/** Arguments for filtering the GfEntryToFormFieldConnection connection */
export interface GfEntryToFormFieldConnectionWhereArgs {
  /** Array of form field adminLabels to return. */
  adminLabels?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of Gravity Forms Field types to return. */
  fieldTypes?: InputMaybe<Array<InputMaybe<FormFieldTypeEnum>>>;
  /** Array of form field IDs to return. */
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** The form page number to return. */
  pageNumber?: InputMaybe<Scalars["Int"]>;
}

/** Arguments for filtering the GfFormToFormFieldConnection connection */
export interface GfFormToFormFieldConnectionWhereArgs {
  /** Array of form field adminLabels to return. */
  adminLabels?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of Gravity Forms Field types to return. */
  fieldTypes?: InputMaybe<Array<InputMaybe<FormFieldTypeEnum>>>;
  /** Array of form field IDs to return. */
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** The form page number to return. */
  pageNumber?: InputMaybe<Scalars["Int"]>;
}

/** Arguments for filtering the GfFormToGfEntryConnection connection */
export interface GfFormToGfEntryConnectionWhereArgs {
  /** Date filters to apply. */
  dateFilters?: InputMaybe<EntriesDateFiltersInput>;
  /** Field-specific filters to apply. */
  fieldFilters?: InputMaybe<Array<InputMaybe<EntriesFieldFiltersInput>>>;
  /** Whether to filter by ALL or ANY of the field filters. Default is ALL. */
  fieldFiltersMode?: InputMaybe<FieldFiltersModeEnum>;
  /** How to sort the entries. */
  orderby?: InputMaybe<EntriesConnectionOrderbyInput>;
  /** Entry status. Default is "ACTIVE". */
  status?: InputMaybe<EntryStatusEnum>;
}

/** Arguments for filtering the HierarchicalContentNodeToContentNodeAncestorsConnection connection */
export interface HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the HierarchicalContentNodeToContentNodeChildrenConnection connection */
export interface HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum JobIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  ID = "ID",
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  SLUG = "SLUG",
  /** Identify a resource by the URI. */
  URI = "URI",
}

/** Input fields for a single List field item. */
export interface ListFieldInput {
  /** Input values for the specific listField row. */
  rowValues?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum MediaItemIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  ID = "ID",
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  SLUG = "SLUG",
  /** Identify a media item by its source url */
  SOURCE_URL = "SOURCE_URL",
  /** Identify a resource by the URI. */
  URI = "URI",
}

/** The size of the media item object. */
export enum MediaItemSizeEnum {
  /** MediaItem with the large size */
  LARGE = "LARGE",
  /** MediaItem with the medium size */
  MEDIUM = "MEDIUM",
  /** MediaItem with the medium_large size */
  MEDIUM_LARGE = "MEDIUM_LARGE",
  /** MediaItem with the thumbnail size */
  THUMBNAIL = "THUMBNAIL",
  /** MediaItem with the 1536x1536 size */
  _1536X1536 = "_1536X1536",
  /** MediaItem with the 2048x2048 size */
  _2048X2048 = "_2048X2048",
}

/** The status of the media item object. */
export enum MediaItemStatusEnum {
  /** Objects with the auto-draft status */
  AUTO_DRAFT = "AUTO_DRAFT",
  /** Objects with the inherit status */
  INHERIT = "INHERIT",
  /** Objects with the private status */
  PRIVATE = "PRIVATE",
  /** Objects with the trash status */
  TRASH = "TRASH",
}

/** Arguments for filtering the MediaItemToCommentConnection connection */
export interface MediaItemToCommentConnectionWhereArgs {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars["String"]>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars["String"]>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars["ID"]>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars["String"]>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars["Int"]>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars["Int"]>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars["String"]>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars["String"]>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars["ID"]>;
}

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuItemNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  ID = "ID",
}

/** Arguments for filtering the MenuItemToMenuItemConnection connection */
export interface MenuItemToMenuItemConnectionWhereArgs {
  /** The ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars["ID"]>;
}

/** Registered menu locations */
export enum MenuLocationEnum {
  /** Put the menu in the footer location */
  FOOTER = "FOOTER",
  /** Put the menu in the primary location */
  PRIMARY = "PRIMARY",
}

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuNodeIdTypeEnum {
  /** Identify a menu node by the Database ID. */
  DATABASE_ID = "DATABASE_ID",
  /** Identify a menu node by the (hashed) Global ID. */
  ID = "ID",
  /** Identify a menu node by the slug of menu location to which it is assigned */
  LOCATION = "LOCATION",
  /** Identify a menu node by its name */
  NAME = "NAME",
  /** Identify a menu node by its slug */
  SLUG = "SLUG",
}

/** Arguments for filtering the MenuToMenuItemConnection connection */
export interface MenuToMenuItemConnectionWhereArgs {
  /** The ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars["ID"]>;
}

/** The MimeType of the object */
export enum MimeTypeEnum {
  /** MimeType application/java */
  APPLICATION_JAVA = "APPLICATION_JAVA",
  /** MimeType application/msword */
  APPLICATION_MSWORD = "APPLICATION_MSWORD",
  /** MimeType application/octet-stream */
  APPLICATION_OCTET_STREAM = "APPLICATION_OCTET_STREAM",
  /** MimeType application/onenote */
  APPLICATION_ONENOTE = "APPLICATION_ONENOTE",
  /** MimeType application/oxps */
  APPLICATION_OXPS = "APPLICATION_OXPS",
  /** MimeType application/pdf */
  APPLICATION_PDF = "APPLICATION_PDF",
  /** MimeType application/rar */
  APPLICATION_RAR = "APPLICATION_RAR",
  /** MimeType application/rtf */
  APPLICATION_RTF = "APPLICATION_RTF",
  /** MimeType application/ttaf+xml */
  APPLICATION_TTAF_XML = "APPLICATION_TTAF_XML",
  /** MimeType application/vnd.apple.keynote */
  APPLICATION_VND_APPLE_KEYNOTE = "APPLICATION_VND_APPLE_KEYNOTE",
  /** MimeType application/vnd.apple.numbers */
  APPLICATION_VND_APPLE_NUMBERS = "APPLICATION_VND_APPLE_NUMBERS",
  /** MimeType application/vnd.apple.pages */
  APPLICATION_VND_APPLE_PAGES = "APPLICATION_VND_APPLE_PAGES",
  /** MimeType application/vnd.ms-access */
  APPLICATION_VND_MS_ACCESS = "APPLICATION_VND_MS_ACCESS",
  /** MimeType application/vnd.ms-excel */
  APPLICATION_VND_MS_EXCEL = "APPLICATION_VND_MS_EXCEL",
  /** MimeType application/vnd.ms-excel.addin.macroEnabled.12 */
  APPLICATION_VND_MS_EXCEL_ADDIN_MACROENABLED_12 = "APPLICATION_VND_MS_EXCEL_ADDIN_MACROENABLED_12",
  /** MimeType application/vnd.ms-excel.sheet.binary.macroEnabled.12 */
  APPLICATION_VND_MS_EXCEL_SHEET_BINARY_MACROENABLED_12 = "APPLICATION_VND_MS_EXCEL_SHEET_BINARY_MACROENABLED_12",
  /** MimeType application/vnd.ms-excel.sheet.macroEnabled.12 */
  APPLICATION_VND_MS_EXCEL_SHEET_MACROENABLED_12 = "APPLICATION_VND_MS_EXCEL_SHEET_MACROENABLED_12",
  /** MimeType application/vnd.ms-excel.template.macroEnabled.12 */
  APPLICATION_VND_MS_EXCEL_TEMPLATE_MACROENABLED_12 = "APPLICATION_VND_MS_EXCEL_TEMPLATE_MACROENABLED_12",
  /** MimeType application/vnd.ms-powerpoint */
  APPLICATION_VND_MS_POWERPOINT = "APPLICATION_VND_MS_POWERPOINT",
  /** MimeType application/vnd.ms-powerpoint.addin.macroEnabled.12 */
  APPLICATION_VND_MS_POWERPOINT_ADDIN_MACROENABLED_12 = "APPLICATION_VND_MS_POWERPOINT_ADDIN_MACROENABLED_12",
  /** MimeType application/vnd.ms-powerpoint.presentation.macroEnabled.12 */
  APPLICATION_VND_MS_POWERPOINT_PRESENTATION_MACROENABLED_12 = "APPLICATION_VND_MS_POWERPOINT_PRESENTATION_MACROENABLED_12",
  /** MimeType application/vnd.ms-powerpoint.slideshow.macroEnabled.12 */
  APPLICATION_VND_MS_POWERPOINT_SLIDESHOW_MACROENABLED_12 = "APPLICATION_VND_MS_POWERPOINT_SLIDESHOW_MACROENABLED_12",
  /** MimeType application/vnd.ms-powerpoint.slide.macroEnabled.12 */
  APPLICATION_VND_MS_POWERPOINT_SLIDE_MACROENABLED_12 = "APPLICATION_VND_MS_POWERPOINT_SLIDE_MACROENABLED_12",
  /** MimeType application/vnd.ms-powerpoint.template.macroEnabled.12 */
  APPLICATION_VND_MS_POWERPOINT_TEMPLATE_MACROENABLED_12 = "APPLICATION_VND_MS_POWERPOINT_TEMPLATE_MACROENABLED_12",
  /** MimeType application/vnd.ms-project */
  APPLICATION_VND_MS_PROJECT = "APPLICATION_VND_MS_PROJECT",
  /** MimeType application/vnd.ms-word.document.macroEnabled.12 */
  APPLICATION_VND_MS_WORD_DOCUMENT_MACROENABLED_12 = "APPLICATION_VND_MS_WORD_DOCUMENT_MACROENABLED_12",
  /** MimeType application/vnd.ms-word.template.macroEnabled.12 */
  APPLICATION_VND_MS_WORD_TEMPLATE_MACROENABLED_12 = "APPLICATION_VND_MS_WORD_TEMPLATE_MACROENABLED_12",
  /** MimeType application/vnd.ms-write */
  APPLICATION_VND_MS_WRITE = "APPLICATION_VND_MS_WRITE",
  /** MimeType application/vnd.ms-xpsdocument */
  APPLICATION_VND_MS_XPSDOCUMENT = "APPLICATION_VND_MS_XPSDOCUMENT",
  /** MimeType application/vnd.oasis.opendocument.chart */
  APPLICATION_VND_OASIS_OPENDOCUMENT_CHART = "APPLICATION_VND_OASIS_OPENDOCUMENT_CHART",
  /** MimeType application/vnd.oasis.opendocument.database */
  APPLICATION_VND_OASIS_OPENDOCUMENT_DATABASE = "APPLICATION_VND_OASIS_OPENDOCUMENT_DATABASE",
  /** MimeType application/vnd.oasis.opendocument.formula */
  APPLICATION_VND_OASIS_OPENDOCUMENT_FORMULA = "APPLICATION_VND_OASIS_OPENDOCUMENT_FORMULA",
  /** MimeType application/vnd.oasis.opendocument.graphics */
  APPLICATION_VND_OASIS_OPENDOCUMENT_GRAPHICS = "APPLICATION_VND_OASIS_OPENDOCUMENT_GRAPHICS",
  /** MimeType application/vnd.oasis.opendocument.presentation */
  APPLICATION_VND_OASIS_OPENDOCUMENT_PRESENTATION = "APPLICATION_VND_OASIS_OPENDOCUMENT_PRESENTATION",
  /** MimeType application/vnd.oasis.opendocument.spreadsheet */
  APPLICATION_VND_OASIS_OPENDOCUMENT_SPREADSHEET = "APPLICATION_VND_OASIS_OPENDOCUMENT_SPREADSHEET",
  /** MimeType application/vnd.oasis.opendocument.text */
  APPLICATION_VND_OASIS_OPENDOCUMENT_TEXT = "APPLICATION_VND_OASIS_OPENDOCUMENT_TEXT",
  /** MimeType application/vnd.openxmlformats-officedocument.presentationml.presentation */
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION = "APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION",
  /** MimeType application/vnd.openxmlformats-officedocument.presentationml.slide */
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDE = "APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDE",
  /** MimeType application/vnd.openxmlformats-officedocument.presentationml.slideshow */
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDESHOW = "APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDESHOW",
  /** MimeType application/vnd.openxmlformats-officedocument.presentationml.template */
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_TEMPLATE = "APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_TEMPLATE",
  /** MimeType application/vnd.openxmlformats-officedocument.spreadsheetml.sheet */
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET = "APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET",
  /** MimeType application/vnd.openxmlformats-officedocument.spreadsheetml.template */
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_TEMPLATE = "APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_TEMPLATE",
  /** MimeType application/vnd.openxmlformats-officedocument.wordprocessingml.document */
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT = "APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT",
  /** MimeType application/vnd.openxmlformats-officedocument.wordprocessingml.template */
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_TEMPLATE = "APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_TEMPLATE",
  /** MimeType application/wordperfect */
  APPLICATION_WORDPERFECT = "APPLICATION_WORDPERFECT",
  /** MimeType application/x-7z-compressed */
  APPLICATION_X_7Z_COMPRESSED = "APPLICATION_X_7Z_COMPRESSED",
  /** MimeType application/x-gzip */
  APPLICATION_X_GZIP = "APPLICATION_X_GZIP",
  /** MimeType application/x-tar */
  APPLICATION_X_TAR = "APPLICATION_X_TAR",
  /** MimeType application/zip */
  APPLICATION_ZIP = "APPLICATION_ZIP",
  /** MimeType audio/aac */
  AUDIO_AAC = "AUDIO_AAC",
  /** MimeType audio/flac */
  AUDIO_FLAC = "AUDIO_FLAC",
  /** MimeType audio/midi */
  AUDIO_MIDI = "AUDIO_MIDI",
  /** MimeType audio/mpeg */
  AUDIO_MPEG = "AUDIO_MPEG",
  /** MimeType audio/ogg */
  AUDIO_OGG = "AUDIO_OGG",
  /** MimeType audio/wav */
  AUDIO_WAV = "AUDIO_WAV",
  /** MimeType audio/x-matroska */
  AUDIO_X_MATROSKA = "AUDIO_X_MATROSKA",
  /** MimeType audio/x-ms-wax */
  AUDIO_X_MS_WAX = "AUDIO_X_MS_WAX",
  /** MimeType audio/x-ms-wma */
  AUDIO_X_MS_WMA = "AUDIO_X_MS_WMA",
  /** MimeType audio/x-realaudio */
  AUDIO_X_REALAUDIO = "AUDIO_X_REALAUDIO",
  /** MimeType image/bmp */
  IMAGE_BMP = "IMAGE_BMP",
  /** MimeType image/gif */
  IMAGE_GIF = "IMAGE_GIF",
  /** MimeType image/heic */
  IMAGE_HEIC = "IMAGE_HEIC",
  /** MimeType image/jpeg */
  IMAGE_JPEG = "IMAGE_JPEG",
  /** MimeType image/png */
  IMAGE_PNG = "IMAGE_PNG",
  /** MimeType image/svg+xml */
  IMAGE_SVG_XML = "IMAGE_SVG_XML",
  /** MimeType image/tiff */
  IMAGE_TIFF = "IMAGE_TIFF",
  /** MimeType image/webp */
  IMAGE_WEBP = "IMAGE_WEBP",
  /** MimeType image/x-icon */
  IMAGE_X_ICON = "IMAGE_X_ICON",
  /** MimeType text/calendar */
  TEXT_CALENDAR = "TEXT_CALENDAR",
  /** MimeType text/css */
  TEXT_CSS = "TEXT_CSS",
  /** MimeType text/csv */
  TEXT_CSV = "TEXT_CSV",
  /** MimeType text/plain */
  TEXT_PLAIN = "TEXT_PLAIN",
  /** MimeType text/richtext */
  TEXT_RICHTEXT = "TEXT_RICHTEXT",
  /** MimeType text/tab-separated-values */
  TEXT_TAB_SEPARATED_VALUES = "TEXT_TAB_SEPARATED_VALUES",
  /** MimeType text/vtt */
  TEXT_VTT = "TEXT_VTT",
  /** MimeType video/3gpp */
  VIDEO_3GPP = "VIDEO_3GPP",
  /** MimeType video/3gpp2 */
  VIDEO_3GPP2 = "VIDEO_3GPP2",
  /** MimeType video/avi */
  VIDEO_AVI = "VIDEO_AVI",
  /** MimeType video/divx */
  VIDEO_DIVX = "VIDEO_DIVX",
  /** MimeType video/mp4 */
  VIDEO_MP4 = "VIDEO_MP4",
  /** MimeType video/mpeg */
  VIDEO_MPEG = "VIDEO_MPEG",
  /** MimeType video/ogg */
  VIDEO_OGG = "VIDEO_OGG",
  /** MimeType video/quicktime */
  VIDEO_QUICKTIME = "VIDEO_QUICKTIME",
  /** MimeType video/webm */
  VIDEO_WEBM = "VIDEO_WEBM",
  /** MimeType video/x-flv */
  VIDEO_X_FLV = "VIDEO_X_FLV",
  /** MimeType video/x-matroska */
  VIDEO_X_MATROSKA = "VIDEO_X_MATROSKA",
  /** MimeType video/x-ms-asf */
  VIDEO_X_MS_ASF = "VIDEO_X_MS_ASF",
  /** MimeType video/x-ms-wm */
  VIDEO_X_MS_WM = "VIDEO_X_MS_WM",
  /** MimeType video/x-ms-wmv */
  VIDEO_X_MS_WMV = "VIDEO_X_MS_WMV",
  /** MimeType video/x-ms-wmx */
  VIDEO_X_MS_WMX = "VIDEO_X_MS_WMX",
}

/** Input fields for name field. */
export interface NameFieldInput {
  /** First name. */
  first?: InputMaybe<Scalars["String"]>;
  /** Last name. */
  last?: InputMaybe<Scalars["String"]>;
  /** Middle name. */
  middle?: InputMaybe<Scalars["String"]>;
  /** Prefix, such as Mr., Mrs. etc. */
  prefix?: InputMaybe<Scalars["String"]>;
  /** Suffix, such as Sr., Jr. etc. */
  suffix?: InputMaybe<Scalars["String"]>;
}

/** The format allowed for the number field. . */
export enum NumberFieldFormatEnum {
  /** Currency format. */
  CURRENCY = "CURRENCY",
  /** Decimal-comma format (e.g. 9.999,99). */
  DECIMAL_COMMA = "DECIMAL_COMMA",
  /** Decimal-dot format (e.g. 9,999.99). */
  DECIMAL_DOT = "DECIMAL_DOT",
}

/** The cardinality of the connection order */
export enum OrderEnum {
  /** Sort the query result set in an ascending order */
  ASC = "ASC",
  /** Sort the query result set in a descending order */
  DESC = "DESC",
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PageIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  ID = "ID",
  /** Identify a resource by the URI. */
  URI = "URI",
}

/** Arguments for filtering the PageToCommentConnection connection */
export interface PageToCommentConnectionWhereArgs {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars["String"]>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars["String"]>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars["ID"]>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars["String"]>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars["Int"]>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars["Int"]>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars["String"]>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars["String"]>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars["ID"]>;
}

/** Arguments for filtering the pageToRevisionConnection connection */
export interface PageToRevisionConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Indicates how strong the password should be. */
export enum PasswordFieldMinStrengthEnum {
  /** The password strength must be "bad" or better. */
  BAD = "BAD",
  /** The password strength must be "good" or better. */
  GOOD = "GOOD",
  /** The password strength must be "short" or better. */
  SHORT = "SHORT",
  /** The password strength must be "strong". */
  STRONG = "STRONG",
}

/** Tthe allowed format for phone numbers. */
export enum PhoneFieldFormatEnum {
  /** International phone number format. */
  INTERNATIONAL = "INTERNATIONAL",
  /** Standard phone number format. */
  STANDARD = "STANDARD",
}

/** The status of the WordPress plugin. */
export enum PluginStatusEnum {
  /** The plugin is currently active. */
  ACTIVE = "ACTIVE",
  /** The plugin is a drop-in plugin. */
  DROP_IN = "DROP_IN",
  /** The plugin is currently inactive. */
  INACTIVE = "INACTIVE",
  /** The plugin is a must-use plugin. */
  MUST_USE = "MUST_USE",
  /** The plugin is technically active but was paused while loading. */
  PAUSED = "PAUSED",
  /** The plugin was active recently. */
  RECENTLY_ACTIVE = "RECENTLY_ACTIVE",
  /** The plugin has an upgrade available. */
  UPGRADE = "UPGRADE",
}

/** Set relationships between the post to categories */
export interface PostCategoriesInput {
  /** If true, this will append the category to existing related categories. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostCategoriesNodeInput>>>;
}

/** List of categories to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export interface PostCategoriesNodeInput {
  /** The description of the category. This field is used to set a description of the category if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the category. If present, this will be used to connect to the post. If no existing category exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the category. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the category. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostFormatIdType {
  /** The Database ID for the node */
  DATABASE_ID = "DATABASE_ID",
  /** The hashed Global ID */
  ID = "ID",
  /** The name of the node */
  NAME = "NAME",
  /** Url friendly name of the node */
  SLUG = "SLUG",
  /** The URI for the node */
  URI = "URI",
}

/** Arguments for filtering the PostFormatToContentNodeConnection connection */
export interface PostFormatToContentNodeConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfPostFormatEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the PostFormatToPostConnection connection */
export interface PostFormatToPostConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** List of possible post formats. */
export enum PostFormatTypeEnum {
  /** A standard post format. */
  STANDARD = "STANDARD",
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  ID = "ID",
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  SLUG = "SLUG",
  /** Identify a resource by the URI. */
  URI = "URI",
}

/** The format of post field data. */
export enum PostObjectFieldFormatEnum {
  /** Provide the field value directly from database. Null on unauthenticated requests. */
  RAW = "RAW",
  /** Provide the field value as rendered by WordPress. Default. */
  RENDERED = "RENDERED",
}

/** The column to use when filtering by date */
export enum PostObjectsConnectionDateColumnEnum {
  /** The date the comment was created in local time. */
  DATE = "DATE",
  /** The most recent modification date of the comment. */
  MODIFIED = "MODIFIED",
}

/** Field to order the connection by */
export enum PostObjectsConnectionOrderbyEnum {
  /** Order by author */
  AUTHOR = "AUTHOR",
  /** Order by the number of comments it has acquired */
  COMMENT_COUNT = "COMMENT_COUNT",
  /** Order by publish date */
  DATE = "DATE",
  /** Preserve the ID order given in the IN array */
  IN = "IN",
  /** Order by the menu order value */
  MENU_ORDER = "MENU_ORDER",
  /** Order by last modified date */
  MODIFIED = "MODIFIED",
  /** Preserve slug order given in the NAME_IN array */
  NAME_IN = "NAME_IN",
  /** Order by parent ID */
  PARENT = "PARENT",
  /** Order by slug */
  SLUG = "SLUG",
  /** Order by title */
  TITLE = "TITLE",
}

/** Options for ordering the connection */
export interface PostObjectsConnectionOrderbyInput {
  /** The field to order the connection by */
  field: PostObjectsConnectionOrderbyEnum;
  /** Possible directions in which to order a list of items */
  order: OrderEnum;
}

/** Set relationships between the post to postFormats */
export interface PostPostFormatsInput {
  /** If true, this will append the postFormat to existing related postFormats. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostPostFormatsNodeInput>>>;
}

/** List of postFormats to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export interface PostPostFormatsNodeInput {
  /** The description of the postFormat. This field is used to set a description of the postFormat if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the postFormat. If present, this will be used to connect to the post. If no existing postFormat exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the postFormat. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the postFormat. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
}

/** The status of the object. */
export enum PostStatusEnum {
  /** Objects with the acf-disabled status */
  ACF_DISABLED = "ACF_DISABLED",
  /** Objects with the auto-draft status */
  AUTO_DRAFT = "AUTO_DRAFT",
  /** Objects with the draft status */
  DRAFT = "DRAFT",
  /** Objects with the future status */
  FUTURE = "FUTURE",
  /** Objects with the inherit status */
  INHERIT = "INHERIT",
  /** Objects with the pending status */
  PENDING = "PENDING",
  /** Objects with the private status */
  PRIVATE = "PRIVATE",
  /** Objects with the publish status */
  PUBLISH = "PUBLISH",
  /** Objects with the request-completed status */
  REQUEST_COMPLETED = "REQUEST_COMPLETED",
  /** Objects with the request-confirmed status */
  REQUEST_CONFIRMED = "REQUEST_CONFIRMED",
  /** Objects with the request-failed status */
  REQUEST_FAILED = "REQUEST_FAILED",
  /** Objects with the request-pending status */
  REQUEST_PENDING = "REQUEST_PENDING",
  /** Objects with the trash status */
  TRASH = "TRASH",
}

/** Set relationships between the post to tags */
export interface PostTagsInput {
  /** If true, this will append the tag to existing related tags. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostTagsNodeInput>>>;
}

/** List of tags to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export interface PostTagsNodeInput {
  /** The description of the tag. This field is used to set a description of the tag if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the tag. If present, this will be used to connect to the post. If no existing tag exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the tag. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the tag. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the PostToCategoryConnection connection */
export interface PostToCategoryConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
}

/** Arguments for filtering the PostToCommentConnection connection */
export interface PostToCommentConnectionWhereArgs {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars["String"]>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars["String"]>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars["ID"]>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars["String"]>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars["Int"]>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars["Int"]>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars["String"]>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars["String"]>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars["ID"]>;
}

/** Arguments for filtering the PostToPostFormatConnection connection */
export interface PostToPostFormatConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
}

/** Arguments for filtering the postToRevisionConnection connection */
export interface PostToRevisionConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the PostToTagConnection connection */
export interface PostToTagConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
}

/** Arguments for filtering the PostToTermNodeConnection connection */
export interface PostToTermNodeConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
}

/** Input for the registerUser mutation */
export interface RegisterUserInput {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars["String"]>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars["String"]>;
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars["String"]>;
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars["String"]>;
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars["String"]>;
  /** The user's last name. */
  lastName?: InputMaybe<Scalars["String"]>;
  /** User's locale. */
  locale?: InputMaybe<Scalars["String"]>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars["String"]>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars["String"]>;
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars["String"]>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars["String"]>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars["String"]>;
  /** A string that contains the user's username. */
  username: Scalars["String"];
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars["String"]>;
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars["String"]>;
}

/** The logical relation between each item in the array when there are more than one. */
export enum RelationEnum {
  /** The logical AND condition returns true if both operands are true, otherwise, it returns false. */
  AND = "AND",
  /** The logical OR condition returns false if both operands are false, otherwise, it returns true. */
  OR = "OR",
}

/** Input for the resetUserPassword mutation */
export interface ResetUserPasswordInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Password reset key */
  key?: InputMaybe<Scalars["String"]>;
  /** The user's login (username). */
  login?: InputMaybe<Scalars["String"]>;
  /** The new password. */
  password?: InputMaybe<Scalars["String"]>;
}

/** Input for the restoreComment mutation */
export interface RestoreCommentInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The ID of the comment to be restored */
  id: Scalars["ID"];
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ReusableBlockIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  ID = "ID",
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  SLUG = "SLUG",
  /** Identify a resource by the URI. */
  URI = "URI",
}

/** Arguments for filtering the ReusableBlockToRevisionConnection connection */
export interface ReusableBlockToRevisionConnectionWhereArgs {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the RootQueryToBlockEditorPreviewConnection connection */
export interface RootQueryToBlockEditorPreviewConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  previewedDatabaseId?: InputMaybe<Scalars["Int"]>;
  previewedParentDatabaseId?: InputMaybe<Scalars["Int"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the RootQueryToCategoryConnection connection */
export interface RootQueryToCategoryConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
}

/** Arguments for filtering the RootQueryToCommentConnection connection */
export interface RootQueryToCommentConnectionWhereArgs {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars["String"]>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars["String"]>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars["ID"]>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars["String"]>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars["Int"]>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars["Int"]>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars["String"]>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars["String"]>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars["ID"]>;
}

/** Arguments for filtering the RootQueryToContentNodeConnection connection */
export interface RootQueryToContentNodeConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the RootQueryToContentRevisionUnionConnection connection */
export interface RootQueryToContentRevisionUnionConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the RootQueryToGfEntryConnection connection */
export interface RootQueryToGfEntryConnectionWhereArgs {
  /** Date filters to apply. */
  dateFilters?: InputMaybe<EntriesDateFiltersInput>;
  /** Entry status. Default is `SUBMITTED`. Currently no other types are supported. */
  entryType?: InputMaybe<EntryTypeEnum>;
  /** Field-specific filters to apply. */
  fieldFilters?: InputMaybe<Array<InputMaybe<EntriesFieldFiltersInput>>>;
  /** Whether to filter by ALL or ANY of the field filters. Default is ALL. */
  fieldFiltersMode?: InputMaybe<FieldFiltersModeEnum>;
  /** Array of form IDs to limit the entries to. Exclude this argument to query all forms. */
  formIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** How to sort the entries. */
  orderby?: InputMaybe<EntriesConnectionOrderbyInput>;
  /** Entry status. Default is "ACTIVE". */
  status?: InputMaybe<EntryStatusEnum>;
}

/** Arguments for filtering the RootQueryToGfFormConnection connection */
export interface RootQueryToGfFormConnectionWhereArgs {
  /** Array of form IDs to return. Exclude this argument to query all forms. */
  formIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** How to sort the entries. */
  orderby?: InputMaybe<FormsConnectionOrderbyInput>;
  /** Status of the forms to get. */
  status?: InputMaybe<FormStatusEnum>;
}

/** Arguments for filtering the RootQueryToGfSubmittedEntryConnection connection */
export interface RootQueryToGfSubmittedEntryConnectionWhereArgs {
  /** Date filters to apply. */
  dateFilters?: InputMaybe<EntriesDateFiltersInput>;
  /** Field-specific filters to apply. */
  fieldFilters?: InputMaybe<Array<InputMaybe<EntriesFieldFiltersInput>>>;
  /** Whether to filter by ALL or ANY of the field filters. Default is ALL. */
  fieldFiltersMode?: InputMaybe<FieldFiltersModeEnum>;
  /** Array of form IDs to limit the entries to. Exclude this argument to query all forms. */
  formIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** How to sort the entries. */
  orderby?: InputMaybe<EntriesConnectionOrderbyInput>;
  /** Entry status. Default is "ACTIVE". */
  status?: InputMaybe<EntryStatusEnum>;
}

/** Arguments for filtering the RootQueryToJobConnection connection */
export interface RootQueryToJobConnectionWhereArgs {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the RootQueryToMediaItemConnection connection */
export interface RootQueryToMediaItemConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the RootQueryToMenuConnection connection */
export interface RootQueryToMenuConnectionWhereArgs {
  /** The ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The slug of the menu to query items for */
  slug?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the RootQueryToMenuItemConnection connection */
export interface RootQueryToMenuItemConnectionWhereArgs {
  /** The ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars["ID"]>;
}

/** Arguments for filtering the RootQueryToPageConnection connection */
export interface RootQueryToPageConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the RootQueryToPluginConnection connection */
export interface RootQueryToPluginConnectionWhereArgs {
  /** Show plugin based on a keyword search. */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve plugins where plugin status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PluginStatusEnum>>>;
  /** Show plugins with a specific status. */
  status?: InputMaybe<PluginStatusEnum>;
}

/** Arguments for filtering the RootQueryToPostConnection connection */
export interface RootQueryToPostConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to only include sticky posts */
  onlySticky?: InputMaybe<Scalars["Boolean"]>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the RootQueryToPostFormatConnection connection */
export interface RootQueryToPostFormatConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
}

/** Arguments for filtering the RootQueryToReusableBlockConnection connection */
export interface RootQueryToReusableBlockConnectionWhereArgs {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the RootQueryToServiceConnection connection */
export interface RootQueryToServiceConnectionWhereArgs {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the RootQueryToSpecialtyConnection connection */
export interface RootQueryToSpecialtyConnectionWhereArgs {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the RootQueryToTagConnection connection */
export interface RootQueryToTagConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
}

/** Arguments for filtering the RootQueryToTeam_memberConnection connection */
export interface RootQueryToTeam_memberConnectionWhereArgs {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the RootQueryToTermNodeConnection connection */
export interface RootQueryToTermNodeConnectionWhereArgs {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
}

/** Arguments for filtering the RootQueryToUserConnection connection */
export interface RootQueryToUserConnectionWhereArgs {
  /** Array of userIds to exclude. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  /** Pass an array of post types to filter results to users who have published posts in those post types. */
  hasPublishedPosts?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of userIds to include. */
  include?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  /** The user login. */
  login?: InputMaybe<Scalars["String"]>;
  /** An array of logins to include. Users matching one of these logins will be included in results. */
  loginIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** An array of logins to exclude. Users matching one of these logins will not be included in results. */
  loginNotIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** The user nicename. */
  nicename?: InputMaybe<Scalars["String"]>;
  /** An array of nicenames to include. Users matching one of these nicenames will be included in results. */
  nicenameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** An array of nicenames to exclude. Users matching one of these nicenames will not be included in results. */
  nicenameNotIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<UsersConnectionOrderbyInput>>>;
  /** An array of role names that users must match to be included in results. Note that this is an inclusive list: users must match *each* role. */
  role?: InputMaybe<UserRoleEnum>;
  /** An array of role names. Matched users must have at least one of these roles. */
  roleIn?: InputMaybe<Array<InputMaybe<UserRoleEnum>>>;
  /** An array of role names to exclude. Users matching one or more of these roles will not be included in results. */
  roleNotIn?: InputMaybe<Array<InputMaybe<UserRoleEnum>>>;
  /** Search keyword. Searches for possible string matches on columns. When "searchColumns" is left empty, it tries to determine which column to search in based on search string. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of column names to be searched. Accepts 'ID', 'login', 'nicename', 'email', 'url'. */
  searchColumns?: InputMaybe<
    Array<InputMaybe<UsersConnectionSearchColumnEnum>>
  >;
}

/** Arguments for filtering the RootQueryToWorkConnection connection */
export interface RootQueryToWorkConnectionWhereArgs {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Types of cards */
export enum SEOCardType {
  summary = "summary",
  summary_large_image = "summary_large_image",
}

/** Input for the sendPasswordResetEmail mutation */
export interface SendPasswordResetEmailInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A string that contains the user's username or email address. */
  username: Scalars["String"];
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ServiceIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  ID = "ID",
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  SLUG = "SLUG",
  /** Identify a resource by the URI. */
  URI = "URI",
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum SpecialtyIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  ID = "ID",
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  SLUG = "SLUG",
  /** Identify a resource by the URI. */
  URI = "URI",
}

/** Entry meta input fields for submitting Gravity Forms forms. */
export interface SubmitFormMetaInput {
  /** ID of the user that submitted of the form if a logged in user submitted the form. */
  createdById?: InputMaybe<Scalars["Int"]>;
  /** The UTC date the entry was created, in `Y-m-d H:i:s` format. */
  dateCreatedGmt?: InputMaybe<Scalars["String"]>;
  /** Client IP of user who submitted the form. */
  ip?: InputMaybe<Scalars["String"]>;
  /** Used to overwrite the sourceUrl the form was submitted from. */
  sourceUrl?: InputMaybe<Scalars["String"]>;
  /** The name and version of both the browser and operating system from which the entry was submitted. */
  userAgent?: InputMaybe<Scalars["String"]>;
}

/** Input for the submitGfDraftEntry mutation */
export interface SubmitGfDraftEntryInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Either the global ID of the draft entry, or its resume token. */
  id: Scalars["ID"];
  /** The ID type for the draft entry. Defaults to `ID` . */
  idType?: InputMaybe<DraftEntryIdTypeEnum>;
}

/** Input for the submitGfForm mutation */
export interface SubmitGfFormInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The entry meta associated with the submission. */
  entryMeta?: InputMaybe<SubmitFormMetaInput>;
  /** The field ids and their values. */
  fieldValues: Array<InputMaybe<FormFieldValuesInput>>;
  /** The form ID. Accepts either a global or Database ID. */
  id: Scalars["ID"];
  /** Set to `true` if submitting a draft entry. Defaults to `false`. */
  saveAsDraft?: InputMaybe<Scalars["Boolean"]>;
  /** Useful for multi-page forms to indicate which page of the form was just submitted. */
  sourcePage?: InputMaybe<Scalars["Int"]>;
  /** Useful for multi-page forms to indicate which page is to be loaded if the current page passes validation. */
  targetPage?: InputMaybe<Scalars["Int"]>;
}

/** The Type of Identifier used to fetch a single resource. */
export enum SubmittedEntryIdTypeEnum {
  /** The database ID assigned by Gravity Forms. Used by submitted entries. */
  DATABASE_ID = "DATABASE_ID",
  /** Unique global ID for the object. */
  ID = "ID",
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TagIdType {
  /** The Database ID for the node */
  DATABASE_ID = "DATABASE_ID",
  /** The hashed Global ID */
  ID = "ID",
  /** The name of the node */
  NAME = "NAME",
  /** Url friendly name of the node */
  SLUG = "SLUG",
  /** The URI for the node */
  URI = "URI",
}

/** Arguments for filtering the TagToContentNodeConnection connection */
export interface TagToContentNodeConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfTagEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the TagToPostConnection connection */
export interface TagToPostConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Allowed taxonomies */
export enum TaxonomyEnum {
  /** Taxonomy enum category */
  CATEGORY = "CATEGORY",
  /** Taxonomy enum post_format */
  POSTFORMAT = "POSTFORMAT",
  /** Taxonomy enum post_tag */
  TAG = "TAG",
}

/** The Type of Identifier used to fetch a single Taxonomy node. To be used along with the "id" field. Default is "ID". */
export enum TaxonomyIdTypeEnum {
  /** The globally unique ID */
  ID = "ID",
  /** The name of the taxonomy */
  NAME = "NAME",
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum Team_memberIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  ID = "ID",
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  SLUG = "SLUG",
  /** Identify a resource by the URI. */
  URI = "URI",
}

/** The Type of Identifier used to fetch a single resource. Default is "ID". To be used along with the "id" field. */
export enum TermNodeIdTypeEnum {
  /** The Database ID for the node */
  DATABASE_ID = "DATABASE_ID",
  /** The hashed Global ID */
  ID = "ID",
  /** The name of the node */
  NAME = "NAME",
  /** Url friendly name of the node */
  SLUG = "SLUG",
  /** The URI for the node */
  URI = "URI",
}

/** Options for ordering the connection by */
export enum TermObjectsConnectionOrderbyEnum {
  /** Order the connection by item count. */
  COUNT = "COUNT",
  /** Order the connection by description. */
  DESCRIPTION = "DESCRIPTION",
  /** Order the connection by name. */
  NAME = "NAME",
  /** Order the connection by slug. */
  SLUG = "SLUG",
  /** Order the connection by term group. */
  TERM_GROUP = "TERM_GROUP",
  /** Order the connection by term id. */
  TERM_ID = "TERM_ID",
  /** Order the connection by term order. */
  TERM_ORDER = "TERM_ORDER",
}

/** How the time is displayed. */
export enum TimeFieldFormatEnum {
  /** 12-hour time format. */
  H12 = "H12",
  /** 24-hour time format. */
  H24 = "H24",
}

/** Input for the UpdateCategory mutation */
export interface UpdateCategoryInput {
  /** The slug that the category will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the category object */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the category object to update */
  id: Scalars["ID"];
  /** The name of the category object to mutate */
  name?: InputMaybe<Scalars["String"]>;
  /** The ID of the category that should be set as the parent */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
}

/** Input for the updateComment mutation */
export interface UpdateCommentInput {
  /** The approval status of the comment. */
  approved?: InputMaybe<Scalars["String"]>;
  /** The name of the comment's author. */
  author?: InputMaybe<Scalars["String"]>;
  /** The email of the comment's author. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** The url of the comment's author. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The database ID of the post object the comment belongs to. */
  commentOn?: InputMaybe<Scalars["Int"]>;
  /** Content of the comment. */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The ID of the comment being updated. */
  id: Scalars["ID"];
  /** Parent comment ID of current comment. */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Type of comment. */
  type?: InputMaybe<Scalars["String"]>;
}

/** Entry meta input fields for updating draft Gravity Forms entries. */
export interface UpdateDraftEntryMetaInput {
  /** ID of the user that submitted of the form if a logged in user submitted the form. */
  createdById?: InputMaybe<Scalars["Int"]>;
  /** The UTC date the entry was created, in `Y-m-d H:i:s` format. */
  dateCreatedGmt?: InputMaybe<Scalars["String"]>;
  /** Client IP of user who submitted the form. */
  ip?: InputMaybe<Scalars["String"]>;
  /** Used to overwrite the sourceUrl the form was submitted from. */
  sourceUrl?: InputMaybe<Scalars["String"]>;
  /** The name and version of both the browser and operating system from which the entry was submitted. */
  userAgent?: InputMaybe<Scalars["String"]>;
}

/** Entry meta input fields for updating Gravity Forms entries. */
export interface UpdateEntryMetaInput {
  /** ID of the user that submitted of the form if a logged in user submitted the form. */
  createdById?: InputMaybe<Scalars["Int"]>;
  /** The UTC date the entry was created, in `Y-m-d H:i:s` format. */
  dateCreatedGmt?: InputMaybe<Scalars["String"]>;
  /** Client IP of user who submitted the form. */
  ip?: InputMaybe<Scalars["String"]>;
  /** Whether the entry has been read. */
  isRead?: InputMaybe<Scalars["Boolean"]>;
  /** Whether the entry has been starred (i.e marked with a star). */
  isStarred?: InputMaybe<Scalars["Boolean"]>;
  /** Used to overwrite the sourceUrl the form was submitted from. */
  sourceUrl?: InputMaybe<Scalars["String"]>;
  /** The current status of the entry. */
  status?: InputMaybe<EntryStatusEnum>;
  /** The name and version of both the browser and operating system from which the entry was submitted. */
  userAgent?: InputMaybe<Scalars["String"]>;
}

/** Input for the updateGfDraftEntry mutation */
export interface UpdateGfDraftEntryInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The entry meta values to update. */
  entryMeta?: InputMaybe<UpdateDraftEntryMetaInput>;
  /** The field ids and their values. */
  fieldValues?: InputMaybe<Array<InputMaybe<FormFieldValuesInput>>>;
  /** Either the global ID of the draft entry, or its resume token. */
  id: Scalars["ID"];
  /** The ID type for the draft entry. Defaults to `ID` . */
  idType?: InputMaybe<DraftEntryIdTypeEnum>;
  /** Whether the field values should be validated on submission. Defaults to false. */
  shouldValidate?: InputMaybe<Scalars["Boolean"]>;
}

/** Input for the updateGfEntry mutation */
export interface UpdateGfEntryInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The entry meta values to update. */
  entryMeta?: InputMaybe<UpdateEntryMetaInput>;
  /** The field ids and their values to update. */
  fieldValues?: InputMaybe<Array<InputMaybe<FormFieldValuesInput>>>;
  /** ID of the entry to update, either a global or database ID. */
  id: Scalars["ID"];
  /** Whether the field values should be validated on submission. Defaults to false. */
  shouldValidate?: InputMaybe<Scalars["Boolean"]>;
}

/** Input for the updateJob mutation */
export interface UpdateJobInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The ID of the job object */
  id: Scalars["ID"];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Input for the updateMediaItem mutation */
export interface UpdateMediaItemInput {
  /** Alternative text to display when mediaItem is not displayed */
  altText?: InputMaybe<Scalars["String"]>;
  /** The userId to assign as the author of the mediaItem */
  authorId?: InputMaybe<Scalars["ID"]>;
  /** The caption for the mediaItem */
  caption?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The comment status for the mediaItem */
  commentStatus?: InputMaybe<Scalars["String"]>;
  /** The date of the mediaItem */
  date?: InputMaybe<Scalars["String"]>;
  /** The date (in GMT zone) of the mediaItem */
  dateGmt?: InputMaybe<Scalars["String"]>;
  /** Description of the mediaItem */
  description?: InputMaybe<Scalars["String"]>;
  /** The file name of the mediaItem */
  filePath?: InputMaybe<Scalars["String"]>;
  /** The file type of the mediaItem */
  fileType?: InputMaybe<MimeTypeEnum>;
  /** The ID of the mediaItem object */
  id: Scalars["ID"];
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The ping status for the mediaItem */
  pingStatus?: InputMaybe<Scalars["String"]>;
  /** The slug of the mediaItem */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the mediaItem */
  status?: InputMaybe<MediaItemStatusEnum>;
  /** The title of the mediaItem */
  title?: InputMaybe<Scalars["String"]>;
}

/** Input for the updatePage mutation */
export interface UpdatePageInput {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars["ID"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The ID of the page object */
  id: Scalars["ID"];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Input for the UpdatePostFormat mutation */
export interface UpdatePostFormatInput {
  /** The slug that the post_format will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the post_format object */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the postFormat object to update */
  id: Scalars["ID"];
  /** The name of the post_format object to mutate */
  name?: InputMaybe<Scalars["String"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
}

/** Input for the updatePost mutation */
export interface UpdatePostInput {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars["ID"]>;
  /** Set connections between the post and categories */
  categories?: InputMaybe<PostCategoriesInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars["String"]>;
  /** The ID of the post object */
  id: Scalars["ID"];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The ping status for the object */
  pingStatus?: InputMaybe<Scalars["String"]>;
  /** URLs that have been pinged. */
  pinged?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Set connections between the post and postFormats */
  postFormats?: InputMaybe<PostPostFormatsInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the post and tags */
  tags?: InputMaybe<PostTagsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
  /** URLs queued to be pinged. */
  toPing?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
}

/** Input for the updateReusableBlock mutation */
export interface UpdateReusableBlockInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The ID of the ReusableBlock object */
  id: Scalars["ID"];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Input for the updateService mutation */
export interface UpdateServiceInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The ID of the service object */
  id: Scalars["ID"];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Input for the updateSettings mutation */
export interface UpdateSettingsInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Allow people to submit comments on new posts. */
  discussionSettingsDefaultCommentStatus?: InputMaybe<Scalars["String"]>;
  /** Allow link notifications from other blogs (pingbacks and trackbacks) on new articles. */
  discussionSettingsDefaultPingStatus?: InputMaybe<Scalars["String"]>;
  /** A date format for all date strings. */
  generalSettingsDateFormat?: InputMaybe<Scalars["String"]>;
  /** Site tagline. */
  generalSettingsDescription?: InputMaybe<Scalars["String"]>;
  /** This address is used for admin purposes, like new user notification. */
  generalSettingsEmail?: InputMaybe<Scalars["String"]>;
  /** WordPress locale code. */
  generalSettingsLanguage?: InputMaybe<Scalars["String"]>;
  /** A day number of the week that the week should start on. */
  generalSettingsStartOfWeek?: InputMaybe<Scalars["Int"]>;
  /** A time format for all time strings. */
  generalSettingsTimeFormat?: InputMaybe<Scalars["String"]>;
  /** A city in the same timezone as you. */
  generalSettingsTimezone?: InputMaybe<Scalars["String"]>;
  /** Site title. */
  generalSettingsTitle?: InputMaybe<Scalars["String"]>;
  /** Site URL. */
  generalSettingsUrl?: InputMaybe<Scalars["String"]>;
  /** The ID of the page that should display the latest posts */
  readingSettingsPageForPosts?: InputMaybe<Scalars["Int"]>;
  /** The ID of the page that should be displayed on the front page */
  readingSettingsPageOnFront?: InputMaybe<Scalars["Int"]>;
  /** Blog pages show at most. */
  readingSettingsPostsPerPage?: InputMaybe<Scalars["Int"]>;
  /** What to show on the front page */
  readingSettingsShowOnFront?: InputMaybe<Scalars["String"]>;
  /** Default post category. */
  writingSettingsDefaultCategory?: InputMaybe<Scalars["Int"]>;
  /** Default post format. */
  writingSettingsDefaultPostFormat?: InputMaybe<Scalars["String"]>;
  /** Convert emoticons like :-) and :-P to graphics on display. */
  writingSettingsUseSmilies?: InputMaybe<Scalars["Boolean"]>;
}

/** Input for the updateSpecialty mutation */
export interface UpdateSpecialtyInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The ID of the specialty object */
  id: Scalars["ID"];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Input for the UpdateTag mutation */
export interface UpdateTagInput {
  /** The slug that the post_tag will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the post_tag object */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the tag object to update */
  id: Scalars["ID"];
  /** The name of the post_tag object to mutate */
  name?: InputMaybe<Scalars["String"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
}

/** Input for the updateTeam_member mutation */
export interface UpdateTeam_memberInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The ID of the team_member object */
  id: Scalars["ID"];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Input for the updateUser mutation */
export interface UpdateUserInput {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars["String"]>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars["String"]>;
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars["String"]>;
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars["String"]>;
  /** The ID of the user */
  id: Scalars["ID"];
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars["String"]>;
  /** The user's last name. */
  lastName?: InputMaybe<Scalars["String"]>;
  /** User's locale. */
  locale?: InputMaybe<Scalars["String"]>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars["String"]>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars["String"]>;
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars["String"]>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars["String"]>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars["String"]>;
  /** An array of roles to be assigned to the user. */
  roles?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars["String"]>;
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars["String"]>;
}

/** Input for the updateWork mutation */
export interface UpdateWorkInput {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The ID of the work object */
  id: Scalars["ID"];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** The Type of Identifier used to fetch a single User node. To be used along with the "id" field. Default is "ID". */
export enum UserNodeIdTypeEnum {
  /** The Database ID for the node */
  DATABASE_ID = "DATABASE_ID",
  /** The Email of the User */
  EMAIL = "EMAIL",
  /** The hashed Global ID */
  ID = "ID",
  /** The slug of the User */
  SLUG = "SLUG",
  /** The URI for the node */
  URI = "URI",
  /** The username the User uses to login with */
  USERNAME = "USERNAME",
}

/** Names of available user roles */
export enum UserRoleEnum {
  /** User role with specific capabilities */
  ADMINISTRATOR = "ADMINISTRATOR",
  /** User role with specific capabilities */
  AUTHOR = "AUTHOR",
  /** User role with specific capabilities */
  CONTRIBUTOR = "CONTRIBUTOR",
  /** User role with specific capabilities */
  EDITOR = "EDITOR",
  /** User role with specific capabilities */
  SEO_EDITOR = "SEO_EDITOR",
  /** User role with specific capabilities */
  SEO_MANAGER = "SEO_MANAGER",
  /** User role with specific capabilities */
  SUBSCRIBER = "SUBSCRIBER",
}

/** Arguments for filtering the UserToBlockEditorPreviewConnection connection */
export interface UserToBlockEditorPreviewConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the UserToCommentConnection connection */
export interface UserToCommentConnectionWhereArgs {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars["String"]>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars["String"]>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars["ID"]>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars["String"]>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars["Int"]>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars["Int"]>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars["String"]>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars["String"]>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars["ID"]>;
}

/** Arguments for filtering the UserToContentRevisionUnionConnection connection */
export interface UserToContentRevisionUnionConnectionWhereArgs {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the UserToMediaItemConnection connection */
export interface UserToMediaItemConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the UserToPageConnection connection */
export interface UserToPageConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Arguments for filtering the UserToPostConnection connection */
export interface UserToPostConnectionWhereArgs {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
}

/** Field to order the connection by */
export enum UsersConnectionOrderbyEnum {
  /** Order by display name */
  DISPLAY_NAME = "DISPLAY_NAME",
  /** Order by email address */
  EMAIL = "EMAIL",
  /** Order by login */
  LOGIN = "LOGIN",
  /** Preserve the login order given in the LOGIN_IN array */
  LOGIN_IN = "LOGIN_IN",
  /** Order by nice name */
  NICE_NAME = "NICE_NAME",
  /** Preserve the nice name order given in the NICE_NAME_IN array */
  NICE_NAME_IN = "NICE_NAME_IN",
  /** Order by registration date */
  REGISTERED = "REGISTERED",
  /** Order by URL */
  URL = "URL",
}

/** Options for ordering the connection */
export interface UsersConnectionOrderbyInput {
  /** The field name used to sort the results. */
  field: UsersConnectionOrderbyEnum;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
}

/** Column used for searching for users. */
export enum UsersConnectionSearchColumnEnum {
  /** The user's email address. */
  EMAIL = "EMAIL",
  /** The globally unique ID. */
  ID = "ID",
  /** The username the User uses to login with. */
  LOGIN = "LOGIN",
  /** A URL-friendly name for the user. The default is the user's username. */
  NICENAME = "NICENAME",
  /** The URL of the user\s website. */
  URL = "URL",
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum WorkIdType {
  /** Identify a resource by the Database ID. */
  DATABASE_ID = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  ID = "ID",
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  SLUG = "SLUG",
  /** Identify a resource by the URI. */
  URI = "URI",
}

export const scalarsEnumsHash: import("gqty").ScalarsEnumsHash = {
  AddressFieldCountryEnum: true,
  AddressFieldTypeEnum: true,
  AmPmEnum: true,
  AvatarRatingEnum: true,
  BlockAttributesArray: true,
  BlockAttributesObject: true,
  BlockEditorPreviewIdType: true,
  Boolean: true,
  CaptchaFieldBadgePositionEnum: true,
  CaptchaFieldThemeEnum: true,
  CaptchaFieldTypeEnum: true,
  CategoryIdType: true,
  CommentsConnectionOrderbyEnum: true,
  ConditionalLogicActionTypeEnum: true,
  ConditionalLogicLogicTypeEnum: true,
  ContentNodeIdTypeEnum: true,
  ContentTypeEnum: true,
  ContentTypeIdTypeEnum: true,
  ContentTypesOfCategoryEnum: true,
  ContentTypesOfPostFormatEnum: true,
  ContentTypesOfTagEnum: true,
  DateFieldFormatEnum: true,
  DateFieldTypeEnum: true,
  DraftEntryIdTypeEnum: true,
  EntryIdTypeEnum: true,
  EntryStatusEnum: true,
  EntryTypeEnum: true,
  FieldFiltersModeEnum: true,
  FieldFiltersOperatorEnum: true,
  Float: true,
  FormButtonTypeEnum: true,
  FormConfirmationTypeEnum: true,
  FormDescriptionPlacementEnum: true,
  FormFieldCalendarIconTypeEnum: true,
  FormFieldDescriptionPlacementEnum: true,
  FormFieldLabelPlacementEnum: true,
  FormFieldRequiredIndicatorEnum: true,
  FormFieldSizeEnum: true,
  FormFieldSubLabelPlacementEnum: true,
  FormFieldTypeEnum: true,
  FormFieldVisibilityEnum: true,
  FormIdTypeEnum: true,
  FormLabelPlacementEnum: true,
  FormLimitEntriesPeriodEnum: true,
  FormNotificationToTypeEnum: true,
  FormPageProgressStyleEnum: true,
  FormPageProgressTypeEnum: true,
  FormRetentionPolicyEnum: true,
  FormRuleOperatorEnum: true,
  FormStatusEnum: true,
  FormSubLabelPlacementEnum: true,
  FormSubmitButtonLocationEnum: true,
  FormSubmitButtonWidthEnum: true,
  GfCurrencyEnum: true,
  ID: true,
  Int: true,
  JobIdType: true,
  MediaItemIdType: true,
  MediaItemSizeEnum: true,
  MediaItemStatusEnum: true,
  MenuItemNodeIdTypeEnum: true,
  MenuLocationEnum: true,
  MenuNodeIdTypeEnum: true,
  MimeTypeEnum: true,
  NumberFieldFormatEnum: true,
  OrderEnum: true,
  PageIdType: true,
  PasswordFieldMinStrengthEnum: true,
  PhoneFieldFormatEnum: true,
  PluginStatusEnum: true,
  PostFormatIdType: true,
  PostFormatTypeEnum: true,
  PostIdType: true,
  PostObjectFieldFormatEnum: true,
  PostObjectsConnectionDateColumnEnum: true,
  PostObjectsConnectionOrderbyEnum: true,
  PostStatusEnum: true,
  RelationEnum: true,
  ReusableBlockIdType: true,
  SEOCardType: true,
  ServiceIdType: true,
  SpecialtyIdType: true,
  String: true,
  SubmittedEntryIdTypeEnum: true,
  TagIdType: true,
  TaxonomyEnum: true,
  TaxonomyIdTypeEnum: true,
  Team_memberIdType: true,
  TermNodeIdTypeEnum: true,
  TermObjectsConnectionOrderbyEnum: true,
  TimeFieldFormatEnum: true,
  UserNodeIdTypeEnum: true,
  UserRoleEnum: true,
  UsersConnectionOrderbyEnum: true,
  UsersConnectionSearchColumnEnum: true,
  WorkIdType: true,
};
export const generatedSchema = {
  AcfFieldGroup: {
    __typename: { __type: "String!" },
    fieldGroupName: { __type: "String" },
    $on: { __type: "$AcfFieldGroup!" },
  },
  AddressField: {
    __typename: { __type: "String!" },
    addressType: { __type: "AddressFieldTypeEnum" },
    addressValues: { __type: "AddressFieldValue" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    copyValuesOptionFieldId: { __type: "Int" },
    copyValuesOptionLabel: { __type: "String" },
    cssClass: { __type: "String" },
    defaultCountry: { __type: "AddressFieldCountryEnum" },
    defaultProvince: { __type: "String" },
    defaultState: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasAutocomplete: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    inputs: { __type: "[AddressInputProperty]" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    shouldCopyValuesOption: { __type: "Boolean" },
    subLabelPlacement: { __type: "FormFieldSubLabelPlacementEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  AddressFieldInput: {
    city: { __type: "String" },
    country: { __type: "AddressFieldCountryEnum" },
    lineTwo: { __type: "String" },
    state: { __type: "String" },
    street: { __type: "String" },
    zip: { __type: "String" },
  },
  AddressFieldValue: {
    __typename: { __type: "String!" },
    city: { __type: "String" },
    country: { __type: "AddressFieldCountryEnum" },
    lineTwo: { __type: "String" },
    state: { __type: "String" },
    street: { __type: "String" },
    zip: { __type: "String" },
  },
  AddressInputProperty: {
    __typename: { __type: "String!" },
    autocompleteAttribute: { __type: "String" },
    customLabel: { __type: "String" },
    defaultValue: { __type: "String" },
    id: { __type: "Float" },
    isHidden: { __type: "Boolean" },
    key: { __type: "String" },
    label: { __type: "String" },
    name: { __type: "String" },
    placeholder: { __type: "String" },
  },
  Avatar: {
    __typename: { __type: "String!" },
    default: { __type: "String" },
    extraAttr: { __type: "String" },
    forceDefault: { __type: "Boolean" },
    foundAvatar: { __type: "Boolean" },
    height: { __type: "Int" },
    isRestricted: { __type: "Boolean" },
    rating: { __type: "String" },
    scheme: { __type: "String" },
    size: { __type: "Int" },
    url: { __type: "String" },
    width: { __type: "Int" },
  },
  Block: {
    __typename: { __type: "String!" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
    $on: { __type: "$Block!" },
  },
  BlockEditorContentNode: {
    __typename: { __type: "String!" },
    blocks: { __type: "[Block!]" },
    blocksJSON: { __type: "String" },
    id: { __type: "ID!" },
    previewBlocks: { __type: "[Block!]" },
    previewBlocksJSON: { __type: "String" },
    $on: { __type: "$BlockEditorContentNode!" },
  },
  BlockEditorContentNodeConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[BlockEditorContentNodeConnectionEdge]" },
    nodes: { __type: "[BlockEditorContentNode]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  BlockEditorContentNodeConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "BlockEditorContentNode" },
  },
  BlockEditorContentNodeConnectionWhereArgs: {
    contentTypes: { __type: "[ContentTypeEnum]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  BlockEditorPreview: {
    __typename: { __type: "String!" },
    author: { __type: "NodeWithAuthorToUserConnectionEdge" },
    authorDatabaseId: { __type: "Int" },
    authorId: { __type: "ID" },
    blockEditorPreviewId: { __type: "Int!" },
    blocks: { __type: "[Block!]" },
    blocksJSON: { __type: "String" },
    conditionalTags: { __type: "ConditionalTags" },
    content: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    contentType: { __type: "ContentNodeToContentTypeConnectionEdge" },
    contentTypeName: { __type: "String!" },
    databaseId: { __type: "Int!" },
    date: { __type: "String" },
    dateGmt: { __type: "String" },
    desiredSlug: { __type: "String" },
    editingLockedBy: { __type: "ContentNodeToEditLockConnectionEdge" },
    enclosure: { __type: "String" },
    enqueuedScripts: {
      __type: "ContentNodeToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    enqueuedStylesheets: {
      __type: "ContentNodeToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    guid: { __type: "String" },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isPreview: { __type: "Boolean" },
    isRestricted: { __type: "Boolean" },
    isTermNode: { __type: "Boolean!" },
    lastEditedBy: { __type: "ContentNodeToEditLastConnectionEdge" },
    lastUpdateTime: { __type: "String" },
    link: { __type: "String" },
    modified: { __type: "String" },
    modifiedGmt: { __type: "String" },
    preview: { __type: "BlockEditorPreviewToPreviewConnectionEdge" },
    previewRevisionDatabaseId: { __type: "Int" },
    previewRevisionId: { __type: "ID" },
    previewed: { __type: "BlockEditorContentNode" },
    previewedDatabaseId: { __type: "Int" },
    previewedParentDatabaseId: { __type: "Int" },
    seo: { __type: "PostTypeSEO" },
    slug: { __type: "String" },
    status: { __type: "String" },
    template: { __type: "ContentTemplate" },
    templates: { __type: "[String]" },
    title: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    uri: { __type: "String" },
  },
  BlockEditorPreviewToPreviewConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "BlockEditorPreview" },
  },
  CaptchaField: {
    __typename: { __type: "String!" },
    captchaBadgePosition: { __type: "CaptchaFieldBadgePositionEnum" },
    captchaLanguage: { __type: "String" },
    captchaTheme: { __type: "CaptchaFieldThemeEnum" },
    captchaType: { __type: "CaptchaFieldTypeEnum" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    id: { __type: "Int!" },
    inputType: { __type: "FormFieldTypeEnum" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    simpleCaptchaBackgroundColor: { __type: "String" },
    simpleCaptchaFontColor: { __type: "String" },
    simpleCaptchaSize: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  Category: {
    __typename: { __type: "String!" },
    ancestors: {
      __type: "CategoryToAncestorsCategoryConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    categoryId: { __type: "Int" },
    children: {
      __type: "CategoryToCategoryConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "CategoryToCategoryConnectionWhereArgs",
      },
    },
    conditionalTags: { __type: "ConditionalTags" },
    contentNodes: {
      __type: "CategoryToContentNodeConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "CategoryToContentNodeConnectionWhereArgs",
      },
    },
    count: { __type: "Int" },
    databaseId: { __type: "Int!" },
    description: { __type: "String" },
    enqueuedScripts: {
      __type: "TermNodeToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    enqueuedStylesheets: {
      __type: "TermNodeToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isRestricted: { __type: "Boolean" },
    isTermNode: { __type: "Boolean!" },
    link: { __type: "String" },
    name: { __type: "String" },
    parent: { __type: "CategoryToParentCategoryConnectionEdge" },
    parentDatabaseId: { __type: "Int" },
    parentId: { __type: "ID" },
    posts: {
      __type: "CategoryToPostConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "CategoryToPostConnectionWhereArgs",
      },
    },
    seo: { __type: "TaxonomySEO" },
    slug: { __type: "String" },
    taxonomy: { __type: "CategoryToTaxonomyConnectionEdge" },
    taxonomyName: { __type: "String" },
    templates: { __type: "[String]" },
    termGroupId: { __type: "Int" },
    termTaxonomyId: { __type: "Int" },
    uri: { __type: "String" },
  },
  CategoryToAncestorsCategoryConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[CategoryToAncestorsCategoryConnectionEdge]" },
    nodes: { __type: "[Category]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  CategoryToAncestorsCategoryConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Category" },
  },
  CategoryToCategoryConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[CategoryToCategoryConnectionEdge]" },
    nodes: { __type: "[Category]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  CategoryToCategoryConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Category" },
  },
  CategoryToCategoryConnectionWhereArgs: {
    cacheDomain: { __type: "String" },
    childOf: { __type: "Int" },
    childless: { __type: "Boolean" },
    descriptionLike: { __type: "String" },
    exclude: { __type: "[ID]" },
    excludeTree: { __type: "[ID]" },
    hideEmpty: { __type: "Boolean" },
    hierarchical: { __type: "Boolean" },
    include: { __type: "[ID]" },
    name: { __type: "[String]" },
    nameLike: { __type: "String" },
    objectIds: { __type: "[ID]" },
    order: { __type: "OrderEnum" },
    orderby: { __type: "TermObjectsConnectionOrderbyEnum" },
    padCounts: { __type: "Boolean" },
    parent: { __type: "Int" },
    search: { __type: "String" },
    slug: { __type: "[String]" },
    termTaxonomId: { __type: "[ID]" },
    updateTermMetaCache: { __type: "Boolean" },
  },
  CategoryToContentNodeConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[CategoryToContentNodeConnectionEdge]" },
    nodes: { __type: "[ContentNode]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  CategoryToContentNodeConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "ContentNode" },
  },
  CategoryToContentNodeConnectionWhereArgs: {
    contentTypes: { __type: "[ContentTypesOfCategoryEnum]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  CategoryToParentCategoryConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "Category" },
  },
  CategoryToPostConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[CategoryToPostConnectionEdge]" },
    nodes: { __type: "[Post]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  CategoryToPostConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Post" },
  },
  CategoryToPostConnectionWhereArgs: {
    author: { __type: "Int" },
    authorIn: { __type: "[ID]" },
    authorName: { __type: "String" },
    authorNotIn: { __type: "[ID]" },
    categoryId: { __type: "Int" },
    categoryIn: { __type: "[ID]" },
    categoryName: { __type: "String" },
    categoryNotIn: { __type: "[ID]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    tag: { __type: "String" },
    tagId: { __type: "String" },
    tagIn: { __type: "[ID]" },
    tagNotIn: { __type: "[ID]" },
    tagSlugAnd: { __type: "[String]" },
    tagSlugIn: { __type: "[String]" },
    title: { __type: "String" },
  },
  CategoryToTaxonomyConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "Taxonomy" },
  },
  CheckboxField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    checkboxValues: { __type: "[CheckboxFieldValue]" },
    choices: { __type: "[CheckboxFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasChoiceValue: { __type: "Boolean" },
    hasSelectAll: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    inputs: { __type: "[CheckboxInputProperty]" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  CheckboxFieldChoice: {
    __typename: { __type: "String!" },
    isSelected: { __type: "Boolean" },
    text: { __type: "String" },
    value: { __type: "String" },
  },
  CheckboxFieldInput: {
    inputId: { __type: "Float" },
    value: { __type: "String" },
  },
  CheckboxFieldValue: {
    __typename: { __type: "String!" },
    inputId: { __type: "Float" },
    text: { __type: "String" },
    value: { __type: "String" },
  },
  CheckboxInputProperty: {
    __typename: { __type: "String!" },
    id: { __type: "Float" },
    label: { __type: "String" },
    name: { __type: "String" },
  },
  Comment: {
    __typename: { __type: "String!" },
    agent: { __type: "String" },
    approved: { __type: "Boolean" },
    author: { __type: "CommentToCommenterConnectionEdge" },
    authorIp: { __type: "String" },
    commentId: { __type: "Int" },
    commentedOn: { __type: "CommentToContentNodeConnectionEdge" },
    content: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    databaseId: { __type: "Int!" },
    date: { __type: "String" },
    dateGmt: { __type: "String" },
    id: { __type: "ID!" },
    isRestricted: { __type: "Boolean" },
    karma: { __type: "Int" },
    parent: {
      __type: "CommentToParentCommentConnectionEdge",
      __args: { where: "CommentToParentCommentConnectionWhereArgs" },
    },
    parentDatabaseId: { __type: "Int" },
    parentId: { __type: "ID" },
    replies: {
      __type: "CommentToCommentConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "CommentToCommentConnectionWhereArgs",
      },
    },
    type: { __type: "String" },
  },
  CommentAuthor: {
    __typename: { __type: "String!" },
    avatar: {
      __type: "Avatar",
      __args: {
        forceDefault: "Boolean",
        rating: "AvatarRatingEnum",
        size: "Int",
      },
    },
    databaseId: { __type: "Int!" },
    email: { __type: "String" },
    id: { __type: "ID!" },
    isRestricted: { __type: "Boolean" },
    name: { __type: "String" },
    url: { __type: "String" },
  },
  CommentToCommentConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[CommentToCommentConnectionEdge]" },
    nodes: { __type: "[Comment]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  CommentToCommentConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Comment" },
  },
  CommentToCommentConnectionWhereArgs: {
    authorEmail: { __type: "String" },
    authorIn: { __type: "[ID]" },
    authorNotIn: { __type: "[ID]" },
    authorUrl: { __type: "String" },
    commentIn: { __type: "[ID]" },
    commentNotIn: { __type: "[ID]" },
    commentType: { __type: "String" },
    commentTypeIn: { __type: "[String]" },
    commentTypeNotIn: { __type: "String" },
    contentAuthor: { __type: "[ID]" },
    contentAuthorIn: { __type: "[ID]" },
    contentAuthorNotIn: { __type: "[ID]" },
    contentId: { __type: "ID" },
    contentIdIn: { __type: "[ID]" },
    contentIdNotIn: { __type: "[ID]" },
    contentName: { __type: "String" },
    contentParent: { __type: "Int" },
    contentStatus: { __type: "[PostStatusEnum]" },
    contentType: { __type: "[ContentTypeEnum]" },
    includeUnapproved: { __type: "[ID]" },
    karma: { __type: "Int" },
    order: { __type: "OrderEnum" },
    orderby: { __type: "CommentsConnectionOrderbyEnum" },
    parent: { __type: "Int" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    search: { __type: "String" },
    status: { __type: "String" },
    userId: { __type: "ID" },
  },
  CommentToCommenterConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "Commenter" },
  },
  CommentToContentNodeConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "ContentNode" },
  },
  CommentToParentCommentConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "Comment" },
  },
  CommentToParentCommentConnectionWhereArgs: {
    authorEmail: { __type: "String" },
    authorIn: { __type: "[ID]" },
    authorNotIn: { __type: "[ID]" },
    authorUrl: { __type: "String" },
    commentIn: { __type: "[ID]" },
    commentNotIn: { __type: "[ID]" },
    commentType: { __type: "String" },
    commentTypeIn: { __type: "[String]" },
    commentTypeNotIn: { __type: "String" },
    contentAuthor: { __type: "[ID]" },
    contentAuthorIn: { __type: "[ID]" },
    contentAuthorNotIn: { __type: "[ID]" },
    contentId: { __type: "ID" },
    contentIdIn: { __type: "[ID]" },
    contentIdNotIn: { __type: "[ID]" },
    contentName: { __type: "String" },
    contentParent: { __type: "Int" },
    contentStatus: { __type: "[PostStatusEnum]" },
    contentType: { __type: "[ContentTypeEnum]" },
    includeUnapproved: { __type: "[ID]" },
    karma: { __type: "Int" },
    order: { __type: "OrderEnum" },
    orderby: { __type: "CommentsConnectionOrderbyEnum" },
    parent: { __type: "Int" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    search: { __type: "String" },
    status: { __type: "String" },
    userId: { __type: "ID" },
  },
  Commenter: {
    __typename: { __type: "String!" },
    avatar: { __type: "Avatar" },
    databaseId: { __type: "Int!" },
    email: { __type: "String" },
    id: { __type: "ID!" },
    isRestricted: { __type: "Boolean" },
    name: { __type: "String" },
    url: { __type: "String" },
    $on: { __type: "$Commenter!" },
  },
  ConditionalLogic: {
    __typename: { __type: "String!" },
    actionType: { __type: "ConditionalLogicActionTypeEnum" },
    logicType: { __type: "ConditionalLogicLogicTypeEnum" },
    rules: { __type: "[ConditionalLogicRule]" },
  },
  ConditionalLogicRule: {
    __typename: { __type: "String!" },
    fieldId: { __type: "Float" },
    operator: { __type: "FormRuleOperatorEnum" },
    value: { __type: "String" },
  },
  ConditionalTags: {
    __typename: { __type: "String!" },
    isArchive: { __type: "Boolean" },
    isAttachment: { __type: "Boolean" },
    isAuthor: { __type: "Boolean" },
    isCategory: { __type: "Boolean" },
    isDate: { __type: "Boolean" },
    isDay: { __type: "Boolean" },
    isFrontPage: { __type: "Boolean" },
    isHome: { __type: "Boolean" },
    isMonth: { __type: "Boolean" },
    isMultiAuthor: { __type: "Boolean" },
    isPage: { __type: "Boolean" },
    isPageTemplate: { __type: "Boolean" },
    isPostTypeArchive: { __type: "Boolean" },
    isPreview: { __type: "Boolean" },
    isPrivacyPolicy: { __type: "Boolean" },
    isSearch: { __type: "Boolean" },
    isSingle: { __type: "Boolean" },
    isSingular: { __type: "Boolean" },
    isSticky: { __type: "Boolean" },
    isTag: { __type: "Boolean" },
    isTax: { __type: "Boolean" },
    isYear: { __type: "Boolean" },
  },
  ConsentField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    checkboxLabel: { __type: "String" },
    conditionalLogic: { __type: "ConditionalLogic" },
    consentValue: { __type: "Boolean" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    id: { __type: "Int!" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  ContentNode: {
    __typename: { __type: "String!" },
    conditionalTags: { __type: "ConditionalTags" },
    contentType: { __type: "ContentNodeToContentTypeConnectionEdge" },
    contentTypeName: { __type: "String!" },
    databaseId: { __type: "Int!" },
    date: { __type: "String" },
    dateGmt: { __type: "String" },
    desiredSlug: { __type: "String" },
    editingLockedBy: { __type: "ContentNodeToEditLockConnectionEdge" },
    enclosure: { __type: "String" },
    enqueuedScripts: {
      __type: "ContentNodeToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    enqueuedStylesheets: {
      __type: "ContentNodeToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    guid: { __type: "String" },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isPreview: { __type: "Boolean" },
    isRestricted: { __type: "Boolean" },
    isTermNode: { __type: "Boolean!" },
    lastEditedBy: { __type: "ContentNodeToEditLastConnectionEdge" },
    link: { __type: "String" },
    modified: { __type: "String" },
    modifiedGmt: { __type: "String" },
    previewRevisionDatabaseId: { __type: "Int" },
    previewRevisionId: { __type: "ID" },
    seo: { __type: "PostTypeSEO" },
    slug: { __type: "String" },
    status: { __type: "String" },
    template: { __type: "ContentTemplate" },
    templates: { __type: "[String]" },
    uri: { __type: "String" },
    $on: { __type: "$ContentNode!" },
  },
  ContentNodeToContentTypeConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "ContentType" },
  },
  ContentNodeToEditLastConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "User" },
  },
  ContentNodeToEditLockConnectionEdge: {
    __typename: { __type: "String!" },
    lockTimestamp: { __type: "String" },
    node: { __type: "User" },
  },
  ContentNodeToEnqueuedScriptConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[ContentNodeToEnqueuedScriptConnectionEdge]" },
    nodes: { __type: "[EnqueuedScript]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  ContentNodeToEnqueuedScriptConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "EnqueuedScript" },
  },
  ContentNodeToEnqueuedStylesheetConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[ContentNodeToEnqueuedStylesheetConnectionEdge]" },
    nodes: { __type: "[EnqueuedStylesheet]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  ContentNodeToEnqueuedStylesheetConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "EnqueuedStylesheet" },
  },
  ContentRevisionUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$ContentRevisionUnion!" },
  },
  ContentTemplate: {
    __typename: { __type: "String!" },
    templateName: { __type: "String" },
    $on: { __type: "$ContentTemplate!" },
  },
  ContentType: {
    __typename: { __type: "String!" },
    canExport: { __type: "Boolean" },
    conditionalTags: { __type: "ConditionalTags" },
    connectedTaxonomies: {
      __type: "ContentTypeToTaxonomyConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    contentNodes: {
      __type: "ContentTypeToContentNodeConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "ContentTypeToContentNodeConnectionWhereArgs",
      },
    },
    deleteWithUser: { __type: "Boolean" },
    description: { __type: "String" },
    excludeFromSearch: { __type: "Boolean" },
    graphqlPluralName: { __type: "String" },
    graphqlSingleName: { __type: "String" },
    hasArchive: { __type: "Boolean" },
    hierarchical: { __type: "Boolean" },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isFrontPage: { __type: "Boolean!" },
    isPostsPage: { __type: "Boolean!" },
    isRestricted: { __type: "Boolean" },
    isTermNode: { __type: "Boolean!" },
    label: { __type: "String" },
    labels: { __type: "PostTypeLabelDetails" },
    menuIcon: { __type: "String" },
    menuPosition: { __type: "Int" },
    name: { __type: "String" },
    public: { __type: "Boolean" },
    publiclyQueryable: { __type: "Boolean" },
    restBase: { __type: "String" },
    restControllerClass: { __type: "String" },
    showInAdminBar: { __type: "Boolean" },
    showInGraphql: { __type: "Boolean" },
    showInMenu: { __type: "Boolean" },
    showInNavMenus: { __type: "Boolean" },
    showInRest: { __type: "Boolean" },
    showUi: { __type: "Boolean" },
    templates: { __type: "[String]" },
    uri: { __type: "String" },
  },
  ContentTypeToContentNodeConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[ContentTypeToContentNodeConnectionEdge]" },
    nodes: { __type: "[ContentNode]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  ContentTypeToContentNodeConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "ContentNode" },
  },
  ContentTypeToContentNodeConnectionWhereArgs: {
    contentTypes: { __type: "[ContentTypeEnum]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  ContentTypeToTaxonomyConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[ContentTypeToTaxonomyConnectionEdge]" },
    nodes: { __type: "[Taxonomy]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  ContentTypeToTaxonomyConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Taxonomy" },
  },
  CoreArchivesBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreArchivesBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreArchivesBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    className: { __type: "String" },
    displayAsDropdown: { __type: "Boolean!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    showLabel: { __type: "Boolean!" },
    showPostCounts: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    type: { __type: "String!" },
  },
  CoreAudioBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreAudioBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreAudioBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    autoplay: { __type: "Boolean" },
    caption: { __type: "String" },
    className: { __type: "String" },
    id: { __type: "Float" },
    lock: { __type: "BlockAttributesObject" },
    loop: { __type: "Boolean" },
    preload: { __type: "String" },
    src: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
  },
  CoreAudioBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreAudioBlockAttributesUnion!" },
  },
  CoreAudioBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    autoplay: { __type: "Boolean" },
    caption: { __type: "String" },
    className: { __type: "String" },
    id: { __type: "Float" },
    lock: { __type: "BlockAttributesObject" },
    loop: { __type: "Boolean" },
    preload: { __type: "String" },
    src: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
  },
  CoreAvatarBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreAvatarBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreAvatarBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    isLink: { __type: "Boolean!" },
    linkTarget: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    size: { __type: "Float!" },
    style: { __type: "BlockAttributesObject" },
    userId: { __type: "Float" },
  },
  CoreBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    reusableBlock: { __type: "Node!" },
    saveContent: { __type: "String" },
  },
  CoreBlockAttributes: {
    __typename: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    ref: { __type: "Float" },
  },
  CoreButtonBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreButtonBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreButtonBlockAttributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    text: { __type: "String" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreButtonBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreButtonBlockAttributesUnion!" },
  },
  CoreButtonBlockDeprecatedV10Attributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    text: { __type: "String" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreButtonBlockDeprecatedV11Attributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    text: { __type: "String" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreButtonBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    text: { __type: "String" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreButtonBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    text: { __type: "String" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreButtonBlockDeprecatedV3Attributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    text: { __type: "String" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreButtonBlockDeprecatedV4Attributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    text: { __type: "String" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreButtonBlockDeprecatedV5Attributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    text: { __type: "String" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreButtonBlockDeprecatedV6Attributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    text: { __type: "String" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreButtonBlockDeprecatedV7Attributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    text: { __type: "String" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreButtonBlockDeprecatedV8Attributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    text: { __type: "String" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreButtonBlockDeprecatedV9Attributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    text: { __type: "String" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreButtonsBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreButtonsBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreButtonsBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
  },
  CoreButtonsBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreButtonsBlockAttributesUnion!" },
  },
  CoreButtonsBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
  },
  CoreButtonsBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
  },
  CoreCalendarBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreCalendarBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreCalendarBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    month: { __type: "Int" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    year: { __type: "Int" },
  },
  CoreCategoriesBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreCategoriesBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreCategoriesBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    className: { __type: "String" },
    displayAsDropdown: { __type: "Boolean!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    showEmpty: { __type: "Boolean!" },
    showHierarchy: { __type: "Boolean!" },
    showOnlyTopLevel: { __type: "Boolean!" },
    showPostCounts: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
  },
  CoreCodeBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreCodeBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreCodeBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreColumnBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreColumnBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreColumnBlockAttributes: {
    __typename: { __type: "String!" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    verticalAlignment: { __type: "String" },
    width: { __type: "String" },
  },
  CoreColumnBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreColumnBlockAttributesUnion!" },
  },
  CoreColumnBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    verticalAlignment: { __type: "String" },
    width: { __type: "String" },
  },
  CoreColumnsBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreColumnsBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreColumnsBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    isStackedOnMobile: { __type: "Boolean!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    verticalAlignment: { __type: "String" },
  },
  CoreColumnsBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreColumnsBlockAttributesUnion!" },
  },
  CoreColumnsBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    isStackedOnMobile: { __type: "Boolean!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    verticalAlignment: { __type: "String" },
  },
  CoreColumnsBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    isStackedOnMobile: { __type: "Boolean!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    verticalAlignment: { __type: "String" },
  },
  CoreColumnsBlockDeprecatedV3Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    isStackedOnMobile: { __type: "Boolean!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    verticalAlignment: { __type: "String" },
  },
  CoreCommentAuthorNameBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreCommentAuthorNameBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreCommentAuthorNameBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    isLink: { __type: "Boolean!" },
    linkTarget: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreCommentAuthorNameBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreCommentAuthorNameBlockAttributesUnion!" },
  },
  CoreCommentAuthorNameBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    isLink: { __type: "Boolean!" },
    linkTarget: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreCommentContentBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreCommentContentBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreCommentContentBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreCommentDateBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreCommentDateBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreCommentDateBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    format: { __type: "String" },
    gradient: { __type: "String" },
    isLink: { __type: "Boolean!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreCommentDateBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreCommentDateBlockAttributesUnion!" },
  },
  CoreCommentDateBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    format: { __type: "String" },
    gradient: { __type: "String" },
    isLink: { __type: "Boolean!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreCommentEditLinkBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreCommentEditLinkBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreCommentEditLinkBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    linkTarget: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreCommentReplyLinkBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreCommentReplyLinkBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreCommentReplyLinkBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreCommentTemplateBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreCommentTemplateBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreCommentTemplateBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
  },
  CoreCommentsBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreCommentsBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreCommentsBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    legacy: { __type: "Boolean!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
  },
  CoreCommentsBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreCommentsBlockAttributesUnion!" },
  },
  CoreCommentsBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    legacy: { __type: "Boolean!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
  },
  CoreCommentsPaginationBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreCommentsPaginationBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreCommentsPaginationBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    paginationArrow: { __type: "String!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreCommentsPaginationNextBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreCommentsPaginationNextBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreCommentsPaginationNextBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    label: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreCommentsPaginationNumbersBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreCommentsPaginationNumbersBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreCommentsPaginationNumbersBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreCommentsPaginationPreviousBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreCommentsPaginationPreviousBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreCommentsPaginationPreviousBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    label: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreCommentsTitleBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreCommentsTitleBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreCommentsTitleBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    level: { __type: "Float!" },
    lock: { __type: "BlockAttributesObject" },
    showCommentsCount: { __type: "Boolean!" },
    showPostTitle: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreCommentsTitleBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreCommentsTitleBlockAttributesUnion!" },
  },
  CoreCommentsTitleBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    level: { __type: "Float!" },
    lock: { __type: "BlockAttributesObject" },
    showCommentsCount: { __type: "Boolean!" },
    showPostTitle: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreCoverBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreCoverBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreCoverBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    backgroundType: { __type: "String!" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    contentPosition: { __type: "String" },
    customGradient: { __type: "String" },
    customOverlayColor: { __type: "String" },
    dimRatio: { __type: "Float!" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    hasParallax: { __type: "Boolean!" },
    id: { __type: "Float" },
    isDark: { __type: "Boolean!" },
    isRepeated: { __type: "Boolean!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    minHeight: { __type: "Float" },
    minHeightUnit: { __type: "String" },
    overlayColor: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
    url: { __type: "String" },
    useFeaturedImage: { __type: "Boolean!" },
  },
  CoreCoverBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreCoverBlockAttributesUnion!" },
  },
  CoreCoverBlockDeprecatedV10Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    backgroundType: { __type: "String!" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    contentPosition: { __type: "String" },
    customGradient: { __type: "String" },
    customOverlayColor: { __type: "String" },
    dimRatio: { __type: "Float!" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    hasParallax: { __type: "Boolean!" },
    id: { __type: "Float" },
    isDark: { __type: "Boolean!" },
    isRepeated: { __type: "Boolean!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    minHeight: { __type: "Float" },
    minHeightUnit: { __type: "String" },
    overlayColor: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
    url: { __type: "String" },
    useFeaturedImage: { __type: "Boolean!" },
  },
  CoreCoverBlockDeprecatedV11Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    backgroundType: { __type: "String!" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    contentPosition: { __type: "String" },
    customGradient: { __type: "String" },
    customOverlayColor: { __type: "String" },
    dimRatio: { __type: "Float!" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    hasParallax: { __type: "Boolean!" },
    id: { __type: "Float" },
    isDark: { __type: "Boolean!" },
    isRepeated: { __type: "Boolean!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    minHeight: { __type: "Float" },
    minHeightUnit: { __type: "String" },
    overlayColor: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
    url: { __type: "String" },
    useFeaturedImage: { __type: "Boolean!" },
  },
  CoreCoverBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    backgroundType: { __type: "String!" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    contentPosition: { __type: "String" },
    customGradient: { __type: "String" },
    customOverlayColor: { __type: "String" },
    dimRatio: { __type: "Float!" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    hasParallax: { __type: "Boolean!" },
    id: { __type: "Float" },
    isDark: { __type: "Boolean!" },
    isRepeated: { __type: "Boolean!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    minHeight: { __type: "Float" },
    minHeightUnit: { __type: "String" },
    overlayColor: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
    url: { __type: "String" },
    useFeaturedImage: { __type: "Boolean!" },
  },
  CoreCoverBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    backgroundType: { __type: "String!" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    contentPosition: { __type: "String" },
    customGradient: { __type: "String" },
    customOverlayColor: { __type: "String" },
    dimRatio: { __type: "Float!" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    hasParallax: { __type: "Boolean!" },
    id: { __type: "Float" },
    isDark: { __type: "Boolean!" },
    isRepeated: { __type: "Boolean!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    minHeight: { __type: "Float" },
    minHeightUnit: { __type: "String" },
    overlayColor: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
    url: { __type: "String" },
    useFeaturedImage: { __type: "Boolean!" },
  },
  CoreCoverBlockDeprecatedV3Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    backgroundType: { __type: "String!" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    contentPosition: { __type: "String" },
    customGradient: { __type: "String" },
    customOverlayColor: { __type: "String" },
    dimRatio: { __type: "Float!" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    hasParallax: { __type: "Boolean!" },
    id: { __type: "Float" },
    isDark: { __type: "Boolean!" },
    isRepeated: { __type: "Boolean!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    minHeight: { __type: "Float" },
    minHeightUnit: { __type: "String" },
    overlayColor: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
    url: { __type: "String" },
    useFeaturedImage: { __type: "Boolean!" },
  },
  CoreCoverBlockDeprecatedV4Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    backgroundType: { __type: "String!" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    contentPosition: { __type: "String" },
    customGradient: { __type: "String" },
    customOverlayColor: { __type: "String" },
    dimRatio: { __type: "Float!" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    hasParallax: { __type: "Boolean!" },
    id: { __type: "Float" },
    isDark: { __type: "Boolean!" },
    isRepeated: { __type: "Boolean!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    minHeight: { __type: "Float" },
    minHeightUnit: { __type: "String" },
    overlayColor: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
    url: { __type: "String" },
    useFeaturedImage: { __type: "Boolean!" },
  },
  CoreCoverBlockDeprecatedV5Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    backgroundType: { __type: "String!" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    contentPosition: { __type: "String" },
    customGradient: { __type: "String" },
    customOverlayColor: { __type: "String" },
    dimRatio: { __type: "Float!" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    hasParallax: { __type: "Boolean!" },
    id: { __type: "Float" },
    isDark: { __type: "Boolean!" },
    isRepeated: { __type: "Boolean!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    minHeight: { __type: "Float" },
    minHeightUnit: { __type: "String" },
    overlayColor: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
    url: { __type: "String" },
    useFeaturedImage: { __type: "Boolean!" },
  },
  CoreCoverBlockDeprecatedV6Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    backgroundType: { __type: "String!" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    contentPosition: { __type: "String" },
    customGradient: { __type: "String" },
    customOverlayColor: { __type: "String" },
    dimRatio: { __type: "Float!" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    hasParallax: { __type: "Boolean!" },
    id: { __type: "Float" },
    isDark: { __type: "Boolean!" },
    isRepeated: { __type: "Boolean!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    minHeight: { __type: "Float" },
    minHeightUnit: { __type: "String" },
    overlayColor: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
    url: { __type: "String" },
    useFeaturedImage: { __type: "Boolean!" },
  },
  CoreCoverBlockDeprecatedV7Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    backgroundType: { __type: "String!" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    contentPosition: { __type: "String" },
    customGradient: { __type: "String" },
    customOverlayColor: { __type: "String" },
    dimRatio: { __type: "Float!" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    hasParallax: { __type: "Boolean!" },
    id: { __type: "Float" },
    isDark: { __type: "Boolean!" },
    isRepeated: { __type: "Boolean!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    minHeight: { __type: "Float" },
    minHeightUnit: { __type: "String" },
    overlayColor: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
    url: { __type: "String" },
    useFeaturedImage: { __type: "Boolean!" },
  },
  CoreCoverBlockDeprecatedV8Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    backgroundType: { __type: "String!" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    contentPosition: { __type: "String" },
    customGradient: { __type: "String" },
    customOverlayColor: { __type: "String" },
    dimRatio: { __type: "Float!" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    hasParallax: { __type: "Boolean!" },
    id: { __type: "Float" },
    isDark: { __type: "Boolean!" },
    isRepeated: { __type: "Boolean!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    minHeight: { __type: "Float" },
    minHeightUnit: { __type: "String" },
    overlayColor: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
    url: { __type: "String" },
    useFeaturedImage: { __type: "Boolean!" },
  },
  CoreCoverBlockDeprecatedV9Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    backgroundType: { __type: "String!" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    contentPosition: { __type: "String" },
    customGradient: { __type: "String" },
    customOverlayColor: { __type: "String" },
    dimRatio: { __type: "Float!" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    hasParallax: { __type: "Boolean!" },
    id: { __type: "Float" },
    isDark: { __type: "Boolean!" },
    isRepeated: { __type: "Boolean!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    minHeight: { __type: "Float" },
    minHeightUnit: { __type: "String" },
    overlayColor: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
    url: { __type: "String" },
    useFeaturedImage: { __type: "Boolean!" },
  },
  CoreDetailsBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreDetailsBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreDetailsBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    showContent: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    summary: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreEmbedBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreEmbedBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreEmbedBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowResponsive: { __type: "Boolean!" },
    caption: { __type: "String" },
    className: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    previewable: { __type: "Boolean!" },
    providerNameSlug: { __type: "String" },
    responsive: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    type: { __type: "String" },
    url: { __type: "String" },
  },
  CoreEmbedBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreEmbedBlockAttributesUnion!" },
  },
  CoreEmbedBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowResponsive: { __type: "Boolean!" },
    caption: { __type: "String" },
    className: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    previewable: { __type: "Boolean!" },
    providerNameSlug: { __type: "String" },
    responsive: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    type: { __type: "String" },
    url: { __type: "String" },
  },
  CoreEmbedBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowResponsive: { __type: "Boolean!" },
    caption: { __type: "String" },
    className: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    previewable: { __type: "Boolean!" },
    providerNameSlug: { __type: "String" },
    responsive: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    type: { __type: "String" },
    url: { __type: "String" },
  },
  CoreFileBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreFileBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreFileBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    displayPreview: { __type: "Boolean" },
    downloadButtonText: { __type: "String" },
    fileId: { __type: "String" },
    fileName: { __type: "String" },
    gradient: { __type: "String" },
    href: { __type: "String" },
    id: { __type: "Float" },
    lock: { __type: "BlockAttributesObject" },
    previewHeight: { __type: "Float!" },
    showDownloadButton: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    textLinkHref: { __type: "String" },
    textLinkTarget: { __type: "String" },
  },
  CoreFileBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreFileBlockAttributesUnion!" },
  },
  CoreFileBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    displayPreview: { __type: "Boolean" },
    downloadButtonText: { __type: "String" },
    fileId: { __type: "String" },
    fileName: { __type: "String" },
    gradient: { __type: "String" },
    href: { __type: "String" },
    id: { __type: "Float" },
    lock: { __type: "BlockAttributesObject" },
    previewHeight: { __type: "Float!" },
    showDownloadButton: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    textLinkHref: { __type: "String" },
    textLinkTarget: { __type: "String" },
  },
  CoreFileBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    displayPreview: { __type: "Boolean" },
    downloadButtonText: { __type: "String" },
    fileId: { __type: "String" },
    fileName: { __type: "String" },
    gradient: { __type: "String" },
    href: { __type: "String" },
    id: { __type: "Float" },
    lock: { __type: "BlockAttributesObject" },
    previewHeight: { __type: "Float!" },
    showDownloadButton: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    textLinkHref: { __type: "String" },
    textLinkTarget: { __type: "String" },
  },
  CoreFileBlockDeprecatedV3Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    displayPreview: { __type: "Boolean" },
    downloadButtonText: { __type: "String" },
    fileId: { __type: "String" },
    fileName: { __type: "String" },
    gradient: { __type: "String" },
    href: { __type: "String" },
    id: { __type: "Float" },
    lock: { __type: "BlockAttributesObject" },
    previewHeight: { __type: "Float!" },
    showDownloadButton: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    textLinkHref: { __type: "String" },
    textLinkTarget: { __type: "String" },
  },
  CoreFootnotesBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreFootnotesBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreFootnotesBlockAttributes: {
    __typename: { __type: "String!" },
    className: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
  },
  CoreFreeformBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreFreeformBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreFreeformBlockAttributes: {
    __typename: { __type: "String!" },
    content: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
  },
  CoreGalleryBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreGalleryBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreGalleryBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowResize: { __type: "Boolean!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    caption: { __type: "String" },
    className: { __type: "String" },
    columns: { __type: "Float" },
    fixedHeight: { __type: "Boolean!" },
    gradient: { __type: "String" },
    ids: { __type: "[Float]!" },
    imageCrop: { __type: "Boolean!" },
    images: { __type: "[CoreGalleryBlockAttributesImages]!" },
    layout: { __type: "BlockAttributesObject" },
    linkTarget: { __type: "String" },
    linkTo: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    shortCodeTransforms: { __type: "[BlockAttributesObject]!" },
    sizeSlug: { __type: "String!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreGalleryBlockAttributesImages: {
    __typename: { __type: "String!" },
    alt: { __type: "String!" },
    caption: { __type: "String" },
    fullUrl: { __type: "String" },
    id: { __type: "String" },
    link: { __type: "String" },
    url: { __type: "String" },
  },
  CoreGalleryBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreGalleryBlockAttributesUnion!" },
  },
  CoreGalleryBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowResize: { __type: "Boolean!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    caption: { __type: "String" },
    className: { __type: "String" },
    columns: { __type: "Float" },
    fixedHeight: { __type: "Boolean!" },
    gradient: { __type: "String" },
    ids: { __type: "[Float]!" },
    imageCrop: { __type: "Boolean!" },
    images: { __type: "[CoreGalleryBlockDeprecatedV1AttributesImages]!" },
    layout: { __type: "BlockAttributesObject" },
    linkTarget: { __type: "String" },
    linkTo: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    shortCodeTransforms: { __type: "[BlockAttributesObject]!" },
    sizeSlug: { __type: "String!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreGalleryBlockDeprecatedV1AttributesImages: {
    __typename: { __type: "String!" },
    alt: { __type: "String!" },
    caption: { __type: "String" },
    fullUrl: { __type: "String" },
    id: { __type: "String" },
    link: { __type: "String" },
    url: { __type: "String" },
  },
  CoreGalleryBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowResize: { __type: "Boolean!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    caption: { __type: "String" },
    className: { __type: "String" },
    columns: { __type: "Float" },
    fixedHeight: { __type: "Boolean!" },
    gradient: { __type: "String" },
    ids: { __type: "[Float]!" },
    imageCrop: { __type: "Boolean!" },
    images: { __type: "[CoreGalleryBlockDeprecatedV2AttributesImages]!" },
    layout: { __type: "BlockAttributesObject" },
    linkTarget: { __type: "String" },
    linkTo: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    shortCodeTransforms: { __type: "[BlockAttributesObject]!" },
    sizeSlug: { __type: "String!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreGalleryBlockDeprecatedV2AttributesImages: {
    __typename: { __type: "String!" },
    alt: { __type: "String!" },
    caption: { __type: "String" },
    fullUrl: { __type: "String" },
    id: { __type: "String" },
    link: { __type: "String" },
    url: { __type: "String" },
  },
  CoreGalleryBlockDeprecatedV3Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowResize: { __type: "Boolean!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    caption: { __type: "String" },
    className: { __type: "String" },
    columns: { __type: "Float" },
    fixedHeight: { __type: "Boolean!" },
    gradient: { __type: "String" },
    ids: { __type: "[Float]!" },
    imageCrop: { __type: "Boolean!" },
    images: { __type: "[CoreGalleryBlockDeprecatedV3AttributesImages]!" },
    layout: { __type: "BlockAttributesObject" },
    linkTarget: { __type: "String" },
    linkTo: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    shortCodeTransforms: { __type: "[BlockAttributesObject]!" },
    sizeSlug: { __type: "String!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreGalleryBlockDeprecatedV3AttributesImages: {
    __typename: { __type: "String!" },
    alt: { __type: "String!" },
    caption: { __type: "String" },
    fullUrl: { __type: "String" },
    id: { __type: "String" },
    link: { __type: "String" },
    url: { __type: "String" },
  },
  CoreGalleryBlockDeprecatedV4Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowResize: { __type: "Boolean!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    caption: { __type: "String" },
    className: { __type: "String" },
    columns: { __type: "Float" },
    fixedHeight: { __type: "Boolean!" },
    gradient: { __type: "String" },
    ids: { __type: "[Float]!" },
    imageCrop: { __type: "Boolean!" },
    images: { __type: "[CoreGalleryBlockDeprecatedV4AttributesImages]!" },
    layout: { __type: "BlockAttributesObject" },
    linkTarget: { __type: "String" },
    linkTo: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    shortCodeTransforms: { __type: "[BlockAttributesObject]!" },
    sizeSlug: { __type: "String!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreGalleryBlockDeprecatedV4AttributesImages: {
    __typename: { __type: "String!" },
    alt: { __type: "String!" },
    caption: { __type: "String" },
    fullUrl: { __type: "String" },
    id: { __type: "String" },
    link: { __type: "String" },
    url: { __type: "String" },
  },
  CoreGalleryBlockDeprecatedV5Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowResize: { __type: "Boolean!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    caption: { __type: "String" },
    className: { __type: "String" },
    columns: { __type: "Float" },
    fixedHeight: { __type: "Boolean!" },
    gradient: { __type: "String" },
    ids: { __type: "[Float]!" },
    imageCrop: { __type: "Boolean!" },
    images: { __type: "[CoreGalleryBlockDeprecatedV5AttributesImages]!" },
    layout: { __type: "BlockAttributesObject" },
    linkTarget: { __type: "String" },
    linkTo: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    shortCodeTransforms: { __type: "[BlockAttributesObject]!" },
    sizeSlug: { __type: "String!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreGalleryBlockDeprecatedV5AttributesImages: {
    __typename: { __type: "String!" },
    alt: { __type: "String!" },
    caption: { __type: "String" },
    fullUrl: { __type: "String" },
    id: { __type: "String" },
    link: { __type: "String" },
    url: { __type: "String" },
  },
  CoreGalleryBlockDeprecatedV6Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowResize: { __type: "Boolean!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    caption: { __type: "String" },
    className: { __type: "String" },
    columns: { __type: "Float" },
    fixedHeight: { __type: "Boolean!" },
    gradient: { __type: "String" },
    ids: { __type: "[Float]!" },
    imageCrop: { __type: "Boolean!" },
    images: { __type: "[CoreGalleryBlockDeprecatedV6AttributesImages]!" },
    layout: { __type: "BlockAttributesObject" },
    linkTarget: { __type: "String" },
    linkTo: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    shortCodeTransforms: { __type: "[BlockAttributesObject]!" },
    sizeSlug: { __type: "String!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreGalleryBlockDeprecatedV6AttributesImages: {
    __typename: { __type: "String!" },
    alt: { __type: "String!" },
    caption: { __type: "String" },
    fullUrl: { __type: "String" },
    id: { __type: "String" },
    link: { __type: "String" },
    url: { __type: "String" },
  },
  CoreGalleryBlockDeprecatedV7Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowResize: { __type: "Boolean!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    caption: { __type: "String" },
    className: { __type: "String" },
    columns: { __type: "Float" },
    fixedHeight: { __type: "Boolean!" },
    gradient: { __type: "String" },
    ids: { __type: "[Float]!" },
    imageCrop: { __type: "Boolean!" },
    images: { __type: "[CoreGalleryBlockDeprecatedV7AttributesImages]!" },
    layout: { __type: "BlockAttributesObject" },
    linkTarget: { __type: "String" },
    linkTo: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    shortCodeTransforms: { __type: "[BlockAttributesObject]!" },
    sizeSlug: { __type: "String!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreGalleryBlockDeprecatedV7AttributesImages: {
    __typename: { __type: "String!" },
    alt: { __type: "String!" },
    caption: { __type: "String" },
    fullUrl: { __type: "String" },
    id: { __type: "String" },
    link: { __type: "String" },
    url: { __type: "String" },
  },
  CoreGroupBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreGroupBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreGroupBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    anchor: { __type: "String" },
    ariaLabel: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    settings: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
  },
  CoreGroupBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreGroupBlockAttributesUnion!" },
  },
  CoreGroupBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    anchor: { __type: "String" },
    ariaLabel: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    settings: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
  },
  CoreGroupBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    anchor: { __type: "String" },
    ariaLabel: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    settings: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
  },
  CoreGroupBlockDeprecatedV3Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    anchor: { __type: "String" },
    ariaLabel: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    settings: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
  },
  CoreGroupBlockDeprecatedV4Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    anchor: { __type: "String" },
    ariaLabel: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    settings: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
  },
  CoreGroupBlockDeprecatedV5Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    anchor: { __type: "String" },
    ariaLabel: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    settings: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    tagName: { __type: "String!" },
    textColor: { __type: "String" },
  },
  CoreHeadingBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreHeadingBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreHeadingBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    level: { __type: "Float!" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreHeadingBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreHeadingBlockAttributesUnion!" },
  },
  CoreHeadingBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    level: { __type: "Float!" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreHeadingBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    level: { __type: "Float!" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreHeadingBlockDeprecatedV3Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    level: { __type: "Float!" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreHeadingBlockDeprecatedV4Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    level: { __type: "Float!" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreHeadingBlockDeprecatedV5Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    level: { __type: "Float!" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreHomeLinkBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreHomeLinkBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreHomeLinkBlockAttributes: {
    __typename: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    label: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
  },
  CoreHtmlBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreHtmlBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreHtmlBlockAttributes: {
    __typename: { __type: "String!" },
    content: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
  },
  CoreImageBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreImageBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    mediaItem: { __type: "CoreImageBlockToMediaItemConnectionEdge" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreImageBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    aspectRatio: { __type: "String" },
    borderColor: { __type: "String" },
    caption: { __type: "String" },
    className: { __type: "String" },
    height: { __type: "Float" },
    href: { __type: "String" },
    id: { __type: "Float" },
    linkClass: { __type: "String" },
    linkDestination: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    rel: { __type: "String" },
    scale: { __type: "String" },
    sizeSlug: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreImageBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreImageBlockAttributesUnion!" },
  },
  CoreImageBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    aspectRatio: { __type: "String" },
    borderColor: { __type: "String" },
    caption: { __type: "String" },
    className: { __type: "String" },
    height: { __type: "Float" },
    href: { __type: "String" },
    id: { __type: "Float" },
    linkClass: { __type: "String" },
    linkDestination: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    rel: { __type: "String" },
    scale: { __type: "String" },
    sizeSlug: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreImageBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    aspectRatio: { __type: "String" },
    borderColor: { __type: "String" },
    caption: { __type: "String" },
    className: { __type: "String" },
    height: { __type: "Float" },
    href: { __type: "String" },
    id: { __type: "Float" },
    linkClass: { __type: "String" },
    linkDestination: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    rel: { __type: "String" },
    scale: { __type: "String" },
    sizeSlug: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreImageBlockDeprecatedV3Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    aspectRatio: { __type: "String" },
    borderColor: { __type: "String" },
    caption: { __type: "String" },
    className: { __type: "String" },
    height: { __type: "Float" },
    href: { __type: "String" },
    id: { __type: "Float" },
    linkClass: { __type: "String" },
    linkDestination: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    rel: { __type: "String" },
    scale: { __type: "String" },
    sizeSlug: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreImageBlockDeprecatedV4Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    aspectRatio: { __type: "String" },
    borderColor: { __type: "String" },
    caption: { __type: "String" },
    className: { __type: "String" },
    height: { __type: "Float" },
    href: { __type: "String" },
    id: { __type: "Float" },
    linkClass: { __type: "String" },
    linkDestination: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    rel: { __type: "String" },
    scale: { __type: "String" },
    sizeSlug: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreImageBlockDeprecatedV5Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    aspectRatio: { __type: "String" },
    borderColor: { __type: "String" },
    caption: { __type: "String" },
    className: { __type: "String" },
    height: { __type: "Float" },
    href: { __type: "String" },
    id: { __type: "Float" },
    linkClass: { __type: "String" },
    linkDestination: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    rel: { __type: "String" },
    scale: { __type: "String" },
    sizeSlug: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreImageBlockDeprecatedV6Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    alt: { __type: "String!" },
    anchor: { __type: "String" },
    aspectRatio: { __type: "String" },
    borderColor: { __type: "String" },
    caption: { __type: "String" },
    className: { __type: "String" },
    height: { __type: "Float" },
    href: { __type: "String" },
    id: { __type: "Float" },
    linkClass: { __type: "String" },
    linkDestination: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    rel: { __type: "String" },
    scale: { __type: "String" },
    sizeSlug: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    title: { __type: "String" },
    url: { __type: "String" },
    width: { __type: "Float" },
  },
  CoreImageBlockToMediaItemConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "MediaItem" },
  },
  CoreLatestCommentsBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreLatestCommentsBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreLatestCommentsBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    className: { __type: "String" },
    commentsToShow: { __type: "Float!" },
    displayAvatar: { __type: "Boolean!" },
    displayDate: { __type: "Boolean!" },
    displayExcerpt: { __type: "Boolean!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
  },
  CoreLatestPostsBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreLatestPostsBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreLatestPostsBlockAttributes: {
    __typename: { __type: "String!" },
    addLinkToFeaturedImage: { __type: "Boolean!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    categories: { __type: "[BlockAttributesObject]" },
    className: { __type: "String" },
    columns: { __type: "Float!" },
    displayAuthor: { __type: "Boolean!" },
    displayFeaturedImage: { __type: "Boolean!" },
    displayPostContent: { __type: "Boolean!" },
    displayPostContentRadio: { __type: "String!" },
    displayPostDate: { __type: "Boolean!" },
    excerptLength: { __type: "Float!" },
    featuredImageAlign: { __type: "String" },
    featuredImageSizeHeight: { __type: "Float" },
    featuredImageSizeSlug: { __type: "String!" },
    featuredImageSizeWidth: { __type: "Float" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    order: { __type: "String!" },
    orderBy: { __type: "String!" },
    postLayout: { __type: "String!" },
    postsToShow: { __type: "Float!" },
    selectedAuthor: { __type: "Float" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreLatestPostsBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreLatestPostsBlockAttributesUnion!" },
  },
  CoreLatestPostsBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    addLinkToFeaturedImage: { __type: "Boolean!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    categories: { __type: "[BlockAttributesObject]" },
    className: { __type: "String" },
    columns: { __type: "Float!" },
    displayAuthor: { __type: "Boolean!" },
    displayFeaturedImage: { __type: "Boolean!" },
    displayPostContent: { __type: "Boolean!" },
    displayPostContentRadio: { __type: "String!" },
    displayPostDate: { __type: "Boolean!" },
    excerptLength: { __type: "Float!" },
    featuredImageAlign: { __type: "String" },
    featuredImageSizeHeight: { __type: "Float" },
    featuredImageSizeSlug: { __type: "String!" },
    featuredImageSizeWidth: { __type: "Float" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    order: { __type: "String!" },
    orderBy: { __type: "String!" },
    postLayout: { __type: "String!" },
    postsToShow: { __type: "Float!" },
    selectedAuthor: { __type: "Float" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreLegacyWidgetBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreLegacyWidgetBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreLegacyWidgetBlockAttributes: {
    __typename: { __type: "String!" },
    className: { __type: "String" },
    id: { __type: "String" },
    idBase: { __type: "String" },
    instance: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
  },
  CoreListBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreListBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreListBlockAttributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    ordered: { __type: "Boolean!" },
    placeholder: { __type: "String" },
    reversed: { __type: "Boolean" },
    start: { __type: "Float" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    type: { __type: "String" },
    values: { __type: "String!" },
  },
  CoreListBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreListBlockAttributesUnion!" },
  },
  CoreListBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    ordered: { __type: "Boolean!" },
    placeholder: { __type: "String" },
    reversed: { __type: "Boolean" },
    start: { __type: "Float" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    type: { __type: "String" },
    values: { __type: "String!" },
  },
  CoreListBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    ordered: { __type: "Boolean!" },
    placeholder: { __type: "String" },
    reversed: { __type: "Boolean" },
    start: { __type: "Float" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    type: { __type: "String" },
    values: { __type: "String!" },
  },
  CoreListItemBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreListItemBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreListItemBlockAttributes: {
    __typename: { __type: "String!" },
    className: { __type: "String" },
    content: { __type: "String!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
  },
  CoreLoginoutBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreLoginoutBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreLoginoutBlockAttributes: {
    __typename: { __type: "String!" },
    className: { __type: "String" },
    displayLoginAsForm: { __type: "Boolean!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    redirectToCurrent: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
  },
  CoreMediaTextBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreMediaTextBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreMediaTextBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String!" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    href: { __type: "String" },
    imageFill: { __type: "Boolean" },
    isStackedOnMobile: { __type: "Boolean!" },
    linkClass: { __type: "String" },
    linkDestination: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    mediaAlt: { __type: "String!" },
    mediaId: { __type: "Float" },
    mediaLink: { __type: "String" },
    mediaPosition: { __type: "String!" },
    mediaSizeSlug: { __type: "String" },
    mediaType: { __type: "String" },
    mediaUrl: { __type: "String" },
    mediaWidth: { __type: "Float!" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    verticalAlignment: { __type: "String" },
  },
  CoreMediaTextBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreMediaTextBlockAttributesUnion!" },
  },
  CoreMediaTextBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String!" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    href: { __type: "String" },
    imageFill: { __type: "Boolean" },
    isStackedOnMobile: { __type: "Boolean!" },
    linkClass: { __type: "String" },
    linkDestination: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    mediaAlt: { __type: "String!" },
    mediaId: { __type: "Float" },
    mediaLink: { __type: "String" },
    mediaPosition: { __type: "String!" },
    mediaSizeSlug: { __type: "String" },
    mediaType: { __type: "String" },
    mediaUrl: { __type: "String" },
    mediaWidth: { __type: "Float!" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    verticalAlignment: { __type: "String" },
  },
  CoreMediaTextBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String!" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    href: { __type: "String" },
    imageFill: { __type: "Boolean" },
    isStackedOnMobile: { __type: "Boolean!" },
    linkClass: { __type: "String" },
    linkDestination: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    mediaAlt: { __type: "String!" },
    mediaId: { __type: "Float" },
    mediaLink: { __type: "String" },
    mediaPosition: { __type: "String!" },
    mediaSizeSlug: { __type: "String" },
    mediaType: { __type: "String" },
    mediaUrl: { __type: "String" },
    mediaWidth: { __type: "Float!" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    verticalAlignment: { __type: "String" },
  },
  CoreMediaTextBlockDeprecatedV3Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String!" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    href: { __type: "String" },
    imageFill: { __type: "Boolean" },
    isStackedOnMobile: { __type: "Boolean!" },
    linkClass: { __type: "String" },
    linkDestination: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    mediaAlt: { __type: "String!" },
    mediaId: { __type: "Float" },
    mediaLink: { __type: "String" },
    mediaPosition: { __type: "String!" },
    mediaSizeSlug: { __type: "String" },
    mediaType: { __type: "String" },
    mediaUrl: { __type: "String" },
    mediaWidth: { __type: "Float!" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    verticalAlignment: { __type: "String" },
  },
  CoreMediaTextBlockDeprecatedV4Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String!" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    href: { __type: "String" },
    imageFill: { __type: "Boolean" },
    isStackedOnMobile: { __type: "Boolean!" },
    linkClass: { __type: "String" },
    linkDestination: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    mediaAlt: { __type: "String!" },
    mediaId: { __type: "Float" },
    mediaLink: { __type: "String" },
    mediaPosition: { __type: "String!" },
    mediaSizeSlug: { __type: "String" },
    mediaType: { __type: "String" },
    mediaUrl: { __type: "String" },
    mediaWidth: { __type: "Float!" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    verticalAlignment: { __type: "String" },
  },
  CoreMediaTextBlockDeprecatedV5Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String!" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    href: { __type: "String" },
    imageFill: { __type: "Boolean" },
    isStackedOnMobile: { __type: "Boolean!" },
    linkClass: { __type: "String" },
    linkDestination: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    mediaAlt: { __type: "String!" },
    mediaId: { __type: "Float" },
    mediaLink: { __type: "String" },
    mediaPosition: { __type: "String!" },
    mediaSizeSlug: { __type: "String" },
    mediaType: { __type: "String" },
    mediaUrl: { __type: "String" },
    mediaWidth: { __type: "Float!" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    verticalAlignment: { __type: "String" },
  },
  CoreMediaTextBlockDeprecatedV6Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String!" },
    allowedBlocks: { __type: "BlockAttributesArray" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    focalPoint: { __type: "BlockAttributesObject" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    href: { __type: "String" },
    imageFill: { __type: "Boolean" },
    isStackedOnMobile: { __type: "Boolean!" },
    linkClass: { __type: "String" },
    linkDestination: { __type: "String" },
    linkTarget: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    mediaAlt: { __type: "String!" },
    mediaId: { __type: "Float" },
    mediaLink: { __type: "String" },
    mediaPosition: { __type: "String!" },
    mediaSizeSlug: { __type: "String" },
    mediaType: { __type: "String" },
    mediaUrl: { __type: "String" },
    mediaWidth: { __type: "Float!" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    verticalAlignment: { __type: "String" },
  },
  CoreMissingBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreMissingBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreMissingBlockAttributes: {
    __typename: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    originalContent: { __type: "String" },
    originalName: { __type: "String" },
    originalUndelimitedContent: { __type: "String" },
  },
  CoreMoreBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreMoreBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreMoreBlockAttributes: {
    __typename: { __type: "String!" },
    customText: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    noTeaser: { __type: "Boolean!" },
  },
  CoreNavigationBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreNavigationBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreNavigationBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    customBackgroundColor: { __type: "String" },
    customOverlayBackgroundColor: { __type: "String" },
    customOverlayTextColor: { __type: "String" },
    customTextColor: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    hasIcon: { __type: "Boolean!" },
    icon: { __type: "String!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    maxNestingLevel: { __type: "Float!" },
    openSubmenusOnClick: { __type: "Boolean!" },
    overlayBackgroundColor: { __type: "String" },
    overlayMenu: { __type: "String!" },
    overlayTextColor: { __type: "String" },
    ref: { __type: "Float" },
    rgbBackgroundColor: { __type: "String" },
    rgbTextColor: { __type: "String" },
    showSubmenuIcon: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    unstableLocation: { __type: "String" },
  },
  CoreNavigationBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreNavigationBlockAttributesUnion!" },
  },
  CoreNavigationBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    customBackgroundColor: { __type: "String" },
    customOverlayBackgroundColor: { __type: "String" },
    customOverlayTextColor: { __type: "String" },
    customTextColor: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    hasIcon: { __type: "Boolean!" },
    icon: { __type: "String!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    maxNestingLevel: { __type: "Float!" },
    openSubmenusOnClick: { __type: "Boolean!" },
    overlayBackgroundColor: { __type: "String" },
    overlayMenu: { __type: "String!" },
    overlayTextColor: { __type: "String" },
    ref: { __type: "Float" },
    rgbBackgroundColor: { __type: "String" },
    rgbTextColor: { __type: "String" },
    showSubmenuIcon: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    unstableLocation: { __type: "String" },
  },
  CoreNavigationBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    customBackgroundColor: { __type: "String" },
    customOverlayBackgroundColor: { __type: "String" },
    customOverlayTextColor: { __type: "String" },
    customTextColor: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    hasIcon: { __type: "Boolean!" },
    icon: { __type: "String!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    maxNestingLevel: { __type: "Float!" },
    openSubmenusOnClick: { __type: "Boolean!" },
    overlayBackgroundColor: { __type: "String" },
    overlayMenu: { __type: "String!" },
    overlayTextColor: { __type: "String" },
    ref: { __type: "Float" },
    rgbBackgroundColor: { __type: "String" },
    rgbTextColor: { __type: "String" },
    showSubmenuIcon: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    unstableLocation: { __type: "String" },
  },
  CoreNavigationBlockDeprecatedV3Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    customBackgroundColor: { __type: "String" },
    customOverlayBackgroundColor: { __type: "String" },
    customOverlayTextColor: { __type: "String" },
    customTextColor: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    hasIcon: { __type: "Boolean!" },
    icon: { __type: "String!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    maxNestingLevel: { __type: "Float!" },
    openSubmenusOnClick: { __type: "Boolean!" },
    overlayBackgroundColor: { __type: "String" },
    overlayMenu: { __type: "String!" },
    overlayTextColor: { __type: "String" },
    ref: { __type: "Float" },
    rgbBackgroundColor: { __type: "String" },
    rgbTextColor: { __type: "String" },
    showSubmenuIcon: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    unstableLocation: { __type: "String" },
  },
  CoreNavigationBlockDeprecatedV4Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    customBackgroundColor: { __type: "String" },
    customOverlayBackgroundColor: { __type: "String" },
    customOverlayTextColor: { __type: "String" },
    customTextColor: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    hasIcon: { __type: "Boolean!" },
    icon: { __type: "String!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    maxNestingLevel: { __type: "Float!" },
    openSubmenusOnClick: { __type: "Boolean!" },
    overlayBackgroundColor: { __type: "String" },
    overlayMenu: { __type: "String!" },
    overlayTextColor: { __type: "String" },
    ref: { __type: "Float" },
    rgbBackgroundColor: { __type: "String" },
    rgbTextColor: { __type: "String" },
    showSubmenuIcon: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    unstableLocation: { __type: "String" },
  },
  CoreNavigationBlockDeprecatedV5Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    customBackgroundColor: { __type: "String" },
    customOverlayBackgroundColor: { __type: "String" },
    customOverlayTextColor: { __type: "String" },
    customTextColor: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    hasIcon: { __type: "Boolean!" },
    icon: { __type: "String!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    maxNestingLevel: { __type: "Float!" },
    openSubmenusOnClick: { __type: "Boolean!" },
    overlayBackgroundColor: { __type: "String" },
    overlayMenu: { __type: "String!" },
    overlayTextColor: { __type: "String" },
    ref: { __type: "Float" },
    rgbBackgroundColor: { __type: "String" },
    rgbTextColor: { __type: "String" },
    showSubmenuIcon: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    unstableLocation: { __type: "String" },
  },
  CoreNavigationBlockDeprecatedV6Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    customBackgroundColor: { __type: "String" },
    customOverlayBackgroundColor: { __type: "String" },
    customOverlayTextColor: { __type: "String" },
    customTextColor: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    hasIcon: { __type: "Boolean!" },
    icon: { __type: "String!" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    maxNestingLevel: { __type: "Float!" },
    openSubmenusOnClick: { __type: "Boolean!" },
    overlayBackgroundColor: { __type: "String" },
    overlayMenu: { __type: "String!" },
    overlayTextColor: { __type: "String" },
    ref: { __type: "Float" },
    rgbBackgroundColor: { __type: "String" },
    rgbTextColor: { __type: "String" },
    showSubmenuIcon: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    unstableLocation: { __type: "String" },
  },
  CoreNavigationLinkBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreNavigationLinkBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreNavigationLinkBlockAttributes: {
    __typename: { __type: "String!" },
    className: { __type: "String" },
    description: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    id: { __type: "Float" },
    isTopLevelLink: { __type: "Boolean" },
    kind: { __type: "String" },
    label: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    opensInNewTab: { __type: "Boolean!" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    title: { __type: "String" },
    type: { __type: "String" },
    url: { __type: "String" },
  },
  CoreNavigationLinkBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreNavigationLinkBlockAttributesUnion!" },
  },
  CoreNavigationLinkBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    className: { __type: "String" },
    description: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    id: { __type: "Float" },
    isTopLevelLink: { __type: "Boolean" },
    kind: { __type: "String" },
    label: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    opensInNewTab: { __type: "Boolean!" },
    rel: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    title: { __type: "String" },
    type: { __type: "String" },
    url: { __type: "String" },
  },
  CoreNavigationSubmenuBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreNavigationSubmenuBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreNavigationSubmenuBlockAttributes: {
    __typename: { __type: "String!" },
    className: { __type: "String" },
    description: { __type: "String" },
    id: { __type: "Float" },
    isTopLevelItem: { __type: "Boolean" },
    kind: { __type: "String" },
    label: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    opensInNewTab: { __type: "Boolean!" },
    rel: { __type: "String" },
    title: { __type: "String" },
    type: { __type: "String" },
    url: { __type: "String" },
  },
  CoreNextpageBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreNextpageBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreNextpageBlockAttributes: {
    __typename: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
  },
  CorePageListBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CorePageListBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CorePageListBlockAttributes: {
    __typename: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    isNested: { __type: "Boolean!" },
    lock: { __type: "BlockAttributesObject" },
    parentPageID: { __type: "Int!" },
    style: { __type: "BlockAttributesObject" },
  },
  CorePageListItemBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CorePageListItemBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CorePageListItemBlockAttributes: {
    __typename: { __type: "String!" },
    className: { __type: "String" },
    hasChildren: { __type: "Boolean" },
    id: { __type: "Float" },
    label: { __type: "String" },
    link: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    title: { __type: "String" },
  },
  CoreParagraphBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreParagraphBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreParagraphBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String!" },
    direction: { __type: "String" },
    dropCap: { __type: "Boolean!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreParagraphBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreParagraphBlockAttributesUnion!" },
  },
  CoreParagraphBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String!" },
    direction: { __type: "String" },
    dropCap: { __type: "Boolean!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreParagraphBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String!" },
    direction: { __type: "String" },
    dropCap: { __type: "Boolean!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreParagraphBlockDeprecatedV3Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String!" },
    direction: { __type: "String" },
    dropCap: { __type: "Boolean!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreParagraphBlockDeprecatedV4Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String!" },
    direction: { __type: "String" },
    dropCap: { __type: "Boolean!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreParagraphBlockDeprecatedV5Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String!" },
    direction: { __type: "String" },
    dropCap: { __type: "Boolean!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreParagraphBlockDeprecatedV6Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String!" },
    direction: { __type: "String" },
    dropCap: { __type: "Boolean!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CorePatternBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CorePatternBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CorePatternBlockAttributes: {
    __typename: { __type: "String!" },
    className: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    slug: { __type: "String" },
  },
  CorePostAuthorBiographyBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CorePostAuthorBiographyBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CorePostAuthorBiographyBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CorePostAuthorBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CorePostAuthorBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CorePostAuthorBlockAttributes: {
    __typename: { __type: "String!" },
    avatarSize: { __type: "Float!" },
    backgroundColor: { __type: "String" },
    byline: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    isLink: { __type: "Boolean!" },
    linkTarget: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    showAvatar: { __type: "Boolean!" },
    showBio: { __type: "Boolean" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CorePostAuthorNameBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CorePostAuthorNameBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CorePostAuthorNameBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    isLink: { __type: "Boolean!" },
    linkTarget: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CorePostCommentsFormBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CorePostCommentsFormBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CorePostCommentsFormBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CorePostContentBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CorePostContentBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CorePostContentBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
  },
  CorePostDateBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CorePostDateBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CorePostDateBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    displayType: { __type: "String!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    format: { __type: "String" },
    gradient: { __type: "String" },
    isLink: { __type: "Boolean!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CorePostDateBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CorePostDateBlockAttributesUnion!" },
  },
  CorePostDateBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    displayType: { __type: "String!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    format: { __type: "String" },
    gradient: { __type: "String" },
    isLink: { __type: "Boolean!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CorePostExcerptBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CorePostExcerptBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CorePostExcerptBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    excerptLength: { __type: "Float!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    moreText: { __type: "String" },
    showMoreOnNewLine: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CorePostFeaturedImageBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CorePostFeaturedImageBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CorePostFeaturedImageBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    aspectRatio: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    customGradient: { __type: "String" },
    customOverlayColor: { __type: "String" },
    dimRatio: { __type: "Float!" },
    gradient: { __type: "String" },
    height: { __type: "String" },
    isLink: { __type: "Boolean!" },
    linkTarget: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    overlayColor: { __type: "String" },
    rel: { __type: "String!" },
    scale: { __type: "String!" },
    sizeSlug: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    width: { __type: "String" },
  },
  CorePostNavigationLinkBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CorePostNavigationLinkBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CorePostNavigationLinkBlockAttributes: {
    __typename: { __type: "String!" },
    arrow: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    label: { __type: "String" },
    linkLabel: { __type: "Boolean!" },
    lock: { __type: "BlockAttributesObject" },
    showTitle: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    type: { __type: "String!" },
  },
  CorePostTemplateBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CorePostTemplateBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CorePostTemplateBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CorePostTermsBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CorePostTermsBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CorePostTermsBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    prefix: { __type: "String!" },
    separator: { __type: "String!" },
    style: { __type: "BlockAttributesObject" },
    suffix: { __type: "String!" },
    term: { __type: "String" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CorePostTitleBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CorePostTitleBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CorePostTitleBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    isLink: { __type: "Boolean!" },
    level: { __type: "Float!" },
    linkTarget: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    rel: { __type: "String!" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CorePostTitleBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CorePostTitleBlockAttributesUnion!" },
  },
  CorePostTitleBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    isLink: { __type: "Boolean!" },
    level: { __type: "Float!" },
    linkTarget: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    rel: { __type: "String!" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CorePreformattedBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CorePreformattedBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CorePreformattedBlockAttributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CorePullquoteBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CorePullquoteBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CorePullquoteBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    citation: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    value: { __type: "String" },
  },
  CorePullquoteBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CorePullquoteBlockAttributesUnion!" },
  },
  CorePullquoteBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    citation: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    value: { __type: "String" },
  },
  CorePullquoteBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    citation: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    value: { __type: "String" },
  },
  CorePullquoteBlockDeprecatedV3Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    citation: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    value: { __type: "String" },
  },
  CorePullquoteBlockDeprecatedV4Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    citation: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    value: { __type: "String" },
  },
  CorePullquoteBlockDeprecatedV5Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    citation: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    value: { __type: "String" },
  },
  CorePullquoteBlockDeprecatedV6Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    citation: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    value: { __type: "String" },
  },
  CoreQueryBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreQueryBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreQueryBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    className: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    namespace: { __type: "String" },
    query: { __type: "BlockAttributesObject!" },
    queryId: { __type: "Float" },
    tagName: { __type: "String!" },
  },
  CoreQueryBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreQueryBlockAttributesUnion!" },
  },
  CoreQueryBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    className: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    namespace: { __type: "String" },
    query: { __type: "BlockAttributesObject!" },
    queryId: { __type: "Float" },
    tagName: { __type: "String!" },
  },
  CoreQueryBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    className: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    namespace: { __type: "String" },
    query: { __type: "BlockAttributesObject!" },
    queryId: { __type: "Float" },
    tagName: { __type: "String!" },
  },
  CoreQueryBlockDeprecatedV3Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    className: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    namespace: { __type: "String" },
    query: { __type: "BlockAttributesObject!" },
    queryId: { __type: "Float" },
    tagName: { __type: "String!" },
  },
  CoreQueryBlockDeprecatedV4Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    className: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    namespace: { __type: "String" },
    query: { __type: "BlockAttributesObject!" },
    queryId: { __type: "Float" },
    tagName: { __type: "String!" },
  },
  CoreQueryBlockDeprecatedV5Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    className: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    namespace: { __type: "String" },
    query: { __type: "BlockAttributesObject!" },
    queryId: { __type: "Float" },
    tagName: { __type: "String!" },
  },
  CoreQueryNoResultsBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreQueryNoResultsBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreQueryNoResultsBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreQueryPaginationBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreQueryPaginationBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreQueryPaginationBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    paginationArrow: { __type: "String!" },
    showLabel: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreQueryPaginationBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreQueryPaginationBlockAttributesUnion!" },
  },
  CoreQueryPaginationBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    paginationArrow: { __type: "String!" },
    showLabel: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreQueryPaginationNextBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreQueryPaginationNextBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreQueryPaginationNextBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    label: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreQueryPaginationNumbersBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreQueryPaginationNumbersBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreQueryPaginationNumbersBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreQueryPaginationPreviousBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreQueryPaginationPreviousBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreQueryPaginationPreviousBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    label: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreQueryTitleBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreQueryTitleBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreQueryTitleBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    level: { __type: "Float!" },
    lock: { __type: "BlockAttributesObject" },
    showPrefix: { __type: "Boolean!" },
    showSearchTerm: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    type: { __type: "String" },
  },
  CoreQueryTitleBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreQueryTitleBlockAttributesUnion!" },
  },
  CoreQueryTitleBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    level: { __type: "Float!" },
    lock: { __type: "BlockAttributesObject" },
    showPrefix: { __type: "Boolean!" },
    showSearchTerm: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
    type: { __type: "String" },
  },
  CoreQuoteBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreQuoteBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreQuoteBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    citation: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    value: { __type: "String!" },
  },
  CoreQuoteBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreQuoteBlockAttributesUnion!" },
  },
  CoreQuoteBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    citation: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    value: { __type: "String!" },
  },
  CoreQuoteBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    citation: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    value: { __type: "String!" },
  },
  CoreQuoteBlockDeprecatedV3Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    citation: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    value: { __type: "String!" },
  },
  CoreQuoteBlockDeprecatedV4Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    citation: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    value: { __type: "String!" },
  },
  CoreReadMoreBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreReadMoreBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreReadMoreBlockAttributes: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    linkTarget: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreRssBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreRssBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreRssBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    blockLayout: { __type: "String!" },
    className: { __type: "String" },
    columns: { __type: "Float!" },
    displayAuthor: { __type: "Boolean!" },
    displayDate: { __type: "Boolean!" },
    displayExcerpt: { __type: "Boolean!" },
    excerptLength: { __type: "Float!" },
    feedURL: { __type: "String!" },
    itemsToShow: { __type: "Float!" },
    lock: { __type: "BlockAttributesObject" },
  },
  CoreSearchBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreSearchBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreSearchBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    buttonBehavior: { __type: "String!" },
    buttonPosition: { __type: "String!" },
    buttonText: { __type: "String" },
    buttonUseIcon: { __type: "Boolean!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    isSearchFieldHidden: { __type: "Boolean!" },
    label: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    placeholder: { __type: "String!" },
    query: { __type: "BlockAttributesObject!" },
    showLabel: { __type: "Boolean!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
    width: { __type: "Float" },
    widthUnit: { __type: "String" },
  },
  CoreSeparatorBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreSeparatorBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreSeparatorBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    opacity: { __type: "String!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreSeparatorBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreSeparatorBlockAttributesUnion!" },
  },
  CoreSeparatorBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    opacity: { __type: "String!" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreShortcodeBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreShortcodeBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreShortcodeBlockAttributes: {
    __typename: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    text: { __type: "String" },
  },
  CoreSiteLogoBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreSiteLogoBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreSiteLogoBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    className: { __type: "String" },
    isLink: { __type: "Boolean!" },
    linkTarget: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    shouldSyncIcon: { __type: "Boolean" },
    style: { __type: "BlockAttributesObject" },
    width: { __type: "Float" },
  },
  CoreSiteTaglineBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreSiteTaglineBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreSiteTaglineBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreSiteTaglineBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreSiteTaglineBlockAttributesUnion!" },
  },
  CoreSiteTaglineBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreSiteTitleBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreSiteTitleBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreSiteTitleBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    isLink: { __type: "Boolean!" },
    level: { __type: "Float!" },
    linkTarget: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreSiteTitleBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreSiteTitleBlockAttributesUnion!" },
  },
  CoreSiteTitleBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    isLink: { __type: "Boolean!" },
    level: { __type: "Float!" },
    linkTarget: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreSocialLinkBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreSocialLinkBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreSocialLinkBlockAttributes: {
    __typename: { __type: "String!" },
    className: { __type: "String" },
    label: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    rel: { __type: "String" },
    service: { __type: "String" },
    url: { __type: "String" },
  },
  CoreSocialLinksBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreSocialLinksBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreSocialLinksBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    customIconBackgroundColor: { __type: "String" },
    customIconColor: { __type: "String" },
    gradient: { __type: "String" },
    iconBackgroundColor: { __type: "String" },
    iconBackgroundColorValue: { __type: "String" },
    iconColor: { __type: "String" },
    iconColorValue: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    openInNewTab: { __type: "Boolean!" },
    showLabels: { __type: "Boolean!" },
    size: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreSocialLinksBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreSocialLinksBlockAttributesUnion!" },
  },
  CoreSocialLinksBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    customIconBackgroundColor: { __type: "String" },
    customIconColor: { __type: "String" },
    gradient: { __type: "String" },
    iconBackgroundColor: { __type: "String" },
    iconBackgroundColorValue: { __type: "String" },
    iconColor: { __type: "String" },
    iconColorValue: { __type: "String" },
    layout: { __type: "BlockAttributesObject" },
    lock: { __type: "BlockAttributesObject" },
    openInNewTab: { __type: "Boolean!" },
    showLabels: { __type: "Boolean!" },
    size: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreSpacerBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreSpacerBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreSpacerBlockAttributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    className: { __type: "String" },
    height: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    width: { __type: "String" },
  },
  CoreSpacerBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreSpacerBlockAttributesUnion!" },
  },
  CoreSpacerBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    className: { __type: "String" },
    height: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    width: { __type: "String" },
  },
  CoreTableBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreTableBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreTableBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    body: { __type: "[CoreTableBlockAttributesBody]!" },
    borderColor: { __type: "String" },
    caption: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    foot: { __type: "[CoreTableBlockAttributesFoot]!" },
    gradient: { __type: "String" },
    hasFixedLayout: { __type: "Boolean!" },
    head: { __type: "[CoreTableBlockAttributesHead]!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreTableBlockAttributesBody: {
    __typename: { __type: "String!" },
    cells: { __type: "[CoreTableBlockAttributesBodyCells]!" },
  },
  CoreTableBlockAttributesBodyCells: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    colspan: { __type: "String" },
    content: { __type: "String" },
    rowspan: { __type: "String" },
    scope: { __type: "String" },
    tag: { __type: "String!" },
  },
  CoreTableBlockAttributesFoot: {
    __typename: { __type: "String!" },
    cells: { __type: "[CoreTableBlockAttributesFootCells]!" },
  },
  CoreTableBlockAttributesFootCells: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    colspan: { __type: "String" },
    content: { __type: "String" },
    rowspan: { __type: "String" },
    scope: { __type: "String" },
    tag: { __type: "String!" },
  },
  CoreTableBlockAttributesHead: {
    __typename: { __type: "String!" },
    cells: { __type: "[CoreTableBlockAttributesHeadCells]!" },
  },
  CoreTableBlockAttributesHeadCells: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    colspan: { __type: "String" },
    content: { __type: "String" },
    rowspan: { __type: "String" },
    scope: { __type: "String" },
    tag: { __type: "String!" },
  },
  CoreTableBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreTableBlockAttributesUnion!" },
  },
  CoreTableBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    body: { __type: "[CoreTableBlockDeprecatedV1AttributesBody]!" },
    borderColor: { __type: "String" },
    caption: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    foot: { __type: "[CoreTableBlockDeprecatedV1AttributesFoot]!" },
    gradient: { __type: "String" },
    hasFixedLayout: { __type: "Boolean!" },
    head: { __type: "[CoreTableBlockDeprecatedV1AttributesHead]!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreTableBlockDeprecatedV1AttributesBody: {
    __typename: { __type: "String!" },
    cells: { __type: "[CoreTableBlockDeprecatedV1AttributesBodyCells]!" },
  },
  CoreTableBlockDeprecatedV1AttributesBodyCells: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    colspan: { __type: "String" },
    content: { __type: "String" },
    rowspan: { __type: "String" },
    scope: { __type: "String" },
    tag: { __type: "String!" },
  },
  CoreTableBlockDeprecatedV1AttributesFoot: {
    __typename: { __type: "String!" },
    cells: { __type: "[CoreTableBlockDeprecatedV1AttributesFootCells]!" },
  },
  CoreTableBlockDeprecatedV1AttributesFootCells: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    colspan: { __type: "String" },
    content: { __type: "String" },
    rowspan: { __type: "String" },
    scope: { __type: "String" },
    tag: { __type: "String!" },
  },
  CoreTableBlockDeprecatedV1AttributesHead: {
    __typename: { __type: "String!" },
    cells: { __type: "[CoreTableBlockDeprecatedV1AttributesHeadCells]!" },
  },
  CoreTableBlockDeprecatedV1AttributesHeadCells: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    colspan: { __type: "String" },
    content: { __type: "String" },
    rowspan: { __type: "String" },
    scope: { __type: "String" },
    tag: { __type: "String!" },
  },
  CoreTableBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    body: { __type: "[CoreTableBlockDeprecatedV2AttributesBody]!" },
    borderColor: { __type: "String" },
    caption: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    foot: { __type: "[CoreTableBlockDeprecatedV2AttributesFoot]!" },
    gradient: { __type: "String" },
    hasFixedLayout: { __type: "Boolean!" },
    head: { __type: "[CoreTableBlockDeprecatedV2AttributesHead]!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreTableBlockDeprecatedV2AttributesBody: {
    __typename: { __type: "String!" },
    cells: { __type: "[CoreTableBlockDeprecatedV2AttributesBodyCells]!" },
  },
  CoreTableBlockDeprecatedV2AttributesBodyCells: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    colspan: { __type: "String" },
    content: { __type: "String" },
    rowspan: { __type: "String" },
    scope: { __type: "String" },
    tag: { __type: "String!" },
  },
  CoreTableBlockDeprecatedV2AttributesFoot: {
    __typename: { __type: "String!" },
    cells: { __type: "[CoreTableBlockDeprecatedV2AttributesFootCells]!" },
  },
  CoreTableBlockDeprecatedV2AttributesFootCells: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    colspan: { __type: "String" },
    content: { __type: "String" },
    rowspan: { __type: "String" },
    scope: { __type: "String" },
    tag: { __type: "String!" },
  },
  CoreTableBlockDeprecatedV2AttributesHead: {
    __typename: { __type: "String!" },
    cells: { __type: "[CoreTableBlockDeprecatedV2AttributesHeadCells]!" },
  },
  CoreTableBlockDeprecatedV2AttributesHeadCells: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    colspan: { __type: "String" },
    content: { __type: "String" },
    rowspan: { __type: "String" },
    scope: { __type: "String" },
    tag: { __type: "String!" },
  },
  CoreTableBlockDeprecatedV3Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    body: { __type: "[CoreTableBlockDeprecatedV3AttributesBody]!" },
    borderColor: { __type: "String" },
    caption: { __type: "String!" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    foot: { __type: "[CoreTableBlockDeprecatedV3AttributesFoot]!" },
    gradient: { __type: "String" },
    hasFixedLayout: { __type: "Boolean!" },
    head: { __type: "[CoreTableBlockDeprecatedV3AttributesHead]!" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textColor: { __type: "String" },
  },
  CoreTableBlockDeprecatedV3AttributesBody: {
    __typename: { __type: "String!" },
    cells: { __type: "[CoreTableBlockDeprecatedV3AttributesBodyCells]!" },
  },
  CoreTableBlockDeprecatedV3AttributesBodyCells: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    colspan: { __type: "String" },
    content: { __type: "String" },
    rowspan: { __type: "String" },
    scope: { __type: "String" },
    tag: { __type: "String!" },
  },
  CoreTableBlockDeprecatedV3AttributesFoot: {
    __typename: { __type: "String!" },
    cells: { __type: "[CoreTableBlockDeprecatedV3AttributesFootCells]!" },
  },
  CoreTableBlockDeprecatedV3AttributesFootCells: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    colspan: { __type: "String" },
    content: { __type: "String" },
    rowspan: { __type: "String" },
    scope: { __type: "String" },
    tag: { __type: "String!" },
  },
  CoreTableBlockDeprecatedV3AttributesHead: {
    __typename: { __type: "String!" },
    cells: { __type: "[CoreTableBlockDeprecatedV3AttributesHeadCells]!" },
  },
  CoreTableBlockDeprecatedV3AttributesHeadCells: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    colspan: { __type: "String" },
    content: { __type: "String" },
    rowspan: { __type: "String" },
    scope: { __type: "String" },
    tag: { __type: "String!" },
  },
  CoreTagCloudBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreTagCloudBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreTagCloudBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    largestFontSize: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    numberOfTags: { __type: "Float!" },
    showTagCounts: { __type: "Boolean!" },
    smallestFontSize: { __type: "String!" },
    style: { __type: "BlockAttributesObject" },
    taxonomy: { __type: "String!" },
  },
  CoreTemplatePartBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreTemplatePartBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreTemplatePartBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    area: { __type: "String" },
    className: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    slug: { __type: "String" },
    tagName: { __type: "String" },
    theme: { __type: "String" },
  },
  CoreTermDescriptionBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreTermDescriptionBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreTermDescriptionBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    backgroundColor: { __type: "String" },
    className: { __type: "String" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreTextColumnsBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreTextColumnsBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreTextColumnsBlockAttributes: {
    __typename: { __type: "String!" },
    className: { __type: "String" },
    columns: { __type: "Float!" },
    content: { __type: "[CoreTextColumnsBlockAttributesContent]!" },
    lock: { __type: "BlockAttributesObject" },
    width: { __type: "String" },
  },
  CoreTextColumnsBlockAttributesContent: {
    __typename: { __type: "String!" },
    children: { __type: "String" },
  },
  CoreVerseBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreVerseBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreVerseBlockAttributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreVerseBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreVerseBlockAttributesUnion!" },
  },
  CoreVerseBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreVerseBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    anchor: { __type: "String" },
    backgroundColor: { __type: "String" },
    borderColor: { __type: "String" },
    className: { __type: "String" },
    content: { __type: "String!" },
    fontFamily: { __type: "String" },
    fontSize: { __type: "String" },
    gradient: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    style: { __type: "BlockAttributesObject" },
    textAlign: { __type: "String" },
    textColor: { __type: "String" },
  },
  CoreVideoBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreVideoBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreVideoBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    autoplay: { __type: "Boolean" },
    caption: { __type: "String" },
    className: { __type: "String" },
    controls: { __type: "Boolean!" },
    id: { __type: "Float" },
    lock: { __type: "BlockAttributesObject" },
    loop: { __type: "Boolean" },
    muted: { __type: "Boolean" },
    playsInline: { __type: "Boolean" },
    poster: { __type: "String" },
    preload: { __type: "String!" },
    src: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    tracks: { __type: "[BlockAttributesObject]!" },
  },
  CoreVideoBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreVideoBlockAttributesUnion!" },
  },
  CoreVideoBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchor: { __type: "String" },
    autoplay: { __type: "Boolean" },
    caption: { __type: "String" },
    className: { __type: "String" },
    controls: { __type: "Boolean!" },
    id: { __type: "Float" },
    lock: { __type: "BlockAttributesObject" },
    loop: { __type: "Boolean" },
    muted: { __type: "Boolean" },
    playsInline: { __type: "Boolean" },
    poster: { __type: "String" },
    preload: { __type: "String!" },
    src: { __type: "String" },
    style: { __type: "BlockAttributesObject" },
    tracks: { __type: "[BlockAttributesObject]!" },
  },
  CoreWidgetGroupBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "CoreWidgetGroupBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  CoreWidgetGroupBlockAttributes: {
    __typename: { __type: "String!" },
    className: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    title: { __type: "String" },
  },
  CoreWidgetGroupBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$CoreWidgetGroupBlockAttributesUnion!" },
  },
  CoreWidgetGroupBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    className: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    title: { __type: "String" },
  },
  CreateCategoryInput: {
    aliasOf: { __type: "String" },
    clientMutationId: { __type: "String" },
    description: { __type: "String" },
    name: { __type: "String!" },
    parentId: { __type: "ID" },
    slug: { __type: "String" },
  },
  CreateCategoryPayload: {
    __typename: { __type: "String!" },
    category: { __type: "Category" },
    clientMutationId: { __type: "String" },
  },
  CreateCommentInput: {
    approved: { __type: "String" },
    author: { __type: "String" },
    authorEmail: { __type: "String" },
    authorUrl: { __type: "String" },
    clientMutationId: { __type: "String" },
    commentOn: { __type: "Int" },
    content: { __type: "String" },
    date: { __type: "String" },
    parent: { __type: "ID" },
    type: { __type: "String" },
  },
  CreateCommentPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    comment: { __type: "Comment" },
    success: { __type: "Boolean" },
  },
  CreateJobInput: {
    clientMutationId: { __type: "String" },
    content: { __type: "String" },
    date: { __type: "String" },
    menuOrder: { __type: "Int" },
    password: { __type: "String" },
    slug: { __type: "String" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  CreateJobPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    job: { __type: "Job" },
  },
  CreateMediaItemInput: {
    altText: { __type: "String" },
    authorId: { __type: "ID" },
    caption: { __type: "String" },
    clientMutationId: { __type: "String" },
    commentStatus: { __type: "String" },
    date: { __type: "String" },
    dateGmt: { __type: "String" },
    description: { __type: "String" },
    filePath: { __type: "String" },
    fileType: { __type: "MimeTypeEnum" },
    parentId: { __type: "ID" },
    pingStatus: { __type: "String" },
    slug: { __type: "String" },
    status: { __type: "MediaItemStatusEnum" },
    title: { __type: "String" },
  },
  CreateMediaItemPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    mediaItem: { __type: "MediaItem" },
  },
  CreatePageInput: {
    authorId: { __type: "ID" },
    clientMutationId: { __type: "String" },
    commentStatus: { __type: "String" },
    content: { __type: "String" },
    date: { __type: "String" },
    menuOrder: { __type: "Int" },
    parentId: { __type: "ID" },
    password: { __type: "String" },
    slug: { __type: "String" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  CreatePagePayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    page: { __type: "Page" },
  },
  CreatePostFormatInput: {
    aliasOf: { __type: "String" },
    clientMutationId: { __type: "String" },
    description: { __type: "String" },
    name: { __type: "String!" },
    slug: { __type: "String" },
  },
  CreatePostFormatPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    postFormat: { __type: "PostFormat" },
  },
  CreatePostInput: {
    authorId: { __type: "ID" },
    categories: { __type: "PostCategoriesInput" },
    clientMutationId: { __type: "String" },
    commentStatus: { __type: "String" },
    content: { __type: "String" },
    date: { __type: "String" },
    excerpt: { __type: "String" },
    menuOrder: { __type: "Int" },
    password: { __type: "String" },
    pingStatus: { __type: "String" },
    pinged: { __type: "[String]" },
    postFormats: { __type: "PostPostFormatsInput" },
    slug: { __type: "String" },
    status: { __type: "PostStatusEnum" },
    tags: { __type: "PostTagsInput" },
    title: { __type: "String" },
    toPing: { __type: "[String]" },
  },
  CreatePostPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    post: { __type: "Post" },
  },
  CreateReusableBlockInput: {
    clientMutationId: { __type: "String" },
    content: { __type: "String" },
    date: { __type: "String" },
    menuOrder: { __type: "Int" },
    password: { __type: "String" },
    slug: { __type: "String" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  CreateReusableBlockPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    reusableBlock: { __type: "ReusableBlock" },
  },
  CreateServiceInput: {
    clientMutationId: { __type: "String" },
    content: { __type: "String" },
    date: { __type: "String" },
    menuOrder: { __type: "Int" },
    password: { __type: "String" },
    slug: { __type: "String" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  CreateServicePayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    service: { __type: "Service" },
  },
  CreateSpecialtyInput: {
    clientMutationId: { __type: "String" },
    content: { __type: "String" },
    date: { __type: "String" },
    menuOrder: { __type: "Int" },
    password: { __type: "String" },
    slug: { __type: "String" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  CreateSpecialtyPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    specialty: { __type: "Specialty" },
  },
  CreateTagInput: {
    aliasOf: { __type: "String" },
    clientMutationId: { __type: "String" },
    description: { __type: "String" },
    name: { __type: "String!" },
    slug: { __type: "String" },
  },
  CreateTagPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    tag: { __type: "Tag" },
  },
  CreateTeam_memberInput: {
    clientMutationId: { __type: "String" },
    content: { __type: "String" },
    date: { __type: "String" },
    menuOrder: { __type: "Int" },
    password: { __type: "String" },
    slug: { __type: "String" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  CreateTeam_memberPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    team_member: { __type: "Team_member" },
  },
  CreateUserInput: {
    aim: { __type: "String" },
    clientMutationId: { __type: "String" },
    description: { __type: "String" },
    displayName: { __type: "String" },
    email: { __type: "String" },
    firstName: { __type: "String" },
    jabber: { __type: "String" },
    lastName: { __type: "String" },
    locale: { __type: "String" },
    nicename: { __type: "String" },
    nickname: { __type: "String" },
    password: { __type: "String" },
    registered: { __type: "String" },
    richEditing: { __type: "String" },
    roles: { __type: "[String]" },
    username: { __type: "String!" },
    websiteUrl: { __type: "String" },
    yim: { __type: "String" },
  },
  CreateUserPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    user: { __type: "User" },
  },
  CreateWorkInput: {
    clientMutationId: { __type: "String" },
    content: { __type: "String" },
    date: { __type: "String" },
    menuOrder: { __type: "Int" },
    password: { __type: "String" },
    slug: { __type: "String" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  CreateWorkPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    work: { __type: "Work" },
  },
  DatabaseIdentifier: {
    __typename: { __type: "String!" },
    databaseId: { __type: "Int!" },
    $on: { __type: "$DatabaseIdentifier!" },
  },
  DateField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    calendarIconType: { __type: "FormFieldCalendarIconTypeEnum" },
    calendarIconUrl: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    dateFormat: { __type: "DateFieldFormatEnum" },
    dateType: { __type: "DateFieldTypeEnum" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    inputs: { __type: "[DateInputProperty]" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    subLabelPlacement: { __type: "FormFieldSubLabelPlacementEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  DateInput: {
    day: { __type: "Int" },
    month: { __type: "Int" },
    year: { __type: "Int" },
  },
  DateInputProperty: {
    __typename: { __type: "String!" },
    autocompleteAttribute: { __type: "String" },
    customLabel: { __type: "String" },
    defaultValue: { __type: "String" },
    id: { __type: "Float" },
    label: { __type: "String" },
    placeholder: { __type: "String" },
  },
  DateQueryInput: {
    after: { __type: "DateInput" },
    before: { __type: "DateInput" },
    column: { __type: "PostObjectsConnectionDateColumnEnum" },
    compare: { __type: "String" },
    day: { __type: "Int" },
    hour: { __type: "Int" },
    inclusive: { __type: "Boolean" },
    minute: { __type: "Int" },
    month: { __type: "Int" },
    relation: { __type: "RelationEnum" },
    second: { __type: "Int" },
    week: { __type: "Int" },
    year: { __type: "Int" },
  },
  DefaultTemplate: {
    __typename: { __type: "String!" },
    templateName: { __type: "String" },
  },
  DeleteCategoryInput: {
    clientMutationId: { __type: "String" },
    id: { __type: "ID!" },
  },
  DeleteCategoryPayload: {
    __typename: { __type: "String!" },
    category: { __type: "Category" },
    clientMutationId: { __type: "String" },
    deletedId: { __type: "ID" },
  },
  DeleteCommentInput: {
    clientMutationId: { __type: "String" },
    forceDelete: { __type: "Boolean" },
    id: { __type: "ID!" },
  },
  DeleteCommentPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    comment: { __type: "Comment" },
    deletedId: { __type: "ID" },
  },
  DeleteGfDraftEntryInput: {
    clientMutationId: { __type: "String" },
    id: { __type: "ID!" },
    idType: { __type: "DraftEntryIdTypeEnum" },
  },
  DeleteGfDraftEntryPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    deletedId: { __type: "ID" },
    draftEntry: { __type: "GfDraftEntry" },
  },
  DeleteGfEntryInput: {
    clientMutationId: { __type: "String" },
    forceDelete: { __type: "Boolean" },
    id: { __type: "ID!" },
  },
  DeleteGfEntryPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    deletedId: { __type: "ID" },
    entry: { __type: "GfSubmittedEntry" },
  },
  DeleteJobInput: {
    clientMutationId: { __type: "String" },
    forceDelete: { __type: "Boolean" },
    id: { __type: "ID!" },
  },
  DeleteJobPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    deletedId: { __type: "ID" },
    job: { __type: "Job" },
  },
  DeleteMediaItemInput: {
    clientMutationId: { __type: "String" },
    forceDelete: { __type: "Boolean" },
    id: { __type: "ID!" },
  },
  DeleteMediaItemPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    deletedId: { __type: "ID" },
    mediaItem: { __type: "MediaItem" },
  },
  DeletePageInput: {
    clientMutationId: { __type: "String" },
    forceDelete: { __type: "Boolean" },
    id: { __type: "ID!" },
  },
  DeletePagePayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    deletedId: { __type: "ID" },
    page: { __type: "Page" },
  },
  DeletePostFormatInput: {
    clientMutationId: { __type: "String" },
    id: { __type: "ID!" },
  },
  DeletePostFormatPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    deletedId: { __type: "ID" },
    postFormat: { __type: "PostFormat" },
  },
  DeletePostInput: {
    clientMutationId: { __type: "String" },
    forceDelete: { __type: "Boolean" },
    id: { __type: "ID!" },
  },
  DeletePostPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    deletedId: { __type: "ID" },
    post: { __type: "Post" },
  },
  DeleteReusableBlockInput: {
    clientMutationId: { __type: "String" },
    forceDelete: { __type: "Boolean" },
    id: { __type: "ID!" },
  },
  DeleteReusableBlockPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    deletedId: { __type: "ID" },
    reusableBlock: { __type: "ReusableBlock" },
  },
  DeleteServiceInput: {
    clientMutationId: { __type: "String" },
    forceDelete: { __type: "Boolean" },
    id: { __type: "ID!" },
  },
  DeleteServicePayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    deletedId: { __type: "ID" },
    service: { __type: "Service" },
  },
  DeleteSpecialtyInput: {
    clientMutationId: { __type: "String" },
    forceDelete: { __type: "Boolean" },
    id: { __type: "ID!" },
  },
  DeleteSpecialtyPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    deletedId: { __type: "ID" },
    specialty: { __type: "Specialty" },
  },
  DeleteTagInput: {
    clientMutationId: { __type: "String" },
    id: { __type: "ID!" },
  },
  DeleteTagPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    deletedId: { __type: "ID" },
    tag: { __type: "Tag" },
  },
  DeleteTeam_memberInput: {
    clientMutationId: { __type: "String" },
    forceDelete: { __type: "Boolean" },
    id: { __type: "ID!" },
  },
  DeleteTeam_memberPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    deletedId: { __type: "ID" },
    team_member: { __type: "Team_member" },
  },
  DeleteUserInput: {
    clientMutationId: { __type: "String" },
    id: { __type: "ID!" },
    reassignId: { __type: "ID" },
  },
  DeleteUserPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    deletedId: { __type: "ID" },
    user: { __type: "User" },
  },
  DeleteWorkInput: {
    clientMutationId: { __type: "String" },
    forceDelete: { __type: "Boolean" },
    id: { __type: "ID!" },
  },
  DeleteWorkPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    deletedId: { __type: "ID" },
    work: { __type: "Work" },
  },
  DiscussionSettings: {
    __typename: { __type: "String!" },
    defaultCommentStatus: { __type: "String" },
    defaultPingStatus: { __type: "String" },
  },
  EmailField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasAutocomplete: { __type: "Boolean" },
    hasEmailConfirmation: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputType: { __type: "FormFieldTypeEnum" },
    inputs: { __type: "[EmailInputProperty]" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    subLabelPlacement: { __type: "FormFieldSubLabelPlacementEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  EmailFieldInput: {
    confirmationValue: { __type: "String" },
    value: { __type: "String" },
  },
  EmailInputProperty: {
    __typename: { __type: "String!" },
    autocompleteAttribute: { __type: "String" },
    customLabel: { __type: "String" },
    defaultValue: { __type: "String" },
    id: { __type: "Float" },
    label: { __type: "String" },
    name: { __type: "String" },
    placeholder: { __type: "String" },
  },
  EnqueuedAsset: {
    __typename: { __type: "String!" },
    args: { __type: "Boolean" },
    dependencies: { __type: "[EnqueuedScript]" },
    extra: { __type: "String" },
    handle: { __type: "String" },
    id: { __type: "ID!" },
    src: { __type: "String" },
    version: { __type: "String" },
    $on: { __type: "$EnqueuedAsset!" },
  },
  EnqueuedScript: {
    __typename: { __type: "String!" },
    args: { __type: "Boolean" },
    dependencies: { __type: "[EnqueuedScript]" },
    extra: { __type: "String" },
    handle: { __type: "String" },
    id: { __type: "ID!" },
    src: { __type: "String" },
    version: { __type: "String" },
  },
  EnqueuedStylesheet: {
    __typename: { __type: "String!" },
    args: { __type: "Boolean" },
    dependencies: { __type: "[EnqueuedScript]" },
    extra: { __type: "String" },
    handle: { __type: "String" },
    id: { __type: "ID!" },
    src: { __type: "String" },
    version: { __type: "String" },
  },
  EntriesConnectionOrderbyInput: {
    field: { __type: "String" },
    isNumeric: { __type: "Boolean" },
    order: { __type: "OrderEnum" },
  },
  EntriesDateFiltersInput: {
    endDate: { __type: "String" },
    startDate: { __type: "String" },
  },
  EntriesFieldFiltersInput: {
    boolValues: { __type: "[Boolean]" },
    floatValues: { __type: "[Float]" },
    intValues: { __type: "[Int]" },
    key: { __type: "String" },
    operator: { __type: "FieldFiltersOperatorEnum" },
    stringValues: { __type: "[String]" },
  },
  FieldError: {
    __typename: { __type: "String!" },
    id: { __type: "Float" },
    message: { __type: "String" },
  },
  FileUploadField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    allowedExtensions: { __type: "[String]" },
    canAcceptMultipleFiles: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    fileUploadValues: { __type: "[FileUploadFieldValue]" },
    id: { __type: "Int!" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    maxFileSize: { __type: "Int" },
    maxFiles: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    values: { __type: "[String]" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  FileUploadFieldValue: {
    __typename: { __type: "String!" },
    basePath: { __type: "String" },
    baseUrl: { __type: "String" },
    filename: { __type: "String" },
    url: { __type: "String" },
  },
  FormButton: {
    __typename: { __type: "String!" },
    conditionalLogic: { __type: "ConditionalLogic" },
    imageUrl: { __type: "String" },
    text: { __type: "String" },
    type: { __type: "FormButtonTypeEnum" },
  },
  FormConfirmation: {
    __typename: { __type: "String!" },
    conditionalLogic: { __type: "ConditionalLogic" },
    id: { __type: "String" },
    isActive: { __type: "Boolean" },
    isDefault: { __type: "Boolean" },
    message: { __type: "String" },
    name: { __type: "String" },
    pageId: { __type: "Int" },
    queryString: { __type: "String" },
    type: { __type: "FormConfirmationTypeEnum" },
    url: { __type: "String" },
  },
  FormDataPolicies: {
    __typename: { __type: "String!" },
    canExportAndErase: { __type: "Boolean" },
    entryData: { __type: "[FormEntryDataPolicy]" },
    identificationFieldDatabaseId: { __type: "Int" },
  },
  FormEntryDataPolicy: {
    __typename: { __type: "String!" },
    key: { __type: "String" },
    shouldErase: { __type: "Boolean" },
    shouldExport: { __type: "Boolean" },
  },
  FormEntryLimits: {
    __typename: { __type: "String!" },
    hasLimit: { __type: "Boolean" },
    limitReachedMessage: { __type: "String" },
    limitationPeriod: { __type: "FormLimitEntriesPeriodEnum" },
    maxEntries: { __type: "Int" },
  },
  FormField: {
    __typename: { __type: "String!" },
    displayOnly: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputType: { __type: "FormFieldTypeEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    type: { __type: "FormFieldTypeEnum" },
    visibility: { __type: "FormFieldVisibilityEnum" },
    $on: { __type: "$FormField!" },
  },
  FormFieldDataPolicy: {
    __typename: { __type: "String!" },
    isIdentificationField: { __type: "Boolean" },
    shouldErase: { __type: "Boolean" },
    shouldExport: { __type: "Boolean" },
  },
  FormFieldValuesInput: {
    addressValues: { __type: "AddressFieldInput" },
    checkboxValues: { __type: "[CheckboxFieldInput]" },
    emailValues: { __type: "EmailFieldInput" },
    id: { __type: "Int!" },
    listValues: { __type: "[ListFieldInput]" },
    nameValues: { __type: "NameFieldInput" },
    value: { __type: "String" },
    values: { __type: "[String]" },
  },
  FormLastPageButton: {
    __typename: { __type: "String!" },
    imageUrl: { __type: "String" },
    text: { __type: "String" },
    type: { __type: "FormButtonTypeEnum" },
  },
  FormLogin: {
    __typename: { __type: "String!" },
    isLoginRequired: { __type: "Boolean" },
    loginRequiredMessage: { __type: "String" },
  },
  FormNotification: {
    __typename: { __type: "String!" },
    bcc: { __type: "String" },
    conditionalLogic: { __type: "ConditionalLogic" },
    event: { __type: "String" },
    from: { __type: "String" },
    fromName: { __type: "String" },
    id: { __type: "String" },
    isActive: { __type: "Boolean" },
    isAutoformatted: { __type: "Boolean" },
    message: { __type: "String" },
    name: { __type: "String" },
    replyTo: { __type: "String" },
    routing: { __type: "[FormNotificationRouting]" },
    service: { __type: "String" },
    shouldSendAttachments: { __type: "Boolean" },
    subject: { __type: "String" },
    to: { __type: "String" },
    toType: { __type: "FormNotificationToTypeEnum" },
  },
  FormNotificationRouting: {
    __typename: { __type: "String!" },
    email: { __type: "String" },
    fieldId: { __type: "Int" },
    operator: { __type: "FormRuleOperatorEnum" },
    value: { __type: "String" },
  },
  FormPagination: {
    __typename: { __type: "String!" },
    backgroundColor: { __type: "String" },
    color: { __type: "String" },
    hasProgressbarOnConfirmation: { __type: "Boolean" },
    lastPageButton: { __type: "FormLastPageButton" },
    pageNames: { __type: "[String]" },
    progressbarCompletionText: { __type: "String" },
    style: { __type: "FormPageProgressStyleEnum" },
    type: { __type: "FormPageProgressTypeEnum" },
  },
  FormPersonalData: {
    __typename: { __type: "String!" },
    dataPolicies: { __type: "FormDataPolicies" },
    daysToRetain: { __type: "Int" },
    retentionPolicy: { __type: "FormRetentionPolicyEnum" },
    shouldSaveIP: { __type: "Boolean" },
  },
  FormPostCreation: {
    __typename: { __type: "String!" },
    author: { __type: "User" },
    authorDatabaseId: { __type: "Int" },
    authorId: { __type: "ID" },
    categoryDatabaseId: { __type: "Int" },
    contentTemplate: { __type: "String" },
    format: { __type: "PostFormatTypeEnum" },
    hasContentTemplate: { __type: "Boolean" },
    hasTitleTemplate: { __type: "Boolean" },
    shouldUseCurrentUserAsAuthor: { __type: "Boolean" },
    status: { __type: "String" },
    titleTemplate: { __type: "String" },
  },
  FormSaveAndContinue: {
    __typename: { __type: "String!" },
    buttonText: { __type: "String" },
    hasSaveAndContinue: { __type: "Boolean" },
  },
  FormSchedule: {
    __typename: { __type: "String!" },
    closedMessage: { __type: "String" },
    endDetails: { __type: "FormScheduleDetails" },
    hasSchedule: { __type: "Boolean" },
    pendingMessage: { __type: "String" },
    startDetails: { __type: "FormScheduleDetails" },
  },
  FormScheduleDetails: {
    __typename: { __type: "String!" },
    amPm: { __type: "AmPmEnum" },
    date: { __type: "String" },
    dateGmt: { __type: "String" },
    hour: { __type: "Int" },
    minute: { __type: "Int" },
  },
  FormSubmitButton: {
    __typename: { __type: "String!" },
    conditionalLogic: { __type: "ConditionalLogic" },
    imageUrl: { __type: "String" },
    layoutGridColumnSpan: { __type: "Int" },
    location: { __type: "FormSubmitButtonLocationEnum" },
    text: { __type: "String" },
    type: { __type: "FormButtonTypeEnum" },
    width: { __type: "FormSubmitButtonWidthEnum" },
  },
  FormsConnectionOrderbyInput: {
    field: { __type: "String" },
    order: { __type: "OrderEnum" },
  },
  GeneralOptionsSettings: {
    __typename: { __type: "String!" },
    generalSettings: { __type: "GeneralOptionsSettings_Generalsettings" },
    pageSlug: { __type: "String" },
    pageTitle: { __type: "String" },
  },
  GeneralOptionsSettings_Generalsettings: {
    __typename: { __type: "String!" },
    address: { __type: "String" },
    ceoLink: { __type: "String" },
    clientSupportUrl: { __type: "String" },
    email: { __type: "String" },
    fieldGroupName: { __type: "String" },
    googleMapsUrl: { __type: "String" },
    instagramUrl: { __type: "String" },
    linkedinUrl: { __type: "String" },
    logo: { __type: "MediaItem" },
    phone: { __type: "String" },
  },
  GeneralSettings: {
    __typename: { __type: "String!" },
    dateFormat: { __type: "String" },
    description: { __type: "String" },
    email: { __type: "String" },
    language: { __type: "String" },
    startOfWeek: { __type: "Int" },
    timeFormat: { __type: "String" },
    timezone: { __type: "String" },
    title: { __type: "String" },
    url: { __type: "String" },
  },
  GenerateAuthorizationCodeInput: {
    clientMutationId: { __type: "String" },
    email: { __type: "String" },
    password: { __type: "String" },
    username: { __type: "String" },
  },
  GenerateAuthorizationCodePayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    code: { __type: "String" },
    error: { __type: "String" },
  },
  GenesisCustomBlocksHeroBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "GenesisCustomBlocksHeroBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  GenesisCustomBlocksHeroBlockAttributes: {
    __typename: { __type: "String!" },
    className: { __type: "String" },
    content: { __type: "String" },
    heading: { __type: "String" },
    link: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
  },
  GfDraftEntry: {
    __typename: { __type: "String!" },
    createdBy: { __type: "User" },
    createdByDatabaseId: { __type: "Int" },
    createdById: { __type: "ID" },
    dateCreated: { __type: "String" },
    dateCreatedGmt: { __type: "String" },
    dateUpdated: { __type: "String" },
    dateUpdatedGmt: { __type: "String" },
    form: { __type: "GfForm" },
    formDatabaseId: { __type: "Int" },
    formFields: {
      __type: "GfEntryToFormFieldConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "GfEntryToFormFieldConnectionWhereArgs",
      },
    },
    formId: { __type: "ID" },
    id: { __type: "ID!" },
    ip: { __type: "String" },
    isDraft: { __type: "Boolean" },
    isSubmitted: { __type: "Boolean" },
    resumeToken: { __type: "String" },
    sourceUrl: { __type: "String" },
    userAgent: { __type: "String" },
  },
  GfEntry: {
    __typename: { __type: "String!" },
    createdBy: { __type: "User" },
    createdByDatabaseId: { __type: "Int" },
    createdById: { __type: "ID" },
    dateCreated: { __type: "String" },
    dateCreatedGmt: { __type: "String" },
    dateUpdated: { __type: "String" },
    dateUpdatedGmt: { __type: "String" },
    form: { __type: "GfForm" },
    formDatabaseId: { __type: "Int" },
    formFields: {
      __type: "GfEntryToFormFieldConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "GfEntryToFormFieldConnectionWhereArgs",
      },
    },
    formId: { __type: "ID" },
    id: { __type: "ID!" },
    ip: { __type: "String" },
    isDraft: { __type: "Boolean" },
    isSubmitted: { __type: "Boolean" },
    sourceUrl: { __type: "String" },
    userAgent: { __type: "String" },
    $on: { __type: "$GfEntry!" },
  },
  GfEntryToFormFieldConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[GfEntryToFormFieldConnectionEdge]" },
    nodes: { __type: "[FormField]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  GfEntryToFormFieldConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "FormField" },
  },
  GfEntryToFormFieldConnectionWhereArgs: {
    adminLabels: { __type: "[String]" },
    fieldTypes: { __type: "[FormFieldTypeEnum]" },
    ids: { __type: "[ID]" },
    pageNumber: { __type: "Int" },
  },
  GfForm: {
    __typename: { __type: "String!" },
    button: { __type: "FormSubmitButton" },
    confirmations: { __type: "[FormConfirmation]" },
    cssClass: { __type: "String" },
    customRequiredIndicator: { __type: "String" },
    databaseId: { __type: "Int!" },
    dateCreated: { __type: "String" },
    dateCreatedGmt: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormDescriptionPlacementEnum" },
    entries: {
      __type: "GfFormToGfEntryConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "GfFormToGfEntryConnectionWhereArgs",
      },
    },
    entryLimits: { __type: "FormEntryLimits" },
    firstPageCssClass: { __type: "String" },
    formFields: {
      __type: "GfFormToFormFieldConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "GfFormToFormFieldConnectionWhereArgs",
      },
    },
    formId: { __type: "Int" },
    hasConditionalLogicAnimation: { __type: "Boolean" },
    hasHoneypot: { __type: "Boolean" },
    hasValidationSummary: { __type: "Boolean" },
    id: { __type: "ID!" },
    isActive: { __type: "Boolean" },
    isTrash: { __type: "Boolean" },
    labelPlacement: { __type: "FormLabelPlacementEnum" },
    lastPageButton: { __type: "FormLastPageButton" },
    login: { __type: "FormLogin" },
    markupVersion: { __type: "Int" },
    nextFieldId: { __type: "Int" },
    notifications: { __type: "[FormNotification]" },
    pagination: { __type: "FormPagination" },
    personalData: { __type: "FormPersonalData" },
    postCreation: { __type: "FormPostCreation" },
    requiredIndicator: { __type: "FormFieldRequiredIndicatorEnum" },
    saveAndContinue: { __type: "FormSaveAndContinue" },
    scheduling: { __type: "FormSchedule" },
    subLabelPlacement: { __type: "FormSubLabelPlacementEnum" },
    submitButton: { __type: "FormSubmitButton" },
    title: { __type: "String" },
    version: { __type: "String" },
  },
  GfFormToFormFieldConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[GfFormToFormFieldConnectionEdge]" },
    nodes: { __type: "[FormField]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  GfFormToFormFieldConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "FormField" },
  },
  GfFormToFormFieldConnectionWhereArgs: {
    adminLabels: { __type: "[String]" },
    fieldTypes: { __type: "[FormFieldTypeEnum]" },
    ids: { __type: "[ID]" },
    pageNumber: { __type: "Int" },
  },
  GfFormToGfEntryConnection: {
    __typename: { __type: "String!" },
    count: { __type: "Int" },
    edges: { __type: "[GfFormToGfEntryConnectionEdge]" },
    nodes: { __type: "[GfEntry]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  GfFormToGfEntryConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "GfEntry" },
  },
  GfFormToGfEntryConnectionWhereArgs: {
    dateFilters: { __type: "EntriesDateFiltersInput" },
    fieldFilters: { __type: "[EntriesFieldFiltersInput]" },
    fieldFiltersMode: { __type: "FieldFiltersModeEnum" },
    orderby: { __type: "EntriesConnectionOrderbyInput" },
    status: { __type: "EntryStatusEnum" },
  },
  GfLogger: {
    __typename: { __type: "String!" },
    isEnabled: { __type: "Boolean" },
    name: { __type: "String" },
  },
  GfSettings: {
    __typename: { __type: "String!" },
    currency: { __type: "GfCurrencyEnum" },
    hasBackgroundUpdates: { __type: "Boolean" },
    hasDefaultCss: { __type: "Boolean" },
    hasToolbar: { __type: "Boolean" },
    isHtml5Enabled: { __type: "Boolean" },
    isNoConflictModeEnabled: { __type: "Boolean" },
    logging: { __type: "GfSettingsLogging" },
  },
  GfSettingsLogging: {
    __typename: { __type: "String!" },
    isLoggingEnabled: { __type: "Boolean" },
    loggers: { __type: "[GfLogger]" },
  },
  GfSubmittedEntry: {
    __typename: { __type: "String!" },
    createdBy: { __type: "User" },
    createdByDatabaseId: { __type: "Int" },
    createdById: { __type: "ID" },
    databaseId: { __type: "Int!" },
    dateCreated: { __type: "String" },
    dateCreatedGmt: { __type: "String" },
    dateUpdated: { __type: "String" },
    dateUpdatedGmt: { __type: "String" },
    entryId: { __type: "Int" },
    form: { __type: "GfForm" },
    formDatabaseId: { __type: "Int" },
    formFields: {
      __type: "GfEntryToFormFieldConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "GfEntryToFormFieldConnectionWhereArgs",
      },
    },
    formId: { __type: "ID" },
    id: { __type: "ID!" },
    ip: { __type: "String" },
    isDraft: { __type: "Boolean" },
    isRead: { __type: "Boolean" },
    isStarred: { __type: "Boolean" },
    isSubmitted: { __type: "Boolean" },
    post: { __type: "Post" },
    postDatabaseId: { __type: "Int" },
    sourceUrl: { __type: "String" },
    status: { __type: "EntryStatusEnum" },
    userAgent: { __type: "String" },
  },
  GravityformsFormBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "GravityformsFormBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  GravityformsFormBlockAttributes: {
    __typename: { __type: "String!" },
    ajax: { __type: "Boolean!" },
    description: { __type: "Boolean!" },
    fieldValues: { __type: "String" },
    formId: { __type: "String" },
    formPreview: { __type: "Boolean!" },
    imgPreview: { __type: "Boolean!" },
    lock: { __type: "BlockAttributesObject" },
    tabindex: { __type: "String" },
    title: { __type: "Boolean!" },
  },
  HiddenField: {
    __typename: { __type: "String!" },
    canPrepopulate: { __type: "Boolean" },
    defaultValue: { __type: "String" },
    displayOnly: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    label: { __type: "String" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  HierarchicalContentNode: {
    __typename: { __type: "String!" },
    ancestors: {
      __type: "HierarchicalContentNodeToContentNodeAncestorsConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where:
          "HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs",
      },
    },
    children: {
      __type: "HierarchicalContentNodeToContentNodeChildrenConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where:
          "HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs",
      },
    },
    parent: {
      __type: "HierarchicalContentNodeToParentContentNodeConnectionEdge",
    },
    parentDatabaseId: { __type: "Int" },
    parentId: { __type: "ID" },
    $on: { __type: "$HierarchicalContentNode!" },
  },
  HierarchicalContentNodeToContentNodeAncestorsConnection: {
    __typename: { __type: "String!" },
    edges: {
      __type: "[HierarchicalContentNodeToContentNodeAncestorsConnectionEdge]",
    },
    nodes: { __type: "[ContentNode]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  HierarchicalContentNodeToContentNodeAncestorsConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "ContentNode" },
  },
  HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs: {
    contentTypes: { __type: "[ContentTypeEnum]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  HierarchicalContentNodeToContentNodeChildrenConnection: {
    __typename: { __type: "String!" },
    edges: {
      __type: "[HierarchicalContentNodeToContentNodeChildrenConnectionEdge]",
    },
    nodes: { __type: "[ContentNode]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  HierarchicalContentNodeToContentNodeChildrenConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "ContentNode" },
  },
  HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs: {
    contentTypes: { __type: "[ContentTypeEnum]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  HierarchicalContentNodeToParentContentNodeConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "ContentNode" },
  },
  HierarchicalTermNode: {
    __typename: { __type: "String!" },
    parentDatabaseId: { __type: "Int" },
    parentId: { __type: "ID" },
    $on: { __type: "$HierarchicalTermNode!" },
  },
  HtmlField: {
    __typename: { __type: "String!" },
    conditionalLogic: { __type: "ConditionalLogic" },
    content: { __type: "String" },
    cssClass: { __type: "String" },
    displayOnly: { __type: "Boolean" },
    hasMargins: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputType: { __type: "FormFieldTypeEnum" },
    label: { __type: "String" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    type: { __type: "FormFieldTypeEnum" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  IdeaboxTocBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "IdeaboxTocBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  IdeaboxTocBlockAttributes: {
    __typename: { __type: "String!" },
    align: { __type: "String" },
    anchorsByTags: { __type: "BlockAttributesArray!" },
    bgColor: { __type: "String!" },
    borderColor: { __type: "String!" },
    borderRadius: { __type: "Int!" },
    borderWidth: { __type: "Int!" },
    className: { __type: "String" },
    collapsable: { __type: "Boolean!" },
    collapsableIcons: { __type: "String!" },
    excludeContainer: { __type: "String!" },
    extraOffset: { __type: "Int!" },
    fontSize: { __type: "Int!" },
    headerBgColor: { __type: "String!" },
    headerFontSize: { __type: "Int!" },
    headerTextColor: { __type: "String!" },
    hierarchicalView: { __type: "Boolean!" },
    includeContainer: { __type: "String!" },
    linkColor: { __type: "String!" },
    listSpacing: { __type: "Int!" },
    listStyle: { __type: "String!" },
    lock: { __type: "BlockAttributesObject" },
    separatorColor: { __type: "String!" },
    separatorHeight: { __type: "Int!" },
    textColor: { __type: "String!" },
  },
  ImageFieldValue: {
    __typename: { __type: "String!" },
    altText: { __type: "String" },
    basePath: { __type: "String" },
    baseUrl: { __type: "String" },
    caption: { __type: "String" },
    description: { __type: "String" },
    filename: { __type: "String" },
    title: { __type: "String" },
    url: { __type: "String" },
  },
  Job: {
    __typename: { __type: "String!" },
    blocks: { __type: "[Block!]" },
    blocksJSON: { __type: "String" },
    conditionalTags: { __type: "ConditionalTags" },
    content: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    contentType: { __type: "ContentNodeToContentTypeConnectionEdge" },
    contentTypeName: { __type: "String!" },
    databaseId: { __type: "Int!" },
    date: { __type: "String" },
    dateGmt: { __type: "String" },
    desiredSlug: { __type: "String" },
    editingLockedBy: { __type: "ContentNodeToEditLockConnectionEdge" },
    enclosure: { __type: "String" },
    enqueuedScripts: {
      __type: "ContentNodeToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    enqueuedStylesheets: {
      __type: "ContentNodeToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    guid: { __type: "String" },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isPreview: { __type: "Boolean" },
    isRestricted: { __type: "Boolean" },
    isTermNode: { __type: "Boolean!" },
    jobACF: { __type: "Job_Jobacf" },
    jobId: { __type: "Int!" },
    lastEditedBy: { __type: "ContentNodeToEditLastConnectionEdge" },
    link: { __type: "String" },
    modified: { __type: "String" },
    modifiedGmt: { __type: "String" },
    preview: { __type: "JobToPreviewConnectionEdge" },
    previewBlocks: { __type: "[Block!]" },
    previewBlocksJSON: { __type: "String" },
    previewRevisionDatabaseId: { __type: "Int" },
    previewRevisionId: { __type: "ID" },
    seo: { __type: "PostTypeSEO" },
    slug: { __type: "String" },
    status: { __type: "String" },
    template: { __type: "ContentTemplate" },
    templates: { __type: "[String]" },
    title: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    uri: { __type: "String" },
  },
  JobToPreviewConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "Job" },
  },
  Job_Jobacf: {
    __typename: { __type: "String!" },
    description: { __type: "String" },
    fieldGroupName: { __type: "String" },
    link: { __type: "String" },
    location: { __type: "String" },
  },
  ListField: {
    __typename: { __type: "String!" },
    addIconUrl: { __type: "String" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    choices: { __type: "[ListFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    deleteIconUrl: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasColumns: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    listValues: { __type: "[ListFieldValue]" },
    maxRows: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  ListFieldChoice: {
    __typename: { __type: "String!" },
    text: { __type: "String" },
    value: { __type: "String" },
  },
  ListFieldInput: { rowValues: { __type: "[String]" } },
  ListFieldValue: {
    __typename: { __type: "String!" },
    values: { __type: "[String]" },
  },
  MediaDetails: {
    __typename: { __type: "String!" },
    file: { __type: "String" },
    height: { __type: "Int" },
    meta: { __type: "MediaItemMeta" },
    sizes: { __type: "[MediaSize]" },
    width: { __type: "Int" },
  },
  MediaItem: {
    __typename: { __type: "String!" },
    altText: { __type: "String" },
    ancestors: {
      __type: "HierarchicalContentNodeToContentNodeAncestorsConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where:
          "HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs",
      },
    },
    author: { __type: "NodeWithAuthorToUserConnectionEdge" },
    authorDatabaseId: { __type: "Int" },
    authorId: { __type: "ID" },
    caption: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    children: {
      __type: "HierarchicalContentNodeToContentNodeChildrenConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where:
          "HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs",
      },
    },
    commentCount: { __type: "Int" },
    commentStatus: { __type: "String" },
    comments: {
      __type: "MediaItemToCommentConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "MediaItemToCommentConnectionWhereArgs",
      },
    },
    conditionalTags: { __type: "ConditionalTags" },
    contentType: { __type: "ContentNodeToContentTypeConnectionEdge" },
    contentTypeName: { __type: "String!" },
    databaseId: { __type: "Int!" },
    date: { __type: "String" },
    dateGmt: { __type: "String" },
    description: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    desiredSlug: { __type: "String" },
    editingLockedBy: { __type: "ContentNodeToEditLockConnectionEdge" },
    enclosure: { __type: "String" },
    enqueuedScripts: {
      __type: "ContentNodeToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    enqueuedStylesheets: {
      __type: "ContentNodeToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    fileSize: { __type: "Int", __args: { size: "MediaItemSizeEnum" } },
    guid: { __type: "String" },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isPreview: { __type: "Boolean" },
    isRestricted: { __type: "Boolean" },
    isTermNode: { __type: "Boolean!" },
    lastEditedBy: { __type: "ContentNodeToEditLastConnectionEdge" },
    link: { __type: "String" },
    mediaDetails: { __type: "MediaDetails" },
    mediaItemId: { __type: "Int!" },
    mediaItemUrl: { __type: "String" },
    mediaType: { __type: "String" },
    mimeType: { __type: "String" },
    modified: { __type: "String" },
    modifiedGmt: { __type: "String" },
    parent: {
      __type: "HierarchicalContentNodeToParentContentNodeConnectionEdge",
    },
    parentDatabaseId: { __type: "Int" },
    parentId: { __type: "ID" },
    previewRevisionDatabaseId: { __type: "Int" },
    previewRevisionId: { __type: "ID" },
    seo: { __type: "PostTypeSEO" },
    sizes: { __type: "String", __args: { size: "MediaItemSizeEnum" } },
    slug: { __type: "String" },
    sourceUrl: { __type: "String", __args: { size: "MediaItemSizeEnum" } },
    srcSet: { __type: "String", __args: { size: "MediaItemSizeEnum" } },
    status: { __type: "String" },
    template: { __type: "ContentTemplate" },
    templates: { __type: "[String]" },
    title: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    uri: { __type: "String" },
  },
  MediaItemMeta: {
    __typename: { __type: "String!" },
    aperture: { __type: "Float" },
    camera: { __type: "String" },
    caption: { __type: "String" },
    copyright: { __type: "String" },
    createdTimestamp: { __type: "Int" },
    credit: { __type: "String" },
    focalLength: { __type: "Float" },
    iso: { __type: "Int" },
    keywords: { __type: "[String]" },
    orientation: { __type: "String" },
    shutterSpeed: { __type: "Float" },
    title: { __type: "String" },
  },
  MediaItemToCommentConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[MediaItemToCommentConnectionEdge]" },
    nodes: { __type: "[Comment]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  MediaItemToCommentConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Comment" },
  },
  MediaItemToCommentConnectionWhereArgs: {
    authorEmail: { __type: "String" },
    authorIn: { __type: "[ID]" },
    authorNotIn: { __type: "[ID]" },
    authorUrl: { __type: "String" },
    commentIn: { __type: "[ID]" },
    commentNotIn: { __type: "[ID]" },
    commentType: { __type: "String" },
    commentTypeIn: { __type: "[String]" },
    commentTypeNotIn: { __type: "String" },
    contentAuthor: { __type: "[ID]" },
    contentAuthorIn: { __type: "[ID]" },
    contentAuthorNotIn: { __type: "[ID]" },
    contentId: { __type: "ID" },
    contentIdIn: { __type: "[ID]" },
    contentIdNotIn: { __type: "[ID]" },
    contentName: { __type: "String" },
    contentParent: { __type: "Int" },
    contentStatus: { __type: "[PostStatusEnum]" },
    contentType: { __type: "[ContentTypeEnum]" },
    includeUnapproved: { __type: "[ID]" },
    karma: { __type: "Int" },
    order: { __type: "OrderEnum" },
    orderby: { __type: "CommentsConnectionOrderbyEnum" },
    parent: { __type: "Int" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    search: { __type: "String" },
    status: { __type: "String" },
    userId: { __type: "ID" },
  },
  MediaSize: {
    __typename: { __type: "String!" },
    file: { __type: "String" },
    fileSize: { __type: "Int" },
    height: { __type: "String" },
    mimeType: { __type: "String" },
    name: { __type: "String" },
    sourceUrl: { __type: "String" },
    width: { __type: "String" },
  },
  Menu: {
    __typename: { __type: "String!" },
    count: { __type: "Int" },
    databaseId: { __type: "Int!" },
    id: { __type: "ID!" },
    isRestricted: { __type: "Boolean" },
    locations: { __type: "[MenuLocationEnum]" },
    menuId: { __type: "Int" },
    menuItems: {
      __type: "MenuToMenuItemConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "MenuToMenuItemConnectionWhereArgs",
      },
    },
    name: { __type: "String" },
    slug: { __type: "String" },
  },
  MenuItem: {
    __typename: { __type: "String!" },
    childItems: {
      __type: "MenuItemToMenuItemConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "MenuItemToMenuItemConnectionWhereArgs",
      },
    },
    connectedNode: { __type: "MenuItemToMenuItemLinkableConnectionEdge" },
    connectedObject: { __type: "MenuItemObjectUnion" },
    cssClasses: { __type: "[String]" },
    databaseId: { __type: "Int!" },
    description: { __type: "String" },
    id: { __type: "ID!" },
    isRestricted: { __type: "Boolean" },
    label: { __type: "String" },
    linkRelationship: { __type: "String" },
    locations: { __type: "[MenuLocationEnum]" },
    menu: { __type: "MenuItemToMenuConnectionEdge" },
    menuItemId: { __type: "Int" },
    order: { __type: "Int" },
    parentDatabaseId: { __type: "Int" },
    parentId: { __type: "ID" },
    path: { __type: "String" },
    target: { __type: "String" },
    title: { __type: "String" },
    uri: { __type: "String" },
    url: { __type: "String" },
  },
  MenuItemLinkable: {
    __typename: { __type: "String!" },
    databaseId: { __type: "Int!" },
    id: { __type: "ID!" },
    uri: { __type: "String" },
    $on: { __type: "$MenuItemLinkable!" },
  },
  MenuItemObjectUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$MenuItemObjectUnion!" },
  },
  MenuItemToMenuConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "Menu" },
  },
  MenuItemToMenuItemConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[MenuItemToMenuItemConnectionEdge]" },
    nodes: { __type: "[MenuItem]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  MenuItemToMenuItemConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "MenuItem" },
  },
  MenuItemToMenuItemConnectionWhereArgs: {
    id: { __type: "Int" },
    location: { __type: "MenuLocationEnum" },
    parentDatabaseId: { __type: "Int" },
    parentId: { __type: "ID" },
  },
  MenuItemToMenuItemLinkableConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "MenuItemLinkable" },
  },
  MenuToMenuItemConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[MenuToMenuItemConnectionEdge]" },
    nodes: { __type: "[MenuItem]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  MenuToMenuItemConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "MenuItem" },
  },
  MenuToMenuItemConnectionWhereArgs: {
    id: { __type: "Int" },
    location: { __type: "MenuLocationEnum" },
    parentDatabaseId: { __type: "Int" },
    parentId: { __type: "ID" },
  },
  MultiSelectField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    choices: { __type: "[MultiSelectFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasChoiceValue: { __type: "Boolean" },
    hasEnhancedUI: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    values: { __type: "[String]" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  MultiSelectFieldChoice: {
    __typename: { __type: "String!" },
    isSelected: { __type: "Boolean" },
    text: { __type: "String" },
    value: { __type: "String" },
  },
  NameField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasAutocomplete: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    inputs: { __type: "[NameInputProperty]" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    nameValues: { __type: "NameFieldValue" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    subLabelPlacement: { __type: "FormFieldSubLabelPlacementEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  NameFieldChoice: {
    __typename: { __type: "String!" },
    isSelected: { __type: "Boolean" },
    text: { __type: "String" },
    value: { __type: "String" },
  },
  NameFieldInput: {
    first: { __type: "String" },
    last: { __type: "String" },
    middle: { __type: "String" },
    prefix: { __type: "String" },
    suffix: { __type: "String" },
  },
  NameFieldValue: {
    __typename: { __type: "String!" },
    first: { __type: "String" },
    last: { __type: "String" },
    middle: { __type: "String" },
    prefix: { __type: "String" },
    suffix: { __type: "String" },
  },
  NameInputProperty: {
    __typename: { __type: "String!" },
    autocompleteAttribute: { __type: "String" },
    choices: { __type: "[NameFieldChoice]" },
    customLabel: { __type: "String" },
    defaultValue: { __type: "String" },
    hasChoiceValue: { __type: "Boolean" },
    id: { __type: "Float" },
    isHidden: { __type: "Boolean" },
    key: { __type: "String" },
    label: { __type: "String" },
    name: { __type: "String" },
    placeholder: { __type: "String" },
  },
  Node: {
    __typename: { __type: "String!" },
    id: { __type: "ID!" },
    $on: { __type: "$Node!" },
  },
  NodeWithAuthor: {
    __typename: { __type: "String!" },
    author: { __type: "NodeWithAuthorToUserConnectionEdge" },
    authorDatabaseId: { __type: "Int" },
    authorId: { __type: "ID" },
    $on: { __type: "$NodeWithAuthor!" },
  },
  NodeWithAuthorToUserConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "User" },
  },
  NodeWithComments: {
    __typename: { __type: "String!" },
    commentCount: { __type: "Int" },
    commentStatus: { __type: "String" },
    $on: { __type: "$NodeWithComments!" },
  },
  NodeWithContentEditor: {
    __typename: { __type: "String!" },
    content: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    $on: { __type: "$NodeWithContentEditor!" },
  },
  NodeWithExcerpt: {
    __typename: { __type: "String!" },
    excerpt: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    $on: { __type: "$NodeWithExcerpt!" },
  },
  NodeWithFeaturedImage: {
    __typename: { __type: "String!" },
    conditionalTags: { __type: "ConditionalTags" },
    contentType: { __type: "ContentNodeToContentTypeConnectionEdge" },
    contentTypeName: { __type: "String!" },
    databaseId: { __type: "Int!" },
    date: { __type: "String" },
    dateGmt: { __type: "String" },
    desiredSlug: { __type: "String" },
    editingLockedBy: { __type: "ContentNodeToEditLockConnectionEdge" },
    enclosure: { __type: "String" },
    enqueuedScripts: {
      __type: "ContentNodeToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    enqueuedStylesheets: {
      __type: "ContentNodeToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    featuredImage: { __type: "NodeWithFeaturedImageToMediaItemConnectionEdge" },
    featuredImageDatabaseId: { __type: "Int" },
    featuredImageId: { __type: "ID" },
    guid: { __type: "String" },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isPreview: { __type: "Boolean" },
    isRestricted: { __type: "Boolean" },
    isTermNode: { __type: "Boolean!" },
    lastEditedBy: { __type: "ContentNodeToEditLastConnectionEdge" },
    link: { __type: "String" },
    modified: { __type: "String" },
    modifiedGmt: { __type: "String" },
    previewRevisionDatabaseId: { __type: "Int" },
    previewRevisionId: { __type: "ID" },
    seo: { __type: "PostTypeSEO" },
    slug: { __type: "String" },
    status: { __type: "String" },
    template: { __type: "ContentTemplate" },
    templates: { __type: "[String]" },
    uri: { __type: "String" },
    $on: { __type: "$NodeWithFeaturedImage!" },
  },
  NodeWithFeaturedImageToMediaItemConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "MediaItem" },
  },
  NodeWithForm: {
    __typename: { __type: "String!" },
    form: { __type: "GfForm" },
    formDatabaseId: { __type: "Int" },
    formId: { __type: "ID" },
    $on: { __type: "$NodeWithForm!" },
  },
  NodeWithPageAttributes: {
    __typename: { __type: "String!" },
    menuOrder: { __type: "Int" },
    $on: { __type: "$NodeWithPageAttributes!" },
  },
  NodeWithRevisions: {
    __typename: { __type: "String!" },
    isRevision: { __type: "Boolean" },
    revisionOf: { __type: "NodeWithRevisionsToContentNodeConnectionEdge" },
    $on: { __type: "$NodeWithRevisions!" },
  },
  NodeWithRevisionsToContentNodeConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "ContentNode" },
  },
  NodeWithTemplate: {
    __typename: { __type: "String!" },
    template: { __type: "ContentTemplate" },
    $on: { __type: "$NodeWithTemplate!" },
  },
  NodeWithTitle: {
    __typename: { __type: "String!" },
    seo: { __type: "PostTypeSEO" },
    title: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    $on: { __type: "$NodeWithTitle!" },
  },
  NodeWithTrackbacks: {
    __typename: { __type: "String!" },
    pingStatus: { __type: "String" },
    pinged: { __type: "[String]" },
    toPing: { __type: "[String]" },
    $on: { __type: "$NodeWithTrackbacks!" },
  },
  NumberField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    autocompleteAttribute: { __type: "String" },
    calculationFormula: { __type: "String" },
    calculationRounding: { __type: "Int" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasAutocomplete: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isCalculation: { __type: "Boolean" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    numberFormat: { __type: "NumberFieldFormatEnum" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    rangeMax: { __type: "Float" },
    rangeMin: { __type: "Float" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  Page: {
    __typename: { __type: "String!" },
    aboutCTA: { __type: "Page_Aboutcta" },
    ancestors: {
      __type: "HierarchicalContentNodeToContentNodeAncestorsConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where:
          "HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs",
      },
    },
    author: { __type: "NodeWithAuthorToUserConnectionEdge" },
    authorDatabaseId: { __type: "Int" },
    authorId: { __type: "ID" },
    blocks: { __type: "[Block!]" },
    blocksJSON: { __type: "String" },
    children: {
      __type: "HierarchicalContentNodeToContentNodeChildrenConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where:
          "HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs",
      },
    },
    commentCount: { __type: "Int" },
    commentStatus: { __type: "String" },
    comments: {
      __type: "PageToCommentConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "PageToCommentConnectionWhereArgs",
      },
    },
    conditionalTags: { __type: "ConditionalTags" },
    content: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    contentHero: { __type: "Page_Contenthero" },
    contentType: { __type: "ContentNodeToContentTypeConnectionEdge" },
    contentTypeName: { __type: "String!" },
    databaseId: { __type: "Int!" },
    date: { __type: "String" },
    dateGmt: { __type: "String" },
    desiredSlug: { __type: "String" },
    editingLockedBy: { __type: "ContentNodeToEditLockConnectionEdge" },
    enclosure: { __type: "String" },
    enqueuedScripts: {
      __type: "ContentNodeToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    enqueuedStylesheets: {
      __type: "ContentNodeToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    featuredImage: { __type: "NodeWithFeaturedImageToMediaItemConnectionEdge" },
    featuredImageDatabaseId: { __type: "Int" },
    featuredImageId: { __type: "ID" },
    features: { __type: "Page_Features" },
    guid: { __type: "String" },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isFrontPage: { __type: "Boolean!" },
    isPostsPage: { __type: "Boolean!" },
    isPreview: { __type: "Boolean" },
    isPrivacyPage: { __type: "Boolean!" },
    isRestricted: { __type: "Boolean" },
    isRevision: { __type: "Boolean" },
    isTermNode: { __type: "Boolean!" },
    lastEditedBy: { __type: "ContentNodeToEditLastConnectionEdge" },
    link: { __type: "String" },
    menuOrder: { __type: "Int" },
    modified: { __type: "String" },
    modifiedGmt: { __type: "String" },
    pageId: { __type: "Int!" },
    parent: {
      __type: "HierarchicalContentNodeToParentContentNodeConnectionEdge",
    },
    parentDatabaseId: { __type: "Int" },
    parentId: { __type: "ID" },
    preview: { __type: "PageToPreviewConnectionEdge" },
    previewBlocks: { __type: "[Block!]" },
    previewBlocksJSON: { __type: "String" },
    previewRevisionDatabaseId: { __type: "Int" },
    previewRevisionId: { __type: "ID" },
    revisionOf: { __type: "NodeWithRevisionsToContentNodeConnectionEdge" },
    revisions: {
      __type: "PageToRevisionConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "PageToRevisionConnectionWhereArgs",
      },
    },
    seo: { __type: "PostTypeSEO" },
    slug: { __type: "String" },
    status: { __type: "String" },
    template: { __type: "ContentTemplate" },
    templates: { __type: "[String]" },
    title: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    uri: { __type: "String" },
  },
  PageField: {
    __typename: { __type: "String!" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    displayOnly: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputType: { __type: "FormFieldTypeEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    nextButton: { __type: "FormButton" },
    pageNumber: { __type: "Int" },
    previousButton: { __type: "FormButton" },
    type: { __type: "FormFieldTypeEnum" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PageToCommentConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[PageToCommentConnectionEdge]" },
    nodes: { __type: "[Comment]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  PageToCommentConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Comment" },
  },
  PageToCommentConnectionWhereArgs: {
    authorEmail: { __type: "String" },
    authorIn: { __type: "[ID]" },
    authorNotIn: { __type: "[ID]" },
    authorUrl: { __type: "String" },
    commentIn: { __type: "[ID]" },
    commentNotIn: { __type: "[ID]" },
    commentType: { __type: "String" },
    commentTypeIn: { __type: "[String]" },
    commentTypeNotIn: { __type: "String" },
    contentAuthor: { __type: "[ID]" },
    contentAuthorIn: { __type: "[ID]" },
    contentAuthorNotIn: { __type: "[ID]" },
    contentId: { __type: "ID" },
    contentIdIn: { __type: "[ID]" },
    contentIdNotIn: { __type: "[ID]" },
    contentName: { __type: "String" },
    contentParent: { __type: "Int" },
    contentStatus: { __type: "[PostStatusEnum]" },
    contentType: { __type: "[ContentTypeEnum]" },
    includeUnapproved: { __type: "[ID]" },
    karma: { __type: "Int" },
    order: { __type: "OrderEnum" },
    orderby: { __type: "CommentsConnectionOrderbyEnum" },
    parent: { __type: "Int" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    search: { __type: "String" },
    status: { __type: "String" },
    userId: { __type: "ID" },
  },
  PageToPreviewConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "Page" },
  },
  PageToRevisionConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[PageToRevisionConnectionEdge]" },
    nodes: { __type: "[Page]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  PageToRevisionConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Page" },
  },
  PageToRevisionConnectionWhereArgs: {
    author: { __type: "Int" },
    authorIn: { __type: "[ID]" },
    authorName: { __type: "String" },
    authorNotIn: { __type: "[ID]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  Page_Aboutcta: {
    __typename: { __type: "String!" },
    bullets: { __type: "[Page_Aboutcta_bullets]" },
    fieldGroupName: { __type: "String" },
    heading: { __type: "String" },
    image: { __type: "MediaItem" },
  },
  Page_Aboutcta_bullets: {
    __typename: { __type: "String!" },
    bulletItem: { __type: "String" },
    fieldGroupName: { __type: "String" },
  },
  Page_Contenthero: {
    __typename: { __type: "String!" },
    calendlyLink: { __type: "String" },
    content: { __type: "String" },
    fieldGroupName: { __type: "String" },
    heading: { __type: "String" },
  },
  Page_Features: {
    __typename: { __type: "String!" },
    features: { __type: "[Page_Features_features]" },
    fieldGroupName: { __type: "String" },
  },
  Page_Features_features: {
    __typename: { __type: "String!" },
    bullettPoints: { __type: "[Page_Features_features_bullettPoints]" },
    color: { __type: "String" },
    fieldGroupName: { __type: "String" },
    heading: { __type: "String" },
    image: { __type: "MediaItem" },
  },
  Page_Features_features_bullettPoints: {
    __typename: { __type: "String!" },
    eachBullet: { __type: "String" },
    fieldGroupName: { __type: "String" },
  },
  PasswordField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasPasswordStrengthIndicator: { __type: "Boolean" },
    hasPasswordVisibilityToggle: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputType: { __type: "FormFieldTypeEnum" },
    inputs: { __type: "[PasswordInputProperty]" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    minPasswordStrength: { __type: "PasswordFieldMinStrengthEnum" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    size: { __type: "FormFieldSizeEnum" },
    subLabelPlacement: { __type: "FormFieldSubLabelPlacementEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PasswordInputProperty: {
    __typename: { __type: "String!" },
    customLabel: { __type: "String" },
    id: { __type: "Float" },
    isHidden: { __type: "Boolean" },
    label: { __type: "String" },
    placeholder: { __type: "String" },
  },
  PhoneField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    autocompleteAttribute: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasAutocomplete: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    phoneFormat: { __type: "PhoneFieldFormatEnum" },
    placeholder: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  Plugin: {
    __typename: { __type: "String!" },
    author: { __type: "String" },
    authorUri: { __type: "String" },
    description: { __type: "String" },
    id: { __type: "ID!" },
    isRestricted: { __type: "Boolean" },
    name: { __type: "String" },
    path: { __type: "String" },
    pluginUri: { __type: "String" },
    version: { __type: "String" },
  },
  Post: {
    __typename: { __type: "String!" },
    author: { __type: "NodeWithAuthorToUserConnectionEdge" },
    authorDatabaseId: { __type: "Int" },
    authorId: { __type: "ID" },
    blocks: { __type: "[Block!]" },
    blocksJSON: { __type: "String" },
    categories: {
      __type: "PostToCategoryConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "PostToCategoryConnectionWhereArgs",
      },
    },
    commentCount: { __type: "Int" },
    commentStatus: { __type: "String" },
    comments: {
      __type: "PostToCommentConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "PostToCommentConnectionWhereArgs",
      },
    },
    conditionalTags: { __type: "ConditionalTags" },
    content: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    contentType: { __type: "ContentNodeToContentTypeConnectionEdge" },
    contentTypeName: { __type: "String!" },
    databaseId: { __type: "Int!" },
    date: { __type: "String" },
    dateGmt: { __type: "String" },
    desiredSlug: { __type: "String" },
    editingLockedBy: { __type: "ContentNodeToEditLockConnectionEdge" },
    enclosure: { __type: "String" },
    enqueuedScripts: {
      __type: "ContentNodeToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    enqueuedStylesheets: {
      __type: "ContentNodeToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    excerpt: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    featuredImage: { __type: "NodeWithFeaturedImageToMediaItemConnectionEdge" },
    featuredImageDatabaseId: { __type: "Int" },
    featuredImageId: { __type: "ID" },
    guid: { __type: "String" },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isPreview: { __type: "Boolean" },
    isRestricted: { __type: "Boolean" },
    isRevision: { __type: "Boolean" },
    isSticky: { __type: "Boolean!" },
    isTermNode: { __type: "Boolean!" },
    lastEditedBy: { __type: "ContentNodeToEditLastConnectionEdge" },
    link: { __type: "String" },
    modified: { __type: "String" },
    modifiedGmt: { __type: "String" },
    next: { __type: "Post" },
    pingStatus: { __type: "String" },
    pinged: { __type: "[String]" },
    postFormats: {
      __type: "PostToPostFormatConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "PostToPostFormatConnectionWhereArgs",
      },
    },
    postId: { __type: "Int!" },
    postsACF: { __type: "Post_Postsacf" },
    preview: { __type: "PostToPreviewConnectionEdge" },
    previewBlocks: { __type: "[Block!]" },
    previewBlocksJSON: { __type: "String" },
    previewRevisionDatabaseId: { __type: "Int" },
    previewRevisionId: { __type: "ID" },
    previous: { __type: "Post" },
    revisionOf: { __type: "NodeWithRevisionsToContentNodeConnectionEdge" },
    revisions: {
      __type: "PostToRevisionConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "PostToRevisionConnectionWhereArgs",
      },
    },
    seo: { __type: "PostTypeSEO" },
    slug: { __type: "String" },
    status: { __type: "String" },
    tags: {
      __type: "PostToTagConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "PostToTagConnectionWhereArgs",
      },
    },
    template: { __type: "ContentTemplate" },
    templates: { __type: "[String]" },
    terms: {
      __type: "PostToTermNodeConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "PostToTermNodeConnectionWhereArgs",
      },
    },
    title: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    toPing: { __type: "[String]" },
    uri: { __type: "String" },
  },
  PostCategoriesInput: {
    append: { __type: "Boolean" },
    nodes: { __type: "[PostCategoriesNodeInput]" },
  },
  PostCategoriesNodeInput: {
    description: { __type: "String" },
    id: { __type: "ID" },
    name: { __type: "String" },
    slug: { __type: "String" },
  },
  PostCategoryCheckboxField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    checkboxValues: { __type: "[CheckboxFieldValue]" },
    choices: { __type: "[PostCategoryFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    dropdownPlaceholder: { __type: "String" },
    errorMessage: { __type: "String" },
    hasAllCategories: { __type: "Boolean" },
    hasChoiceValue: { __type: "Boolean" },
    hasSelectAll: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    inputs: { __type: "[PostCategoryInputProperty]" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCategoryField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    choices: { __type: "[PostCategoryFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    dropdownPlaceholder: { __type: "String" },
    errorMessage: { __type: "String" },
    hasAllCategories: { __type: "Boolean" },
    hasChoiceValue: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
    $on: { __type: "$PostCategoryField!" },
  },
  PostCategoryFieldChoice: {
    __typename: { __type: "String!" },
    isSelected: { __type: "Boolean" },
    text: { __type: "String" },
    value: { __type: "String" },
  },
  PostCategoryInputProperty: {
    __typename: { __type: "String!" },
    id: { __type: "Float" },
    label: { __type: "String" },
    name: { __type: "String" },
  },
  PostCategoryMultiSelectField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    choices: { __type: "[PostCategoryFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    dropdownPlaceholder: { __type: "String" },
    errorMessage: { __type: "String" },
    hasAllCategories: { __type: "Boolean" },
    hasChoiceValue: { __type: "Boolean" },
    hasEnhancedUI: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    values: { __type: "[String]" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCategoryRadioField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    choices: { __type: "[PostCategoryFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    dropdownPlaceholder: { __type: "String" },
    errorMessage: { __type: "String" },
    hasAllCategories: { __type: "Boolean" },
    hasChoiceValue: { __type: "Boolean" },
    hasOtherChoice: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCategorySelectField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    autocompleteAttribute: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    choices: { __type: "[PostCategoryFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    dropdownPlaceholder: { __type: "String" },
    errorMessage: { __type: "String" },
    hasAllCategories: { __type: "Boolean" },
    hasAutocomplete: { __type: "Boolean" },
    hasChoiceValue: { __type: "Boolean" },
    hasEnhancedUI: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostContentField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasRichTextEditor: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    maxLength: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCustomCheckboxField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    checkboxValues: { __type: "[CheckboxFieldValue]" },
    choices: { __type: "[PostCustomCheckboxFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasChoiceValue: { __type: "Boolean" },
    hasSelectAll: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    inputs: { __type: "[PostCustomInputProperty]" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    postMetaFieldName: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCustomCheckboxFieldChoice: {
    __typename: { __type: "String!" },
    isSelected: { __type: "Boolean" },
    text: { __type: "String" },
    value: { __type: "String" },
  },
  PostCustomDateField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    calendarIconType: { __type: "FormFieldCalendarIconTypeEnum" },
    calendarIconUrl: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    dateFormat: { __type: "DateFieldFormatEnum" },
    dateType: { __type: "DateFieldTypeEnum" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    inputs: { __type: "[PostCustomInputProperty]" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    postMetaFieldName: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    subLabelPlacement: { __type: "FormFieldSubLabelPlacementEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCustomEmailField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    autocompleteAttribute: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasAutocomplete: { __type: "Boolean" },
    hasEmailConfirmation: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    inputs: { __type: "[PostCustomInputProperty]" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    postMetaFieldName: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    subLabelPlacement: { __type: "FormFieldSubLabelPlacementEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCustomField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    postMetaFieldName: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
    $on: { __type: "$PostCustomField!" },
  },
  PostCustomFileuploadField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    allowedExtensions: { __type: "[String]" },
    canAcceptMultipleFiles: { __type: "Boolean" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    fileUploadValues: { __type: "[FileUploadFieldValue]" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    maxFileSize: { __type: "Int" },
    maxFiles: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    postMetaFieldName: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    values: { __type: "[String]" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCustomHiddenField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    postMetaFieldName: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCustomInputProperty: {
    __typename: { __type: "String!" },
    id: { __type: "Float" },
    label: { __type: "String" },
    name: { __type: "String" },
  },
  PostCustomListField: {
    __typename: { __type: "String!" },
    addIconUrl: { __type: "String" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    choices: { __type: "[PostCustomListFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    deleteIconUrl: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasColumns: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    listValues: { __type: "[ListFieldValue]" },
    maxRows: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    postMetaFieldName: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCustomListFieldChoice: {
    __typename: { __type: "String!" },
    text: { __type: "String" },
    value: { __type: "String" },
  },
  PostCustomMultiSelectField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    choices: { __type: "[PostCustomMultiSelectFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasChoiceValue: { __type: "Boolean" },
    hasEnhancedUI: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    postMetaFieldName: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    values: { __type: "[String]" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCustomMultiSelectFieldChoice: {
    __typename: { __type: "String!" },
    isSelected: { __type: "Boolean" },
    text: { __type: "String" },
    value: { __type: "String" },
  },
  PostCustomNumberField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    autocompleteAttribute: { __type: "String" },
    calculationFormula: { __type: "String" },
    calculationRounding: { __type: "Int" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasAutocomplete: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isCalculation: { __type: "Boolean" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    numberFormat: { __type: "NumberFieldFormatEnum" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    postMetaFieldName: { __type: "String" },
    rangeMax: { __type: "Float" },
    rangeMin: { __type: "Float" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCustomPhoneField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    autocompleteAttribute: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasAutocomplete: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    phoneFormat: { __type: "PhoneFieldFormatEnum" },
    placeholder: { __type: "String" },
    postMetaFieldName: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCustomRadioField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    choices: { __type: "[PostCustomRadioFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasChoiceValue: { __type: "Boolean" },
    hasOtherChoice: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    postMetaFieldName: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCustomRadioFieldChoice: {
    __typename: { __type: "String!" },
    isOtherChoice: { __type: "Boolean" },
    isSelected: { __type: "Boolean" },
    text: { __type: "String" },
    value: { __type: "String" },
  },
  PostCustomSelectField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    autocompleteAttribute: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    choices: { __type: "[PostCustomSelectFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasAutocomplete: { __type: "Boolean" },
    hasChoiceValue: { __type: "Boolean" },
    hasEnhancedUI: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    postMetaFieldName: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCustomSelectFieldChoice: {
    __typename: { __type: "String!" },
    isSelected: { __type: "Boolean" },
    text: { __type: "String" },
    value: { __type: "String" },
  },
  PostCustomTextAreaField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasRichTextEditor: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    maxLength: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    postMetaFieldName: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCustomTextField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    autocompleteAttribute: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasAutocomplete: { __type: "Boolean" },
    hasInputMask: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputMaskValue: { __type: "String" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isPasswordInput: { __type: "Boolean" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    maxLength: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    postMetaFieldName: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCustomTimeField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    inputs: { __type: "[PostCustomInputProperty]" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    postMetaFieldName: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    subLabelPlacement: { __type: "FormFieldSubLabelPlacementEnum" },
    timeFormat: { __type: "TimeFieldFormatEnum" },
    timeValues: { __type: "TimeFieldValue" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostCustomWebsiteField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    postMetaFieldName: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostExcerptField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    maxLength: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostFormat: {
    __typename: { __type: "String!" },
    conditionalTags: { __type: "ConditionalTags" },
    contentNodes: {
      __type: "PostFormatToContentNodeConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "PostFormatToContentNodeConnectionWhereArgs",
      },
    },
    count: { __type: "Int" },
    databaseId: { __type: "Int!" },
    description: { __type: "String" },
    enqueuedScripts: {
      __type: "TermNodeToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    enqueuedStylesheets: {
      __type: "TermNodeToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isRestricted: { __type: "Boolean" },
    isTermNode: { __type: "Boolean!" },
    link: { __type: "String" },
    name: { __type: "String" },
    postFormatId: { __type: "Int" },
    posts: {
      __type: "PostFormatToPostConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "PostFormatToPostConnectionWhereArgs",
      },
    },
    seo: { __type: "TaxonomySEO" },
    slug: { __type: "String" },
    taxonomy: { __type: "PostFormatToTaxonomyConnectionEdge" },
    taxonomyName: { __type: "String" },
    templates: { __type: "[String]" },
    termGroupId: { __type: "Int" },
    termTaxonomyId: { __type: "Int" },
    uri: { __type: "String" },
  },
  PostFormatToContentNodeConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[PostFormatToContentNodeConnectionEdge]" },
    nodes: { __type: "[ContentNode]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  PostFormatToContentNodeConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "ContentNode" },
  },
  PostFormatToContentNodeConnectionWhereArgs: {
    contentTypes: { __type: "[ContentTypesOfPostFormatEnum]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  PostFormatToPostConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[PostFormatToPostConnectionEdge]" },
    nodes: { __type: "[Post]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  PostFormatToPostConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Post" },
  },
  PostFormatToPostConnectionWhereArgs: {
    author: { __type: "Int" },
    authorIn: { __type: "[ID]" },
    authorName: { __type: "String" },
    authorNotIn: { __type: "[ID]" },
    categoryId: { __type: "Int" },
    categoryIn: { __type: "[ID]" },
    categoryName: { __type: "String" },
    categoryNotIn: { __type: "[ID]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    tag: { __type: "String" },
    tagId: { __type: "String" },
    tagIn: { __type: "[ID]" },
    tagNotIn: { __type: "[ID]" },
    tagSlugAnd: { __type: "[String]" },
    tagSlugIn: { __type: "[String]" },
    title: { __type: "String" },
  },
  PostFormatToTaxonomyConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "Taxonomy" },
  },
  PostImageField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    allowedExtensions: { __type: "[String]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasAlt: { __type: "Boolean" },
    hasCaption: { __type: "Boolean" },
    hasDescription: { __type: "Boolean" },
    hasTitle: { __type: "Boolean" },
    id: { __type: "Int!" },
    imageValues: { __type: "ImageFieldValue" },
    inputType: { __type: "FormFieldTypeEnum" },
    isFeaturedImage: { __type: "Boolean" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    subLabelPlacement: { __type: "FormFieldSubLabelPlacementEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostObjectUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$PostObjectUnion!" },
  },
  PostObjectsConnectionOrderbyInput: {
    field: { __type: "PostObjectsConnectionOrderbyEnum!" },
    order: { __type: "OrderEnum!" },
  },
  PostPostFormatsInput: {
    append: { __type: "Boolean" },
    nodes: { __type: "[PostPostFormatsNodeInput]" },
  },
  PostPostFormatsNodeInput: {
    description: { __type: "String" },
    id: { __type: "ID" },
    name: { __type: "String" },
    slug: { __type: "String" },
  },
  PostTagsCheckboxField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    checkboxValues: { __type: "[CheckboxFieldValue]" },
    choices: { __type: "[PostTagsCheckboxFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasChoiceValue: { __type: "Boolean" },
    hasSelectAll: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    inputs: { __type: "[PostTagsInputProperty]" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostTagsCheckboxFieldChoice: {
    __typename: { __type: "String!" },
    isSelected: { __type: "Boolean" },
    text: { __type: "String" },
    value: { __type: "String" },
  },
  PostTagsField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
    $on: { __type: "$PostTagsField!" },
  },
  PostTagsInput: {
    append: { __type: "Boolean" },
    nodes: { __type: "[PostTagsNodeInput]" },
  },
  PostTagsInputProperty: {
    __typename: { __type: "String!" },
    id: { __type: "Float" },
    label: { __type: "String" },
    name: { __type: "String" },
  },
  PostTagsMultiSelectField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    choices: { __type: "[PostTagsMultiSelectFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasChoiceValue: { __type: "Boolean" },
    hasEnhancedUI: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    values: { __type: "[String]" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostTagsMultiSelectFieldChoice: {
    __typename: { __type: "String!" },
    isSelected: { __type: "Boolean" },
    text: { __type: "String" },
    value: { __type: "String" },
  },
  PostTagsNodeInput: {
    description: { __type: "String" },
    id: { __type: "ID" },
    name: { __type: "String" },
    slug: { __type: "String" },
  },
  PostTagsRadioField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    choices: { __type: "[PostTagsRadioFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasChoiceValue: { __type: "Boolean" },
    hasOtherChoice: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostTagsRadioFieldChoice: {
    __typename: { __type: "String!" },
    isOtherChoice: { __type: "Boolean" },
    isSelected: { __type: "Boolean" },
    text: { __type: "String" },
    value: { __type: "String" },
  },
  PostTagsSelectField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    autocompleteAttribute: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    choices: { __type: "[PostTagsSelectFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasAutocomplete: { __type: "Boolean" },
    hasChoiceValue: { __type: "Boolean" },
    hasEnhancedUI: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostTagsSelectFieldChoice: {
    __typename: { __type: "String!" },
    isSelected: { __type: "Boolean" },
    text: { __type: "String" },
    value: { __type: "String" },
  },
  PostTagsTextField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    autocompleteAttribute: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasAutocomplete: { __type: "Boolean" },
    hasInputMask: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputMaskValue: { __type: "String" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isPasswordInput: { __type: "Boolean" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    maxLength: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostTitleField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  PostToCategoryConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[PostToCategoryConnectionEdge]" },
    nodes: { __type: "[Category]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  PostToCategoryConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    isPrimary: { __type: "Boolean" },
    node: { __type: "Category" },
  },
  PostToCategoryConnectionWhereArgs: {
    cacheDomain: { __type: "String" },
    childOf: { __type: "Int" },
    childless: { __type: "Boolean" },
    descriptionLike: { __type: "String" },
    exclude: { __type: "[ID]" },
    excludeTree: { __type: "[ID]" },
    hideEmpty: { __type: "Boolean" },
    hierarchical: { __type: "Boolean" },
    include: { __type: "[ID]" },
    name: { __type: "[String]" },
    nameLike: { __type: "String" },
    objectIds: { __type: "[ID]" },
    order: { __type: "OrderEnum" },
    orderby: { __type: "TermObjectsConnectionOrderbyEnum" },
    padCounts: { __type: "Boolean" },
    parent: { __type: "Int" },
    search: { __type: "String" },
    slug: { __type: "[String]" },
    termTaxonomId: { __type: "[ID]" },
    updateTermMetaCache: { __type: "Boolean" },
  },
  PostToCommentConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[PostToCommentConnectionEdge]" },
    nodes: { __type: "[Comment]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  PostToCommentConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Comment" },
  },
  PostToCommentConnectionWhereArgs: {
    authorEmail: { __type: "String" },
    authorIn: { __type: "[ID]" },
    authorNotIn: { __type: "[ID]" },
    authorUrl: { __type: "String" },
    commentIn: { __type: "[ID]" },
    commentNotIn: { __type: "[ID]" },
    commentType: { __type: "String" },
    commentTypeIn: { __type: "[String]" },
    commentTypeNotIn: { __type: "String" },
    contentAuthor: { __type: "[ID]" },
    contentAuthorIn: { __type: "[ID]" },
    contentAuthorNotIn: { __type: "[ID]" },
    contentId: { __type: "ID" },
    contentIdIn: { __type: "[ID]" },
    contentIdNotIn: { __type: "[ID]" },
    contentName: { __type: "String" },
    contentParent: { __type: "Int" },
    contentStatus: { __type: "[PostStatusEnum]" },
    contentType: { __type: "[ContentTypeEnum]" },
    includeUnapproved: { __type: "[ID]" },
    karma: { __type: "Int" },
    order: { __type: "OrderEnum" },
    orderby: { __type: "CommentsConnectionOrderbyEnum" },
    parent: { __type: "Int" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    search: { __type: "String" },
    status: { __type: "String" },
    userId: { __type: "ID" },
  },
  PostToPostFormatConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[PostToPostFormatConnectionEdge]" },
    nodes: { __type: "[PostFormat]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  PostToPostFormatConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    isPrimary: { __type: "Boolean" },
    node: { __type: "PostFormat" },
  },
  PostToPostFormatConnectionWhereArgs: {
    cacheDomain: { __type: "String" },
    childOf: { __type: "Int" },
    childless: { __type: "Boolean" },
    descriptionLike: { __type: "String" },
    exclude: { __type: "[ID]" },
    excludeTree: { __type: "[ID]" },
    hideEmpty: { __type: "Boolean" },
    hierarchical: { __type: "Boolean" },
    include: { __type: "[ID]" },
    name: { __type: "[String]" },
    nameLike: { __type: "String" },
    objectIds: { __type: "[ID]" },
    order: { __type: "OrderEnum" },
    orderby: { __type: "TermObjectsConnectionOrderbyEnum" },
    padCounts: { __type: "Boolean" },
    parent: { __type: "Int" },
    search: { __type: "String" },
    slug: { __type: "[String]" },
    termTaxonomId: { __type: "[ID]" },
    updateTermMetaCache: { __type: "Boolean" },
  },
  PostToPreviewConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "Post" },
  },
  PostToRevisionConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[PostToRevisionConnectionEdge]" },
    nodes: { __type: "[Post]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  PostToRevisionConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Post" },
  },
  PostToRevisionConnectionWhereArgs: {
    author: { __type: "Int" },
    authorIn: { __type: "[ID]" },
    authorName: { __type: "String" },
    authorNotIn: { __type: "[ID]" },
    categoryId: { __type: "Int" },
    categoryIn: { __type: "[ID]" },
    categoryName: { __type: "String" },
    categoryNotIn: { __type: "[ID]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    tag: { __type: "String" },
    tagId: { __type: "String" },
    tagIn: { __type: "[ID]" },
    tagNotIn: { __type: "[ID]" },
    tagSlugAnd: { __type: "[String]" },
    tagSlugIn: { __type: "[String]" },
    title: { __type: "String" },
  },
  PostToTagConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[PostToTagConnectionEdge]" },
    nodes: { __type: "[Tag]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  PostToTagConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    isPrimary: { __type: "Boolean" },
    node: { __type: "Tag" },
  },
  PostToTagConnectionWhereArgs: {
    cacheDomain: { __type: "String" },
    childOf: { __type: "Int" },
    childless: { __type: "Boolean" },
    descriptionLike: { __type: "String" },
    exclude: { __type: "[ID]" },
    excludeTree: { __type: "[ID]" },
    hideEmpty: { __type: "Boolean" },
    hierarchical: { __type: "Boolean" },
    include: { __type: "[ID]" },
    name: { __type: "[String]" },
    nameLike: { __type: "String" },
    objectIds: { __type: "[ID]" },
    order: { __type: "OrderEnum" },
    orderby: { __type: "TermObjectsConnectionOrderbyEnum" },
    padCounts: { __type: "Boolean" },
    parent: { __type: "Int" },
    search: { __type: "String" },
    slug: { __type: "[String]" },
    termTaxonomId: { __type: "[ID]" },
    updateTermMetaCache: { __type: "Boolean" },
  },
  PostToTermNodeConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[PostToTermNodeConnectionEdge]" },
    nodes: { __type: "[TermNode]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  PostToTermNodeConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "TermNode" },
  },
  PostToTermNodeConnectionWhereArgs: {
    cacheDomain: { __type: "String" },
    childOf: { __type: "Int" },
    childless: { __type: "Boolean" },
    descriptionLike: { __type: "String" },
    exclude: { __type: "[ID]" },
    excludeTree: { __type: "[ID]" },
    hideEmpty: { __type: "Boolean" },
    hierarchical: { __type: "Boolean" },
    include: { __type: "[ID]" },
    name: { __type: "[String]" },
    nameLike: { __type: "String" },
    objectIds: { __type: "[ID]" },
    order: { __type: "OrderEnum" },
    orderby: { __type: "TermObjectsConnectionOrderbyEnum" },
    padCounts: { __type: "Boolean" },
    parent: { __type: "Int" },
    search: { __type: "String" },
    slug: { __type: "[String]" },
    taxonomies: { __type: "[TaxonomyEnum]" },
    termTaxonomId: { __type: "[ID]" },
    updateTermMetaCache: { __type: "Boolean" },
  },
  PostTypeLabelDetails: {
    __typename: { __type: "String!" },
    addNew: { __type: "String" },
    addNewItem: { __type: "String" },
    allItems: { __type: "String" },
    archives: { __type: "String" },
    attributes: { __type: "String" },
    editItem: { __type: "String" },
    featuredImage: { __type: "String" },
    filterItemsList: { __type: "String" },
    insertIntoItem: { __type: "String" },
    itemsList: { __type: "String" },
    itemsListNavigation: { __type: "String" },
    menuName: { __type: "String" },
    name: { __type: "String" },
    newItem: { __type: "String" },
    notFound: { __type: "String" },
    notFoundInTrash: { __type: "String" },
    parentItemColon: { __type: "String" },
    removeFeaturedImage: { __type: "String" },
    searchItems: { __type: "String" },
    setFeaturedImage: { __type: "String" },
    singularName: { __type: "String" },
    uploadedToThisItem: { __type: "String" },
    useFeaturedImage: { __type: "String" },
    viewItem: { __type: "String" },
    viewItems: { __type: "String" },
  },
  PostTypeSEO: {
    __typename: { __type: "String!" },
    breadcrumbs: { __type: "[SEOPostTypeBreadcrumbs]" },
    canonical: { __type: "String" },
    cornerstone: { __type: "Boolean" },
    focuskw: { __type: "String" },
    fullHead: { __type: "String" },
    metaDesc: { __type: "String" },
    metaKeywords: { __type: "String" },
    metaRobotsNofollow: { __type: "String" },
    metaRobotsNoindex: { __type: "String" },
    opengraphAuthor: { __type: "String" },
    opengraphDescription: { __type: "String" },
    opengraphImage: { __type: "MediaItem" },
    opengraphModifiedTime: { __type: "String" },
    opengraphPublishedTime: { __type: "String" },
    opengraphPublisher: { __type: "String" },
    opengraphSiteName: { __type: "String" },
    opengraphTitle: { __type: "String" },
    opengraphType: { __type: "String" },
    opengraphUrl: { __type: "String" },
    readingTime: { __type: "Float" },
    schema: { __type: "SEOPostTypeSchema" },
    title: { __type: "String" },
    twitterDescription: { __type: "String" },
    twitterImage: { __type: "MediaItem" },
    twitterTitle: { __type: "String" },
  },
  Post_Postsacf: {
    __typename: { __type: "String!" },
    fieldGroupName: { __type: "String" },
    series: { __type: "PostObjectUnion" },
  },
  RadioField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    choices: { __type: "[RadioFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasChoiceValue: { __type: "Boolean" },
    hasOtherChoice: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    shouldAllowDuplicates: { __type: "Boolean" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  RadioFieldChoice: {
    __typename: { __type: "String!" },
    isOtherChoice: { __type: "Boolean" },
    isSelected: { __type: "Boolean" },
    text: { __type: "String" },
    value: { __type: "String" },
  },
  ReadingSettings: {
    __typename: { __type: "String!" },
    pageForPosts: { __type: "Int" },
    pageOnFront: { __type: "Int" },
    postsPerPage: { __type: "Int" },
    showOnFront: { __type: "String" },
  },
  RegisterUserInput: {
    aim: { __type: "String" },
    clientMutationId: { __type: "String" },
    description: { __type: "String" },
    displayName: { __type: "String" },
    email: { __type: "String" },
    firstName: { __type: "String" },
    jabber: { __type: "String" },
    lastName: { __type: "String" },
    locale: { __type: "String" },
    nicename: { __type: "String" },
    nickname: { __type: "String" },
    password: { __type: "String" },
    registered: { __type: "String" },
    richEditing: { __type: "String" },
    username: { __type: "String!" },
    websiteUrl: { __type: "String" },
    yim: { __type: "String" },
  },
  RegisterUserPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    user: { __type: "User" },
  },
  ResetUserPasswordInput: {
    clientMutationId: { __type: "String" },
    key: { __type: "String" },
    login: { __type: "String" },
    password: { __type: "String" },
  },
  ResetUserPasswordPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    user: { __type: "User" },
  },
  RestoreCommentInput: {
    clientMutationId: { __type: "String" },
    id: { __type: "ID!" },
  },
  RestoreCommentPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    comment: { __type: "Comment" },
    restoredId: { __type: "ID" },
  },
  ReusableBlock: {
    __typename: { __type: "String!" },
    blocks: { __type: "[Block!]" },
    blocksJSON: { __type: "String" },
    conditionalTags: { __type: "ConditionalTags" },
    content: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    contentType: { __type: "ContentNodeToContentTypeConnectionEdge" },
    contentTypeName: { __type: "String!" },
    databaseId: { __type: "Int!" },
    date: { __type: "String" },
    dateGmt: { __type: "String" },
    desiredSlug: { __type: "String" },
    editingLockedBy: { __type: "ContentNodeToEditLockConnectionEdge" },
    enclosure: { __type: "String" },
    enqueuedScripts: {
      __type: "ContentNodeToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    enqueuedStylesheets: {
      __type: "ContentNodeToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    guid: { __type: "String" },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isPreview: { __type: "Boolean" },
    isRestricted: { __type: "Boolean" },
    isRevision: { __type: "Boolean" },
    isTermNode: { __type: "Boolean!" },
    lastEditedBy: { __type: "ContentNodeToEditLastConnectionEdge" },
    link: { __type: "String" },
    modified: { __type: "String" },
    modifiedGmt: { __type: "String" },
    preview: { __type: "ReusableBlockToPreviewConnectionEdge" },
    previewBlocks: { __type: "[Block!]" },
    previewBlocksFrom: { __type: "[Block!]", __args: { databaseId: "Int!" } },
    previewBlocksFromJSON: { __type: "String", __args: { databaseId: "Int!" } },
    previewBlocksJSON: { __type: "String" },
    previewRevisionDatabaseId: { __type: "Int" },
    previewRevisionId: { __type: "ID" },
    reusableBlockId: { __type: "Int!" },
    revisionOf: { __type: "NodeWithRevisionsToContentNodeConnectionEdge" },
    revisions: {
      __type: "ReusableBlockToRevisionConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "ReusableBlockToRevisionConnectionWhereArgs",
      },
    },
    seo: { __type: "PostTypeSEO" },
    slug: { __type: "String" },
    status: { __type: "String" },
    template: { __type: "ContentTemplate" },
    templates: { __type: "[String]" },
    title: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    uri: { __type: "String" },
  },
  ReusableBlockToPreviewConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "ReusableBlock" },
  },
  ReusableBlockToRevisionConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[ReusableBlockToRevisionConnectionEdge]" },
    nodes: { __type: "[ReusableBlock]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  ReusableBlockToRevisionConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "ReusableBlock" },
  },
  ReusableBlockToRevisionConnectionWhereArgs: {
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  RootQueryToBlockEditorPreviewConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToBlockEditorPreviewConnectionEdge]" },
    nodes: { __type: "[BlockEditorPreview]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToBlockEditorPreviewConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "BlockEditorPreview" },
  },
  RootQueryToBlockEditorPreviewConnectionWhereArgs: {
    author: { __type: "Int" },
    authorIn: { __type: "[ID]" },
    authorName: { __type: "String" },
    authorNotIn: { __type: "[ID]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    previewedDatabaseId: { __type: "Int" },
    previewedParentDatabaseId: { __type: "Int" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  RootQueryToCategoryConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToCategoryConnectionEdge]" },
    nodes: { __type: "[Category]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToCategoryConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Category" },
  },
  RootQueryToCategoryConnectionWhereArgs: {
    cacheDomain: { __type: "String" },
    childOf: { __type: "Int" },
    childless: { __type: "Boolean" },
    descriptionLike: { __type: "String" },
    exclude: { __type: "[ID]" },
    excludeTree: { __type: "[ID]" },
    hideEmpty: { __type: "Boolean" },
    hierarchical: { __type: "Boolean" },
    include: { __type: "[ID]" },
    name: { __type: "[String]" },
    nameLike: { __type: "String" },
    objectIds: { __type: "[ID]" },
    order: { __type: "OrderEnum" },
    orderby: { __type: "TermObjectsConnectionOrderbyEnum" },
    padCounts: { __type: "Boolean" },
    parent: { __type: "Int" },
    search: { __type: "String" },
    slug: { __type: "[String]" },
    termTaxonomId: { __type: "[ID]" },
    updateTermMetaCache: { __type: "Boolean" },
  },
  RootQueryToCommentConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToCommentConnectionEdge]" },
    nodes: { __type: "[Comment]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToCommentConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Comment" },
  },
  RootQueryToCommentConnectionWhereArgs: {
    authorEmail: { __type: "String" },
    authorIn: { __type: "[ID]" },
    authorNotIn: { __type: "[ID]" },
    authorUrl: { __type: "String" },
    commentIn: { __type: "[ID]" },
    commentNotIn: { __type: "[ID]" },
    commentType: { __type: "String" },
    commentTypeIn: { __type: "[String]" },
    commentTypeNotIn: { __type: "String" },
    contentAuthor: { __type: "[ID]" },
    contentAuthorIn: { __type: "[ID]" },
    contentAuthorNotIn: { __type: "[ID]" },
    contentId: { __type: "ID" },
    contentIdIn: { __type: "[ID]" },
    contentIdNotIn: { __type: "[ID]" },
    contentName: { __type: "String" },
    contentParent: { __type: "Int" },
    contentStatus: { __type: "[PostStatusEnum]" },
    contentType: { __type: "[ContentTypeEnum]" },
    includeUnapproved: { __type: "[ID]" },
    karma: { __type: "Int" },
    order: { __type: "OrderEnum" },
    orderby: { __type: "CommentsConnectionOrderbyEnum" },
    parent: { __type: "Int" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    search: { __type: "String" },
    status: { __type: "String" },
    userId: { __type: "ID" },
  },
  RootQueryToContentNodeConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToContentNodeConnectionEdge]" },
    nodes: { __type: "[ContentNode]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToContentNodeConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "ContentNode" },
  },
  RootQueryToContentNodeConnectionWhereArgs: {
    contentTypes: { __type: "[ContentTypeEnum]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  RootQueryToContentRevisionUnionConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToContentRevisionUnionConnectionEdge]" },
    nodes: { __type: "[ContentRevisionUnion]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToContentRevisionUnionConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "ContentRevisionUnion" },
  },
  RootQueryToContentRevisionUnionConnectionWhereArgs: {
    contentTypes: { __type: "[ContentTypeEnum]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  RootQueryToContentTypeConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToContentTypeConnectionEdge]" },
    nodes: { __type: "[ContentType]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToContentTypeConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "ContentType" },
  },
  RootQueryToEnqueuedScriptConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToEnqueuedScriptConnectionEdge]" },
    nodes: { __type: "[EnqueuedScript]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToEnqueuedScriptConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "EnqueuedScript" },
  },
  RootQueryToEnqueuedStylesheetConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToEnqueuedStylesheetConnectionEdge]" },
    nodes: { __type: "[EnqueuedStylesheet]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToEnqueuedStylesheetConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "EnqueuedStylesheet" },
  },
  RootQueryToGfEntryConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToGfEntryConnectionEdge]" },
    nodes: { __type: "[GfEntry]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToGfEntryConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "GfEntry" },
  },
  RootQueryToGfEntryConnectionWhereArgs: {
    dateFilters: { __type: "EntriesDateFiltersInput" },
    entryType: { __type: "EntryTypeEnum" },
    fieldFilters: { __type: "[EntriesFieldFiltersInput]" },
    fieldFiltersMode: { __type: "FieldFiltersModeEnum" },
    formIds: { __type: "[ID]" },
    orderby: { __type: "EntriesConnectionOrderbyInput" },
    status: { __type: "EntryStatusEnum" },
  },
  RootQueryToGfFormConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToGfFormConnectionEdge]" },
    nodes: { __type: "[GfForm]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToGfFormConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "GfForm" },
  },
  RootQueryToGfFormConnectionWhereArgs: {
    formIds: { __type: "[ID]" },
    orderby: { __type: "FormsConnectionOrderbyInput" },
    status: { __type: "FormStatusEnum" },
  },
  RootQueryToGfSubmittedEntryConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToGfSubmittedEntryConnectionEdge]" },
    nodes: { __type: "[GfSubmittedEntry]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToGfSubmittedEntryConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "GfSubmittedEntry" },
  },
  RootQueryToGfSubmittedEntryConnectionWhereArgs: {
    dateFilters: { __type: "EntriesDateFiltersInput" },
    fieldFilters: { __type: "[EntriesFieldFiltersInput]" },
    fieldFiltersMode: { __type: "FieldFiltersModeEnum" },
    formIds: { __type: "[ID]" },
    orderby: { __type: "EntriesConnectionOrderbyInput" },
    status: { __type: "EntryStatusEnum" },
  },
  RootQueryToJobConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToJobConnectionEdge]" },
    nodes: { __type: "[Job]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToJobConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Job" },
  },
  RootQueryToJobConnectionWhereArgs: {
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  RootQueryToMediaItemConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToMediaItemConnectionEdge]" },
    nodes: { __type: "[MediaItem]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToMediaItemConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "MediaItem" },
  },
  RootQueryToMediaItemConnectionWhereArgs: {
    author: { __type: "Int" },
    authorIn: { __type: "[ID]" },
    authorName: { __type: "String" },
    authorNotIn: { __type: "[ID]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  RootQueryToMenuConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToMenuConnectionEdge]" },
    nodes: { __type: "[Menu]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToMenuConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Menu" },
  },
  RootQueryToMenuConnectionWhereArgs: {
    id: { __type: "Int" },
    location: { __type: "MenuLocationEnum" },
    slug: { __type: "String" },
  },
  RootQueryToMenuItemConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToMenuItemConnectionEdge]" },
    nodes: { __type: "[MenuItem]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToMenuItemConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "MenuItem" },
  },
  RootQueryToMenuItemConnectionWhereArgs: {
    id: { __type: "Int" },
    location: { __type: "MenuLocationEnum" },
    parentDatabaseId: { __type: "Int" },
    parentId: { __type: "ID" },
  },
  RootQueryToPageConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToPageConnectionEdge]" },
    nodes: { __type: "[Page]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToPageConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Page" },
  },
  RootQueryToPageConnectionWhereArgs: {
    author: { __type: "Int" },
    authorIn: { __type: "[ID]" },
    authorName: { __type: "String" },
    authorNotIn: { __type: "[ID]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  RootQueryToPluginConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToPluginConnectionEdge]" },
    nodes: { __type: "[Plugin]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToPluginConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Plugin" },
  },
  RootQueryToPluginConnectionWhereArgs: {
    search: { __type: "String" },
    stati: { __type: "[PluginStatusEnum]" },
    status: { __type: "PluginStatusEnum" },
  },
  RootQueryToPostConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToPostConnectionEdge]" },
    nodes: { __type: "[Post]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToPostConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Post" },
  },
  RootQueryToPostConnectionWhereArgs: {
    author: { __type: "Int" },
    authorIn: { __type: "[ID]" },
    authorName: { __type: "String" },
    authorNotIn: { __type: "[ID]" },
    categoryId: { __type: "Int" },
    categoryIn: { __type: "[ID]" },
    categoryName: { __type: "String" },
    categoryNotIn: { __type: "[ID]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    onlySticky: { __type: "Boolean" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    tag: { __type: "String" },
    tagId: { __type: "String" },
    tagIn: { __type: "[ID]" },
    tagNotIn: { __type: "[ID]" },
    tagSlugAnd: { __type: "[String]" },
    tagSlugIn: { __type: "[String]" },
    title: { __type: "String" },
  },
  RootQueryToPostFormatConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToPostFormatConnectionEdge]" },
    nodes: { __type: "[PostFormat]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToPostFormatConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "PostFormat" },
  },
  RootQueryToPostFormatConnectionWhereArgs: {
    cacheDomain: { __type: "String" },
    childOf: { __type: "Int" },
    childless: { __type: "Boolean" },
    descriptionLike: { __type: "String" },
    exclude: { __type: "[ID]" },
    excludeTree: { __type: "[ID]" },
    hideEmpty: { __type: "Boolean" },
    hierarchical: { __type: "Boolean" },
    include: { __type: "[ID]" },
    name: { __type: "[String]" },
    nameLike: { __type: "String" },
    objectIds: { __type: "[ID]" },
    order: { __type: "OrderEnum" },
    orderby: { __type: "TermObjectsConnectionOrderbyEnum" },
    padCounts: { __type: "Boolean" },
    parent: { __type: "Int" },
    search: { __type: "String" },
    slug: { __type: "[String]" },
    termTaxonomId: { __type: "[ID]" },
    updateTermMetaCache: { __type: "Boolean" },
  },
  RootQueryToReusableBlockConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToReusableBlockConnectionEdge]" },
    nodes: { __type: "[ReusableBlock]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToReusableBlockConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "ReusableBlock" },
  },
  RootQueryToReusableBlockConnectionWhereArgs: {
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  RootQueryToServiceConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToServiceConnectionEdge]" },
    nodes: { __type: "[Service]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToServiceConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Service" },
  },
  RootQueryToServiceConnectionWhereArgs: {
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  RootQueryToSpecialtyConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToSpecialtyConnectionEdge]" },
    nodes: { __type: "[Specialty]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToSpecialtyConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Specialty" },
  },
  RootQueryToSpecialtyConnectionWhereArgs: {
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  RootQueryToTagConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToTagConnectionEdge]" },
    nodes: { __type: "[Tag]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToTagConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Tag" },
  },
  RootQueryToTagConnectionWhereArgs: {
    cacheDomain: { __type: "String" },
    childOf: { __type: "Int" },
    childless: { __type: "Boolean" },
    descriptionLike: { __type: "String" },
    exclude: { __type: "[ID]" },
    excludeTree: { __type: "[ID]" },
    hideEmpty: { __type: "Boolean" },
    hierarchical: { __type: "Boolean" },
    include: { __type: "[ID]" },
    name: { __type: "[String]" },
    nameLike: { __type: "String" },
    objectIds: { __type: "[ID]" },
    order: { __type: "OrderEnum" },
    orderby: { __type: "TermObjectsConnectionOrderbyEnum" },
    padCounts: { __type: "Boolean" },
    parent: { __type: "Int" },
    search: { __type: "String" },
    slug: { __type: "[String]" },
    termTaxonomId: { __type: "[ID]" },
    updateTermMetaCache: { __type: "Boolean" },
  },
  RootQueryToTaxonomyConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToTaxonomyConnectionEdge]" },
    nodes: { __type: "[Taxonomy]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToTaxonomyConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Taxonomy" },
  },
  RootQueryToTeam_memberConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToTeam_memberConnectionEdge]" },
    nodes: { __type: "[Team_member]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToTeam_memberConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Team_member" },
  },
  RootQueryToTeam_memberConnectionWhereArgs: {
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  RootQueryToTermNodeConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToTermNodeConnectionEdge]" },
    nodes: { __type: "[TermNode]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToTermNodeConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "TermNode" },
  },
  RootQueryToTermNodeConnectionWhereArgs: {
    cacheDomain: { __type: "String" },
    childOf: { __type: "Int" },
    childless: { __type: "Boolean" },
    descriptionLike: { __type: "String" },
    exclude: { __type: "[ID]" },
    excludeTree: { __type: "[ID]" },
    hideEmpty: { __type: "Boolean" },
    hierarchical: { __type: "Boolean" },
    include: { __type: "[ID]" },
    name: { __type: "[String]" },
    nameLike: { __type: "String" },
    objectIds: { __type: "[ID]" },
    order: { __type: "OrderEnum" },
    orderby: { __type: "TermObjectsConnectionOrderbyEnum" },
    padCounts: { __type: "Boolean" },
    parent: { __type: "Int" },
    search: { __type: "String" },
    slug: { __type: "[String]" },
    taxonomies: { __type: "[TaxonomyEnum]" },
    termTaxonomId: { __type: "[ID]" },
    updateTermMetaCache: { __type: "Boolean" },
  },
  RootQueryToThemeConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToThemeConnectionEdge]" },
    nodes: { __type: "[Theme]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToThemeConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Theme" },
  },
  RootQueryToUserConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToUserConnectionEdge]" },
    nodes: { __type: "[User]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToUserConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "User" },
  },
  RootQueryToUserConnectionWhereArgs: {
    exclude: { __type: "[Int]" },
    hasPublishedPosts: { __type: "[ContentTypeEnum]" },
    include: { __type: "[Int]" },
    login: { __type: "String" },
    loginIn: { __type: "[String]" },
    loginNotIn: { __type: "[String]" },
    nicename: { __type: "String" },
    nicenameIn: { __type: "[String]" },
    nicenameNotIn: { __type: "[String]" },
    orderby: { __type: "[UsersConnectionOrderbyInput]" },
    role: { __type: "UserRoleEnum" },
    roleIn: { __type: "[UserRoleEnum]" },
    roleNotIn: { __type: "[UserRoleEnum]" },
    search: { __type: "String" },
    searchColumns: { __type: "[UsersConnectionSearchColumnEnum]" },
  },
  RootQueryToUserRoleConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToUserRoleConnectionEdge]" },
    nodes: { __type: "[UserRole]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToUserRoleConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "UserRole" },
  },
  RootQueryToWorkConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[RootQueryToWorkConnectionEdge]" },
    nodes: { __type: "[Work]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  RootQueryToWorkConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Work" },
  },
  RootQueryToWorkConnectionWhereArgs: {
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  SEOBreadcrumbs: {
    __typename: { __type: "String!" },
    archivePrefix: { __type: "String" },
    boldLast: { __type: "Boolean" },
    enabled: { __type: "Boolean" },
    homeText: { __type: "String" },
    notFoundText: { __type: "String" },
    prefix: { __type: "String" },
    searchPrefix: { __type: "String" },
    separator: { __type: "String" },
    showBlogPage: { __type: "Boolean" },
  },
  SEOConfig: {
    __typename: { __type: "String!" },
    breadcrumbs: { __type: "SEOBreadcrumbs" },
    contentTypes: { __type: "SEOContentTypes" },
    openGraph: { __type: "SEOOpenGraph" },
    redirects: { __type: "[SEORedirect]" },
    schema: { __type: "SEOSchema" },
    social: { __type: "SEOSocial" },
    webmaster: { __type: "SEOWebmaster" },
  },
  SEOContentType: {
    __typename: { __type: "String!" },
    archive: { __type: "SEOContentTypeArchive" },
    metaDesc: { __type: "String" },
    metaRobotsNoindex: { __type: "Boolean" },
    schema: { __type: "SEOPageInfoSchema" },
    schemaType: { __type: "String" },
    title: { __type: "String" },
  },
  SEOContentTypeArchive: {
    __typename: { __type: "String!" },
    archiveLink: { __type: "String" },
    breadcrumbTitle: { __type: "String" },
    fullHead: { __type: "String" },
    hasArchive: { __type: "Boolean" },
    metaDesc: { __type: "String" },
    metaRobotsNoindex: { __type: "Boolean" },
    title: { __type: "String" },
  },
  SEOContentTypes: {
    __typename: { __type: "String!" },
    blockEditorPreview: { __type: "SEOContentType" },
    job: { __type: "SEOContentType" },
    mediaItem: { __type: "SEOContentType" },
    page: { __type: "SEOContentType" },
    post: { __type: "SEOContentType" },
    reusableBlock: { __type: "SEOContentType" },
    service: { __type: "SEOContentType" },
    specialty: { __type: "SEOContentType" },
    teamMember: { __type: "SEOContentType" },
    work: { __type: "SEOContentType" },
  },
  SEOOpenGraph: {
    __typename: { __type: "String!" },
    defaultImage: { __type: "MediaItem" },
    frontPage: { __type: "SEOOpenGraphFrontPage" },
  },
  SEOOpenGraphFrontPage: {
    __typename: { __type: "String!" },
    description: { __type: "String" },
    image: { __type: "MediaItem" },
    title: { __type: "String" },
  },
  SEOPageInfoSchema: {
    __typename: { __type: "String!" },
    raw: { __type: "String" },
  },
  SEOPostTypeBreadcrumbs: {
    __typename: { __type: "String!" },
    text: { __type: "String" },
    url: { __type: "String" },
  },
  SEOPostTypePageInfo: {
    __typename: { __type: "String!" },
    schema: { __type: "SEOPageInfoSchema" },
  },
  SEOPostTypeSchema: {
    __typename: { __type: "String!" },
    articleType: { __type: "[String]" },
    pageType: { __type: "[String]" },
    raw: { __type: "String" },
  },
  SEORedirect: {
    __typename: { __type: "String!" },
    format: { __type: "String" },
    origin: { __type: "String" },
    target: { __type: "String" },
    type: { __type: "Int" },
  },
  SEOSchema: {
    __typename: { __type: "String!" },
    companyLogo: { __type: "MediaItem" },
    companyName: { __type: "String" },
    companyOrPerson: { __type: "String" },
    homeUrl: { __type: "String" },
    inLanguage: { __type: "String" },
    logo: { __type: "MediaItem" },
    personLogo: { __type: "MediaItem" },
    personName: { __type: "String" },
    siteName: { __type: "String" },
    siteUrl: { __type: "String" },
    wordpressSiteName: { __type: "String" },
  },
  SEOSocial: {
    __typename: { __type: "String!" },
    facebook: { __type: "SEOSocialFacebook" },
    instagram: { __type: "SEOSocialInstagram" },
    linkedIn: { __type: "SEOSocialLinkedIn" },
    mySpace: { __type: "SEOSocialMySpace" },
    pinterest: { __type: "SEOSocialPinterest" },
    twitter: { __type: "SEOSocialTwitter" },
    wikipedia: { __type: "SEOSocialWikipedia" },
    youTube: { __type: "SEOSocialYoutube" },
  },
  SEOSocialFacebook: {
    __typename: { __type: "String!" },
    defaultImage: { __type: "MediaItem" },
    url: { __type: "String" },
  },
  SEOSocialInstagram: {
    __typename: { __type: "String!" },
    url: { __type: "String" },
  },
  SEOSocialLinkedIn: {
    __typename: { __type: "String!" },
    url: { __type: "String" },
  },
  SEOSocialMySpace: {
    __typename: { __type: "String!" },
    url: { __type: "String" },
  },
  SEOSocialPinterest: {
    __typename: { __type: "String!" },
    metaTag: { __type: "String" },
    url: { __type: "String" },
  },
  SEOSocialTwitter: {
    __typename: { __type: "String!" },
    cardType: { __type: "SEOCardType" },
    username: { __type: "String" },
  },
  SEOSocialWikipedia: {
    __typename: { __type: "String!" },
    url: { __type: "String" },
  },
  SEOSocialYoutube: {
    __typename: { __type: "String!" },
    url: { __type: "String" },
  },
  SEOTaxonomySchema: {
    __typename: { __type: "String!" },
    raw: { __type: "String" },
  },
  SEOUser: {
    __typename: { __type: "String!" },
    breadcrumbTitle: { __type: "String" },
    canonical: { __type: "String" },
    fullHead: { __type: "String" },
    language: { __type: "String" },
    metaDesc: { __type: "String" },
    metaRobotsNofollow: { __type: "String" },
    metaRobotsNoindex: { __type: "String" },
    opengraphDescription: { __type: "String" },
    opengraphImage: { __type: "MediaItem" },
    opengraphTitle: { __type: "String" },
    region: { __type: "String" },
    schema: { __type: "SEOUserSchema" },
    social: { __type: "SEOUserSocial" },
    title: { __type: "String" },
    twitterDescription: { __type: "String" },
    twitterImage: { __type: "MediaItem" },
    twitterTitle: { __type: "String" },
  },
  SEOUserSchema: {
    __typename: { __type: "String!" },
    articleType: { __type: "[String]" },
    pageType: { __type: "[String]" },
    raw: { __type: "String" },
  },
  SEOUserSocial: {
    __typename: { __type: "String!" },
    facebook: { __type: "String" },
    instagram: { __type: "String" },
    linkedIn: { __type: "String" },
    mySpace: { __type: "String" },
    pinterest: { __type: "String" },
    soundCloud: { __type: "String" },
    twitter: { __type: "String" },
    wikipedia: { __type: "String" },
    youTube: { __type: "String" },
  },
  SEOWebmaster: {
    __typename: { __type: "String!" },
    baiduVerify: { __type: "String" },
    googleVerify: { __type: "String" },
    msVerify: { __type: "String" },
    yandexVerify: { __type: "String" },
  },
  SectionField: {
    __typename: { __type: "String!" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    displayOnly: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputType: { __type: "FormFieldTypeEnum" },
    label: { __type: "String" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    type: { __type: "FormFieldTypeEnum" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  SelectField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    autocompleteAttribute: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    choices: { __type: "[SelectFieldChoice]" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasAutocomplete: { __type: "Boolean" },
    hasChoiceValue: { __type: "Boolean" },
    hasEnhancedUI: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  SelectFieldChoice: {
    __typename: { __type: "String!" },
    isSelected: { __type: "Boolean" },
    text: { __type: "String" },
    value: { __type: "String" },
  },
  SendPasswordResetEmailInput: {
    clientMutationId: { __type: "String" },
    username: { __type: "String!" },
  },
  SendPasswordResetEmailPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    user: { __type: "User" },
  },
  Service: {
    __typename: { __type: "String!" },
    blocks: { __type: "[Block!]" },
    blocksJSON: { __type: "String" },
    conditionalTags: { __type: "ConditionalTags" },
    content: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    contentType: { __type: "ContentNodeToContentTypeConnectionEdge" },
    contentTypeName: { __type: "String!" },
    databaseId: { __type: "Int!" },
    date: { __type: "String" },
    dateGmt: { __type: "String" },
    desiredSlug: { __type: "String" },
    editingLockedBy: { __type: "ContentNodeToEditLockConnectionEdge" },
    enclosure: { __type: "String" },
    enqueuedScripts: {
      __type: "ContentNodeToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    enqueuedStylesheets: {
      __type: "ContentNodeToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    featuredImage: { __type: "NodeWithFeaturedImageToMediaItemConnectionEdge" },
    featuredImageDatabaseId: { __type: "Int" },
    featuredImageId: { __type: "ID" },
    guid: { __type: "String" },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isPreview: { __type: "Boolean" },
    isRestricted: { __type: "Boolean" },
    isTermNode: { __type: "Boolean!" },
    lastEditedBy: { __type: "ContentNodeToEditLastConnectionEdge" },
    link: { __type: "String" },
    modified: { __type: "String" },
    modifiedGmt: { __type: "String" },
    preview: { __type: "ServiceToPreviewConnectionEdge" },
    previewBlocks: { __type: "[Block!]" },
    previewBlocksJSON: { __type: "String" },
    previewRevisionDatabaseId: { __type: "Int" },
    previewRevisionId: { __type: "ID" },
    seo: { __type: "PostTypeSEO" },
    serviceId: { __type: "Int!" },
    slug: { __type: "String" },
    status: { __type: "String" },
    template: { __type: "ContentTemplate" },
    templates: { __type: "[String]" },
    title: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    uri: { __type: "String" },
  },
  ServiceToPreviewConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "Service" },
  },
  Settings: {
    __typename: { __type: "String!" },
    discussionSettingsDefaultCommentStatus: { __type: "String" },
    discussionSettingsDefaultPingStatus: { __type: "String" },
    generalSettingsDateFormat: { __type: "String" },
    generalSettingsDescription: { __type: "String" },
    generalSettingsEmail: { __type: "String" },
    generalSettingsLanguage: { __type: "String" },
    generalSettingsStartOfWeek: { __type: "Int" },
    generalSettingsTimeFormat: { __type: "String" },
    generalSettingsTimezone: { __type: "String" },
    generalSettingsTitle: { __type: "String" },
    generalSettingsUrl: { __type: "String" },
    readingSettingsPageForPosts: { __type: "Int" },
    readingSettingsPageOnFront: { __type: "Int" },
    readingSettingsPostsPerPage: { __type: "Int" },
    readingSettingsShowOnFront: { __type: "String" },
    writingSettingsDefaultCategory: { __type: "Int" },
    writingSettingsDefaultPostFormat: { __type: "String" },
    writingSettingsUseSmilies: { __type: "Boolean" },
  },
  Specialty: {
    __typename: { __type: "String!" },
    blocks: { __type: "[Block!]" },
    blocksJSON: { __type: "String" },
    conditionalTags: { __type: "ConditionalTags" },
    content: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    contentType: { __type: "ContentNodeToContentTypeConnectionEdge" },
    contentTypeName: { __type: "String!" },
    databaseId: { __type: "Int!" },
    date: { __type: "String" },
    dateGmt: { __type: "String" },
    desiredSlug: { __type: "String" },
    editingLockedBy: { __type: "ContentNodeToEditLockConnectionEdge" },
    enclosure: { __type: "String" },
    enqueuedScripts: {
      __type: "ContentNodeToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    enqueuedStylesheets: {
      __type: "ContentNodeToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    featuredImage: { __type: "NodeWithFeaturedImageToMediaItemConnectionEdge" },
    featuredImageDatabaseId: { __type: "Int" },
    featuredImageId: { __type: "ID" },
    guid: { __type: "String" },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isPreview: { __type: "Boolean" },
    isRestricted: { __type: "Boolean" },
    isTermNode: { __type: "Boolean!" },
    lastEditedBy: { __type: "ContentNodeToEditLastConnectionEdge" },
    link: { __type: "String" },
    modified: { __type: "String" },
    modifiedGmt: { __type: "String" },
    preview: { __type: "SpecialtyToPreviewConnectionEdge" },
    previewBlocks: { __type: "[Block!]" },
    previewBlocksJSON: { __type: "String" },
    previewRevisionDatabaseId: { __type: "Int" },
    previewRevisionId: { __type: "ID" },
    seo: { __type: "PostTypeSEO" },
    slug: { __type: "String" },
    specialtyId: { __type: "Int!" },
    status: { __type: "String" },
    template: { __type: "ContentTemplate" },
    templates: { __type: "[String]" },
    title: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    uri: { __type: "String" },
  },
  SpecialtyToPreviewConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "Specialty" },
  },
  SubmitField: {
    __typename: { __type: "String!" },
    displayOnly: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputType: { __type: "FormFieldTypeEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  SubmitFormMetaInput: {
    createdById: { __type: "Int" },
    dateCreatedGmt: { __type: "String" },
    ip: { __type: "String" },
    sourceUrl: { __type: "String" },
    userAgent: { __type: "String" },
  },
  SubmitGfDraftEntryInput: {
    clientMutationId: { __type: "String" },
    id: { __type: "ID!" },
    idType: { __type: "DraftEntryIdTypeEnum" },
  },
  SubmitGfDraftEntryPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    entry: { __type: "GfSubmittedEntry" },
    errors: { __type: "[FieldError]" },
  },
  SubmitGfFormInput: {
    clientMutationId: { __type: "String" },
    entryMeta: { __type: "SubmitFormMetaInput" },
    fieldValues: { __type: "[FormFieldValuesInput]!" },
    id: { __type: "ID!" },
    saveAsDraft: { __type: "Boolean" },
    sourcePage: { __type: "Int" },
    targetPage: { __type: "Int" },
  },
  SubmitGfFormPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    entry: { __type: "GfEntry" },
    errors: { __type: "[FieldError]" },
    resumeUrl: { __type: "String" },
  },
  Tag: {
    __typename: { __type: "String!" },
    conditionalTags: { __type: "ConditionalTags" },
    contentNodes: {
      __type: "TagToContentNodeConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "TagToContentNodeConnectionWhereArgs",
      },
    },
    count: { __type: "Int" },
    databaseId: { __type: "Int!" },
    description: { __type: "String" },
    enqueuedScripts: {
      __type: "TermNodeToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    enqueuedStylesheets: {
      __type: "TermNodeToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isRestricted: { __type: "Boolean" },
    isTermNode: { __type: "Boolean!" },
    link: { __type: "String" },
    name: { __type: "String" },
    posts: {
      __type: "TagToPostConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "TagToPostConnectionWhereArgs",
      },
    },
    seo: { __type: "TaxonomySEO" },
    slug: { __type: "String" },
    tagId: { __type: "Int" },
    taxonomy: { __type: "TagToTaxonomyConnectionEdge" },
    taxonomyName: { __type: "String" },
    templates: { __type: "[String]" },
    termGroupId: { __type: "Int" },
    termTaxonomyId: { __type: "Int" },
    uri: { __type: "String" },
  },
  TagToContentNodeConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[TagToContentNodeConnectionEdge]" },
    nodes: { __type: "[ContentNode]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  TagToContentNodeConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "ContentNode" },
  },
  TagToContentNodeConnectionWhereArgs: {
    contentTypes: { __type: "[ContentTypesOfTagEnum]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  TagToPostConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[TagToPostConnectionEdge]" },
    nodes: { __type: "[Post]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  TagToPostConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Post" },
  },
  TagToPostConnectionWhereArgs: {
    author: { __type: "Int" },
    authorIn: { __type: "[ID]" },
    authorName: { __type: "String" },
    authorNotIn: { __type: "[ID]" },
    categoryId: { __type: "Int" },
    categoryIn: { __type: "[ID]" },
    categoryName: { __type: "String" },
    categoryNotIn: { __type: "[ID]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    tag: { __type: "String" },
    tagId: { __type: "String" },
    tagIn: { __type: "[ID]" },
    tagNotIn: { __type: "[ID]" },
    tagSlugAnd: { __type: "[String]" },
    tagSlugIn: { __type: "[String]" },
    title: { __type: "String" },
  },
  TagToTaxonomyConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "Taxonomy" },
  },
  Taxonomy: {
    __typename: { __type: "String!" },
    connectedContentTypes: {
      __type: "TaxonomyToContentTypeConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    description: { __type: "String" },
    graphqlPluralName: { __type: "String" },
    graphqlSingleName: { __type: "String" },
    hierarchical: { __type: "Boolean" },
    id: { __type: "ID!" },
    isRestricted: { __type: "Boolean" },
    label: { __type: "String" },
    name: { __type: "String" },
    public: { __type: "Boolean" },
    restBase: { __type: "String" },
    restControllerClass: { __type: "String" },
    showCloud: { __type: "Boolean" },
    showInAdminColumn: { __type: "Boolean" },
    showInGraphql: { __type: "Boolean" },
    showInMenu: { __type: "Boolean" },
    showInNavMenus: { __type: "Boolean" },
    showInQuickEdit: { __type: "Boolean" },
    showInRest: { __type: "Boolean" },
    showUi: { __type: "Boolean" },
  },
  TaxonomySEO: {
    __typename: { __type: "String!" },
    breadcrumbs: { __type: "[SEOPostTypeBreadcrumbs]" },
    canonical: { __type: "String" },
    cornerstone: { __type: "Boolean" },
    focuskw: { __type: "String" },
    fullHead: { __type: "String" },
    metaDesc: { __type: "String" },
    metaKeywords: { __type: "String" },
    metaRobotsNofollow: { __type: "String" },
    metaRobotsNoindex: { __type: "String" },
    opengraphAuthor: { __type: "String" },
    opengraphDescription: { __type: "String" },
    opengraphImage: { __type: "MediaItem" },
    opengraphModifiedTime: { __type: "String" },
    opengraphPublishedTime: { __type: "String" },
    opengraphPublisher: { __type: "String" },
    opengraphSiteName: { __type: "String" },
    opengraphTitle: { __type: "String" },
    opengraphType: { __type: "String" },
    opengraphUrl: { __type: "String" },
    schema: { __type: "SEOTaxonomySchema" },
    title: { __type: "String" },
    twitterDescription: { __type: "String" },
    twitterImage: { __type: "MediaItem" },
    twitterTitle: { __type: "String" },
  },
  TaxonomyToContentTypeConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[TaxonomyToContentTypeConnectionEdge]" },
    nodes: { __type: "[ContentType]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  TaxonomyToContentTypeConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "ContentType" },
  },
  Team_member: {
    __typename: { __type: "String!" },
    blocks: { __type: "[Block!]" },
    blocksJSON: { __type: "String" },
    conditionalTags: { __type: "ConditionalTags" },
    content: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    contentType: { __type: "ContentNodeToContentTypeConnectionEdge" },
    contentTypeName: { __type: "String!" },
    databaseId: { __type: "Int!" },
    date: { __type: "String" },
    dateGmt: { __type: "String" },
    desiredSlug: { __type: "String" },
    editingLockedBy: { __type: "ContentNodeToEditLockConnectionEdge" },
    enclosure: { __type: "String" },
    enqueuedScripts: {
      __type: "ContentNodeToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    enqueuedStylesheets: {
      __type: "ContentNodeToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    featuredImage: { __type: "NodeWithFeaturedImageToMediaItemConnectionEdge" },
    featuredImageDatabaseId: { __type: "Int" },
    featuredImageId: { __type: "ID" },
    guid: { __type: "String" },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isPreview: { __type: "Boolean" },
    isRestricted: { __type: "Boolean" },
    isTermNode: { __type: "Boolean!" },
    lastEditedBy: { __type: "ContentNodeToEditLastConnectionEdge" },
    link: { __type: "String" },
    modified: { __type: "String" },
    modifiedGmt: { __type: "String" },
    preview: { __type: "Team_memberToPreviewConnectionEdge" },
    previewBlocks: { __type: "[Block!]" },
    previewBlocksJSON: { __type: "String" },
    previewRevisionDatabaseId: { __type: "Int" },
    previewRevisionId: { __type: "ID" },
    seo: { __type: "PostTypeSEO" },
    slug: { __type: "String" },
    status: { __type: "String" },
    team: { __type: "Team_member_Team" },
    team_memberId: { __type: "Int!" },
    template: { __type: "ContentTemplate" },
    templates: { __type: "[String]" },
    title: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    uri: { __type: "String" },
  },
  Team_memberToPreviewConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "Team_member" },
  },
  Team_member_Team: {
    __typename: { __type: "String!" },
    fieldGroupName: { __type: "String" },
    title: { __type: "String" },
  },
  Template_About: {
    __typename: { __type: "String!" },
    templateName: { __type: "String" },
  },
  Template_Blog: {
    __typename: { __type: "String!" },
    templateName: { __type: "String" },
  },
  Template_Contact: {
    __typename: { __type: "String!" },
    templateName: { __type: "String" },
  },
  Template_Home: {
    __typename: { __type: "String!" },
    templateName: { __type: "String" },
  },
  Template_Work: {
    __typename: { __type: "String!" },
    templateName: { __type: "String" },
  },
  TermNode: {
    __typename: { __type: "String!" },
    conditionalTags: { __type: "ConditionalTags" },
    count: { __type: "Int" },
    databaseId: { __type: "Int!" },
    description: { __type: "String" },
    enqueuedScripts: {
      __type: "TermNodeToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    enqueuedStylesheets: {
      __type: "TermNodeToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isRestricted: { __type: "Boolean" },
    isTermNode: { __type: "Boolean!" },
    link: { __type: "String" },
    name: { __type: "String" },
    slug: { __type: "String" },
    taxonomyName: { __type: "String" },
    templates: { __type: "[String]" },
    termGroupId: { __type: "Int" },
    termTaxonomyId: { __type: "Int" },
    uri: { __type: "String" },
    $on: { __type: "$TermNode!" },
  },
  TermNodeToEnqueuedScriptConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[TermNodeToEnqueuedScriptConnectionEdge]" },
    nodes: { __type: "[EnqueuedScript]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  TermNodeToEnqueuedScriptConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "EnqueuedScript" },
  },
  TermNodeToEnqueuedStylesheetConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[TermNodeToEnqueuedStylesheetConnectionEdge]" },
    nodes: { __type: "[EnqueuedStylesheet]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  TermNodeToEnqueuedStylesheetConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "EnqueuedStylesheet" },
  },
  TextAreaField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasRichTextEditor: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    maxLength: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  TextField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    autocompleteAttribute: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    hasAutocomplete: { __type: "Boolean" },
    hasInputMask: { __type: "Boolean" },
    id: { __type: "Int!" },
    inputMaskValue: { __type: "String" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isPasswordInput: { __type: "Boolean" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    maxLength: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  Theme: {
    __typename: { __type: "String!" },
    author: { __type: "String" },
    authorUri: { __type: "String" },
    description: { __type: "String" },
    id: { __type: "ID!" },
    isRestricted: { __type: "Boolean" },
    name: { __type: "String" },
    screenshot: { __type: "String" },
    slug: { __type: "String" },
    tags: { __type: "[String]" },
    themeUri: { __type: "String" },
    version: { __type: "String" },
  },
  TimeField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    id: { __type: "Int!" },
    inputType: { __type: "FormFieldTypeEnum" },
    inputs: { __type: "[TimeInputProperty]" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    shouldAllowDuplicates: { __type: "Boolean" },
    subLabelPlacement: { __type: "FormFieldSubLabelPlacementEnum" },
    timeFormat: { __type: "TimeFieldFormatEnum" },
    timeValues: { __type: "TimeFieldValue" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  TimeFieldValue: {
    __typename: { __type: "String!" },
    amPm: { __type: "AmPmEnum" },
    displayValue: { __type: "String" },
    hours: { __type: "String" },
    minutes: { __type: "String" },
  },
  TimeInputProperty: {
    __typename: { __type: "String!" },
    autocompleteAttribute: { __type: "String" },
    customLabel: { __type: "String" },
    defaultValue: { __type: "String" },
    id: { __type: "Float" },
    label: { __type: "String" },
    placeholder: { __type: "String" },
  },
  UniformResourceIdentifiable: {
    __typename: { __type: "String!" },
    conditionalTags: { __type: "ConditionalTags" },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isTermNode: { __type: "Boolean!" },
    templates: { __type: "[String]" },
    uri: { __type: "String" },
    $on: { __type: "$UniformResourceIdentifiable!" },
  },
  UpdateCategoryInput: {
    aliasOf: { __type: "String" },
    clientMutationId: { __type: "String" },
    description: { __type: "String" },
    id: { __type: "ID!" },
    name: { __type: "String" },
    parentId: { __type: "ID" },
    slug: { __type: "String" },
  },
  UpdateCategoryPayload: {
    __typename: { __type: "String!" },
    category: { __type: "Category" },
    clientMutationId: { __type: "String" },
  },
  UpdateCommentInput: {
    approved: { __type: "String" },
    author: { __type: "String" },
    authorEmail: { __type: "String" },
    authorUrl: { __type: "String" },
    clientMutationId: { __type: "String" },
    commentOn: { __type: "Int" },
    content: { __type: "String" },
    date: { __type: "String" },
    id: { __type: "ID!" },
    parent: { __type: "ID" },
    type: { __type: "String" },
  },
  UpdateCommentPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    comment: { __type: "Comment" },
    success: { __type: "Boolean" },
  },
  UpdateDraftEntryMetaInput: {
    createdById: { __type: "Int" },
    dateCreatedGmt: { __type: "String" },
    ip: { __type: "String" },
    sourceUrl: { __type: "String" },
    userAgent: { __type: "String" },
  },
  UpdateEntryMetaInput: {
    createdById: { __type: "Int" },
    dateCreatedGmt: { __type: "String" },
    ip: { __type: "String" },
    isRead: { __type: "Boolean" },
    isStarred: { __type: "Boolean" },
    sourceUrl: { __type: "String" },
    status: { __type: "EntryStatusEnum" },
    userAgent: { __type: "String" },
  },
  UpdateGfDraftEntryInput: {
    clientMutationId: { __type: "String" },
    entryMeta: { __type: "UpdateDraftEntryMetaInput" },
    fieldValues: { __type: "[FormFieldValuesInput]" },
    id: { __type: "ID!" },
    idType: { __type: "DraftEntryIdTypeEnum" },
    shouldValidate: { __type: "Boolean" },
  },
  UpdateGfDraftEntryPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    draftEntry: { __type: "GfDraftEntry" },
    errors: { __type: "[FieldError]" },
    resumeUrl: { __type: "String" },
  },
  UpdateGfEntryInput: {
    clientMutationId: { __type: "String" },
    entryMeta: { __type: "UpdateEntryMetaInput" },
    fieldValues: { __type: "[FormFieldValuesInput]" },
    id: { __type: "ID!" },
    shouldValidate: { __type: "Boolean" },
  },
  UpdateGfEntryPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    entry: { __type: "GfSubmittedEntry" },
    errors: { __type: "[FieldError]" },
  },
  UpdateJobInput: {
    clientMutationId: { __type: "String" },
    content: { __type: "String" },
    date: { __type: "String" },
    id: { __type: "ID!" },
    menuOrder: { __type: "Int" },
    password: { __type: "String" },
    slug: { __type: "String" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  UpdateJobPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    job: { __type: "Job" },
  },
  UpdateMediaItemInput: {
    altText: { __type: "String" },
    authorId: { __type: "ID" },
    caption: { __type: "String" },
    clientMutationId: { __type: "String" },
    commentStatus: { __type: "String" },
    date: { __type: "String" },
    dateGmt: { __type: "String" },
    description: { __type: "String" },
    filePath: { __type: "String" },
    fileType: { __type: "MimeTypeEnum" },
    id: { __type: "ID!" },
    parentId: { __type: "ID" },
    pingStatus: { __type: "String" },
    slug: { __type: "String" },
    status: { __type: "MediaItemStatusEnum" },
    title: { __type: "String" },
  },
  UpdateMediaItemPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    mediaItem: { __type: "MediaItem" },
  },
  UpdatePageInput: {
    authorId: { __type: "ID" },
    clientMutationId: { __type: "String" },
    commentStatus: { __type: "String" },
    content: { __type: "String" },
    date: { __type: "String" },
    id: { __type: "ID!" },
    menuOrder: { __type: "Int" },
    parentId: { __type: "ID" },
    password: { __type: "String" },
    slug: { __type: "String" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  UpdatePagePayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    page: { __type: "Page" },
  },
  UpdatePostFormatInput: {
    aliasOf: { __type: "String" },
    clientMutationId: { __type: "String" },
    description: { __type: "String" },
    id: { __type: "ID!" },
    name: { __type: "String" },
    slug: { __type: "String" },
  },
  UpdatePostFormatPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    postFormat: { __type: "PostFormat" },
  },
  UpdatePostInput: {
    authorId: { __type: "ID" },
    categories: { __type: "PostCategoriesInput" },
    clientMutationId: { __type: "String" },
    commentStatus: { __type: "String" },
    content: { __type: "String" },
    date: { __type: "String" },
    excerpt: { __type: "String" },
    id: { __type: "ID!" },
    menuOrder: { __type: "Int" },
    password: { __type: "String" },
    pingStatus: { __type: "String" },
    pinged: { __type: "[String]" },
    postFormats: { __type: "PostPostFormatsInput" },
    slug: { __type: "String" },
    status: { __type: "PostStatusEnum" },
    tags: { __type: "PostTagsInput" },
    title: { __type: "String" },
    toPing: { __type: "[String]" },
  },
  UpdatePostPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    post: { __type: "Post" },
  },
  UpdateReusableBlockInput: {
    clientMutationId: { __type: "String" },
    content: { __type: "String" },
    date: { __type: "String" },
    id: { __type: "ID!" },
    menuOrder: { __type: "Int" },
    password: { __type: "String" },
    slug: { __type: "String" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  UpdateReusableBlockPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    reusableBlock: { __type: "ReusableBlock" },
  },
  UpdateServiceInput: {
    clientMutationId: { __type: "String" },
    content: { __type: "String" },
    date: { __type: "String" },
    id: { __type: "ID!" },
    menuOrder: { __type: "Int" },
    password: { __type: "String" },
    slug: { __type: "String" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  UpdateServicePayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    service: { __type: "Service" },
  },
  UpdateSettingsInput: {
    clientMutationId: { __type: "String" },
    discussionSettingsDefaultCommentStatus: { __type: "String" },
    discussionSettingsDefaultPingStatus: { __type: "String" },
    generalSettingsDateFormat: { __type: "String" },
    generalSettingsDescription: { __type: "String" },
    generalSettingsEmail: { __type: "String" },
    generalSettingsLanguage: { __type: "String" },
    generalSettingsStartOfWeek: { __type: "Int" },
    generalSettingsTimeFormat: { __type: "String" },
    generalSettingsTimezone: { __type: "String" },
    generalSettingsTitle: { __type: "String" },
    generalSettingsUrl: { __type: "String" },
    readingSettingsPageForPosts: { __type: "Int" },
    readingSettingsPageOnFront: { __type: "Int" },
    readingSettingsPostsPerPage: { __type: "Int" },
    readingSettingsShowOnFront: { __type: "String" },
    writingSettingsDefaultCategory: { __type: "Int" },
    writingSettingsDefaultPostFormat: { __type: "String" },
    writingSettingsUseSmilies: { __type: "Boolean" },
  },
  UpdateSettingsPayload: {
    __typename: { __type: "String!" },
    allSettings: { __type: "Settings" },
    clientMutationId: { __type: "String" },
    discussionSettings: { __type: "DiscussionSettings" },
    generalSettings: { __type: "GeneralSettings" },
    readingSettings: { __type: "ReadingSettings" },
    writingSettings: { __type: "WritingSettings" },
  },
  UpdateSpecialtyInput: {
    clientMutationId: { __type: "String" },
    content: { __type: "String" },
    date: { __type: "String" },
    id: { __type: "ID!" },
    menuOrder: { __type: "Int" },
    password: { __type: "String" },
    slug: { __type: "String" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  UpdateSpecialtyPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    specialty: { __type: "Specialty" },
  },
  UpdateTagInput: {
    aliasOf: { __type: "String" },
    clientMutationId: { __type: "String" },
    description: { __type: "String" },
    id: { __type: "ID!" },
    name: { __type: "String" },
    slug: { __type: "String" },
  },
  UpdateTagPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    tag: { __type: "Tag" },
  },
  UpdateTeam_memberInput: {
    clientMutationId: { __type: "String" },
    content: { __type: "String" },
    date: { __type: "String" },
    id: { __type: "ID!" },
    menuOrder: { __type: "Int" },
    password: { __type: "String" },
    slug: { __type: "String" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  UpdateTeam_memberPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    team_member: { __type: "Team_member" },
  },
  UpdateUserInput: {
    aim: { __type: "String" },
    clientMutationId: { __type: "String" },
    description: { __type: "String" },
    displayName: { __type: "String" },
    email: { __type: "String" },
    firstName: { __type: "String" },
    id: { __type: "ID!" },
    jabber: { __type: "String" },
    lastName: { __type: "String" },
    locale: { __type: "String" },
    nicename: { __type: "String" },
    nickname: { __type: "String" },
    password: { __type: "String" },
    registered: { __type: "String" },
    richEditing: { __type: "String" },
    roles: { __type: "[String]" },
    websiteUrl: { __type: "String" },
    yim: { __type: "String" },
  },
  UpdateUserPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    user: { __type: "User" },
  },
  UpdateWorkInput: {
    clientMutationId: { __type: "String" },
    content: { __type: "String" },
    date: { __type: "String" },
    id: { __type: "ID!" },
    menuOrder: { __type: "Int" },
    password: { __type: "String" },
    slug: { __type: "String" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  UpdateWorkPayload: {
    __typename: { __type: "String!" },
    clientMutationId: { __type: "String" },
    work: { __type: "Work" },
  },
  User: {
    __typename: { __type: "String!" },
    avatar: {
      __type: "Avatar",
      __args: {
        forceDefault: "Boolean",
        rating: "AvatarRatingEnum",
        size: "Int",
      },
    },
    blockEditorPreviews: {
      __type: "UserToBlockEditorPreviewConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "UserToBlockEditorPreviewConnectionWhereArgs",
      },
    },
    capKey: { __type: "String" },
    capabilities: { __type: "[String]" },
    comments: {
      __type: "UserToCommentConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "UserToCommentConnectionWhereArgs",
      },
    },
    conditionalTags: { __type: "ConditionalTags" },
    databaseId: { __type: "Int!" },
    description: { __type: "String" },
    email: { __type: "String" },
    enqueuedScripts: {
      __type: "UserToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    enqueuedStylesheets: {
      __type: "UserToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    extraCapabilities: { __type: "[String]" },
    firstName: { __type: "String" },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isRestricted: { __type: "Boolean" },
    isTermNode: { __type: "Boolean!" },
    lastName: { __type: "String" },
    locale: { __type: "String" },
    mediaItems: {
      __type: "UserToMediaItemConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "UserToMediaItemConnectionWhereArgs",
      },
    },
    name: { __type: "String" },
    nicename: { __type: "String" },
    nickname: { __type: "String" },
    pages: {
      __type: "UserToPageConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "UserToPageConnectionWhereArgs",
      },
    },
    posts: {
      __type: "UserToPostConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "UserToPostConnectionWhereArgs",
      },
    },
    registeredDate: { __type: "String" },
    revisions: {
      __type: "UserToContentRevisionUnionConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "UserToContentRevisionUnionConnectionWhereArgs",
      },
    },
    roles: {
      __type: "UserToUserRoleConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    seo: { __type: "SEOUser" },
    slug: { __type: "String" },
    templates: { __type: "[String]" },
    uri: { __type: "String" },
    url: { __type: "String" },
    userId: { __type: "Int" },
    username: { __type: "String" },
  },
  UserRole: {
    __typename: { __type: "String!" },
    capabilities: { __type: "[String]" },
    displayName: { __type: "String" },
    id: { __type: "ID!" },
    isRestricted: { __type: "Boolean" },
    name: { __type: "String" },
  },
  UserToBlockEditorPreviewConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[UserToBlockEditorPreviewConnectionEdge]" },
    nodes: { __type: "[BlockEditorPreview]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  UserToBlockEditorPreviewConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "BlockEditorPreview" },
  },
  UserToBlockEditorPreviewConnectionWhereArgs: {
    author: { __type: "Int" },
    authorIn: { __type: "[ID]" },
    authorName: { __type: "String" },
    authorNotIn: { __type: "[ID]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  UserToCommentConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[UserToCommentConnectionEdge]" },
    nodes: { __type: "[Comment]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  UserToCommentConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Comment" },
  },
  UserToCommentConnectionWhereArgs: {
    authorEmail: { __type: "String" },
    authorIn: { __type: "[ID]" },
    authorNotIn: { __type: "[ID]" },
    authorUrl: { __type: "String" },
    commentIn: { __type: "[ID]" },
    commentNotIn: { __type: "[ID]" },
    commentType: { __type: "String" },
    commentTypeIn: { __type: "[String]" },
    commentTypeNotIn: { __type: "String" },
    contentAuthor: { __type: "[ID]" },
    contentAuthorIn: { __type: "[ID]" },
    contentAuthorNotIn: { __type: "[ID]" },
    contentId: { __type: "ID" },
    contentIdIn: { __type: "[ID]" },
    contentIdNotIn: { __type: "[ID]" },
    contentName: { __type: "String" },
    contentParent: { __type: "Int" },
    contentStatus: { __type: "[PostStatusEnum]" },
    contentType: { __type: "[ContentTypeEnum]" },
    includeUnapproved: { __type: "[ID]" },
    karma: { __type: "Int" },
    order: { __type: "OrderEnum" },
    orderby: { __type: "CommentsConnectionOrderbyEnum" },
    parent: { __type: "Int" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    search: { __type: "String" },
    status: { __type: "String" },
    userId: { __type: "ID" },
  },
  UserToContentRevisionUnionConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[UserToContentRevisionUnionConnectionEdge]" },
    nodes: { __type: "[ContentRevisionUnion]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  UserToContentRevisionUnionConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "ContentRevisionUnion" },
  },
  UserToContentRevisionUnionConnectionWhereArgs: {
    contentTypes: { __type: "[ContentTypeEnum]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  UserToEnqueuedScriptConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[UserToEnqueuedScriptConnectionEdge]" },
    nodes: { __type: "[EnqueuedScript]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  UserToEnqueuedScriptConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "EnqueuedScript" },
  },
  UserToEnqueuedStylesheetConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[UserToEnqueuedStylesheetConnectionEdge]" },
    nodes: { __type: "[EnqueuedStylesheet]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  UserToEnqueuedStylesheetConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "EnqueuedStylesheet" },
  },
  UserToMediaItemConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[UserToMediaItemConnectionEdge]" },
    nodes: { __type: "[MediaItem]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  UserToMediaItemConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "MediaItem" },
  },
  UserToMediaItemConnectionWhereArgs: {
    author: { __type: "Int" },
    authorIn: { __type: "[ID]" },
    authorName: { __type: "String" },
    authorNotIn: { __type: "[ID]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  UserToPageConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[UserToPageConnectionEdge]" },
    nodes: { __type: "[Page]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  UserToPageConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Page" },
  },
  UserToPageConnectionWhereArgs: {
    author: { __type: "Int" },
    authorIn: { __type: "[ID]" },
    authorName: { __type: "String" },
    authorNotIn: { __type: "[ID]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    title: { __type: "String" },
  },
  UserToPostConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[UserToPostConnectionEdge]" },
    nodes: { __type: "[Post]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  UserToPostConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "Post" },
  },
  UserToPostConnectionWhereArgs: {
    author: { __type: "Int" },
    authorIn: { __type: "[ID]" },
    authorName: { __type: "String" },
    authorNotIn: { __type: "[ID]" },
    categoryId: { __type: "Int" },
    categoryIn: { __type: "[ID]" },
    categoryName: { __type: "String" },
    categoryNotIn: { __type: "[ID]" },
    dateQuery: { __type: "DateQueryInput" },
    hasPassword: { __type: "Boolean" },
    id: { __type: "Int" },
    in: { __type: "[ID]" },
    mimeType: { __type: "MimeTypeEnum" },
    name: { __type: "String" },
    nameIn: { __type: "[String]" },
    notIn: { __type: "[ID]" },
    orderby: { __type: "[PostObjectsConnectionOrderbyInput]" },
    parent: { __type: "ID" },
    parentIn: { __type: "[ID]" },
    parentNotIn: { __type: "[ID]" },
    password: { __type: "String" },
    search: { __type: "String" },
    stati: { __type: "[PostStatusEnum]" },
    status: { __type: "PostStatusEnum" },
    tag: { __type: "String" },
    tagId: { __type: "String" },
    tagIn: { __type: "[ID]" },
    tagNotIn: { __type: "[ID]" },
    tagSlugAnd: { __type: "[String]" },
    tagSlugIn: { __type: "[String]" },
    title: { __type: "String" },
  },
  UserToUserRoleConnection: {
    __typename: { __type: "String!" },
    edges: { __type: "[UserToUserRoleConnectionEdge]" },
    nodes: { __type: "[UserRole]" },
    pageInfo: { __type: "WPPageInfo" },
  },
  UserToUserRoleConnectionEdge: {
    __typename: { __type: "String!" },
    cursor: { __type: "String" },
    node: { __type: "UserRole" },
  },
  UsersConnectionOrderbyInput: {
    field: { __type: "UsersConnectionOrderbyEnum!" },
    order: { __type: "OrderEnum" },
  },
  WPPageInfo: {
    __typename: { __type: "String!" },
    endCursor: { __type: "String" },
    hasNextPage: { __type: "Boolean!" },
    hasPreviousPage: { __type: "Boolean!" },
    seo: { __type: "SEOPostTypePageInfo" },
    startCursor: { __type: "String" },
  },
  WebsiteField: {
    __typename: { __type: "String!" },
    adminLabel: { __type: "String" },
    canPrepopulate: { __type: "Boolean" },
    conditionalLogic: { __type: "ConditionalLogic" },
    cssClass: { __type: "String" },
    defaultValue: { __type: "String" },
    description: { __type: "String" },
    descriptionPlacement: { __type: "FormFieldDescriptionPlacementEnum" },
    displayOnly: { __type: "Boolean" },
    errorMessage: { __type: "String" },
    id: { __type: "Int!" },
    inputName: { __type: "String" },
    inputType: { __type: "FormFieldTypeEnum" },
    isRequired: { __type: "Boolean" },
    label: { __type: "String" },
    labelPlacement: { __type: "FormFieldLabelPlacementEnum" },
    layoutGridColumnSpan: { __type: "Int" },
    layoutSpacerGridColumnSpan: { __type: "Int" },
    pageNumber: { __type: "Int" },
    personalData: { __type: "FormFieldDataPolicy" },
    placeholder: { __type: "String" },
    shouldAllowDuplicates: { __type: "Boolean" },
    size: { __type: "FormFieldSizeEnum" },
    type: { __type: "FormFieldTypeEnum" },
    value: { __type: "String" },
    visibility: { __type: "FormFieldVisibilityEnum" },
  },
  Work: {
    __typename: { __type: "String!" },
    blocks: { __type: "[Block!]" },
    blocksJSON: { __type: "String" },
    conditionalTags: { __type: "ConditionalTags" },
    content: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    contentType: { __type: "ContentNodeToContentTypeConnectionEdge" },
    contentTypeName: { __type: "String!" },
    databaseId: { __type: "Int!" },
    date: { __type: "String" },
    dateGmt: { __type: "String" },
    desiredSlug: { __type: "String" },
    editingLockedBy: { __type: "ContentNodeToEditLockConnectionEdge" },
    enclosure: { __type: "String" },
    enqueuedScripts: {
      __type: "ContentNodeToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    enqueuedStylesheets: {
      __type: "ContentNodeToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    featuredImage: { __type: "NodeWithFeaturedImageToMediaItemConnectionEdge" },
    featuredImageDatabaseId: { __type: "Int" },
    featuredImageId: { __type: "ID" },
    guid: { __type: "String" },
    id: { __type: "ID!" },
    isContentNode: { __type: "Boolean!" },
    isPreview: { __type: "Boolean" },
    isRestricted: { __type: "Boolean" },
    isTermNode: { __type: "Boolean!" },
    lastEditedBy: { __type: "ContentNodeToEditLastConnectionEdge" },
    link: { __type: "String" },
    modified: { __type: "String" },
    modifiedGmt: { __type: "String" },
    preview: { __type: "WorkToPreviewConnectionEdge" },
    previewBlocks: { __type: "[Block!]" },
    previewBlocksJSON: { __type: "String" },
    previewRevisionDatabaseId: { __type: "Int" },
    previewRevisionId: { __type: "ID" },
    seo: { __type: "PostTypeSEO" },
    slug: { __type: "String" },
    status: { __type: "String" },
    template: { __type: "ContentTemplate" },
    templates: { __type: "[String]" },
    title: {
      __type: "String",
      __args: { format: "PostObjectFieldFormatEnum" },
    },
    uri: { __type: "String" },
    workACF: { __type: "Work_Workacf" },
    workId: { __type: "Int!" },
  },
  WorkToPreviewConnectionEdge: {
    __typename: { __type: "String!" },
    node: { __type: "Work" },
  },
  Work_Workacf: {
    __typename: { __type: "String!" },
    color: { __type: "String" },
    description: { __type: "String" },
    fieldGroupName: { __type: "String" },
    link: { __type: "String" },
    problem: { __type: "String" },
    solution: { __type: "String" },
  },
  WritingSettings: {
    __typename: { __type: "String!" },
    defaultCategory: { __type: "Int" },
    defaultPostFormat: { __type: "String" },
    useSmilies: { __type: "Boolean" },
  },
  YoastFaqBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "YoastFaqBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  YoastFaqBlockAttributes: {
    __typename: { __type: "String!" },
    additionalListCssClasses: { __type: "String" },
    className: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    questions: { __type: "BlockAttributesArray" },
  },
  YoastFaqBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$YoastFaqBlockAttributesUnion!" },
  },
  YoastFaqBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    additionalListCssClasses: { __type: "String" },
    className: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    questions: { __type: "BlockAttributesArray" },
  },
  YoastHowToBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "YoastHowToBlockAttributesUnion" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  YoastHowToBlockAttributes: {
    __typename: { __type: "String!" },
    additionalListCssClasses: { __type: "String" },
    className: { __type: "String" },
    days: { __type: "String" },
    defaultDurationText: { __type: "String" },
    description: { __type: "BlockAttributesArray" },
    durationText: { __type: "String" },
    hasDuration: { __type: "Boolean" },
    hours: { __type: "String" },
    jsonDescription: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    minutes: { __type: "String" },
    steps: { __type: "BlockAttributesArray" },
    unorderedList: { __type: "Boolean" },
  },
  YoastHowToBlockAttributesUnion: {
    __typename: { __type: "String!" },
    $on: { __type: "$YoastHowToBlockAttributesUnion!" },
  },
  YoastHowToBlockDeprecatedV1Attributes: {
    __typename: { __type: "String!" },
    additionalListCssClasses: { __type: "String" },
    className: { __type: "String" },
    days: { __type: "String" },
    defaultDurationText: { __type: "String" },
    description: { __type: "BlockAttributesArray" },
    durationText: { __type: "String" },
    hasDuration: { __type: "Boolean" },
    hours: { __type: "String" },
    jsonDescription: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    minutes: { __type: "String" },
    steps: { __type: "BlockAttributesArray" },
    unorderedList: { __type: "Boolean" },
  },
  YoastHowToBlockDeprecatedV2Attributes: {
    __typename: { __type: "String!" },
    additionalListCssClasses: { __type: "String" },
    className: { __type: "String" },
    days: { __type: "String" },
    defaultDurationText: { __type: "String" },
    description: { __type: "BlockAttributesArray" },
    durationText: { __type: "String" },
    hasDuration: { __type: "Boolean" },
    hours: { __type: "String" },
    jsonDescription: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
    minutes: { __type: "String" },
    steps: { __type: "BlockAttributesArray" },
    unorderedList: { __type: "Boolean" },
  },
  YoastSeoBreadcrumbsBlock: {
    __typename: { __type: "String!" },
    attributes: { __type: "YoastSeoBreadcrumbsBlockAttributes" },
    attributesJSON: { __type: "String" },
    dynamicContent: { __type: "String" },
    innerBlocks: { __type: "[Block!]" },
    isDynamic: { __type: "Boolean!" },
    name: { __type: "String!" },
    order: { __type: "Int!" },
    originalContent: { __type: "String" },
    parentNode: { __type: "Node!" },
    parentNodeDatabaseId: { __type: "Int!" },
    saveContent: { __type: "String" },
  },
  YoastSeoBreadcrumbsBlockAttributes: {
    __typename: { __type: "String!" },
    className: { __type: "String" },
    lock: { __type: "BlockAttributesObject" },
  },
  mutation: {
    __typename: { __type: "String!" },
    createCategory: {
      __type: "CreateCategoryPayload",
      __args: { input: "CreateCategoryInput!" },
    },
    createComment: {
      __type: "CreateCommentPayload",
      __args: { input: "CreateCommentInput!" },
    },
    createJob: {
      __type: "CreateJobPayload",
      __args: { input: "CreateJobInput!" },
    },
    createMediaItem: {
      __type: "CreateMediaItemPayload",
      __args: { input: "CreateMediaItemInput!" },
    },
    createPage: {
      __type: "CreatePagePayload",
      __args: { input: "CreatePageInput!" },
    },
    createPost: {
      __type: "CreatePostPayload",
      __args: { input: "CreatePostInput!" },
    },
    createPostFormat: {
      __type: "CreatePostFormatPayload",
      __args: { input: "CreatePostFormatInput!" },
    },
    createReusableBlock: {
      __type: "CreateReusableBlockPayload",
      __args: { input: "CreateReusableBlockInput!" },
    },
    createService: {
      __type: "CreateServicePayload",
      __args: { input: "CreateServiceInput!" },
    },
    createSpecialty: {
      __type: "CreateSpecialtyPayload",
      __args: { input: "CreateSpecialtyInput!" },
    },
    createTag: {
      __type: "CreateTagPayload",
      __args: { input: "CreateTagInput!" },
    },
    createTeamMember: {
      __type: "CreateTeam_memberPayload",
      __args: { input: "CreateTeam_memberInput!" },
    },
    createUser: {
      __type: "CreateUserPayload",
      __args: { input: "CreateUserInput!" },
    },
    createWork: {
      __type: "CreateWorkPayload",
      __args: { input: "CreateWorkInput!" },
    },
    deleteCategory: {
      __type: "DeleteCategoryPayload",
      __args: { input: "DeleteCategoryInput!" },
    },
    deleteComment: {
      __type: "DeleteCommentPayload",
      __args: { input: "DeleteCommentInput!" },
    },
    deleteGfDraftEntry: {
      __type: "DeleteGfDraftEntryPayload",
      __args: { input: "DeleteGfDraftEntryInput!" },
    },
    deleteGfEntry: {
      __type: "DeleteGfEntryPayload",
      __args: { input: "DeleteGfEntryInput!" },
    },
    deleteJob: {
      __type: "DeleteJobPayload",
      __args: { input: "DeleteJobInput!" },
    },
    deleteMediaItem: {
      __type: "DeleteMediaItemPayload",
      __args: { input: "DeleteMediaItemInput!" },
    },
    deletePage: {
      __type: "DeletePagePayload",
      __args: { input: "DeletePageInput!" },
    },
    deletePost: {
      __type: "DeletePostPayload",
      __args: { input: "DeletePostInput!" },
    },
    deletePostFormat: {
      __type: "DeletePostFormatPayload",
      __args: { input: "DeletePostFormatInput!" },
    },
    deleteReusableBlock: {
      __type: "DeleteReusableBlockPayload",
      __args: { input: "DeleteReusableBlockInput!" },
    },
    deleteService: {
      __type: "DeleteServicePayload",
      __args: { input: "DeleteServiceInput!" },
    },
    deleteSpecialty: {
      __type: "DeleteSpecialtyPayload",
      __args: { input: "DeleteSpecialtyInput!" },
    },
    deleteTag: {
      __type: "DeleteTagPayload",
      __args: { input: "DeleteTagInput!" },
    },
    deleteTeamMember: {
      __type: "DeleteTeam_memberPayload",
      __args: { input: "DeleteTeam_memberInput!" },
    },
    deleteUser: {
      __type: "DeleteUserPayload",
      __args: { input: "DeleteUserInput!" },
    },
    deleteWork: {
      __type: "DeleteWorkPayload",
      __args: { input: "DeleteWorkInput!" },
    },
    generateAuthorizationCode: {
      __type: "GenerateAuthorizationCodePayload",
      __args: { input: "GenerateAuthorizationCodeInput!" },
    },
    increaseCount: { __type: "Int", __args: { count: "Int" } },
    registerUser: {
      __type: "RegisterUserPayload",
      __args: { input: "RegisterUserInput!" },
    },
    resetUserPassword: {
      __type: "ResetUserPasswordPayload",
      __args: { input: "ResetUserPasswordInput!" },
    },
    restoreComment: {
      __type: "RestoreCommentPayload",
      __args: { input: "RestoreCommentInput!" },
    },
    sendPasswordResetEmail: {
      __type: "SendPasswordResetEmailPayload",
      __args: { input: "SendPasswordResetEmailInput!" },
    },
    submitGfDraftEntry: {
      __type: "SubmitGfDraftEntryPayload",
      __args: { input: "SubmitGfDraftEntryInput!" },
    },
    submitGfForm: {
      __type: "SubmitGfFormPayload",
      __args: { input: "SubmitGfFormInput!" },
    },
    updateCategory: {
      __type: "UpdateCategoryPayload",
      __args: { input: "UpdateCategoryInput!" },
    },
    updateComment: {
      __type: "UpdateCommentPayload",
      __args: { input: "UpdateCommentInput!" },
    },
    updateGfDraftEntry: {
      __type: "UpdateGfDraftEntryPayload",
      __args: { input: "UpdateGfDraftEntryInput!" },
    },
    updateGfEntry: {
      __type: "UpdateGfEntryPayload",
      __args: { input: "UpdateGfEntryInput!" },
    },
    updateJob: {
      __type: "UpdateJobPayload",
      __args: { input: "UpdateJobInput!" },
    },
    updateMediaItem: {
      __type: "UpdateMediaItemPayload",
      __args: { input: "UpdateMediaItemInput!" },
    },
    updatePage: {
      __type: "UpdatePagePayload",
      __args: { input: "UpdatePageInput!" },
    },
    updatePost: {
      __type: "UpdatePostPayload",
      __args: { input: "UpdatePostInput!" },
    },
    updatePostFormat: {
      __type: "UpdatePostFormatPayload",
      __args: { input: "UpdatePostFormatInput!" },
    },
    updateReusableBlock: {
      __type: "UpdateReusableBlockPayload",
      __args: { input: "UpdateReusableBlockInput!" },
    },
    updateService: {
      __type: "UpdateServicePayload",
      __args: { input: "UpdateServiceInput!" },
    },
    updateSettings: {
      __type: "UpdateSettingsPayload",
      __args: { input: "UpdateSettingsInput!" },
    },
    updateSpecialty: {
      __type: "UpdateSpecialtyPayload",
      __args: { input: "UpdateSpecialtyInput!" },
    },
    updateTag: {
      __type: "UpdateTagPayload",
      __args: { input: "UpdateTagInput!" },
    },
    updateTeamMember: {
      __type: "UpdateTeam_memberPayload",
      __args: { input: "UpdateTeam_memberInput!" },
    },
    updateUser: {
      __type: "UpdateUserPayload",
      __args: { input: "UpdateUserInput!" },
    },
    updateWork: {
      __type: "UpdateWorkPayload",
      __args: { input: "UpdateWorkInput!" },
    },
  },
  query: {
    __typename: { __type: "String!" },
    allSettings: { __type: "Settings" },
    blockEditorContentNodes: {
      __type: "BlockEditorContentNodeConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "BlockEditorContentNodeConnectionWhereArgs",
      },
    },
    blockEditorPreview: {
      __type: "BlockEditorPreview",
      __args: {
        asPreview: "Boolean",
        id: "ID!",
        idType: "BlockEditorPreviewIdType",
      },
    },
    blockEditorPreviewBy: {
      __type: "BlockEditorPreview",
      __args: {
        blockEditorPreviewId: "Int",
        id: "ID",
        slug: "String",
        uri: "String",
      },
    },
    blockEditorPreviews: {
      __type: "RootQueryToBlockEditorPreviewConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToBlockEditorPreviewConnectionWhereArgs",
      },
    },
    categories: {
      __type: "RootQueryToCategoryConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToCategoryConnectionWhereArgs",
      },
    },
    category: {
      __type: "Category",
      __args: { id: "ID!", idType: "CategoryIdType" },
    },
    comment: { __type: "Comment", __args: { id: "ID!" } },
    comments: {
      __type: "RootQueryToCommentConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToCommentConnectionWhereArgs",
      },
    },
    contentNode: {
      __type: "ContentNode",
      __args: {
        asPreview: "Boolean",
        contentType: "ContentTypeEnum",
        id: "ID!",
        idType: "ContentNodeIdTypeEnum",
      },
    },
    contentNodes: {
      __type: "RootQueryToContentNodeConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToContentNodeConnectionWhereArgs",
      },
    },
    contentType: {
      __type: "ContentType",
      __args: { id: "ID!", idType: "ContentTypeIdTypeEnum" },
    },
    contentTypes: {
      __type: "RootQueryToContentTypeConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    discussionSettings: { __type: "DiscussionSettings" },
    generalOptionsSettings: { __type: "GeneralOptionsSettings" },
    generalSettings: { __type: "GeneralSettings" },
    gfDraftEntry: {
      __type: "GfDraftEntry",
      __args: { id: "ID!", idType: "DraftEntryIdTypeEnum" },
    },
    gfEntries: {
      __type: "RootQueryToGfEntryConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToGfEntryConnectionWhereArgs",
      },
    },
    gfEntry: {
      __type: "GfEntry",
      __args: { id: "ID!", idType: "EntryIdTypeEnum" },
    },
    gfForm: {
      __type: "GfForm",
      __args: { id: "ID!", idType: "FormIdTypeEnum" },
    },
    gfForms: {
      __type: "RootQueryToGfFormConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToGfFormConnectionWhereArgs",
      },
    },
    gfSettings: { __type: "GfSettings" },
    gfSubmittedEntries: {
      __type: "RootQueryToGfSubmittedEntryConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToGfSubmittedEntryConnectionWhereArgs",
      },
    },
    gfSubmittedEntry: {
      __type: "GfSubmittedEntry",
      __args: { id: "ID!", idType: "SubmittedEntryIdTypeEnum" },
    },
    job: {
      __type: "Job",
      __args: { asPreview: "Boolean", id: "ID!", idType: "JobIdType" },
    },
    jobBy: {
      __type: "Job",
      __args: { id: "ID", jobId: "Int", slug: "String", uri: "String" },
    },
    jobs: {
      __type: "RootQueryToJobConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToJobConnectionWhereArgs",
      },
    },
    mediaItem: {
      __type: "MediaItem",
      __args: { asPreview: "Boolean", id: "ID!", idType: "MediaItemIdType" },
    },
    mediaItemBy: {
      __type: "MediaItem",
      __args: { id: "ID", mediaItemId: "Int", slug: "String", uri: "String" },
    },
    mediaItems: {
      __type: "RootQueryToMediaItemConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToMediaItemConnectionWhereArgs",
      },
    },
    menu: {
      __type: "Menu",
      __args: { id: "ID!", idType: "MenuNodeIdTypeEnum" },
    },
    menuItem: {
      __type: "MenuItem",
      __args: { id: "ID!", idType: "MenuItemNodeIdTypeEnum" },
    },
    menuItems: {
      __type: "RootQueryToMenuItemConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToMenuItemConnectionWhereArgs",
      },
    },
    menus: {
      __type: "RootQueryToMenuConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToMenuConnectionWhereArgs",
      },
    },
    node: { __type: "Node", __args: { id: "ID" } },
    nodeByUri: {
      __type: "UniformResourceIdentifiable",
      __args: { uri: "String!" },
    },
    page: {
      __type: "Page",
      __args: { asPreview: "Boolean", id: "ID!", idType: "PageIdType" },
    },
    pageBy: {
      __type: "Page",
      __args: { id: "ID", pageId: "Int", uri: "String" },
    },
    pages: {
      __type: "RootQueryToPageConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToPageConnectionWhereArgs",
      },
    },
    plugin: { __type: "Plugin", __args: { id: "ID!" } },
    plugins: {
      __type: "RootQueryToPluginConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToPluginConnectionWhereArgs",
      },
    },
    post: {
      __type: "Post",
      __args: { asPreview: "Boolean", id: "ID!", idType: "PostIdType" },
    },
    postBy: {
      __type: "Post",
      __args: { id: "ID", postId: "Int", slug: "String", uri: "String" },
    },
    postFormat: {
      __type: "PostFormat",
      __args: { id: "ID!", idType: "PostFormatIdType" },
    },
    postFormats: {
      __type: "RootQueryToPostFormatConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToPostFormatConnectionWhereArgs",
      },
    },
    posts: {
      __type: "RootQueryToPostConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToPostConnectionWhereArgs",
      },
    },
    readingSettings: { __type: "ReadingSettings" },
    registeredScripts: {
      __type: "RootQueryToEnqueuedScriptConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    registeredStylesheets: {
      __type: "RootQueryToEnqueuedStylesheetConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    reusableBlock: {
      __type: "ReusableBlock",
      __args: {
        asPreview: "Boolean",
        id: "ID!",
        idType: "ReusableBlockIdType",
      },
    },
    reusableBlockBy: {
      __type: "ReusableBlock",
      __args: {
        id: "ID",
        reusableBlockId: "Int",
        slug: "String",
        uri: "String",
      },
    },
    reusableBlocks: {
      __type: "RootQueryToReusableBlockConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToReusableBlockConnectionWhereArgs",
      },
    },
    revisions: {
      __type: "RootQueryToContentRevisionUnionConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToContentRevisionUnionConnectionWhereArgs",
      },
    },
    seo: { __type: "SEOConfig" },
    service: {
      __type: "Service",
      __args: { asPreview: "Boolean", id: "ID!", idType: "ServiceIdType" },
    },
    serviceBy: {
      __type: "Service",
      __args: { id: "ID", serviceId: "Int", slug: "String", uri: "String" },
    },
    services: {
      __type: "RootQueryToServiceConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToServiceConnectionWhereArgs",
      },
    },
    specialties: {
      __type: "RootQueryToSpecialtyConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToSpecialtyConnectionWhereArgs",
      },
    },
    specialty: {
      __type: "Specialty",
      __args: { asPreview: "Boolean", id: "ID!", idType: "SpecialtyIdType" },
    },
    specialtyBy: {
      __type: "Specialty",
      __args: { id: "ID", slug: "String", specialtyId: "Int", uri: "String" },
    },
    tag: { __type: "Tag", __args: { id: "ID!", idType: "TagIdType" } },
    tags: {
      __type: "RootQueryToTagConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToTagConnectionWhereArgs",
      },
    },
    taxonomies: {
      __type: "RootQueryToTaxonomyConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    taxonomy: {
      __type: "Taxonomy",
      __args: { id: "ID!", idType: "TaxonomyIdTypeEnum" },
    },
    teamMember: {
      __type: "Team_member",
      __args: { asPreview: "Boolean", id: "ID!", idType: "Team_memberIdType" },
    },
    teamMemberBy: {
      __type: "Team_member",
      __args: { id: "ID", slug: "String", team_memberId: "Int", uri: "String" },
    },
    teamMembers: {
      __type: "RootQueryToTeam_memberConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToTeam_memberConnectionWhereArgs",
      },
    },
    termNode: {
      __type: "TermNode",
      __args: {
        id: "ID!",
        idType: "TermNodeIdTypeEnum",
        taxonomy: "TaxonomyEnum",
      },
    },
    terms: {
      __type: "RootQueryToTermNodeConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToTermNodeConnectionWhereArgs",
      },
    },
    theme: { __type: "Theme", __args: { id: "ID!" } },
    themes: {
      __type: "RootQueryToThemeConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    user: {
      __type: "User",
      __args: { id: "ID!", idType: "UserNodeIdTypeEnum" },
    },
    userRole: { __type: "UserRole", __args: { id: "ID!" } },
    userRoles: {
      __type: "RootQueryToUserRoleConnection",
      __args: { after: "String", before: "String", first: "Int", last: "Int" },
    },
    users: {
      __type: "RootQueryToUserConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToUserConnectionWhereArgs",
      },
    },
    viewer: { __type: "User" },
    work: {
      __type: "Work",
      __args: { asPreview: "Boolean", id: "ID!", idType: "WorkIdType" },
    },
    workBy: {
      __type: "Work",
      __args: { id: "ID", slug: "String", uri: "String", workId: "Int" },
    },
    works: {
      __type: "RootQueryToWorkConnection",
      __args: {
        after: "String",
        before: "String",
        first: "Int",
        last: "Int",
        where: "RootQueryToWorkConnectionWhereArgs",
      },
    },
    writingSettings: { __type: "WritingSettings" },
  },
  subscription: {},
  [SchemaUnionsKey]: {
    FormField: [
      "AddressField",
      "CaptchaField",
      "CheckboxField",
      "ConsentField",
      "DateField",
      "EmailField",
      "FileUploadField",
      "HiddenField",
      "HtmlField",
      "ListField",
      "MultiSelectField",
      "NameField",
      "NumberField",
      "PageField",
      "PasswordField",
      "PhoneField",
      "PostCategoryCheckboxField",
      "PostCategoryMultiSelectField",
      "PostCategoryRadioField",
      "PostCategorySelectField",
      "PostContentField",
      "PostCustomCheckboxField",
      "PostCustomDateField",
      "PostCustomEmailField",
      "PostCustomFileuploadField",
      "PostCustomHiddenField",
      "PostCustomListField",
      "PostCustomMultiSelectField",
      "PostCustomNumberField",
      "PostCustomPhoneField",
      "PostCustomRadioField",
      "PostCustomSelectField",
      "PostCustomTextAreaField",
      "PostCustomTextField",
      "PostCustomTimeField",
      "PostCustomWebsiteField",
      "PostExcerptField",
      "PostImageField",
      "PostTagsCheckboxField",
      "PostTagsMultiSelectField",
      "PostTagsRadioField",
      "PostTagsSelectField",
      "PostTagsTextField",
      "PostTitleField",
      "RadioField",
      "SectionField",
      "SelectField",
      "SubmitField",
      "TextAreaField",
      "TextField",
      "TimeField",
      "WebsiteField",
    ],
    ContentNode: [
      "BlockEditorPreview",
      "Job",
      "MediaItem",
      "Page",
      "Post",
      "ReusableBlock",
      "Service",
      "Specialty",
      "Team_member",
      "Work",
    ],
    DatabaseIdentifier: [
      "BlockEditorPreview",
      "Category",
      "Comment",
      "GfForm",
      "GfSubmittedEntry",
      "Job",
      "MediaItem",
      "Menu",
      "MenuItem",
      "Page",
      "Post",
      "PostFormat",
      "ReusableBlock",
      "Service",
      "Specialty",
      "Tag",
      "Team_member",
      "User",
      "Work",
    ],
    Node: [
      "BlockEditorPreview",
      "Category",
      "Comment",
      "CommentAuthor",
      "ContentType",
      "EnqueuedScript",
      "EnqueuedStylesheet",
      "GfDraftEntry",
      "GfForm",
      "GfSubmittedEntry",
      "Job",
      "MediaItem",
      "Menu",
      "MenuItem",
      "Page",
      "Plugin",
      "Post",
      "PostFormat",
      "ReusableBlock",
      "Service",
      "Specialty",
      "Tag",
      "Taxonomy",
      "Team_member",
      "Theme",
      "User",
      "UserRole",
      "Work",
    ],
    NodeWithAuthor: ["BlockEditorPreview", "MediaItem", "Page", "Post"],
    NodeWithContentEditor: [
      "BlockEditorPreview",
      "Job",
      "Page",
      "Post",
      "ReusableBlock",
      "Service",
      "Specialty",
      "Team_member",
      "Work",
    ],
    NodeWithTemplate: [
      "BlockEditorPreview",
      "Job",
      "MediaItem",
      "Page",
      "Post",
      "ReusableBlock",
      "Service",
      "Specialty",
      "Team_member",
      "Work",
    ],
    NodeWithTitle: [
      "BlockEditorPreview",
      "Job",
      "MediaItem",
      "Page",
      "Post",
      "ReusableBlock",
      "Service",
      "Specialty",
      "Team_member",
      "Work",
    ],
    UniformResourceIdentifiable: [
      "BlockEditorPreview",
      "Category",
      "ContentType",
      "Job",
      "MediaItem",
      "Page",
      "Post",
      "PostFormat",
      "ReusableBlock",
      "Service",
      "Specialty",
      "Tag",
      "Team_member",
      "User",
      "Work",
    ],
    HierarchicalTermNode: ["Category"],
    MenuItemLinkable: [
      "Category",
      "Job",
      "Page",
      "Post",
      "Service",
      "Specialty",
      "Tag",
      "Team_member",
      "Work",
    ],
    TermNode: ["Category", "PostFormat", "Tag"],
    Commenter: ["CommentAuthor", "User"],
    ContentRevisionUnion: ["Page", "Post", "ReusableBlock"],
    Block: [
      "CoreArchivesBlock",
      "CoreAudioBlock",
      "CoreAvatarBlock",
      "CoreBlock",
      "CoreButtonBlock",
      "CoreButtonsBlock",
      "CoreCalendarBlock",
      "CoreCategoriesBlock",
      "CoreCodeBlock",
      "CoreColumnBlock",
      "CoreColumnsBlock",
      "CoreCommentAuthorNameBlock",
      "CoreCommentContentBlock",
      "CoreCommentDateBlock",
      "CoreCommentEditLinkBlock",
      "CoreCommentReplyLinkBlock",
      "CoreCommentTemplateBlock",
      "CoreCommentsBlock",
      "CoreCommentsPaginationBlock",
      "CoreCommentsPaginationNextBlock",
      "CoreCommentsPaginationNumbersBlock",
      "CoreCommentsPaginationPreviousBlock",
      "CoreCommentsTitleBlock",
      "CoreCoverBlock",
      "CoreDetailsBlock",
      "CoreEmbedBlock",
      "CoreFileBlock",
      "CoreFootnotesBlock",
      "CoreFreeformBlock",
      "CoreGalleryBlock",
      "CoreGroupBlock",
      "CoreHeadingBlock",
      "CoreHomeLinkBlock",
      "CoreHtmlBlock",
      "CoreImageBlock",
      "CoreLatestCommentsBlock",
      "CoreLatestPostsBlock",
      "CoreLegacyWidgetBlock",
      "CoreListBlock",
      "CoreListItemBlock",
      "CoreLoginoutBlock",
      "CoreMediaTextBlock",
      "CoreMissingBlock",
      "CoreMoreBlock",
      "CoreNavigationBlock",
      "CoreNavigationLinkBlock",
      "CoreNavigationSubmenuBlock",
      "CoreNextpageBlock",
      "CorePageListBlock",
      "CorePageListItemBlock",
      "CoreParagraphBlock",
      "CorePatternBlock",
      "CorePostAuthorBiographyBlock",
      "CorePostAuthorBlock",
      "CorePostAuthorNameBlock",
      "CorePostCommentsFormBlock",
      "CorePostContentBlock",
      "CorePostDateBlock",
      "CorePostExcerptBlock",
      "CorePostFeaturedImageBlock",
      "CorePostNavigationLinkBlock",
      "CorePostTemplateBlock",
      "CorePostTermsBlock",
      "CorePostTitleBlock",
      "CorePreformattedBlock",
      "CorePullquoteBlock",
      "CoreQueryBlock",
      "CoreQueryNoResultsBlock",
      "CoreQueryPaginationBlock",
      "CoreQueryPaginationNextBlock",
      "CoreQueryPaginationNumbersBlock",
      "CoreQueryPaginationPreviousBlock",
      "CoreQueryTitleBlock",
      "CoreQuoteBlock",
      "CoreReadMoreBlock",
      "CoreRssBlock",
      "CoreSearchBlock",
      "CoreSeparatorBlock",
      "CoreShortcodeBlock",
      "CoreSiteLogoBlock",
      "CoreSiteTaglineBlock",
      "CoreSiteTitleBlock",
      "CoreSocialLinkBlock",
      "CoreSocialLinksBlock",
      "CoreSpacerBlock",
      "CoreTableBlock",
      "CoreTagCloudBlock",
      "CoreTemplatePartBlock",
      "CoreTermDescriptionBlock",
      "CoreTextColumnsBlock",
      "CoreVerseBlock",
      "CoreVideoBlock",
      "CoreWidgetGroupBlock",
      "GenesisCustomBlocksHeroBlock",
      "GravityformsFormBlock",
      "IdeaboxTocBlock",
      "YoastFaqBlock",
      "YoastHowToBlock",
      "YoastSeoBreadcrumbsBlock",
    ],
    CoreAudioBlockAttributesUnion: [
      "CoreAudioBlockAttributes",
      "CoreAudioBlockDeprecatedV1Attributes",
    ],
    CoreButtonBlockAttributesUnion: [
      "CoreButtonBlockAttributes",
      "CoreButtonBlockDeprecatedV1Attributes",
      "CoreButtonBlockDeprecatedV2Attributes",
      "CoreButtonBlockDeprecatedV3Attributes",
      "CoreButtonBlockDeprecatedV4Attributes",
      "CoreButtonBlockDeprecatedV5Attributes",
      "CoreButtonBlockDeprecatedV6Attributes",
      "CoreButtonBlockDeprecatedV7Attributes",
      "CoreButtonBlockDeprecatedV8Attributes",
      "CoreButtonBlockDeprecatedV9Attributes",
      "CoreButtonBlockDeprecatedV10Attributes",
      "CoreButtonBlockDeprecatedV11Attributes",
    ],
    CoreButtonsBlockAttributesUnion: [
      "CoreButtonsBlockAttributes",
      "CoreButtonsBlockDeprecatedV1Attributes",
      "CoreButtonsBlockDeprecatedV2Attributes",
    ],
    CoreColumnBlockAttributesUnion: [
      "CoreColumnBlockAttributes",
      "CoreColumnBlockDeprecatedV1Attributes",
    ],
    CoreColumnsBlockAttributesUnion: [
      "CoreColumnsBlockAttributes",
      "CoreColumnsBlockDeprecatedV1Attributes",
      "CoreColumnsBlockDeprecatedV2Attributes",
      "CoreColumnsBlockDeprecatedV3Attributes",
    ],
    CoreCommentAuthorNameBlockAttributesUnion: [
      "CoreCommentAuthorNameBlockAttributes",
      "CoreCommentAuthorNameBlockDeprecatedV1Attributes",
    ],
    CoreCommentDateBlockAttributesUnion: [
      "CoreCommentDateBlockAttributes",
      "CoreCommentDateBlockDeprecatedV1Attributes",
    ],
    CoreCommentsBlockAttributesUnion: [
      "CoreCommentsBlockAttributes",
      "CoreCommentsBlockDeprecatedV1Attributes",
    ],
    CoreCommentsTitleBlockAttributesUnion: [
      "CoreCommentsTitleBlockAttributes",
      "CoreCommentsTitleBlockDeprecatedV1Attributes",
    ],
    CoreCoverBlockAttributesUnion: [
      "CoreCoverBlockAttributes",
      "CoreCoverBlockDeprecatedV1Attributes",
      "CoreCoverBlockDeprecatedV2Attributes",
      "CoreCoverBlockDeprecatedV3Attributes",
      "CoreCoverBlockDeprecatedV4Attributes",
      "CoreCoverBlockDeprecatedV5Attributes",
      "CoreCoverBlockDeprecatedV6Attributes",
      "CoreCoverBlockDeprecatedV7Attributes",
      "CoreCoverBlockDeprecatedV8Attributes",
      "CoreCoverBlockDeprecatedV9Attributes",
      "CoreCoverBlockDeprecatedV10Attributes",
      "CoreCoverBlockDeprecatedV11Attributes",
    ],
    CoreEmbedBlockAttributesUnion: [
      "CoreEmbedBlockAttributes",
      "CoreEmbedBlockDeprecatedV1Attributes",
      "CoreEmbedBlockDeprecatedV2Attributes",
    ],
    CoreFileBlockAttributesUnion: [
      "CoreFileBlockAttributes",
      "CoreFileBlockDeprecatedV1Attributes",
      "CoreFileBlockDeprecatedV2Attributes",
      "CoreFileBlockDeprecatedV3Attributes",
    ],
    CoreGalleryBlockAttributesUnion: [
      "CoreGalleryBlockAttributes",
      "CoreGalleryBlockDeprecatedV1Attributes",
      "CoreGalleryBlockDeprecatedV2Attributes",
      "CoreGalleryBlockDeprecatedV3Attributes",
      "CoreGalleryBlockDeprecatedV4Attributes",
      "CoreGalleryBlockDeprecatedV5Attributes",
      "CoreGalleryBlockDeprecatedV6Attributes",
      "CoreGalleryBlockDeprecatedV7Attributes",
    ],
    CoreGroupBlockAttributesUnion: [
      "CoreGroupBlockAttributes",
      "CoreGroupBlockDeprecatedV1Attributes",
      "CoreGroupBlockDeprecatedV2Attributes",
      "CoreGroupBlockDeprecatedV3Attributes",
      "CoreGroupBlockDeprecatedV4Attributes",
      "CoreGroupBlockDeprecatedV5Attributes",
    ],
    CoreHeadingBlockAttributesUnion: [
      "CoreHeadingBlockAttributes",
      "CoreHeadingBlockDeprecatedV1Attributes",
      "CoreHeadingBlockDeprecatedV2Attributes",
      "CoreHeadingBlockDeprecatedV3Attributes",
      "CoreHeadingBlockDeprecatedV4Attributes",
      "CoreHeadingBlockDeprecatedV5Attributes",
    ],
    CoreImageBlockAttributesUnion: [
      "CoreImageBlockAttributes",
      "CoreImageBlockDeprecatedV1Attributes",
      "CoreImageBlockDeprecatedV2Attributes",
      "CoreImageBlockDeprecatedV3Attributes",
      "CoreImageBlockDeprecatedV4Attributes",
      "CoreImageBlockDeprecatedV5Attributes",
      "CoreImageBlockDeprecatedV6Attributes",
    ],
    CoreLatestPostsBlockAttributesUnion: [
      "CoreLatestPostsBlockAttributes",
      "CoreLatestPostsBlockDeprecatedV1Attributes",
    ],
    CoreListBlockAttributesUnion: [
      "CoreListBlockAttributes",
      "CoreListBlockDeprecatedV1Attributes",
      "CoreListBlockDeprecatedV2Attributes",
    ],
    CoreMediaTextBlockAttributesUnion: [
      "CoreMediaTextBlockAttributes",
      "CoreMediaTextBlockDeprecatedV1Attributes",
      "CoreMediaTextBlockDeprecatedV2Attributes",
      "CoreMediaTextBlockDeprecatedV3Attributes",
      "CoreMediaTextBlockDeprecatedV4Attributes",
      "CoreMediaTextBlockDeprecatedV5Attributes",
      "CoreMediaTextBlockDeprecatedV6Attributes",
    ],
    CoreNavigationBlockAttributesUnion: [
      "CoreNavigationBlockAttributes",
      "CoreNavigationBlockDeprecatedV1Attributes",
      "CoreNavigationBlockDeprecatedV2Attributes",
      "CoreNavigationBlockDeprecatedV3Attributes",
      "CoreNavigationBlockDeprecatedV4Attributes",
      "CoreNavigationBlockDeprecatedV5Attributes",
      "CoreNavigationBlockDeprecatedV6Attributes",
    ],
    CoreNavigationLinkBlockAttributesUnion: [
      "CoreNavigationLinkBlockAttributes",
      "CoreNavigationLinkBlockDeprecatedV1Attributes",
    ],
    CoreParagraphBlockAttributesUnion: [
      "CoreParagraphBlockAttributes",
      "CoreParagraphBlockDeprecatedV1Attributes",
      "CoreParagraphBlockDeprecatedV2Attributes",
      "CoreParagraphBlockDeprecatedV3Attributes",
      "CoreParagraphBlockDeprecatedV4Attributes",
      "CoreParagraphBlockDeprecatedV5Attributes",
      "CoreParagraphBlockDeprecatedV6Attributes",
    ],
    CorePostDateBlockAttributesUnion: [
      "CorePostDateBlockAttributes",
      "CorePostDateBlockDeprecatedV1Attributes",
    ],
    CorePostTitleBlockAttributesUnion: [
      "CorePostTitleBlockAttributes",
      "CorePostTitleBlockDeprecatedV1Attributes",
    ],
    CorePullquoteBlockAttributesUnion: [
      "CorePullquoteBlockAttributes",
      "CorePullquoteBlockDeprecatedV1Attributes",
      "CorePullquoteBlockDeprecatedV2Attributes",
      "CorePullquoteBlockDeprecatedV3Attributes",
      "CorePullquoteBlockDeprecatedV4Attributes",
      "CorePullquoteBlockDeprecatedV5Attributes",
      "CorePullquoteBlockDeprecatedV6Attributes",
    ],
    CoreQueryBlockAttributesUnion: [
      "CoreQueryBlockAttributes",
      "CoreQueryBlockDeprecatedV1Attributes",
      "CoreQueryBlockDeprecatedV2Attributes",
      "CoreQueryBlockDeprecatedV3Attributes",
      "CoreQueryBlockDeprecatedV4Attributes",
      "CoreQueryBlockDeprecatedV5Attributes",
    ],
    CoreQueryPaginationBlockAttributesUnion: [
      "CoreQueryPaginationBlockAttributes",
      "CoreQueryPaginationBlockDeprecatedV1Attributes",
    ],
    CoreQueryTitleBlockAttributesUnion: [
      "CoreQueryTitleBlockAttributes",
      "CoreQueryTitleBlockDeprecatedV1Attributes",
    ],
    CoreQuoteBlockAttributesUnion: [
      "CoreQuoteBlockAttributes",
      "CoreQuoteBlockDeprecatedV1Attributes",
      "CoreQuoteBlockDeprecatedV2Attributes",
      "CoreQuoteBlockDeprecatedV3Attributes",
      "CoreQuoteBlockDeprecatedV4Attributes",
    ],
    CoreSeparatorBlockAttributesUnion: [
      "CoreSeparatorBlockAttributes",
      "CoreSeparatorBlockDeprecatedV1Attributes",
    ],
    CoreSiteTaglineBlockAttributesUnion: [
      "CoreSiteTaglineBlockAttributes",
      "CoreSiteTaglineBlockDeprecatedV1Attributes",
    ],
    CoreSiteTitleBlockAttributesUnion: [
      "CoreSiteTitleBlockAttributes",
      "CoreSiteTitleBlockDeprecatedV1Attributes",
    ],
    CoreSocialLinksBlockAttributesUnion: [
      "CoreSocialLinksBlockAttributes",
      "CoreSocialLinksBlockDeprecatedV1Attributes",
    ],
    CoreSpacerBlockAttributesUnion: [
      "CoreSpacerBlockAttributes",
      "CoreSpacerBlockDeprecatedV1Attributes",
    ],
    CoreTableBlockAttributesUnion: [
      "CoreTableBlockAttributes",
      "CoreTableBlockDeprecatedV1Attributes",
      "CoreTableBlockDeprecatedV2Attributes",
      "CoreTableBlockDeprecatedV3Attributes",
    ],
    CoreVerseBlockAttributesUnion: [
      "CoreVerseBlockAttributes",
      "CoreVerseBlockDeprecatedV1Attributes",
      "CoreVerseBlockDeprecatedV2Attributes",
    ],
    CoreVideoBlockAttributesUnion: [
      "CoreVideoBlockAttributes",
      "CoreVideoBlockDeprecatedV1Attributes",
    ],
    CoreWidgetGroupBlockAttributesUnion: [
      "CoreWidgetGroupBlockAttributes",
      "CoreWidgetGroupBlockDeprecatedV1Attributes",
    ],
    ContentTemplate: [
      "DefaultTemplate",
      "Template_About",
      "Template_Blog",
      "Template_Contact",
      "Template_Home",
      "Template_Work",
    ],
    EnqueuedAsset: ["EnqueuedScript", "EnqueuedStylesheet"],
    AcfFieldGroup: [
      "GeneralOptionsSettings_Generalsettings",
      "Job_Jobacf",
      "Page_Aboutcta",
      "Page_Aboutcta_bullets",
      "Page_Contenthero",
      "Page_Features",
      "Page_Features_features",
      "Page_Features_features_bullettPoints",
      "Post_Postsacf",
      "Team_member_Team",
      "Work_Workacf",
    ],
    GfEntry: ["GfDraftEntry", "GfSubmittedEntry"],
    NodeWithForm: ["GfDraftEntry", "GfSubmittedEntry"],
    BlockEditorContentNode: [
      "Job",
      "Page",
      "Post",
      "ReusableBlock",
      "Service",
      "Specialty",
      "Team_member",
      "Work",
    ],
    HierarchicalContentNode: ["MediaItem", "Page"],
    NodeWithComments: ["MediaItem", "Page", "Post"],
    MenuItemObjectUnion: [
      "Category",
      "Job",
      "Page",
      "Post",
      "Service",
      "Specialty",
      "Tag",
      "Team_member",
      "Work",
    ],
    NodeWithFeaturedImage: [
      "Page",
      "Post",
      "Service",
      "Specialty",
      "Team_member",
      "Work",
    ],
    NodeWithPageAttributes: ["Page"],
    NodeWithRevisions: ["Page", "Post", "ReusableBlock"],
    NodeWithExcerpt: ["Post"],
    NodeWithTrackbacks: ["Post"],
    PostCategoryField: [
      "PostCategoryCheckboxField",
      "PostCategoryMultiSelectField",
      "PostCategoryRadioField",
      "PostCategorySelectField",
    ],
    PostCustomField: [
      "PostCustomCheckboxField",
      "PostCustomDateField",
      "PostCustomEmailField",
      "PostCustomFileuploadField",
      "PostCustomHiddenField",
      "PostCustomListField",
      "PostCustomMultiSelectField",
      "PostCustomNumberField",
      "PostCustomPhoneField",
      "PostCustomRadioField",
      "PostCustomSelectField",
      "PostCustomTextAreaField",
      "PostCustomTextField",
      "PostCustomTimeField",
      "PostCustomWebsiteField",
    ],
    PostObjectUnion: [
      "BlockEditorPreview",
      "Job",
      "MediaItem",
      "Page",
      "Post",
      "ReusableBlock",
      "Service",
      "Specialty",
      "Team_member",
      "Work",
    ],
    PostTagsField: [
      "PostTagsCheckboxField",
      "PostTagsMultiSelectField",
      "PostTagsRadioField",
      "PostTagsSelectField",
      "PostTagsTextField",
    ],
    YoastFaqBlockAttributesUnion: [
      "YoastFaqBlockAttributes",
      "YoastFaqBlockDeprecatedV1Attributes",
    ],
    YoastHowToBlockAttributesUnion: [
      "YoastHowToBlockAttributes",
      "YoastHowToBlockDeprecatedV1Attributes",
      "YoastHowToBlockDeprecatedV2Attributes",
    ],
  },
} as const;

/**
 * A Field Group registered by ACF
 */
export interface AcfFieldGroup {
  __typename?:
    | "GeneralOptionsSettings_Generalsettings"
    | "Job_Jobacf"
    | "Page_Aboutcta"
    | "Page_Aboutcta_bullets"
    | "Page_Contenthero"
    | "Page_Features"
    | "Page_Features_features"
    | "Page_Features_features_bullettPoints"
    | "Post_Postsacf"
    | "Team_member_Team"
    | "Work_Workacf";
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums["String"]>;
  $on: $AcfFieldGroup;
}

/**
 * A Gravity Forms   field.
 */
export interface AddressField {
  __typename?: "AddressField";
  /**
   * Determines the type of address to be displayed.
   */
  addressType?: Maybe<ScalarsEnums["AddressFieldTypeEnum"]>;
  /**
   * Address field value.
   */
  addressValues?: Maybe<AddressFieldValue>;
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * The field id of the field being used as the copy source.
   */
  copyValuesOptionFieldId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The label that appears next to the copy values option when the form is displayed. The default value is \“Same as previous\”.
   */
  copyValuesOptionLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the country that will be selected by default. Only applicable when &quot;addressType&quot; is set to &quot;INTERATIONAL&quot;.
   */
  defaultCountry?: Maybe<ScalarsEnums["AddressFieldCountryEnum"]>;
  /**
   * Contains the province that will be selected by default. Only applicable when &quot;addressType&quot; is set to &quot;CANADA&quot;.
   */
  defaultProvince?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the state that will be selected by default. Only applicable when &quot;addressType&quot; is set to &quot;US&quot;.
   */
  defaultState?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether autocomplete should be enabled for this field.
   */
  hasAutocomplete?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * An array of the available properties for each input of the address field.
   */
  inputs?: Maybe<Array<Maybe<AddressInputProperty>>>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Indicates whether the copy values option can be used. This option allows users to skip filling out the field and use the same values as another. For example, if the mailing and billing address are the same.
   */
  shouldCopyValuesOption?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs.
   */
  subLabelPlacement?: Maybe<ScalarsEnums["FormFieldSubLabelPlacementEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * The individual properties for each element of the address value field.
 */
export interface AddressFieldValue {
  __typename?: "AddressFieldValue";
  /**
   * Address city.
   */
  city?: Maybe<ScalarsEnums["String"]>;
  /**
   * Address country.
   */
  country?: Maybe<ScalarsEnums["AddressFieldCountryEnum"]>;
  /**
   * Address line two.
   */
  lineTwo?: Maybe<ScalarsEnums["String"]>;
  /**
   * Address state/region/province name.
   */
  state?: Maybe<ScalarsEnums["String"]>;
  /**
   * Street address.
   */
  street?: Maybe<ScalarsEnums["String"]>;
  /**
   * Address zip code.
   */
  zip?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Address input values.
 */
export interface AddressInputProperty {
  __typename?: "AddressInputProperty";
  /**
   * The autocomplete attribute for the field.
   */
  autocompleteAttribute?: Maybe<ScalarsEnums["String"]>;
  /**
   * The custom label for the input. When set, this is used in place of the label.
   */
  customLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field.
   */
  id?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Whether or not this field should be hidden.
   */
  isHidden?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Key used to identify this input.
   */
  key?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Avatars are profile images for users. WordPress by default uses the Gravatar service to host and fetch avatars from.
 */
export interface Avatar {
  __typename?: "Avatar";
  /**
   * URL for the default image or a default type. Accepts &#039;404&#039; (return a 404 instead of a default image), &#039;retro&#039; (8bit), &#039;monsterid&#039; (monster), &#039;wavatar&#039; (cartoon face), &#039;indenticon&#039; (the &#039;quilt&#039;), &#039;mystery&#039;, &#039;mm&#039;, or &#039;mysteryman&#039; (The Oyster Man), &#039;blank&#039; (transparent GIF), or &#039;gravatar_default&#039; (the Gravatar logo).
   */
  default?: Maybe<ScalarsEnums["String"]>;
  /**
   * HTML attributes to insert in the IMG element. Is not sanitized.
   */
  extraAttr?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether to always show the default image, never the Gravatar.
   */
  forceDefault?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the avatar was successfully found.
   */
  foundAvatar?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Height of the avatar image.
   */
  height?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * What rating to display avatars up to. Accepts &#039;G&#039;, &#039;PG&#039;, &#039;R&#039;, &#039;X&#039;, and are judged in that order.
   */
  rating?: Maybe<ScalarsEnums["String"]>;
  /**
   * Type of url scheme to use. Typically HTTP vs. HTTPS.
   */
  scheme?: Maybe<ScalarsEnums["String"]>;
  /**
   * The size of the avatar in pixels. A value of 96 will match a 96px x 96px gravatar image.
   */
  size?: Maybe<ScalarsEnums["Int"]>;
  /**
   * URL for the gravatar image source.
   */
  url?: Maybe<ScalarsEnums["String"]>;
  /**
   * Width of the avatar image.
   */
  width?: Maybe<ScalarsEnums["Int"]>;
}

/**
 * Gutenberg block interface
 */
export interface Block {
  __typename?:
    | "CoreArchivesBlock"
    | "CoreAudioBlock"
    | "CoreAvatarBlock"
    | "CoreBlock"
    | "CoreButtonBlock"
    | "CoreButtonsBlock"
    | "CoreCalendarBlock"
    | "CoreCategoriesBlock"
    | "CoreCodeBlock"
    | "CoreColumnBlock"
    | "CoreColumnsBlock"
    | "CoreCommentAuthorNameBlock"
    | "CoreCommentContentBlock"
    | "CoreCommentDateBlock"
    | "CoreCommentEditLinkBlock"
    | "CoreCommentReplyLinkBlock"
    | "CoreCommentTemplateBlock"
    | "CoreCommentsBlock"
    | "CoreCommentsPaginationBlock"
    | "CoreCommentsPaginationNextBlock"
    | "CoreCommentsPaginationNumbersBlock"
    | "CoreCommentsPaginationPreviousBlock"
    | "CoreCommentsTitleBlock"
    | "CoreCoverBlock"
    | "CoreDetailsBlock"
    | "CoreEmbedBlock"
    | "CoreFileBlock"
    | "CoreFootnotesBlock"
    | "CoreFreeformBlock"
    | "CoreGalleryBlock"
    | "CoreGroupBlock"
    | "CoreHeadingBlock"
    | "CoreHomeLinkBlock"
    | "CoreHtmlBlock"
    | "CoreImageBlock"
    | "CoreLatestCommentsBlock"
    | "CoreLatestPostsBlock"
    | "CoreLegacyWidgetBlock"
    | "CoreListBlock"
    | "CoreListItemBlock"
    | "CoreLoginoutBlock"
    | "CoreMediaTextBlock"
    | "CoreMissingBlock"
    | "CoreMoreBlock"
    | "CoreNavigationBlock"
    | "CoreNavigationLinkBlock"
    | "CoreNavigationSubmenuBlock"
    | "CoreNextpageBlock"
    | "CorePageListBlock"
    | "CorePageListItemBlock"
    | "CoreParagraphBlock"
    | "CorePatternBlock"
    | "CorePostAuthorBiographyBlock"
    | "CorePostAuthorBlock"
    | "CorePostAuthorNameBlock"
    | "CorePostCommentsFormBlock"
    | "CorePostContentBlock"
    | "CorePostDateBlock"
    | "CorePostExcerptBlock"
    | "CorePostFeaturedImageBlock"
    | "CorePostNavigationLinkBlock"
    | "CorePostTemplateBlock"
    | "CorePostTermsBlock"
    | "CorePostTitleBlock"
    | "CorePreformattedBlock"
    | "CorePullquoteBlock"
    | "CoreQueryBlock"
    | "CoreQueryNoResultsBlock"
    | "CoreQueryPaginationBlock"
    | "CoreQueryPaginationNextBlock"
    | "CoreQueryPaginationNumbersBlock"
    | "CoreQueryPaginationPreviousBlock"
    | "CoreQueryTitleBlock"
    | "CoreQuoteBlock"
    | "CoreReadMoreBlock"
    | "CoreRssBlock"
    | "CoreSearchBlock"
    | "CoreSeparatorBlock"
    | "CoreShortcodeBlock"
    | "CoreSiteLogoBlock"
    | "CoreSiteTaglineBlock"
    | "CoreSiteTitleBlock"
    | "CoreSocialLinkBlock"
    | "CoreSocialLinksBlock"
    | "CoreSpacerBlock"
    | "CoreTableBlock"
    | "CoreTagCloudBlock"
    | "CoreTemplatePartBlock"
    | "CoreTermDescriptionBlock"
    | "CoreTextColumnsBlock"
    | "CoreVerseBlock"
    | "CoreVideoBlock"
    | "CoreWidgetGroupBlock"
    | "GenesisCustomBlocksHeroBlock"
    | "GravityformsFormBlock"
    | "IdeaboxTocBlock"
    | "YoastFaqBlock"
    | "YoastHowToBlock"
    | "YoastSeoBreadcrumbsBlock";
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
  $on: $Block;
}

/**
 * Gutenberg post interface
 */
export interface BlockEditorContentNode {
  __typename?:
    | "Job"
    | "Page"
    | "Post"
    | "ReusableBlock"
    | "Service"
    | "Specialty"
    | "Team_member"
    | "Work";
  /**
   * Gutenberg blocks
   */
  blocks?: Maybe<Array<Block>>;
  /**
   * Gutenberg blocks as json string
   */
  blocksJSON?: Maybe<ScalarsEnums["String"]>;
  id: ScalarsEnums["ID"];
  /**
   * Previewed gutenberg blocks
   */
  previewBlocks?: Maybe<Array<Block>>;
  /**
   * Previewed Gutenberg blocks as json string
   */
  previewBlocksJSON?: Maybe<ScalarsEnums["String"]>;
  $on: $BlockEditorContentNode;
}

/**
 * Connection between the RootQuery type and the BlockEditorContentNode type
 */
export interface BlockEditorContentNodeConnection {
  __typename?: "BlockEditorContentNodeConnection";
  /**
   * Edges for the BlockEditorContentNodeConnection connection
   */
  edges?: Maybe<Array<Maybe<BlockEditorContentNodeConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<BlockEditorContentNode>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface BlockEditorContentNodeConnectionEdge {
  __typename?: "BlockEditorContentNodeConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<BlockEditorContentNode>;
}

/**
 * The BlockEditorPreview type
 */
export interface BlockEditorPreview {
  __typename?: "BlockEditorPreview";
  /**
   * Connection between the NodeWithAuthor type and the User type
   */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /**
   * The database identifier of the author of the node
   */
  authorDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The globally unique identifier of the author of the node
   */
  authorId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  blockEditorPreviewId: ScalarsEnums["Int"];
  blocks?: Maybe<Array<Block>>;
  blocksJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums["String"];
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums["String"]>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums["String"]>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier of the wgg_preview object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  lastUpdateTime?: Maybe<ScalarsEnums["String"]>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums["String"]>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums["String"]>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the BlockEditorPreview type and the BlockEditorPreview type
   */
  preview?: Maybe<BlockEditorPreviewToPreviewConnectionEdge>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums["ID"]>;
  previewed?: Maybe<BlockEditorContentNode>;
  previewedDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  previewedParentDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums["String"]>;
  /**
   * The template assigned to the node
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Connection between the BlockEditorPreview type and the BlockEditorPreview type
 */
export interface BlockEditorPreviewToPreviewConnectionEdge {
  __typename?: "BlockEditorPreviewToPreviewConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<BlockEditorPreview>;
}

/**
 * A Gravity Forms   field.
 */
export interface CaptchaField {
  __typename?: "CaptchaField";
  /**
   * The language used when the captcha is displayed. This property is available when the captchaType is “captcha”, the default. The possible values are the language codes used by WordPress.
   */
  captchaBadgePosition?: Maybe<ScalarsEnums["CaptchaFieldBadgePositionEnum"]>;
  /**
   * The language used when the captcha is displayed. This property is available when the captchaType is “captcha”, the default. The possible values are the language codes used by WordPress.
   */
  captchaLanguage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines the theme to be used for the reCAPTCHA field. Only applicable to the recaptcha captcha type.
   */
  captchaTheme?: Maybe<ScalarsEnums["CaptchaFieldThemeEnum"]>;
  /**
   * Determines the type of CAPTCHA field to be used.
   */
  captchaType?: Maybe<ScalarsEnums["CaptchaFieldTypeEnum"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Determines the image’s background color, in HEX format (i.e. #CCCCCC). Only applicable to simple_captcha and math captcha types.
   */
  simpleCaptchaBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines the image’s font color, in HEX format (i.e. #CCCCCC). Only applicable to simple_captcha and math captcha types.
   */
  simpleCaptchaFontColor?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines the CAPTCHA image size. Only applicable to simple_captcha and math captcha types.
   */
  simpleCaptchaSize?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * The category type
 */
export interface Category {
  __typename?: "Category";
  /**
   * The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root).
   */
  ancestors: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<CategoryToAncestorsCategoryConnection>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of databaseId
   */
  categoryId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Connection between the category type and the category type
   */
  children: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<CategoryToCategoryConnectionWhereArgs>;
  }) => Maybe<CategoryToCategoryConnection>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Connection between the category type and the ContentNode type
   */
  contentNodes: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<CategoryToContentNodeConnectionWhereArgs>;
  }) => Maybe<CategoryToContentNodeConnection>;
  /**
   * The number of objects connected to the object
   */
  count?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The unique resource identifier path
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * The description of the object
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the TermNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<TermNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the TermNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /**
   * The unique resource identifier path
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * The link to the term
   */
  link?: Maybe<ScalarsEnums["String"]>;
  /**
   * The human friendly name of the object.
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the category type and the category type
   */
  parent?: Maybe<CategoryToParentCategoryConnectionEdge>;
  /**
   * Database id of the parent node
   */
  parentDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The globally unique identifier of the parent node.
   */
  parentId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * Connection between the category type and the post type
   */
  posts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<CategoryToPostConnectionWhereArgs>;
  }) => Maybe<CategoryToPostConnection>;
  /**
   * The Yoast SEO data of the Categories taxonomy.
   */
  seo?: Maybe<TaxonomySEO>;
  /**
   * An alphanumeric identifier for the object unique to its type.
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the category type and the Taxonomy type
   */
  taxonomy?: Maybe<CategoryToTaxonomyConnectionEdge>;
  /**
   * The name of the taxonomy that the object is associated with
   */
  taxonomyName?: Maybe<ScalarsEnums["String"]>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The ID of the term group that this term object belongs to
   */
  termGroupId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The taxonomy ID that the object is associated with
   */
  termTaxonomyId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Connection between the category type and the category type
 */
export interface CategoryToAncestorsCategoryConnection {
  __typename?: "CategoryToAncestorsCategoryConnection";
  /**
   * Edges for the CategoryToAncestorsCategoryConnection connection
   */
  edges?: Maybe<Array<Maybe<CategoryToAncestorsCategoryConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Category>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface CategoryToAncestorsCategoryConnectionEdge {
  __typename?: "CategoryToAncestorsCategoryConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Category>;
}

/**
 * Connection between the category type and the category type
 */
export interface CategoryToCategoryConnection {
  __typename?: "CategoryToCategoryConnection";
  /**
   * Edges for the CategoryToCategoryConnection connection
   */
  edges?: Maybe<Array<Maybe<CategoryToCategoryConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Category>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface CategoryToCategoryConnectionEdge {
  __typename?: "CategoryToCategoryConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Category>;
}

/**
 * Connection between the category type and the ContentNode type
 */
export interface CategoryToContentNodeConnection {
  __typename?: "CategoryToContentNodeConnection";
  /**
   * Edges for the CategoryToContentNodeConnection connection
   */
  edges?: Maybe<Array<Maybe<CategoryToContentNodeConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface CategoryToContentNodeConnectionEdge {
  __typename?: "CategoryToContentNodeConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<ContentNode>;
}

/**
 * Connection between the category type and the category type
 */
export interface CategoryToParentCategoryConnectionEdge {
  __typename?: "CategoryToParentCategoryConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<Category>;
}

/**
 * Connection between the category type and the post type
 */
export interface CategoryToPostConnection {
  __typename?: "CategoryToPostConnection";
  /**
   * Edges for the CategoryToPostConnection connection
   */
  edges?: Maybe<Array<Maybe<CategoryToPostConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Post>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface CategoryToPostConnectionEdge {
  __typename?: "CategoryToPostConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Post>;
}

/**
 * Connection between the category type and the Taxonomy type
 */
export interface CategoryToTaxonomyConnectionEdge {
  __typename?: "CategoryToTaxonomyConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<Taxonomy>;
}

/**
 * A Gravity Forms   field.
 */
export interface CheckboxField {
  __typename?: "CheckboxField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Checkbox field value.
   */
  checkboxValues?: Maybe<Array<Maybe<CheckboxFieldValue>>>;
  /**
   * The available choices for the checkbox field.
   */
  choices?: Maybe<Array<Maybe<CheckboxFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the \&quot;select all\&quot; choice should be displayed.
   */
  hasSelectAll?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * An array of the available properties for each input of the checkbox field.
   */
  inputs?: Maybe<Array<Maybe<CheckboxInputProperty>>>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Checkbox choice values.
 */
export interface CheckboxFieldChoice {
  __typename?: "CheckboxFieldChoice";
  /**
   * Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected.
   */
  isSelected?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The text to be displayed to the user when displaying this choice.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The individual properties for each element of the Checkbox value field.
 */
export interface CheckboxFieldValue {
  __typename?: "CheckboxFieldValue";
  /**
   * Input ID.
   */
  inputId?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Input text.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * Input value.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Checkbox input values.
 */
export interface CheckboxInputProperty {
  __typename?: "CheckboxInputProperty";
  /**
   * The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field.
   */
  id?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  name?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Comment object
 */
export interface Comment {
  __typename?: "Comment";
  /**
   * User agent used to post the comment. This field is equivalent to WP_Comment-&gt;comment_agent and the value matching the &quot;comment_agent&quot; column in SQL.
   */
  agent?: Maybe<ScalarsEnums["String"]>;
  /**
   * The approval status of the comment. This field is equivalent to WP_Comment-&gt;comment_approved and the value matching the &quot;comment_approved&quot; column in SQL.
   */
  approved?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The author of the comment
   */
  author?: Maybe<CommentToCommenterConnectionEdge>;
  /**
   * IP address for the author. This field is equivalent to WP_Comment-&gt;comment_author_IP and the value matching the &quot;comment_author_IP&quot; column in SQL.
   */
  authorIp?: Maybe<ScalarsEnums["String"]>;
  /**
   * ID for the comment, unique among comments.
   * @deprecated Deprecated in favor of databaseId
   */
  commentId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Connection between the Comment type and the ContentNode type
   */
  commentedOn?: Maybe<CommentToContentNodeConnectionEdge>;
  /**
   * Content of the comment. This field is equivalent to WP_Comment-&gt;comment_content and the value matching the &quot;comment_content&quot; column in SQL.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * Date the comment was posted in local time. This field is equivalent to WP_Comment-&gt;date and the value matching the &quot;date&quot; column in SQL.
   */
  date?: Maybe<ScalarsEnums["String"]>;
  /**
   * Date the comment was posted in GMT. This field is equivalent to WP_Comment-&gt;date_gmt and the value matching the &quot;date_gmt&quot; column in SQL.
   */
  dateGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier for the comment object
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Karma value for the comment. This field is equivalent to WP_Comment-&gt;comment_karma and the value matching the &quot;comment_karma&quot; column in SQL.
   */
  karma?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Connection between the Comment type and the Comment type
   */
  parent: (args?: {
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<CommentToParentCommentConnectionWhereArgs>;
  }) => Maybe<CommentToParentCommentConnectionEdge>;
  /**
   * The database id of the parent comment node or null if it is the root comment
   */
  parentDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The globally unique identifier of the parent comment node.
   */
  parentId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * Connection between the Comment type and the Comment type
   */
  replies: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<CommentToCommentConnectionWhereArgs>;
  }) => Maybe<CommentToCommentConnection>;
  /**
   * Type of comment. This field is equivalent to WP_Comment-&gt;comment_type and the value matching the &quot;comment_type&quot; column in SQL.
   */
  type?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Comment Author object
 */
export interface CommentAuthor {
  __typename?: "CommentAuthor";
  /**
   * Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument.
   */
  avatar: (args?: {
    /**
     * Whether to always show the default image, never the Gravatar. Default false
     */
    forceDefault?: Maybe<Scalars["Boolean"]>;
    /**
     * The rating level of the avatar.
     */
    rating?: Maybe<AvatarRatingEnum>;
    /**
     * The size attribute of the avatar field can be used to fetch avatars of different sizes. The value corresponds to the dimension in pixels to fetch. The default is 96 pixels.
     * @defaultValue `96`
     */
    size?: Maybe<Scalars["Int"]>;
  }) => Maybe<Avatar>;
  /**
   * Identifies the primary key from the database.
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * The email for the comment author
   */
  email?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier for the comment author object
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The name for the comment author.
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * The url the comment author.
   */
  url?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Connection between the Comment type and the Comment type
 */
export interface CommentToCommentConnection {
  __typename?: "CommentToCommentConnection";
  /**
   * Edges for the CommentToCommentConnection connection
   */
  edges?: Maybe<Array<Maybe<CommentToCommentConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Comment>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface CommentToCommentConnectionEdge {
  __typename?: "CommentToCommentConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Comment>;
}

/**
 * Connection between the Comment type and the Commenter type
 */
export interface CommentToCommenterConnectionEdge {
  __typename?: "CommentToCommenterConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<Commenter>;
}

/**
 * Connection between the Comment type and the ContentNode type
 */
export interface CommentToContentNodeConnectionEdge {
  __typename?: "CommentToContentNodeConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<ContentNode>;
}

/**
 * Connection between the Comment type and the Comment type
 */
export interface CommentToParentCommentConnectionEdge {
  __typename?: "CommentToParentCommentConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<Comment>;
}

/**
 * The author of a comment
 */
export interface Commenter {
  __typename?: "CommentAuthor" | "User";
  /**
   * Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument.
   */
  avatar?: Maybe<Avatar>;
  /**
   * Identifies the primary key from the database.
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * The email address of the author of a comment.
   */
  email?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier for the comment author.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the author information is considered restricted. (not fully public)
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The name of the author of a comment.
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * The url of the author of a comment.
   */
  url?: Maybe<ScalarsEnums["String"]>;
  $on: $Commenter;
}

/**
 * Gravity Forms conditional logic.
 */
export interface ConditionalLogic {
  __typename?: "ConditionalLogic";
  /**
   * The type of action the conditional logic will perform.
   */
  actionType?: Maybe<ScalarsEnums["ConditionalLogicActionTypeEnum"]>;
  /**
   * Determines how to the rules should be evaluated.
   */
  logicType?: Maybe<ScalarsEnums["ConditionalLogicLogicTypeEnum"]>;
  /**
   * Conditional logic rules.
   */
  rules?: Maybe<Array<Maybe<ConditionalLogicRule>>>;
}

/**
 * Gravity Forms conditional logic rule.
 */
export interface ConditionalLogicRule {
  __typename?: "ConditionalLogicRule";
  /**
   * Target field Id. Field that will have it’s value compared with the value property to determine if this rule is a match.
   */
  fieldId?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Operator to be used when evaluating this rule.
   */
  operator?: Maybe<ScalarsEnums["FormRuleOperatorEnum"]>;
  /**
   * The value to compare with field specified by fieldId.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * GraphQL representation of WordPress Conditional Tags.
 */
export interface ConditionalTags {
  __typename?: "ConditionalTags";
  /**
   * Determines whether the query is for an existing archive page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isArchive?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for an existing attachment page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isAttachment?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for an existing author archive page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isAuthor?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for an existing category archive page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isCategory?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for an existing date archive.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isDate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for an existing day archive.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isDay?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for the front page of the site.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isFrontPage?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for the blog homepage.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isHome?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for an existing month archive.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isMonth?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether this site has more than one author.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isMultiAuthor?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for an existing single page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isPage?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether currently in a page template.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isPageTemplate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for an existing post type archive page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isPostTypeArchive?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for a post or page preview.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isPreview?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for the Privacy Policy page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isPrivacyPolicy?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for a search.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isSearch?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for an existing single post.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isSingle?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for an existing single post of any post type (post, attachment, page, custom post types).
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isSingular?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether a post is sticky.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isSticky?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for an existing tag archive page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isTag?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for an existing custom taxonomy archive page.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isTax?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the query is for an existing year archive.
   * @deprecated Deprecated in favor of using Next.js pages
   */
  isYear?: Maybe<ScalarsEnums["Boolean"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface ConsentField {
  __typename?: "ConsentField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Text of the consent checkbox.
   */
  checkboxLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * Consent field value. This is `true` when consent is given, `false` when it is not.
   */
  consentValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Nodes used to manage content
 */
export interface ContentNode {
  __typename?:
    | "BlockEditorPreview"
    | "Job"
    | "MediaItem"
    | "Page"
    | "Post"
    | "ReusableBlock"
    | "Service"
    | "Specialty"
    | "Team_member"
    | "Work";
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums["String"];
  /**
   * The ID of the node in the database.
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums["String"]>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums["String"]>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums["String"]>;
  /**
   * The unique resource identifier path
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums["String"]>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums["String"]>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums["String"]>;
  /**
   * The template assigned to a node of content
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
  $on: $ContentNode;
}

/**
 * Connection between the ContentNode type and the ContentType type
 */
export interface ContentNodeToContentTypeConnectionEdge {
  __typename?: "ContentNodeToContentTypeConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<ContentType>;
}

/**
 * Connection between the ContentNode type and the User type
 */
export interface ContentNodeToEditLastConnectionEdge {
  __typename?: "ContentNodeToEditLastConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<User>;
}

/**
 * Connection between the ContentNode type and the User type
 */
export interface ContentNodeToEditLockConnectionEdge {
  __typename?: "ContentNodeToEditLockConnectionEdge";
  /**
   * The timestamp for when the node was last edited
   */
  lockTimestamp?: Maybe<ScalarsEnums["String"]>;
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<User>;
}

/**
 * Connection between the ContentNode type and the EnqueuedScript type
 */
export interface ContentNodeToEnqueuedScriptConnection {
  __typename?: "ContentNodeToEnqueuedScriptConnection";
  /**
   * Edges for the ContentNodeToEnqueuedScriptConnection connection
   */
  edges?: Maybe<Array<Maybe<ContentNodeToEnqueuedScriptConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<EnqueuedScript>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface ContentNodeToEnqueuedScriptConnectionEdge {
  __typename?: "ContentNodeToEnqueuedScriptConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<EnqueuedScript>;
}

/**
 * Connection between the ContentNode type and the EnqueuedStylesheet type
 */
export interface ContentNodeToEnqueuedStylesheetConnection {
  __typename?: "ContentNodeToEnqueuedStylesheetConnection";
  /**
   * Edges for the ContentNodeToEnqueuedStylesheetConnection connection
   */
  edges?: Maybe<Array<Maybe<ContentNodeToEnqueuedStylesheetConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<EnqueuedStylesheet>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface ContentNodeToEnqueuedStylesheetConnectionEdge {
  __typename?: "ContentNodeToEnqueuedStylesheetConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<EnqueuedStylesheet>;
}

/**
 * A union of Content Node Types that support revisions
 */
export interface ContentRevisionUnion {
  __typename?: "Page" | "Post" | "ReusableBlock";
  $on: $ContentRevisionUnion;
}

/**
 * The template assigned to a node of content
 */
export interface ContentTemplate {
  __typename?:
    | "DefaultTemplate"
    | "Template_About"
    | "Template_Blog"
    | "Template_Contact"
    | "Template_Home"
    | "Template_Work";
  /**
   * The name of the template
   */
  templateName?: Maybe<ScalarsEnums["String"]>;
  $on: $ContentTemplate;
}

/**
 * An Post Type object
 */
export interface ContentType {
  __typename?: "ContentType";
  /**
   * Whether this content type should can be exported.
   */
  canExport?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Connection between the ContentType type and the Taxonomy type
   */
  connectedTaxonomies: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentTypeToTaxonomyConnection>;
  /**
   * Connection between the ContentType type and the ContentNode type
   */
  contentNodes: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<ContentTypeToContentNodeConnectionWhereArgs>;
  }) => Maybe<ContentTypeToContentNodeConnection>;
  /**
   * Whether content of this type should be deleted when the author of it is deleted from the system.
   */
  deleteWithUser?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Description of the content type.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether to exclude nodes of this content type from front end search results.
   */
  excludeFromSearch?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The plural name of the content type within the GraphQL Schema.
   */
  graphqlPluralName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The singular name of the content type within the GraphQL Schema.
   */
  graphqlSingleName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether this content type should have archives. Content archives are generated by type and by date.
   */
  hasArchive?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the content type is hierarchical, for example pages.
   */
  hierarchical?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The globally unique identifier of the post-type object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether this page is set to the static front page.
   */
  isFrontPage: ScalarsEnums["Boolean"];
  /**
   * Whether this page is set to the blog posts page.
   */
  isPostsPage: ScalarsEnums["Boolean"];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * Display name of the content type.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * Details about the content type labels.
   */
  labels?: Maybe<PostTypeLabelDetails>;
  /**
   * The name of the icon file to display as a menu icon.
   */
  menuIcon?: Maybe<ScalarsEnums["String"]>;
  /**
   * The position of this post type in the menu. Only applies if show_in_menu is true.
   */
  menuPosition?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The internal name of the post type. This should not be used for display purposes.
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether a content type is intended for use publicly either via the admin interface or by front-end users. While the default settings of exclude_from_search, publicly_queryable, show_ui, and show_in_nav_menus are inherited from public, each does not rely on this relationship and controls a very specific intention.
   */
  public?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether queries can be performed on the front end for the content type as part of parse_request().
   */
  publiclyQueryable?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Name of content type to display in REST API &quot;wp/v2&quot; namespace.
   */
  restBase?: Maybe<ScalarsEnums["String"]>;
  /**
   * The REST Controller class assigned to handling this content type.
   */
  restControllerClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Makes this content type available via the admin bar.
   */
  showInAdminBar?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether to add the content type to the GraphQL Schema.
   */
  showInGraphql?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Where to show the content type in the admin menu. To work, $show_ui must be true. If true, the post type is shown in its own top level menu. If false, no menu is shown. If a string of an existing top level menu (eg. &quot;tools.php&quot; or &quot;edit.php?post_type=page&quot;), the post type will be placed as a sub-menu of that.
   */
  showInMenu?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Makes this content type available for selection in navigation menus.
   */
  showInNavMenus?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the content type is associated with a route under the the REST API &quot;wp/v2&quot; namespace.
   */
  showInRest?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether to generate and allow a UI for managing this content type in the admin.
   */
  showUi?: Maybe<ScalarsEnums["Boolean"]>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Connection between the ContentType type and the ContentNode type
 */
export interface ContentTypeToContentNodeConnection {
  __typename?: "ContentTypeToContentNodeConnection";
  /**
   * Edges for the ContentTypeToContentNodeConnection connection
   */
  edges?: Maybe<Array<Maybe<ContentTypeToContentNodeConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface ContentTypeToContentNodeConnectionEdge {
  __typename?: "ContentTypeToContentNodeConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<ContentNode>;
}

/**
 * Connection between the ContentType type and the Taxonomy type
 */
export interface ContentTypeToTaxonomyConnection {
  __typename?: "ContentTypeToTaxonomyConnection";
  /**
   * Edges for the ContentTypeToTaxonomyConnection connection
   */
  edges?: Maybe<Array<Maybe<ContentTypeToTaxonomyConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Taxonomy>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface ContentTypeToTaxonomyConnectionEdge {
  __typename?: "ContentTypeToTaxonomyConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Taxonomy>;
}

/**
 * core/archives block
 */
export interface CoreArchivesBlock {
  __typename?: "CoreArchivesBlock";
  attributes?: Maybe<CoreArchivesBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreArchivesBlockAttributes {
  __typename?: "CoreArchivesBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  displayAsDropdown: ScalarsEnums["Boolean"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  showLabel: ScalarsEnums["Boolean"];
  showPostCounts: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  type: ScalarsEnums["String"];
}

/**
 * core/audio block
 */
export interface CoreAudioBlock {
  __typename?: "CoreAudioBlock";
  attributes?: Maybe<CoreAudioBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreAudioBlockAttributes {
  __typename?: "CoreAudioBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  autoplay?: Maybe<ScalarsEnums["Boolean"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  loop?: Maybe<ScalarsEnums["Boolean"]>;
  preload?: Maybe<ScalarsEnums["String"]>;
  src?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

export interface CoreAudioBlockAttributesUnion {
  __typename?:
    | "CoreAudioBlockAttributes"
    | "CoreAudioBlockDeprecatedV1Attributes";
  $on: $CoreAudioBlockAttributesUnion;
}

export interface CoreAudioBlockDeprecatedV1Attributes {
  __typename?: "CoreAudioBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  autoplay?: Maybe<ScalarsEnums["Boolean"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  loop?: Maybe<ScalarsEnums["Boolean"]>;
  preload?: Maybe<ScalarsEnums["String"]>;
  src?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

/**
 * core/avatar block
 */
export interface CoreAvatarBlock {
  __typename?: "CoreAvatarBlock";
  attributes?: Maybe<CoreAvatarBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreAvatarBlockAttributes {
  __typename?: "CoreAvatarBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  isLink: ScalarsEnums["Boolean"];
  linkTarget: ScalarsEnums["String"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  size: ScalarsEnums["Float"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  userId?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * core/block block
 */
export interface CoreBlock {
  __typename?: "CoreBlock";
  attributes?: Maybe<CoreBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  reusableBlock: Node;
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreBlockAttributes {
  __typename?: "CoreBlockAttributes";
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  ref?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * core/button block
 */
export interface CoreButtonBlock {
  __typename?: "CoreButtonBlock";
  attributes?: Maybe<CoreButtonBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreButtonBlockAttributes {
  __typename?: "CoreButtonBlockAttributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  text?: Maybe<ScalarsEnums["String"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

export interface CoreButtonBlockAttributesUnion {
  __typename?:
    | "CoreButtonBlockAttributes"
    | "CoreButtonBlockDeprecatedV1Attributes"
    | "CoreButtonBlockDeprecatedV2Attributes"
    | "CoreButtonBlockDeprecatedV3Attributes"
    | "CoreButtonBlockDeprecatedV4Attributes"
    | "CoreButtonBlockDeprecatedV5Attributes"
    | "CoreButtonBlockDeprecatedV6Attributes"
    | "CoreButtonBlockDeprecatedV7Attributes"
    | "CoreButtonBlockDeprecatedV8Attributes"
    | "CoreButtonBlockDeprecatedV9Attributes"
    | "CoreButtonBlockDeprecatedV10Attributes"
    | "CoreButtonBlockDeprecatedV11Attributes";
  $on: $CoreButtonBlockAttributesUnion;
}

export interface CoreButtonBlockDeprecatedV10Attributes {
  __typename?: "CoreButtonBlockDeprecatedV10Attributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  text?: Maybe<ScalarsEnums["String"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

export interface CoreButtonBlockDeprecatedV11Attributes {
  __typename?: "CoreButtonBlockDeprecatedV11Attributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  text?: Maybe<ScalarsEnums["String"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

export interface CoreButtonBlockDeprecatedV1Attributes {
  __typename?: "CoreButtonBlockDeprecatedV1Attributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  text?: Maybe<ScalarsEnums["String"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

export interface CoreButtonBlockDeprecatedV2Attributes {
  __typename?: "CoreButtonBlockDeprecatedV2Attributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  text?: Maybe<ScalarsEnums["String"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

export interface CoreButtonBlockDeprecatedV3Attributes {
  __typename?: "CoreButtonBlockDeprecatedV3Attributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  text?: Maybe<ScalarsEnums["String"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

export interface CoreButtonBlockDeprecatedV4Attributes {
  __typename?: "CoreButtonBlockDeprecatedV4Attributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  text?: Maybe<ScalarsEnums["String"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

export interface CoreButtonBlockDeprecatedV5Attributes {
  __typename?: "CoreButtonBlockDeprecatedV5Attributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  text?: Maybe<ScalarsEnums["String"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

export interface CoreButtonBlockDeprecatedV6Attributes {
  __typename?: "CoreButtonBlockDeprecatedV6Attributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  text?: Maybe<ScalarsEnums["String"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

export interface CoreButtonBlockDeprecatedV7Attributes {
  __typename?: "CoreButtonBlockDeprecatedV7Attributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  text?: Maybe<ScalarsEnums["String"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

export interface CoreButtonBlockDeprecatedV8Attributes {
  __typename?: "CoreButtonBlockDeprecatedV8Attributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  text?: Maybe<ScalarsEnums["String"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

export interface CoreButtonBlockDeprecatedV9Attributes {
  __typename?: "CoreButtonBlockDeprecatedV9Attributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  text?: Maybe<ScalarsEnums["String"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * core/buttons block
 */
export interface CoreButtonsBlock {
  __typename?: "CoreButtonsBlock";
  attributes?: Maybe<CoreButtonsBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreButtonsBlockAttributes {
  __typename?: "CoreButtonsBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

export interface CoreButtonsBlockAttributesUnion {
  __typename?:
    | "CoreButtonsBlockAttributes"
    | "CoreButtonsBlockDeprecatedV1Attributes"
    | "CoreButtonsBlockDeprecatedV2Attributes";
  $on: $CoreButtonsBlockAttributesUnion;
}

export interface CoreButtonsBlockDeprecatedV1Attributes {
  __typename?: "CoreButtonsBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

export interface CoreButtonsBlockDeprecatedV2Attributes {
  __typename?: "CoreButtonsBlockDeprecatedV2Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

/**
 * core/calendar block
 */
export interface CoreCalendarBlock {
  __typename?: "CoreCalendarBlock";
  attributes?: Maybe<CoreCalendarBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCalendarBlockAttributes {
  __typename?: "CoreCalendarBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  month?: Maybe<ScalarsEnums["Int"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  year?: Maybe<ScalarsEnums["Int"]>;
}

/**
 * core/categories block
 */
export interface CoreCategoriesBlock {
  __typename?: "CoreCategoriesBlock";
  attributes?: Maybe<CoreCategoriesBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCategoriesBlockAttributes {
  __typename?: "CoreCategoriesBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  displayAsDropdown: ScalarsEnums["Boolean"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  showEmpty: ScalarsEnums["Boolean"];
  showHierarchy: ScalarsEnums["Boolean"];
  showOnlyTopLevel: ScalarsEnums["Boolean"];
  showPostCounts: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

/**
 * core/code block
 */
export interface CoreCodeBlock {
  __typename?: "CoreCodeBlock";
  attributes?: Maybe<CoreCodeBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCodeBlockAttributes {
  __typename?: "CoreCodeBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/column block
 */
export interface CoreColumnBlock {
  __typename?: "CoreColumnBlock";
  attributes?: Maybe<CoreColumnBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreColumnBlockAttributes {
  __typename?: "CoreColumnBlockAttributes";
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  verticalAlignment?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreColumnBlockAttributesUnion {
  __typename?:
    | "CoreColumnBlockAttributes"
    | "CoreColumnBlockDeprecatedV1Attributes";
  $on: $CoreColumnBlockAttributesUnion;
}

export interface CoreColumnBlockDeprecatedV1Attributes {
  __typename?: "CoreColumnBlockDeprecatedV1Attributes";
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  verticalAlignment?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/columns block
 */
export interface CoreColumnsBlock {
  __typename?: "CoreColumnsBlock";
  attributes?: Maybe<CoreColumnsBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreColumnsBlockAttributes {
  __typename?: "CoreColumnsBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  isStackedOnMobile: ScalarsEnums["Boolean"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  verticalAlignment?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreColumnsBlockAttributesUnion {
  __typename?:
    | "CoreColumnsBlockAttributes"
    | "CoreColumnsBlockDeprecatedV1Attributes"
    | "CoreColumnsBlockDeprecatedV2Attributes"
    | "CoreColumnsBlockDeprecatedV3Attributes";
  $on: $CoreColumnsBlockAttributesUnion;
}

export interface CoreColumnsBlockDeprecatedV1Attributes {
  __typename?: "CoreColumnsBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  isStackedOnMobile: ScalarsEnums["Boolean"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  verticalAlignment?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreColumnsBlockDeprecatedV2Attributes {
  __typename?: "CoreColumnsBlockDeprecatedV2Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  isStackedOnMobile: ScalarsEnums["Boolean"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  verticalAlignment?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreColumnsBlockDeprecatedV3Attributes {
  __typename?: "CoreColumnsBlockDeprecatedV3Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  isStackedOnMobile: ScalarsEnums["Boolean"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  verticalAlignment?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/comment-author-name block
 */
export interface CoreCommentAuthorNameBlock {
  __typename?: "CoreCommentAuthorNameBlock";
  attributes?: Maybe<CoreCommentAuthorNameBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCommentAuthorNameBlockAttributes {
  __typename?: "CoreCommentAuthorNameBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  isLink: ScalarsEnums["Boolean"];
  linkTarget: ScalarsEnums["String"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCommentAuthorNameBlockAttributesUnion {
  __typename?:
    | "CoreCommentAuthorNameBlockAttributes"
    | "CoreCommentAuthorNameBlockDeprecatedV1Attributes";
  $on: $CoreCommentAuthorNameBlockAttributesUnion;
}

export interface CoreCommentAuthorNameBlockDeprecatedV1Attributes {
  __typename?: "CoreCommentAuthorNameBlockDeprecatedV1Attributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  isLink: ScalarsEnums["Boolean"];
  linkTarget: ScalarsEnums["String"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/comment-content block
 */
export interface CoreCommentContentBlock {
  __typename?: "CoreCommentContentBlock";
  attributes?: Maybe<CoreCommentContentBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCommentContentBlockAttributes {
  __typename?: "CoreCommentContentBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/comment-date block
 */
export interface CoreCommentDateBlock {
  __typename?: "CoreCommentDateBlock";
  attributes?: Maybe<CoreCommentDateBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCommentDateBlockAttributes {
  __typename?: "CoreCommentDateBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  format?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  isLink: ScalarsEnums["Boolean"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCommentDateBlockAttributesUnion {
  __typename?:
    | "CoreCommentDateBlockAttributes"
    | "CoreCommentDateBlockDeprecatedV1Attributes";
  $on: $CoreCommentDateBlockAttributesUnion;
}

export interface CoreCommentDateBlockDeprecatedV1Attributes {
  __typename?: "CoreCommentDateBlockDeprecatedV1Attributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  format?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  isLink: ScalarsEnums["Boolean"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/comment-edit-link block
 */
export interface CoreCommentEditLinkBlock {
  __typename?: "CoreCommentEditLinkBlock";
  attributes?: Maybe<CoreCommentEditLinkBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCommentEditLinkBlockAttributes {
  __typename?: "CoreCommentEditLinkBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  linkTarget: ScalarsEnums["String"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/comment-reply-link block
 */
export interface CoreCommentReplyLinkBlock {
  __typename?: "CoreCommentReplyLinkBlock";
  attributes?: Maybe<CoreCommentReplyLinkBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCommentReplyLinkBlockAttributes {
  __typename?: "CoreCommentReplyLinkBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/comment-template block
 */
export interface CoreCommentTemplateBlock {
  __typename?: "CoreCommentTemplateBlock";
  attributes?: Maybe<CoreCommentTemplateBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCommentTemplateBlockAttributes {
  __typename?: "CoreCommentTemplateBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

/**
 * core/comments block
 */
export interface CoreCommentsBlock {
  __typename?: "CoreCommentsBlock";
  attributes?: Maybe<CoreCommentsBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCommentsBlockAttributes {
  __typename?: "CoreCommentsBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  legacy: ScalarsEnums["Boolean"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCommentsBlockAttributesUnion {
  __typename?:
    | "CoreCommentsBlockAttributes"
    | "CoreCommentsBlockDeprecatedV1Attributes";
  $on: $CoreCommentsBlockAttributesUnion;
}

export interface CoreCommentsBlockDeprecatedV1Attributes {
  __typename?: "CoreCommentsBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  legacy: ScalarsEnums["Boolean"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/comments-pagination block
 */
export interface CoreCommentsPaginationBlock {
  __typename?: "CoreCommentsPaginationBlock";
  attributes?: Maybe<CoreCommentsPaginationBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCommentsPaginationBlockAttributes {
  __typename?: "CoreCommentsPaginationBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  paginationArrow: ScalarsEnums["String"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/comments-pagination-next block
 */
export interface CoreCommentsPaginationNextBlock {
  __typename?: "CoreCommentsPaginationNextBlock";
  attributes?: Maybe<CoreCommentsPaginationNextBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCommentsPaginationNextBlockAttributes {
  __typename?: "CoreCommentsPaginationNextBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  label?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/comments-pagination-numbers block
 */
export interface CoreCommentsPaginationNumbersBlock {
  __typename?: "CoreCommentsPaginationNumbersBlock";
  attributes?: Maybe<CoreCommentsPaginationNumbersBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCommentsPaginationNumbersBlockAttributes {
  __typename?: "CoreCommentsPaginationNumbersBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/comments-pagination-previous block
 */
export interface CoreCommentsPaginationPreviousBlock {
  __typename?: "CoreCommentsPaginationPreviousBlock";
  attributes?: Maybe<CoreCommentsPaginationPreviousBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCommentsPaginationPreviousBlockAttributes {
  __typename?: "CoreCommentsPaginationPreviousBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  label?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/comments-title block
 */
export interface CoreCommentsTitleBlock {
  __typename?: "CoreCommentsTitleBlock";
  attributes?: Maybe<CoreCommentsTitleBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCommentsTitleBlockAttributes {
  __typename?: "CoreCommentsTitleBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  level: ScalarsEnums["Float"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  showCommentsCount: ScalarsEnums["Boolean"];
  showPostTitle: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCommentsTitleBlockAttributesUnion {
  __typename?:
    | "CoreCommentsTitleBlockAttributes"
    | "CoreCommentsTitleBlockDeprecatedV1Attributes";
  $on: $CoreCommentsTitleBlockAttributesUnion;
}

export interface CoreCommentsTitleBlockDeprecatedV1Attributes {
  __typename?: "CoreCommentsTitleBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  level: ScalarsEnums["Float"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  showCommentsCount: ScalarsEnums["Boolean"];
  showPostTitle: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/cover block
 */
export interface CoreCoverBlock {
  __typename?: "CoreCoverBlock";
  attributes?: Maybe<CoreCoverBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreCoverBlockAttributes {
  __typename?: "CoreCoverBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  backgroundType: ScalarsEnums["String"];
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  contentPosition?: Maybe<ScalarsEnums["String"]>;
  customGradient?: Maybe<ScalarsEnums["String"]>;
  customOverlayColor?: Maybe<ScalarsEnums["String"]>;
  dimRatio: ScalarsEnums["Float"];
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  hasParallax: ScalarsEnums["Boolean"];
  id?: Maybe<ScalarsEnums["Float"]>;
  isDark: ScalarsEnums["Boolean"];
  isRepeated: ScalarsEnums["Boolean"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  minHeight?: Maybe<ScalarsEnums["Float"]>;
  minHeightUnit?: Maybe<ScalarsEnums["String"]>;
  overlayColor?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  useFeaturedImage: ScalarsEnums["Boolean"];
}

export interface CoreCoverBlockAttributesUnion {
  __typename?:
    | "CoreCoverBlockAttributes"
    | "CoreCoverBlockDeprecatedV1Attributes"
    | "CoreCoverBlockDeprecatedV2Attributes"
    | "CoreCoverBlockDeprecatedV3Attributes"
    | "CoreCoverBlockDeprecatedV4Attributes"
    | "CoreCoverBlockDeprecatedV5Attributes"
    | "CoreCoverBlockDeprecatedV6Attributes"
    | "CoreCoverBlockDeprecatedV7Attributes"
    | "CoreCoverBlockDeprecatedV8Attributes"
    | "CoreCoverBlockDeprecatedV9Attributes"
    | "CoreCoverBlockDeprecatedV10Attributes"
    | "CoreCoverBlockDeprecatedV11Attributes";
  $on: $CoreCoverBlockAttributesUnion;
}

export interface CoreCoverBlockDeprecatedV10Attributes {
  __typename?: "CoreCoverBlockDeprecatedV10Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  backgroundType: ScalarsEnums["String"];
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  contentPosition?: Maybe<ScalarsEnums["String"]>;
  customGradient?: Maybe<ScalarsEnums["String"]>;
  customOverlayColor?: Maybe<ScalarsEnums["String"]>;
  dimRatio: ScalarsEnums["Float"];
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  hasParallax: ScalarsEnums["Boolean"];
  id?: Maybe<ScalarsEnums["Float"]>;
  isDark: ScalarsEnums["Boolean"];
  isRepeated: ScalarsEnums["Boolean"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  minHeight?: Maybe<ScalarsEnums["Float"]>;
  minHeightUnit?: Maybe<ScalarsEnums["String"]>;
  overlayColor?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  useFeaturedImage: ScalarsEnums["Boolean"];
}

export interface CoreCoverBlockDeprecatedV11Attributes {
  __typename?: "CoreCoverBlockDeprecatedV11Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  backgroundType: ScalarsEnums["String"];
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  contentPosition?: Maybe<ScalarsEnums["String"]>;
  customGradient?: Maybe<ScalarsEnums["String"]>;
  customOverlayColor?: Maybe<ScalarsEnums["String"]>;
  dimRatio: ScalarsEnums["Float"];
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  hasParallax: ScalarsEnums["Boolean"];
  id?: Maybe<ScalarsEnums["Float"]>;
  isDark: ScalarsEnums["Boolean"];
  isRepeated: ScalarsEnums["Boolean"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  minHeight?: Maybe<ScalarsEnums["Float"]>;
  minHeightUnit?: Maybe<ScalarsEnums["String"]>;
  overlayColor?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  useFeaturedImage: ScalarsEnums["Boolean"];
}

export interface CoreCoverBlockDeprecatedV1Attributes {
  __typename?: "CoreCoverBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  backgroundType: ScalarsEnums["String"];
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  contentPosition?: Maybe<ScalarsEnums["String"]>;
  customGradient?: Maybe<ScalarsEnums["String"]>;
  customOverlayColor?: Maybe<ScalarsEnums["String"]>;
  dimRatio: ScalarsEnums["Float"];
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  hasParallax: ScalarsEnums["Boolean"];
  id?: Maybe<ScalarsEnums["Float"]>;
  isDark: ScalarsEnums["Boolean"];
  isRepeated: ScalarsEnums["Boolean"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  minHeight?: Maybe<ScalarsEnums["Float"]>;
  minHeightUnit?: Maybe<ScalarsEnums["String"]>;
  overlayColor?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  useFeaturedImage: ScalarsEnums["Boolean"];
}

export interface CoreCoverBlockDeprecatedV2Attributes {
  __typename?: "CoreCoverBlockDeprecatedV2Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  backgroundType: ScalarsEnums["String"];
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  contentPosition?: Maybe<ScalarsEnums["String"]>;
  customGradient?: Maybe<ScalarsEnums["String"]>;
  customOverlayColor?: Maybe<ScalarsEnums["String"]>;
  dimRatio: ScalarsEnums["Float"];
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  hasParallax: ScalarsEnums["Boolean"];
  id?: Maybe<ScalarsEnums["Float"]>;
  isDark: ScalarsEnums["Boolean"];
  isRepeated: ScalarsEnums["Boolean"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  minHeight?: Maybe<ScalarsEnums["Float"]>;
  minHeightUnit?: Maybe<ScalarsEnums["String"]>;
  overlayColor?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  useFeaturedImage: ScalarsEnums["Boolean"];
}

export interface CoreCoverBlockDeprecatedV3Attributes {
  __typename?: "CoreCoverBlockDeprecatedV3Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  backgroundType: ScalarsEnums["String"];
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  contentPosition?: Maybe<ScalarsEnums["String"]>;
  customGradient?: Maybe<ScalarsEnums["String"]>;
  customOverlayColor?: Maybe<ScalarsEnums["String"]>;
  dimRatio: ScalarsEnums["Float"];
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  hasParallax: ScalarsEnums["Boolean"];
  id?: Maybe<ScalarsEnums["Float"]>;
  isDark: ScalarsEnums["Boolean"];
  isRepeated: ScalarsEnums["Boolean"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  minHeight?: Maybe<ScalarsEnums["Float"]>;
  minHeightUnit?: Maybe<ScalarsEnums["String"]>;
  overlayColor?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  useFeaturedImage: ScalarsEnums["Boolean"];
}

export interface CoreCoverBlockDeprecatedV4Attributes {
  __typename?: "CoreCoverBlockDeprecatedV4Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  backgroundType: ScalarsEnums["String"];
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  contentPosition?: Maybe<ScalarsEnums["String"]>;
  customGradient?: Maybe<ScalarsEnums["String"]>;
  customOverlayColor?: Maybe<ScalarsEnums["String"]>;
  dimRatio: ScalarsEnums["Float"];
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  hasParallax: ScalarsEnums["Boolean"];
  id?: Maybe<ScalarsEnums["Float"]>;
  isDark: ScalarsEnums["Boolean"];
  isRepeated: ScalarsEnums["Boolean"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  minHeight?: Maybe<ScalarsEnums["Float"]>;
  minHeightUnit?: Maybe<ScalarsEnums["String"]>;
  overlayColor?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  useFeaturedImage: ScalarsEnums["Boolean"];
}

export interface CoreCoverBlockDeprecatedV5Attributes {
  __typename?: "CoreCoverBlockDeprecatedV5Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  backgroundType: ScalarsEnums["String"];
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  contentPosition?: Maybe<ScalarsEnums["String"]>;
  customGradient?: Maybe<ScalarsEnums["String"]>;
  customOverlayColor?: Maybe<ScalarsEnums["String"]>;
  dimRatio: ScalarsEnums["Float"];
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  hasParallax: ScalarsEnums["Boolean"];
  id?: Maybe<ScalarsEnums["Float"]>;
  isDark: ScalarsEnums["Boolean"];
  isRepeated: ScalarsEnums["Boolean"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  minHeight?: Maybe<ScalarsEnums["Float"]>;
  minHeightUnit?: Maybe<ScalarsEnums["String"]>;
  overlayColor?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  useFeaturedImage: ScalarsEnums["Boolean"];
}

export interface CoreCoverBlockDeprecatedV6Attributes {
  __typename?: "CoreCoverBlockDeprecatedV6Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  backgroundType: ScalarsEnums["String"];
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  contentPosition?: Maybe<ScalarsEnums["String"]>;
  customGradient?: Maybe<ScalarsEnums["String"]>;
  customOverlayColor?: Maybe<ScalarsEnums["String"]>;
  dimRatio: ScalarsEnums["Float"];
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  hasParallax: ScalarsEnums["Boolean"];
  id?: Maybe<ScalarsEnums["Float"]>;
  isDark: ScalarsEnums["Boolean"];
  isRepeated: ScalarsEnums["Boolean"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  minHeight?: Maybe<ScalarsEnums["Float"]>;
  minHeightUnit?: Maybe<ScalarsEnums["String"]>;
  overlayColor?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  useFeaturedImage: ScalarsEnums["Boolean"];
}

export interface CoreCoverBlockDeprecatedV7Attributes {
  __typename?: "CoreCoverBlockDeprecatedV7Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  backgroundType: ScalarsEnums["String"];
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  contentPosition?: Maybe<ScalarsEnums["String"]>;
  customGradient?: Maybe<ScalarsEnums["String"]>;
  customOverlayColor?: Maybe<ScalarsEnums["String"]>;
  dimRatio: ScalarsEnums["Float"];
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  hasParallax: ScalarsEnums["Boolean"];
  id?: Maybe<ScalarsEnums["Float"]>;
  isDark: ScalarsEnums["Boolean"];
  isRepeated: ScalarsEnums["Boolean"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  minHeight?: Maybe<ScalarsEnums["Float"]>;
  minHeightUnit?: Maybe<ScalarsEnums["String"]>;
  overlayColor?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  useFeaturedImage: ScalarsEnums["Boolean"];
}

export interface CoreCoverBlockDeprecatedV8Attributes {
  __typename?: "CoreCoverBlockDeprecatedV8Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  backgroundType: ScalarsEnums["String"];
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  contentPosition?: Maybe<ScalarsEnums["String"]>;
  customGradient?: Maybe<ScalarsEnums["String"]>;
  customOverlayColor?: Maybe<ScalarsEnums["String"]>;
  dimRatio: ScalarsEnums["Float"];
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  hasParallax: ScalarsEnums["Boolean"];
  id?: Maybe<ScalarsEnums["Float"]>;
  isDark: ScalarsEnums["Boolean"];
  isRepeated: ScalarsEnums["Boolean"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  minHeight?: Maybe<ScalarsEnums["Float"]>;
  minHeightUnit?: Maybe<ScalarsEnums["String"]>;
  overlayColor?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  useFeaturedImage: ScalarsEnums["Boolean"];
}

export interface CoreCoverBlockDeprecatedV9Attributes {
  __typename?: "CoreCoverBlockDeprecatedV9Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  backgroundType: ScalarsEnums["String"];
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  contentPosition?: Maybe<ScalarsEnums["String"]>;
  customGradient?: Maybe<ScalarsEnums["String"]>;
  customOverlayColor?: Maybe<ScalarsEnums["String"]>;
  dimRatio: ScalarsEnums["Float"];
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  hasParallax: ScalarsEnums["Boolean"];
  id?: Maybe<ScalarsEnums["Float"]>;
  isDark: ScalarsEnums["Boolean"];
  isRepeated: ScalarsEnums["Boolean"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  minHeight?: Maybe<ScalarsEnums["Float"]>;
  minHeightUnit?: Maybe<ScalarsEnums["String"]>;
  overlayColor?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  useFeaturedImage: ScalarsEnums["Boolean"];
}

/**
 * core/details block
 */
export interface CoreDetailsBlock {
  __typename?: "CoreDetailsBlock";
  attributes?: Maybe<CoreDetailsBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreDetailsBlockAttributes {
  __typename?: "CoreDetailsBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  showContent: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  summary?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/embed block
 */
export interface CoreEmbedBlock {
  __typename?: "CoreEmbedBlock";
  attributes?: Maybe<CoreEmbedBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreEmbedBlockAttributes {
  __typename?: "CoreEmbedBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowResponsive: ScalarsEnums["Boolean"];
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  previewable: ScalarsEnums["Boolean"];
  providerNameSlug?: Maybe<ScalarsEnums["String"]>;
  responsive: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  type?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreEmbedBlockAttributesUnion {
  __typename?:
    | "CoreEmbedBlockAttributes"
    | "CoreEmbedBlockDeprecatedV1Attributes"
    | "CoreEmbedBlockDeprecatedV2Attributes";
  $on: $CoreEmbedBlockAttributesUnion;
}

export interface CoreEmbedBlockDeprecatedV1Attributes {
  __typename?: "CoreEmbedBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowResponsive: ScalarsEnums["Boolean"];
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  previewable: ScalarsEnums["Boolean"];
  providerNameSlug?: Maybe<ScalarsEnums["String"]>;
  responsive: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  type?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreEmbedBlockDeprecatedV2Attributes {
  __typename?: "CoreEmbedBlockDeprecatedV2Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowResponsive: ScalarsEnums["Boolean"];
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  previewable: ScalarsEnums["Boolean"];
  providerNameSlug?: Maybe<ScalarsEnums["String"]>;
  responsive: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  type?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/file block
 */
export interface CoreFileBlock {
  __typename?: "CoreFileBlock";
  attributes?: Maybe<CoreFileBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreFileBlockAttributes {
  __typename?: "CoreFileBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  displayPreview?: Maybe<ScalarsEnums["Boolean"]>;
  downloadButtonText?: Maybe<ScalarsEnums["String"]>;
  fileId?: Maybe<ScalarsEnums["String"]>;
  fileName?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  previewHeight: ScalarsEnums["Float"];
  showDownloadButton: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  textLinkHref?: Maybe<ScalarsEnums["String"]>;
  textLinkTarget?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreFileBlockAttributesUnion {
  __typename?:
    | "CoreFileBlockAttributes"
    | "CoreFileBlockDeprecatedV1Attributes"
    | "CoreFileBlockDeprecatedV2Attributes"
    | "CoreFileBlockDeprecatedV3Attributes";
  $on: $CoreFileBlockAttributesUnion;
}

export interface CoreFileBlockDeprecatedV1Attributes {
  __typename?: "CoreFileBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  displayPreview?: Maybe<ScalarsEnums["Boolean"]>;
  downloadButtonText?: Maybe<ScalarsEnums["String"]>;
  fileId?: Maybe<ScalarsEnums["String"]>;
  fileName?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  previewHeight: ScalarsEnums["Float"];
  showDownloadButton: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  textLinkHref?: Maybe<ScalarsEnums["String"]>;
  textLinkTarget?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreFileBlockDeprecatedV2Attributes {
  __typename?: "CoreFileBlockDeprecatedV2Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  displayPreview?: Maybe<ScalarsEnums["Boolean"]>;
  downloadButtonText?: Maybe<ScalarsEnums["String"]>;
  fileId?: Maybe<ScalarsEnums["String"]>;
  fileName?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  previewHeight: ScalarsEnums["Float"];
  showDownloadButton: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  textLinkHref?: Maybe<ScalarsEnums["String"]>;
  textLinkTarget?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreFileBlockDeprecatedV3Attributes {
  __typename?: "CoreFileBlockDeprecatedV3Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  displayPreview?: Maybe<ScalarsEnums["Boolean"]>;
  downloadButtonText?: Maybe<ScalarsEnums["String"]>;
  fileId?: Maybe<ScalarsEnums["String"]>;
  fileName?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  previewHeight: ScalarsEnums["Float"];
  showDownloadButton: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  textLinkHref?: Maybe<ScalarsEnums["String"]>;
  textLinkTarget?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/footnotes block
 */
export interface CoreFootnotesBlock {
  __typename?: "CoreFootnotesBlock";
  attributes?: Maybe<CoreFootnotesBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreFootnotesBlockAttributes {
  __typename?: "CoreFootnotesBlockAttributes";
  className?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

/**
 * core/freeform block
 */
export interface CoreFreeformBlock {
  __typename?: "CoreFreeformBlock";
  attributes?: Maybe<CoreFreeformBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreFreeformBlockAttributes {
  __typename?: "CoreFreeformBlockAttributes";
  content?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

/**
 * core/gallery block
 */
export interface CoreGalleryBlock {
  __typename?: "CoreGalleryBlock";
  attributes?: Maybe<CoreGalleryBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGalleryBlockAttributes {
  __typename?: "CoreGalleryBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowResize: ScalarsEnums["Boolean"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  columns?: Maybe<ScalarsEnums["Float"]>;
  fixedHeight: ScalarsEnums["Boolean"];
  gradient?: Maybe<ScalarsEnums["String"]>;
  ids: Array<Maybe<ScalarsEnums["Float"]>>;
  imageCrop: ScalarsEnums["Boolean"];
  images: Array<Maybe<CoreGalleryBlockAttributesImages>>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  linkTo?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  shortCodeTransforms: Array<Maybe<ScalarsEnums["BlockAttributesObject"]>>;
  sizeSlug: ScalarsEnums["String"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGalleryBlockAttributesImages {
  __typename?: "CoreGalleryBlockAttributesImages";
  alt: ScalarsEnums["String"];
  caption?: Maybe<ScalarsEnums["String"]>;
  fullUrl?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["String"]>;
  link?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGalleryBlockAttributesUnion {
  __typename?:
    | "CoreGalleryBlockAttributes"
    | "CoreGalleryBlockDeprecatedV1Attributes"
    | "CoreGalleryBlockDeprecatedV2Attributes"
    | "CoreGalleryBlockDeprecatedV3Attributes"
    | "CoreGalleryBlockDeprecatedV4Attributes"
    | "CoreGalleryBlockDeprecatedV5Attributes"
    | "CoreGalleryBlockDeprecatedV6Attributes"
    | "CoreGalleryBlockDeprecatedV7Attributes";
  $on: $CoreGalleryBlockAttributesUnion;
}

export interface CoreGalleryBlockDeprecatedV1Attributes {
  __typename?: "CoreGalleryBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowResize: ScalarsEnums["Boolean"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  columns?: Maybe<ScalarsEnums["Float"]>;
  fixedHeight: ScalarsEnums["Boolean"];
  gradient?: Maybe<ScalarsEnums["String"]>;
  ids: Array<Maybe<ScalarsEnums["Float"]>>;
  imageCrop: ScalarsEnums["Boolean"];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV1AttributesImages>>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  linkTo?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  shortCodeTransforms: Array<Maybe<ScalarsEnums["BlockAttributesObject"]>>;
  sizeSlug: ScalarsEnums["String"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGalleryBlockDeprecatedV1AttributesImages {
  __typename?: "CoreGalleryBlockDeprecatedV1AttributesImages";
  alt: ScalarsEnums["String"];
  caption?: Maybe<ScalarsEnums["String"]>;
  fullUrl?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["String"]>;
  link?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGalleryBlockDeprecatedV2Attributes {
  __typename?: "CoreGalleryBlockDeprecatedV2Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowResize: ScalarsEnums["Boolean"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  columns?: Maybe<ScalarsEnums["Float"]>;
  fixedHeight: ScalarsEnums["Boolean"];
  gradient?: Maybe<ScalarsEnums["String"]>;
  ids: Array<Maybe<ScalarsEnums["Float"]>>;
  imageCrop: ScalarsEnums["Boolean"];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV2AttributesImages>>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  linkTo?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  shortCodeTransforms: Array<Maybe<ScalarsEnums["BlockAttributesObject"]>>;
  sizeSlug: ScalarsEnums["String"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGalleryBlockDeprecatedV2AttributesImages {
  __typename?: "CoreGalleryBlockDeprecatedV2AttributesImages";
  alt: ScalarsEnums["String"];
  caption?: Maybe<ScalarsEnums["String"]>;
  fullUrl?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["String"]>;
  link?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGalleryBlockDeprecatedV3Attributes {
  __typename?: "CoreGalleryBlockDeprecatedV3Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowResize: ScalarsEnums["Boolean"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  columns?: Maybe<ScalarsEnums["Float"]>;
  fixedHeight: ScalarsEnums["Boolean"];
  gradient?: Maybe<ScalarsEnums["String"]>;
  ids: Array<Maybe<ScalarsEnums["Float"]>>;
  imageCrop: ScalarsEnums["Boolean"];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV3AttributesImages>>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  linkTo?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  shortCodeTransforms: Array<Maybe<ScalarsEnums["BlockAttributesObject"]>>;
  sizeSlug: ScalarsEnums["String"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGalleryBlockDeprecatedV3AttributesImages {
  __typename?: "CoreGalleryBlockDeprecatedV3AttributesImages";
  alt: ScalarsEnums["String"];
  caption?: Maybe<ScalarsEnums["String"]>;
  fullUrl?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["String"]>;
  link?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGalleryBlockDeprecatedV4Attributes {
  __typename?: "CoreGalleryBlockDeprecatedV4Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowResize: ScalarsEnums["Boolean"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  columns?: Maybe<ScalarsEnums["Float"]>;
  fixedHeight: ScalarsEnums["Boolean"];
  gradient?: Maybe<ScalarsEnums["String"]>;
  ids: Array<Maybe<ScalarsEnums["Float"]>>;
  imageCrop: ScalarsEnums["Boolean"];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV4AttributesImages>>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  linkTo?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  shortCodeTransforms: Array<Maybe<ScalarsEnums["BlockAttributesObject"]>>;
  sizeSlug: ScalarsEnums["String"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGalleryBlockDeprecatedV4AttributesImages {
  __typename?: "CoreGalleryBlockDeprecatedV4AttributesImages";
  alt: ScalarsEnums["String"];
  caption?: Maybe<ScalarsEnums["String"]>;
  fullUrl?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["String"]>;
  link?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGalleryBlockDeprecatedV5Attributes {
  __typename?: "CoreGalleryBlockDeprecatedV5Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowResize: ScalarsEnums["Boolean"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  columns?: Maybe<ScalarsEnums["Float"]>;
  fixedHeight: ScalarsEnums["Boolean"];
  gradient?: Maybe<ScalarsEnums["String"]>;
  ids: Array<Maybe<ScalarsEnums["Float"]>>;
  imageCrop: ScalarsEnums["Boolean"];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV5AttributesImages>>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  linkTo?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  shortCodeTransforms: Array<Maybe<ScalarsEnums["BlockAttributesObject"]>>;
  sizeSlug: ScalarsEnums["String"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGalleryBlockDeprecatedV5AttributesImages {
  __typename?: "CoreGalleryBlockDeprecatedV5AttributesImages";
  alt: ScalarsEnums["String"];
  caption?: Maybe<ScalarsEnums["String"]>;
  fullUrl?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["String"]>;
  link?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGalleryBlockDeprecatedV6Attributes {
  __typename?: "CoreGalleryBlockDeprecatedV6Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowResize: ScalarsEnums["Boolean"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  columns?: Maybe<ScalarsEnums["Float"]>;
  fixedHeight: ScalarsEnums["Boolean"];
  gradient?: Maybe<ScalarsEnums["String"]>;
  ids: Array<Maybe<ScalarsEnums["Float"]>>;
  imageCrop: ScalarsEnums["Boolean"];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV6AttributesImages>>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  linkTo?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  shortCodeTransforms: Array<Maybe<ScalarsEnums["BlockAttributesObject"]>>;
  sizeSlug: ScalarsEnums["String"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGalleryBlockDeprecatedV6AttributesImages {
  __typename?: "CoreGalleryBlockDeprecatedV6AttributesImages";
  alt: ScalarsEnums["String"];
  caption?: Maybe<ScalarsEnums["String"]>;
  fullUrl?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["String"]>;
  link?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGalleryBlockDeprecatedV7Attributes {
  __typename?: "CoreGalleryBlockDeprecatedV7Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowResize: ScalarsEnums["Boolean"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  columns?: Maybe<ScalarsEnums["Float"]>;
  fixedHeight: ScalarsEnums["Boolean"];
  gradient?: Maybe<ScalarsEnums["String"]>;
  ids: Array<Maybe<ScalarsEnums["Float"]>>;
  imageCrop: ScalarsEnums["Boolean"];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV7AttributesImages>>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  linkTo?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  shortCodeTransforms: Array<Maybe<ScalarsEnums["BlockAttributesObject"]>>;
  sizeSlug: ScalarsEnums["String"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGalleryBlockDeprecatedV7AttributesImages {
  __typename?: "CoreGalleryBlockDeprecatedV7AttributesImages";
  alt: ScalarsEnums["String"];
  caption?: Maybe<ScalarsEnums["String"]>;
  fullUrl?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["String"]>;
  link?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/group block
 */
export interface CoreGroupBlock {
  __typename?: "CoreGroupBlock";
  attributes?: Maybe<CoreGroupBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGroupBlockAttributes {
  __typename?: "CoreGroupBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  ariaLabel?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  settings?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGroupBlockAttributesUnion {
  __typename?:
    | "CoreGroupBlockAttributes"
    | "CoreGroupBlockDeprecatedV1Attributes"
    | "CoreGroupBlockDeprecatedV2Attributes"
    | "CoreGroupBlockDeprecatedV3Attributes"
    | "CoreGroupBlockDeprecatedV4Attributes"
    | "CoreGroupBlockDeprecatedV5Attributes";
  $on: $CoreGroupBlockAttributesUnion;
}

export interface CoreGroupBlockDeprecatedV1Attributes {
  __typename?: "CoreGroupBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  ariaLabel?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  settings?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGroupBlockDeprecatedV2Attributes {
  __typename?: "CoreGroupBlockDeprecatedV2Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  ariaLabel?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  settings?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGroupBlockDeprecatedV3Attributes {
  __typename?: "CoreGroupBlockDeprecatedV3Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  ariaLabel?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  settings?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGroupBlockDeprecatedV4Attributes {
  __typename?: "CoreGroupBlockDeprecatedV4Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  ariaLabel?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  settings?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreGroupBlockDeprecatedV5Attributes {
  __typename?: "CoreGroupBlockDeprecatedV5Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  ariaLabel?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  settings?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tagName: ScalarsEnums["String"];
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/heading block
 */
export interface CoreHeadingBlock {
  __typename?: "CoreHeadingBlock";
  attributes?: Maybe<CoreHeadingBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreHeadingBlockAttributes {
  __typename?: "CoreHeadingBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  level: ScalarsEnums["Float"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreHeadingBlockAttributesUnion {
  __typename?:
    | "CoreHeadingBlockAttributes"
    | "CoreHeadingBlockDeprecatedV1Attributes"
    | "CoreHeadingBlockDeprecatedV2Attributes"
    | "CoreHeadingBlockDeprecatedV3Attributes"
    | "CoreHeadingBlockDeprecatedV4Attributes"
    | "CoreHeadingBlockDeprecatedV5Attributes";
  $on: $CoreHeadingBlockAttributesUnion;
}

export interface CoreHeadingBlockDeprecatedV1Attributes {
  __typename?: "CoreHeadingBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  level: ScalarsEnums["Float"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreHeadingBlockDeprecatedV2Attributes {
  __typename?: "CoreHeadingBlockDeprecatedV2Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  level: ScalarsEnums["Float"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreHeadingBlockDeprecatedV3Attributes {
  __typename?: "CoreHeadingBlockDeprecatedV3Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  level: ScalarsEnums["Float"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreHeadingBlockDeprecatedV4Attributes {
  __typename?: "CoreHeadingBlockDeprecatedV4Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  level: ScalarsEnums["Float"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreHeadingBlockDeprecatedV5Attributes {
  __typename?: "CoreHeadingBlockDeprecatedV5Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  level: ScalarsEnums["Float"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/home-link block
 */
export interface CoreHomeLinkBlock {
  __typename?: "CoreHomeLinkBlock";
  attributes?: Maybe<CoreHomeLinkBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreHomeLinkBlockAttributes {
  __typename?: "CoreHomeLinkBlockAttributes";
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  label?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

/**
 * core/html block
 */
export interface CoreHtmlBlock {
  __typename?: "CoreHtmlBlock";
  attributes?: Maybe<CoreHtmlBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreHtmlBlockAttributes {
  __typename?: "CoreHtmlBlockAttributes";
  content?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

/**
 * core/image block
 */
export interface CoreImageBlock {
  __typename?: "CoreImageBlock";
  attributes?: Maybe<CoreImageBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Connection between the CoreImageBlock type and the MediaItem type
   */
  mediaItem?: Maybe<CoreImageBlockToMediaItemConnectionEdge>;
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreImageBlockAttributes {
  __typename?: "CoreImageBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  aspectRatio?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  height?: Maybe<ScalarsEnums["Float"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  linkClass?: Maybe<ScalarsEnums["String"]>;
  linkDestination?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  scale?: Maybe<ScalarsEnums["String"]>;
  sizeSlug?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

export interface CoreImageBlockAttributesUnion {
  __typename?:
    | "CoreImageBlockAttributes"
    | "CoreImageBlockDeprecatedV1Attributes"
    | "CoreImageBlockDeprecatedV2Attributes"
    | "CoreImageBlockDeprecatedV3Attributes"
    | "CoreImageBlockDeprecatedV4Attributes"
    | "CoreImageBlockDeprecatedV5Attributes"
    | "CoreImageBlockDeprecatedV6Attributes";
  $on: $CoreImageBlockAttributesUnion;
}

export interface CoreImageBlockDeprecatedV1Attributes {
  __typename?: "CoreImageBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  aspectRatio?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  height?: Maybe<ScalarsEnums["Float"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  linkClass?: Maybe<ScalarsEnums["String"]>;
  linkDestination?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  scale?: Maybe<ScalarsEnums["String"]>;
  sizeSlug?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

export interface CoreImageBlockDeprecatedV2Attributes {
  __typename?: "CoreImageBlockDeprecatedV2Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  aspectRatio?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  height?: Maybe<ScalarsEnums["Float"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  linkClass?: Maybe<ScalarsEnums["String"]>;
  linkDestination?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  scale?: Maybe<ScalarsEnums["String"]>;
  sizeSlug?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

export interface CoreImageBlockDeprecatedV3Attributes {
  __typename?: "CoreImageBlockDeprecatedV3Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  aspectRatio?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  height?: Maybe<ScalarsEnums["Float"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  linkClass?: Maybe<ScalarsEnums["String"]>;
  linkDestination?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  scale?: Maybe<ScalarsEnums["String"]>;
  sizeSlug?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

export interface CoreImageBlockDeprecatedV4Attributes {
  __typename?: "CoreImageBlockDeprecatedV4Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  aspectRatio?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  height?: Maybe<ScalarsEnums["Float"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  linkClass?: Maybe<ScalarsEnums["String"]>;
  linkDestination?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  scale?: Maybe<ScalarsEnums["String"]>;
  sizeSlug?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

export interface CoreImageBlockDeprecatedV5Attributes {
  __typename?: "CoreImageBlockDeprecatedV5Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  aspectRatio?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  height?: Maybe<ScalarsEnums["Float"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  linkClass?: Maybe<ScalarsEnums["String"]>;
  linkDestination?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  scale?: Maybe<ScalarsEnums["String"]>;
  sizeSlug?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

export interface CoreImageBlockDeprecatedV6Attributes {
  __typename?: "CoreImageBlockDeprecatedV6Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  alt: ScalarsEnums["String"];
  anchor?: Maybe<ScalarsEnums["String"]>;
  aspectRatio?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  height?: Maybe<ScalarsEnums["Float"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  linkClass?: Maybe<ScalarsEnums["String"]>;
  linkDestination?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  scale?: Maybe<ScalarsEnums["String"]>;
  sizeSlug?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * Connection between the CoreImageBlock type and the MediaItem type
 */
export interface CoreImageBlockToMediaItemConnectionEdge {
  __typename?: "CoreImageBlockToMediaItemConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<MediaItem>;
}

/**
 * core/latest-comments block
 */
export interface CoreLatestCommentsBlock {
  __typename?: "CoreLatestCommentsBlock";
  attributes?: Maybe<CoreLatestCommentsBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreLatestCommentsBlockAttributes {
  __typename?: "CoreLatestCommentsBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  commentsToShow: ScalarsEnums["Float"];
  displayAvatar: ScalarsEnums["Boolean"];
  displayDate: ScalarsEnums["Boolean"];
  displayExcerpt: ScalarsEnums["Boolean"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

/**
 * core/latest-posts block
 */
export interface CoreLatestPostsBlock {
  __typename?: "CoreLatestPostsBlock";
  attributes?: Maybe<CoreLatestPostsBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreLatestPostsBlockAttributes {
  __typename?: "CoreLatestPostsBlockAttributes";
  addLinkToFeaturedImage: ScalarsEnums["Boolean"];
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  categories?: Maybe<Array<Maybe<ScalarsEnums["BlockAttributesObject"]>>>;
  className?: Maybe<ScalarsEnums["String"]>;
  columns: ScalarsEnums["Float"];
  displayAuthor: ScalarsEnums["Boolean"];
  displayFeaturedImage: ScalarsEnums["Boolean"];
  displayPostContent: ScalarsEnums["Boolean"];
  displayPostContentRadio: ScalarsEnums["String"];
  displayPostDate: ScalarsEnums["Boolean"];
  excerptLength: ScalarsEnums["Float"];
  featuredImageAlign?: Maybe<ScalarsEnums["String"]>;
  featuredImageSizeHeight?: Maybe<ScalarsEnums["Float"]>;
  featuredImageSizeSlug: ScalarsEnums["String"];
  featuredImageSizeWidth?: Maybe<ScalarsEnums["Float"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  order: ScalarsEnums["String"];
  orderBy: ScalarsEnums["String"];
  postLayout: ScalarsEnums["String"];
  postsToShow: ScalarsEnums["Float"];
  selectedAuthor?: Maybe<ScalarsEnums["Float"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreLatestPostsBlockAttributesUnion {
  __typename?:
    | "CoreLatestPostsBlockAttributes"
    | "CoreLatestPostsBlockDeprecatedV1Attributes";
  $on: $CoreLatestPostsBlockAttributesUnion;
}

export interface CoreLatestPostsBlockDeprecatedV1Attributes {
  __typename?: "CoreLatestPostsBlockDeprecatedV1Attributes";
  addLinkToFeaturedImage: ScalarsEnums["Boolean"];
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  categories?: Maybe<Array<Maybe<ScalarsEnums["BlockAttributesObject"]>>>;
  className?: Maybe<ScalarsEnums["String"]>;
  columns: ScalarsEnums["Float"];
  displayAuthor: ScalarsEnums["Boolean"];
  displayFeaturedImage: ScalarsEnums["Boolean"];
  displayPostContent: ScalarsEnums["Boolean"];
  displayPostContentRadio: ScalarsEnums["String"];
  displayPostDate: ScalarsEnums["Boolean"];
  excerptLength: ScalarsEnums["Float"];
  featuredImageAlign?: Maybe<ScalarsEnums["String"]>;
  featuredImageSizeHeight?: Maybe<ScalarsEnums["Float"]>;
  featuredImageSizeSlug: ScalarsEnums["String"];
  featuredImageSizeWidth?: Maybe<ScalarsEnums["Float"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  order: ScalarsEnums["String"];
  orderBy: ScalarsEnums["String"];
  postLayout: ScalarsEnums["String"];
  postsToShow: ScalarsEnums["Float"];
  selectedAuthor?: Maybe<ScalarsEnums["Float"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/legacy-widget block
 */
export interface CoreLegacyWidgetBlock {
  __typename?: "CoreLegacyWidgetBlock";
  attributes?: Maybe<CoreLegacyWidgetBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreLegacyWidgetBlockAttributes {
  __typename?: "CoreLegacyWidgetBlockAttributes";
  className?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["String"]>;
  idBase?: Maybe<ScalarsEnums["String"]>;
  instance?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

/**
 * core/list block
 */
export interface CoreListBlock {
  __typename?: "CoreListBlock";
  attributes?: Maybe<CoreListBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreListBlockAttributes {
  __typename?: "CoreListBlockAttributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  ordered: ScalarsEnums["Boolean"];
  placeholder?: Maybe<ScalarsEnums["String"]>;
  reversed?: Maybe<ScalarsEnums["Boolean"]>;
  start?: Maybe<ScalarsEnums["Float"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  type?: Maybe<ScalarsEnums["String"]>;
  values: ScalarsEnums["String"];
}

export interface CoreListBlockAttributesUnion {
  __typename?:
    | "CoreListBlockAttributes"
    | "CoreListBlockDeprecatedV1Attributes"
    | "CoreListBlockDeprecatedV2Attributes";
  $on: $CoreListBlockAttributesUnion;
}

export interface CoreListBlockDeprecatedV1Attributes {
  __typename?: "CoreListBlockDeprecatedV1Attributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  ordered: ScalarsEnums["Boolean"];
  placeholder?: Maybe<ScalarsEnums["String"]>;
  reversed?: Maybe<ScalarsEnums["Boolean"]>;
  start?: Maybe<ScalarsEnums["Float"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  type?: Maybe<ScalarsEnums["String"]>;
  values: ScalarsEnums["String"];
}

export interface CoreListBlockDeprecatedV2Attributes {
  __typename?: "CoreListBlockDeprecatedV2Attributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  ordered: ScalarsEnums["Boolean"];
  placeholder?: Maybe<ScalarsEnums["String"]>;
  reversed?: Maybe<ScalarsEnums["Boolean"]>;
  start?: Maybe<ScalarsEnums["Float"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  type?: Maybe<ScalarsEnums["String"]>;
  values: ScalarsEnums["String"];
}

/**
 * core/list-item block
 */
export interface CoreListItemBlock {
  __typename?: "CoreListItemBlock";
  attributes?: Maybe<CoreListItemBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreListItemBlockAttributes {
  __typename?: "CoreListItemBlockAttributes";
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

/**
 * core/loginout block
 */
export interface CoreLoginoutBlock {
  __typename?: "CoreLoginoutBlock";
  attributes?: Maybe<CoreLoginoutBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreLoginoutBlockAttributes {
  __typename?: "CoreLoginoutBlockAttributes";
  className?: Maybe<ScalarsEnums["String"]>;
  displayLoginAsForm: ScalarsEnums["Boolean"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  redirectToCurrent: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

/**
 * core/media-text block
 */
export interface CoreMediaTextBlock {
  __typename?: "CoreMediaTextBlock";
  attributes?: Maybe<CoreMediaTextBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreMediaTextBlockAttributes {
  __typename?: "CoreMediaTextBlockAttributes";
  align: ScalarsEnums["String"];
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  imageFill?: Maybe<ScalarsEnums["Boolean"]>;
  isStackedOnMobile: ScalarsEnums["Boolean"];
  linkClass?: Maybe<ScalarsEnums["String"]>;
  linkDestination?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  mediaAlt: ScalarsEnums["String"];
  mediaId?: Maybe<ScalarsEnums["Float"]>;
  mediaLink?: Maybe<ScalarsEnums["String"]>;
  mediaPosition: ScalarsEnums["String"];
  mediaSizeSlug?: Maybe<ScalarsEnums["String"]>;
  mediaType?: Maybe<ScalarsEnums["String"]>;
  mediaUrl?: Maybe<ScalarsEnums["String"]>;
  mediaWidth: ScalarsEnums["Float"];
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  verticalAlignment?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreMediaTextBlockAttributesUnion {
  __typename?:
    | "CoreMediaTextBlockAttributes"
    | "CoreMediaTextBlockDeprecatedV1Attributes"
    | "CoreMediaTextBlockDeprecatedV2Attributes"
    | "CoreMediaTextBlockDeprecatedV3Attributes"
    | "CoreMediaTextBlockDeprecatedV4Attributes"
    | "CoreMediaTextBlockDeprecatedV5Attributes"
    | "CoreMediaTextBlockDeprecatedV6Attributes";
  $on: $CoreMediaTextBlockAttributesUnion;
}

export interface CoreMediaTextBlockDeprecatedV1Attributes {
  __typename?: "CoreMediaTextBlockDeprecatedV1Attributes";
  align: ScalarsEnums["String"];
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  imageFill?: Maybe<ScalarsEnums["Boolean"]>;
  isStackedOnMobile: ScalarsEnums["Boolean"];
  linkClass?: Maybe<ScalarsEnums["String"]>;
  linkDestination?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  mediaAlt: ScalarsEnums["String"];
  mediaId?: Maybe<ScalarsEnums["Float"]>;
  mediaLink?: Maybe<ScalarsEnums["String"]>;
  mediaPosition: ScalarsEnums["String"];
  mediaSizeSlug?: Maybe<ScalarsEnums["String"]>;
  mediaType?: Maybe<ScalarsEnums["String"]>;
  mediaUrl?: Maybe<ScalarsEnums["String"]>;
  mediaWidth: ScalarsEnums["Float"];
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  verticalAlignment?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreMediaTextBlockDeprecatedV2Attributes {
  __typename?: "CoreMediaTextBlockDeprecatedV2Attributes";
  align: ScalarsEnums["String"];
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  imageFill?: Maybe<ScalarsEnums["Boolean"]>;
  isStackedOnMobile: ScalarsEnums["Boolean"];
  linkClass?: Maybe<ScalarsEnums["String"]>;
  linkDestination?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  mediaAlt: ScalarsEnums["String"];
  mediaId?: Maybe<ScalarsEnums["Float"]>;
  mediaLink?: Maybe<ScalarsEnums["String"]>;
  mediaPosition: ScalarsEnums["String"];
  mediaSizeSlug?: Maybe<ScalarsEnums["String"]>;
  mediaType?: Maybe<ScalarsEnums["String"]>;
  mediaUrl?: Maybe<ScalarsEnums["String"]>;
  mediaWidth: ScalarsEnums["Float"];
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  verticalAlignment?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreMediaTextBlockDeprecatedV3Attributes {
  __typename?: "CoreMediaTextBlockDeprecatedV3Attributes";
  align: ScalarsEnums["String"];
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  imageFill?: Maybe<ScalarsEnums["Boolean"]>;
  isStackedOnMobile: ScalarsEnums["Boolean"];
  linkClass?: Maybe<ScalarsEnums["String"]>;
  linkDestination?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  mediaAlt: ScalarsEnums["String"];
  mediaId?: Maybe<ScalarsEnums["Float"]>;
  mediaLink?: Maybe<ScalarsEnums["String"]>;
  mediaPosition: ScalarsEnums["String"];
  mediaSizeSlug?: Maybe<ScalarsEnums["String"]>;
  mediaType?: Maybe<ScalarsEnums["String"]>;
  mediaUrl?: Maybe<ScalarsEnums["String"]>;
  mediaWidth: ScalarsEnums["Float"];
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  verticalAlignment?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreMediaTextBlockDeprecatedV4Attributes {
  __typename?: "CoreMediaTextBlockDeprecatedV4Attributes";
  align: ScalarsEnums["String"];
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  imageFill?: Maybe<ScalarsEnums["Boolean"]>;
  isStackedOnMobile: ScalarsEnums["Boolean"];
  linkClass?: Maybe<ScalarsEnums["String"]>;
  linkDestination?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  mediaAlt: ScalarsEnums["String"];
  mediaId?: Maybe<ScalarsEnums["Float"]>;
  mediaLink?: Maybe<ScalarsEnums["String"]>;
  mediaPosition: ScalarsEnums["String"];
  mediaSizeSlug?: Maybe<ScalarsEnums["String"]>;
  mediaType?: Maybe<ScalarsEnums["String"]>;
  mediaUrl?: Maybe<ScalarsEnums["String"]>;
  mediaWidth: ScalarsEnums["Float"];
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  verticalAlignment?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreMediaTextBlockDeprecatedV5Attributes {
  __typename?: "CoreMediaTextBlockDeprecatedV5Attributes";
  align: ScalarsEnums["String"];
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  imageFill?: Maybe<ScalarsEnums["Boolean"]>;
  isStackedOnMobile: ScalarsEnums["Boolean"];
  linkClass?: Maybe<ScalarsEnums["String"]>;
  linkDestination?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  mediaAlt: ScalarsEnums["String"];
  mediaId?: Maybe<ScalarsEnums["Float"]>;
  mediaLink?: Maybe<ScalarsEnums["String"]>;
  mediaPosition: ScalarsEnums["String"];
  mediaSizeSlug?: Maybe<ScalarsEnums["String"]>;
  mediaType?: Maybe<ScalarsEnums["String"]>;
  mediaUrl?: Maybe<ScalarsEnums["String"]>;
  mediaWidth: ScalarsEnums["Float"];
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  verticalAlignment?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreMediaTextBlockDeprecatedV6Attributes {
  __typename?: "CoreMediaTextBlockDeprecatedV6Attributes";
  align: ScalarsEnums["String"];
  allowedBlocks?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  focalPoint?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  href?: Maybe<ScalarsEnums["String"]>;
  imageFill?: Maybe<ScalarsEnums["Boolean"]>;
  isStackedOnMobile: ScalarsEnums["Boolean"];
  linkClass?: Maybe<ScalarsEnums["String"]>;
  linkDestination?: Maybe<ScalarsEnums["String"]>;
  linkTarget?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  mediaAlt: ScalarsEnums["String"];
  mediaId?: Maybe<ScalarsEnums["Float"]>;
  mediaLink?: Maybe<ScalarsEnums["String"]>;
  mediaPosition: ScalarsEnums["String"];
  mediaSizeSlug?: Maybe<ScalarsEnums["String"]>;
  mediaType?: Maybe<ScalarsEnums["String"]>;
  mediaUrl?: Maybe<ScalarsEnums["String"]>;
  mediaWidth: ScalarsEnums["Float"];
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  verticalAlignment?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/missing block
 */
export interface CoreMissingBlock {
  __typename?: "CoreMissingBlock";
  attributes?: Maybe<CoreMissingBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreMissingBlockAttributes {
  __typename?: "CoreMissingBlockAttributes";
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  originalContent?: Maybe<ScalarsEnums["String"]>;
  originalName?: Maybe<ScalarsEnums["String"]>;
  originalUndelimitedContent?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/more block
 */
export interface CoreMoreBlock {
  __typename?: "CoreMoreBlock";
  attributes?: Maybe<CoreMoreBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreMoreBlockAttributes {
  __typename?: "CoreMoreBlockAttributes";
  customText?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  noTeaser: ScalarsEnums["Boolean"];
}

/**
 * core/navigation block
 */
export interface CoreNavigationBlock {
  __typename?: "CoreNavigationBlock";
  attributes?: Maybe<CoreNavigationBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreNavigationBlockAttributes {
  __typename?: "CoreNavigationBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  customBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  customOverlayBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  customOverlayTextColor?: Maybe<ScalarsEnums["String"]>;
  customTextColor?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  hasIcon: ScalarsEnums["Boolean"];
  icon: ScalarsEnums["String"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  maxNestingLevel: ScalarsEnums["Float"];
  openSubmenusOnClick: ScalarsEnums["Boolean"];
  overlayBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  overlayMenu: ScalarsEnums["String"];
  overlayTextColor?: Maybe<ScalarsEnums["String"]>;
  ref?: Maybe<ScalarsEnums["Float"]>;
  rgbBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  rgbTextColor?: Maybe<ScalarsEnums["String"]>;
  showSubmenuIcon: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  unstableLocation?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreNavigationBlockAttributesUnion {
  __typename?:
    | "CoreNavigationBlockAttributes"
    | "CoreNavigationBlockDeprecatedV1Attributes"
    | "CoreNavigationBlockDeprecatedV2Attributes"
    | "CoreNavigationBlockDeprecatedV3Attributes"
    | "CoreNavigationBlockDeprecatedV4Attributes"
    | "CoreNavigationBlockDeprecatedV5Attributes"
    | "CoreNavigationBlockDeprecatedV6Attributes";
  $on: $CoreNavigationBlockAttributesUnion;
}

export interface CoreNavigationBlockDeprecatedV1Attributes {
  __typename?: "CoreNavigationBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  customBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  customOverlayBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  customOverlayTextColor?: Maybe<ScalarsEnums["String"]>;
  customTextColor?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  hasIcon: ScalarsEnums["Boolean"];
  icon: ScalarsEnums["String"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  maxNestingLevel: ScalarsEnums["Float"];
  openSubmenusOnClick: ScalarsEnums["Boolean"];
  overlayBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  overlayMenu: ScalarsEnums["String"];
  overlayTextColor?: Maybe<ScalarsEnums["String"]>;
  ref?: Maybe<ScalarsEnums["Float"]>;
  rgbBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  rgbTextColor?: Maybe<ScalarsEnums["String"]>;
  showSubmenuIcon: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  unstableLocation?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreNavigationBlockDeprecatedV2Attributes {
  __typename?: "CoreNavigationBlockDeprecatedV2Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  customBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  customOverlayBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  customOverlayTextColor?: Maybe<ScalarsEnums["String"]>;
  customTextColor?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  hasIcon: ScalarsEnums["Boolean"];
  icon: ScalarsEnums["String"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  maxNestingLevel: ScalarsEnums["Float"];
  openSubmenusOnClick: ScalarsEnums["Boolean"];
  overlayBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  overlayMenu: ScalarsEnums["String"];
  overlayTextColor?: Maybe<ScalarsEnums["String"]>;
  ref?: Maybe<ScalarsEnums["Float"]>;
  rgbBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  rgbTextColor?: Maybe<ScalarsEnums["String"]>;
  showSubmenuIcon: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  unstableLocation?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreNavigationBlockDeprecatedV3Attributes {
  __typename?: "CoreNavigationBlockDeprecatedV3Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  customBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  customOverlayBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  customOverlayTextColor?: Maybe<ScalarsEnums["String"]>;
  customTextColor?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  hasIcon: ScalarsEnums["Boolean"];
  icon: ScalarsEnums["String"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  maxNestingLevel: ScalarsEnums["Float"];
  openSubmenusOnClick: ScalarsEnums["Boolean"];
  overlayBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  overlayMenu: ScalarsEnums["String"];
  overlayTextColor?: Maybe<ScalarsEnums["String"]>;
  ref?: Maybe<ScalarsEnums["Float"]>;
  rgbBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  rgbTextColor?: Maybe<ScalarsEnums["String"]>;
  showSubmenuIcon: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  unstableLocation?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreNavigationBlockDeprecatedV4Attributes {
  __typename?: "CoreNavigationBlockDeprecatedV4Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  customBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  customOverlayBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  customOverlayTextColor?: Maybe<ScalarsEnums["String"]>;
  customTextColor?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  hasIcon: ScalarsEnums["Boolean"];
  icon: ScalarsEnums["String"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  maxNestingLevel: ScalarsEnums["Float"];
  openSubmenusOnClick: ScalarsEnums["Boolean"];
  overlayBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  overlayMenu: ScalarsEnums["String"];
  overlayTextColor?: Maybe<ScalarsEnums["String"]>;
  ref?: Maybe<ScalarsEnums["Float"]>;
  rgbBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  rgbTextColor?: Maybe<ScalarsEnums["String"]>;
  showSubmenuIcon: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  unstableLocation?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreNavigationBlockDeprecatedV5Attributes {
  __typename?: "CoreNavigationBlockDeprecatedV5Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  customBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  customOverlayBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  customOverlayTextColor?: Maybe<ScalarsEnums["String"]>;
  customTextColor?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  hasIcon: ScalarsEnums["Boolean"];
  icon: ScalarsEnums["String"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  maxNestingLevel: ScalarsEnums["Float"];
  openSubmenusOnClick: ScalarsEnums["Boolean"];
  overlayBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  overlayMenu: ScalarsEnums["String"];
  overlayTextColor?: Maybe<ScalarsEnums["String"]>;
  ref?: Maybe<ScalarsEnums["Float"]>;
  rgbBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  rgbTextColor?: Maybe<ScalarsEnums["String"]>;
  showSubmenuIcon: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  unstableLocation?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreNavigationBlockDeprecatedV6Attributes {
  __typename?: "CoreNavigationBlockDeprecatedV6Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  customBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  customOverlayBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  customOverlayTextColor?: Maybe<ScalarsEnums["String"]>;
  customTextColor?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  hasIcon: ScalarsEnums["Boolean"];
  icon: ScalarsEnums["String"];
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  maxNestingLevel: ScalarsEnums["Float"];
  openSubmenusOnClick: ScalarsEnums["Boolean"];
  overlayBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  overlayMenu: ScalarsEnums["String"];
  overlayTextColor?: Maybe<ScalarsEnums["String"]>;
  ref?: Maybe<ScalarsEnums["Float"]>;
  rgbBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  rgbTextColor?: Maybe<ScalarsEnums["String"]>;
  showSubmenuIcon: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  unstableLocation?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/navigation-link block
 */
export interface CoreNavigationLinkBlock {
  __typename?: "CoreNavigationLinkBlock";
  attributes?: Maybe<CoreNavigationLinkBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreNavigationLinkBlockAttributes {
  __typename?: "CoreNavigationLinkBlockAttributes";
  className?: Maybe<ScalarsEnums["String"]>;
  description?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  isTopLevelLink?: Maybe<ScalarsEnums["Boolean"]>;
  kind?: Maybe<ScalarsEnums["String"]>;
  label?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  opensInNewTab: ScalarsEnums["Boolean"];
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  type?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreNavigationLinkBlockAttributesUnion {
  __typename?:
    | "CoreNavigationLinkBlockAttributes"
    | "CoreNavigationLinkBlockDeprecatedV1Attributes";
  $on: $CoreNavigationLinkBlockAttributesUnion;
}

export interface CoreNavigationLinkBlockDeprecatedV1Attributes {
  __typename?: "CoreNavigationLinkBlockDeprecatedV1Attributes";
  className?: Maybe<ScalarsEnums["String"]>;
  description?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  isTopLevelLink?: Maybe<ScalarsEnums["Boolean"]>;
  kind?: Maybe<ScalarsEnums["String"]>;
  label?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  opensInNewTab: ScalarsEnums["Boolean"];
  rel?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  type?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/navigation-submenu block
 */
export interface CoreNavigationSubmenuBlock {
  __typename?: "CoreNavigationSubmenuBlock";
  attributes?: Maybe<CoreNavigationSubmenuBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreNavigationSubmenuBlockAttributes {
  __typename?: "CoreNavigationSubmenuBlockAttributes";
  className?: Maybe<ScalarsEnums["String"]>;
  description?: Maybe<ScalarsEnums["String"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  isTopLevelItem?: Maybe<ScalarsEnums["Boolean"]>;
  kind?: Maybe<ScalarsEnums["String"]>;
  label?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  opensInNewTab: ScalarsEnums["Boolean"];
  rel?: Maybe<ScalarsEnums["String"]>;
  title?: Maybe<ScalarsEnums["String"]>;
  type?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/nextpage block
 */
export interface CoreNextpageBlock {
  __typename?: "CoreNextpageBlock";
  attributes?: Maybe<CoreNextpageBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreNextpageBlockAttributes {
  __typename?: "CoreNextpageBlockAttributes";
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

/**
 * core/page-list block
 */
export interface CorePageListBlock {
  __typename?: "CorePageListBlock";
  attributes?: Maybe<CorePageListBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePageListBlockAttributes {
  __typename?: "CorePageListBlockAttributes";
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  isNested: ScalarsEnums["Boolean"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  parentPageID: ScalarsEnums["Int"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

/**
 * core/page-list-item block
 */
export interface CorePageListItemBlock {
  __typename?: "CorePageListItemBlock";
  attributes?: Maybe<CorePageListItemBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePageListItemBlockAttributes {
  __typename?: "CorePageListItemBlockAttributes";
  className?: Maybe<ScalarsEnums["String"]>;
  hasChildren?: Maybe<ScalarsEnums["Boolean"]>;
  id?: Maybe<ScalarsEnums["Float"]>;
  label?: Maybe<ScalarsEnums["String"]>;
  link?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  title?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/paragraph block
 */
export interface CoreParagraphBlock {
  __typename?: "CoreParagraphBlock";
  attributes?: Maybe<CoreParagraphBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreParagraphBlockAttributes {
  __typename?: "CoreParagraphBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  direction?: Maybe<ScalarsEnums["String"]>;
  dropCap: ScalarsEnums["Boolean"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreParagraphBlockAttributesUnion {
  __typename?:
    | "CoreParagraphBlockAttributes"
    | "CoreParagraphBlockDeprecatedV1Attributes"
    | "CoreParagraphBlockDeprecatedV2Attributes"
    | "CoreParagraphBlockDeprecatedV3Attributes"
    | "CoreParagraphBlockDeprecatedV4Attributes"
    | "CoreParagraphBlockDeprecatedV5Attributes"
    | "CoreParagraphBlockDeprecatedV6Attributes";
  $on: $CoreParagraphBlockAttributesUnion;
}

export interface CoreParagraphBlockDeprecatedV1Attributes {
  __typename?: "CoreParagraphBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  direction?: Maybe<ScalarsEnums["String"]>;
  dropCap: ScalarsEnums["Boolean"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreParagraphBlockDeprecatedV2Attributes {
  __typename?: "CoreParagraphBlockDeprecatedV2Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  direction?: Maybe<ScalarsEnums["String"]>;
  dropCap: ScalarsEnums["Boolean"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreParagraphBlockDeprecatedV3Attributes {
  __typename?: "CoreParagraphBlockDeprecatedV3Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  direction?: Maybe<ScalarsEnums["String"]>;
  dropCap: ScalarsEnums["Boolean"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreParagraphBlockDeprecatedV4Attributes {
  __typename?: "CoreParagraphBlockDeprecatedV4Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  direction?: Maybe<ScalarsEnums["String"]>;
  dropCap: ScalarsEnums["Boolean"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreParagraphBlockDeprecatedV5Attributes {
  __typename?: "CoreParagraphBlockDeprecatedV5Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  direction?: Maybe<ScalarsEnums["String"]>;
  dropCap: ScalarsEnums["Boolean"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreParagraphBlockDeprecatedV6Attributes {
  __typename?: "CoreParagraphBlockDeprecatedV6Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  direction?: Maybe<ScalarsEnums["String"]>;
  dropCap: ScalarsEnums["Boolean"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/pattern block
 */
export interface CorePatternBlock {
  __typename?: "CorePatternBlock";
  attributes?: Maybe<CorePatternBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePatternBlockAttributes {
  __typename?: "CorePatternBlockAttributes";
  className?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  slug?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/post-author-biography block
 */
export interface CorePostAuthorBiographyBlock {
  __typename?: "CorePostAuthorBiographyBlock";
  attributes?: Maybe<CorePostAuthorBiographyBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePostAuthorBiographyBlockAttributes {
  __typename?: "CorePostAuthorBiographyBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/post-author block
 */
export interface CorePostAuthorBlock {
  __typename?: "CorePostAuthorBlock";
  attributes?: Maybe<CorePostAuthorBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePostAuthorBlockAttributes {
  __typename?: "CorePostAuthorBlockAttributes";
  avatarSize: ScalarsEnums["Float"];
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  byline?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  isLink: ScalarsEnums["Boolean"];
  linkTarget: ScalarsEnums["String"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  showAvatar: ScalarsEnums["Boolean"];
  showBio?: Maybe<ScalarsEnums["Boolean"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/post-author-name block
 */
export interface CorePostAuthorNameBlock {
  __typename?: "CorePostAuthorNameBlock";
  attributes?: Maybe<CorePostAuthorNameBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePostAuthorNameBlockAttributes {
  __typename?: "CorePostAuthorNameBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  isLink: ScalarsEnums["Boolean"];
  linkTarget: ScalarsEnums["String"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/post-comments-form block
 */
export interface CorePostCommentsFormBlock {
  __typename?: "CorePostCommentsFormBlock";
  attributes?: Maybe<CorePostCommentsFormBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePostCommentsFormBlockAttributes {
  __typename?: "CorePostCommentsFormBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/post-content block
 */
export interface CorePostContentBlock {
  __typename?: "CorePostContentBlock";
  attributes?: Maybe<CorePostContentBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePostContentBlockAttributes {
  __typename?: "CorePostContentBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

/**
 * core/post-date block
 */
export interface CorePostDateBlock {
  __typename?: "CorePostDateBlock";
  attributes?: Maybe<CorePostDateBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePostDateBlockAttributes {
  __typename?: "CorePostDateBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  displayType: ScalarsEnums["String"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  format?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  isLink: ScalarsEnums["Boolean"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePostDateBlockAttributesUnion {
  __typename?:
    | "CorePostDateBlockAttributes"
    | "CorePostDateBlockDeprecatedV1Attributes";
  $on: $CorePostDateBlockAttributesUnion;
}

export interface CorePostDateBlockDeprecatedV1Attributes {
  __typename?: "CorePostDateBlockDeprecatedV1Attributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  displayType: ScalarsEnums["String"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  format?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  isLink: ScalarsEnums["Boolean"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/post-excerpt block
 */
export interface CorePostExcerptBlock {
  __typename?: "CorePostExcerptBlock";
  attributes?: Maybe<CorePostExcerptBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePostExcerptBlockAttributes {
  __typename?: "CorePostExcerptBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  excerptLength: ScalarsEnums["Float"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  moreText?: Maybe<ScalarsEnums["String"]>;
  showMoreOnNewLine: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/post-featured-image block
 */
export interface CorePostFeaturedImageBlock {
  __typename?: "CorePostFeaturedImageBlock";
  attributes?: Maybe<CorePostFeaturedImageBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePostFeaturedImageBlockAttributes {
  __typename?: "CorePostFeaturedImageBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  aspectRatio?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  customGradient?: Maybe<ScalarsEnums["String"]>;
  customOverlayColor?: Maybe<ScalarsEnums["String"]>;
  dimRatio: ScalarsEnums["Float"];
  gradient?: Maybe<ScalarsEnums["String"]>;
  height?: Maybe<ScalarsEnums["String"]>;
  isLink: ScalarsEnums["Boolean"];
  linkTarget: ScalarsEnums["String"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  overlayColor?: Maybe<ScalarsEnums["String"]>;
  rel: ScalarsEnums["String"];
  scale: ScalarsEnums["String"];
  sizeSlug?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  width?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/post-navigation-link block
 */
export interface CorePostNavigationLinkBlock {
  __typename?: "CorePostNavigationLinkBlock";
  attributes?: Maybe<CorePostNavigationLinkBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePostNavigationLinkBlockAttributes {
  __typename?: "CorePostNavigationLinkBlockAttributes";
  arrow: ScalarsEnums["String"];
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  label?: Maybe<ScalarsEnums["String"]>;
  linkLabel: ScalarsEnums["Boolean"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  showTitle: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  type: ScalarsEnums["String"];
}

/**
 * core/post-template block
 */
export interface CorePostTemplateBlock {
  __typename?: "CorePostTemplateBlock";
  attributes?: Maybe<CorePostTemplateBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePostTemplateBlockAttributes {
  __typename?: "CorePostTemplateBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/post-terms block
 */
export interface CorePostTermsBlock {
  __typename?: "CorePostTermsBlock";
  attributes?: Maybe<CorePostTermsBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePostTermsBlockAttributes {
  __typename?: "CorePostTermsBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  prefix: ScalarsEnums["String"];
  separator: ScalarsEnums["String"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  suffix: ScalarsEnums["String"];
  term?: Maybe<ScalarsEnums["String"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/post-title block
 */
export interface CorePostTitleBlock {
  __typename?: "CorePostTitleBlock";
  attributes?: Maybe<CorePostTitleBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePostTitleBlockAttributes {
  __typename?: "CorePostTitleBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  isLink: ScalarsEnums["Boolean"];
  level: ScalarsEnums["Float"];
  linkTarget: ScalarsEnums["String"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  rel: ScalarsEnums["String"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePostTitleBlockAttributesUnion {
  __typename?:
    | "CorePostTitleBlockAttributes"
    | "CorePostTitleBlockDeprecatedV1Attributes";
  $on: $CorePostTitleBlockAttributesUnion;
}

export interface CorePostTitleBlockDeprecatedV1Attributes {
  __typename?: "CorePostTitleBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  isLink: ScalarsEnums["Boolean"];
  level: ScalarsEnums["Float"];
  linkTarget: ScalarsEnums["String"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  rel: ScalarsEnums["String"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/preformatted block
 */
export interface CorePreformattedBlock {
  __typename?: "CorePreformattedBlock";
  attributes?: Maybe<CorePreformattedBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePreformattedBlockAttributes {
  __typename?: "CorePreformattedBlockAttributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/pullquote block
 */
export interface CorePullquoteBlock {
  __typename?: "CorePullquoteBlock";
  attributes?: Maybe<CorePullquoteBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePullquoteBlockAttributes {
  __typename?: "CorePullquoteBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  citation: ScalarsEnums["String"];
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  value?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePullquoteBlockAttributesUnion {
  __typename?:
    | "CorePullquoteBlockAttributes"
    | "CorePullquoteBlockDeprecatedV1Attributes"
    | "CorePullquoteBlockDeprecatedV2Attributes"
    | "CorePullquoteBlockDeprecatedV3Attributes"
    | "CorePullquoteBlockDeprecatedV4Attributes"
    | "CorePullquoteBlockDeprecatedV5Attributes"
    | "CorePullquoteBlockDeprecatedV6Attributes";
  $on: $CorePullquoteBlockAttributesUnion;
}

export interface CorePullquoteBlockDeprecatedV1Attributes {
  __typename?: "CorePullquoteBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  citation: ScalarsEnums["String"];
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  value?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePullquoteBlockDeprecatedV2Attributes {
  __typename?: "CorePullquoteBlockDeprecatedV2Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  citation: ScalarsEnums["String"];
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  value?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePullquoteBlockDeprecatedV3Attributes {
  __typename?: "CorePullquoteBlockDeprecatedV3Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  citation: ScalarsEnums["String"];
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  value?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePullquoteBlockDeprecatedV4Attributes {
  __typename?: "CorePullquoteBlockDeprecatedV4Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  citation: ScalarsEnums["String"];
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  value?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePullquoteBlockDeprecatedV5Attributes {
  __typename?: "CorePullquoteBlockDeprecatedV5Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  citation: ScalarsEnums["String"];
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  value?: Maybe<ScalarsEnums["String"]>;
}

export interface CorePullquoteBlockDeprecatedV6Attributes {
  __typename?: "CorePullquoteBlockDeprecatedV6Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  citation: ScalarsEnums["String"];
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/query block
 */
export interface CoreQueryBlock {
  __typename?: "CoreQueryBlock";
  attributes?: Maybe<CoreQueryBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreQueryBlockAttributes {
  __typename?: "CoreQueryBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  namespace?: Maybe<ScalarsEnums["String"]>;
  query: ScalarsEnums["BlockAttributesObject"];
  queryId?: Maybe<ScalarsEnums["Float"]>;
  tagName: ScalarsEnums["String"];
}

export interface CoreQueryBlockAttributesUnion {
  __typename?:
    | "CoreQueryBlockAttributes"
    | "CoreQueryBlockDeprecatedV1Attributes"
    | "CoreQueryBlockDeprecatedV2Attributes"
    | "CoreQueryBlockDeprecatedV3Attributes"
    | "CoreQueryBlockDeprecatedV4Attributes"
    | "CoreQueryBlockDeprecatedV5Attributes";
  $on: $CoreQueryBlockAttributesUnion;
}

export interface CoreQueryBlockDeprecatedV1Attributes {
  __typename?: "CoreQueryBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  namespace?: Maybe<ScalarsEnums["String"]>;
  query: ScalarsEnums["BlockAttributesObject"];
  queryId?: Maybe<ScalarsEnums["Float"]>;
  tagName: ScalarsEnums["String"];
}

export interface CoreQueryBlockDeprecatedV2Attributes {
  __typename?: "CoreQueryBlockDeprecatedV2Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  namespace?: Maybe<ScalarsEnums["String"]>;
  query: ScalarsEnums["BlockAttributesObject"];
  queryId?: Maybe<ScalarsEnums["Float"]>;
  tagName: ScalarsEnums["String"];
}

export interface CoreQueryBlockDeprecatedV3Attributes {
  __typename?: "CoreQueryBlockDeprecatedV3Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  namespace?: Maybe<ScalarsEnums["String"]>;
  query: ScalarsEnums["BlockAttributesObject"];
  queryId?: Maybe<ScalarsEnums["Float"]>;
  tagName: ScalarsEnums["String"];
}

export interface CoreQueryBlockDeprecatedV4Attributes {
  __typename?: "CoreQueryBlockDeprecatedV4Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  namespace?: Maybe<ScalarsEnums["String"]>;
  query: ScalarsEnums["BlockAttributesObject"];
  queryId?: Maybe<ScalarsEnums["Float"]>;
  tagName: ScalarsEnums["String"];
}

export interface CoreQueryBlockDeprecatedV5Attributes {
  __typename?: "CoreQueryBlockDeprecatedV5Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  namespace?: Maybe<ScalarsEnums["String"]>;
  query: ScalarsEnums["BlockAttributesObject"];
  queryId?: Maybe<ScalarsEnums["Float"]>;
  tagName: ScalarsEnums["String"];
}

/**
 * core/query-no-results block
 */
export interface CoreQueryNoResultsBlock {
  __typename?: "CoreQueryNoResultsBlock";
  attributes?: Maybe<CoreQueryNoResultsBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreQueryNoResultsBlockAttributes {
  __typename?: "CoreQueryNoResultsBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/query-pagination block
 */
export interface CoreQueryPaginationBlock {
  __typename?: "CoreQueryPaginationBlock";
  attributes?: Maybe<CoreQueryPaginationBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreQueryPaginationBlockAttributes {
  __typename?: "CoreQueryPaginationBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  paginationArrow: ScalarsEnums["String"];
  showLabel: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreQueryPaginationBlockAttributesUnion {
  __typename?:
    | "CoreQueryPaginationBlockAttributes"
    | "CoreQueryPaginationBlockDeprecatedV1Attributes";
  $on: $CoreQueryPaginationBlockAttributesUnion;
}

export interface CoreQueryPaginationBlockDeprecatedV1Attributes {
  __typename?: "CoreQueryPaginationBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  paginationArrow: ScalarsEnums["String"];
  showLabel: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/query-pagination-next block
 */
export interface CoreQueryPaginationNextBlock {
  __typename?: "CoreQueryPaginationNextBlock";
  attributes?: Maybe<CoreQueryPaginationNextBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreQueryPaginationNextBlockAttributes {
  __typename?: "CoreQueryPaginationNextBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  label?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/query-pagination-numbers block
 */
export interface CoreQueryPaginationNumbersBlock {
  __typename?: "CoreQueryPaginationNumbersBlock";
  attributes?: Maybe<CoreQueryPaginationNumbersBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreQueryPaginationNumbersBlockAttributes {
  __typename?: "CoreQueryPaginationNumbersBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/query-pagination-previous block
 */
export interface CoreQueryPaginationPreviousBlock {
  __typename?: "CoreQueryPaginationPreviousBlock";
  attributes?: Maybe<CoreQueryPaginationPreviousBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreQueryPaginationPreviousBlockAttributes {
  __typename?: "CoreQueryPaginationPreviousBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  label?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/query-title block
 */
export interface CoreQueryTitleBlock {
  __typename?: "CoreQueryTitleBlock";
  attributes?: Maybe<CoreQueryTitleBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreQueryTitleBlockAttributes {
  __typename?: "CoreQueryTitleBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  level: ScalarsEnums["Float"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  showPrefix: ScalarsEnums["Boolean"];
  showSearchTerm: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  type?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreQueryTitleBlockAttributesUnion {
  __typename?:
    | "CoreQueryTitleBlockAttributes"
    | "CoreQueryTitleBlockDeprecatedV1Attributes";
  $on: $CoreQueryTitleBlockAttributesUnion;
}

export interface CoreQueryTitleBlockDeprecatedV1Attributes {
  __typename?: "CoreQueryTitleBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  level: ScalarsEnums["Float"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  showPrefix: ScalarsEnums["Boolean"];
  showSearchTerm: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  type?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/quote block
 */
export interface CoreQuoteBlock {
  __typename?: "CoreQuoteBlock";
  attributes?: Maybe<CoreQuoteBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreQuoteBlockAttributes {
  __typename?: "CoreQuoteBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  citation: ScalarsEnums["String"];
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  value: ScalarsEnums["String"];
}

export interface CoreQuoteBlockAttributesUnion {
  __typename?:
    | "CoreQuoteBlockAttributes"
    | "CoreQuoteBlockDeprecatedV1Attributes"
    | "CoreQuoteBlockDeprecatedV2Attributes"
    | "CoreQuoteBlockDeprecatedV3Attributes"
    | "CoreQuoteBlockDeprecatedV4Attributes";
  $on: $CoreQuoteBlockAttributesUnion;
}

export interface CoreQuoteBlockDeprecatedV1Attributes {
  __typename?: "CoreQuoteBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  citation: ScalarsEnums["String"];
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  value: ScalarsEnums["String"];
}

export interface CoreQuoteBlockDeprecatedV2Attributes {
  __typename?: "CoreQuoteBlockDeprecatedV2Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  citation: ScalarsEnums["String"];
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  value: ScalarsEnums["String"];
}

export interface CoreQuoteBlockDeprecatedV3Attributes {
  __typename?: "CoreQuoteBlockDeprecatedV3Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  citation: ScalarsEnums["String"];
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  value: ScalarsEnums["String"];
}

export interface CoreQuoteBlockDeprecatedV4Attributes {
  __typename?: "CoreQuoteBlockDeprecatedV4Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  citation: ScalarsEnums["String"];
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  value: ScalarsEnums["String"];
}

/**
 * core/read-more block
 */
export interface CoreReadMoreBlock {
  __typename?: "CoreReadMoreBlock";
  attributes?: Maybe<CoreReadMoreBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreReadMoreBlockAttributes {
  __typename?: "CoreReadMoreBlockAttributes";
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  linkTarget: ScalarsEnums["String"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/rss block
 */
export interface CoreRssBlock {
  __typename?: "CoreRssBlock";
  attributes?: Maybe<CoreRssBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreRssBlockAttributes {
  __typename?: "CoreRssBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  blockLayout: ScalarsEnums["String"];
  className?: Maybe<ScalarsEnums["String"]>;
  columns: ScalarsEnums["Float"];
  displayAuthor: ScalarsEnums["Boolean"];
  displayDate: ScalarsEnums["Boolean"];
  displayExcerpt: ScalarsEnums["Boolean"];
  excerptLength: ScalarsEnums["Float"];
  feedURL: ScalarsEnums["String"];
  itemsToShow: ScalarsEnums["Float"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

/**
 * core/search block
 */
export interface CoreSearchBlock {
  __typename?: "CoreSearchBlock";
  attributes?: Maybe<CoreSearchBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreSearchBlockAttributes {
  __typename?: "CoreSearchBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  buttonBehavior: ScalarsEnums["String"];
  buttonPosition: ScalarsEnums["String"];
  buttonText?: Maybe<ScalarsEnums["String"]>;
  buttonUseIcon: ScalarsEnums["Boolean"];
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  isSearchFieldHidden: ScalarsEnums["Boolean"];
  label?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  placeholder: ScalarsEnums["String"];
  query: ScalarsEnums["BlockAttributesObject"];
  showLabel: ScalarsEnums["Boolean"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
  widthUnit?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/separator block
 */
export interface CoreSeparatorBlock {
  __typename?: "CoreSeparatorBlock";
  attributes?: Maybe<CoreSeparatorBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreSeparatorBlockAttributes {
  __typename?: "CoreSeparatorBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  opacity: ScalarsEnums["String"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreSeparatorBlockAttributesUnion {
  __typename?:
    | "CoreSeparatorBlockAttributes"
    | "CoreSeparatorBlockDeprecatedV1Attributes";
  $on: $CoreSeparatorBlockAttributesUnion;
}

export interface CoreSeparatorBlockDeprecatedV1Attributes {
  __typename?: "CoreSeparatorBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  opacity: ScalarsEnums["String"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/shortcode block
 */
export interface CoreShortcodeBlock {
  __typename?: "CoreShortcodeBlock";
  attributes?: Maybe<CoreShortcodeBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreShortcodeBlockAttributes {
  __typename?: "CoreShortcodeBlockAttributes";
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  text?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/site-logo block
 */
export interface CoreSiteLogoBlock {
  __typename?: "CoreSiteLogoBlock";
  attributes?: Maybe<CoreSiteLogoBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreSiteLogoBlockAttributes {
  __typename?: "CoreSiteLogoBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  isLink: ScalarsEnums["Boolean"];
  linkTarget: ScalarsEnums["String"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  shouldSyncIcon?: Maybe<ScalarsEnums["Boolean"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  width?: Maybe<ScalarsEnums["Float"]>;
}

/**
 * core/site-tagline block
 */
export interface CoreSiteTaglineBlock {
  __typename?: "CoreSiteTaglineBlock";
  attributes?: Maybe<CoreSiteTaglineBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreSiteTaglineBlockAttributes {
  __typename?: "CoreSiteTaglineBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreSiteTaglineBlockAttributesUnion {
  __typename?:
    | "CoreSiteTaglineBlockAttributes"
    | "CoreSiteTaglineBlockDeprecatedV1Attributes";
  $on: $CoreSiteTaglineBlockAttributesUnion;
}

export interface CoreSiteTaglineBlockDeprecatedV1Attributes {
  __typename?: "CoreSiteTaglineBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/site-title block
 */
export interface CoreSiteTitleBlock {
  __typename?: "CoreSiteTitleBlock";
  attributes?: Maybe<CoreSiteTitleBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreSiteTitleBlockAttributes {
  __typename?: "CoreSiteTitleBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  isLink: ScalarsEnums["Boolean"];
  level: ScalarsEnums["Float"];
  linkTarget: ScalarsEnums["String"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreSiteTitleBlockAttributesUnion {
  __typename?:
    | "CoreSiteTitleBlockAttributes"
    | "CoreSiteTitleBlockDeprecatedV1Attributes";
  $on: $CoreSiteTitleBlockAttributesUnion;
}

export interface CoreSiteTitleBlockDeprecatedV1Attributes {
  __typename?: "CoreSiteTitleBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  isLink: ScalarsEnums["Boolean"];
  level: ScalarsEnums["Float"];
  linkTarget: ScalarsEnums["String"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/social-link block
 */
export interface CoreSocialLinkBlock {
  __typename?: "CoreSocialLinkBlock";
  attributes?: Maybe<CoreSocialLinkBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreSocialLinkBlockAttributes {
  __typename?: "CoreSocialLinkBlockAttributes";
  className?: Maybe<ScalarsEnums["String"]>;
  label?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  rel?: Maybe<ScalarsEnums["String"]>;
  service?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/social-links block
 */
export interface CoreSocialLinksBlock {
  __typename?: "CoreSocialLinksBlock";
  attributes?: Maybe<CoreSocialLinksBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreSocialLinksBlockAttributes {
  __typename?: "CoreSocialLinksBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  customIconBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  customIconColor?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  iconBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  iconBackgroundColorValue?: Maybe<ScalarsEnums["String"]>;
  iconColor?: Maybe<ScalarsEnums["String"]>;
  iconColorValue?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  openInNewTab: ScalarsEnums["Boolean"];
  showLabels: ScalarsEnums["Boolean"];
  size?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreSocialLinksBlockAttributesUnion {
  __typename?:
    | "CoreSocialLinksBlockAttributes"
    | "CoreSocialLinksBlockDeprecatedV1Attributes";
  $on: $CoreSocialLinksBlockAttributesUnion;
}

export interface CoreSocialLinksBlockDeprecatedV1Attributes {
  __typename?: "CoreSocialLinksBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  customIconBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  customIconColor?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  iconBackgroundColor?: Maybe<ScalarsEnums["String"]>;
  iconBackgroundColorValue?: Maybe<ScalarsEnums["String"]>;
  iconColor?: Maybe<ScalarsEnums["String"]>;
  iconColorValue?: Maybe<ScalarsEnums["String"]>;
  layout?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  openInNewTab: ScalarsEnums["Boolean"];
  showLabels: ScalarsEnums["Boolean"];
  size?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/spacer block
 */
export interface CoreSpacerBlock {
  __typename?: "CoreSpacerBlock";
  attributes?: Maybe<CoreSpacerBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreSpacerBlockAttributes {
  __typename?: "CoreSpacerBlockAttributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  height: ScalarsEnums["String"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  width?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreSpacerBlockAttributesUnion {
  __typename?:
    | "CoreSpacerBlockAttributes"
    | "CoreSpacerBlockDeprecatedV1Attributes";
  $on: $CoreSpacerBlockAttributesUnion;
}

export interface CoreSpacerBlockDeprecatedV1Attributes {
  __typename?: "CoreSpacerBlockDeprecatedV1Attributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  height: ScalarsEnums["String"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  width?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/table block
 */
export interface CoreTableBlock {
  __typename?: "CoreTableBlock";
  attributes?: Maybe<CoreTableBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreTableBlockAttributes {
  __typename?: "CoreTableBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  body: Array<Maybe<CoreTableBlockAttributesBody>>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  caption: ScalarsEnums["String"];
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  foot: Array<Maybe<CoreTableBlockAttributesFoot>>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  hasFixedLayout: ScalarsEnums["Boolean"];
  head: Array<Maybe<CoreTableBlockAttributesHead>>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreTableBlockAttributesBody {
  __typename?: "CoreTableBlockAttributesBody";
  cells: Array<Maybe<CoreTableBlockAttributesBodyCells>>;
}

export interface CoreTableBlockAttributesBodyCells {
  __typename?: "CoreTableBlockAttributesBodyCells";
  align?: Maybe<ScalarsEnums["String"]>;
  colspan?: Maybe<ScalarsEnums["String"]>;
  content?: Maybe<ScalarsEnums["String"]>;
  rowspan?: Maybe<ScalarsEnums["String"]>;
  scope?: Maybe<ScalarsEnums["String"]>;
  tag: ScalarsEnums["String"];
}

export interface CoreTableBlockAttributesFoot {
  __typename?: "CoreTableBlockAttributesFoot";
  cells: Array<Maybe<CoreTableBlockAttributesFootCells>>;
}

export interface CoreTableBlockAttributesFootCells {
  __typename?: "CoreTableBlockAttributesFootCells";
  align?: Maybe<ScalarsEnums["String"]>;
  colspan?: Maybe<ScalarsEnums["String"]>;
  content?: Maybe<ScalarsEnums["String"]>;
  rowspan?: Maybe<ScalarsEnums["String"]>;
  scope?: Maybe<ScalarsEnums["String"]>;
  tag: ScalarsEnums["String"];
}

export interface CoreTableBlockAttributesHead {
  __typename?: "CoreTableBlockAttributesHead";
  cells: Array<Maybe<CoreTableBlockAttributesHeadCells>>;
}

export interface CoreTableBlockAttributesHeadCells {
  __typename?: "CoreTableBlockAttributesHeadCells";
  align?: Maybe<ScalarsEnums["String"]>;
  colspan?: Maybe<ScalarsEnums["String"]>;
  content?: Maybe<ScalarsEnums["String"]>;
  rowspan?: Maybe<ScalarsEnums["String"]>;
  scope?: Maybe<ScalarsEnums["String"]>;
  tag: ScalarsEnums["String"];
}

export interface CoreTableBlockAttributesUnion {
  __typename?:
    | "CoreTableBlockAttributes"
    | "CoreTableBlockDeprecatedV1Attributes"
    | "CoreTableBlockDeprecatedV2Attributes"
    | "CoreTableBlockDeprecatedV3Attributes";
  $on: $CoreTableBlockAttributesUnion;
}

export interface CoreTableBlockDeprecatedV1Attributes {
  __typename?: "CoreTableBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  body: Array<Maybe<CoreTableBlockDeprecatedV1AttributesBody>>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  caption: ScalarsEnums["String"];
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  foot: Array<Maybe<CoreTableBlockDeprecatedV1AttributesFoot>>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  hasFixedLayout: ScalarsEnums["Boolean"];
  head: Array<Maybe<CoreTableBlockDeprecatedV1AttributesHead>>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreTableBlockDeprecatedV1AttributesBody {
  __typename?: "CoreTableBlockDeprecatedV1AttributesBody";
  cells: Array<Maybe<CoreTableBlockDeprecatedV1AttributesBodyCells>>;
}

export interface CoreTableBlockDeprecatedV1AttributesBodyCells {
  __typename?: "CoreTableBlockDeprecatedV1AttributesBodyCells";
  align?: Maybe<ScalarsEnums["String"]>;
  colspan?: Maybe<ScalarsEnums["String"]>;
  content?: Maybe<ScalarsEnums["String"]>;
  rowspan?: Maybe<ScalarsEnums["String"]>;
  scope?: Maybe<ScalarsEnums["String"]>;
  tag: ScalarsEnums["String"];
}

export interface CoreTableBlockDeprecatedV1AttributesFoot {
  __typename?: "CoreTableBlockDeprecatedV1AttributesFoot";
  cells: Array<Maybe<CoreTableBlockDeprecatedV1AttributesFootCells>>;
}

export interface CoreTableBlockDeprecatedV1AttributesFootCells {
  __typename?: "CoreTableBlockDeprecatedV1AttributesFootCells";
  align?: Maybe<ScalarsEnums["String"]>;
  colspan?: Maybe<ScalarsEnums["String"]>;
  content?: Maybe<ScalarsEnums["String"]>;
  rowspan?: Maybe<ScalarsEnums["String"]>;
  scope?: Maybe<ScalarsEnums["String"]>;
  tag: ScalarsEnums["String"];
}

export interface CoreTableBlockDeprecatedV1AttributesHead {
  __typename?: "CoreTableBlockDeprecatedV1AttributesHead";
  cells: Array<Maybe<CoreTableBlockDeprecatedV1AttributesHeadCells>>;
}

export interface CoreTableBlockDeprecatedV1AttributesHeadCells {
  __typename?: "CoreTableBlockDeprecatedV1AttributesHeadCells";
  align?: Maybe<ScalarsEnums["String"]>;
  colspan?: Maybe<ScalarsEnums["String"]>;
  content?: Maybe<ScalarsEnums["String"]>;
  rowspan?: Maybe<ScalarsEnums["String"]>;
  scope?: Maybe<ScalarsEnums["String"]>;
  tag: ScalarsEnums["String"];
}

export interface CoreTableBlockDeprecatedV2Attributes {
  __typename?: "CoreTableBlockDeprecatedV2Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  body: Array<Maybe<CoreTableBlockDeprecatedV2AttributesBody>>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  caption: ScalarsEnums["String"];
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  foot: Array<Maybe<CoreTableBlockDeprecatedV2AttributesFoot>>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  hasFixedLayout: ScalarsEnums["Boolean"];
  head: Array<Maybe<CoreTableBlockDeprecatedV2AttributesHead>>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreTableBlockDeprecatedV2AttributesBody {
  __typename?: "CoreTableBlockDeprecatedV2AttributesBody";
  cells: Array<Maybe<CoreTableBlockDeprecatedV2AttributesBodyCells>>;
}

export interface CoreTableBlockDeprecatedV2AttributesBodyCells {
  __typename?: "CoreTableBlockDeprecatedV2AttributesBodyCells";
  align?: Maybe<ScalarsEnums["String"]>;
  colspan?: Maybe<ScalarsEnums["String"]>;
  content?: Maybe<ScalarsEnums["String"]>;
  rowspan?: Maybe<ScalarsEnums["String"]>;
  scope?: Maybe<ScalarsEnums["String"]>;
  tag: ScalarsEnums["String"];
}

export interface CoreTableBlockDeprecatedV2AttributesFoot {
  __typename?: "CoreTableBlockDeprecatedV2AttributesFoot";
  cells: Array<Maybe<CoreTableBlockDeprecatedV2AttributesFootCells>>;
}

export interface CoreTableBlockDeprecatedV2AttributesFootCells {
  __typename?: "CoreTableBlockDeprecatedV2AttributesFootCells";
  align?: Maybe<ScalarsEnums["String"]>;
  colspan?: Maybe<ScalarsEnums["String"]>;
  content?: Maybe<ScalarsEnums["String"]>;
  rowspan?: Maybe<ScalarsEnums["String"]>;
  scope?: Maybe<ScalarsEnums["String"]>;
  tag: ScalarsEnums["String"];
}

export interface CoreTableBlockDeprecatedV2AttributesHead {
  __typename?: "CoreTableBlockDeprecatedV2AttributesHead";
  cells: Array<Maybe<CoreTableBlockDeprecatedV2AttributesHeadCells>>;
}

export interface CoreTableBlockDeprecatedV2AttributesHeadCells {
  __typename?: "CoreTableBlockDeprecatedV2AttributesHeadCells";
  align?: Maybe<ScalarsEnums["String"]>;
  colspan?: Maybe<ScalarsEnums["String"]>;
  content?: Maybe<ScalarsEnums["String"]>;
  rowspan?: Maybe<ScalarsEnums["String"]>;
  scope?: Maybe<ScalarsEnums["String"]>;
  tag: ScalarsEnums["String"];
}

export interface CoreTableBlockDeprecatedV3Attributes {
  __typename?: "CoreTableBlockDeprecatedV3Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  body: Array<Maybe<CoreTableBlockDeprecatedV3AttributesBody>>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  caption: ScalarsEnums["String"];
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  foot: Array<Maybe<CoreTableBlockDeprecatedV3AttributesFoot>>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  hasFixedLayout: ScalarsEnums["Boolean"];
  head: Array<Maybe<CoreTableBlockDeprecatedV3AttributesHead>>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreTableBlockDeprecatedV3AttributesBody {
  __typename?: "CoreTableBlockDeprecatedV3AttributesBody";
  cells: Array<Maybe<CoreTableBlockDeprecatedV3AttributesBodyCells>>;
}

export interface CoreTableBlockDeprecatedV3AttributesBodyCells {
  __typename?: "CoreTableBlockDeprecatedV3AttributesBodyCells";
  align?: Maybe<ScalarsEnums["String"]>;
  colspan?: Maybe<ScalarsEnums["String"]>;
  content?: Maybe<ScalarsEnums["String"]>;
  rowspan?: Maybe<ScalarsEnums["String"]>;
  scope?: Maybe<ScalarsEnums["String"]>;
  tag: ScalarsEnums["String"];
}

export interface CoreTableBlockDeprecatedV3AttributesFoot {
  __typename?: "CoreTableBlockDeprecatedV3AttributesFoot";
  cells: Array<Maybe<CoreTableBlockDeprecatedV3AttributesFootCells>>;
}

export interface CoreTableBlockDeprecatedV3AttributesFootCells {
  __typename?: "CoreTableBlockDeprecatedV3AttributesFootCells";
  align?: Maybe<ScalarsEnums["String"]>;
  colspan?: Maybe<ScalarsEnums["String"]>;
  content?: Maybe<ScalarsEnums["String"]>;
  rowspan?: Maybe<ScalarsEnums["String"]>;
  scope?: Maybe<ScalarsEnums["String"]>;
  tag: ScalarsEnums["String"];
}

export interface CoreTableBlockDeprecatedV3AttributesHead {
  __typename?: "CoreTableBlockDeprecatedV3AttributesHead";
  cells: Array<Maybe<CoreTableBlockDeprecatedV3AttributesHeadCells>>;
}

export interface CoreTableBlockDeprecatedV3AttributesHeadCells {
  __typename?: "CoreTableBlockDeprecatedV3AttributesHeadCells";
  align?: Maybe<ScalarsEnums["String"]>;
  colspan?: Maybe<ScalarsEnums["String"]>;
  content?: Maybe<ScalarsEnums["String"]>;
  rowspan?: Maybe<ScalarsEnums["String"]>;
  scope?: Maybe<ScalarsEnums["String"]>;
  tag: ScalarsEnums["String"];
}

/**
 * core/tag-cloud block
 */
export interface CoreTagCloudBlock {
  __typename?: "CoreTagCloudBlock";
  attributes?: Maybe<CoreTagCloudBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreTagCloudBlockAttributes {
  __typename?: "CoreTagCloudBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  largestFontSize: ScalarsEnums["String"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  numberOfTags: ScalarsEnums["Float"];
  showTagCounts: ScalarsEnums["Boolean"];
  smallestFontSize: ScalarsEnums["String"];
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  taxonomy: ScalarsEnums["String"];
}

/**
 * core/template-part block
 */
export interface CoreTemplatePartBlock {
  __typename?: "CoreTemplatePartBlock";
  attributes?: Maybe<CoreTemplatePartBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreTemplatePartBlockAttributes {
  __typename?: "CoreTemplatePartBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  area?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  slug?: Maybe<ScalarsEnums["String"]>;
  tagName?: Maybe<ScalarsEnums["String"]>;
  theme?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/term-description block
 */
export interface CoreTermDescriptionBlock {
  __typename?: "CoreTermDescriptionBlock";
  attributes?: Maybe<CoreTermDescriptionBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreTermDescriptionBlockAttributes {
  __typename?: "CoreTermDescriptionBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/text-columns block
 */
export interface CoreTextColumnsBlock {
  __typename?: "CoreTextColumnsBlock";
  attributes?: Maybe<CoreTextColumnsBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreTextColumnsBlockAttributes {
  __typename?: "CoreTextColumnsBlockAttributes";
  className?: Maybe<ScalarsEnums["String"]>;
  columns: ScalarsEnums["Float"];
  content: Array<Maybe<CoreTextColumnsBlockAttributesContent>>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  width?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreTextColumnsBlockAttributesContent {
  __typename?: "CoreTextColumnsBlockAttributesContent";
  children?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/verse block
 */
export interface CoreVerseBlock {
  __typename?: "CoreVerseBlock";
  attributes?: Maybe<CoreVerseBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreVerseBlockAttributes {
  __typename?: "CoreVerseBlockAttributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreVerseBlockAttributesUnion {
  __typename?:
    | "CoreVerseBlockAttributes"
    | "CoreVerseBlockDeprecatedV1Attributes"
    | "CoreVerseBlockDeprecatedV2Attributes";
  $on: $CoreVerseBlockAttributesUnion;
}

export interface CoreVerseBlockDeprecatedV1Attributes {
  __typename?: "CoreVerseBlockDeprecatedV1Attributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreVerseBlockDeprecatedV2Attributes {
  __typename?: "CoreVerseBlockDeprecatedV2Attributes";
  anchor?: Maybe<ScalarsEnums["String"]>;
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  borderColor?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  content: ScalarsEnums["String"];
  fontFamily?: Maybe<ScalarsEnums["String"]>;
  fontSize?: Maybe<ScalarsEnums["String"]>;
  gradient?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  textAlign?: Maybe<ScalarsEnums["String"]>;
  textColor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * core/video block
 */
export interface CoreVideoBlock {
  __typename?: "CoreVideoBlock";
  attributes?: Maybe<CoreVideoBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreVideoBlockAttributes {
  __typename?: "CoreVideoBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  autoplay?: Maybe<ScalarsEnums["Boolean"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  controls: ScalarsEnums["Boolean"];
  id?: Maybe<ScalarsEnums["Float"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  loop?: Maybe<ScalarsEnums["Boolean"]>;
  muted?: Maybe<ScalarsEnums["Boolean"]>;
  playsInline?: Maybe<ScalarsEnums["Boolean"]>;
  poster?: Maybe<ScalarsEnums["String"]>;
  preload: ScalarsEnums["String"];
  src?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tracks: Array<Maybe<ScalarsEnums["BlockAttributesObject"]>>;
}

export interface CoreVideoBlockAttributesUnion {
  __typename?:
    | "CoreVideoBlockAttributes"
    | "CoreVideoBlockDeprecatedV1Attributes";
  $on: $CoreVideoBlockAttributesUnion;
}

export interface CoreVideoBlockDeprecatedV1Attributes {
  __typename?: "CoreVideoBlockDeprecatedV1Attributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchor?: Maybe<ScalarsEnums["String"]>;
  autoplay?: Maybe<ScalarsEnums["Boolean"]>;
  caption?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  controls: ScalarsEnums["Boolean"];
  id?: Maybe<ScalarsEnums["Float"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  loop?: Maybe<ScalarsEnums["Boolean"]>;
  muted?: Maybe<ScalarsEnums["Boolean"]>;
  playsInline?: Maybe<ScalarsEnums["Boolean"]>;
  poster?: Maybe<ScalarsEnums["String"]>;
  preload: ScalarsEnums["String"];
  src?: Maybe<ScalarsEnums["String"]>;
  style?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tracks: Array<Maybe<ScalarsEnums["BlockAttributesObject"]>>;
}

/**
 * core/widget-group block
 */
export interface CoreWidgetGroupBlock {
  __typename?: "CoreWidgetGroupBlock";
  attributes?: Maybe<CoreWidgetGroupBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreWidgetGroupBlockAttributes {
  __typename?: "CoreWidgetGroupBlockAttributes";
  className?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  title?: Maybe<ScalarsEnums["String"]>;
}

export interface CoreWidgetGroupBlockAttributesUnion {
  __typename?:
    | "CoreWidgetGroupBlockAttributes"
    | "CoreWidgetGroupBlockDeprecatedV1Attributes";
  $on: $CoreWidgetGroupBlockAttributesUnion;
}

export interface CoreWidgetGroupBlockDeprecatedV1Attributes {
  __typename?: "CoreWidgetGroupBlockDeprecatedV1Attributes";
  className?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  title?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The payload for the createCategory mutation
 */
export interface CreateCategoryPayload {
  __typename?: "CreateCategoryPayload";
  /**
   * The created category
   */
  category?: Maybe<Category>;
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The payload for the createComment mutation
 */
export interface CreateCommentPayload {
  __typename?: "CreateCommentPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The comment that was created
   */
  comment?: Maybe<Comment>;
  /**
   * Whether the mutation succeeded. If the comment is not approved, the server will not return the comment to a non authenticated user, but a success message can be returned if the create succeeded, and the client can optimistically add the comment to the client cache
   */
  success?: Maybe<ScalarsEnums["Boolean"]>;
}

/**
 * The payload for the createJob mutation
 */
export interface CreateJobPayload {
  __typename?: "CreateJobPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Post object mutation type.
   */
  job?: Maybe<Job>;
}

/**
 * The payload for the createMediaItem mutation
 */
export interface CreateMediaItemPayload {
  __typename?: "CreateMediaItemPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The MediaItem object mutation type.
   */
  mediaItem?: Maybe<MediaItem>;
}

/**
 * The payload for the createPage mutation
 */
export interface CreatePagePayload {
  __typename?: "CreatePagePayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Post object mutation type.
   */
  page?: Maybe<Page>;
}

/**
 * The payload for the createPostFormat mutation
 */
export interface CreatePostFormatPayload {
  __typename?: "CreatePostFormatPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The created post_format
   */
  postFormat?: Maybe<PostFormat>;
}

/**
 * The payload for the createPost mutation
 */
export interface CreatePostPayload {
  __typename?: "CreatePostPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Post object mutation type.
   */
  post?: Maybe<Post>;
}

/**
 * The payload for the createReusableBlock mutation
 */
export interface CreateReusableBlockPayload {
  __typename?: "CreateReusableBlockPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Post object mutation type.
   */
  reusableBlock?: Maybe<ReusableBlock>;
}

/**
 * The payload for the createService mutation
 */
export interface CreateServicePayload {
  __typename?: "CreateServicePayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Post object mutation type.
   */
  service?: Maybe<Service>;
}

/**
 * The payload for the createSpecialty mutation
 */
export interface CreateSpecialtyPayload {
  __typename?: "CreateSpecialtyPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Post object mutation type.
   */
  specialty?: Maybe<Specialty>;
}

/**
 * The payload for the createTag mutation
 */
export interface CreateTagPayload {
  __typename?: "CreateTagPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The created post_tag
   */
  tag?: Maybe<Tag>;
}

/**
 * The payload for the createTeam_member mutation
 */
export interface CreateTeam_memberPayload {
  __typename?: "CreateTeam_memberPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Post object mutation type.
   */
  team_member?: Maybe<Team_member>;
}

/**
 * The payload for the createUser mutation
 */
export interface CreateUserPayload {
  __typename?: "CreateUserPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The User object mutation type.
   */
  user?: Maybe<User>;
}

/**
 * The payload for the createWork mutation
 */
export interface CreateWorkPayload {
  __typename?: "CreateWorkPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Post object mutation type.
   */
  work?: Maybe<Work>;
}

/**
 * Object that can be identified with a Database ID
 */
export interface DatabaseIdentifier {
  __typename?:
    | "BlockEditorPreview"
    | "Category"
    | "Comment"
    | "GfForm"
    | "GfSubmittedEntry"
    | "Job"
    | "MediaItem"
    | "Menu"
    | "MenuItem"
    | "Page"
    | "Post"
    | "PostFormat"
    | "ReusableBlock"
    | "Service"
    | "Specialty"
    | "Tag"
    | "Team_member"
    | "User"
    | "Work";
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums["Int"];
  $on: $DatabaseIdentifier;
}

/**
 * A Gravity Forms   field.
 */
export interface DateField {
  __typename?: "DateField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines how the date field displays it’s calendar icon.
   */
  calendarIconType?: Maybe<ScalarsEnums["FormFieldCalendarIconTypeEnum"]>;
  /**
   * Contains the URL to the custom calendar icon. Only applicable when calendarIconType is set to custom.
   */
  calendarIconUrl?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines how the date is displayed.
   */
  dateFormat?: Maybe<ScalarsEnums["DateFieldFormatEnum"]>;
  /**
   * The type of date field to display.
   */
  dateType?: Maybe<ScalarsEnums["DateFieldTypeEnum"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * An array of the available properties for each input of the date field.
   */
  inputs?: Maybe<Array<Maybe<DateInputProperty>>>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs.
   */
  subLabelPlacement?: Maybe<ScalarsEnums["FormFieldSubLabelPlacementEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Date input values.
 */
export interface DateInputProperty {
  __typename?: "DateInputProperty";
  /**
   * The autocomplete attribute for the field.
   */
  autocompleteAttribute?: Maybe<ScalarsEnums["String"]>;
  /**
   * The custom label for the input. When set, this is used in place of the label.
   */
  customLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field.
   */
  id?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The template assigned to the node
 */
export interface DefaultTemplate {
  __typename?: "DefaultTemplate";
  /**
   * The name of the template
   */
  templateName?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The payload for the deleteCategory mutation
 */
export interface DeleteCategoryPayload {
  __typename?: "DeleteCategoryPayload";
  /**
   * The deteted term object
   */
  category?: Maybe<Category>;
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums["ID"]>;
}

/**
 * The payload for the deleteComment mutation
 */
export interface DeleteCommentPayload {
  __typename?: "DeleteCommentPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The deleted comment object
   */
  comment?: Maybe<Comment>;
  /**
   * The deleted comment ID
   */
  deletedId?: Maybe<ScalarsEnums["ID"]>;
}

/**
 * The payload for the deleteGfDraftEntry mutation
 */
export interface DeleteGfDraftEntryPayload {
  __typename?: "DeleteGfDraftEntryPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The global ID of the draft entry that was deleted.
   */
  deletedId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The draft entry object before it was deleted.
   */
  draftEntry?: Maybe<GfDraftEntry>;
}

/**
 * The payload for the deleteGfEntry mutation
 */
export interface DeleteGfEntryPayload {
  __typename?: "DeleteGfEntryPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The global ID of the draft entry that was deleted.
   */
  deletedId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The entry object before it was deleted.
   */
  entry?: Maybe<GfSubmittedEntry>;
}

/**
 * The payload for the deleteJob mutation
 */
export interface DeleteJobPayload {
  __typename?: "DeleteJobPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The object before it was deleted
   */
  job?: Maybe<Job>;
}

/**
 * The payload for the deleteMediaItem mutation
 */
export interface DeleteMediaItemPayload {
  __typename?: "DeleteMediaItemPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The ID of the deleted mediaItem
   */
  deletedId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The mediaItem before it was deleted
   */
  mediaItem?: Maybe<MediaItem>;
}

/**
 * The payload for the deletePage mutation
 */
export interface DeletePagePayload {
  __typename?: "DeletePagePayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The object before it was deleted
   */
  page?: Maybe<Page>;
}

/**
 * The payload for the deletePostFormat mutation
 */
export interface DeletePostFormatPayload {
  __typename?: "DeletePostFormatPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The deteted term object
   */
  postFormat?: Maybe<PostFormat>;
}

/**
 * The payload for the deletePost mutation
 */
export interface DeletePostPayload {
  __typename?: "DeletePostPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The object before it was deleted
   */
  post?: Maybe<Post>;
}

/**
 * The payload for the deleteReusableBlock mutation
 */
export interface DeleteReusableBlockPayload {
  __typename?: "DeleteReusableBlockPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The object before it was deleted
   */
  reusableBlock?: Maybe<ReusableBlock>;
}

/**
 * The payload for the deleteService mutation
 */
export interface DeleteServicePayload {
  __typename?: "DeleteServicePayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The object before it was deleted
   */
  service?: Maybe<Service>;
}

/**
 * The payload for the deleteSpecialty mutation
 */
export interface DeleteSpecialtyPayload {
  __typename?: "DeleteSpecialtyPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The object before it was deleted
   */
  specialty?: Maybe<Specialty>;
}

/**
 * The payload for the deleteTag mutation
 */
export interface DeleteTagPayload {
  __typename?: "DeleteTagPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The deteted term object
   */
  tag?: Maybe<Tag>;
}

/**
 * The payload for the deleteTeam_member mutation
 */
export interface DeleteTeam_memberPayload {
  __typename?: "DeleteTeam_memberPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The object before it was deleted
   */
  team_member?: Maybe<Team_member>;
}

/**
 * The payload for the deleteUser mutation
 */
export interface DeleteUserPayload {
  __typename?: "DeleteUserPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The ID of the user that you just deleted
   */
  deletedId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The deleted user object
   */
  user?: Maybe<User>;
}

/**
 * The payload for the deleteWork mutation
 */
export interface DeleteWorkPayload {
  __typename?: "DeleteWorkPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The ID of the deleted object
   */
  deletedId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The object before it was deleted
   */
  work?: Maybe<Work>;
}

/**
 * The discussion setting type
 */
export interface DiscussionSettings {
  __typename?: "DiscussionSettings";
  /**
   * Allow people to submit comments on new posts.
   */
  defaultCommentStatus?: Maybe<ScalarsEnums["String"]>;
  /**
   * Allow link notifications from other blogs (pingbacks and trackbacks) on new articles.
   */
  defaultPingStatus?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface EmailField {
  __typename?: "EmailField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether autocomplete should be enabled for this field.
   */
  hasAutocomplete?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the Confirm Email field is active.
   */
  hasEmailConfirmation?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * An array of the available properties for each input of the email field.
   */
  inputs?: Maybe<Array<Maybe<EmailInputProperty>>>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs.
   */
  subLabelPlacement?: Maybe<ScalarsEnums["FormFieldSubLabelPlacementEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Email input values.
 */
export interface EmailInputProperty {
  __typename?: "EmailInputProperty";
  /**
   * The autocomplete attribute for the field.
   */
  autocompleteAttribute?: Maybe<ScalarsEnums["String"]>;
  /**
   * The custom label for the input. When set, this is used in place of the label.
   */
  customLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field.
   */
  id?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Asset enqueued by the CMS
 */
export interface EnqueuedAsset {
  __typename?: "EnqueuedScript" | "EnqueuedStylesheet";
  /**
   * @todo
   */
  args?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Dependencies needed to use this asset
   */
  dependencies?: Maybe<Array<Maybe<EnqueuedScript>>>;
  /**
   * Extra information needed for the script
   */
  extra?: Maybe<ScalarsEnums["String"]>;
  /**
   * The handle of the enqueued asset
   */
  handle?: Maybe<ScalarsEnums["String"]>;
  /**
   * The ID of the enqueued asset
   */
  id: ScalarsEnums["ID"];
  /**
   * The source of the asset
   */
  src?: Maybe<ScalarsEnums["String"]>;
  /**
   * The version of the enqueued asset
   */
  version?: Maybe<ScalarsEnums["String"]>;
  $on: $EnqueuedAsset;
}

/**
 * Script enqueued by the CMS
 */
export interface EnqueuedScript {
  __typename?: "EnqueuedScript";
  /**
   * @todo
   */
  args?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Dependencies needed to use this asset
   */
  dependencies?: Maybe<Array<Maybe<EnqueuedScript>>>;
  /**
   * Extra information needed for the script
   */
  extra?: Maybe<ScalarsEnums["String"]>;
  /**
   * The handle of the enqueued asset
   */
  handle?: Maybe<ScalarsEnums["String"]>;
  /**
   * The ID of the enqueued asset
   */
  id: ScalarsEnums["ID"];
  /**
   * The source of the asset
   */
  src?: Maybe<ScalarsEnums["String"]>;
  /**
   * The version of the enqueued asset
   */
  version?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Stylesheet enqueued by the CMS
 */
export interface EnqueuedStylesheet {
  __typename?: "EnqueuedStylesheet";
  /**
   * @todo
   */
  args?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Dependencies needed to use this asset
   */
  dependencies?: Maybe<Array<Maybe<EnqueuedScript>>>;
  /**
   * Extra information needed for the script
   */
  extra?: Maybe<ScalarsEnums["String"]>;
  /**
   * The handle of the enqueued asset
   */
  handle?: Maybe<ScalarsEnums["String"]>;
  /**
   * The ID of the enqueued asset
   */
  id: ScalarsEnums["ID"];
  /**
   * The source of the asset
   */
  src?: Maybe<ScalarsEnums["String"]>;
  /**
   * The version of the enqueued asset
   */
  version?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Field error.
 */
export interface FieldError {
  __typename?: "FieldError";
  /**
   * The field with the associated error message.
   */
  id?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Error message.
   */
  message?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface FileUploadField {
  __typename?: "FileUploadField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * A comma-delimited list of the file extensions which may be uploaded.
   */
  allowedExtensions?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * Indicates whether multiple files may be uploaded.
   */
  canAcceptMultipleFiles?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * File upload value
   */
  fileUploadValues?: Maybe<Array<Maybe<FileUploadFieldValue>>>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The maximum size (in MB) an uploaded file may be .
   */
  maxFileSize?: Maybe<ScalarsEnums["Int"]>;
  /**
   * When the field is set to allow multiple files to be uploaded, this property is available to set a limit on how many may be uploaded.
   */
  maxFiles?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * An array of field values.
   * @deprecated Use `fileUploadValues` instead.
   */
  values?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * The individual file properties from an uploaded file.
 */
export interface FileUploadFieldValue {
  __typename?: "FileUploadFieldValue";
  /**
   * The path to the parent directory of the file.
   */
  basePath?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base url to the parent directory of the file.
   */
  baseUrl?: Maybe<ScalarsEnums["String"]>;
  /**
   * The filename.
   */
  filename?: Maybe<ScalarsEnums["String"]>;
  /**
   * The url to the file.
   */
  url?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Gravity Forms button.
 */
export interface FormButton {
  __typename?: "FormButton";
  /**
   * Controls when the form button should be visible based on values selected on the form.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * Contains the URL for the image button. Only applicable when type is set to image.
   */
  imageUrl?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the button text. Only applicable when type is set to text.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * Specifies the type of button to be displayed. Defaults to TEXT.
   */
  type?: Maybe<ScalarsEnums["FormButtonTypeEnum"]>;
}

/**
 * Properties for all the email notifications which exist for a form.
 */
export interface FormConfirmation {
  __typename?: "FormConfirmation";
  /**
   * Controls which form confirmation message should be displayed.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * ID.
   */
  id?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether the confirmation is active or inactive. The default confirmation is always active.
   */
  isActive?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether this is the default confirmation.
   */
  isDefault?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the confirmation message that will be displayed. Only applicable when type is set to &quot;MESSAGE&quot;.
   */
  message?: Maybe<ScalarsEnums["String"]>;
  /**
   * The confirmation name.
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the Id of the WordPress page that the browser will be redirected to. Only applicable when type is set to `PAGE`.
   */
  pageId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Contains the query string to be appended to the redirection url. Only applicable when type is set to `REDIRECT`.
   */
  queryString?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines the type of confirmation to be used.
   */
  type?: Maybe<ScalarsEnums["FormConfirmationTypeEnum"]>;
  /**
   * Contains the URL that the browser will be redirected to. Only applicable when type is set to `REDIRECT`.
   */
  url?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The policies governing which entry data to include when erasing and exporting personal data.
 */
export interface FormDataPolicies {
  __typename?: "FormDataPolicies";
  /**
   * Whether entry data from this form is included when erasing and exporting personal data.
   */
  canExportAndErase?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The individual entry data exporting and erasing policies.
   */
  entryData?: Maybe<Array<Maybe<FormEntryDataPolicy>>>;
  /**
   * The database ID of the Gravity Forms field used to identify the user.
   */
  identificationFieldDatabaseId?: Maybe<ScalarsEnums["Int"]>;
}

/**
 * The individual entry data exporting and erasing policies.
 */
export interface FormEntryDataPolicy {
  __typename?: "FormEntryDataPolicy";
  /**
   * The array key for the Gravity Forms Entry.
   */
  key?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether this field should be included when erasing personal data.
   */
  shouldErase?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether this field should be included when exporting personal data.
   */
  shouldExport?: Maybe<ScalarsEnums["Boolean"]>;
}

/**
 * Gravity Forms form entry limititation details.
 */
export interface FormEntryLimits {
  __typename?: "FormEntryLimits";
  /**
   * Whether the form has a limit on the number of submissions.
   */
  hasLimit?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Message that will be displayed when the maximum number of submissions have been reached.
   */
  limitReachedMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * The time period during which submissions are allowed.
   */
  limitationPeriod?: Maybe<ScalarsEnums["FormLimitEntriesPeriodEnum"]>;
  /**
   * The number of submissions allowed.
   */
  maxEntries?: Maybe<ScalarsEnums["Int"]>;
}

/**
 * Gravity Forms field.
 */
export interface FormField {
  __typename?:
    | "AddressField"
    | "CaptchaField"
    | "CheckboxField"
    | "ConsentField"
    | "DateField"
    | "EmailField"
    | "FileUploadField"
    | "HiddenField"
    | "HtmlField"
    | "ListField"
    | "MultiSelectField"
    | "NameField"
    | "NumberField"
    | "PageField"
    | "PasswordField"
    | "PhoneField"
    | "PostCategoryCheckboxField"
    | "PostCategoryMultiSelectField"
    | "PostCategoryRadioField"
    | "PostCategorySelectField"
    | "PostContentField"
    | "PostCustomCheckboxField"
    | "PostCustomDateField"
    | "PostCustomEmailField"
    | "PostCustomFileuploadField"
    | "PostCustomHiddenField"
    | "PostCustomListField"
    | "PostCustomMultiSelectField"
    | "PostCustomNumberField"
    | "PostCustomPhoneField"
    | "PostCustomRadioField"
    | "PostCustomSelectField"
    | "PostCustomTextAreaField"
    | "PostCustomTextField"
    | "PostCustomTimeField"
    | "PostCustomWebsiteField"
    | "PostExcerptField"
    | "PostImageField"
    | "PostTagsCheckboxField"
    | "PostTagsMultiSelectField"
    | "PostTagsRadioField"
    | "PostTagsSelectField"
    | "PostTagsTextField"
    | "PostTitleField"
    | "RadioField"
    | "SectionField"
    | "SelectField"
    | "SubmitField"
    | "TextAreaField"
    | "TextField"
    | "TimeField"
    | "WebsiteField";
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
  $on: $FormField;
}

/**
 * The form field-specifc policies for exporting and erasing personal data.
 */
export interface FormFieldDataPolicy {
  __typename?: "FormFieldDataPolicy";
  /**
   * Whether this field is used to identify the user&#039;s personal data.
   */
  isIdentificationField?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether this field should be included when erasing personal data.
   */
  shouldErase?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether this field should be included when exporting personal data.
   */
  shouldExport?: Maybe<ScalarsEnums["Boolean"]>;
}

/**
 * Gravity Forms button.
 */
export interface FormLastPageButton {
  __typename?: "FormLastPageButton";
  /**
   * Contains the URL for the image button. Only applicable when type is set to image.
   */
  imageUrl?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the button text. Only applicable when type is set to text.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * Specifies the type of button to be displayed. Defaults to TEXT.
   */
  type?: Maybe<ScalarsEnums["FormButtonTypeEnum"]>;
}

/**
 * Gravity Forms form login requirements data.
 */
export interface FormLogin {
  __typename?: "FormLogin";
  /**
   * Whether the form is configured to be displayed only to logged in users.
   */
  isLoginRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * When `isLoginRequired` is set to true, this controls the message displayed when non-logged in user tries to access the form.
   */
  loginRequiredMessage?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Properties for all the email notifications which exist for a form.
 */
export interface FormNotification {
  __typename?: "FormNotification";
  /**
   * The email or merge tags to be used as the email bcc address.
   */
  bcc?: Maybe<ScalarsEnums["String"]>;
  /**
   * An associative array containing the conditional logic rules. See the Conditional Logic Object for more details.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * The notification event. Default is form_submission.
   */
  event?: Maybe<ScalarsEnums["String"]>;
  /**
   * The email or merge tag to be used as the email from address.
   */
  from?: Maybe<ScalarsEnums["String"]>;
  /**
   * The text or merge tag to be used as the email from name.
   */
  fromName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The notification ID. A 13 character unique ID.
   */
  id?: Maybe<ScalarsEnums["String"]>;
  /**
   * Is the notification active or inactive. The default is true (active).
   */
  isActive?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the email message should be formatted so that paragraphs are automatically added for new lines.
   */
  isAutoformatted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The email body/content. Merge tags supported.
   */
  message?: Maybe<ScalarsEnums["String"]>;
  /**
   * The notification name.
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * The email or merge tags to be used as the email reply to address.
   */
  replyTo?: Maybe<ScalarsEnums["String"]>;
  /**
   * Routing rules.
   */
  routing?: Maybe<Array<Maybe<FormNotificationRouting>>>;
  /**
   * The name of the service to be used when sending this notification. Default is wordpress.
   */
  service?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if files uploaded on the form should be included when the notification is sent.
   */
  shouldSendAttachments?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The email subject line. Merge tags supported.
   */
  subject?: Maybe<ScalarsEnums["String"]>;
  /**
   * The ID of an email field, an email address or merge tag to be used as the email to address.
   */
  to?: Maybe<ScalarsEnums["String"]>;
  /**
   * Identifies what to use for the notification &quot;to&quot;.
   */
  toType?: Maybe<ScalarsEnums["FormNotificationToTypeEnum"]>;
}

/**
 * Properties for all the email notifications which exist for a form.
 */
export interface FormNotificationRouting {
  __typename?: "FormNotificationRouting";
  /**
   * The email or merge tag to be used as the email To address if this rule is a match.
   */
  email?: Maybe<ScalarsEnums["String"]>;
  /**
   * Target field ID. The field that will have it’s value compared with the value property to determine if this rule is a match.
   */
  fieldId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Operator to be used when evaluating this rule.
   */
  operator?: Maybe<ScalarsEnums["FormRuleOperatorEnum"]>;
  /**
   * The value to compare with the field specified by fieldId.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Gravity Forms form pagination data.
 */
export interface FormPagination {
  __typename?: "FormPagination";
  /**
   * Progress bar background color. Can be any CSS color value. Only applies when `style` is set to &quot;CUSTOM&quot;.
   */
  backgroundColor?: Maybe<ScalarsEnums["String"]>;
  /**
   * Progress bar text color. Can be any CSS color value. Only applies when `style` is set to &quot;CUSTOM&quot;.
   */
  color?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether the confirmation bar should be displayed with the confirmation text.
   */
  hasProgressbarOnConfirmation?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Last page button data.
   */
  lastPageButton?: Maybe<FormLastPageButton>;
  /**
   * Names of the form&#039;s pages.
   */
  pageNames?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The confirmation text to display once the end of the progress bar has been reached. Only applies when `hasProgressbarOnConfirmation` is set to true.
   */
  progressbarCompletionText?: Maybe<ScalarsEnums["String"]>;
  /**
   * Style of progress bar.
   */
  style?: Maybe<ScalarsEnums["FormPageProgressStyleEnum"]>;
  /**
   * Type of progress indicator.
   */
  type?: Maybe<ScalarsEnums["FormPageProgressTypeEnum"]>;
}

/**
 * Gravity Forms form Personal Data settings.
 */
export interface FormPersonalData {
  __typename?: "FormPersonalData";
  /**
   * The policies governing which entry data to include when erasing and exporting personal data.
   */
  dataPolicies?: Maybe<FormDataPolicies>;
  /**
   * The number of days to retain entries. `null` if `retentionPolicy` is set to `RETAIN` entries indefinitely.
   */
  daysToRetain?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The policy for retaining old entry data.
   */
  retentionPolicy?: Maybe<ScalarsEnums["FormRetentionPolicyEnum"]>;
  /**
   * Whether the IP address should be saved to the form submission.
   */
  shouldSaveIP?: Maybe<ScalarsEnums["Boolean"]>;
}

/**
 * Gravity Forms form entry limititation details.
 */
export interface FormPostCreation {
  __typename?: "FormPostCreation";
  /**
   * When `useCurrentUserAsAuthor` is `false`, this contains the User object for the author.
   */
  author?: Maybe<User>;
  /**
   * When `useCurrentUserAsAuthor` is `false`, this property contains the user database that will be used as the Post author.
   */
  authorDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * When `useCurrentUserAsAuthor` is `false`, this property contains the user ID that will be used as the Post author.
   */
  authorId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * Form forms with Post fields, but without a Post Category field, this property contains the default category database ID the post will be associated with when created.
   */
  categoryDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Template to be used when creating the post content. Field variables (i.e. {Name:3} ) can be added to the template to insert user submitted values into the post content. Only applicable when `hasContentTemplate` is `true`.
   */
  contentTemplate?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines the format that the Post should be created with.
   */
  format?: Maybe<ScalarsEnums["PostFormatTypeEnum"]>;
  /**
   * Determines if the post template functionality is enabled. When enabled, the post content will be created based on the template specified by `contentTemplate`.
   */
  hasContentTemplate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if the post title template functionality is enabled. When enabled, the post title will be created based on the template specified by `titleTemplate`.
   */
  hasTitleTemplate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * For forms with Post fields, this determines if the post should be created using the current logged in user as the author.
   */
  shouldUseCurrentUserAsAuthor?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * For forms with Post fields, determines the status that the Post should be created with.
   */
  status?: Maybe<ScalarsEnums["String"]>;
  /**
   * Template to be used when creating the post title. Field variables (i.e. {Name:3} ) can be added to the template to insert user submitted values into the post title. Only applicable when `hasTitleTemplate` is `true`.
   */
  titleTemplate?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Gravity Forms form Save and Continue data.
 */
export interface FormSaveAndContinue {
  __typename?: "FormSaveAndContinue";
  /**
   * Contains the save button text.
   */
  buttonText?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether the Save And Continue feature is enabled.
   */
  hasSaveAndContinue?: Maybe<ScalarsEnums["Boolean"]>;
}

/**
 * Gravity Forms form scheduling data.
 */
export interface FormSchedule {
  __typename?: "FormSchedule";
  /**
   * Message to be displayed when form is no longer available.
   */
  closedMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Date/time details when the form will become inactive.
   */
  endDetails?: Maybe<FormScheduleDetails>;
  /**
   * Specifies if this form is scheduled to be displayed only during a certain configured date/time.
   */
  hasSchedule?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Message to be displayed when form is not yet available.
   */
  pendingMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Date/time details when the form will become active/visible.
   */
  startDetails?: Maybe<FormScheduleDetails>;
}

/**
 * Gravity Forms form scheduling data.
 */
export interface FormScheduleDetails {
  __typename?: "FormScheduleDetails";
  /**
   * Whether the date is in the AM or PM of a 12-hour clock.
   */
  amPm?: Maybe<ScalarsEnums["AmPmEnum"]>;
  /**
   * The schedule date in local time.
   */
  date?: Maybe<ScalarsEnums["String"]>;
  /**
   * The schedule date in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The hour (1-12).
   */
  hour?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The minute.
   */
  minute?: Maybe<ScalarsEnums["Int"]>;
}

/**
 * Gravity Forms submit button.
 */
export interface FormSubmitButton {
  __typename?: "FormSubmitButton";
  /**
   * Controls when the form button should be visible based on values selected on the form.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * Contains the URL for the image button. Only applicable when type is set to image.
   */
  imageUrl?: Maybe<ScalarsEnums["String"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Where the submit button should be located.
   */
  location?: Maybe<ScalarsEnums["FormSubmitButtonLocationEnum"]>;
  /**
   * Contains the button text. Only applicable when type is set to text.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * Specifies the type of button to be displayed. Defaults to TEXT.
   */
  type?: Maybe<ScalarsEnums["FormButtonTypeEnum"]>;
  /**
   * The width of the submit button element.
   */
  width?: Maybe<ScalarsEnums["FormSubmitButtonWidthEnum"]>;
}

/**
 * General Options Settings options.
 */
export interface GeneralOptionsSettings {
  __typename?: "GeneralOptionsSettings";
  /**
   * Added to the GraphQL Schema because the ACF Field Group &quot;General Settings&quot; was set to Show in GraphQL.
   */
  generalSettings?: Maybe<GeneralOptionsSettings_Generalsettings>;
  pageSlug?: Maybe<ScalarsEnums["String"]>;
  pageTitle?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Field Group
 */
export interface GeneralOptionsSettings_Generalsettings {
  __typename?: "GeneralOptionsSettings_Generalsettings";
  address?: Maybe<ScalarsEnums["String"]>;
  ceoLink?: Maybe<ScalarsEnums["String"]>;
  clientSupportUrl?: Maybe<ScalarsEnums["String"]>;
  email?: Maybe<ScalarsEnums["String"]>;
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums["String"]>;
  googleMapsUrl?: Maybe<ScalarsEnums["String"]>;
  instagramUrl?: Maybe<ScalarsEnums["String"]>;
  linkedinUrl?: Maybe<ScalarsEnums["String"]>;
  logo?: Maybe<MediaItem>;
  phone?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The general setting type
 */
export interface GeneralSettings {
  __typename?: "GeneralSettings";
  /**
   * A date format for all date strings.
   */
  dateFormat?: Maybe<ScalarsEnums["String"]>;
  /**
   * Site tagline.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * This address is used for admin purposes, like new user notification.
   */
  email?: Maybe<ScalarsEnums["String"]>;
  /**
   * WordPress locale code.
   */
  language?: Maybe<ScalarsEnums["String"]>;
  /**
   * A day number of the week that the week should start on.
   */
  startOfWeek?: Maybe<ScalarsEnums["Int"]>;
  /**
   * A time format for all time strings.
   */
  timeFormat?: Maybe<ScalarsEnums["String"]>;
  /**
   * A city in the same timezone as you.
   */
  timezone?: Maybe<ScalarsEnums["String"]>;
  /**
   * Site title.
   */
  title?: Maybe<ScalarsEnums["String"]>;
  /**
   * Site URL.
   */
  url?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The payload for the generateAuthorizationCode mutation
 */
export interface GenerateAuthorizationCodePayload {
  __typename?: "GenerateAuthorizationCodePayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * Authorization code used for requesting refresh/access tokens
   */
  code?: Maybe<ScalarsEnums["String"]>;
  /**
   * Error encountered during user authentication, if any
   */
  error?: Maybe<ScalarsEnums["String"]>;
}

/**
 * genesis-custom-blocks/hero block
 */
export interface GenesisCustomBlocksHeroBlock {
  __typename?: "GenesisCustomBlocksHeroBlock";
  attributes?: Maybe<GenesisCustomBlocksHeroBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface GenesisCustomBlocksHeroBlockAttributes {
  __typename?: "GenesisCustomBlocksHeroBlockAttributes";
  className?: Maybe<ScalarsEnums["String"]>;
  content?: Maybe<ScalarsEnums["String"]>;
  heading?: Maybe<ScalarsEnums["String"]>;
  link?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

/**
 * A Gravity Forms draft entry.
 */
export interface GfDraftEntry {
  __typename?: "GfDraftEntry";
  /**
   * The user who created the entry.
   */
  createdBy?: Maybe<User>;
  /**
   * Database ID of the user that submitted of the form if a logged in user submitted the form.
   */
  createdByDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Global ID of the user that submitted of the form if a logged in user submitted the form.
   */
  createdById?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The date and time that the entry was created in local time.
   */
  dateCreated?: Maybe<ScalarsEnums["String"]>;
  /**
   * The date and time that the entry was created in GMT.
   */
  dateCreatedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The date and time that the entry was created in local time.
   */
  dateUpdated?: Maybe<ScalarsEnums["String"]>;
  /**
   * The date and time that the entry was updated in GMT.
   */
  dateUpdatedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The form object of the node.
   */
  form?: Maybe<GfForm>;
  /**
   * The database identifier of the form of the node.
   */
  formDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Connection between the GfEntry type and the FormField type
   */
  formFields: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<GfEntryToFormFieldConnectionWhereArgs>;
  }) => Maybe<GfEntryToFormFieldConnection>;
  /**
   * The globally unique identifier of the form of the node.
   */
  formId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums["ID"];
  /**
   * Client IP of user who submitted the form.
   */
  ip?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether the entry is a draft.
   */
  isDraft?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the entry has been submitted.
   */
  isSubmitted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The resume token. Only applies to draft entries.
   */
  resumeToken?: Maybe<ScalarsEnums["String"]>;
  /**
   * Source URL of page that contained the form when it was submitted.
   */
  sourceUrl?: Maybe<ScalarsEnums["String"]>;
  /**
   * Provides the name and version of both the browser and operating system from which the entry was submitted.
   */
  userAgent?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Gravity Forms entry interface.
 */
export interface GfEntry {
  __typename?: "GfDraftEntry" | "GfSubmittedEntry";
  /**
   * The user who created the entry.
   */
  createdBy?: Maybe<User>;
  /**
   * Database ID of the user that submitted of the form if a logged in user submitted the form.
   */
  createdByDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Global ID of the user that submitted of the form if a logged in user submitted the form.
   */
  createdById?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The date and time that the entry was created in local time.
   */
  dateCreated?: Maybe<ScalarsEnums["String"]>;
  /**
   * The date and time that the entry was created in GMT.
   */
  dateCreatedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The date and time that the entry was created in local time.
   */
  dateUpdated?: Maybe<ScalarsEnums["String"]>;
  /**
   * The date and time that the entry was updated in GMT.
   */
  dateUpdatedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The form object of the node.
   */
  form?: Maybe<GfForm>;
  /**
   * The database identifier of the form of the node.
   */
  formDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Connection between the GfEntry type and the FormField type
   */
  formFields: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<GfEntryToFormFieldConnectionWhereArgs>;
  }) => Maybe<GfEntryToFormFieldConnection>;
  /**
   * The globally unique identifier of the form of the node.
   */
  formId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums["ID"];
  /**
   * Client IP of user who submitted the form.
   */
  ip?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether the entry is a draft.
   */
  isDraft?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the entry has been submitted.
   */
  isSubmitted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Source URL of page that contained the form when it was submitted.
   */
  sourceUrl?: Maybe<ScalarsEnums["String"]>;
  /**
   * Provides the name and version of both the browser and operating system from which the entry was submitted.
   */
  userAgent?: Maybe<ScalarsEnums["String"]>;
  $on: $GfEntry;
}

/**
 * Connection between the GfEntry type and the FormField type
 */
export interface GfEntryToFormFieldConnection {
  __typename?: "GfEntryToFormFieldConnection";
  /**
   * Edges for the GfEntryToFormFieldConnection connection
   */
  edges?: Maybe<Array<Maybe<GfEntryToFormFieldConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<FormField>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface GfEntryToFormFieldConnectionEdge {
  __typename?: "GfEntryToFormFieldConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<FormField>;
}

/**
 * Gravity Forms form.
 */
export interface GfForm {
  __typename?: "GfForm";
  /**
   * Contains the form button settings such as the button text or image button source.
   * @deprecated Use `submitButton` field instead
   */
  button?: Maybe<FormSubmitButton>;
  /**
   * Contains the form confirmation settings such as confirmation text or redirect URL.
   */
  confirmations?: Maybe<Array<Maybe<FormConfirmation>>>;
  /**
   * String containing the custom CSS classes to be added to the &lt;form&gt; tag.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * The custom text to use to indicate a field is required.
   */
  customRequiredIndicator?: Maybe<ScalarsEnums["String"]>;
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * The date and time that the entry was created in local time.
   */
  dateCreated?: Maybe<ScalarsEnums["String"]>;
  /**
   * The date and time that the entry was created in GMT.
   */
  dateCreatedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * Form description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field description is displayed above the field input (i.e. immediately after the field label) or below the field input.
   */
  descriptionPlacement?: Maybe<ScalarsEnums["FormDescriptionPlacementEnum"]>;
  /**
   * Connection between the GfForm type and the GfEntry type
   */
  entries: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<GfFormToGfEntryConnectionWhereArgs>;
  }) => Maybe<GfFormToGfEntryConnection>;
  /**
   * The entry limit settings.
   */
  entryLimits?: Maybe<FormEntryLimits>;
  /**
   * CSS class for the first page.
   */
  firstPageCssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the GfForm type and the FormField type
   */
  formFields: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<GfFormToFormFieldConnectionWhereArgs>;
  }) => Maybe<GfFormToFormFieldConnection>;
  /**
   * Form ID.
   * @deprecated Deprecated in favor of the databaseId field.
   */
  formId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * When enabled, conditional logic hide/show operation will be performed with a jQuery slide animation. Only applicable to forms with conditional logic.
   */
  hasConditionalLogicAnimation?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Specifies if the form has the Honeypot spam-protection feature.
   */
  hasHoneypot?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * If enabled, will show a summary that lists form validation errors at the top of the form when a user attempts a failed submission.
   */
  hasValidationSummary?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums["ID"];
  /**
   * Determines whether the form is active.
   */
  isActive?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the form is in the trash.
   */
  isTrash?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines where the field labels should be placed in relation to the field.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormLabelPlacementEnum"]>;
  /**
   * Last page button data.
   * @deprecated Use `pagination.lastPageButton` instead
   */
  lastPageButton?: Maybe<FormLastPageButton>;
  /**
   * Login requirements data.
   */
  login?: Maybe<FormLogin>;
  /**
   * The Gravity Forms markup version.
   */
  markupVersion?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The ID to assign to the next field that is added to the form.
   */
  nextFieldId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The properties for all the email notifications which exist for a form.
   */
  notifications?: Maybe<Array<Maybe<FormNotification>>>;
  /**
   * Pagination data.
   */
  pagination?: Maybe<FormPagination>;
  /**
   * Personal data settings.
   */
  personalData?: Maybe<FormPersonalData>;
  /**
   * Post creation data.
   */
  postCreation?: Maybe<FormPostCreation>;
  /**
   * Type of indicator to use when field is required.
   */
  requiredIndicator?: Maybe<ScalarsEnums["FormFieldRequiredIndicatorEnum"]>;
  /**
   * \&quot;Save and Continue\&quot; data.
   */
  saveAndContinue?: Maybe<FormSaveAndContinue>;
  /**
   * Form scheduling data.
   */
  scheduling?: Maybe<FormSchedule>;
  /**
   * How sub-labels are aligned.
   */
  subLabelPlacement?: Maybe<ScalarsEnums["FormSubLabelPlacementEnum"]>;
  /**
   * Contains the form button settings such as the button text or image button source.
   */
  submitButton?: Maybe<FormSubmitButton>;
  /**
   * Form title.
   */
  title?: Maybe<ScalarsEnums["String"]>;
  /**
   * The version of Gravity Forms used to create this form.
   */
  version?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Connection between the GfForm type and the FormField type
 */
export interface GfFormToFormFieldConnection {
  __typename?: "GfFormToFormFieldConnection";
  /**
   * Edges for the GfFormToFormFieldConnection connection
   */
  edges?: Maybe<Array<Maybe<GfFormToFormFieldConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<FormField>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface GfFormToFormFieldConnectionEdge {
  __typename?: "GfFormToFormFieldConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<FormField>;
}

/**
 * Connection between the GfForm type and the GfEntry type
 */
export interface GfFormToGfEntryConnection {
  __typename?: "GfFormToGfEntryConnection";
  /**
   * The number of (filtered) entries submitted to the form.
   */
  count?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Edges for the GfFormToGfEntryConnection connection
   */
  edges?: Maybe<Array<Maybe<GfFormToGfEntryConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<GfEntry>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface GfFormToGfEntryConnectionEdge {
  __typename?: "GfFormToGfEntryConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<GfEntry>;
}

/**
 * Gravity Forms Logging Settings.
 */
export interface GfLogger {
  __typename?: "GfLogger";
  /**
   * Whether the logger is enabled.
   */
  isEnabled?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The name of the Gravity Forms logger.
   */
  name?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Gravity Forms Settings.
 */
export interface GfSettings {
  __typename?: "GfSettings";
  /**
   * The default currency for your forms. Used for product, credit card, and other fields.
   */
  currency?: Maybe<ScalarsEnums["GfCurrencyEnum"]>;
  /**
   * Whether Gravity Forms to download and install bug fixes and security updates automatically in the background. Requires a valid license key.
   */
  hasBackgroundUpdates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether to output Gravity Forms&#039; default CSS.
   */
  hasDefaultCss?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether to display the forms menu in the WordPress top toolbar. The forms menu will display the ten forms recently opened in the form editor.
   */
  hasToolbar?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the server-generated form markup uses HTML5.
   */
  isHtml5Enabled?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Enable to prevent extraneous scripts and styles from being printed on a Gravity Forms admin pages, reducing conflicts with other plugins and themes.
   */
  isNoConflictModeEnabled?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Logging settings.
   */
  logging?: Maybe<GfSettingsLogging>;
}

/**
 * Gravity Forms Logging Settings.
 */
export interface GfSettingsLogging {
  __typename?: "GfSettingsLogging";
  /**
   * Whether Gravity Forms internal logging is enabled. Logging allows you to easily debug the inner workings of Gravity Forms to solve any possible issues.
   */
  isLoggingEnabled?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * A list of registered Gravity Forms loggers and their configurations.
   */
  loggers?: Maybe<Array<Maybe<GfLogger>>>;
}

/**
 * A Gravity Forms submitted entry.
 */
export interface GfSubmittedEntry {
  __typename?: "GfSubmittedEntry";
  /**
   * The user who created the entry.
   */
  createdBy?: Maybe<User>;
  /**
   * Database ID of the user that submitted of the form if a logged in user submitted the form.
   */
  createdByDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Global ID of the user that submitted of the form if a logged in user submitted the form.
   */
  createdById?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * The date and time that the entry was created in local time.
   */
  dateCreated?: Maybe<ScalarsEnums["String"]>;
  /**
   * The date and time that the entry was created in GMT.
   */
  dateCreatedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The date and time that the entry was created in local time.
   */
  dateUpdated?: Maybe<ScalarsEnums["String"]>;
  /**
   * The date and time that the entry was updated in GMT.
   */
  dateUpdatedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The entry ID. Returns null for draft entries.
   * @deprecated Deprecated in favor of the databaseId field.
   */
  entryId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form object of the node.
   */
  form?: Maybe<GfForm>;
  /**
   * The database identifier of the form of the node.
   */
  formDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Connection between the GfEntry type and the FormField type
   */
  formFields: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<GfEntryToFormFieldConnectionWhereArgs>;
  }) => Maybe<GfEntryToFormFieldConnection>;
  /**
   * The globally unique identifier of the form of the node.
   */
  formId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums["ID"];
  /**
   * Client IP of user who submitted the form.
   */
  ip?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether the entry is a draft.
   */
  isDraft?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the entry has been read.
   */
  isRead?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Indicates if the entry has been starred (i.e marked with a star).
   */
  isStarred?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the entry has been submitted.
   */
  isSubmitted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * For forms with Post fields, this is the post object that was created.
   */
  post?: Maybe<Post>;
  /**
   * For forms with Post fields, this property contains the Id of the Post that was created.
   */
  postDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Source URL of page that contained the form when it was submitted.
   */
  sourceUrl?: Maybe<ScalarsEnums["String"]>;
  /**
   * The current status of the entry.
   */
  status?: Maybe<ScalarsEnums["EntryStatusEnum"]>;
  /**
   * Provides the name and version of both the browser and operating system from which the entry was submitted.
   */
  userAgent?: Maybe<ScalarsEnums["String"]>;
}

/**
 * gravityforms/form block
 */
export interface GravityformsFormBlock {
  __typename?: "GravityformsFormBlock";
  attributes?: Maybe<GravityformsFormBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface GravityformsFormBlockAttributes {
  __typename?: "GravityformsFormBlockAttributes";
  ajax: ScalarsEnums["Boolean"];
  description: ScalarsEnums["Boolean"];
  fieldValues?: Maybe<ScalarsEnums["String"]>;
  formId?: Maybe<ScalarsEnums["String"]>;
  formPreview: ScalarsEnums["Boolean"];
  imgPreview: ScalarsEnums["Boolean"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  tabindex?: Maybe<ScalarsEnums["String"]>;
  title: ScalarsEnums["Boolean"];
}

/**
 * A Gravity Forms   field.
 */
export interface HiddenField {
  __typename?: "HiddenField";
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Content node with hierarchical (parent/child) relationships
 */
export interface HierarchicalContentNode {
  __typename?: "MediaItem" | "Page";
  /**
   * Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root).
   */
  ancestors: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
  }) => Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
  /**
   * Connection between the HierarchicalContentNode type and the ContentNode type
   */
  children: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
  }) => Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
  /**
   * The parent of the node. The parent object can be of various types
   */
  parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
  /**
   * Database id of the parent node
   */
  parentDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The globally unique identifier of the parent node.
   */
  parentId?: Maybe<ScalarsEnums["ID"]>;
  $on: $HierarchicalContentNode;
}

/**
 * Connection between the HierarchicalContentNode type and the ContentNode type
 */
export interface HierarchicalContentNodeToContentNodeAncestorsConnection {
  __typename?: "HierarchicalContentNodeToContentNodeAncestorsConnection";
  /**
   * Edges for the HierarchicalContentNodeToContentNodeAncestorsConnection connection
   */
  edges?: Maybe<
    Array<Maybe<HierarchicalContentNodeToContentNodeAncestorsConnectionEdge>>
  >;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface HierarchicalContentNodeToContentNodeAncestorsConnectionEdge {
  __typename?: "HierarchicalContentNodeToContentNodeAncestorsConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<ContentNode>;
}

/**
 * Connection between the HierarchicalContentNode type and the ContentNode type
 */
export interface HierarchicalContentNodeToContentNodeChildrenConnection {
  __typename?: "HierarchicalContentNodeToContentNodeChildrenConnection";
  /**
   * Edges for the HierarchicalContentNodeToContentNodeChildrenConnection connection
   */
  edges?: Maybe<
    Array<Maybe<HierarchicalContentNodeToContentNodeChildrenConnectionEdge>>
  >;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface HierarchicalContentNodeToContentNodeChildrenConnectionEdge {
  __typename?: "HierarchicalContentNodeToContentNodeChildrenConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<ContentNode>;
}

/**
 * Connection between the HierarchicalContentNode type and the ContentNode type
 */
export interface HierarchicalContentNodeToParentContentNodeConnectionEdge {
  __typename?: "HierarchicalContentNodeToParentContentNodeConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<ContentNode>;
}

/**
 * Term node with hierarchical (parent/child) relationships
 */
export interface HierarchicalTermNode {
  __typename?: "Category";
  /**
   * Database id of the parent node
   */
  parentDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The globally unique identifier of the parent node.
   */
  parentId?: Maybe<ScalarsEnums["ID"]>;
  $on: $HierarchicalTermNode;
}

/**
 * A Gravity Forms   field.
 */
export interface HtmlField {
  __typename?: "HtmlField";
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * Content of an HTML block field to be displayed on the form.
   */
  content?: Maybe<ScalarsEnums["String"]>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Indicates whether the default margins are turned on to align the HTML content with other fields.
   */
  hasMargins?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * ideabox/toc block
 */
export interface IdeaboxTocBlock {
  __typename?: "IdeaboxTocBlock";
  attributes?: Maybe<IdeaboxTocBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface IdeaboxTocBlockAttributes {
  __typename?: "IdeaboxTocBlockAttributes";
  align?: Maybe<ScalarsEnums["String"]>;
  anchorsByTags: ScalarsEnums["BlockAttributesArray"];
  bgColor: ScalarsEnums["String"];
  borderColor: ScalarsEnums["String"];
  borderRadius: ScalarsEnums["Int"];
  borderWidth: ScalarsEnums["Int"];
  className?: Maybe<ScalarsEnums["String"]>;
  collapsable: ScalarsEnums["Boolean"];
  collapsableIcons: ScalarsEnums["String"];
  excludeContainer: ScalarsEnums["String"];
  extraOffset: ScalarsEnums["Int"];
  fontSize: ScalarsEnums["Int"];
  headerBgColor: ScalarsEnums["String"];
  headerFontSize: ScalarsEnums["Int"];
  headerTextColor: ScalarsEnums["String"];
  hierarchicalView: ScalarsEnums["Boolean"];
  includeContainer: ScalarsEnums["String"];
  linkColor: ScalarsEnums["String"];
  listSpacing: ScalarsEnums["Int"];
  listStyle: ScalarsEnums["String"];
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  separatorColor: ScalarsEnums["String"];
  separatorHeight: ScalarsEnums["Int"];
  textColor: ScalarsEnums["String"];
}

/**
 * The individual properties for each element of the PostImage value field.
 */
export interface ImageFieldValue {
  __typename?: "ImageFieldValue";
  /**
   * The image alt text.
   */
  altText?: Maybe<ScalarsEnums["String"]>;
  /**
   * The path to the parent directory of the file.
   */
  basePath?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base url to the parent directory of the file.
   */
  baseUrl?: Maybe<ScalarsEnums["String"]>;
  /**
   * The image caption.
   */
  caption?: Maybe<ScalarsEnums["String"]>;
  /**
   * The image description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The filename.
   */
  filename?: Maybe<ScalarsEnums["String"]>;
  /**
   * The image title.
   */
  title?: Maybe<ScalarsEnums["String"]>;
  /**
   * The url to the file.
   */
  url?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The job type
 */
export interface Job {
  __typename?: "Job";
  /**
   * Gutenberg blocks
   */
  blocks?: Maybe<Array<Block>>;
  /**
   * Gutenberg blocks as json string
   */
  blocksJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums["String"];
  /**
   * The unique resource identifier path
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums["String"]>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums["String"]>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier of the jobs object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * Added to the GraphQL Schema because the ACF Field Group &quot;Job&quot; was set to Show in GraphQL.
   */
  jobACF?: Maybe<Job_Jobacf>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  jobId: ScalarsEnums["Int"];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums["String"]>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums["String"]>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the job type and the job type
   */
  preview?: Maybe<JobToPreviewConnectionEdge>;
  /**
   * Previewed gutenberg blocks
   */
  previewBlocks?: Maybe<Array<Block>>;
  /**
   * Previewed Gutenberg blocks as json string
   */
  previewBlocksJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums["String"]>;
  /**
   * The template assigned to the node
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Connection between the job type and the job type
 */
export interface JobToPreviewConnectionEdge {
  __typename?: "JobToPreviewConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<Job>;
}

/**
 * Field Group
 */
export interface Job_Jobacf {
  __typename?: "Job_Jobacf";
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums["String"]>;
  link?: Maybe<ScalarsEnums["String"]>;
  location?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface ListField {
  __typename?: "ListField";
  /**
   * The URL of the image to be used for the add row button.
   */
  addIconUrl?: Maybe<ScalarsEnums["String"]>;
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The available choices for the list field.
   */
  choices?: Maybe<Array<Maybe<ListFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * The URL of the image to be used for the delete row button.
   */
  deleteIconUrl?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field should use multiple columns. Default is false.
   */
  hasColumns?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * List field value.
   */
  listValues?: Maybe<Array<Maybe<ListFieldValue>>>;
  /**
   * The maximum number of rows the user can add to the field.
   */
  maxRows?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * List choice values.
 */
export interface ListFieldChoice {
  __typename?: "ListFieldChoice";
  /**
   * The text to be displayed to the user when displaying this choice.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The individual properties for each element of the List value field.
 */
export interface ListFieldValue {
  __typename?: "ListFieldValue";
  /**
   * Input values.
   */
  values?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
}

/**
 * File details for a Media Item
 */
export interface MediaDetails {
  __typename?: "MediaDetails";
  /**
   * The filename of the mediaItem
   */
  file?: Maybe<ScalarsEnums["String"]>;
  /**
   * The height of the mediaItem
   */
  height?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Meta information associated with the mediaItem
   */
  meta?: Maybe<MediaItemMeta>;
  /**
   * The available sizes of the mediaItem
   */
  sizes?: Maybe<Array<Maybe<MediaSize>>>;
  /**
   * The width of the mediaItem
   */
  width?: Maybe<ScalarsEnums["Int"]>;
}

/**
 * The mediaItem type
 */
export interface MediaItem {
  __typename?: "MediaItem";
  /**
   * Alternative text to display when resource is not displayed
   */
  altText?: Maybe<ScalarsEnums["String"]>;
  /**
   * Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root).
   */
  ancestors: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
  }) => Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
  /**
   * Connection between the NodeWithAuthor type and the User type
   */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /**
   * The database identifier of the author of the node
   */
  authorDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The globally unique identifier of the author of the node
   */
  authorId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The caption for the resource
   */
  caption: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the HierarchicalContentNode type and the ContentNode type
   */
  children: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
  }) => Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
  /**
   * The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility.
   */
  commentCount?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Whether the comments are open or closed for this particular post.
   */
  commentStatus?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the mediaItem type and the Comment type
   */
  comments: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<MediaItemToCommentConnectionWhereArgs>;
  }) => Maybe<MediaItemToCommentConnection>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums["String"];
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums["String"]>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * Description of the image (stored as post_content)
   */
  description: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums["String"]>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * The filesize in bytes of the resource
   */
  fileSize: (args?: {
    /**
     * Size of the MediaItem to return
     */
    size?: Maybe<MediaItemSizeEnum>;
  }) => Maybe<ScalarsEnums["Int"]>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier of the attachment object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums["String"]>;
  /**
   * Details about the mediaItem
   */
  mediaDetails?: Maybe<MediaDetails>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  mediaItemId: ScalarsEnums["Int"];
  /**
   * Url of the mediaItem
   */
  mediaItemUrl?: Maybe<ScalarsEnums["String"]>;
  /**
   * Type of resource
   */
  mediaType?: Maybe<ScalarsEnums["String"]>;
  /**
   * The mime type of the mediaItem
   */
  mimeType?: Maybe<ScalarsEnums["String"]>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums["String"]>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The parent of the node. The parent object can be of various types
   */
  parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
  /**
   * Database id of the parent node
   */
  parentDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The globally unique identifier of the parent node.
   */
  parentId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The sizes attribute value for an image.
   */
  sizes: (args?: {
    /**
     * Size of the MediaItem to calculate sizes with
     */
    size?: Maybe<MediaItemSizeEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  /**
   * Url of the mediaItem
   */
  sourceUrl: (args?: {
    /**
     * Size of the MediaItem to return
     */
    size?: Maybe<MediaItemSizeEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * The srcset attribute specifies the URL of the image to use in different situations. It is a comma separated string of urls and their widths.
   */
  srcSet: (args?: {
    /**
     * Size of the MediaItem to calculate srcSet with
     */
    size?: Maybe<MediaItemSizeEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums["String"]>;
  /**
   * The template assigned to the node
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Meta connected to a MediaItem
 */
export interface MediaItemMeta {
  __typename?: "MediaItemMeta";
  /**
   * Aperture measurement of the media item.
   */
  aperture?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Information about the camera used to create the media item.
   */
  camera?: Maybe<ScalarsEnums["String"]>;
  /**
   * The text string description associated with the media item.
   */
  caption?: Maybe<ScalarsEnums["String"]>;
  /**
   * Copyright information associated with the media item.
   */
  copyright?: Maybe<ScalarsEnums["String"]>;
  /**
   * The date/time when the media was created.
   */
  createdTimestamp?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The original creator of the media item.
   */
  credit?: Maybe<ScalarsEnums["String"]>;
  /**
   * The focal length value of the media item.
   */
  focalLength?: Maybe<ScalarsEnums["Float"]>;
  /**
   * The ISO (International Organization for Standardization) value of the media item.
   */
  iso?: Maybe<ScalarsEnums["Int"]>;
  /**
   * List of keywords used to describe or identfy the media item.
   */
  keywords?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The vertical or horizontal aspect of the media item.
   */
  orientation?: Maybe<ScalarsEnums["String"]>;
  /**
   * The shutter speed information of the media item.
   */
  shutterSpeed?: Maybe<ScalarsEnums["Float"]>;
  /**
   * A useful title for the media item.
   */
  title?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Connection between the mediaItem type and the Comment type
 */
export interface MediaItemToCommentConnection {
  __typename?: "MediaItemToCommentConnection";
  /**
   * Edges for the MediaItemToCommentConnection connection
   */
  edges?: Maybe<Array<Maybe<MediaItemToCommentConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Comment>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface MediaItemToCommentConnectionEdge {
  __typename?: "MediaItemToCommentConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Comment>;
}

/**
 * Details of an available size for a media item
 */
export interface MediaSize {
  __typename?: "MediaSize";
  /**
   * The filename of the referenced size
   */
  file?: Maybe<ScalarsEnums["String"]>;
  /**
   * The filesize of the resource
   */
  fileSize?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The height of the referenced size
   */
  height?: Maybe<ScalarsEnums["String"]>;
  /**
   * The mime type of the referenced size
   */
  mimeType?: Maybe<ScalarsEnums["String"]>;
  /**
   * The referenced size name
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * The url of the referenced size
   */
  sourceUrl?: Maybe<ScalarsEnums["String"]>;
  /**
   * The width of the referenced size
   */
  width?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Menus are the containers for navigation items. Menus can be assigned to menu locations, which are typically registered by the active theme.
 */
export interface Menu {
  __typename?: "Menu";
  /**
   * The number of items in the menu
   */
  count?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * The globally unique identifier of the nav menu object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The locations a menu is assigned to
   */
  locations?: Maybe<Array<Maybe<ScalarsEnums["MenuLocationEnum"]>>>;
  /**
   * WP ID of the nav menu.
   * @deprecated Deprecated in favor of the databaseId field
   */
  menuId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Connection between the Menu type and the MenuItem type
   */
  menuItems: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<MenuToMenuItemConnectionWhereArgs>;
  }) => Maybe<MenuToMenuItemConnection>;
  /**
   * Display name of the menu. Equivalent to WP_Term-&gt;name.
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * The url friendly name of the menu. Equivalent to WP_Term-&gt;slug
   */
  slug?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Navigation menu items are the individual items assigned to a menu. These are rendered as the links in a navigation menu.
 */
export interface MenuItem {
  __typename?: "MenuItem";
  /**
   * Connection between the MenuItem type and the MenuItem type
   */
  childItems: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<MenuItemToMenuItemConnectionWhereArgs>;
  }) => Maybe<MenuItemToMenuItemConnection>;
  /**
   * Connection from MenuItem to it&#039;s connected node
   */
  connectedNode?: Maybe<MenuItemToMenuItemLinkableConnectionEdge>;
  /**
   * The object connected to this menu item.
   * @deprecated Deprecated in favor of the connectedNode field
   */
  connectedObject?: Maybe<MenuItemObjectUnion>;
  /**
   * Class attribute for the menu item link
   */
  cssClasses?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * Description of the menu item.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier of the nav menu item object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Label or title of the menu item.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * Link relationship (XFN) of the menu item.
   */
  linkRelationship?: Maybe<ScalarsEnums["String"]>;
  /**
   * The locations the menu item&#039;s Menu is assigned to
   */
  locations?: Maybe<Array<Maybe<ScalarsEnums["MenuLocationEnum"]>>>;
  /**
   * The Menu a MenuItem is part of
   */
  menu?: Maybe<MenuItemToMenuConnectionEdge>;
  /**
   * WP ID of the menu item.
   * @deprecated Deprecated in favor of the databaseId field
   */
  menuItemId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Menu item order
   */
  order?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The database id of the parent menu item or null if it is the root
   */
  parentDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The globally unique identifier of the parent nav menu item object.
   */
  parentId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * Path for the resource. Relative path for internal resources. Absolute path for external resources.
   */
  path?: Maybe<ScalarsEnums["String"]>;
  /**
   * Target attribute for the menu item link.
   */
  target?: Maybe<ScalarsEnums["String"]>;
  /**
   * Title attribute for the menu item link
   */
  title?: Maybe<ScalarsEnums["String"]>;
  /**
   * The uri of the resource the menu item links to
   */
  uri?: Maybe<ScalarsEnums["String"]>;
  /**
   * URL or destination of the menu item.
   */
  url?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Nodes that can be linked to as Menu Items
 */
export interface MenuItemLinkable {
  __typename?:
    | "Category"
    | "Job"
    | "Page"
    | "Post"
    | "Service"
    | "Specialty"
    | "Tag"
    | "Team_member"
    | "Work";
  /**
   * The unique resource identifier path
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * The unique resource identifier path
   */
  id: ScalarsEnums["ID"];
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
  $on: $MenuItemLinkable;
}

/**
 * Deprecated in favor of MenuItemLinkeable Interface
 */
export interface MenuItemObjectUnion {
  __typename?:
    | "Category"
    | "Job"
    | "Page"
    | "Post"
    | "Service"
    | "Specialty"
    | "Tag"
    | "Team_member"
    | "Work";
  $on: $MenuItemObjectUnion;
}

/**
 * Connection between the MenuItem type and the Menu type
 */
export interface MenuItemToMenuConnectionEdge {
  __typename?: "MenuItemToMenuConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<Menu>;
}

/**
 * Connection between the MenuItem type and the MenuItem type
 */
export interface MenuItemToMenuItemConnection {
  __typename?: "MenuItemToMenuItemConnection";
  /**
   * Edges for the MenuItemToMenuItemConnection connection
   */
  edges?: Maybe<Array<Maybe<MenuItemToMenuItemConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<MenuItem>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface MenuItemToMenuItemConnectionEdge {
  __typename?: "MenuItemToMenuItemConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<MenuItem>;
}

/**
 * Connection between the MenuItem type and the MenuItemLinkable type
 */
export interface MenuItemToMenuItemLinkableConnectionEdge {
  __typename?: "MenuItemToMenuItemLinkableConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<MenuItemLinkable>;
}

/**
 * Connection between the Menu type and the MenuItem type
 */
export interface MenuToMenuItemConnection {
  __typename?: "MenuToMenuItemConnection";
  /**
   * Edges for the MenuToMenuItemConnection connection
   */
  edges?: Maybe<Array<Maybe<MenuToMenuItemConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<MenuItem>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface MenuToMenuItemConnectionEdge {
  __typename?: "MenuToMenuItemConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<MenuItem>;
}

/**
 * A Gravity Forms   field.
 */
export interface MultiSelectField {
  __typename?: "MultiSelectField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The available choices for the multiselect field.
   */
  choices?: Maybe<Array<Maybe<MultiSelectFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields.
   */
  hasEnhancedUI?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * An array of field values.
   */
  values?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Multiselect choice values.
 */
export interface MultiSelectFieldChoice {
  __typename?: "MultiSelectFieldChoice";
  /**
   * Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected.
   */
  isSelected?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The text to be displayed to the user when displaying this choice.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface NameField {
  __typename?: "NameField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether autocomplete should be enabled for this field.
   */
  hasAutocomplete?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * An array of the available properties for each input of the name field.
   */
  inputs?: Maybe<Array<Maybe<NameInputProperty>>>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Name field value.
   */
  nameValues?: Maybe<NameFieldValue>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs.
   */
  subLabelPlacement?: Maybe<ScalarsEnums["FormFieldSubLabelPlacementEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Name choice values.
 */
export interface NameFieldChoice {
  __typename?: "NameFieldChoice";
  /**
   * Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected.
   */
  isSelected?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The text to be displayed to the user when displaying this choice.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The individual properties for each element of the Name value field.
 */
export interface NameFieldValue {
  __typename?: "NameFieldValue";
  /**
   * First name.
   */
  first?: Maybe<ScalarsEnums["String"]>;
  /**
   * Last name.
   */
  last?: Maybe<ScalarsEnums["String"]>;
  /**
   * Middle name.
   */
  middle?: Maybe<ScalarsEnums["String"]>;
  /**
   * Prefix, such as Mr., Mrs. etc.
   */
  prefix?: Maybe<ScalarsEnums["String"]>;
  /**
   * Suffix, such as Sr., Jr. etc.
   */
  suffix?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Name input values.
 */
export interface NameInputProperty {
  __typename?: "NameInputProperty";
  /**
   * The autocomplete attribute for the field.
   */
  autocompleteAttribute?: Maybe<ScalarsEnums["String"]>;
  /**
   * The available choices for the name field.
   */
  choices?: Maybe<Array<Maybe<NameFieldChoice>>>;
  /**
   * The custom label for the input. When set, this is used in place of the label.
   */
  customLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field.
   */
  id?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Whether or not this field should be hidden.
   */
  isHidden?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Key used to identify this input.
   */
  key?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
}

/**
 * An object with an ID
 */
export interface Node {
  __typename?:
    | "BlockEditorPreview"
    | "Category"
    | "Comment"
    | "CommentAuthor"
    | "ContentType"
    | "EnqueuedScript"
    | "EnqueuedStylesheet"
    | "GfDraftEntry"
    | "GfForm"
    | "GfSubmittedEntry"
    | "Job"
    | "MediaItem"
    | "Menu"
    | "MenuItem"
    | "Page"
    | "Plugin"
    | "Post"
    | "PostFormat"
    | "ReusableBlock"
    | "Service"
    | "Specialty"
    | "Tag"
    | "Taxonomy"
    | "Team_member"
    | "Theme"
    | "User"
    | "UserRole"
    | "Work";
  /**
   * The globally unique ID for the object
   */
  id: ScalarsEnums["ID"];
  $on: $Node;
}

/**
 * A node that can have an author assigned to it
 */
export interface NodeWithAuthor {
  __typename?: "BlockEditorPreview" | "MediaItem" | "Page" | "Post";
  /**
   * Connection between the NodeWithAuthor type and the User type
   */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /**
   * The database identifier of the author of the node
   */
  authorDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The globally unique identifier of the author of the node
   */
  authorId?: Maybe<ScalarsEnums["ID"]>;
  $on: $NodeWithAuthor;
}

/**
 * Connection between the NodeWithAuthor type and the User type
 */
export interface NodeWithAuthorToUserConnectionEdge {
  __typename?: "NodeWithAuthorToUserConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<User>;
}

/**
 * A node that can have comments associated with it
 */
export interface NodeWithComments {
  __typename?: "MediaItem" | "Page" | "Post";
  /**
   * The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility.
   */
  commentCount?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Whether the comments are open or closed for this particular post.
   */
  commentStatus?: Maybe<ScalarsEnums["String"]>;
  $on: $NodeWithComments;
}

/**
 * A node that supports the content editor
 */
export interface NodeWithContentEditor {
  __typename?:
    | "BlockEditorPreview"
    | "Job"
    | "Page"
    | "Post"
    | "ReusableBlock"
    | "Service"
    | "Specialty"
    | "Team_member"
    | "Work";
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  $on: $NodeWithContentEditor;
}

/**
 * A node that can have an excerpt
 */
export interface NodeWithExcerpt {
  __typename?: "Post";
  /**
   * The excerpt of the post.
   */
  excerpt: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  $on: $NodeWithExcerpt;
}

/**
 * A node that can have a featured image set
 */
export interface NodeWithFeaturedImage {
  __typename?:
    | "Page"
    | "Post"
    | "Service"
    | "Specialty"
    | "Team_member"
    | "Work";
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums["String"];
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums["String"]>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums["String"]>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * Connection between the NodeWithFeaturedImage type and the MediaItem type
   */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /**
   * The database identifier for the featured image node assigned to the content node
   */
  featuredImageDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Globally unique ID of the featured image assigned to the node
   */
  featuredImageId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums["String"]>;
  /**
   * The unique resource identifier path
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums["String"]>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums["String"]>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums["String"]>;
  /**
   * The template assigned to a node of content
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
  $on: $NodeWithFeaturedImage;
}

/**
 * Connection between the NodeWithFeaturedImage type and the MediaItem type
 */
export interface NodeWithFeaturedImageToMediaItemConnectionEdge {
  __typename?: "NodeWithFeaturedImageToMediaItemConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<MediaItem>;
}

/**
 * A node that can have a Gravity Forms form assigned to it.
 */
export interface NodeWithForm {
  __typename?: "GfDraftEntry" | "GfSubmittedEntry";
  /**
   * The form object of the node.
   */
  form?: Maybe<GfForm>;
  /**
   * The database identifier of the form of the node.
   */
  formDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The globally unique identifier of the form of the node.
   */
  formId?: Maybe<ScalarsEnums["ID"]>;
  $on: $NodeWithForm;
}

/**
 * A node that can have page attributes
 */
export interface NodeWithPageAttributes {
  __typename?: "Page";
  /**
   * A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<ScalarsEnums["Int"]>;
  $on: $NodeWithPageAttributes;
}

/**
 * A node that can have revisions
 */
export interface NodeWithRevisions {
  __typename?: "Page" | "Post" | "ReusableBlock";
  /**
   * True if the node is a revision of another node
   */
  isRevision?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node.
   */
  revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
  $on: $NodeWithRevisions;
}

/**
 * Connection between the NodeWithRevisions type and the ContentNode type
 */
export interface NodeWithRevisionsToContentNodeConnectionEdge {
  __typename?: "NodeWithRevisionsToContentNodeConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<ContentNode>;
}

/**
 * A node that can have a template associated with it
 */
export interface NodeWithTemplate {
  __typename?:
    | "BlockEditorPreview"
    | "Job"
    | "MediaItem"
    | "Page"
    | "Post"
    | "ReusableBlock"
    | "Service"
    | "Specialty"
    | "Team_member"
    | "Work";
  /**
   * The template assigned to the node
   */
  template?: Maybe<ContentTemplate>;
  $on: $NodeWithTemplate;
}

/**
 * A node that NodeWith a title
 */
export interface NodeWithTitle {
  __typename?:
    | "BlockEditorPreview"
    | "Job"
    | "MediaItem"
    | "Page"
    | "Post"
    | "ReusableBlock"
    | "Service"
    | "Specialty"
    | "Team_member"
    | "Work";
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  $on: $NodeWithTitle;
}

/**
 * A node that can have trackbacks and pingbacks
 */
export interface NodeWithTrackbacks {
  __typename?: "Post";
  /**
   * Whether the pings are open or closed for this particular post.
   */
  pingStatus?: Maybe<ScalarsEnums["String"]>;
  /**
   * URLs that have been pinged.
   */
  pinged?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * URLs queued to be pinged.
   */
  toPing?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  $on: $NodeWithTrackbacks;
}

/**
 * A Gravity Forms   field.
 */
export interface NumberField {
  __typename?: "NumberField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * The autocomplete attribute for the field.
   */
  autocompleteAttribute?: Maybe<ScalarsEnums["String"]>;
  /**
   * The formula used for the number field.
   */
  calculationFormula?: Maybe<ScalarsEnums["String"]>;
  /**
   * Specifies to how many decimal places the number should be rounded. This is available when isCalculation is true, but is not available when the chosen format is “Currency”.
   */
  calculationRounding?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether autocomplete should be enabled for this field.
   */
  hasAutocomplete?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Indicates whether the number field is a calculation.
   */
  isCalculation?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Specifies the format allowed for the number field.
   */
  numberFormat?: Maybe<ScalarsEnums["NumberFieldFormatEnum"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Maximum allowed value for a number field. Values higher than the number specified by this property will cause the field to fail validation.
   */
  rangeMax?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Minimum allowed value for a number field. Values lower than the number specified by this property will cause the field to fail validation.
   */
  rangeMin?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * The page type
 */
export interface Page {
  __typename?: "Page";
  /**
   * Added to the GraphQL Schema because the ACF Field Group &quot;About CTA&quot; was set to Show in GraphQL.
   */
  aboutCTA?: Maybe<Page_Aboutcta>;
  /**
   * Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root).
   */
  ancestors: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
  }) => Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
  /**
   * Connection between the NodeWithAuthor type and the User type
   */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /**
   * The database identifier of the author of the node
   */
  authorDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The globally unique identifier of the author of the node
   */
  authorId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * Gutenberg blocks
   */
  blocks?: Maybe<Array<Block>>;
  /**
   * Gutenberg blocks as json string
   */
  blocksJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the HierarchicalContentNode type and the ContentNode type
   */
  children: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
  }) => Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
  /**
   * The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility.
   */
  commentCount?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Whether the comments are open or closed for this particular post.
   */
  commentStatus?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the page type and the Comment type
   */
  comments: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PageToCommentConnectionWhereArgs>;
  }) => Maybe<PageToCommentConnection>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * Added to the GraphQL Schema because the ACF Field Group &quot;Hero&quot; was set to Show in GraphQL.
   */
  contentHero?: Maybe<Page_Contenthero>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums["String"];
  /**
   * The unique resource identifier path
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums["String"]>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums["String"]>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * Connection between the NodeWithFeaturedImage type and the MediaItem type
   */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /**
   * The database identifier for the featured image node assigned to the content node
   */
  featuredImageDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Globally unique ID of the featured image assigned to the node
   */
  featuredImageId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * Added to the GraphQL Schema because the ACF Field Group &quot;Features&quot; was set to Show in GraphQL.
   */
  features?: Maybe<Page_Features>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier of the page object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether this page is set to the static front page.
   */
  isFrontPage: ScalarsEnums["Boolean"];
  /**
   * Whether this page is set to the blog posts page.
   */
  isPostsPage: ScalarsEnums["Boolean"];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether this page is set to the privacy page.
   */
  isPrivacyPage: ScalarsEnums["Boolean"];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * True if the node is a revision of another node
   */
  isRevision?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums["String"]>;
  /**
   * A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums["String"]>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  pageId: ScalarsEnums["Int"];
  /**
   * The parent of the node. The parent object can be of various types
   */
  parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
  /**
   * Database id of the parent node
   */
  parentDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The globally unique identifier of the parent node.
   */
  parentId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * Connection between the page type and the page type
   */
  preview?: Maybe<PageToPreviewConnectionEdge>;
  /**
   * Previewed gutenberg blocks
   */
  previewBlocks?: Maybe<Array<Block>>;
  /**
   * Previewed Gutenberg blocks as json string
   */
  previewBlocksJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node.
   */
  revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
  /**
   * Connection between the page type and the page type
   */
  revisions: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PageToRevisionConnectionWhereArgs>;
  }) => Maybe<PageToRevisionConnection>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums["String"]>;
  /**
   * The template assigned to a node of content
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface PageField {
  __typename?: "PageField";
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * An array containing the the individual properties for the &quot;Next&quot; button.
   */
  nextButton?: Maybe<FormButton>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * An array containing the the individual properties for the &quot;Previous&quot; button.
   */
  previousButton?: Maybe<FormButton>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Connection between the page type and the Comment type
 */
export interface PageToCommentConnection {
  __typename?: "PageToCommentConnection";
  /**
   * Edges for the PageToCommentConnection connection
   */
  edges?: Maybe<Array<Maybe<PageToCommentConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Comment>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface PageToCommentConnectionEdge {
  __typename?: "PageToCommentConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Comment>;
}

/**
 * Connection between the page type and the page type
 */
export interface PageToPreviewConnectionEdge {
  __typename?: "PageToPreviewConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<Page>;
}

/**
 * Connection between the page type and the page type
 */
export interface PageToRevisionConnection {
  __typename?: "PageToRevisionConnection";
  /**
   * Edges for the pageToRevisionConnection connection
   */
  edges?: Maybe<Array<Maybe<PageToRevisionConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Page>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface PageToRevisionConnectionEdge {
  __typename?: "PageToRevisionConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Page>;
}

/**
 * Field Group
 */
export interface Page_Aboutcta {
  __typename?: "Page_Aboutcta";
  bullets?: Maybe<Array<Maybe<Page_Aboutcta_bullets>>>;
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums["String"]>;
  heading?: Maybe<ScalarsEnums["String"]>;
  image?: Maybe<MediaItem>;
}

/**
 * Field Group
 */
export interface Page_Aboutcta_bullets {
  __typename?: "Page_Aboutcta_bullets";
  bulletItem?: Maybe<ScalarsEnums["String"]>;
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Field Group
 */
export interface Page_Contenthero {
  __typename?: "Page_Contenthero";
  calendlyLink?: Maybe<ScalarsEnums["String"]>;
  content?: Maybe<ScalarsEnums["String"]>;
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums["String"]>;
  heading?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Field Group
 */
export interface Page_Features {
  __typename?: "Page_Features";
  features?: Maybe<Array<Maybe<Page_Features_features>>>;
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Field Group
 */
export interface Page_Features_features {
  __typename?: "Page_Features_features";
  bullettPoints?: Maybe<Array<Maybe<Page_Features_features_bullettPoints>>>;
  /**
   * Color used for bullet points. Should match primary color in image.
   */
  color?: Maybe<ScalarsEnums["String"]>;
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums["String"]>;
  heading?: Maybe<ScalarsEnums["String"]>;
  image?: Maybe<MediaItem>;
}

/**
 * Field Group
 */
export interface Page_Features_features_bullettPoints {
  __typename?: "Page_Features_features_bullettPoints";
  eachBullet?: Maybe<ScalarsEnums["String"]>;
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface PasswordField {
  __typename?: "PasswordField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Indicates whether the field displays the password strength indicator.
   */
  hasPasswordStrengthIndicator?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the Password visibility toggle should be enabled for this field.
   */
  hasPasswordVisibilityToggle?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * An array of the available properties for each input of the password field.
   */
  inputs?: Maybe<Array<Maybe<PasswordInputProperty>>>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Indicates how strong the password should be.
   */
  minPasswordStrength?: Maybe<ScalarsEnums["PasswordFieldMinStrengthEnum"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs.
   */
  subLabelPlacement?: Maybe<ScalarsEnums["FormFieldSubLabelPlacementEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Password input values.
 */
export interface PasswordInputProperty {
  __typename?: "PasswordInputProperty";
  /**
   * The custom label for the input. When set, this is used in place of the label.
   */
  customLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field.
   */
  id?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Whether or not this field should be hidden.
   */
  isHidden?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface PhoneField {
  __typename?: "PhoneField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * The autocomplete attribute for the field.
   */
  autocompleteAttribute?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether autocomplete should be enabled for this field.
   */
  hasAutocomplete?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Determines the allowed format for phones. If the phone value does not conform with the specified format, the field will fail validation.
   */
  phoneFormat?: Maybe<ScalarsEnums["PhoneFieldFormatEnum"]>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * An plugin object
 */
export interface Plugin {
  __typename?: "Plugin";
  /**
   * Name of the plugin author(s), may also be a company name.
   */
  author?: Maybe<ScalarsEnums["String"]>;
  /**
   * URI for the related author(s)/company website.
   */
  authorUri?: Maybe<ScalarsEnums["String"]>;
  /**
   * Description of the plugin.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier of the plugin object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Display name of the plugin.
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * Plugin path.
   */
  path?: Maybe<ScalarsEnums["String"]>;
  /**
   * URI for the plugin website. This is useful for directing users for support requests etc.
   */
  pluginUri?: Maybe<ScalarsEnums["String"]>;
  /**
   * Current version of the plugin.
   */
  version?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The post type
 */
export interface Post {
  __typename?: "Post";
  /**
   * Connection between the NodeWithAuthor type and the User type
   */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /**
   * The database identifier of the author of the node
   */
  authorDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The globally unique identifier of the author of the node
   */
  authorId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * Gutenberg blocks
   */
  blocks?: Maybe<Array<Block>>;
  /**
   * Gutenberg blocks as json string
   */
  blocksJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the post type and the category type
   */
  categories: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PostToCategoryConnectionWhereArgs>;
  }) => Maybe<PostToCategoryConnection>;
  /**
   * The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility.
   */
  commentCount?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Whether the comments are open or closed for this particular post.
   */
  commentStatus?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the post type and the Comment type
   */
  comments: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PostToCommentConnectionWhereArgs>;
  }) => Maybe<PostToCommentConnection>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums["String"];
  /**
   * The unique resource identifier path
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums["String"]>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums["String"]>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * The excerpt of the post.
   */
  excerpt: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the NodeWithFeaturedImage type and the MediaItem type
   */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /**
   * The database identifier for the featured image node assigned to the content node
   */
  featuredImageDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Globally unique ID of the featured image assigned to the node
   */
  featuredImageId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier of the post object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * True if the node is a revision of another node
   */
  isRevision?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether this page is sticky
   */
  isSticky: ScalarsEnums["Boolean"];
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums["String"]>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums["String"]>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * Next post
   */
  next?: Maybe<Post>;
  /**
   * Whether the pings are open or closed for this particular post.
   */
  pingStatus?: Maybe<ScalarsEnums["String"]>;
  /**
   * URLs that have been pinged.
   */
  pinged?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * Connection between the post type and the postFormat type
   */
  postFormats: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PostToPostFormatConnectionWhereArgs>;
  }) => Maybe<PostToPostFormatConnection>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  postId: ScalarsEnums["Int"];
  /**
   * Added to the GraphQL Schema because the ACF Field Group &quot;Posts&quot; was set to Show in GraphQL.
   */
  postsACF?: Maybe<Post_Postsacf>;
  /**
   * Connection between the post type and the post type
   */
  preview?: Maybe<PostToPreviewConnectionEdge>;
  /**
   * Previewed gutenberg blocks
   */
  previewBlocks?: Maybe<Array<Block>>;
  /**
   * Previewed Gutenberg blocks as json string
   */
  previewBlocksJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * Previous post
   */
  previous?: Maybe<Post>;
  /**
   * If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node.
   */
  revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
  /**
   * Connection between the post type and the post type
   */
  revisions: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PostToRevisionConnectionWhereArgs>;
  }) => Maybe<PostToRevisionConnection>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the post type and the tag type
   */
  tags: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PostToTagConnectionWhereArgs>;
  }) => Maybe<PostToTagConnection>;
  /**
   * The template assigned to a node of content
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * Connection between the post type and the TermNode type
   */
  terms: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PostToTermNodeConnectionWhereArgs>;
  }) => Maybe<PostToTermNodeConnection>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * URLs queued to be pinged.
   */
  toPing?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms  post_category field.
 */
export interface PostCategoryCheckboxField {
  __typename?: "PostCategoryCheckboxField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Checkbox field value.
   */
  checkboxValues?: Maybe<Array<Maybe<CheckboxFieldValue>>>;
  /**
   * The available choices for the post_category field.
   */
  choices?: Maybe<Array<Maybe<PostCategoryFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The dropdown placeholder for the field.
   */
  dropdownPlaceholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically.
   */
  hasAllCategories?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the \&quot;select all\&quot; choice should be displayed.
   */
  hasSelectAll?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * An array of the available properties for each input of the post_category field.
   */
  inputs?: Maybe<Array<Maybe<PostCategoryInputProperty>>>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface PostCategoryField {
  __typename?:
    | "PostCategoryCheckboxField"
    | "PostCategoryMultiSelectField"
    | "PostCategoryRadioField"
    | "PostCategorySelectField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The available choices for the post_category field.
   */
  choices?: Maybe<Array<Maybe<PostCategoryFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The dropdown placeholder for the field.
   */
  dropdownPlaceholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically.
   */
  hasAllCategories?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
  $on: $PostCategoryField;
}

/**
 * Post_category choice values.
 */
export interface PostCategoryFieldChoice {
  __typename?: "PostCategoryFieldChoice";
  /**
   * Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected.
   */
  isSelected?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The text to be displayed to the user when displaying this choice.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Post_category input values.
 */
export interface PostCategoryInputProperty {
  __typename?: "PostCategoryInputProperty";
  /**
   * The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field.
   */
  id?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  name?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms  post_category field.
 */
export interface PostCategoryMultiSelectField {
  __typename?: "PostCategoryMultiSelectField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The available choices for the post_category field.
   */
  choices?: Maybe<Array<Maybe<PostCategoryFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The dropdown placeholder for the field.
   */
  dropdownPlaceholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically.
   */
  hasAllCategories?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields.
   */
  hasEnhancedUI?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * An array of field values.
   */
  values?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A Gravity Forms  post_category field.
 */
export interface PostCategoryRadioField {
  __typename?: "PostCategoryRadioField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The available choices for the post_category field.
   */
  choices?: Maybe<Array<Maybe<PostCategoryFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The dropdown placeholder for the field.
   */
  dropdownPlaceholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically.
   */
  hasAllCategories?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor.
   */
  hasOtherChoice?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A Gravity Forms  post_category field.
 */
export interface PostCategorySelectField {
  __typename?: "PostCategorySelectField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * The autocomplete attribute for the field.
   */
  autocompleteAttribute?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The available choices for the post_category field.
   */
  choices?: Maybe<Array<Maybe<PostCategoryFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The dropdown placeholder for the field.
   */
  dropdownPlaceholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically.
   */
  hasAllCategories?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether autocomplete should be enabled for this field.
   */
  hasAutocomplete?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields.
   */
  hasEnhancedUI?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface PostContentField {
  __typename?: "PostContentField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Indicates whether the field uses the rich text editor interface.
   */
  hasRichTextEditor?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Specifies the maximum number of characters allowed in a text or textarea (paragraph) field.
   */
  maxLength?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A Gravity Forms  post_custom_field field.
 */
export interface PostCustomCheckboxField {
  __typename?: "PostCustomCheckboxField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Checkbox field value.
   */
  checkboxValues?: Maybe<Array<Maybe<CheckboxFieldValue>>>;
  /**
   * The available choices for the post_custom_field field.
   */
  choices?: Maybe<Array<Maybe<PostCustomCheckboxFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the \&quot;select all\&quot; choice should be displayed.
   */
  hasSelectAll?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * An array of the available properties for each input of the post_custom_field field.
   */
  inputs?: Maybe<Array<Maybe<PostCustomInputProperty>>>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * The post meta key to which the value should be assigned.
   */
  postMetaFieldName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Post_custom_field choice values.
 */
export interface PostCustomCheckboxFieldChoice {
  __typename?: "PostCustomCheckboxFieldChoice";
  /**
   * Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected.
   */
  isSelected?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The text to be displayed to the user when displaying this choice.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms  post_custom_field field.
 */
export interface PostCustomDateField {
  __typename?: "PostCustomDateField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines how the date field displays it’s calendar icon.
   */
  calendarIconType?: Maybe<ScalarsEnums["FormFieldCalendarIconTypeEnum"]>;
  /**
   * Contains the URL to the custom calendar icon. Only applicable when calendarIconType is set to custom.
   */
  calendarIconUrl?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines how the date is displayed.
   */
  dateFormat?: Maybe<ScalarsEnums["DateFieldFormatEnum"]>;
  /**
   * The type of date field to display.
   */
  dateType?: Maybe<ScalarsEnums["DateFieldTypeEnum"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * An array of the available properties for each input of the post_custom_field field.
   */
  inputs?: Maybe<Array<Maybe<PostCustomInputProperty>>>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * The post meta key to which the value should be assigned.
   */
  postMetaFieldName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs.
   */
  subLabelPlacement?: Maybe<ScalarsEnums["FormFieldSubLabelPlacementEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A Gravity Forms  post_custom_field field.
 */
export interface PostCustomEmailField {
  __typename?: "PostCustomEmailField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * The autocomplete attribute for the field.
   */
  autocompleteAttribute?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether autocomplete should be enabled for this field.
   */
  hasAutocomplete?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines whether the Confirm Email field is active.
   */
  hasEmailConfirmation?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * An array of the available properties for each input of the post_custom_field field.
   */
  inputs?: Maybe<Array<Maybe<PostCustomInputProperty>>>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * The post meta key to which the value should be assigned.
   */
  postMetaFieldName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs.
   */
  subLabelPlacement?: Maybe<ScalarsEnums["FormFieldSubLabelPlacementEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface PostCustomField {
  __typename?:
    | "PostCustomCheckboxField"
    | "PostCustomDateField"
    | "PostCustomEmailField"
    | "PostCustomFileuploadField"
    | "PostCustomHiddenField"
    | "PostCustomListField"
    | "PostCustomMultiSelectField"
    | "PostCustomNumberField"
    | "PostCustomPhoneField"
    | "PostCustomRadioField"
    | "PostCustomSelectField"
    | "PostCustomTextAreaField"
    | "PostCustomTextField"
    | "PostCustomTimeField"
    | "PostCustomWebsiteField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * The post meta key to which the value should be assigned.
   */
  postMetaFieldName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
  $on: $PostCustomField;
}

/**
 * A Gravity Forms  post_custom_field field.
 */
export interface PostCustomFileuploadField {
  __typename?: "PostCustomFileuploadField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * A comma-delimited list of the file extensions which may be uploaded.
   */
  allowedExtensions?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * Indicates whether multiple files may be uploaded.
   */
  canAcceptMultipleFiles?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * File upload value
   */
  fileUploadValues?: Maybe<Array<Maybe<FileUploadFieldValue>>>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The maximum size (in MB) an uploaded file may be .
   */
  maxFileSize?: Maybe<ScalarsEnums["Int"]>;
  /**
   * When the field is set to allow multiple files to be uploaded, this property is available to set a limit on how many may be uploaded.
   */
  maxFiles?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * The post meta key to which the value should be assigned.
   */
  postMetaFieldName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * An array of field values.
   * @deprecated Use `fileUploadValues` instead.
   */
  values?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A Gravity Forms  post_custom_field field.
 */
export interface PostCustomHiddenField {
  __typename?: "PostCustomHiddenField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * The post meta key to which the value should be assigned.
   */
  postMetaFieldName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Post_custom_field input values.
 */
export interface PostCustomInputProperty {
  __typename?: "PostCustomInputProperty";
  /**
   * The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field.
   */
  id?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  name?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms  post_custom_field field.
 */
export interface PostCustomListField {
  __typename?: "PostCustomListField";
  /**
   * The URL of the image to be used for the add row button.
   */
  addIconUrl?: Maybe<ScalarsEnums["String"]>;
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The available choices for the post_custom_field field.
   */
  choices?: Maybe<Array<Maybe<PostCustomListFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * The URL of the image to be used for the delete row button.
   */
  deleteIconUrl?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field should use multiple columns. Default is false.
   */
  hasColumns?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * List field value.
   */
  listValues?: Maybe<Array<Maybe<ListFieldValue>>>;
  /**
   * The maximum number of rows the user can add to the field.
   */
  maxRows?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * The post meta key to which the value should be assigned.
   */
  postMetaFieldName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Post_custom_field choice values.
 */
export interface PostCustomListFieldChoice {
  __typename?: "PostCustomListFieldChoice";
  /**
   * The text to be displayed to the user when displaying this choice.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms  post_custom_field field.
 */
export interface PostCustomMultiSelectField {
  __typename?: "PostCustomMultiSelectField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The available choices for the post_custom_field field.
   */
  choices?: Maybe<Array<Maybe<PostCustomMultiSelectFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields.
   */
  hasEnhancedUI?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * The post meta key to which the value should be assigned.
   */
  postMetaFieldName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * An array of field values.
   */
  values?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Post_custom_field choice values.
 */
export interface PostCustomMultiSelectFieldChoice {
  __typename?: "PostCustomMultiSelectFieldChoice";
  /**
   * Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected.
   */
  isSelected?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The text to be displayed to the user when displaying this choice.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms  post_custom_field field.
 */
export interface PostCustomNumberField {
  __typename?: "PostCustomNumberField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * The autocomplete attribute for the field.
   */
  autocompleteAttribute?: Maybe<ScalarsEnums["String"]>;
  /**
   * The formula used for the number field.
   */
  calculationFormula?: Maybe<ScalarsEnums["String"]>;
  /**
   * Specifies to how many decimal places the number should be rounded. This is available when isCalculation is true, but is not available when the chosen format is “Currency”.
   */
  calculationRounding?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether autocomplete should be enabled for this field.
   */
  hasAutocomplete?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Indicates whether the number field is a calculation.
   */
  isCalculation?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Specifies the format allowed for the number field.
   */
  numberFormat?: Maybe<ScalarsEnums["NumberFieldFormatEnum"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * The post meta key to which the value should be assigned.
   */
  postMetaFieldName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Maximum allowed value for a number field. Values higher than the number specified by this property will cause the field to fail validation.
   */
  rangeMax?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Minimum allowed value for a number field. Values lower than the number specified by this property will cause the field to fail validation.
   */
  rangeMin?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A Gravity Forms  post_custom_field field.
 */
export interface PostCustomPhoneField {
  __typename?: "PostCustomPhoneField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * The autocomplete attribute for the field.
   */
  autocompleteAttribute?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether autocomplete should be enabled for this field.
   */
  hasAutocomplete?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Determines the allowed format for phones. If the phone value does not conform with the specified format, the field will fail validation.
   */
  phoneFormat?: Maybe<ScalarsEnums["PhoneFieldFormatEnum"]>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * The post meta key to which the value should be assigned.
   */
  postMetaFieldName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A Gravity Forms  post_custom_field field.
 */
export interface PostCustomRadioField {
  __typename?: "PostCustomRadioField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The available choices for the post_custom_field field.
   */
  choices?: Maybe<Array<Maybe<PostCustomRadioFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor.
   */
  hasOtherChoice?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * The post meta key to which the value should be assigned.
   */
  postMetaFieldName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Post_custom_field choice values.
 */
export interface PostCustomRadioFieldChoice {
  __typename?: "PostCustomRadioFieldChoice";
  /**
   * Indicates the radio button item is the “Other” choice.
   */
  isOtherChoice?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected.
   */
  isSelected?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The text to be displayed to the user when displaying this choice.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms  post_custom_field field.
 */
export interface PostCustomSelectField {
  __typename?: "PostCustomSelectField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * The autocomplete attribute for the field.
   */
  autocompleteAttribute?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The available choices for the post_custom_field field.
   */
  choices?: Maybe<Array<Maybe<PostCustomSelectFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether autocomplete should be enabled for this field.
   */
  hasAutocomplete?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields.
   */
  hasEnhancedUI?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * The post meta key to which the value should be assigned.
   */
  postMetaFieldName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Post_custom_field choice values.
 */
export interface PostCustomSelectFieldChoice {
  __typename?: "PostCustomSelectFieldChoice";
  /**
   * Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected.
   */
  isSelected?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The text to be displayed to the user when displaying this choice.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms  post_custom_field field.
 */
export interface PostCustomTextAreaField {
  __typename?: "PostCustomTextAreaField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Indicates whether the field uses the rich text editor interface.
   */
  hasRichTextEditor?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Specifies the maximum number of characters allowed in a text or textarea (paragraph) field.
   */
  maxLength?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * The post meta key to which the value should be assigned.
   */
  postMetaFieldName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A Gravity Forms  post_custom_field field.
 */
export interface PostCustomTextField {
  __typename?: "PostCustomTextField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * The autocomplete attribute for the field.
   */
  autocompleteAttribute?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether autocomplete should be enabled for this field.
   */
  hasAutocomplete?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the field has an input mask.
   */
  hasInputMask?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * The pattern used for the input mask.
   */
  inputMaskValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if a text field input tag should be created with a &quot;password&quot; type.
   */
  isPasswordInput?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Specifies the maximum number of characters allowed in a text or textarea (paragraph) field.
   */
  maxLength?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * The post meta key to which the value should be assigned.
   */
  postMetaFieldName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A Gravity Forms  post_custom_field field.
 */
export interface PostCustomTimeField {
  __typename?: "PostCustomTimeField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * An array of the available properties for each input of the post_custom_field field.
   */
  inputs?: Maybe<Array<Maybe<PostCustomInputProperty>>>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * The post meta key to which the value should be assigned.
   */
  postMetaFieldName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs.
   */
  subLabelPlacement?: Maybe<ScalarsEnums["FormFieldSubLabelPlacementEnum"]>;
  /**
   * Determines how the time is displayed.
   */
  timeFormat?: Maybe<ScalarsEnums["TimeFieldFormatEnum"]>;
  /**
   * Time field value.
   */
  timeValues?: Maybe<TimeFieldValue>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A Gravity Forms  post_custom_field field.
 */
export interface PostCustomWebsiteField {
  __typename?: "PostCustomWebsiteField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * The post meta key to which the value should be assigned.
   */
  postMetaFieldName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface PostExcerptField {
  __typename?: "PostExcerptField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Specifies the maximum number of characters allowed in a text or textarea (paragraph) field.
   */
  maxLength?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * The postFormat type
 */
export interface PostFormat {
  __typename?: "PostFormat";
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Connection between the postFormat type and the ContentNode type
   */
  contentNodes: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PostFormatToContentNodeConnectionWhereArgs>;
  }) => Maybe<PostFormatToContentNodeConnection>;
  /**
   * The number of objects connected to the object
   */
  count?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * The description of the object
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the TermNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<TermNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the TermNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /**
   * The unique resource identifier path
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * The link to the term
   */
  link?: Maybe<ScalarsEnums["String"]>;
  /**
   * The human friendly name of the object.
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of databaseId
   */
  postFormatId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Connection between the postFormat type and the post type
   */
  posts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<PostFormatToPostConnectionWhereArgs>;
  }) => Maybe<PostFormatToPostConnection>;
  /**
   * The Yoast SEO data of the Formats taxonomy.
   */
  seo?: Maybe<TaxonomySEO>;
  /**
   * An alphanumeric identifier for the object unique to its type.
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the postFormat type and the Taxonomy type
   */
  taxonomy?: Maybe<PostFormatToTaxonomyConnectionEdge>;
  /**
   * The name of the taxonomy that the object is associated with
   */
  taxonomyName?: Maybe<ScalarsEnums["String"]>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The ID of the term group that this term object belongs to
   */
  termGroupId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The taxonomy ID that the object is associated with
   */
  termTaxonomyId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Connection between the postFormat type and the ContentNode type
 */
export interface PostFormatToContentNodeConnection {
  __typename?: "PostFormatToContentNodeConnection";
  /**
   * Edges for the PostFormatToContentNodeConnection connection
   */
  edges?: Maybe<Array<Maybe<PostFormatToContentNodeConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface PostFormatToContentNodeConnectionEdge {
  __typename?: "PostFormatToContentNodeConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<ContentNode>;
}

/**
 * Connection between the postFormat type and the post type
 */
export interface PostFormatToPostConnection {
  __typename?: "PostFormatToPostConnection";
  /**
   * Edges for the PostFormatToPostConnection connection
   */
  edges?: Maybe<Array<Maybe<PostFormatToPostConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Post>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface PostFormatToPostConnectionEdge {
  __typename?: "PostFormatToPostConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Post>;
}

/**
 * Connection between the postFormat type and the Taxonomy type
 */
export interface PostFormatToTaxonomyConnectionEdge {
  __typename?: "PostFormatToTaxonomyConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<Taxonomy>;
}

/**
 * A Gravity Forms   field.
 */
export interface PostImageField {
  __typename?: "PostImageField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * A comma-delimited list of the file extensions which may be uploaded.
   */
  allowedExtensions?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Controls the visibility of the alt metadata for Post Image fields.
   */
  hasAlt?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the caption metadata for Post Image fields.
   */
  hasCaption?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the description metadata for Post Image fields.
   */
  hasDescription?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the title metadata for Post Image fields.
   */
  hasTitle?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Image field value.
   */
  imageValues?: Maybe<ImageFieldValue>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Whether the image field should be used to set the post&#039;s Featured Image
   */
  isFeaturedImage?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs.
   */
  subLabelPlacement?: Maybe<ScalarsEnums["FormFieldSubLabelPlacementEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Union between the post, page and media item types
 */
export interface PostObjectUnion {
  __typename?:
    | "BlockEditorPreview"
    | "Job"
    | "MediaItem"
    | "Page"
    | "Post"
    | "ReusableBlock"
    | "Service"
    | "Specialty"
    | "Team_member"
    | "Work";
  $on: $PostObjectUnion;
}

/**
 * A Gravity Forms  post_tags field.
 */
export interface PostTagsCheckboxField {
  __typename?: "PostTagsCheckboxField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Checkbox field value.
   */
  checkboxValues?: Maybe<Array<Maybe<CheckboxFieldValue>>>;
  /**
   * The available choices for the post_tags field.
   */
  choices?: Maybe<Array<Maybe<PostTagsCheckboxFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the \&quot;select all\&quot; choice should be displayed.
   */
  hasSelectAll?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * An array of the available properties for each input of the post_tags field.
   */
  inputs?: Maybe<Array<Maybe<PostTagsInputProperty>>>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Post_tags choice values.
 */
export interface PostTagsCheckboxFieldChoice {
  __typename?: "PostTagsCheckboxFieldChoice";
  /**
   * Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected.
   */
  isSelected?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The text to be displayed to the user when displaying this choice.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface PostTagsField {
  __typename?:
    | "PostTagsCheckboxField"
    | "PostTagsMultiSelectField"
    | "PostTagsRadioField"
    | "PostTagsSelectField"
    | "PostTagsTextField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
  $on: $PostTagsField;
}

/**
 * Post_tags input values.
 */
export interface PostTagsInputProperty {
  __typename?: "PostTagsInputProperty";
  /**
   * The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field.
   */
  id?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  name?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms  post_tags field.
 */
export interface PostTagsMultiSelectField {
  __typename?: "PostTagsMultiSelectField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The available choices for the post_tags field.
   */
  choices?: Maybe<Array<Maybe<PostTagsMultiSelectFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields.
   */
  hasEnhancedUI?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * An array of field values.
   */
  values?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Post_tags choice values.
 */
export interface PostTagsMultiSelectFieldChoice {
  __typename?: "PostTagsMultiSelectFieldChoice";
  /**
   * Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected.
   */
  isSelected?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The text to be displayed to the user when displaying this choice.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms  post_tags field.
 */
export interface PostTagsRadioField {
  __typename?: "PostTagsRadioField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The available choices for the post_tags field.
   */
  choices?: Maybe<Array<Maybe<PostTagsRadioFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor.
   */
  hasOtherChoice?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Post_tags choice values.
 */
export interface PostTagsRadioFieldChoice {
  __typename?: "PostTagsRadioFieldChoice";
  /**
   * Indicates the radio button item is the “Other” choice.
   */
  isOtherChoice?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected.
   */
  isSelected?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The text to be displayed to the user when displaying this choice.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms  post_tags field.
 */
export interface PostTagsSelectField {
  __typename?: "PostTagsSelectField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * The autocomplete attribute for the field.
   */
  autocompleteAttribute?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The available choices for the post_tags field.
   */
  choices?: Maybe<Array<Maybe<PostTagsSelectFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether autocomplete should be enabled for this field.
   */
  hasAutocomplete?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields.
   */
  hasEnhancedUI?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Post_tags choice values.
 */
export interface PostTagsSelectFieldChoice {
  __typename?: "PostTagsSelectFieldChoice";
  /**
   * Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected.
   */
  isSelected?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The text to be displayed to the user when displaying this choice.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms  post_tags field.
 */
export interface PostTagsTextField {
  __typename?: "PostTagsTextField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * The autocomplete attribute for the field.
   */
  autocompleteAttribute?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether autocomplete should be enabled for this field.
   */
  hasAutocomplete?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the field has an input mask.
   */
  hasInputMask?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * The pattern used for the input mask.
   */
  inputMaskValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if a text field input tag should be created with a &quot;password&quot; type.
   */
  isPasswordInput?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Specifies the maximum number of characters allowed in a text or textarea (paragraph) field.
   */
  maxLength?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface PostTitleField {
  __typename?: "PostTitleField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Connection between the post type and the category type
 */
export interface PostToCategoryConnection {
  __typename?: "PostToCategoryConnection";
  /**
   * Edges for the PostToCategoryConnection connection
   */
  edges?: Maybe<Array<Maybe<PostToCategoryConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Category>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface PostToCategoryConnectionEdge {
  __typename?: "PostToCategoryConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Yoast SEO Primary category
   */
  isPrimary?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Category>;
}

/**
 * Connection between the post type and the Comment type
 */
export interface PostToCommentConnection {
  __typename?: "PostToCommentConnection";
  /**
   * Edges for the PostToCommentConnection connection
   */
  edges?: Maybe<Array<Maybe<PostToCommentConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Comment>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface PostToCommentConnectionEdge {
  __typename?: "PostToCommentConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Comment>;
}

/**
 * Connection between the post type and the postFormat type
 */
export interface PostToPostFormatConnection {
  __typename?: "PostToPostFormatConnection";
  /**
   * Edges for the PostToPostFormatConnection connection
   */
  edges?: Maybe<Array<Maybe<PostToPostFormatConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<PostFormat>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface PostToPostFormatConnectionEdge {
  __typename?: "PostToPostFormatConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Yoast SEO Primary post_format
   */
  isPrimary?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<PostFormat>;
}

/**
 * Connection between the post type and the post type
 */
export interface PostToPreviewConnectionEdge {
  __typename?: "PostToPreviewConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<Post>;
}

/**
 * Connection between the post type and the post type
 */
export interface PostToRevisionConnection {
  __typename?: "PostToRevisionConnection";
  /**
   * Edges for the postToRevisionConnection connection
   */
  edges?: Maybe<Array<Maybe<PostToRevisionConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Post>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface PostToRevisionConnectionEdge {
  __typename?: "PostToRevisionConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Post>;
}

/**
 * Connection between the post type and the tag type
 */
export interface PostToTagConnection {
  __typename?: "PostToTagConnection";
  /**
   * Edges for the PostToTagConnection connection
   */
  edges?: Maybe<Array<Maybe<PostToTagConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Tag>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface PostToTagConnectionEdge {
  __typename?: "PostToTagConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Yoast SEO Primary post_tag
   */
  isPrimary?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Tag>;
}

/**
 * Connection between the post type and the TermNode type
 */
export interface PostToTermNodeConnection {
  __typename?: "PostToTermNodeConnection";
  /**
   * Edges for the PostToTermNodeConnection connection
   */
  edges?: Maybe<Array<Maybe<PostToTermNodeConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<TermNode>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface PostToTermNodeConnectionEdge {
  __typename?: "PostToTermNodeConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<TermNode>;
}

/**
 * Details for labels of the PostType
 */
export interface PostTypeLabelDetails {
  __typename?: "PostTypeLabelDetails";
  /**
   * Default is ‘Add New’ for both hierarchical and non-hierarchical types.
   */
  addNew?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label for adding a new singular item.
   */
  addNewItem?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label to signify all items in a submenu link.
   */
  allItems?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label for archives in nav menus
   */
  archives?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label for the attributes meta box.
   */
  attributes?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label for editing a singular item.
   */
  editItem?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label for the Featured Image meta box title.
   */
  featuredImage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label for the table views hidden heading.
   */
  filterItemsList?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label for the media frame button.
   */
  insertIntoItem?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label for the table hidden heading.
   */
  itemsList?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label for the table pagination hidden heading.
   */
  itemsListNavigation?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label for the menu name.
   */
  menuName?: Maybe<ScalarsEnums["String"]>;
  /**
   * General name for the post type, usually plural.
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label for the new item page title.
   */
  newItem?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label used when no items are found.
   */
  notFound?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label used when no items are in the trash.
   */
  notFoundInTrash?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label used to prefix parents of hierarchical items.
   */
  parentItemColon?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label for removing the featured image.
   */
  removeFeaturedImage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label for searching plural items.
   */
  searchItems?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label for setting the featured image.
   */
  setFeaturedImage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Name for one object of this post type.
   */
  singularName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label for the media frame filter.
   */
  uploadedToThisItem?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label in the media frame for using a featured image.
   */
  useFeaturedImage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label for viewing a singular item.
   */
  viewItem?: Maybe<ScalarsEnums["String"]>;
  /**
   * Label for viewing post type archives.
   */
  viewItems?: Maybe<ScalarsEnums["String"]>;
}

export interface PostTypeSEO {
  __typename?: "PostTypeSEO";
  breadcrumbs?: Maybe<Array<Maybe<SEOPostTypeBreadcrumbs>>>;
  canonical?: Maybe<ScalarsEnums["String"]>;
  cornerstone?: Maybe<ScalarsEnums["Boolean"]>;
  focuskw?: Maybe<ScalarsEnums["String"]>;
  fullHead?: Maybe<ScalarsEnums["String"]>;
  metaDesc?: Maybe<ScalarsEnums["String"]>;
  metaKeywords?: Maybe<ScalarsEnums["String"]>;
  metaRobotsNofollow?: Maybe<ScalarsEnums["String"]>;
  metaRobotsNoindex?: Maybe<ScalarsEnums["String"]>;
  opengraphAuthor?: Maybe<ScalarsEnums["String"]>;
  opengraphDescription?: Maybe<ScalarsEnums["String"]>;
  opengraphImage?: Maybe<MediaItem>;
  opengraphModifiedTime?: Maybe<ScalarsEnums["String"]>;
  opengraphPublishedTime?: Maybe<ScalarsEnums["String"]>;
  opengraphPublisher?: Maybe<ScalarsEnums["String"]>;
  opengraphSiteName?: Maybe<ScalarsEnums["String"]>;
  opengraphTitle?: Maybe<ScalarsEnums["String"]>;
  opengraphType?: Maybe<ScalarsEnums["String"]>;
  opengraphUrl?: Maybe<ScalarsEnums["String"]>;
  readingTime?: Maybe<ScalarsEnums["Float"]>;
  schema?: Maybe<SEOPostTypeSchema>;
  title?: Maybe<ScalarsEnums["String"]>;
  twitterDescription?: Maybe<ScalarsEnums["String"]>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Field Group
 */
export interface Post_Postsacf {
  __typename?: "Post_Postsacf";
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums["String"]>;
  series?: Maybe<PostObjectUnion>;
}

/**
 * A Gravity Forms   field.
 */
export interface RadioField {
  __typename?: "RadioField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The available choices for the radio field.
   */
  choices?: Maybe<Array<Maybe<RadioFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor.
   */
  hasOtherChoice?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Radio choice values.
 */
export interface RadioFieldChoice {
  __typename?: "RadioFieldChoice";
  /**
   * Indicates the radio button item is the “Other” choice.
   */
  isOtherChoice?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected.
   */
  isSelected?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The text to be displayed to the user when displaying this choice.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The reading setting type
 */
export interface ReadingSettings {
  __typename?: "ReadingSettings";
  /**
   * The ID of the page that should display the latest posts
   */
  pageForPosts?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The ID of the page that should be displayed on the front page
   */
  pageOnFront?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Blog pages show at most.
   */
  postsPerPage?: Maybe<ScalarsEnums["Int"]>;
  /**
   * What to show on the front page
   */
  showOnFront?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The payload for the registerUser mutation
 */
export interface RegisterUserPayload {
  __typename?: "RegisterUserPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The User object mutation type.
   */
  user?: Maybe<User>;
}

/**
 * The payload for the resetUserPassword mutation
 */
export interface ResetUserPasswordPayload {
  __typename?: "ResetUserPasswordPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The User object mutation type.
   */
  user?: Maybe<User>;
}

/**
 * The payload for the restoreComment mutation
 */
export interface RestoreCommentPayload {
  __typename?: "RestoreCommentPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The restored comment object
   */
  comment?: Maybe<Comment>;
  /**
   * The ID of the restored comment
   */
  restoredId?: Maybe<ScalarsEnums["ID"]>;
}

/**
 * The ReusableBlock type
 */
export interface ReusableBlock {
  __typename?: "ReusableBlock";
  /**
   * Gutenberg blocks
   */
  blocks?: Maybe<Array<Block>>;
  /**
   * Gutenberg blocks as json string
   */
  blocksJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums["String"];
  /**
   * The unique identifier stored in the database
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums["String"]>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums["String"]>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier of the wp_block object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * True if the node is a revision of another node
   */
  isRevision?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums["String"]>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums["String"]>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ReusableBlock type and the ReusableBlock type
   */
  preview?: Maybe<ReusableBlockToPreviewConnectionEdge>;
  /**
   * Previewed gutenberg blocks
   */
  previewBlocks?: Maybe<Array<Block>>;
  /**
   * Previewed gutenberg blocks
   */
  previewBlocksFrom: (args: {
    databaseId: Scalars["Int"];
  }) => Maybe<Array<Block>>;
  /**
   * Previewed gutenberg blocks as json string
   */
  previewBlocksFromJSON: (args: {
    databaseId: Scalars["Int"];
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * Previewed Gutenberg blocks as json string
   */
  previewBlocksJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  reusableBlockId: ScalarsEnums["Int"];
  /**
   * If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node.
   */
  revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
  /**
   * Connection between the ReusableBlock type and the ReusableBlock type
   */
  revisions: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<ReusableBlockToRevisionConnectionWhereArgs>;
  }) => Maybe<ReusableBlockToRevisionConnection>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums["String"]>;
  /**
   * The template assigned to the node
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Connection between the ReusableBlock type and the ReusableBlock type
 */
export interface ReusableBlockToPreviewConnectionEdge {
  __typename?: "ReusableBlockToPreviewConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<ReusableBlock>;
}

/**
 * Connection between the ReusableBlock type and the ReusableBlock type
 */
export interface ReusableBlockToRevisionConnection {
  __typename?: "ReusableBlockToRevisionConnection";
  /**
   * Edges for the ReusableBlockToRevisionConnection connection
   */
  edges?: Maybe<Array<Maybe<ReusableBlockToRevisionConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<ReusableBlock>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface ReusableBlockToRevisionConnectionEdge {
  __typename?: "ReusableBlockToRevisionConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<ReusableBlock>;
}

/**
 * Connection between the RootQuery type and the BlockEditorPreview type
 */
export interface RootQueryToBlockEditorPreviewConnection {
  __typename?: "RootQueryToBlockEditorPreviewConnection";
  /**
   * Edges for the RootQueryToBlockEditorPreviewConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToBlockEditorPreviewConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<BlockEditorPreview>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToBlockEditorPreviewConnectionEdge {
  __typename?: "RootQueryToBlockEditorPreviewConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<BlockEditorPreview>;
}

/**
 * Connection between the RootQuery type and the category type
 */
export interface RootQueryToCategoryConnection {
  __typename?: "RootQueryToCategoryConnection";
  /**
   * Edges for the RootQueryToCategoryConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToCategoryConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Category>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToCategoryConnectionEdge {
  __typename?: "RootQueryToCategoryConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Category>;
}

/**
 * Connection between the RootQuery type and the Comment type
 */
export interface RootQueryToCommentConnection {
  __typename?: "RootQueryToCommentConnection";
  /**
   * Edges for the RootQueryToCommentConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToCommentConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Comment>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToCommentConnectionEdge {
  __typename?: "RootQueryToCommentConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Comment>;
}

/**
 * Connection between the RootQuery type and the ContentNode type
 */
export interface RootQueryToContentNodeConnection {
  __typename?: "RootQueryToContentNodeConnection";
  /**
   * Edges for the RootQueryToContentNodeConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToContentNodeConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToContentNodeConnectionEdge {
  __typename?: "RootQueryToContentNodeConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<ContentNode>;
}

/**
 * Connection between the RootQuery type and the ContentRevisionUnion type
 */
export interface RootQueryToContentRevisionUnionConnection {
  __typename?: "RootQueryToContentRevisionUnionConnection";
  /**
   * Edges for the RootQueryToContentRevisionUnionConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToContentRevisionUnionConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<ContentRevisionUnion>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToContentRevisionUnionConnectionEdge {
  __typename?: "RootQueryToContentRevisionUnionConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<ContentRevisionUnion>;
}

/**
 * Connection between the RootQuery type and the ContentType type
 */
export interface RootQueryToContentTypeConnection {
  __typename?: "RootQueryToContentTypeConnection";
  /**
   * Edges for the RootQueryToContentTypeConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToContentTypeConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<ContentType>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToContentTypeConnectionEdge {
  __typename?: "RootQueryToContentTypeConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<ContentType>;
}

/**
 * Connection between the RootQuery type and the EnqueuedScript type
 */
export interface RootQueryToEnqueuedScriptConnection {
  __typename?: "RootQueryToEnqueuedScriptConnection";
  /**
   * Edges for the RootQueryToEnqueuedScriptConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToEnqueuedScriptConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<EnqueuedScript>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToEnqueuedScriptConnectionEdge {
  __typename?: "RootQueryToEnqueuedScriptConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<EnqueuedScript>;
}

/**
 * Connection between the RootQuery type and the EnqueuedStylesheet type
 */
export interface RootQueryToEnqueuedStylesheetConnection {
  __typename?: "RootQueryToEnqueuedStylesheetConnection";
  /**
   * Edges for the RootQueryToEnqueuedStylesheetConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToEnqueuedStylesheetConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<EnqueuedStylesheet>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToEnqueuedStylesheetConnectionEdge {
  __typename?: "RootQueryToEnqueuedStylesheetConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<EnqueuedStylesheet>;
}

/**
 * Connection between the RootQuery type and the GfEntry type
 */
export interface RootQueryToGfEntryConnection {
  __typename?: "RootQueryToGfEntryConnection";
  /**
   * Edges for the RootQueryToGfEntryConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToGfEntryConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<GfEntry>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToGfEntryConnectionEdge {
  __typename?: "RootQueryToGfEntryConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<GfEntry>;
}

/**
 * Connection between the RootQuery type and the GfForm type
 */
export interface RootQueryToGfFormConnection {
  __typename?: "RootQueryToGfFormConnection";
  /**
   * Edges for the RootQueryToGfFormConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToGfFormConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<GfForm>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToGfFormConnectionEdge {
  __typename?: "RootQueryToGfFormConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<GfForm>;
}

/**
 * Connection between the RootQuery type and the GfSubmittedEntry type
 */
export interface RootQueryToGfSubmittedEntryConnection {
  __typename?: "RootQueryToGfSubmittedEntryConnection";
  /**
   * Edges for the RootQueryToGfSubmittedEntryConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToGfSubmittedEntryConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<GfSubmittedEntry>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToGfSubmittedEntryConnectionEdge {
  __typename?: "RootQueryToGfSubmittedEntryConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<GfSubmittedEntry>;
}

/**
 * Connection between the RootQuery type and the job type
 */
export interface RootQueryToJobConnection {
  __typename?: "RootQueryToJobConnection";
  /**
   * Edges for the RootQueryToJobConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToJobConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Job>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToJobConnectionEdge {
  __typename?: "RootQueryToJobConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Job>;
}

/**
 * Connection between the RootQuery type and the mediaItem type
 */
export interface RootQueryToMediaItemConnection {
  __typename?: "RootQueryToMediaItemConnection";
  /**
   * Edges for the RootQueryToMediaItemConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToMediaItemConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<MediaItem>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToMediaItemConnectionEdge {
  __typename?: "RootQueryToMediaItemConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<MediaItem>;
}

/**
 * Connection between the RootQuery type and the Menu type
 */
export interface RootQueryToMenuConnection {
  __typename?: "RootQueryToMenuConnection";
  /**
   * Edges for the RootQueryToMenuConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToMenuConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Menu>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToMenuConnectionEdge {
  __typename?: "RootQueryToMenuConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Menu>;
}

/**
 * Connection between the RootQuery type and the MenuItem type
 */
export interface RootQueryToMenuItemConnection {
  __typename?: "RootQueryToMenuItemConnection";
  /**
   * Edges for the RootQueryToMenuItemConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToMenuItemConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<MenuItem>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToMenuItemConnectionEdge {
  __typename?: "RootQueryToMenuItemConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<MenuItem>;
}

/**
 * Connection between the RootQuery type and the page type
 */
export interface RootQueryToPageConnection {
  __typename?: "RootQueryToPageConnection";
  /**
   * Edges for the RootQueryToPageConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToPageConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Page>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToPageConnectionEdge {
  __typename?: "RootQueryToPageConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Page>;
}

/**
 * Connection between the RootQuery type and the Plugin type
 */
export interface RootQueryToPluginConnection {
  __typename?: "RootQueryToPluginConnection";
  /**
   * Edges for the RootQueryToPluginConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToPluginConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Plugin>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToPluginConnectionEdge {
  __typename?: "RootQueryToPluginConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Plugin>;
}

/**
 * Connection between the RootQuery type and the post type
 */
export interface RootQueryToPostConnection {
  __typename?: "RootQueryToPostConnection";
  /**
   * Edges for the RootQueryToPostConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToPostConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Post>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToPostConnectionEdge {
  __typename?: "RootQueryToPostConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Post>;
}

/**
 * Connection between the RootQuery type and the postFormat type
 */
export interface RootQueryToPostFormatConnection {
  __typename?: "RootQueryToPostFormatConnection";
  /**
   * Edges for the RootQueryToPostFormatConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToPostFormatConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<PostFormat>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToPostFormatConnectionEdge {
  __typename?: "RootQueryToPostFormatConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<PostFormat>;
}

/**
 * Connection between the RootQuery type and the ReusableBlock type
 */
export interface RootQueryToReusableBlockConnection {
  __typename?: "RootQueryToReusableBlockConnection";
  /**
   * Edges for the RootQueryToReusableBlockConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToReusableBlockConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<ReusableBlock>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToReusableBlockConnectionEdge {
  __typename?: "RootQueryToReusableBlockConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<ReusableBlock>;
}

/**
 * Connection between the RootQuery type and the service type
 */
export interface RootQueryToServiceConnection {
  __typename?: "RootQueryToServiceConnection";
  /**
   * Edges for the RootQueryToServiceConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToServiceConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Service>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToServiceConnectionEdge {
  __typename?: "RootQueryToServiceConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Service>;
}

/**
 * Connection between the RootQuery type and the specialty type
 */
export interface RootQueryToSpecialtyConnection {
  __typename?: "RootQueryToSpecialtyConnection";
  /**
   * Edges for the RootQueryToSpecialtyConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToSpecialtyConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Specialty>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToSpecialtyConnectionEdge {
  __typename?: "RootQueryToSpecialtyConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Specialty>;
}

/**
 * Connection between the RootQuery type and the tag type
 */
export interface RootQueryToTagConnection {
  __typename?: "RootQueryToTagConnection";
  /**
   * Edges for the RootQueryToTagConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToTagConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Tag>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToTagConnectionEdge {
  __typename?: "RootQueryToTagConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Tag>;
}

/**
 * Connection between the RootQuery type and the Taxonomy type
 */
export interface RootQueryToTaxonomyConnection {
  __typename?: "RootQueryToTaxonomyConnection";
  /**
   * Edges for the RootQueryToTaxonomyConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToTaxonomyConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Taxonomy>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToTaxonomyConnectionEdge {
  __typename?: "RootQueryToTaxonomyConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Taxonomy>;
}

/**
 * Connection between the RootQuery type and the team_member type
 */
export interface RootQueryToTeam_memberConnection {
  __typename?: "RootQueryToTeam_memberConnection";
  /**
   * Edges for the RootQueryToTeam_memberConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToTeam_memberConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Team_member>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToTeam_memberConnectionEdge {
  __typename?: "RootQueryToTeam_memberConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Team_member>;
}

/**
 * Connection between the RootQuery type and the TermNode type
 */
export interface RootQueryToTermNodeConnection {
  __typename?: "RootQueryToTermNodeConnection";
  /**
   * Edges for the RootQueryToTermNodeConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToTermNodeConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<TermNode>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToTermNodeConnectionEdge {
  __typename?: "RootQueryToTermNodeConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<TermNode>;
}

/**
 * Connection between the RootQuery type and the Theme type
 */
export interface RootQueryToThemeConnection {
  __typename?: "RootQueryToThemeConnection";
  /**
   * Edges for the RootQueryToThemeConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToThemeConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Theme>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToThemeConnectionEdge {
  __typename?: "RootQueryToThemeConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Theme>;
}

/**
 * Connection between the RootQuery type and the User type
 */
export interface RootQueryToUserConnection {
  __typename?: "RootQueryToUserConnection";
  /**
   * Edges for the RootQueryToUserConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToUserConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<User>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToUserConnectionEdge {
  __typename?: "RootQueryToUserConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<User>;
}

/**
 * Connection between the RootQuery type and the UserRole type
 */
export interface RootQueryToUserRoleConnection {
  __typename?: "RootQueryToUserRoleConnection";
  /**
   * Edges for the RootQueryToUserRoleConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToUserRoleConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<UserRole>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToUserRoleConnectionEdge {
  __typename?: "RootQueryToUserRoleConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<UserRole>;
}

/**
 * Connection between the RootQuery type and the work type
 */
export interface RootQueryToWorkConnection {
  __typename?: "RootQueryToWorkConnection";
  /**
   * Edges for the RootQueryToWorkConnection connection
   */
  edges?: Maybe<Array<Maybe<RootQueryToWorkConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Work>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface RootQueryToWorkConnectionEdge {
  __typename?: "RootQueryToWorkConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Work>;
}

/**
 * The Yoast SEO breadcrumb config
 */
export interface SEOBreadcrumbs {
  __typename?: "SEOBreadcrumbs";
  archivePrefix?: Maybe<ScalarsEnums["String"]>;
  boldLast?: Maybe<ScalarsEnums["Boolean"]>;
  enabled?: Maybe<ScalarsEnums["Boolean"]>;
  homeText?: Maybe<ScalarsEnums["String"]>;
  notFoundText?: Maybe<ScalarsEnums["String"]>;
  prefix?: Maybe<ScalarsEnums["String"]>;
  searchPrefix?: Maybe<ScalarsEnums["String"]>;
  separator?: Maybe<ScalarsEnums["String"]>;
  showBlogPage?: Maybe<ScalarsEnums["Boolean"]>;
}

/**
 * The Yoast SEO site level configuration data
 */
export interface SEOConfig {
  __typename?: "SEOConfig";
  breadcrumbs?: Maybe<SEOBreadcrumbs>;
  contentTypes?: Maybe<SEOContentTypes>;
  openGraph?: Maybe<SEOOpenGraph>;
  redirects?: Maybe<Array<Maybe<SEORedirect>>>;
  schema?: Maybe<SEOSchema>;
  social?: Maybe<SEOSocial>;
  webmaster?: Maybe<SEOWebmaster>;
}

/**
 * The Yoast SEO search appearance content types fields
 */
export interface SEOContentType {
  __typename?: "SEOContentType";
  archive?: Maybe<SEOContentTypeArchive>;
  metaDesc?: Maybe<ScalarsEnums["String"]>;
  metaRobotsNoindex?: Maybe<ScalarsEnums["Boolean"]>;
  schema?: Maybe<SEOPageInfoSchema>;
  schemaType?: Maybe<ScalarsEnums["String"]>;
  title?: Maybe<ScalarsEnums["String"]>;
}

/**
 * he Yoast SEO search appearance content types fields
 */
export interface SEOContentTypeArchive {
  __typename?: "SEOContentTypeArchive";
  archiveLink?: Maybe<ScalarsEnums["String"]>;
  breadcrumbTitle?: Maybe<ScalarsEnums["String"]>;
  fullHead?: Maybe<ScalarsEnums["String"]>;
  hasArchive?: Maybe<ScalarsEnums["Boolean"]>;
  metaDesc?: Maybe<ScalarsEnums["String"]>;
  metaRobotsNoindex?: Maybe<ScalarsEnums["Boolean"]>;
  title?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The Yoast SEO search appearance content types
 */
export interface SEOContentTypes {
  __typename?: "SEOContentTypes";
  blockEditorPreview?: Maybe<SEOContentType>;
  job?: Maybe<SEOContentType>;
  mediaItem?: Maybe<SEOContentType>;
  page?: Maybe<SEOContentType>;
  post?: Maybe<SEOContentType>;
  reusableBlock?: Maybe<SEOContentType>;
  service?: Maybe<SEOContentType>;
  specialty?: Maybe<SEOContentType>;
  teamMember?: Maybe<SEOContentType>;
  work?: Maybe<SEOContentType>;
}

/**
 * The Open Graph data
 */
export interface SEOOpenGraph {
  __typename?: "SEOOpenGraph";
  defaultImage?: Maybe<MediaItem>;
  frontPage?: Maybe<SEOOpenGraphFrontPage>;
}

/**
 * The Open Graph Front page data
 */
export interface SEOOpenGraphFrontPage {
  __typename?: "SEOOpenGraphFrontPage";
  description?: Maybe<ScalarsEnums["String"]>;
  image?: Maybe<MediaItem>;
  title?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The Schema for post type
 */
export interface SEOPageInfoSchema {
  __typename?: "SEOPageInfoSchema";
  raw?: Maybe<ScalarsEnums["String"]>;
}

export interface SEOPostTypeBreadcrumbs {
  __typename?: "SEOPostTypeBreadcrumbs";
  text?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The page info SEO details
 */
export interface SEOPostTypePageInfo {
  __typename?: "SEOPostTypePageInfo";
  schema?: Maybe<SEOPageInfoSchema>;
}

/**
 * The Schema types
 */
export interface SEOPostTypeSchema {
  __typename?: "SEOPostTypeSchema";
  articleType?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  pageType?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  raw?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The Yoast redirect data  (Yoast Premium only)
 */
export interface SEORedirect {
  __typename?: "SEORedirect";
  format?: Maybe<ScalarsEnums["String"]>;
  origin?: Maybe<ScalarsEnums["String"]>;
  target?: Maybe<ScalarsEnums["String"]>;
  type?: Maybe<ScalarsEnums["Int"]>;
}

/**
 * The Yoast SEO schema data
 */
export interface SEOSchema {
  __typename?: "SEOSchema";
  companyLogo?: Maybe<MediaItem>;
  companyName?: Maybe<ScalarsEnums["String"]>;
  companyOrPerson?: Maybe<ScalarsEnums["String"]>;
  homeUrl?: Maybe<ScalarsEnums["String"]>;
  inLanguage?: Maybe<ScalarsEnums["String"]>;
  logo?: Maybe<MediaItem>;
  personLogo?: Maybe<MediaItem>;
  personName?: Maybe<ScalarsEnums["String"]>;
  siteName?: Maybe<ScalarsEnums["String"]>;
  siteUrl?: Maybe<ScalarsEnums["String"]>;
  wordpressSiteName?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The Yoast SEO Social media links
 */
export interface SEOSocial {
  __typename?: "SEOSocial";
  facebook?: Maybe<SEOSocialFacebook>;
  instagram?: Maybe<SEOSocialInstagram>;
  linkedIn?: Maybe<SEOSocialLinkedIn>;
  mySpace?: Maybe<SEOSocialMySpace>;
  pinterest?: Maybe<SEOSocialPinterest>;
  twitter?: Maybe<SEOSocialTwitter>;
  wikipedia?: Maybe<SEOSocialWikipedia>;
  youTube?: Maybe<SEOSocialYoutube>;
}

export interface SEOSocialFacebook {
  __typename?: "SEOSocialFacebook";
  defaultImage?: Maybe<MediaItem>;
  url?: Maybe<ScalarsEnums["String"]>;
}

export interface SEOSocialInstagram {
  __typename?: "SEOSocialInstagram";
  url?: Maybe<ScalarsEnums["String"]>;
}

export interface SEOSocialLinkedIn {
  __typename?: "SEOSocialLinkedIn";
  url?: Maybe<ScalarsEnums["String"]>;
}

export interface SEOSocialMySpace {
  __typename?: "SEOSocialMySpace";
  url?: Maybe<ScalarsEnums["String"]>;
}

export interface SEOSocialPinterest {
  __typename?: "SEOSocialPinterest";
  metaTag?: Maybe<ScalarsEnums["String"]>;
  url?: Maybe<ScalarsEnums["String"]>;
}

export interface SEOSocialTwitter {
  __typename?: "SEOSocialTwitter";
  cardType?: Maybe<ScalarsEnums["SEOCardType"]>;
  username?: Maybe<ScalarsEnums["String"]>;
}

export interface SEOSocialWikipedia {
  __typename?: "SEOSocialWikipedia";
  url?: Maybe<ScalarsEnums["String"]>;
}

export interface SEOSocialYoutube {
  __typename?: "SEOSocialYoutube";
  url?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The Schema types for Taxonomy
 */
export interface SEOTaxonomySchema {
  __typename?: "SEOTaxonomySchema";
  raw?: Maybe<ScalarsEnums["String"]>;
}

export interface SEOUser {
  __typename?: "SEOUser";
  breadcrumbTitle?: Maybe<ScalarsEnums["String"]>;
  canonical?: Maybe<ScalarsEnums["String"]>;
  fullHead?: Maybe<ScalarsEnums["String"]>;
  language?: Maybe<ScalarsEnums["String"]>;
  metaDesc?: Maybe<ScalarsEnums["String"]>;
  metaRobotsNofollow?: Maybe<ScalarsEnums["String"]>;
  metaRobotsNoindex?: Maybe<ScalarsEnums["String"]>;
  opengraphDescription?: Maybe<ScalarsEnums["String"]>;
  opengraphImage?: Maybe<MediaItem>;
  opengraphTitle?: Maybe<ScalarsEnums["String"]>;
  region?: Maybe<ScalarsEnums["String"]>;
  schema?: Maybe<SEOUserSchema>;
  social?: Maybe<SEOUserSocial>;
  title?: Maybe<ScalarsEnums["String"]>;
  twitterDescription?: Maybe<ScalarsEnums["String"]>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The Schema types for User
 */
export interface SEOUserSchema {
  __typename?: "SEOUserSchema";
  articleType?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  pageType?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  raw?: Maybe<ScalarsEnums["String"]>;
}

export interface SEOUserSocial {
  __typename?: "SEOUserSocial";
  facebook?: Maybe<ScalarsEnums["String"]>;
  instagram?: Maybe<ScalarsEnums["String"]>;
  linkedIn?: Maybe<ScalarsEnums["String"]>;
  mySpace?: Maybe<ScalarsEnums["String"]>;
  pinterest?: Maybe<ScalarsEnums["String"]>;
  soundCloud?: Maybe<ScalarsEnums["String"]>;
  twitter?: Maybe<ScalarsEnums["String"]>;
  wikipedia?: Maybe<ScalarsEnums["String"]>;
  youTube?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The Yoast SEO  webmaster fields
 */
export interface SEOWebmaster {
  __typename?: "SEOWebmaster";
  baiduVerify?: Maybe<ScalarsEnums["String"]>;
  googleVerify?: Maybe<ScalarsEnums["String"]>;
  msVerify?: Maybe<ScalarsEnums["String"]>;
  yandexVerify?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface SectionField {
  __typename?: "SectionField";
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface SelectField {
  __typename?: "SelectField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * The autocomplete attribute for the field.
   */
  autocompleteAttribute?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The available choices for the select field.
   */
  choices?: Maybe<Array<Maybe<SelectFieldChoice>>>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether autocomplete should be enabled for this field.
   */
  hasAutocomplete?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user.
   */
  hasChoiceValue?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields.
   */
  hasEnhancedUI?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * Select choice values.
 */
export interface SelectFieldChoice {
  __typename?: "SelectFieldChoice";
  /**
   * Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected.
   */
  isSelected?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The text to be displayed to the user when displaying this choice.
   */
  text?: Maybe<ScalarsEnums["String"]>;
  /**
   * The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property.
   */
  value?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The payload for the sendPasswordResetEmail mutation
 */
export interface SendPasswordResetEmailPayload {
  __typename?: "SendPasswordResetEmailPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The user that the password reset email was sent to
   */
  user?: Maybe<User>;
}

/**
 * The service type
 */
export interface Service {
  __typename?: "Service";
  /**
   * Gutenberg blocks
   */
  blocks?: Maybe<Array<Block>>;
  /**
   * Gutenberg blocks as json string
   */
  blocksJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums["String"];
  /**
   * The unique resource identifier path
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums["String"]>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums["String"]>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * Connection between the NodeWithFeaturedImage type and the MediaItem type
   */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /**
   * The database identifier for the featured image node assigned to the content node
   */
  featuredImageDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Globally unique ID of the featured image assigned to the node
   */
  featuredImageId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier of the services object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums["String"]>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums["String"]>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the service type and the service type
   */
  preview?: Maybe<ServiceToPreviewConnectionEdge>;
  /**
   * Previewed gutenberg blocks
   */
  previewBlocks?: Maybe<Array<Block>>;
  /**
   * Previewed Gutenberg blocks as json string
   */
  previewBlocksJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  serviceId: ScalarsEnums["Int"];
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums["String"]>;
  /**
   * The template assigned to a node of content
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Connection between the service type and the service type
 */
export interface ServiceToPreviewConnectionEdge {
  __typename?: "ServiceToPreviewConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<Service>;
}

/**
 * All of the registered settings
 */
export interface Settings {
  __typename?: "Settings";
  /**
   * Settings of the the string Settings Group
   */
  discussionSettingsDefaultCommentStatus?: Maybe<ScalarsEnums["String"]>;
  /**
   * Settings of the the string Settings Group
   */
  discussionSettingsDefaultPingStatus?: Maybe<ScalarsEnums["String"]>;
  /**
   * Settings of the the string Settings Group
   */
  generalSettingsDateFormat?: Maybe<ScalarsEnums["String"]>;
  /**
   * Settings of the the string Settings Group
   */
  generalSettingsDescription?: Maybe<ScalarsEnums["String"]>;
  /**
   * Settings of the the string Settings Group
   */
  generalSettingsEmail?: Maybe<ScalarsEnums["String"]>;
  /**
   * Settings of the the string Settings Group
   */
  generalSettingsLanguage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Settings of the the integer Settings Group
   */
  generalSettingsStartOfWeek?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Settings of the the string Settings Group
   */
  generalSettingsTimeFormat?: Maybe<ScalarsEnums["String"]>;
  /**
   * Settings of the the string Settings Group
   */
  generalSettingsTimezone?: Maybe<ScalarsEnums["String"]>;
  /**
   * Settings of the the string Settings Group
   */
  generalSettingsTitle?: Maybe<ScalarsEnums["String"]>;
  /**
   * Settings of the the string Settings Group
   */
  generalSettingsUrl?: Maybe<ScalarsEnums["String"]>;
  /**
   * Settings of the the integer Settings Group
   */
  readingSettingsPageForPosts?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Settings of the the integer Settings Group
   */
  readingSettingsPageOnFront?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Settings of the the integer Settings Group
   */
  readingSettingsPostsPerPage?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Settings of the the string Settings Group
   */
  readingSettingsShowOnFront?: Maybe<ScalarsEnums["String"]>;
  /**
   * Settings of the the integer Settings Group
   */
  writingSettingsDefaultCategory?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Settings of the the string Settings Group
   */
  writingSettingsDefaultPostFormat?: Maybe<ScalarsEnums["String"]>;
  /**
   * Settings of the the boolean Settings Group
   */
  writingSettingsUseSmilies?: Maybe<ScalarsEnums["Boolean"]>;
}

/**
 * The specialty type
 */
export interface Specialty {
  __typename?: "Specialty";
  /**
   * Gutenberg blocks
   */
  blocks?: Maybe<Array<Block>>;
  /**
   * Gutenberg blocks as json string
   */
  blocksJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums["String"];
  /**
   * The unique resource identifier path
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums["String"]>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums["String"]>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * Connection between the NodeWithFeaturedImage type and the MediaItem type
   */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /**
   * The database identifier for the featured image node assigned to the content node
   */
  featuredImageDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Globally unique ID of the featured image assigned to the node
   */
  featuredImageId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier of the specialties object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums["String"]>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums["String"]>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the specialty type and the specialty type
   */
  preview?: Maybe<SpecialtyToPreviewConnectionEdge>;
  /**
   * Previewed gutenberg blocks
   */
  previewBlocks?: Maybe<Array<Block>>;
  /**
   * Previewed Gutenberg blocks as json string
   */
  previewBlocksJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  specialtyId: ScalarsEnums["Int"];
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums["String"]>;
  /**
   * The template assigned to a node of content
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Connection between the specialty type and the specialty type
 */
export interface SpecialtyToPreviewConnectionEdge {
  __typename?: "SpecialtyToPreviewConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<Specialty>;
}

/**
 * A Gravity Forms   field.
 */
export interface SubmitField {
  __typename?: "SubmitField";
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * The payload for the submitGfDraftEntry mutation
 */
export interface SubmitGfDraftEntryPayload {
  __typename?: "SubmitGfDraftEntryPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The entry that was created.
   */
  entry?: Maybe<GfSubmittedEntry>;
  /**
   * Field errors.
   */
  errors?: Maybe<Array<Maybe<FieldError>>>;
}

/**
 * The payload for the submitGfForm mutation
 */
export interface SubmitGfFormPayload {
  __typename?: "SubmitGfFormPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The entry that was created.
   */
  entry?: Maybe<GfEntry>;
  /**
   * Field errors.
   */
  errors?: Maybe<Array<Maybe<FieldError>>>;
  /**
   * Draft resume URL. Null if submitting an entry. If the &quot;Referer&quot; header is not included in the request, this will be an empty string.
   */
  resumeUrl?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The tag type
 */
export interface Tag {
  __typename?: "Tag";
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Connection between the tag type and the ContentNode type
   */
  contentNodes: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<TagToContentNodeConnectionWhereArgs>;
  }) => Maybe<TagToContentNodeConnection>;
  /**
   * The number of objects connected to the object
   */
  count?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The unique resource identifier path
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * The description of the object
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the TermNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<TermNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the TermNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /**
   * The unique resource identifier path
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * The link to the term
   */
  link?: Maybe<ScalarsEnums["String"]>;
  /**
   * The human friendly name of the object.
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the tag type and the post type
   */
  posts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<TagToPostConnectionWhereArgs>;
  }) => Maybe<TagToPostConnection>;
  /**
   * The Yoast SEO data of the Tags taxonomy.
   */
  seo?: Maybe<TaxonomySEO>;
  /**
   * An alphanumeric identifier for the object unique to its type.
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of databaseId
   */
  tagId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Connection between the tag type and the Taxonomy type
   */
  taxonomy?: Maybe<TagToTaxonomyConnectionEdge>;
  /**
   * The name of the taxonomy that the object is associated with
   */
  taxonomyName?: Maybe<ScalarsEnums["String"]>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The ID of the term group that this term object belongs to
   */
  termGroupId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The taxonomy ID that the object is associated with
   */
  termTaxonomyId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Connection between the tag type and the ContentNode type
 */
export interface TagToContentNodeConnection {
  __typename?: "TagToContentNodeConnection";
  /**
   * Edges for the TagToContentNodeConnection connection
   */
  edges?: Maybe<Array<Maybe<TagToContentNodeConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface TagToContentNodeConnectionEdge {
  __typename?: "TagToContentNodeConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<ContentNode>;
}

/**
 * Connection between the tag type and the post type
 */
export interface TagToPostConnection {
  __typename?: "TagToPostConnection";
  /**
   * Edges for the TagToPostConnection connection
   */
  edges?: Maybe<Array<Maybe<TagToPostConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Post>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface TagToPostConnectionEdge {
  __typename?: "TagToPostConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Post>;
}

/**
 * Connection between the tag type and the Taxonomy type
 */
export interface TagToTaxonomyConnectionEdge {
  __typename?: "TagToTaxonomyConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<Taxonomy>;
}

/**
 * A taxonomy object
 */
export interface Taxonomy {
  __typename?: "Taxonomy";
  /**
   * List of Content Types associated with the Taxonomy
   */
  connectedContentTypes: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<TaxonomyToContentTypeConnection>;
  /**
   * Description of the taxonomy. This field is equivalent to WP_Taxonomy-&gt;description
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The plural name of the post type within the GraphQL Schema.
   */
  graphqlPluralName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The singular name of the post type within the GraphQL Schema.
   */
  graphqlSingleName?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether the taxonomy is hierarchical
   */
  hierarchical?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The globally unique identifier of the taxonomy object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Name of the taxonomy shown in the menu. Usually plural.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The display name of the taxonomy. This field is equivalent to WP_Taxonomy-&gt;label
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether the taxonomy is publicly queryable
   */
  public?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Name of content type to diplay in REST API &quot;wp/v2&quot; namespace.
   */
  restBase?: Maybe<ScalarsEnums["String"]>;
  /**
   * The REST Controller class assigned to handling this content type.
   */
  restControllerClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether to show the taxonomy as part of a tag cloud widget. This field is equivalent to WP_Taxonomy-&gt;show_tagcloud
   */
  showCloud?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether to display a column for the taxonomy on its post type listing screens.
   */
  showInAdminColumn?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether to add the post type to the GraphQL Schema.
   */
  showInGraphql?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether to show the taxonomy in the admin menu
   */
  showInMenu?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the taxonomy is available for selection in navigation menus.
   */
  showInNavMenus?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether to show the taxonomy in the quick/bulk edit panel.
   */
  showInQuickEdit?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether to add the post type route in the REST API &quot;wp/v2&quot; namespace.
   */
  showInRest?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether to generate and allow a UI for managing terms in this taxonomy in the admin
   */
  showUi?: Maybe<ScalarsEnums["Boolean"]>;
}

export interface TaxonomySEO {
  __typename?: "TaxonomySEO";
  breadcrumbs?: Maybe<Array<Maybe<SEOPostTypeBreadcrumbs>>>;
  canonical?: Maybe<ScalarsEnums["String"]>;
  cornerstone?: Maybe<ScalarsEnums["Boolean"]>;
  focuskw?: Maybe<ScalarsEnums["String"]>;
  fullHead?: Maybe<ScalarsEnums["String"]>;
  metaDesc?: Maybe<ScalarsEnums["String"]>;
  metaKeywords?: Maybe<ScalarsEnums["String"]>;
  metaRobotsNofollow?: Maybe<ScalarsEnums["String"]>;
  metaRobotsNoindex?: Maybe<ScalarsEnums["String"]>;
  opengraphAuthor?: Maybe<ScalarsEnums["String"]>;
  opengraphDescription?: Maybe<ScalarsEnums["String"]>;
  opengraphImage?: Maybe<MediaItem>;
  opengraphModifiedTime?: Maybe<ScalarsEnums["String"]>;
  opengraphPublishedTime?: Maybe<ScalarsEnums["String"]>;
  opengraphPublisher?: Maybe<ScalarsEnums["String"]>;
  opengraphSiteName?: Maybe<ScalarsEnums["String"]>;
  opengraphTitle?: Maybe<ScalarsEnums["String"]>;
  opengraphType?: Maybe<ScalarsEnums["String"]>;
  opengraphUrl?: Maybe<ScalarsEnums["String"]>;
  schema?: Maybe<SEOTaxonomySchema>;
  title?: Maybe<ScalarsEnums["String"]>;
  twitterDescription?: Maybe<ScalarsEnums["String"]>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Connection between the Taxonomy type and the ContentType type
 */
export interface TaxonomyToContentTypeConnection {
  __typename?: "TaxonomyToContentTypeConnection";
  /**
   * Edges for the TaxonomyToContentTypeConnection connection
   */
  edges?: Maybe<Array<Maybe<TaxonomyToContentTypeConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<ContentType>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface TaxonomyToContentTypeConnectionEdge {
  __typename?: "TaxonomyToContentTypeConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<ContentType>;
}

/**
 * The team_member type
 */
export interface Team_member {
  __typename?: "Team_member";
  /**
   * Gutenberg blocks
   */
  blocks?: Maybe<Array<Block>>;
  /**
   * Gutenberg blocks as json string
   */
  blocksJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums["String"];
  /**
   * The unique resource identifier path
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums["String"]>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums["String"]>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * Connection between the NodeWithFeaturedImage type and the MediaItem type
   */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /**
   * The database identifier for the featured image node assigned to the content node
   */
  featuredImageDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Globally unique ID of the featured image assigned to the node
   */
  featuredImageId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier of the team object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums["String"]>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums["String"]>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the team_member type and the team_member type
   */
  preview?: Maybe<Team_memberToPreviewConnectionEdge>;
  /**
   * Previewed gutenberg blocks
   */
  previewBlocks?: Maybe<Array<Block>>;
  /**
   * Previewed Gutenberg blocks as json string
   */
  previewBlocksJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums["String"]>;
  /**
   * Added to the GraphQL Schema because the ACF Field Group &quot;Team&quot; was set to Show in GraphQL.
   */
  team?: Maybe<Team_member_Team>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  team_memberId: ScalarsEnums["Int"];
  /**
   * The template assigned to a node of content
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Connection between the team_member type and the team_member type
 */
export interface Team_memberToPreviewConnectionEdge {
  __typename?: "Team_memberToPreviewConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<Team_member>;
}

/**
 * Field Group
 */
export interface Team_member_Team {
  __typename?: "Team_member_Team";
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums["String"]>;
  title?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The template assigned to the node
 */
export interface Template_About {
  __typename?: "Template_About";
  /**
   * The name of the template
   */
  templateName?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The template assigned to the node
 */
export interface Template_Blog {
  __typename?: "Template_Blog";
  /**
   * The name of the template
   */
  templateName?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The template assigned to the node
 */
export interface Template_Contact {
  __typename?: "Template_Contact";
  /**
   * The name of the template
   */
  templateName?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The template assigned to the node
 */
export interface Template_Home {
  __typename?: "Template_Home";
  /**
   * The name of the template
   */
  templateName?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The template assigned to the node
 */
export interface Template_Work {
  __typename?: "Template_Work";
  /**
   * The name of the template
   */
  templateName?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Terms are nodes within a Taxonomy, used to group and relate other nodes.
 */
export interface TermNode {
  __typename?: "Category" | "PostFormat" | "Tag";
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The number of objects connected to the object
   */
  count?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Identifies the primary key from the database.
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * The description of the object
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the TermNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<TermNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the TermNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /**
   * The unique resource identifier path
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * The link to the term
   */
  link?: Maybe<ScalarsEnums["String"]>;
  /**
   * The human friendly name of the object.
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * An alphanumeric identifier for the object unique to its type.
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  /**
   * The name of the taxonomy that the object is associated with
   */
  taxonomyName?: Maybe<ScalarsEnums["String"]>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The ID of the term group that this term object belongs to
   */
  termGroupId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The taxonomy ID that the object is associated with
   */
  termTaxonomyId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
  $on: $TermNode;
}

/**
 * Connection between the TermNode type and the EnqueuedScript type
 */
export interface TermNodeToEnqueuedScriptConnection {
  __typename?: "TermNodeToEnqueuedScriptConnection";
  /**
   * Edges for the TermNodeToEnqueuedScriptConnection connection
   */
  edges?: Maybe<Array<Maybe<TermNodeToEnqueuedScriptConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<EnqueuedScript>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface TermNodeToEnqueuedScriptConnectionEdge {
  __typename?: "TermNodeToEnqueuedScriptConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<EnqueuedScript>;
}

/**
 * Connection between the TermNode type and the EnqueuedStylesheet type
 */
export interface TermNodeToEnqueuedStylesheetConnection {
  __typename?: "TermNodeToEnqueuedStylesheetConnection";
  /**
   * Edges for the TermNodeToEnqueuedStylesheetConnection connection
   */
  edges?: Maybe<Array<Maybe<TermNodeToEnqueuedStylesheetConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<EnqueuedStylesheet>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface TermNodeToEnqueuedStylesheetConnectionEdge {
  __typename?: "TermNodeToEnqueuedStylesheetConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<EnqueuedStylesheet>;
}

/**
 * A Gravity Forms   field.
 */
export interface TextAreaField {
  __typename?: "TextAreaField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Indicates whether the field uses the rich text editor interface.
   */
  hasRichTextEditor?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Specifies the maximum number of characters allowed in a text or textarea (paragraph) field.
   */
  maxLength?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface TextField {
  __typename?: "TextField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * The autocomplete attribute for the field.
   */
  autocompleteAttribute?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Whether autocomplete should be enabled for this field.
   */
  hasAutocomplete?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the field has an input mask.
   */
  hasInputMask?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * The pattern used for the input mask.
   */
  inputMaskValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if a text field input tag should be created with a &quot;password&quot; type.
   */
  isPasswordInput?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Specifies the maximum number of characters allowed in a text or textarea (paragraph) field.
   */
  maxLength?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * A theme object
 */
export interface Theme {
  __typename?: "Theme";
  /**
   * Name of the theme author(s), could also be a company name. This field is equivalent to WP_Theme-&gt;get( &quot;Author&quot; ).
   */
  author?: Maybe<ScalarsEnums["String"]>;
  /**
   * URI for the author/company website. This field is equivalent to WP_Theme-&gt;get( &quot;AuthorURI&quot; ).
   */
  authorUri?: Maybe<ScalarsEnums["String"]>;
  /**
   * The description of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Description&quot; ).
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier of the theme object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Display name of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Name&quot; ).
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * The URL of the screenshot for the theme. The screenshot is intended to give an overview of what the theme looks like. This field is equivalent to WP_Theme-&gt;get_screenshot().
   */
  screenshot?: Maybe<ScalarsEnums["String"]>;
  /**
   * The theme slug is used to internally match themes. Theme slugs can have subdirectories like: my-theme/sub-theme. This field is equivalent to WP_Theme-&gt;get_stylesheet().
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  /**
   * URI for the author/company website. This field is equivalent to WP_Theme-&gt;get( &quot;Tags&quot; ).
   */
  tags?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * A URI if the theme has a website associated with it. The Theme URI is handy for directing users to a theme site for support etc. This field is equivalent to WP_Theme-&gt;get( &quot;ThemeURI&quot; ).
   */
  themeUri?: Maybe<ScalarsEnums["String"]>;
  /**
   * The current version of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Version&quot; ).
   */
  version?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface TimeField {
  __typename?: "TimeField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * An array of the available properties for each input of the time field.
   */
  inputs?: Maybe<Array<Maybe<TimeInputProperty>>>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs.
   */
  subLabelPlacement?: Maybe<ScalarsEnums["FormFieldSubLabelPlacementEnum"]>;
  /**
   * Determines how the time is displayed.
   */
  timeFormat?: Maybe<ScalarsEnums["TimeFieldFormatEnum"]>;
  /**
   * Time field value.
   */
  timeValues?: Maybe<TimeFieldValue>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * The individual properties for each element of the Time value field.
 */
export interface TimeFieldValue {
  __typename?: "TimeFieldValue";
  /**
   * AM or PM.
   */
  amPm?: Maybe<ScalarsEnums["AmPmEnum"]>;
  /**
   * The full display value in 12-hour format. Example: &quot;08:25 am&quot;.
   */
  displayValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * The hours, in this format: hh.
   */
  hours?: Maybe<ScalarsEnums["String"]>;
  /**
   * The minutes, in this format: mm.
   */
  minutes?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Time input values.
 */
export interface TimeInputProperty {
  __typename?: "TimeInputProperty";
  /**
   * The autocomplete attribute for the field.
   */
  autocompleteAttribute?: Maybe<ScalarsEnums["String"]>;
  /**
   * The custom label for the input. When set, this is used in place of the label.
   */
  customLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field.
   */
  id?: Maybe<ScalarsEnums["Float"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Any node that has a URI
 */
export interface UniformResourceIdentifiable {
  __typename?:
    | "BlockEditorPreview"
    | "Category"
    | "ContentType"
    | "Job"
    | "MediaItem"
    | "Page"
    | "Post"
    | "PostFormat"
    | "ReusableBlock"
    | "Service"
    | "Specialty"
    | "Tag"
    | "Team_member"
    | "User"
    | "Work";
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The unique resource identifier path
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
  $on: $UniformResourceIdentifiable;
}

/**
 * The payload for the UpdateCategory mutation
 */
export interface UpdateCategoryPayload {
  __typename?: "UpdateCategoryPayload";
  /**
   * The created category
   */
  category?: Maybe<Category>;
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The payload for the updateComment mutation
 */
export interface UpdateCommentPayload {
  __typename?: "UpdateCommentPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The comment that was created
   */
  comment?: Maybe<Comment>;
  /**
   * Whether the mutation succeeded. If the comment is not approved, the server will not return the comment to a non authenticated user, but a success message can be returned if the create succeeded, and the client can optimistically add the comment to the client cache
   */
  success?: Maybe<ScalarsEnums["Boolean"]>;
}

/**
 * The payload for the updateGfDraftEntry mutation
 */
export interface UpdateGfDraftEntryPayload {
  __typename?: "UpdateGfDraftEntryPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The draft entry after the update mutation has been applied. If a validation error occurred, the draft entry will NOT have been updated with the invalid value provided.
   */
  draftEntry?: Maybe<GfDraftEntry>;
  /**
   * Field validation errors.
   */
  errors?: Maybe<Array<Maybe<FieldError>>>;
  /**
   * Draft resume URL. If the &quot;Referer&quot; header is not included in the request, this will be an empty string.
   */
  resumeUrl?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The payload for the updateGfEntry mutation
 */
export interface UpdateGfEntryPayload {
  __typename?: "UpdateGfEntryPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The entry that was created.
   */
  entry?: Maybe<GfSubmittedEntry>;
  /**
   * Field errors.
   */
  errors?: Maybe<Array<Maybe<FieldError>>>;
}

/**
 * The payload for the updateJob mutation
 */
export interface UpdateJobPayload {
  __typename?: "UpdateJobPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Post object mutation type.
   */
  job?: Maybe<Job>;
}

/**
 * The payload for the updateMediaItem mutation
 */
export interface UpdateMediaItemPayload {
  __typename?: "UpdateMediaItemPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The MediaItem object mutation type.
   */
  mediaItem?: Maybe<MediaItem>;
}

/**
 * The payload for the updatePage mutation
 */
export interface UpdatePagePayload {
  __typename?: "UpdatePagePayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Post object mutation type.
   */
  page?: Maybe<Page>;
}

/**
 * The payload for the UpdatePostFormat mutation
 */
export interface UpdatePostFormatPayload {
  __typename?: "UpdatePostFormatPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The created post_format
   */
  postFormat?: Maybe<PostFormat>;
}

/**
 * The payload for the updatePost mutation
 */
export interface UpdatePostPayload {
  __typename?: "UpdatePostPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Post object mutation type.
   */
  post?: Maybe<Post>;
}

/**
 * The payload for the updateReusableBlock mutation
 */
export interface UpdateReusableBlockPayload {
  __typename?: "UpdateReusableBlockPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Post object mutation type.
   */
  reusableBlock?: Maybe<ReusableBlock>;
}

/**
 * The payload for the updateService mutation
 */
export interface UpdateServicePayload {
  __typename?: "UpdateServicePayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Post object mutation type.
   */
  service?: Maybe<Service>;
}

/**
 * The payload for the updateSettings mutation
 */
export interface UpdateSettingsPayload {
  __typename?: "UpdateSettingsPayload";
  /**
   * Update all settings.
   */
  allSettings?: Maybe<Settings>;
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * Update the DiscussionSettings setting.
   */
  discussionSettings?: Maybe<DiscussionSettings>;
  /**
   * Update the GeneralSettings setting.
   */
  generalSettings?: Maybe<GeneralSettings>;
  /**
   * Update the ReadingSettings setting.
   */
  readingSettings?: Maybe<ReadingSettings>;
  /**
   * Update the WritingSettings setting.
   */
  writingSettings?: Maybe<WritingSettings>;
}

/**
 * The payload for the updateSpecialty mutation
 */
export interface UpdateSpecialtyPayload {
  __typename?: "UpdateSpecialtyPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Post object mutation type.
   */
  specialty?: Maybe<Specialty>;
}

/**
 * The payload for the UpdateTag mutation
 */
export interface UpdateTagPayload {
  __typename?: "UpdateTagPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The created post_tag
   */
  tag?: Maybe<Tag>;
}

/**
 * The payload for the updateTeam_member mutation
 */
export interface UpdateTeam_memberPayload {
  __typename?: "UpdateTeam_memberPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Post object mutation type.
   */
  team_member?: Maybe<Team_member>;
}

/**
 * The payload for the updateUser mutation
 */
export interface UpdateUserPayload {
  __typename?: "UpdateUserPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The User object mutation type.
   */
  user?: Maybe<User>;
}

/**
 * The payload for the updateWork mutation
 */
export interface UpdateWorkPayload {
  __typename?: "UpdateWorkPayload";
  /**
   * If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Post object mutation type.
   */
  work?: Maybe<Work>;
}

/**
 * A User object
 */
export interface User {
  __typename?: "User";
  /**
   * Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument.
   */
  avatar: (args?: {
    /**
     * Whether to always show the default image, never the Gravatar. Default false
     */
    forceDefault?: Maybe<Scalars["Boolean"]>;
    /**
     * The rating level of the avatar.
     */
    rating?: Maybe<AvatarRatingEnum>;
    /**
     * The size attribute of the avatar field can be used to fetch avatars of different sizes. The value corresponds to the dimension in pixels to fetch. The default is 96 pixels.
     * @defaultValue `96`
     */
    size?: Maybe<Scalars["Int"]>;
  }) => Maybe<Avatar>;
  /**
   * Connection between the User type and the BlockEditorPreview type
   */
  blockEditorPreviews: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<UserToBlockEditorPreviewConnectionWhereArgs>;
  }) => Maybe<UserToBlockEditorPreviewConnection>;
  /**
   * User metadata option name. Usually it will be &quot;wp_capabilities&quot;.
   */
  capKey?: Maybe<ScalarsEnums["String"]>;
  /**
   * A list of capabilities (permissions) granted to the user
   */
  capabilities?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * Connection between the User type and the Comment type
   */
  comments: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<UserToCommentConnectionWhereArgs>;
  }) => Maybe<UserToCommentConnection>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * Identifies the primary key from the database.
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * Description of the user.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * Email address of the user. This is equivalent to the WP_User-&gt;user_email property.
   */
  email?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the User type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<UserToEnqueuedScriptConnection>;
  /**
   * Connection between the User type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<UserToEnqueuedStylesheetConnection>;
  /**
   * A complete list of capabilities including capabilities inherited from a role. This is equivalent to the array keys of WP_User-&gt;allcaps.
   */
  extraCapabilities?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * First name of the user. This is equivalent to the WP_User-&gt;user_first_name property.
   */
  firstName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier for the user object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * Last name of the user. This is equivalent to the WP_User-&gt;user_last_name property.
   */
  lastName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The preferred language locale set for the user. Value derived from get_user_locale().
   */
  locale?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the User type and the mediaItem type
   */
  mediaItems: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<UserToMediaItemConnectionWhereArgs>;
  }) => Maybe<UserToMediaItemConnection>;
  /**
   * Display name of the user. This is equivalent to the WP_User-&gt;dispaly_name property.
   */
  name?: Maybe<ScalarsEnums["String"]>;
  /**
   * The nicename for the user. This field is equivalent to WP_User-&gt;user_nicename
   */
  nicename?: Maybe<ScalarsEnums["String"]>;
  /**
   * Nickname of the user.
   */
  nickname?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the User type and the page type
   */
  pages: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<UserToPageConnectionWhereArgs>;
  }) => Maybe<UserToPageConnection>;
  /**
   * Connection between the User type and the post type
   */
  posts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<UserToPostConnectionWhereArgs>;
  }) => Maybe<UserToPostConnection>;
  /**
   * The date the user registered or was created. The field follows a full ISO8601 date string format.
   */
  registeredDate?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the User and Revisions authored by the user
   */
  revisions: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
    /**
     * Arguments for filtering the connection
     */
    where?: Maybe<UserToContentRevisionUnionConnectionWhereArgs>;
  }) => Maybe<UserToContentRevisionUnionConnection>;
  /**
   * Connection between the User type and the UserRole type
   */
  roles: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<UserToUserRoleConnection>;
  /**
   * The Yoast SEO data of a user
   */
  seo?: Maybe<SEOUser>;
  /**
   * The slug for the user. This field is equivalent to WP_User-&gt;user_nicename
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
  /**
   * A website url that is associated with the user.
   */
  url?: Maybe<ScalarsEnums["String"]>;
  /**
   * The Id of the user. Equivalent to WP_User-&gt;ID
   * @deprecated Deprecated in favor of the databaseId field
   */
  userId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Username for the user. This field is equivalent to WP_User-&gt;user_login.
   */
  username?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A user role object
 */
export interface UserRole {
  __typename?: "UserRole";
  /**
   * The capabilities that belong to this role
   */
  capabilities?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The display name of the role
   */
  displayName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier for the user role object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * The registered name of the role
   */
  name?: Maybe<ScalarsEnums["String"]>;
}

/**
 * Connection between the User type and the BlockEditorPreview type
 */
export interface UserToBlockEditorPreviewConnection {
  __typename?: "UserToBlockEditorPreviewConnection";
  /**
   * Edges for the UserToBlockEditorPreviewConnection connection
   */
  edges?: Maybe<Array<Maybe<UserToBlockEditorPreviewConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<BlockEditorPreview>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface UserToBlockEditorPreviewConnectionEdge {
  __typename?: "UserToBlockEditorPreviewConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<BlockEditorPreview>;
}

/**
 * Connection between the User type and the Comment type
 */
export interface UserToCommentConnection {
  __typename?: "UserToCommentConnection";
  /**
   * Edges for the UserToCommentConnection connection
   */
  edges?: Maybe<Array<Maybe<UserToCommentConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Comment>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface UserToCommentConnectionEdge {
  __typename?: "UserToCommentConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Comment>;
}

/**
 * Connection between the User type and the ContentRevisionUnion type
 */
export interface UserToContentRevisionUnionConnection {
  __typename?: "UserToContentRevisionUnionConnection";
  /**
   * Edges for the UserToContentRevisionUnionConnection connection
   */
  edges?: Maybe<Array<Maybe<UserToContentRevisionUnionConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<ContentRevisionUnion>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface UserToContentRevisionUnionConnectionEdge {
  __typename?: "UserToContentRevisionUnionConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<ContentRevisionUnion>;
}

/**
 * Connection between the User type and the EnqueuedScript type
 */
export interface UserToEnqueuedScriptConnection {
  __typename?: "UserToEnqueuedScriptConnection";
  /**
   * Edges for the UserToEnqueuedScriptConnection connection
   */
  edges?: Maybe<Array<Maybe<UserToEnqueuedScriptConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<EnqueuedScript>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface UserToEnqueuedScriptConnectionEdge {
  __typename?: "UserToEnqueuedScriptConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<EnqueuedScript>;
}

/**
 * Connection between the User type and the EnqueuedStylesheet type
 */
export interface UserToEnqueuedStylesheetConnection {
  __typename?: "UserToEnqueuedStylesheetConnection";
  /**
   * Edges for the UserToEnqueuedStylesheetConnection connection
   */
  edges?: Maybe<Array<Maybe<UserToEnqueuedStylesheetConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<EnqueuedStylesheet>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface UserToEnqueuedStylesheetConnectionEdge {
  __typename?: "UserToEnqueuedStylesheetConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<EnqueuedStylesheet>;
}

/**
 * Connection between the User type and the mediaItem type
 */
export interface UserToMediaItemConnection {
  __typename?: "UserToMediaItemConnection";
  /**
   * Edges for the UserToMediaItemConnection connection
   */
  edges?: Maybe<Array<Maybe<UserToMediaItemConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<MediaItem>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface UserToMediaItemConnectionEdge {
  __typename?: "UserToMediaItemConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<MediaItem>;
}

/**
 * Connection between the User type and the page type
 */
export interface UserToPageConnection {
  __typename?: "UserToPageConnection";
  /**
   * Edges for the UserToPageConnection connection
   */
  edges?: Maybe<Array<Maybe<UserToPageConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Page>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface UserToPageConnectionEdge {
  __typename?: "UserToPageConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Page>;
}

/**
 * Connection between the User type and the post type
 */
export interface UserToPostConnection {
  __typename?: "UserToPostConnection";
  /**
   * Edges for the UserToPostConnection connection
   */
  edges?: Maybe<Array<Maybe<UserToPostConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<Post>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface UserToPostConnectionEdge {
  __typename?: "UserToPostConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<Post>;
}

/**
 * Connection between the User type and the UserRole type
 */
export interface UserToUserRoleConnection {
  __typename?: "UserToUserRoleConnection";
  /**
   * Edges for the UserToUserRoleConnection connection
   */
  edges?: Maybe<Array<Maybe<UserToUserRoleConnectionEdge>>>;
  /**
   * The nodes of the connection, without the edges
   */
  nodes?: Maybe<Array<Maybe<UserRole>>>;
  /**
   * Information about pagination in a connection.
   */
  pageInfo?: Maybe<WPPageInfo>;
}

/**
 * An edge in a connection
 */
export interface UserToUserRoleConnectionEdge {
  __typename?: "UserToUserRoleConnectionEdge";
  /**
   * A cursor for use in pagination
   */
  cursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * The item at the end of the edge
   */
  node?: Maybe<UserRole>;
}

/**
 * Information about pagination in a connection.
 */
export interface WPPageInfo {
  __typename?: "WPPageInfo";
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor?: Maybe<ScalarsEnums["String"]>;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: ScalarsEnums["Boolean"];
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: ScalarsEnums["Boolean"];
  /**
   * Raw schema for page
   */
  seo?: Maybe<SEOPostTypePageInfo>;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor?: Maybe<ScalarsEnums["String"]>;
}

/**
 * A Gravity Forms   field.
 */
export interface WebsiteField {
  __typename?: "WebsiteField";
  /**
   * When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels.
   */
  adminLabel?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field’s value can be pre-populated dynamically.
   */
  canPrepopulate?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Controls the visibility of the field based on values selected by the user.
   */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /**
   * String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields.
   */
  cssClass?: Maybe<ScalarsEnums["String"]>;
  /**
   * Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed.
   */
  defaultValue?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field description.
   */
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The placement of the field description.
   */
  descriptionPlacement?: Maybe<
    ScalarsEnums["FormFieldDescriptionPlacementEnum"]
  >;
  /**
   * Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true.
   */
  displayOnly?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Contains the message that is displayed for fields that fail validation.
   */
  errorMessage?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field ID.
   */
  id: ScalarsEnums["Int"];
  /**
   * Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`.
   */
  inputName?: Maybe<ScalarsEnums["String"]>;
  /**
   * The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields.
   */
  inputType?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it.
   */
  isRequired?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Field label that will be displayed on the form and on the admin pages.
   */
  label?: Maybe<ScalarsEnums["String"]>;
  /**
   * The field label position.
   */
  labelPlacement?: Maybe<ScalarsEnums["FormFieldLabelPlacementEnum"]>;
  /**
   * The number of CSS grid columns the field should span.
   */
  layoutGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The number of CSS grid columns the spacer field following this one should span.
   */
  layoutSpacerGridColumnSpan?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form page this field is located on. Default is 1.
   */
  pageNumber?: Maybe<ScalarsEnums["Int"]>;
  /**
   * The form field-specifc policies for exporting and erasing personal data.
   */
  personalData?: Maybe<FormFieldDataPolicy>;
  /**
   * Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form.
   */
  placeholder?: Maybe<ScalarsEnums["String"]>;
  /**
   * Determines if the field allows duplicate submissions.
   */
  shouldAllowDuplicates?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Determines the size of the field when displayed on the page.
   */
  size?: Maybe<ScalarsEnums["FormFieldSizeEnum"]>;
  /**
   * The type of field to be displayed.
   */
  type?: Maybe<ScalarsEnums["FormFieldTypeEnum"]>;
  /**
   * The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array.
   */
  value?: Maybe<ScalarsEnums["String"]>;
  /**
   * Field visibility.
   */
  visibility?: Maybe<ScalarsEnums["FormFieldVisibilityEnum"]>;
}

/**
 * The work type
 */
export interface Work {
  __typename?: "Work";
  /**
   * Gutenberg blocks
   */
  blocks?: Maybe<Array<Block>>;
  /**
   * Gutenberg blocks as json string
   */
  blocksJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * @deprecated Deprecated in favor of using Next.js pages
   */
  conditionalTags?: Maybe<ConditionalTags>;
  /**
   * The content of the post.
   */
  content: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the ContentType type
   */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /**
   * The name of the Content Type the node belongs to
   */
  contentTypeName: ScalarsEnums["String"];
  /**
   * The unique resource identifier path
   */
  databaseId: ScalarsEnums["Int"];
  /**
   * Post publishing date.
   */
  date?: Maybe<ScalarsEnums["String"]>;
  /**
   * The publishing date set in GMT.
   */
  dateGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * The desired slug of the post
   */
  desiredSlug?: Maybe<ScalarsEnums["String"]>;
  /**
   * If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds
   */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /**
   * The RSS enclosure for the object
   */
  enclosure?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the ContentNode type and the EnqueuedScript type
   */
  enqueuedScripts: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedScriptConnection>;
  /**
   * Connection between the ContentNode type and the EnqueuedStylesheet type
   */
  enqueuedStylesheets: (args?: {
    /**
     * Cursor used along with the "first" argument to reference where in the dataset to get data
     */
    after?: Maybe<Scalars["String"]>;
    /**
     * Cursor used along with the "last" argument to reference where in the dataset to get data
     */
    before?: Maybe<Scalars["String"]>;
    /**
     * The number of items to return after the referenced "after" cursor
     */
    first?: Maybe<Scalars["Int"]>;
    /**
     * The number of items to return before the referenced "before" cursor
     */
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * Connection between the NodeWithFeaturedImage type and the MediaItem type
   */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /**
   * The database identifier for the featured image node assigned to the content node
   */
  featuredImageDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Globally unique ID of the featured image assigned to the node
   */
  featuredImageId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table.
   */
  guid?: Maybe<ScalarsEnums["String"]>;
  /**
   * The globally unique identifier of the work object.
   */
  id: ScalarsEnums["ID"];
  /**
   * Whether the node is a Content Node
   */
  isContentNode: ScalarsEnums["Boolean"];
  /**
   * Whether the object is a node in the preview state
   */
  isPreview?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the object is restricted from the current viewer
   */
  isRestricted?: Maybe<ScalarsEnums["Boolean"]>;
  /**
   * Whether the node is a Term
   */
  isTermNode: ScalarsEnums["Boolean"];
  /**
   * The user that most recently edited the node
   */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /**
   * The permalink of the post
   */
  link?: Maybe<ScalarsEnums["String"]>;
  /**
   * The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time.
   */
  modified?: Maybe<ScalarsEnums["String"]>;
  /**
   * The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT.
   */
  modifiedGmt?: Maybe<ScalarsEnums["String"]>;
  /**
   * Connection between the work type and the work type
   */
  preview?: Maybe<WorkToPreviewConnectionEdge>;
  /**
   * Previewed gutenberg blocks
   */
  previewBlocks?: Maybe<Array<Block>>;
  /**
   * Previewed Gutenberg blocks as json string
   */
  previewBlocksJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * The database id of the preview node
   */
  previewRevisionDatabaseId?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Whether the object is a node in the preview state
   */
  previewRevisionId?: Maybe<ScalarsEnums["ID"]>;
  /**
   * The Yoast SEO data of the ContentNode
   */
  seo?: Maybe<PostTypeSEO>;
  /**
   * The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table.
   */
  slug?: Maybe<ScalarsEnums["String"]>;
  /**
   * The current status of the object
   */
  status?: Maybe<ScalarsEnums["String"]>;
  /**
   * The template assigned to a node of content
   */
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<ScalarsEnums["String"]>>>;
  /**
   * The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made.
   */
  title: (args?: {
    /**
     * Format of the field output
     */
    format?: Maybe<PostObjectFieldFormatEnum>;
  }) => Maybe<ScalarsEnums["String"]>;
  /**
   * The unique resource identifier path
   */
  uri?: Maybe<ScalarsEnums["String"]>;
  /**
   * Added to the GraphQL Schema because the ACF Field Group &quot;Work&quot; was set to Show in GraphQL.
   */
  workACF?: Maybe<Work_Workacf>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  workId: ScalarsEnums["Int"];
}

/**
 * Connection between the work type and the work type
 */
export interface WorkToPreviewConnectionEdge {
  __typename?: "WorkToPreviewConnectionEdge";
  /**
   * The node of the connection, without the edges
   */
  node?: Maybe<Work>;
}

/**
 * Field Group
 */
export interface Work_Workacf {
  __typename?: "Work_Workacf";
  color?: Maybe<ScalarsEnums["String"]>;
  description?: Maybe<ScalarsEnums["String"]>;
  /**
   * The name of the ACF Field Group
   */
  fieldGroupName?: Maybe<ScalarsEnums["String"]>;
  link?: Maybe<ScalarsEnums["String"]>;
  problem?: Maybe<ScalarsEnums["String"]>;
  solution?: Maybe<ScalarsEnums["String"]>;
}

/**
 * The writing setting type
 */
export interface WritingSettings {
  __typename?: "WritingSettings";
  /**
   * Default post category.
   */
  defaultCategory?: Maybe<ScalarsEnums["Int"]>;
  /**
   * Default post format.
   */
  defaultPostFormat?: Maybe<ScalarsEnums["String"]>;
  /**
   * Convert emoticons like :-) and :-P to graphics on display.
   */
  useSmilies?: Maybe<ScalarsEnums["Boolean"]>;
}

/**
 * yoast/faq-block block
 */
export interface YoastFaqBlock {
  __typename?: "YoastFaqBlock";
  attributes?: Maybe<YoastFaqBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface YoastFaqBlockAttributes {
  __typename?: "YoastFaqBlockAttributes";
  additionalListCssClasses?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  questions?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
}

export interface YoastFaqBlockAttributesUnion {
  __typename?:
    | "YoastFaqBlockAttributes"
    | "YoastFaqBlockDeprecatedV1Attributes";
  $on: $YoastFaqBlockAttributesUnion;
}

export interface YoastFaqBlockDeprecatedV1Attributes {
  __typename?: "YoastFaqBlockDeprecatedV1Attributes";
  additionalListCssClasses?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  questions?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
}

/**
 * yoast/how-to-block block
 */
export interface YoastHowToBlock {
  __typename?: "YoastHowToBlock";
  attributes?: Maybe<YoastHowToBlockAttributesUnion>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface YoastHowToBlockAttributes {
  __typename?: "YoastHowToBlockAttributes";
  additionalListCssClasses?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  days?: Maybe<ScalarsEnums["String"]>;
  defaultDurationText?: Maybe<ScalarsEnums["String"]>;
  description?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  durationText?: Maybe<ScalarsEnums["String"]>;
  hasDuration?: Maybe<ScalarsEnums["Boolean"]>;
  hours?: Maybe<ScalarsEnums["String"]>;
  jsonDescription?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  minutes?: Maybe<ScalarsEnums["String"]>;
  steps?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  unorderedList?: Maybe<ScalarsEnums["Boolean"]>;
}

export interface YoastHowToBlockAttributesUnion {
  __typename?:
    | "YoastHowToBlockAttributes"
    | "YoastHowToBlockDeprecatedV1Attributes"
    | "YoastHowToBlockDeprecatedV2Attributes";
  $on: $YoastHowToBlockAttributesUnion;
}

export interface YoastHowToBlockDeprecatedV1Attributes {
  __typename?: "YoastHowToBlockDeprecatedV1Attributes";
  additionalListCssClasses?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  days?: Maybe<ScalarsEnums["String"]>;
  defaultDurationText?: Maybe<ScalarsEnums["String"]>;
  description?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  durationText?: Maybe<ScalarsEnums["String"]>;
  hasDuration?: Maybe<ScalarsEnums["Boolean"]>;
  hours?: Maybe<ScalarsEnums["String"]>;
  jsonDescription?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  minutes?: Maybe<ScalarsEnums["String"]>;
  steps?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  unorderedList?: Maybe<ScalarsEnums["Boolean"]>;
}

export interface YoastHowToBlockDeprecatedV2Attributes {
  __typename?: "YoastHowToBlockDeprecatedV2Attributes";
  additionalListCssClasses?: Maybe<ScalarsEnums["String"]>;
  className?: Maybe<ScalarsEnums["String"]>;
  days?: Maybe<ScalarsEnums["String"]>;
  defaultDurationText?: Maybe<ScalarsEnums["String"]>;
  description?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  durationText?: Maybe<ScalarsEnums["String"]>;
  hasDuration?: Maybe<ScalarsEnums["Boolean"]>;
  hours?: Maybe<ScalarsEnums["String"]>;
  jsonDescription?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
  minutes?: Maybe<ScalarsEnums["String"]>;
  steps?: Maybe<ScalarsEnums["BlockAttributesArray"]>;
  unorderedList?: Maybe<ScalarsEnums["Boolean"]>;
}

/**
 * yoast-seo/breadcrumbs block
 */
export interface YoastSeoBreadcrumbsBlock {
  __typename?: "YoastSeoBreadcrumbsBlock";
  attributes?: Maybe<YoastSeoBreadcrumbsBlockAttributes>;
  /**
   * Block attributes, JSON encoded
   */
  attributesJSON?: Maybe<ScalarsEnums["String"]>;
  /**
   * Server side rendered content.
   */
  dynamicContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Gutenberg blocks
   */
  innerBlocks?: Maybe<Array<Block>>;
  /**
   * Is block rendered server side.
   */
  isDynamic: ScalarsEnums["Boolean"];
  /**
   * Name of the block.
   */
  name: ScalarsEnums["String"];
  order: ScalarsEnums["Int"];
  /**
   * Original HTML content.
   */
  originalContent?: Maybe<ScalarsEnums["String"]>;
  /**
   * Parent post.
   */
  parentNode: Node;
  /**
   * Parent post id.
   */
  parentNodeDatabaseId: ScalarsEnums["Int"];
  /**
   * Original HTML content with inner blocks.
   */
  saveContent?: Maybe<ScalarsEnums["String"]>;
}

export interface YoastSeoBreadcrumbsBlockAttributes {
  __typename?: "YoastSeoBreadcrumbsBlockAttributes";
  className?: Maybe<ScalarsEnums["String"]>;
  lock?: Maybe<ScalarsEnums["BlockAttributesObject"]>;
}

export interface Mutation {
  __typename?: "Mutation";
  createCategory: (args: {
    input: CreateCategoryInput;
  }) => Maybe<CreateCategoryPayload>;
  createComment: (args: {
    input: CreateCommentInput;
  }) => Maybe<CreateCommentPayload>;
  createJob: (args: { input: CreateJobInput }) => Maybe<CreateJobPayload>;
  createMediaItem: (args: {
    input: CreateMediaItemInput;
  }) => Maybe<CreateMediaItemPayload>;
  createPage: (args: { input: CreatePageInput }) => Maybe<CreatePagePayload>;
  createPost: (args: { input: CreatePostInput }) => Maybe<CreatePostPayload>;
  createPostFormat: (args: {
    input: CreatePostFormatInput;
  }) => Maybe<CreatePostFormatPayload>;
  createReusableBlock: (args: {
    input: CreateReusableBlockInput;
  }) => Maybe<CreateReusableBlockPayload>;
  createService: (args: {
    input: CreateServiceInput;
  }) => Maybe<CreateServicePayload>;
  createSpecialty: (args: {
    input: CreateSpecialtyInput;
  }) => Maybe<CreateSpecialtyPayload>;
  createTag: (args: { input: CreateTagInput }) => Maybe<CreateTagPayload>;
  createTeamMember: (args: {
    input: CreateTeam_memberInput;
  }) => Maybe<CreateTeam_memberPayload>;
  createUser: (args: { input: CreateUserInput }) => Maybe<CreateUserPayload>;
  createWork: (args: { input: CreateWorkInput }) => Maybe<CreateWorkPayload>;
  deleteCategory: (args: {
    input: DeleteCategoryInput;
  }) => Maybe<DeleteCategoryPayload>;
  deleteComment: (args: {
    input: DeleteCommentInput;
  }) => Maybe<DeleteCommentPayload>;
  deleteGfDraftEntry: (args: {
    input: DeleteGfDraftEntryInput;
  }) => Maybe<DeleteGfDraftEntryPayload>;
  deleteGfEntry: (args: {
    input: DeleteGfEntryInput;
  }) => Maybe<DeleteGfEntryPayload>;
  deleteJob: (args: { input: DeleteJobInput }) => Maybe<DeleteJobPayload>;
  deleteMediaItem: (args: {
    input: DeleteMediaItemInput;
  }) => Maybe<DeleteMediaItemPayload>;
  deletePage: (args: { input: DeletePageInput }) => Maybe<DeletePagePayload>;
  deletePost: (args: { input: DeletePostInput }) => Maybe<DeletePostPayload>;
  deletePostFormat: (args: {
    input: DeletePostFormatInput;
  }) => Maybe<DeletePostFormatPayload>;
  deleteReusableBlock: (args: {
    input: DeleteReusableBlockInput;
  }) => Maybe<DeleteReusableBlockPayload>;
  deleteService: (args: {
    input: DeleteServiceInput;
  }) => Maybe<DeleteServicePayload>;
  deleteSpecialty: (args: {
    input: DeleteSpecialtyInput;
  }) => Maybe<DeleteSpecialtyPayload>;
  deleteTag: (args: { input: DeleteTagInput }) => Maybe<DeleteTagPayload>;
  deleteTeamMember: (args: {
    input: DeleteTeam_memberInput;
  }) => Maybe<DeleteTeam_memberPayload>;
  deleteUser: (args: { input: DeleteUserInput }) => Maybe<DeleteUserPayload>;
  deleteWork: (args: { input: DeleteWorkInput }) => Maybe<DeleteWorkPayload>;
  generateAuthorizationCode: (args: {
    input: GenerateAuthorizationCodeInput;
  }) => Maybe<GenerateAuthorizationCodePayload>;
  increaseCount: (args?: {
    count?: Maybe<Scalars["Int"]>;
  }) => Maybe<ScalarsEnums["Int"]>;
  registerUser: (args: {
    input: RegisterUserInput;
  }) => Maybe<RegisterUserPayload>;
  resetUserPassword: (args: {
    input: ResetUserPasswordInput;
  }) => Maybe<ResetUserPasswordPayload>;
  restoreComment: (args: {
    input: RestoreCommentInput;
  }) => Maybe<RestoreCommentPayload>;
  sendPasswordResetEmail: (args: {
    input: SendPasswordResetEmailInput;
  }) => Maybe<SendPasswordResetEmailPayload>;
  submitGfDraftEntry: (args: {
    input: SubmitGfDraftEntryInput;
  }) => Maybe<SubmitGfDraftEntryPayload>;
  submitGfForm: (args: {
    input: SubmitGfFormInput;
  }) => Maybe<SubmitGfFormPayload>;
  updateCategory: (args: {
    input: UpdateCategoryInput;
  }) => Maybe<UpdateCategoryPayload>;
  updateComment: (args: {
    input: UpdateCommentInput;
  }) => Maybe<UpdateCommentPayload>;
  updateGfDraftEntry: (args: {
    input: UpdateGfDraftEntryInput;
  }) => Maybe<UpdateGfDraftEntryPayload>;
  updateGfEntry: (args: {
    input: UpdateGfEntryInput;
  }) => Maybe<UpdateGfEntryPayload>;
  updateJob: (args: { input: UpdateJobInput }) => Maybe<UpdateJobPayload>;
  updateMediaItem: (args: {
    input: UpdateMediaItemInput;
  }) => Maybe<UpdateMediaItemPayload>;
  updatePage: (args: { input: UpdatePageInput }) => Maybe<UpdatePagePayload>;
  updatePost: (args: { input: UpdatePostInput }) => Maybe<UpdatePostPayload>;
  updatePostFormat: (args: {
    input: UpdatePostFormatInput;
  }) => Maybe<UpdatePostFormatPayload>;
  updateReusableBlock: (args: {
    input: UpdateReusableBlockInput;
  }) => Maybe<UpdateReusableBlockPayload>;
  updateService: (args: {
    input: UpdateServiceInput;
  }) => Maybe<UpdateServicePayload>;
  updateSettings: (args: {
    input: UpdateSettingsInput;
  }) => Maybe<UpdateSettingsPayload>;
  updateSpecialty: (args: {
    input: UpdateSpecialtyInput;
  }) => Maybe<UpdateSpecialtyPayload>;
  updateTag: (args: { input: UpdateTagInput }) => Maybe<UpdateTagPayload>;
  updateTeamMember: (args: {
    input: UpdateTeam_memberInput;
  }) => Maybe<UpdateTeam_memberPayload>;
  updateUser: (args: { input: UpdateUserInput }) => Maybe<UpdateUserPayload>;
  updateWork: (args: { input: UpdateWorkInput }) => Maybe<UpdateWorkPayload>;
}

export interface Query {
  __typename?: "Query";
  allSettings?: Maybe<Settings>;
  blockEditorContentNodes: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<BlockEditorContentNodeConnectionWhereArgs>;
  }) => Maybe<BlockEditorContentNodeConnection>;
  blockEditorPreview: (args: {
    asPreview?: Maybe<Scalars["Boolean"]>;
    id: Scalars["ID"];
    idType?: Maybe<BlockEditorPreviewIdType>;
  }) => Maybe<BlockEditorPreview>;
  blockEditorPreviewBy: (args?: {
    blockEditorPreviewId?: Maybe<Scalars["Int"]>;
    id?: Maybe<Scalars["ID"]>;
    slug?: Maybe<Scalars["String"]>;
    uri?: Maybe<Scalars["String"]>;
  }) => Maybe<BlockEditorPreview>;
  blockEditorPreviews: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToBlockEditorPreviewConnectionWhereArgs>;
  }) => Maybe<RootQueryToBlockEditorPreviewConnection>;
  categories: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToCategoryConnectionWhereArgs>;
  }) => Maybe<RootQueryToCategoryConnection>;
  category: (args: {
    id: Scalars["ID"];
    idType?: Maybe<CategoryIdType>;
  }) => Maybe<Category>;
  comment: (args: { id: Scalars["ID"] }) => Maybe<Comment>;
  comments: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToCommentConnectionWhereArgs>;
  }) => Maybe<RootQueryToCommentConnection>;
  contentNode: (args: {
    asPreview?: Maybe<Scalars["Boolean"]>;
    contentType?: Maybe<ContentTypeEnum>;
    id: Scalars["ID"];
    idType?: Maybe<ContentNodeIdTypeEnum>;
  }) => Maybe<ContentNode>;
  contentNodes: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToContentNodeConnectionWhereArgs>;
  }) => Maybe<RootQueryToContentNodeConnection>;
  contentType: (args: {
    id: Scalars["ID"];
    idType?: Maybe<ContentTypeIdTypeEnum>;
  }) => Maybe<ContentType>;
  contentTypes: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<RootQueryToContentTypeConnection>;
  discussionSettings?: Maybe<DiscussionSettings>;
  generalOptionsSettings?: Maybe<GeneralOptionsSettings>;
  generalSettings?: Maybe<GeneralSettings>;
  gfDraftEntry: (args: {
    id: Scalars["ID"];
    idType?: Maybe<DraftEntryIdTypeEnum>;
  }) => Maybe<GfDraftEntry>;
  gfEntries: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToGfEntryConnectionWhereArgs>;
  }) => Maybe<RootQueryToGfEntryConnection>;
  gfEntry: (args: {
    id: Scalars["ID"];
    idType?: Maybe<EntryIdTypeEnum>;
  }) => Maybe<GfEntry>;
  gfForm: (args: {
    id: Scalars["ID"];
    idType?: Maybe<FormIdTypeEnum>;
  }) => Maybe<GfForm>;
  gfForms: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToGfFormConnectionWhereArgs>;
  }) => Maybe<RootQueryToGfFormConnection>;
  gfSettings?: Maybe<GfSettings>;
  gfSubmittedEntries: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToGfSubmittedEntryConnectionWhereArgs>;
  }) => Maybe<RootQueryToGfSubmittedEntryConnection>;
  gfSubmittedEntry: (args: {
    id: Scalars["ID"];
    idType?: Maybe<SubmittedEntryIdTypeEnum>;
  }) => Maybe<GfSubmittedEntry>;
  job: (args: {
    asPreview?: Maybe<Scalars["Boolean"]>;
    id: Scalars["ID"];
    idType?: Maybe<JobIdType>;
  }) => Maybe<Job>;
  jobBy: (args?: {
    id?: Maybe<Scalars["ID"]>;
    jobId?: Maybe<Scalars["Int"]>;
    slug?: Maybe<Scalars["String"]>;
    uri?: Maybe<Scalars["String"]>;
  }) => Maybe<Job>;
  jobs: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToJobConnectionWhereArgs>;
  }) => Maybe<RootQueryToJobConnection>;
  mediaItem: (args: {
    asPreview?: Maybe<Scalars["Boolean"]>;
    id: Scalars["ID"];
    idType?: Maybe<MediaItemIdType>;
  }) => Maybe<MediaItem>;
  mediaItemBy: (args?: {
    id?: Maybe<Scalars["ID"]>;
    mediaItemId?: Maybe<Scalars["Int"]>;
    slug?: Maybe<Scalars["String"]>;
    uri?: Maybe<Scalars["String"]>;
  }) => Maybe<MediaItem>;
  mediaItems: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToMediaItemConnectionWhereArgs>;
  }) => Maybe<RootQueryToMediaItemConnection>;
  menu: (args: {
    id: Scalars["ID"];
    idType?: Maybe<MenuNodeIdTypeEnum>;
  }) => Maybe<Menu>;
  menuItem: (args: {
    id: Scalars["ID"];
    idType?: Maybe<MenuItemNodeIdTypeEnum>;
  }) => Maybe<MenuItem>;
  menuItems: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToMenuItemConnectionWhereArgs>;
  }) => Maybe<RootQueryToMenuItemConnection>;
  menus: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToMenuConnectionWhereArgs>;
  }) => Maybe<RootQueryToMenuConnection>;
  node: (args?: { id?: Maybe<Scalars["ID"]> }) => Maybe<Node>;
  nodeByUri: (args: {
    uri: Scalars["String"];
  }) => Maybe<UniformResourceIdentifiable>;
  page: (args: {
    asPreview?: Maybe<Scalars["Boolean"]>;
    id: Scalars["ID"];
    idType?: Maybe<PageIdType>;
  }) => Maybe<Page>;
  pageBy: (args?: {
    id?: Maybe<Scalars["ID"]>;
    pageId?: Maybe<Scalars["Int"]>;
    uri?: Maybe<Scalars["String"]>;
  }) => Maybe<Page>;
  pages: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToPageConnectionWhereArgs>;
  }) => Maybe<RootQueryToPageConnection>;
  plugin: (args: { id: Scalars["ID"] }) => Maybe<Plugin>;
  plugins: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToPluginConnectionWhereArgs>;
  }) => Maybe<RootQueryToPluginConnection>;
  post: (args: {
    asPreview?: Maybe<Scalars["Boolean"]>;
    id: Scalars["ID"];
    idType?: Maybe<PostIdType>;
  }) => Maybe<Post>;
  postBy: (args?: {
    id?: Maybe<Scalars["ID"]>;
    postId?: Maybe<Scalars["Int"]>;
    slug?: Maybe<Scalars["String"]>;
    uri?: Maybe<Scalars["String"]>;
  }) => Maybe<Post>;
  postFormat: (args: {
    id: Scalars["ID"];
    idType?: Maybe<PostFormatIdType>;
  }) => Maybe<PostFormat>;
  postFormats: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToPostFormatConnectionWhereArgs>;
  }) => Maybe<RootQueryToPostFormatConnection>;
  posts: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToPostConnectionWhereArgs>;
  }) => Maybe<RootQueryToPostConnection>;
  readingSettings?: Maybe<ReadingSettings>;
  registeredScripts: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<RootQueryToEnqueuedScriptConnection>;
  registeredStylesheets: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<RootQueryToEnqueuedStylesheetConnection>;
  reusableBlock: (args: {
    asPreview?: Maybe<Scalars["Boolean"]>;
    id: Scalars["ID"];
    idType?: Maybe<ReusableBlockIdType>;
  }) => Maybe<ReusableBlock>;
  reusableBlockBy: (args?: {
    id?: Maybe<Scalars["ID"]>;
    reusableBlockId?: Maybe<Scalars["Int"]>;
    slug?: Maybe<Scalars["String"]>;
    uri?: Maybe<Scalars["String"]>;
  }) => Maybe<ReusableBlock>;
  reusableBlocks: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToReusableBlockConnectionWhereArgs>;
  }) => Maybe<RootQueryToReusableBlockConnection>;
  revisions: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToContentRevisionUnionConnectionWhereArgs>;
  }) => Maybe<RootQueryToContentRevisionUnionConnection>;
  seo?: Maybe<SEOConfig>;
  service: (args: {
    asPreview?: Maybe<Scalars["Boolean"]>;
    id: Scalars["ID"];
    idType?: Maybe<ServiceIdType>;
  }) => Maybe<Service>;
  serviceBy: (args?: {
    id?: Maybe<Scalars["ID"]>;
    serviceId?: Maybe<Scalars["Int"]>;
    slug?: Maybe<Scalars["String"]>;
    uri?: Maybe<Scalars["String"]>;
  }) => Maybe<Service>;
  services: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToServiceConnectionWhereArgs>;
  }) => Maybe<RootQueryToServiceConnection>;
  specialties: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToSpecialtyConnectionWhereArgs>;
  }) => Maybe<RootQueryToSpecialtyConnection>;
  specialty: (args: {
    asPreview?: Maybe<Scalars["Boolean"]>;
    id: Scalars["ID"];
    idType?: Maybe<SpecialtyIdType>;
  }) => Maybe<Specialty>;
  specialtyBy: (args?: {
    id?: Maybe<Scalars["ID"]>;
    slug?: Maybe<Scalars["String"]>;
    specialtyId?: Maybe<Scalars["Int"]>;
    uri?: Maybe<Scalars["String"]>;
  }) => Maybe<Specialty>;
  tag: (args: { id: Scalars["ID"]; idType?: Maybe<TagIdType> }) => Maybe<Tag>;
  tags: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToTagConnectionWhereArgs>;
  }) => Maybe<RootQueryToTagConnection>;
  taxonomies: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<RootQueryToTaxonomyConnection>;
  taxonomy: (args: {
    id: Scalars["ID"];
    idType?: Maybe<TaxonomyIdTypeEnum>;
  }) => Maybe<Taxonomy>;
  teamMember: (args: {
    asPreview?: Maybe<Scalars["Boolean"]>;
    id: Scalars["ID"];
    idType?: Maybe<Team_memberIdType>;
  }) => Maybe<Team_member>;
  teamMemberBy: (args?: {
    id?: Maybe<Scalars["ID"]>;
    slug?: Maybe<Scalars["String"]>;
    team_memberId?: Maybe<Scalars["Int"]>;
    uri?: Maybe<Scalars["String"]>;
  }) => Maybe<Team_member>;
  teamMembers: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToTeam_memberConnectionWhereArgs>;
  }) => Maybe<RootQueryToTeam_memberConnection>;
  termNode: (args: {
    id: Scalars["ID"];
    idType?: Maybe<TermNodeIdTypeEnum>;
    taxonomy?: Maybe<TaxonomyEnum>;
  }) => Maybe<TermNode>;
  terms: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToTermNodeConnectionWhereArgs>;
  }) => Maybe<RootQueryToTermNodeConnection>;
  theme: (args: { id: Scalars["ID"] }) => Maybe<Theme>;
  themes: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<RootQueryToThemeConnection>;
  user: (args: {
    id: Scalars["ID"];
    idType?: Maybe<UserNodeIdTypeEnum>;
  }) => Maybe<User>;
  userRole: (args: { id: Scalars["ID"] }) => Maybe<UserRole>;
  userRoles: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
  }) => Maybe<RootQueryToUserRoleConnection>;
  users: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToUserConnectionWhereArgs>;
  }) => Maybe<RootQueryToUserConnection>;
  viewer?: Maybe<User>;
  work: (args: {
    asPreview?: Maybe<Scalars["Boolean"]>;
    id: Scalars["ID"];
    idType?: Maybe<WorkIdType>;
  }) => Maybe<Work>;
  workBy: (args?: {
    id?: Maybe<Scalars["ID"]>;
    slug?: Maybe<Scalars["String"]>;
    uri?: Maybe<Scalars["String"]>;
    workId?: Maybe<Scalars["Int"]>;
  }) => Maybe<Work>;
  works: (args?: {
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
    where?: Maybe<RootQueryToWorkConnectionWhereArgs>;
  }) => Maybe<RootQueryToWorkConnection>;
  writingSettings?: Maybe<WritingSettings>;
}

export interface Subscription {
  __typename?: "Subscription";
}

export interface SchemaObjectTypes {
  AddressField: AddressField;
  AddressFieldValue: AddressFieldValue;
  AddressInputProperty: AddressInputProperty;
  Avatar: Avatar;
  BlockEditorContentNodeConnection: BlockEditorContentNodeConnection;
  BlockEditorContentNodeConnectionEdge: BlockEditorContentNodeConnectionEdge;
  BlockEditorPreview: BlockEditorPreview;
  BlockEditorPreviewToPreviewConnectionEdge: BlockEditorPreviewToPreviewConnectionEdge;
  CaptchaField: CaptchaField;
  Category: Category;
  CategoryToAncestorsCategoryConnection: CategoryToAncestorsCategoryConnection;
  CategoryToAncestorsCategoryConnectionEdge: CategoryToAncestorsCategoryConnectionEdge;
  CategoryToCategoryConnection: CategoryToCategoryConnection;
  CategoryToCategoryConnectionEdge: CategoryToCategoryConnectionEdge;
  CategoryToContentNodeConnection: CategoryToContentNodeConnection;
  CategoryToContentNodeConnectionEdge: CategoryToContentNodeConnectionEdge;
  CategoryToParentCategoryConnectionEdge: CategoryToParentCategoryConnectionEdge;
  CategoryToPostConnection: CategoryToPostConnection;
  CategoryToPostConnectionEdge: CategoryToPostConnectionEdge;
  CategoryToTaxonomyConnectionEdge: CategoryToTaxonomyConnectionEdge;
  CheckboxField: CheckboxField;
  CheckboxFieldChoice: CheckboxFieldChoice;
  CheckboxFieldValue: CheckboxFieldValue;
  CheckboxInputProperty: CheckboxInputProperty;
  Comment: Comment;
  CommentAuthor: CommentAuthor;
  CommentToCommentConnection: CommentToCommentConnection;
  CommentToCommentConnectionEdge: CommentToCommentConnectionEdge;
  CommentToCommenterConnectionEdge: CommentToCommenterConnectionEdge;
  CommentToContentNodeConnectionEdge: CommentToContentNodeConnectionEdge;
  CommentToParentCommentConnectionEdge: CommentToParentCommentConnectionEdge;
  ConditionalLogic: ConditionalLogic;
  ConditionalLogicRule: ConditionalLogicRule;
  ConditionalTags: ConditionalTags;
  ConsentField: ConsentField;
  ContentNodeToContentTypeConnectionEdge: ContentNodeToContentTypeConnectionEdge;
  ContentNodeToEditLastConnectionEdge: ContentNodeToEditLastConnectionEdge;
  ContentNodeToEditLockConnectionEdge: ContentNodeToEditLockConnectionEdge;
  ContentNodeToEnqueuedScriptConnection: ContentNodeToEnqueuedScriptConnection;
  ContentNodeToEnqueuedScriptConnectionEdge: ContentNodeToEnqueuedScriptConnectionEdge;
  ContentNodeToEnqueuedStylesheetConnection: ContentNodeToEnqueuedStylesheetConnection;
  ContentNodeToEnqueuedStylesheetConnectionEdge: ContentNodeToEnqueuedStylesheetConnectionEdge;
  ContentType: ContentType;
  ContentTypeToContentNodeConnection: ContentTypeToContentNodeConnection;
  ContentTypeToContentNodeConnectionEdge: ContentTypeToContentNodeConnectionEdge;
  ContentTypeToTaxonomyConnection: ContentTypeToTaxonomyConnection;
  ContentTypeToTaxonomyConnectionEdge: ContentTypeToTaxonomyConnectionEdge;
  CoreArchivesBlock: CoreArchivesBlock;
  CoreArchivesBlockAttributes: CoreArchivesBlockAttributes;
  CoreAudioBlock: CoreAudioBlock;
  CoreAudioBlockAttributes: CoreAudioBlockAttributes;
  CoreAudioBlockDeprecatedV1Attributes: CoreAudioBlockDeprecatedV1Attributes;
  CoreAvatarBlock: CoreAvatarBlock;
  CoreAvatarBlockAttributes: CoreAvatarBlockAttributes;
  CoreBlock: CoreBlock;
  CoreBlockAttributes: CoreBlockAttributes;
  CoreButtonBlock: CoreButtonBlock;
  CoreButtonBlockAttributes: CoreButtonBlockAttributes;
  CoreButtonBlockDeprecatedV10Attributes: CoreButtonBlockDeprecatedV10Attributes;
  CoreButtonBlockDeprecatedV11Attributes: CoreButtonBlockDeprecatedV11Attributes;
  CoreButtonBlockDeprecatedV1Attributes: CoreButtonBlockDeprecatedV1Attributes;
  CoreButtonBlockDeprecatedV2Attributes: CoreButtonBlockDeprecatedV2Attributes;
  CoreButtonBlockDeprecatedV3Attributes: CoreButtonBlockDeprecatedV3Attributes;
  CoreButtonBlockDeprecatedV4Attributes: CoreButtonBlockDeprecatedV4Attributes;
  CoreButtonBlockDeprecatedV5Attributes: CoreButtonBlockDeprecatedV5Attributes;
  CoreButtonBlockDeprecatedV6Attributes: CoreButtonBlockDeprecatedV6Attributes;
  CoreButtonBlockDeprecatedV7Attributes: CoreButtonBlockDeprecatedV7Attributes;
  CoreButtonBlockDeprecatedV8Attributes: CoreButtonBlockDeprecatedV8Attributes;
  CoreButtonBlockDeprecatedV9Attributes: CoreButtonBlockDeprecatedV9Attributes;
  CoreButtonsBlock: CoreButtonsBlock;
  CoreButtonsBlockAttributes: CoreButtonsBlockAttributes;
  CoreButtonsBlockDeprecatedV1Attributes: CoreButtonsBlockDeprecatedV1Attributes;
  CoreButtonsBlockDeprecatedV2Attributes: CoreButtonsBlockDeprecatedV2Attributes;
  CoreCalendarBlock: CoreCalendarBlock;
  CoreCalendarBlockAttributes: CoreCalendarBlockAttributes;
  CoreCategoriesBlock: CoreCategoriesBlock;
  CoreCategoriesBlockAttributes: CoreCategoriesBlockAttributes;
  CoreCodeBlock: CoreCodeBlock;
  CoreCodeBlockAttributes: CoreCodeBlockAttributes;
  CoreColumnBlock: CoreColumnBlock;
  CoreColumnBlockAttributes: CoreColumnBlockAttributes;
  CoreColumnBlockDeprecatedV1Attributes: CoreColumnBlockDeprecatedV1Attributes;
  CoreColumnsBlock: CoreColumnsBlock;
  CoreColumnsBlockAttributes: CoreColumnsBlockAttributes;
  CoreColumnsBlockDeprecatedV1Attributes: CoreColumnsBlockDeprecatedV1Attributes;
  CoreColumnsBlockDeprecatedV2Attributes: CoreColumnsBlockDeprecatedV2Attributes;
  CoreColumnsBlockDeprecatedV3Attributes: CoreColumnsBlockDeprecatedV3Attributes;
  CoreCommentAuthorNameBlock: CoreCommentAuthorNameBlock;
  CoreCommentAuthorNameBlockAttributes: CoreCommentAuthorNameBlockAttributes;
  CoreCommentAuthorNameBlockDeprecatedV1Attributes: CoreCommentAuthorNameBlockDeprecatedV1Attributes;
  CoreCommentContentBlock: CoreCommentContentBlock;
  CoreCommentContentBlockAttributes: CoreCommentContentBlockAttributes;
  CoreCommentDateBlock: CoreCommentDateBlock;
  CoreCommentDateBlockAttributes: CoreCommentDateBlockAttributes;
  CoreCommentDateBlockDeprecatedV1Attributes: CoreCommentDateBlockDeprecatedV1Attributes;
  CoreCommentEditLinkBlock: CoreCommentEditLinkBlock;
  CoreCommentEditLinkBlockAttributes: CoreCommentEditLinkBlockAttributes;
  CoreCommentReplyLinkBlock: CoreCommentReplyLinkBlock;
  CoreCommentReplyLinkBlockAttributes: CoreCommentReplyLinkBlockAttributes;
  CoreCommentTemplateBlock: CoreCommentTemplateBlock;
  CoreCommentTemplateBlockAttributes: CoreCommentTemplateBlockAttributes;
  CoreCommentsBlock: CoreCommentsBlock;
  CoreCommentsBlockAttributes: CoreCommentsBlockAttributes;
  CoreCommentsBlockDeprecatedV1Attributes: CoreCommentsBlockDeprecatedV1Attributes;
  CoreCommentsPaginationBlock: CoreCommentsPaginationBlock;
  CoreCommentsPaginationBlockAttributes: CoreCommentsPaginationBlockAttributes;
  CoreCommentsPaginationNextBlock: CoreCommentsPaginationNextBlock;
  CoreCommentsPaginationNextBlockAttributes: CoreCommentsPaginationNextBlockAttributes;
  CoreCommentsPaginationNumbersBlock: CoreCommentsPaginationNumbersBlock;
  CoreCommentsPaginationNumbersBlockAttributes: CoreCommentsPaginationNumbersBlockAttributes;
  CoreCommentsPaginationPreviousBlock: CoreCommentsPaginationPreviousBlock;
  CoreCommentsPaginationPreviousBlockAttributes: CoreCommentsPaginationPreviousBlockAttributes;
  CoreCommentsTitleBlock: CoreCommentsTitleBlock;
  CoreCommentsTitleBlockAttributes: CoreCommentsTitleBlockAttributes;
  CoreCommentsTitleBlockDeprecatedV1Attributes: CoreCommentsTitleBlockDeprecatedV1Attributes;
  CoreCoverBlock: CoreCoverBlock;
  CoreCoverBlockAttributes: CoreCoverBlockAttributes;
  CoreCoverBlockDeprecatedV10Attributes: CoreCoverBlockDeprecatedV10Attributes;
  CoreCoverBlockDeprecatedV11Attributes: CoreCoverBlockDeprecatedV11Attributes;
  CoreCoverBlockDeprecatedV1Attributes: CoreCoverBlockDeprecatedV1Attributes;
  CoreCoverBlockDeprecatedV2Attributes: CoreCoverBlockDeprecatedV2Attributes;
  CoreCoverBlockDeprecatedV3Attributes: CoreCoverBlockDeprecatedV3Attributes;
  CoreCoverBlockDeprecatedV4Attributes: CoreCoverBlockDeprecatedV4Attributes;
  CoreCoverBlockDeprecatedV5Attributes: CoreCoverBlockDeprecatedV5Attributes;
  CoreCoverBlockDeprecatedV6Attributes: CoreCoverBlockDeprecatedV6Attributes;
  CoreCoverBlockDeprecatedV7Attributes: CoreCoverBlockDeprecatedV7Attributes;
  CoreCoverBlockDeprecatedV8Attributes: CoreCoverBlockDeprecatedV8Attributes;
  CoreCoverBlockDeprecatedV9Attributes: CoreCoverBlockDeprecatedV9Attributes;
  CoreDetailsBlock: CoreDetailsBlock;
  CoreDetailsBlockAttributes: CoreDetailsBlockAttributes;
  CoreEmbedBlock: CoreEmbedBlock;
  CoreEmbedBlockAttributes: CoreEmbedBlockAttributes;
  CoreEmbedBlockDeprecatedV1Attributes: CoreEmbedBlockDeprecatedV1Attributes;
  CoreEmbedBlockDeprecatedV2Attributes: CoreEmbedBlockDeprecatedV2Attributes;
  CoreFileBlock: CoreFileBlock;
  CoreFileBlockAttributes: CoreFileBlockAttributes;
  CoreFileBlockDeprecatedV1Attributes: CoreFileBlockDeprecatedV1Attributes;
  CoreFileBlockDeprecatedV2Attributes: CoreFileBlockDeprecatedV2Attributes;
  CoreFileBlockDeprecatedV3Attributes: CoreFileBlockDeprecatedV3Attributes;
  CoreFootnotesBlock: CoreFootnotesBlock;
  CoreFootnotesBlockAttributes: CoreFootnotesBlockAttributes;
  CoreFreeformBlock: CoreFreeformBlock;
  CoreFreeformBlockAttributes: CoreFreeformBlockAttributes;
  CoreGalleryBlock: CoreGalleryBlock;
  CoreGalleryBlockAttributes: CoreGalleryBlockAttributes;
  CoreGalleryBlockAttributesImages: CoreGalleryBlockAttributesImages;
  CoreGalleryBlockDeprecatedV1Attributes: CoreGalleryBlockDeprecatedV1Attributes;
  CoreGalleryBlockDeprecatedV1AttributesImages: CoreGalleryBlockDeprecatedV1AttributesImages;
  CoreGalleryBlockDeprecatedV2Attributes: CoreGalleryBlockDeprecatedV2Attributes;
  CoreGalleryBlockDeprecatedV2AttributesImages: CoreGalleryBlockDeprecatedV2AttributesImages;
  CoreGalleryBlockDeprecatedV3Attributes: CoreGalleryBlockDeprecatedV3Attributes;
  CoreGalleryBlockDeprecatedV3AttributesImages: CoreGalleryBlockDeprecatedV3AttributesImages;
  CoreGalleryBlockDeprecatedV4Attributes: CoreGalleryBlockDeprecatedV4Attributes;
  CoreGalleryBlockDeprecatedV4AttributesImages: CoreGalleryBlockDeprecatedV4AttributesImages;
  CoreGalleryBlockDeprecatedV5Attributes: CoreGalleryBlockDeprecatedV5Attributes;
  CoreGalleryBlockDeprecatedV5AttributesImages: CoreGalleryBlockDeprecatedV5AttributesImages;
  CoreGalleryBlockDeprecatedV6Attributes: CoreGalleryBlockDeprecatedV6Attributes;
  CoreGalleryBlockDeprecatedV6AttributesImages: CoreGalleryBlockDeprecatedV6AttributesImages;
  CoreGalleryBlockDeprecatedV7Attributes: CoreGalleryBlockDeprecatedV7Attributes;
  CoreGalleryBlockDeprecatedV7AttributesImages: CoreGalleryBlockDeprecatedV7AttributesImages;
  CoreGroupBlock: CoreGroupBlock;
  CoreGroupBlockAttributes: CoreGroupBlockAttributes;
  CoreGroupBlockDeprecatedV1Attributes: CoreGroupBlockDeprecatedV1Attributes;
  CoreGroupBlockDeprecatedV2Attributes: CoreGroupBlockDeprecatedV2Attributes;
  CoreGroupBlockDeprecatedV3Attributes: CoreGroupBlockDeprecatedV3Attributes;
  CoreGroupBlockDeprecatedV4Attributes: CoreGroupBlockDeprecatedV4Attributes;
  CoreGroupBlockDeprecatedV5Attributes: CoreGroupBlockDeprecatedV5Attributes;
  CoreHeadingBlock: CoreHeadingBlock;
  CoreHeadingBlockAttributes: CoreHeadingBlockAttributes;
  CoreHeadingBlockDeprecatedV1Attributes: CoreHeadingBlockDeprecatedV1Attributes;
  CoreHeadingBlockDeprecatedV2Attributes: CoreHeadingBlockDeprecatedV2Attributes;
  CoreHeadingBlockDeprecatedV3Attributes: CoreHeadingBlockDeprecatedV3Attributes;
  CoreHeadingBlockDeprecatedV4Attributes: CoreHeadingBlockDeprecatedV4Attributes;
  CoreHeadingBlockDeprecatedV5Attributes: CoreHeadingBlockDeprecatedV5Attributes;
  CoreHomeLinkBlock: CoreHomeLinkBlock;
  CoreHomeLinkBlockAttributes: CoreHomeLinkBlockAttributes;
  CoreHtmlBlock: CoreHtmlBlock;
  CoreHtmlBlockAttributes: CoreHtmlBlockAttributes;
  CoreImageBlock: CoreImageBlock;
  CoreImageBlockAttributes: CoreImageBlockAttributes;
  CoreImageBlockDeprecatedV1Attributes: CoreImageBlockDeprecatedV1Attributes;
  CoreImageBlockDeprecatedV2Attributes: CoreImageBlockDeprecatedV2Attributes;
  CoreImageBlockDeprecatedV3Attributes: CoreImageBlockDeprecatedV3Attributes;
  CoreImageBlockDeprecatedV4Attributes: CoreImageBlockDeprecatedV4Attributes;
  CoreImageBlockDeprecatedV5Attributes: CoreImageBlockDeprecatedV5Attributes;
  CoreImageBlockDeprecatedV6Attributes: CoreImageBlockDeprecatedV6Attributes;
  CoreImageBlockToMediaItemConnectionEdge: CoreImageBlockToMediaItemConnectionEdge;
  CoreLatestCommentsBlock: CoreLatestCommentsBlock;
  CoreLatestCommentsBlockAttributes: CoreLatestCommentsBlockAttributes;
  CoreLatestPostsBlock: CoreLatestPostsBlock;
  CoreLatestPostsBlockAttributes: CoreLatestPostsBlockAttributes;
  CoreLatestPostsBlockDeprecatedV1Attributes: CoreLatestPostsBlockDeprecatedV1Attributes;
  CoreLegacyWidgetBlock: CoreLegacyWidgetBlock;
  CoreLegacyWidgetBlockAttributes: CoreLegacyWidgetBlockAttributes;
  CoreListBlock: CoreListBlock;
  CoreListBlockAttributes: CoreListBlockAttributes;
  CoreListBlockDeprecatedV1Attributes: CoreListBlockDeprecatedV1Attributes;
  CoreListBlockDeprecatedV2Attributes: CoreListBlockDeprecatedV2Attributes;
  CoreListItemBlock: CoreListItemBlock;
  CoreListItemBlockAttributes: CoreListItemBlockAttributes;
  CoreLoginoutBlock: CoreLoginoutBlock;
  CoreLoginoutBlockAttributes: CoreLoginoutBlockAttributes;
  CoreMediaTextBlock: CoreMediaTextBlock;
  CoreMediaTextBlockAttributes: CoreMediaTextBlockAttributes;
  CoreMediaTextBlockDeprecatedV1Attributes: CoreMediaTextBlockDeprecatedV1Attributes;
  CoreMediaTextBlockDeprecatedV2Attributes: CoreMediaTextBlockDeprecatedV2Attributes;
  CoreMediaTextBlockDeprecatedV3Attributes: CoreMediaTextBlockDeprecatedV3Attributes;
  CoreMediaTextBlockDeprecatedV4Attributes: CoreMediaTextBlockDeprecatedV4Attributes;
  CoreMediaTextBlockDeprecatedV5Attributes: CoreMediaTextBlockDeprecatedV5Attributes;
  CoreMediaTextBlockDeprecatedV6Attributes: CoreMediaTextBlockDeprecatedV6Attributes;
  CoreMissingBlock: CoreMissingBlock;
  CoreMissingBlockAttributes: CoreMissingBlockAttributes;
  CoreMoreBlock: CoreMoreBlock;
  CoreMoreBlockAttributes: CoreMoreBlockAttributes;
  CoreNavigationBlock: CoreNavigationBlock;
  CoreNavigationBlockAttributes: CoreNavigationBlockAttributes;
  CoreNavigationBlockDeprecatedV1Attributes: CoreNavigationBlockDeprecatedV1Attributes;
  CoreNavigationBlockDeprecatedV2Attributes: CoreNavigationBlockDeprecatedV2Attributes;
  CoreNavigationBlockDeprecatedV3Attributes: CoreNavigationBlockDeprecatedV3Attributes;
  CoreNavigationBlockDeprecatedV4Attributes: CoreNavigationBlockDeprecatedV4Attributes;
  CoreNavigationBlockDeprecatedV5Attributes: CoreNavigationBlockDeprecatedV5Attributes;
  CoreNavigationBlockDeprecatedV6Attributes: CoreNavigationBlockDeprecatedV6Attributes;
  CoreNavigationLinkBlock: CoreNavigationLinkBlock;
  CoreNavigationLinkBlockAttributes: CoreNavigationLinkBlockAttributes;
  CoreNavigationLinkBlockDeprecatedV1Attributes: CoreNavigationLinkBlockDeprecatedV1Attributes;
  CoreNavigationSubmenuBlock: CoreNavigationSubmenuBlock;
  CoreNavigationSubmenuBlockAttributes: CoreNavigationSubmenuBlockAttributes;
  CoreNextpageBlock: CoreNextpageBlock;
  CoreNextpageBlockAttributes: CoreNextpageBlockAttributes;
  CorePageListBlock: CorePageListBlock;
  CorePageListBlockAttributes: CorePageListBlockAttributes;
  CorePageListItemBlock: CorePageListItemBlock;
  CorePageListItemBlockAttributes: CorePageListItemBlockAttributes;
  CoreParagraphBlock: CoreParagraphBlock;
  CoreParagraphBlockAttributes: CoreParagraphBlockAttributes;
  CoreParagraphBlockDeprecatedV1Attributes: CoreParagraphBlockDeprecatedV1Attributes;
  CoreParagraphBlockDeprecatedV2Attributes: CoreParagraphBlockDeprecatedV2Attributes;
  CoreParagraphBlockDeprecatedV3Attributes: CoreParagraphBlockDeprecatedV3Attributes;
  CoreParagraphBlockDeprecatedV4Attributes: CoreParagraphBlockDeprecatedV4Attributes;
  CoreParagraphBlockDeprecatedV5Attributes: CoreParagraphBlockDeprecatedV5Attributes;
  CoreParagraphBlockDeprecatedV6Attributes: CoreParagraphBlockDeprecatedV6Attributes;
  CorePatternBlock: CorePatternBlock;
  CorePatternBlockAttributes: CorePatternBlockAttributes;
  CorePostAuthorBiographyBlock: CorePostAuthorBiographyBlock;
  CorePostAuthorBiographyBlockAttributes: CorePostAuthorBiographyBlockAttributes;
  CorePostAuthorBlock: CorePostAuthorBlock;
  CorePostAuthorBlockAttributes: CorePostAuthorBlockAttributes;
  CorePostAuthorNameBlock: CorePostAuthorNameBlock;
  CorePostAuthorNameBlockAttributes: CorePostAuthorNameBlockAttributes;
  CorePostCommentsFormBlock: CorePostCommentsFormBlock;
  CorePostCommentsFormBlockAttributes: CorePostCommentsFormBlockAttributes;
  CorePostContentBlock: CorePostContentBlock;
  CorePostContentBlockAttributes: CorePostContentBlockAttributes;
  CorePostDateBlock: CorePostDateBlock;
  CorePostDateBlockAttributes: CorePostDateBlockAttributes;
  CorePostDateBlockDeprecatedV1Attributes: CorePostDateBlockDeprecatedV1Attributes;
  CorePostExcerptBlock: CorePostExcerptBlock;
  CorePostExcerptBlockAttributes: CorePostExcerptBlockAttributes;
  CorePostFeaturedImageBlock: CorePostFeaturedImageBlock;
  CorePostFeaturedImageBlockAttributes: CorePostFeaturedImageBlockAttributes;
  CorePostNavigationLinkBlock: CorePostNavigationLinkBlock;
  CorePostNavigationLinkBlockAttributes: CorePostNavigationLinkBlockAttributes;
  CorePostTemplateBlock: CorePostTemplateBlock;
  CorePostTemplateBlockAttributes: CorePostTemplateBlockAttributes;
  CorePostTermsBlock: CorePostTermsBlock;
  CorePostTermsBlockAttributes: CorePostTermsBlockAttributes;
  CorePostTitleBlock: CorePostTitleBlock;
  CorePostTitleBlockAttributes: CorePostTitleBlockAttributes;
  CorePostTitleBlockDeprecatedV1Attributes: CorePostTitleBlockDeprecatedV1Attributes;
  CorePreformattedBlock: CorePreformattedBlock;
  CorePreformattedBlockAttributes: CorePreformattedBlockAttributes;
  CorePullquoteBlock: CorePullquoteBlock;
  CorePullquoteBlockAttributes: CorePullquoteBlockAttributes;
  CorePullquoteBlockDeprecatedV1Attributes: CorePullquoteBlockDeprecatedV1Attributes;
  CorePullquoteBlockDeprecatedV2Attributes: CorePullquoteBlockDeprecatedV2Attributes;
  CorePullquoteBlockDeprecatedV3Attributes: CorePullquoteBlockDeprecatedV3Attributes;
  CorePullquoteBlockDeprecatedV4Attributes: CorePullquoteBlockDeprecatedV4Attributes;
  CorePullquoteBlockDeprecatedV5Attributes: CorePullquoteBlockDeprecatedV5Attributes;
  CorePullquoteBlockDeprecatedV6Attributes: CorePullquoteBlockDeprecatedV6Attributes;
  CoreQueryBlock: CoreQueryBlock;
  CoreQueryBlockAttributes: CoreQueryBlockAttributes;
  CoreQueryBlockDeprecatedV1Attributes: CoreQueryBlockDeprecatedV1Attributes;
  CoreQueryBlockDeprecatedV2Attributes: CoreQueryBlockDeprecatedV2Attributes;
  CoreQueryBlockDeprecatedV3Attributes: CoreQueryBlockDeprecatedV3Attributes;
  CoreQueryBlockDeprecatedV4Attributes: CoreQueryBlockDeprecatedV4Attributes;
  CoreQueryBlockDeprecatedV5Attributes: CoreQueryBlockDeprecatedV5Attributes;
  CoreQueryNoResultsBlock: CoreQueryNoResultsBlock;
  CoreQueryNoResultsBlockAttributes: CoreQueryNoResultsBlockAttributes;
  CoreQueryPaginationBlock: CoreQueryPaginationBlock;
  CoreQueryPaginationBlockAttributes: CoreQueryPaginationBlockAttributes;
  CoreQueryPaginationBlockDeprecatedV1Attributes: CoreQueryPaginationBlockDeprecatedV1Attributes;
  CoreQueryPaginationNextBlock: CoreQueryPaginationNextBlock;
  CoreQueryPaginationNextBlockAttributes: CoreQueryPaginationNextBlockAttributes;
  CoreQueryPaginationNumbersBlock: CoreQueryPaginationNumbersBlock;
  CoreQueryPaginationNumbersBlockAttributes: CoreQueryPaginationNumbersBlockAttributes;
  CoreQueryPaginationPreviousBlock: CoreQueryPaginationPreviousBlock;
  CoreQueryPaginationPreviousBlockAttributes: CoreQueryPaginationPreviousBlockAttributes;
  CoreQueryTitleBlock: CoreQueryTitleBlock;
  CoreQueryTitleBlockAttributes: CoreQueryTitleBlockAttributes;
  CoreQueryTitleBlockDeprecatedV1Attributes: CoreQueryTitleBlockDeprecatedV1Attributes;
  CoreQuoteBlock: CoreQuoteBlock;
  CoreQuoteBlockAttributes: CoreQuoteBlockAttributes;
  CoreQuoteBlockDeprecatedV1Attributes: CoreQuoteBlockDeprecatedV1Attributes;
  CoreQuoteBlockDeprecatedV2Attributes: CoreQuoteBlockDeprecatedV2Attributes;
  CoreQuoteBlockDeprecatedV3Attributes: CoreQuoteBlockDeprecatedV3Attributes;
  CoreQuoteBlockDeprecatedV4Attributes: CoreQuoteBlockDeprecatedV4Attributes;
  CoreReadMoreBlock: CoreReadMoreBlock;
  CoreReadMoreBlockAttributes: CoreReadMoreBlockAttributes;
  CoreRssBlock: CoreRssBlock;
  CoreRssBlockAttributes: CoreRssBlockAttributes;
  CoreSearchBlock: CoreSearchBlock;
  CoreSearchBlockAttributes: CoreSearchBlockAttributes;
  CoreSeparatorBlock: CoreSeparatorBlock;
  CoreSeparatorBlockAttributes: CoreSeparatorBlockAttributes;
  CoreSeparatorBlockDeprecatedV1Attributes: CoreSeparatorBlockDeprecatedV1Attributes;
  CoreShortcodeBlock: CoreShortcodeBlock;
  CoreShortcodeBlockAttributes: CoreShortcodeBlockAttributes;
  CoreSiteLogoBlock: CoreSiteLogoBlock;
  CoreSiteLogoBlockAttributes: CoreSiteLogoBlockAttributes;
  CoreSiteTaglineBlock: CoreSiteTaglineBlock;
  CoreSiteTaglineBlockAttributes: CoreSiteTaglineBlockAttributes;
  CoreSiteTaglineBlockDeprecatedV1Attributes: CoreSiteTaglineBlockDeprecatedV1Attributes;
  CoreSiteTitleBlock: CoreSiteTitleBlock;
  CoreSiteTitleBlockAttributes: CoreSiteTitleBlockAttributes;
  CoreSiteTitleBlockDeprecatedV1Attributes: CoreSiteTitleBlockDeprecatedV1Attributes;
  CoreSocialLinkBlock: CoreSocialLinkBlock;
  CoreSocialLinkBlockAttributes: CoreSocialLinkBlockAttributes;
  CoreSocialLinksBlock: CoreSocialLinksBlock;
  CoreSocialLinksBlockAttributes: CoreSocialLinksBlockAttributes;
  CoreSocialLinksBlockDeprecatedV1Attributes: CoreSocialLinksBlockDeprecatedV1Attributes;
  CoreSpacerBlock: CoreSpacerBlock;
  CoreSpacerBlockAttributes: CoreSpacerBlockAttributes;
  CoreSpacerBlockDeprecatedV1Attributes: CoreSpacerBlockDeprecatedV1Attributes;
  CoreTableBlock: CoreTableBlock;
  CoreTableBlockAttributes: CoreTableBlockAttributes;
  CoreTableBlockAttributesBody: CoreTableBlockAttributesBody;
  CoreTableBlockAttributesBodyCells: CoreTableBlockAttributesBodyCells;
  CoreTableBlockAttributesFoot: CoreTableBlockAttributesFoot;
  CoreTableBlockAttributesFootCells: CoreTableBlockAttributesFootCells;
  CoreTableBlockAttributesHead: CoreTableBlockAttributesHead;
  CoreTableBlockAttributesHeadCells: CoreTableBlockAttributesHeadCells;
  CoreTableBlockDeprecatedV1Attributes: CoreTableBlockDeprecatedV1Attributes;
  CoreTableBlockDeprecatedV1AttributesBody: CoreTableBlockDeprecatedV1AttributesBody;
  CoreTableBlockDeprecatedV1AttributesBodyCells: CoreTableBlockDeprecatedV1AttributesBodyCells;
  CoreTableBlockDeprecatedV1AttributesFoot: CoreTableBlockDeprecatedV1AttributesFoot;
  CoreTableBlockDeprecatedV1AttributesFootCells: CoreTableBlockDeprecatedV1AttributesFootCells;
  CoreTableBlockDeprecatedV1AttributesHead: CoreTableBlockDeprecatedV1AttributesHead;
  CoreTableBlockDeprecatedV1AttributesHeadCells: CoreTableBlockDeprecatedV1AttributesHeadCells;
  CoreTableBlockDeprecatedV2Attributes: CoreTableBlockDeprecatedV2Attributes;
  CoreTableBlockDeprecatedV2AttributesBody: CoreTableBlockDeprecatedV2AttributesBody;
  CoreTableBlockDeprecatedV2AttributesBodyCells: CoreTableBlockDeprecatedV2AttributesBodyCells;
  CoreTableBlockDeprecatedV2AttributesFoot: CoreTableBlockDeprecatedV2AttributesFoot;
  CoreTableBlockDeprecatedV2AttributesFootCells: CoreTableBlockDeprecatedV2AttributesFootCells;
  CoreTableBlockDeprecatedV2AttributesHead: CoreTableBlockDeprecatedV2AttributesHead;
  CoreTableBlockDeprecatedV2AttributesHeadCells: CoreTableBlockDeprecatedV2AttributesHeadCells;
  CoreTableBlockDeprecatedV3Attributes: CoreTableBlockDeprecatedV3Attributes;
  CoreTableBlockDeprecatedV3AttributesBody: CoreTableBlockDeprecatedV3AttributesBody;
  CoreTableBlockDeprecatedV3AttributesBodyCells: CoreTableBlockDeprecatedV3AttributesBodyCells;
  CoreTableBlockDeprecatedV3AttributesFoot: CoreTableBlockDeprecatedV3AttributesFoot;
  CoreTableBlockDeprecatedV3AttributesFootCells: CoreTableBlockDeprecatedV3AttributesFootCells;
  CoreTableBlockDeprecatedV3AttributesHead: CoreTableBlockDeprecatedV3AttributesHead;
  CoreTableBlockDeprecatedV3AttributesHeadCells: CoreTableBlockDeprecatedV3AttributesHeadCells;
  CoreTagCloudBlock: CoreTagCloudBlock;
  CoreTagCloudBlockAttributes: CoreTagCloudBlockAttributes;
  CoreTemplatePartBlock: CoreTemplatePartBlock;
  CoreTemplatePartBlockAttributes: CoreTemplatePartBlockAttributes;
  CoreTermDescriptionBlock: CoreTermDescriptionBlock;
  CoreTermDescriptionBlockAttributes: CoreTermDescriptionBlockAttributes;
  CoreTextColumnsBlock: CoreTextColumnsBlock;
  CoreTextColumnsBlockAttributes: CoreTextColumnsBlockAttributes;
  CoreTextColumnsBlockAttributesContent: CoreTextColumnsBlockAttributesContent;
  CoreVerseBlock: CoreVerseBlock;
  CoreVerseBlockAttributes: CoreVerseBlockAttributes;
  CoreVerseBlockDeprecatedV1Attributes: CoreVerseBlockDeprecatedV1Attributes;
  CoreVerseBlockDeprecatedV2Attributes: CoreVerseBlockDeprecatedV2Attributes;
  CoreVideoBlock: CoreVideoBlock;
  CoreVideoBlockAttributes: CoreVideoBlockAttributes;
  CoreVideoBlockDeprecatedV1Attributes: CoreVideoBlockDeprecatedV1Attributes;
  CoreWidgetGroupBlock: CoreWidgetGroupBlock;
  CoreWidgetGroupBlockAttributes: CoreWidgetGroupBlockAttributes;
  CoreWidgetGroupBlockDeprecatedV1Attributes: CoreWidgetGroupBlockDeprecatedV1Attributes;
  CreateCategoryPayload: CreateCategoryPayload;
  CreateCommentPayload: CreateCommentPayload;
  CreateJobPayload: CreateJobPayload;
  CreateMediaItemPayload: CreateMediaItemPayload;
  CreatePagePayload: CreatePagePayload;
  CreatePostFormatPayload: CreatePostFormatPayload;
  CreatePostPayload: CreatePostPayload;
  CreateReusableBlockPayload: CreateReusableBlockPayload;
  CreateServicePayload: CreateServicePayload;
  CreateSpecialtyPayload: CreateSpecialtyPayload;
  CreateTagPayload: CreateTagPayload;
  CreateTeam_memberPayload: CreateTeam_memberPayload;
  CreateUserPayload: CreateUserPayload;
  CreateWorkPayload: CreateWorkPayload;
  DateField: DateField;
  DateInputProperty: DateInputProperty;
  DefaultTemplate: DefaultTemplate;
  DeleteCategoryPayload: DeleteCategoryPayload;
  DeleteCommentPayload: DeleteCommentPayload;
  DeleteGfDraftEntryPayload: DeleteGfDraftEntryPayload;
  DeleteGfEntryPayload: DeleteGfEntryPayload;
  DeleteJobPayload: DeleteJobPayload;
  DeleteMediaItemPayload: DeleteMediaItemPayload;
  DeletePagePayload: DeletePagePayload;
  DeletePostFormatPayload: DeletePostFormatPayload;
  DeletePostPayload: DeletePostPayload;
  DeleteReusableBlockPayload: DeleteReusableBlockPayload;
  DeleteServicePayload: DeleteServicePayload;
  DeleteSpecialtyPayload: DeleteSpecialtyPayload;
  DeleteTagPayload: DeleteTagPayload;
  DeleteTeam_memberPayload: DeleteTeam_memberPayload;
  DeleteUserPayload: DeleteUserPayload;
  DeleteWorkPayload: DeleteWorkPayload;
  DiscussionSettings: DiscussionSettings;
  EmailField: EmailField;
  EmailInputProperty: EmailInputProperty;
  EnqueuedScript: EnqueuedScript;
  EnqueuedStylesheet: EnqueuedStylesheet;
  FieldError: FieldError;
  FileUploadField: FileUploadField;
  FileUploadFieldValue: FileUploadFieldValue;
  FormButton: FormButton;
  FormConfirmation: FormConfirmation;
  FormDataPolicies: FormDataPolicies;
  FormEntryDataPolicy: FormEntryDataPolicy;
  FormEntryLimits: FormEntryLimits;
  FormFieldDataPolicy: FormFieldDataPolicy;
  FormLastPageButton: FormLastPageButton;
  FormLogin: FormLogin;
  FormNotification: FormNotification;
  FormNotificationRouting: FormNotificationRouting;
  FormPagination: FormPagination;
  FormPersonalData: FormPersonalData;
  FormPostCreation: FormPostCreation;
  FormSaveAndContinue: FormSaveAndContinue;
  FormSchedule: FormSchedule;
  FormScheduleDetails: FormScheduleDetails;
  FormSubmitButton: FormSubmitButton;
  GeneralOptionsSettings: GeneralOptionsSettings;
  GeneralOptionsSettings_Generalsettings: GeneralOptionsSettings_Generalsettings;
  GeneralSettings: GeneralSettings;
  GenerateAuthorizationCodePayload: GenerateAuthorizationCodePayload;
  GenesisCustomBlocksHeroBlock: GenesisCustomBlocksHeroBlock;
  GenesisCustomBlocksHeroBlockAttributes: GenesisCustomBlocksHeroBlockAttributes;
  GfDraftEntry: GfDraftEntry;
  GfEntryToFormFieldConnection: GfEntryToFormFieldConnection;
  GfEntryToFormFieldConnectionEdge: GfEntryToFormFieldConnectionEdge;
  GfForm: GfForm;
  GfFormToFormFieldConnection: GfFormToFormFieldConnection;
  GfFormToFormFieldConnectionEdge: GfFormToFormFieldConnectionEdge;
  GfFormToGfEntryConnection: GfFormToGfEntryConnection;
  GfFormToGfEntryConnectionEdge: GfFormToGfEntryConnectionEdge;
  GfLogger: GfLogger;
  GfSettings: GfSettings;
  GfSettingsLogging: GfSettingsLogging;
  GfSubmittedEntry: GfSubmittedEntry;
  GravityformsFormBlock: GravityformsFormBlock;
  GravityformsFormBlockAttributes: GravityformsFormBlockAttributes;
  HiddenField: HiddenField;
  HierarchicalContentNodeToContentNodeAncestorsConnection: HierarchicalContentNodeToContentNodeAncestorsConnection;
  HierarchicalContentNodeToContentNodeAncestorsConnectionEdge: HierarchicalContentNodeToContentNodeAncestorsConnectionEdge;
  HierarchicalContentNodeToContentNodeChildrenConnection: HierarchicalContentNodeToContentNodeChildrenConnection;
  HierarchicalContentNodeToContentNodeChildrenConnectionEdge: HierarchicalContentNodeToContentNodeChildrenConnectionEdge;
  HierarchicalContentNodeToParentContentNodeConnectionEdge: HierarchicalContentNodeToParentContentNodeConnectionEdge;
  HtmlField: HtmlField;
  IdeaboxTocBlock: IdeaboxTocBlock;
  IdeaboxTocBlockAttributes: IdeaboxTocBlockAttributes;
  ImageFieldValue: ImageFieldValue;
  Job: Job;
  JobToPreviewConnectionEdge: JobToPreviewConnectionEdge;
  Job_Jobacf: Job_Jobacf;
  ListField: ListField;
  ListFieldChoice: ListFieldChoice;
  ListFieldValue: ListFieldValue;
  MediaDetails: MediaDetails;
  MediaItem: MediaItem;
  MediaItemMeta: MediaItemMeta;
  MediaItemToCommentConnection: MediaItemToCommentConnection;
  MediaItemToCommentConnectionEdge: MediaItemToCommentConnectionEdge;
  MediaSize: MediaSize;
  Menu: Menu;
  MenuItem: MenuItem;
  MenuItemToMenuConnectionEdge: MenuItemToMenuConnectionEdge;
  MenuItemToMenuItemConnection: MenuItemToMenuItemConnection;
  MenuItemToMenuItemConnectionEdge: MenuItemToMenuItemConnectionEdge;
  MenuItemToMenuItemLinkableConnectionEdge: MenuItemToMenuItemLinkableConnectionEdge;
  MenuToMenuItemConnection: MenuToMenuItemConnection;
  MenuToMenuItemConnectionEdge: MenuToMenuItemConnectionEdge;
  MultiSelectField: MultiSelectField;
  MultiSelectFieldChoice: MultiSelectFieldChoice;
  Mutation: Mutation;
  NameField: NameField;
  NameFieldChoice: NameFieldChoice;
  NameFieldValue: NameFieldValue;
  NameInputProperty: NameInputProperty;
  NodeWithAuthorToUserConnectionEdge: NodeWithAuthorToUserConnectionEdge;
  NodeWithFeaturedImageToMediaItemConnectionEdge: NodeWithFeaturedImageToMediaItemConnectionEdge;
  NodeWithRevisionsToContentNodeConnectionEdge: NodeWithRevisionsToContentNodeConnectionEdge;
  NumberField: NumberField;
  Page: Page;
  PageField: PageField;
  PageToCommentConnection: PageToCommentConnection;
  PageToCommentConnectionEdge: PageToCommentConnectionEdge;
  PageToPreviewConnectionEdge: PageToPreviewConnectionEdge;
  PageToRevisionConnection: PageToRevisionConnection;
  PageToRevisionConnectionEdge: PageToRevisionConnectionEdge;
  Page_Aboutcta: Page_Aboutcta;
  Page_Aboutcta_bullets: Page_Aboutcta_bullets;
  Page_Contenthero: Page_Contenthero;
  Page_Features: Page_Features;
  Page_Features_features: Page_Features_features;
  Page_Features_features_bullettPoints: Page_Features_features_bullettPoints;
  PasswordField: PasswordField;
  PasswordInputProperty: PasswordInputProperty;
  PhoneField: PhoneField;
  Plugin: Plugin;
  Post: Post;
  PostCategoryCheckboxField: PostCategoryCheckboxField;
  PostCategoryFieldChoice: PostCategoryFieldChoice;
  PostCategoryInputProperty: PostCategoryInputProperty;
  PostCategoryMultiSelectField: PostCategoryMultiSelectField;
  PostCategoryRadioField: PostCategoryRadioField;
  PostCategorySelectField: PostCategorySelectField;
  PostContentField: PostContentField;
  PostCustomCheckboxField: PostCustomCheckboxField;
  PostCustomCheckboxFieldChoice: PostCustomCheckboxFieldChoice;
  PostCustomDateField: PostCustomDateField;
  PostCustomEmailField: PostCustomEmailField;
  PostCustomFileuploadField: PostCustomFileuploadField;
  PostCustomHiddenField: PostCustomHiddenField;
  PostCustomInputProperty: PostCustomInputProperty;
  PostCustomListField: PostCustomListField;
  PostCustomListFieldChoice: PostCustomListFieldChoice;
  PostCustomMultiSelectField: PostCustomMultiSelectField;
  PostCustomMultiSelectFieldChoice: PostCustomMultiSelectFieldChoice;
  PostCustomNumberField: PostCustomNumberField;
  PostCustomPhoneField: PostCustomPhoneField;
  PostCustomRadioField: PostCustomRadioField;
  PostCustomRadioFieldChoice: PostCustomRadioFieldChoice;
  PostCustomSelectField: PostCustomSelectField;
  PostCustomSelectFieldChoice: PostCustomSelectFieldChoice;
  PostCustomTextAreaField: PostCustomTextAreaField;
  PostCustomTextField: PostCustomTextField;
  PostCustomTimeField: PostCustomTimeField;
  PostCustomWebsiteField: PostCustomWebsiteField;
  PostExcerptField: PostExcerptField;
  PostFormat: PostFormat;
  PostFormatToContentNodeConnection: PostFormatToContentNodeConnection;
  PostFormatToContentNodeConnectionEdge: PostFormatToContentNodeConnectionEdge;
  PostFormatToPostConnection: PostFormatToPostConnection;
  PostFormatToPostConnectionEdge: PostFormatToPostConnectionEdge;
  PostFormatToTaxonomyConnectionEdge: PostFormatToTaxonomyConnectionEdge;
  PostImageField: PostImageField;
  PostTagsCheckboxField: PostTagsCheckboxField;
  PostTagsCheckboxFieldChoice: PostTagsCheckboxFieldChoice;
  PostTagsInputProperty: PostTagsInputProperty;
  PostTagsMultiSelectField: PostTagsMultiSelectField;
  PostTagsMultiSelectFieldChoice: PostTagsMultiSelectFieldChoice;
  PostTagsRadioField: PostTagsRadioField;
  PostTagsRadioFieldChoice: PostTagsRadioFieldChoice;
  PostTagsSelectField: PostTagsSelectField;
  PostTagsSelectFieldChoice: PostTagsSelectFieldChoice;
  PostTagsTextField: PostTagsTextField;
  PostTitleField: PostTitleField;
  PostToCategoryConnection: PostToCategoryConnection;
  PostToCategoryConnectionEdge: PostToCategoryConnectionEdge;
  PostToCommentConnection: PostToCommentConnection;
  PostToCommentConnectionEdge: PostToCommentConnectionEdge;
  PostToPostFormatConnection: PostToPostFormatConnection;
  PostToPostFormatConnectionEdge: PostToPostFormatConnectionEdge;
  PostToPreviewConnectionEdge: PostToPreviewConnectionEdge;
  PostToRevisionConnection: PostToRevisionConnection;
  PostToRevisionConnectionEdge: PostToRevisionConnectionEdge;
  PostToTagConnection: PostToTagConnection;
  PostToTagConnectionEdge: PostToTagConnectionEdge;
  PostToTermNodeConnection: PostToTermNodeConnection;
  PostToTermNodeConnectionEdge: PostToTermNodeConnectionEdge;
  PostTypeLabelDetails: PostTypeLabelDetails;
  PostTypeSEO: PostTypeSEO;
  Post_Postsacf: Post_Postsacf;
  Query: Query;
  RadioField: RadioField;
  RadioFieldChoice: RadioFieldChoice;
  ReadingSettings: ReadingSettings;
  RegisterUserPayload: RegisterUserPayload;
  ResetUserPasswordPayload: ResetUserPasswordPayload;
  RestoreCommentPayload: RestoreCommentPayload;
  ReusableBlock: ReusableBlock;
  ReusableBlockToPreviewConnectionEdge: ReusableBlockToPreviewConnectionEdge;
  ReusableBlockToRevisionConnection: ReusableBlockToRevisionConnection;
  ReusableBlockToRevisionConnectionEdge: ReusableBlockToRevisionConnectionEdge;
  RootQueryToBlockEditorPreviewConnection: RootQueryToBlockEditorPreviewConnection;
  RootQueryToBlockEditorPreviewConnectionEdge: RootQueryToBlockEditorPreviewConnectionEdge;
  RootQueryToCategoryConnection: RootQueryToCategoryConnection;
  RootQueryToCategoryConnectionEdge: RootQueryToCategoryConnectionEdge;
  RootQueryToCommentConnection: RootQueryToCommentConnection;
  RootQueryToCommentConnectionEdge: RootQueryToCommentConnectionEdge;
  RootQueryToContentNodeConnection: RootQueryToContentNodeConnection;
  RootQueryToContentNodeConnectionEdge: RootQueryToContentNodeConnectionEdge;
  RootQueryToContentRevisionUnionConnection: RootQueryToContentRevisionUnionConnection;
  RootQueryToContentRevisionUnionConnectionEdge: RootQueryToContentRevisionUnionConnectionEdge;
  RootQueryToContentTypeConnection: RootQueryToContentTypeConnection;
  RootQueryToContentTypeConnectionEdge: RootQueryToContentTypeConnectionEdge;
  RootQueryToEnqueuedScriptConnection: RootQueryToEnqueuedScriptConnection;
  RootQueryToEnqueuedScriptConnectionEdge: RootQueryToEnqueuedScriptConnectionEdge;
  RootQueryToEnqueuedStylesheetConnection: RootQueryToEnqueuedStylesheetConnection;
  RootQueryToEnqueuedStylesheetConnectionEdge: RootQueryToEnqueuedStylesheetConnectionEdge;
  RootQueryToGfEntryConnection: RootQueryToGfEntryConnection;
  RootQueryToGfEntryConnectionEdge: RootQueryToGfEntryConnectionEdge;
  RootQueryToGfFormConnection: RootQueryToGfFormConnection;
  RootQueryToGfFormConnectionEdge: RootQueryToGfFormConnectionEdge;
  RootQueryToGfSubmittedEntryConnection: RootQueryToGfSubmittedEntryConnection;
  RootQueryToGfSubmittedEntryConnectionEdge: RootQueryToGfSubmittedEntryConnectionEdge;
  RootQueryToJobConnection: RootQueryToJobConnection;
  RootQueryToJobConnectionEdge: RootQueryToJobConnectionEdge;
  RootQueryToMediaItemConnection: RootQueryToMediaItemConnection;
  RootQueryToMediaItemConnectionEdge: RootQueryToMediaItemConnectionEdge;
  RootQueryToMenuConnection: RootQueryToMenuConnection;
  RootQueryToMenuConnectionEdge: RootQueryToMenuConnectionEdge;
  RootQueryToMenuItemConnection: RootQueryToMenuItemConnection;
  RootQueryToMenuItemConnectionEdge: RootQueryToMenuItemConnectionEdge;
  RootQueryToPageConnection: RootQueryToPageConnection;
  RootQueryToPageConnectionEdge: RootQueryToPageConnectionEdge;
  RootQueryToPluginConnection: RootQueryToPluginConnection;
  RootQueryToPluginConnectionEdge: RootQueryToPluginConnectionEdge;
  RootQueryToPostConnection: RootQueryToPostConnection;
  RootQueryToPostConnectionEdge: RootQueryToPostConnectionEdge;
  RootQueryToPostFormatConnection: RootQueryToPostFormatConnection;
  RootQueryToPostFormatConnectionEdge: RootQueryToPostFormatConnectionEdge;
  RootQueryToReusableBlockConnection: RootQueryToReusableBlockConnection;
  RootQueryToReusableBlockConnectionEdge: RootQueryToReusableBlockConnectionEdge;
  RootQueryToServiceConnection: RootQueryToServiceConnection;
  RootQueryToServiceConnectionEdge: RootQueryToServiceConnectionEdge;
  RootQueryToSpecialtyConnection: RootQueryToSpecialtyConnection;
  RootQueryToSpecialtyConnectionEdge: RootQueryToSpecialtyConnectionEdge;
  RootQueryToTagConnection: RootQueryToTagConnection;
  RootQueryToTagConnectionEdge: RootQueryToTagConnectionEdge;
  RootQueryToTaxonomyConnection: RootQueryToTaxonomyConnection;
  RootQueryToTaxonomyConnectionEdge: RootQueryToTaxonomyConnectionEdge;
  RootQueryToTeam_memberConnection: RootQueryToTeam_memberConnection;
  RootQueryToTeam_memberConnectionEdge: RootQueryToTeam_memberConnectionEdge;
  RootQueryToTermNodeConnection: RootQueryToTermNodeConnection;
  RootQueryToTermNodeConnectionEdge: RootQueryToTermNodeConnectionEdge;
  RootQueryToThemeConnection: RootQueryToThemeConnection;
  RootQueryToThemeConnectionEdge: RootQueryToThemeConnectionEdge;
  RootQueryToUserConnection: RootQueryToUserConnection;
  RootQueryToUserConnectionEdge: RootQueryToUserConnectionEdge;
  RootQueryToUserRoleConnection: RootQueryToUserRoleConnection;
  RootQueryToUserRoleConnectionEdge: RootQueryToUserRoleConnectionEdge;
  RootQueryToWorkConnection: RootQueryToWorkConnection;
  RootQueryToWorkConnectionEdge: RootQueryToWorkConnectionEdge;
  SEOBreadcrumbs: SEOBreadcrumbs;
  SEOConfig: SEOConfig;
  SEOContentType: SEOContentType;
  SEOContentTypeArchive: SEOContentTypeArchive;
  SEOContentTypes: SEOContentTypes;
  SEOOpenGraph: SEOOpenGraph;
  SEOOpenGraphFrontPage: SEOOpenGraphFrontPage;
  SEOPageInfoSchema: SEOPageInfoSchema;
  SEOPostTypeBreadcrumbs: SEOPostTypeBreadcrumbs;
  SEOPostTypePageInfo: SEOPostTypePageInfo;
  SEOPostTypeSchema: SEOPostTypeSchema;
  SEORedirect: SEORedirect;
  SEOSchema: SEOSchema;
  SEOSocial: SEOSocial;
  SEOSocialFacebook: SEOSocialFacebook;
  SEOSocialInstagram: SEOSocialInstagram;
  SEOSocialLinkedIn: SEOSocialLinkedIn;
  SEOSocialMySpace: SEOSocialMySpace;
  SEOSocialPinterest: SEOSocialPinterest;
  SEOSocialTwitter: SEOSocialTwitter;
  SEOSocialWikipedia: SEOSocialWikipedia;
  SEOSocialYoutube: SEOSocialYoutube;
  SEOTaxonomySchema: SEOTaxonomySchema;
  SEOUser: SEOUser;
  SEOUserSchema: SEOUserSchema;
  SEOUserSocial: SEOUserSocial;
  SEOWebmaster: SEOWebmaster;
  SectionField: SectionField;
  SelectField: SelectField;
  SelectFieldChoice: SelectFieldChoice;
  SendPasswordResetEmailPayload: SendPasswordResetEmailPayload;
  Service: Service;
  ServiceToPreviewConnectionEdge: ServiceToPreviewConnectionEdge;
  Settings: Settings;
  Specialty: Specialty;
  SpecialtyToPreviewConnectionEdge: SpecialtyToPreviewConnectionEdge;
  SubmitField: SubmitField;
  SubmitGfDraftEntryPayload: SubmitGfDraftEntryPayload;
  SubmitGfFormPayload: SubmitGfFormPayload;
  Subscription: Subscription;
  Tag: Tag;
  TagToContentNodeConnection: TagToContentNodeConnection;
  TagToContentNodeConnectionEdge: TagToContentNodeConnectionEdge;
  TagToPostConnection: TagToPostConnection;
  TagToPostConnectionEdge: TagToPostConnectionEdge;
  TagToTaxonomyConnectionEdge: TagToTaxonomyConnectionEdge;
  Taxonomy: Taxonomy;
  TaxonomySEO: TaxonomySEO;
  TaxonomyToContentTypeConnection: TaxonomyToContentTypeConnection;
  TaxonomyToContentTypeConnectionEdge: TaxonomyToContentTypeConnectionEdge;
  Team_member: Team_member;
  Team_memberToPreviewConnectionEdge: Team_memberToPreviewConnectionEdge;
  Team_member_Team: Team_member_Team;
  Template_About: Template_About;
  Template_Blog: Template_Blog;
  Template_Contact: Template_Contact;
  Template_Home: Template_Home;
  Template_Work: Template_Work;
  TermNodeToEnqueuedScriptConnection: TermNodeToEnqueuedScriptConnection;
  TermNodeToEnqueuedScriptConnectionEdge: TermNodeToEnqueuedScriptConnectionEdge;
  TermNodeToEnqueuedStylesheetConnection: TermNodeToEnqueuedStylesheetConnection;
  TermNodeToEnqueuedStylesheetConnectionEdge: TermNodeToEnqueuedStylesheetConnectionEdge;
  TextAreaField: TextAreaField;
  TextField: TextField;
  Theme: Theme;
  TimeField: TimeField;
  TimeFieldValue: TimeFieldValue;
  TimeInputProperty: TimeInputProperty;
  UpdateCategoryPayload: UpdateCategoryPayload;
  UpdateCommentPayload: UpdateCommentPayload;
  UpdateGfDraftEntryPayload: UpdateGfDraftEntryPayload;
  UpdateGfEntryPayload: UpdateGfEntryPayload;
  UpdateJobPayload: UpdateJobPayload;
  UpdateMediaItemPayload: UpdateMediaItemPayload;
  UpdatePagePayload: UpdatePagePayload;
  UpdatePostFormatPayload: UpdatePostFormatPayload;
  UpdatePostPayload: UpdatePostPayload;
  UpdateReusableBlockPayload: UpdateReusableBlockPayload;
  UpdateServicePayload: UpdateServicePayload;
  UpdateSettingsPayload: UpdateSettingsPayload;
  UpdateSpecialtyPayload: UpdateSpecialtyPayload;
  UpdateTagPayload: UpdateTagPayload;
  UpdateTeam_memberPayload: UpdateTeam_memberPayload;
  UpdateUserPayload: UpdateUserPayload;
  UpdateWorkPayload: UpdateWorkPayload;
  User: User;
  UserRole: UserRole;
  UserToBlockEditorPreviewConnection: UserToBlockEditorPreviewConnection;
  UserToBlockEditorPreviewConnectionEdge: UserToBlockEditorPreviewConnectionEdge;
  UserToCommentConnection: UserToCommentConnection;
  UserToCommentConnectionEdge: UserToCommentConnectionEdge;
  UserToContentRevisionUnionConnection: UserToContentRevisionUnionConnection;
  UserToContentRevisionUnionConnectionEdge: UserToContentRevisionUnionConnectionEdge;
  UserToEnqueuedScriptConnection: UserToEnqueuedScriptConnection;
  UserToEnqueuedScriptConnectionEdge: UserToEnqueuedScriptConnectionEdge;
  UserToEnqueuedStylesheetConnection: UserToEnqueuedStylesheetConnection;
  UserToEnqueuedStylesheetConnectionEdge: UserToEnqueuedStylesheetConnectionEdge;
  UserToMediaItemConnection: UserToMediaItemConnection;
  UserToMediaItemConnectionEdge: UserToMediaItemConnectionEdge;
  UserToPageConnection: UserToPageConnection;
  UserToPageConnectionEdge: UserToPageConnectionEdge;
  UserToPostConnection: UserToPostConnection;
  UserToPostConnectionEdge: UserToPostConnectionEdge;
  UserToUserRoleConnection: UserToUserRoleConnection;
  UserToUserRoleConnectionEdge: UserToUserRoleConnectionEdge;
  WPPageInfo: WPPageInfo;
  WebsiteField: WebsiteField;
  Work: Work;
  WorkToPreviewConnectionEdge: WorkToPreviewConnectionEdge;
  Work_Workacf: Work_Workacf;
  WritingSettings: WritingSettings;
  YoastFaqBlock: YoastFaqBlock;
  YoastFaqBlockAttributes: YoastFaqBlockAttributes;
  YoastFaqBlockDeprecatedV1Attributes: YoastFaqBlockDeprecatedV1Attributes;
  YoastHowToBlock: YoastHowToBlock;
  YoastHowToBlockAttributes: YoastHowToBlockAttributes;
  YoastHowToBlockDeprecatedV1Attributes: YoastHowToBlockDeprecatedV1Attributes;
  YoastHowToBlockDeprecatedV2Attributes: YoastHowToBlockDeprecatedV2Attributes;
  YoastSeoBreadcrumbsBlock: YoastSeoBreadcrumbsBlock;
  YoastSeoBreadcrumbsBlockAttributes: YoastSeoBreadcrumbsBlockAttributes;
}
export type SchemaObjectTypesNames =
  | "AddressField"
  | "AddressFieldValue"
  | "AddressInputProperty"
  | "Avatar"
  | "BlockEditorContentNodeConnection"
  | "BlockEditorContentNodeConnectionEdge"
  | "BlockEditorPreview"
  | "BlockEditorPreviewToPreviewConnectionEdge"
  | "CaptchaField"
  | "Category"
  | "CategoryToAncestorsCategoryConnection"
  | "CategoryToAncestorsCategoryConnectionEdge"
  | "CategoryToCategoryConnection"
  | "CategoryToCategoryConnectionEdge"
  | "CategoryToContentNodeConnection"
  | "CategoryToContentNodeConnectionEdge"
  | "CategoryToParentCategoryConnectionEdge"
  | "CategoryToPostConnection"
  | "CategoryToPostConnectionEdge"
  | "CategoryToTaxonomyConnectionEdge"
  | "CheckboxField"
  | "CheckboxFieldChoice"
  | "CheckboxFieldValue"
  | "CheckboxInputProperty"
  | "Comment"
  | "CommentAuthor"
  | "CommentToCommentConnection"
  | "CommentToCommentConnectionEdge"
  | "CommentToCommenterConnectionEdge"
  | "CommentToContentNodeConnectionEdge"
  | "CommentToParentCommentConnectionEdge"
  | "ConditionalLogic"
  | "ConditionalLogicRule"
  | "ConditionalTags"
  | "ConsentField"
  | "ContentNodeToContentTypeConnectionEdge"
  | "ContentNodeToEditLastConnectionEdge"
  | "ContentNodeToEditLockConnectionEdge"
  | "ContentNodeToEnqueuedScriptConnection"
  | "ContentNodeToEnqueuedScriptConnectionEdge"
  | "ContentNodeToEnqueuedStylesheetConnection"
  | "ContentNodeToEnqueuedStylesheetConnectionEdge"
  | "ContentType"
  | "ContentTypeToContentNodeConnection"
  | "ContentTypeToContentNodeConnectionEdge"
  | "ContentTypeToTaxonomyConnection"
  | "ContentTypeToTaxonomyConnectionEdge"
  | "CoreArchivesBlock"
  | "CoreArchivesBlockAttributes"
  | "CoreAudioBlock"
  | "CoreAudioBlockAttributes"
  | "CoreAudioBlockDeprecatedV1Attributes"
  | "CoreAvatarBlock"
  | "CoreAvatarBlockAttributes"
  | "CoreBlock"
  | "CoreBlockAttributes"
  | "CoreButtonBlock"
  | "CoreButtonBlockAttributes"
  | "CoreButtonBlockDeprecatedV10Attributes"
  | "CoreButtonBlockDeprecatedV11Attributes"
  | "CoreButtonBlockDeprecatedV1Attributes"
  | "CoreButtonBlockDeprecatedV2Attributes"
  | "CoreButtonBlockDeprecatedV3Attributes"
  | "CoreButtonBlockDeprecatedV4Attributes"
  | "CoreButtonBlockDeprecatedV5Attributes"
  | "CoreButtonBlockDeprecatedV6Attributes"
  | "CoreButtonBlockDeprecatedV7Attributes"
  | "CoreButtonBlockDeprecatedV8Attributes"
  | "CoreButtonBlockDeprecatedV9Attributes"
  | "CoreButtonsBlock"
  | "CoreButtonsBlockAttributes"
  | "CoreButtonsBlockDeprecatedV1Attributes"
  | "CoreButtonsBlockDeprecatedV2Attributes"
  | "CoreCalendarBlock"
  | "CoreCalendarBlockAttributes"
  | "CoreCategoriesBlock"
  | "CoreCategoriesBlockAttributes"
  | "CoreCodeBlock"
  | "CoreCodeBlockAttributes"
  | "CoreColumnBlock"
  | "CoreColumnBlockAttributes"
  | "CoreColumnBlockDeprecatedV1Attributes"
  | "CoreColumnsBlock"
  | "CoreColumnsBlockAttributes"
  | "CoreColumnsBlockDeprecatedV1Attributes"
  | "CoreColumnsBlockDeprecatedV2Attributes"
  | "CoreColumnsBlockDeprecatedV3Attributes"
  | "CoreCommentAuthorNameBlock"
  | "CoreCommentAuthorNameBlockAttributes"
  | "CoreCommentAuthorNameBlockDeprecatedV1Attributes"
  | "CoreCommentContentBlock"
  | "CoreCommentContentBlockAttributes"
  | "CoreCommentDateBlock"
  | "CoreCommentDateBlockAttributes"
  | "CoreCommentDateBlockDeprecatedV1Attributes"
  | "CoreCommentEditLinkBlock"
  | "CoreCommentEditLinkBlockAttributes"
  | "CoreCommentReplyLinkBlock"
  | "CoreCommentReplyLinkBlockAttributes"
  | "CoreCommentTemplateBlock"
  | "CoreCommentTemplateBlockAttributes"
  | "CoreCommentsBlock"
  | "CoreCommentsBlockAttributes"
  | "CoreCommentsBlockDeprecatedV1Attributes"
  | "CoreCommentsPaginationBlock"
  | "CoreCommentsPaginationBlockAttributes"
  | "CoreCommentsPaginationNextBlock"
  | "CoreCommentsPaginationNextBlockAttributes"
  | "CoreCommentsPaginationNumbersBlock"
  | "CoreCommentsPaginationNumbersBlockAttributes"
  | "CoreCommentsPaginationPreviousBlock"
  | "CoreCommentsPaginationPreviousBlockAttributes"
  | "CoreCommentsTitleBlock"
  | "CoreCommentsTitleBlockAttributes"
  | "CoreCommentsTitleBlockDeprecatedV1Attributes"
  | "CoreCoverBlock"
  | "CoreCoverBlockAttributes"
  | "CoreCoverBlockDeprecatedV10Attributes"
  | "CoreCoverBlockDeprecatedV11Attributes"
  | "CoreCoverBlockDeprecatedV1Attributes"
  | "CoreCoverBlockDeprecatedV2Attributes"
  | "CoreCoverBlockDeprecatedV3Attributes"
  | "CoreCoverBlockDeprecatedV4Attributes"
  | "CoreCoverBlockDeprecatedV5Attributes"
  | "CoreCoverBlockDeprecatedV6Attributes"
  | "CoreCoverBlockDeprecatedV7Attributes"
  | "CoreCoverBlockDeprecatedV8Attributes"
  | "CoreCoverBlockDeprecatedV9Attributes"
  | "CoreDetailsBlock"
  | "CoreDetailsBlockAttributes"
  | "CoreEmbedBlock"
  | "CoreEmbedBlockAttributes"
  | "CoreEmbedBlockDeprecatedV1Attributes"
  | "CoreEmbedBlockDeprecatedV2Attributes"
  | "CoreFileBlock"
  | "CoreFileBlockAttributes"
  | "CoreFileBlockDeprecatedV1Attributes"
  | "CoreFileBlockDeprecatedV2Attributes"
  | "CoreFileBlockDeprecatedV3Attributes"
  | "CoreFootnotesBlock"
  | "CoreFootnotesBlockAttributes"
  | "CoreFreeformBlock"
  | "CoreFreeformBlockAttributes"
  | "CoreGalleryBlock"
  | "CoreGalleryBlockAttributes"
  | "CoreGalleryBlockAttributesImages"
  | "CoreGalleryBlockDeprecatedV1Attributes"
  | "CoreGalleryBlockDeprecatedV1AttributesImages"
  | "CoreGalleryBlockDeprecatedV2Attributes"
  | "CoreGalleryBlockDeprecatedV2AttributesImages"
  | "CoreGalleryBlockDeprecatedV3Attributes"
  | "CoreGalleryBlockDeprecatedV3AttributesImages"
  | "CoreGalleryBlockDeprecatedV4Attributes"
  | "CoreGalleryBlockDeprecatedV4AttributesImages"
  | "CoreGalleryBlockDeprecatedV5Attributes"
  | "CoreGalleryBlockDeprecatedV5AttributesImages"
  | "CoreGalleryBlockDeprecatedV6Attributes"
  | "CoreGalleryBlockDeprecatedV6AttributesImages"
  | "CoreGalleryBlockDeprecatedV7Attributes"
  | "CoreGalleryBlockDeprecatedV7AttributesImages"
  | "CoreGroupBlock"
  | "CoreGroupBlockAttributes"
  | "CoreGroupBlockDeprecatedV1Attributes"
  | "CoreGroupBlockDeprecatedV2Attributes"
  | "CoreGroupBlockDeprecatedV3Attributes"
  | "CoreGroupBlockDeprecatedV4Attributes"
  | "CoreGroupBlockDeprecatedV5Attributes"
  | "CoreHeadingBlock"
  | "CoreHeadingBlockAttributes"
  | "CoreHeadingBlockDeprecatedV1Attributes"
  | "CoreHeadingBlockDeprecatedV2Attributes"
  | "CoreHeadingBlockDeprecatedV3Attributes"
  | "CoreHeadingBlockDeprecatedV4Attributes"
  | "CoreHeadingBlockDeprecatedV5Attributes"
  | "CoreHomeLinkBlock"
  | "CoreHomeLinkBlockAttributes"
  | "CoreHtmlBlock"
  | "CoreHtmlBlockAttributes"
  | "CoreImageBlock"
  | "CoreImageBlockAttributes"
  | "CoreImageBlockDeprecatedV1Attributes"
  | "CoreImageBlockDeprecatedV2Attributes"
  | "CoreImageBlockDeprecatedV3Attributes"
  | "CoreImageBlockDeprecatedV4Attributes"
  | "CoreImageBlockDeprecatedV5Attributes"
  | "CoreImageBlockDeprecatedV6Attributes"
  | "CoreImageBlockToMediaItemConnectionEdge"
  | "CoreLatestCommentsBlock"
  | "CoreLatestCommentsBlockAttributes"
  | "CoreLatestPostsBlock"
  | "CoreLatestPostsBlockAttributes"
  | "CoreLatestPostsBlockDeprecatedV1Attributes"
  | "CoreLegacyWidgetBlock"
  | "CoreLegacyWidgetBlockAttributes"
  | "CoreListBlock"
  | "CoreListBlockAttributes"
  | "CoreListBlockDeprecatedV1Attributes"
  | "CoreListBlockDeprecatedV2Attributes"
  | "CoreListItemBlock"
  | "CoreListItemBlockAttributes"
  | "CoreLoginoutBlock"
  | "CoreLoginoutBlockAttributes"
  | "CoreMediaTextBlock"
  | "CoreMediaTextBlockAttributes"
  | "CoreMediaTextBlockDeprecatedV1Attributes"
  | "CoreMediaTextBlockDeprecatedV2Attributes"
  | "CoreMediaTextBlockDeprecatedV3Attributes"
  | "CoreMediaTextBlockDeprecatedV4Attributes"
  | "CoreMediaTextBlockDeprecatedV5Attributes"
  | "CoreMediaTextBlockDeprecatedV6Attributes"
  | "CoreMissingBlock"
  | "CoreMissingBlockAttributes"
  | "CoreMoreBlock"
  | "CoreMoreBlockAttributes"
  | "CoreNavigationBlock"
  | "CoreNavigationBlockAttributes"
  | "CoreNavigationBlockDeprecatedV1Attributes"
  | "CoreNavigationBlockDeprecatedV2Attributes"
  | "CoreNavigationBlockDeprecatedV3Attributes"
  | "CoreNavigationBlockDeprecatedV4Attributes"
  | "CoreNavigationBlockDeprecatedV5Attributes"
  | "CoreNavigationBlockDeprecatedV6Attributes"
  | "CoreNavigationLinkBlock"
  | "CoreNavigationLinkBlockAttributes"
  | "CoreNavigationLinkBlockDeprecatedV1Attributes"
  | "CoreNavigationSubmenuBlock"
  | "CoreNavigationSubmenuBlockAttributes"
  | "CoreNextpageBlock"
  | "CoreNextpageBlockAttributes"
  | "CorePageListBlock"
  | "CorePageListBlockAttributes"
  | "CorePageListItemBlock"
  | "CorePageListItemBlockAttributes"
  | "CoreParagraphBlock"
  | "CoreParagraphBlockAttributes"
  | "CoreParagraphBlockDeprecatedV1Attributes"
  | "CoreParagraphBlockDeprecatedV2Attributes"
  | "CoreParagraphBlockDeprecatedV3Attributes"
  | "CoreParagraphBlockDeprecatedV4Attributes"
  | "CoreParagraphBlockDeprecatedV5Attributes"
  | "CoreParagraphBlockDeprecatedV6Attributes"
  | "CorePatternBlock"
  | "CorePatternBlockAttributes"
  | "CorePostAuthorBiographyBlock"
  | "CorePostAuthorBiographyBlockAttributes"
  | "CorePostAuthorBlock"
  | "CorePostAuthorBlockAttributes"
  | "CorePostAuthorNameBlock"
  | "CorePostAuthorNameBlockAttributes"
  | "CorePostCommentsFormBlock"
  | "CorePostCommentsFormBlockAttributes"
  | "CorePostContentBlock"
  | "CorePostContentBlockAttributes"
  | "CorePostDateBlock"
  | "CorePostDateBlockAttributes"
  | "CorePostDateBlockDeprecatedV1Attributes"
  | "CorePostExcerptBlock"
  | "CorePostExcerptBlockAttributes"
  | "CorePostFeaturedImageBlock"
  | "CorePostFeaturedImageBlockAttributes"
  | "CorePostNavigationLinkBlock"
  | "CorePostNavigationLinkBlockAttributes"
  | "CorePostTemplateBlock"
  | "CorePostTemplateBlockAttributes"
  | "CorePostTermsBlock"
  | "CorePostTermsBlockAttributes"
  | "CorePostTitleBlock"
  | "CorePostTitleBlockAttributes"
  | "CorePostTitleBlockDeprecatedV1Attributes"
  | "CorePreformattedBlock"
  | "CorePreformattedBlockAttributes"
  | "CorePullquoteBlock"
  | "CorePullquoteBlockAttributes"
  | "CorePullquoteBlockDeprecatedV1Attributes"
  | "CorePullquoteBlockDeprecatedV2Attributes"
  | "CorePullquoteBlockDeprecatedV3Attributes"
  | "CorePullquoteBlockDeprecatedV4Attributes"
  | "CorePullquoteBlockDeprecatedV5Attributes"
  | "CorePullquoteBlockDeprecatedV6Attributes"
  | "CoreQueryBlock"
  | "CoreQueryBlockAttributes"
  | "CoreQueryBlockDeprecatedV1Attributes"
  | "CoreQueryBlockDeprecatedV2Attributes"
  | "CoreQueryBlockDeprecatedV3Attributes"
  | "CoreQueryBlockDeprecatedV4Attributes"
  | "CoreQueryBlockDeprecatedV5Attributes"
  | "CoreQueryNoResultsBlock"
  | "CoreQueryNoResultsBlockAttributes"
  | "CoreQueryPaginationBlock"
  | "CoreQueryPaginationBlockAttributes"
  | "CoreQueryPaginationBlockDeprecatedV1Attributes"
  | "CoreQueryPaginationNextBlock"
  | "CoreQueryPaginationNextBlockAttributes"
  | "CoreQueryPaginationNumbersBlock"
  | "CoreQueryPaginationNumbersBlockAttributes"
  | "CoreQueryPaginationPreviousBlock"
  | "CoreQueryPaginationPreviousBlockAttributes"
  | "CoreQueryTitleBlock"
  | "CoreQueryTitleBlockAttributes"
  | "CoreQueryTitleBlockDeprecatedV1Attributes"
  | "CoreQuoteBlock"
  | "CoreQuoteBlockAttributes"
  | "CoreQuoteBlockDeprecatedV1Attributes"
  | "CoreQuoteBlockDeprecatedV2Attributes"
  | "CoreQuoteBlockDeprecatedV3Attributes"
  | "CoreQuoteBlockDeprecatedV4Attributes"
  | "CoreReadMoreBlock"
  | "CoreReadMoreBlockAttributes"
  | "CoreRssBlock"
  | "CoreRssBlockAttributes"
  | "CoreSearchBlock"
  | "CoreSearchBlockAttributes"
  | "CoreSeparatorBlock"
  | "CoreSeparatorBlockAttributes"
  | "CoreSeparatorBlockDeprecatedV1Attributes"
  | "CoreShortcodeBlock"
  | "CoreShortcodeBlockAttributes"
  | "CoreSiteLogoBlock"
  | "CoreSiteLogoBlockAttributes"
  | "CoreSiteTaglineBlock"
  | "CoreSiteTaglineBlockAttributes"
  | "CoreSiteTaglineBlockDeprecatedV1Attributes"
  | "CoreSiteTitleBlock"
  | "CoreSiteTitleBlockAttributes"
  | "CoreSiteTitleBlockDeprecatedV1Attributes"
  | "CoreSocialLinkBlock"
  | "CoreSocialLinkBlockAttributes"
  | "CoreSocialLinksBlock"
  | "CoreSocialLinksBlockAttributes"
  | "CoreSocialLinksBlockDeprecatedV1Attributes"
  | "CoreSpacerBlock"
  | "CoreSpacerBlockAttributes"
  | "CoreSpacerBlockDeprecatedV1Attributes"
  | "CoreTableBlock"
  | "CoreTableBlockAttributes"
  | "CoreTableBlockAttributesBody"
  | "CoreTableBlockAttributesBodyCells"
  | "CoreTableBlockAttributesFoot"
  | "CoreTableBlockAttributesFootCells"
  | "CoreTableBlockAttributesHead"
  | "CoreTableBlockAttributesHeadCells"
  | "CoreTableBlockDeprecatedV1Attributes"
  | "CoreTableBlockDeprecatedV1AttributesBody"
  | "CoreTableBlockDeprecatedV1AttributesBodyCells"
  | "CoreTableBlockDeprecatedV1AttributesFoot"
  | "CoreTableBlockDeprecatedV1AttributesFootCells"
  | "CoreTableBlockDeprecatedV1AttributesHead"
  | "CoreTableBlockDeprecatedV1AttributesHeadCells"
  | "CoreTableBlockDeprecatedV2Attributes"
  | "CoreTableBlockDeprecatedV2AttributesBody"
  | "CoreTableBlockDeprecatedV2AttributesBodyCells"
  | "CoreTableBlockDeprecatedV2AttributesFoot"
  | "CoreTableBlockDeprecatedV2AttributesFootCells"
  | "CoreTableBlockDeprecatedV2AttributesHead"
  | "CoreTableBlockDeprecatedV2AttributesHeadCells"
  | "CoreTableBlockDeprecatedV3Attributes"
  | "CoreTableBlockDeprecatedV3AttributesBody"
  | "CoreTableBlockDeprecatedV3AttributesBodyCells"
  | "CoreTableBlockDeprecatedV3AttributesFoot"
  | "CoreTableBlockDeprecatedV3AttributesFootCells"
  | "CoreTableBlockDeprecatedV3AttributesHead"
  | "CoreTableBlockDeprecatedV3AttributesHeadCells"
  | "CoreTagCloudBlock"
  | "CoreTagCloudBlockAttributes"
  | "CoreTemplatePartBlock"
  | "CoreTemplatePartBlockAttributes"
  | "CoreTermDescriptionBlock"
  | "CoreTermDescriptionBlockAttributes"
  | "CoreTextColumnsBlock"
  | "CoreTextColumnsBlockAttributes"
  | "CoreTextColumnsBlockAttributesContent"
  | "CoreVerseBlock"
  | "CoreVerseBlockAttributes"
  | "CoreVerseBlockDeprecatedV1Attributes"
  | "CoreVerseBlockDeprecatedV2Attributes"
  | "CoreVideoBlock"
  | "CoreVideoBlockAttributes"
  | "CoreVideoBlockDeprecatedV1Attributes"
  | "CoreWidgetGroupBlock"
  | "CoreWidgetGroupBlockAttributes"
  | "CoreWidgetGroupBlockDeprecatedV1Attributes"
  | "CreateCategoryPayload"
  | "CreateCommentPayload"
  | "CreateJobPayload"
  | "CreateMediaItemPayload"
  | "CreatePagePayload"
  | "CreatePostFormatPayload"
  | "CreatePostPayload"
  | "CreateReusableBlockPayload"
  | "CreateServicePayload"
  | "CreateSpecialtyPayload"
  | "CreateTagPayload"
  | "CreateTeam_memberPayload"
  | "CreateUserPayload"
  | "CreateWorkPayload"
  | "DateField"
  | "DateInputProperty"
  | "DefaultTemplate"
  | "DeleteCategoryPayload"
  | "DeleteCommentPayload"
  | "DeleteGfDraftEntryPayload"
  | "DeleteGfEntryPayload"
  | "DeleteJobPayload"
  | "DeleteMediaItemPayload"
  | "DeletePagePayload"
  | "DeletePostFormatPayload"
  | "DeletePostPayload"
  | "DeleteReusableBlockPayload"
  | "DeleteServicePayload"
  | "DeleteSpecialtyPayload"
  | "DeleteTagPayload"
  | "DeleteTeam_memberPayload"
  | "DeleteUserPayload"
  | "DeleteWorkPayload"
  | "DiscussionSettings"
  | "EmailField"
  | "EmailInputProperty"
  | "EnqueuedScript"
  | "EnqueuedStylesheet"
  | "FieldError"
  | "FileUploadField"
  | "FileUploadFieldValue"
  | "FormButton"
  | "FormConfirmation"
  | "FormDataPolicies"
  | "FormEntryDataPolicy"
  | "FormEntryLimits"
  | "FormFieldDataPolicy"
  | "FormLastPageButton"
  | "FormLogin"
  | "FormNotification"
  | "FormNotificationRouting"
  | "FormPagination"
  | "FormPersonalData"
  | "FormPostCreation"
  | "FormSaveAndContinue"
  | "FormSchedule"
  | "FormScheduleDetails"
  | "FormSubmitButton"
  | "GeneralOptionsSettings"
  | "GeneralOptionsSettings_Generalsettings"
  | "GeneralSettings"
  | "GenerateAuthorizationCodePayload"
  | "GenesisCustomBlocksHeroBlock"
  | "GenesisCustomBlocksHeroBlockAttributes"
  | "GfDraftEntry"
  | "GfEntryToFormFieldConnection"
  | "GfEntryToFormFieldConnectionEdge"
  | "GfForm"
  | "GfFormToFormFieldConnection"
  | "GfFormToFormFieldConnectionEdge"
  | "GfFormToGfEntryConnection"
  | "GfFormToGfEntryConnectionEdge"
  | "GfLogger"
  | "GfSettings"
  | "GfSettingsLogging"
  | "GfSubmittedEntry"
  | "GravityformsFormBlock"
  | "GravityformsFormBlockAttributes"
  | "HiddenField"
  | "HierarchicalContentNodeToContentNodeAncestorsConnection"
  | "HierarchicalContentNodeToContentNodeAncestorsConnectionEdge"
  | "HierarchicalContentNodeToContentNodeChildrenConnection"
  | "HierarchicalContentNodeToContentNodeChildrenConnectionEdge"
  | "HierarchicalContentNodeToParentContentNodeConnectionEdge"
  | "HtmlField"
  | "IdeaboxTocBlock"
  | "IdeaboxTocBlockAttributes"
  | "ImageFieldValue"
  | "Job"
  | "JobToPreviewConnectionEdge"
  | "Job_Jobacf"
  | "ListField"
  | "ListFieldChoice"
  | "ListFieldValue"
  | "MediaDetails"
  | "MediaItem"
  | "MediaItemMeta"
  | "MediaItemToCommentConnection"
  | "MediaItemToCommentConnectionEdge"
  | "MediaSize"
  | "Menu"
  | "MenuItem"
  | "MenuItemToMenuConnectionEdge"
  | "MenuItemToMenuItemConnection"
  | "MenuItemToMenuItemConnectionEdge"
  | "MenuItemToMenuItemLinkableConnectionEdge"
  | "MenuToMenuItemConnection"
  | "MenuToMenuItemConnectionEdge"
  | "MultiSelectField"
  | "MultiSelectFieldChoice"
  | "Mutation"
  | "NameField"
  | "NameFieldChoice"
  | "NameFieldValue"
  | "NameInputProperty"
  | "NodeWithAuthorToUserConnectionEdge"
  | "NodeWithFeaturedImageToMediaItemConnectionEdge"
  | "NodeWithRevisionsToContentNodeConnectionEdge"
  | "NumberField"
  | "Page"
  | "PageField"
  | "PageToCommentConnection"
  | "PageToCommentConnectionEdge"
  | "PageToPreviewConnectionEdge"
  | "PageToRevisionConnection"
  | "PageToRevisionConnectionEdge"
  | "Page_Aboutcta"
  | "Page_Aboutcta_bullets"
  | "Page_Contenthero"
  | "Page_Features"
  | "Page_Features_features"
  | "Page_Features_features_bullettPoints"
  | "PasswordField"
  | "PasswordInputProperty"
  | "PhoneField"
  | "Plugin"
  | "Post"
  | "PostCategoryCheckboxField"
  | "PostCategoryFieldChoice"
  | "PostCategoryInputProperty"
  | "PostCategoryMultiSelectField"
  | "PostCategoryRadioField"
  | "PostCategorySelectField"
  | "PostContentField"
  | "PostCustomCheckboxField"
  | "PostCustomCheckboxFieldChoice"
  | "PostCustomDateField"
  | "PostCustomEmailField"
  | "PostCustomFileuploadField"
  | "PostCustomHiddenField"
  | "PostCustomInputProperty"
  | "PostCustomListField"
  | "PostCustomListFieldChoice"
  | "PostCustomMultiSelectField"
  | "PostCustomMultiSelectFieldChoice"
  | "PostCustomNumberField"
  | "PostCustomPhoneField"
  | "PostCustomRadioField"
  | "PostCustomRadioFieldChoice"
  | "PostCustomSelectField"
  | "PostCustomSelectFieldChoice"
  | "PostCustomTextAreaField"
  | "PostCustomTextField"
  | "PostCustomTimeField"
  | "PostCustomWebsiteField"
  | "PostExcerptField"
  | "PostFormat"
  | "PostFormatToContentNodeConnection"
  | "PostFormatToContentNodeConnectionEdge"
  | "PostFormatToPostConnection"
  | "PostFormatToPostConnectionEdge"
  | "PostFormatToTaxonomyConnectionEdge"
  | "PostImageField"
  | "PostTagsCheckboxField"
  | "PostTagsCheckboxFieldChoice"
  | "PostTagsInputProperty"
  | "PostTagsMultiSelectField"
  | "PostTagsMultiSelectFieldChoice"
  | "PostTagsRadioField"
  | "PostTagsRadioFieldChoice"
  | "PostTagsSelectField"
  | "PostTagsSelectFieldChoice"
  | "PostTagsTextField"
  | "PostTitleField"
  | "PostToCategoryConnection"
  | "PostToCategoryConnectionEdge"
  | "PostToCommentConnection"
  | "PostToCommentConnectionEdge"
  | "PostToPostFormatConnection"
  | "PostToPostFormatConnectionEdge"
  | "PostToPreviewConnectionEdge"
  | "PostToRevisionConnection"
  | "PostToRevisionConnectionEdge"
  | "PostToTagConnection"
  | "PostToTagConnectionEdge"
  | "PostToTermNodeConnection"
  | "PostToTermNodeConnectionEdge"
  | "PostTypeLabelDetails"
  | "PostTypeSEO"
  | "Post_Postsacf"
  | "Query"
  | "RadioField"
  | "RadioFieldChoice"
  | "ReadingSettings"
  | "RegisterUserPayload"
  | "ResetUserPasswordPayload"
  | "RestoreCommentPayload"
  | "ReusableBlock"
  | "ReusableBlockToPreviewConnectionEdge"
  | "ReusableBlockToRevisionConnection"
  | "ReusableBlockToRevisionConnectionEdge"
  | "RootQueryToBlockEditorPreviewConnection"
  | "RootQueryToBlockEditorPreviewConnectionEdge"
  | "RootQueryToCategoryConnection"
  | "RootQueryToCategoryConnectionEdge"
  | "RootQueryToCommentConnection"
  | "RootQueryToCommentConnectionEdge"
  | "RootQueryToContentNodeConnection"
  | "RootQueryToContentNodeConnectionEdge"
  | "RootQueryToContentRevisionUnionConnection"
  | "RootQueryToContentRevisionUnionConnectionEdge"
  | "RootQueryToContentTypeConnection"
  | "RootQueryToContentTypeConnectionEdge"
  | "RootQueryToEnqueuedScriptConnection"
  | "RootQueryToEnqueuedScriptConnectionEdge"
  | "RootQueryToEnqueuedStylesheetConnection"
  | "RootQueryToEnqueuedStylesheetConnectionEdge"
  | "RootQueryToGfEntryConnection"
  | "RootQueryToGfEntryConnectionEdge"
  | "RootQueryToGfFormConnection"
  | "RootQueryToGfFormConnectionEdge"
  | "RootQueryToGfSubmittedEntryConnection"
  | "RootQueryToGfSubmittedEntryConnectionEdge"
  | "RootQueryToJobConnection"
  | "RootQueryToJobConnectionEdge"
  | "RootQueryToMediaItemConnection"
  | "RootQueryToMediaItemConnectionEdge"
  | "RootQueryToMenuConnection"
  | "RootQueryToMenuConnectionEdge"
  | "RootQueryToMenuItemConnection"
  | "RootQueryToMenuItemConnectionEdge"
  | "RootQueryToPageConnection"
  | "RootQueryToPageConnectionEdge"
  | "RootQueryToPluginConnection"
  | "RootQueryToPluginConnectionEdge"
  | "RootQueryToPostConnection"
  | "RootQueryToPostConnectionEdge"
  | "RootQueryToPostFormatConnection"
  | "RootQueryToPostFormatConnectionEdge"
  | "RootQueryToReusableBlockConnection"
  | "RootQueryToReusableBlockConnectionEdge"
  | "RootQueryToServiceConnection"
  | "RootQueryToServiceConnectionEdge"
  | "RootQueryToSpecialtyConnection"
  | "RootQueryToSpecialtyConnectionEdge"
  | "RootQueryToTagConnection"
  | "RootQueryToTagConnectionEdge"
  | "RootQueryToTaxonomyConnection"
  | "RootQueryToTaxonomyConnectionEdge"
  | "RootQueryToTeam_memberConnection"
  | "RootQueryToTeam_memberConnectionEdge"
  | "RootQueryToTermNodeConnection"
  | "RootQueryToTermNodeConnectionEdge"
  | "RootQueryToThemeConnection"
  | "RootQueryToThemeConnectionEdge"
  | "RootQueryToUserConnection"
  | "RootQueryToUserConnectionEdge"
  | "RootQueryToUserRoleConnection"
  | "RootQueryToUserRoleConnectionEdge"
  | "RootQueryToWorkConnection"
  | "RootQueryToWorkConnectionEdge"
  | "SEOBreadcrumbs"
  | "SEOConfig"
  | "SEOContentType"
  | "SEOContentTypeArchive"
  | "SEOContentTypes"
  | "SEOOpenGraph"
  | "SEOOpenGraphFrontPage"
  | "SEOPageInfoSchema"
  | "SEOPostTypeBreadcrumbs"
  | "SEOPostTypePageInfo"
  | "SEOPostTypeSchema"
  | "SEORedirect"
  | "SEOSchema"
  | "SEOSocial"
  | "SEOSocialFacebook"
  | "SEOSocialInstagram"
  | "SEOSocialLinkedIn"
  | "SEOSocialMySpace"
  | "SEOSocialPinterest"
  | "SEOSocialTwitter"
  | "SEOSocialWikipedia"
  | "SEOSocialYoutube"
  | "SEOTaxonomySchema"
  | "SEOUser"
  | "SEOUserSchema"
  | "SEOUserSocial"
  | "SEOWebmaster"
  | "SectionField"
  | "SelectField"
  | "SelectFieldChoice"
  | "SendPasswordResetEmailPayload"
  | "Service"
  | "ServiceToPreviewConnectionEdge"
  | "Settings"
  | "Specialty"
  | "SpecialtyToPreviewConnectionEdge"
  | "SubmitField"
  | "SubmitGfDraftEntryPayload"
  | "SubmitGfFormPayload"
  | "Subscription"
  | "Tag"
  | "TagToContentNodeConnection"
  | "TagToContentNodeConnectionEdge"
  | "TagToPostConnection"
  | "TagToPostConnectionEdge"
  | "TagToTaxonomyConnectionEdge"
  | "Taxonomy"
  | "TaxonomySEO"
  | "TaxonomyToContentTypeConnection"
  | "TaxonomyToContentTypeConnectionEdge"
  | "Team_member"
  | "Team_memberToPreviewConnectionEdge"
  | "Team_member_Team"
  | "Template_About"
  | "Template_Blog"
  | "Template_Contact"
  | "Template_Home"
  | "Template_Work"
  | "TermNodeToEnqueuedScriptConnection"
  | "TermNodeToEnqueuedScriptConnectionEdge"
  | "TermNodeToEnqueuedStylesheetConnection"
  | "TermNodeToEnqueuedStylesheetConnectionEdge"
  | "TextAreaField"
  | "TextField"
  | "Theme"
  | "TimeField"
  | "TimeFieldValue"
  | "TimeInputProperty"
  | "UpdateCategoryPayload"
  | "UpdateCommentPayload"
  | "UpdateGfDraftEntryPayload"
  | "UpdateGfEntryPayload"
  | "UpdateJobPayload"
  | "UpdateMediaItemPayload"
  | "UpdatePagePayload"
  | "UpdatePostFormatPayload"
  | "UpdatePostPayload"
  | "UpdateReusableBlockPayload"
  | "UpdateServicePayload"
  | "UpdateSettingsPayload"
  | "UpdateSpecialtyPayload"
  | "UpdateTagPayload"
  | "UpdateTeam_memberPayload"
  | "UpdateUserPayload"
  | "UpdateWorkPayload"
  | "User"
  | "UserRole"
  | "UserToBlockEditorPreviewConnection"
  | "UserToBlockEditorPreviewConnectionEdge"
  | "UserToCommentConnection"
  | "UserToCommentConnectionEdge"
  | "UserToContentRevisionUnionConnection"
  | "UserToContentRevisionUnionConnectionEdge"
  | "UserToEnqueuedScriptConnection"
  | "UserToEnqueuedScriptConnectionEdge"
  | "UserToEnqueuedStylesheetConnection"
  | "UserToEnqueuedStylesheetConnectionEdge"
  | "UserToMediaItemConnection"
  | "UserToMediaItemConnectionEdge"
  | "UserToPageConnection"
  | "UserToPageConnectionEdge"
  | "UserToPostConnection"
  | "UserToPostConnectionEdge"
  | "UserToUserRoleConnection"
  | "UserToUserRoleConnectionEdge"
  | "WPPageInfo"
  | "WebsiteField"
  | "Work"
  | "WorkToPreviewConnectionEdge"
  | "Work_Workacf"
  | "WritingSettings"
  | "YoastFaqBlock"
  | "YoastFaqBlockAttributes"
  | "YoastFaqBlockDeprecatedV1Attributes"
  | "YoastHowToBlock"
  | "YoastHowToBlockAttributes"
  | "YoastHowToBlockDeprecatedV1Attributes"
  | "YoastHowToBlockDeprecatedV2Attributes"
  | "YoastSeoBreadcrumbsBlock"
  | "YoastSeoBreadcrumbsBlockAttributes";

export interface $AcfFieldGroup {
  GeneralOptionsSettings_Generalsettings?: GeneralOptionsSettings_Generalsettings;
  Job_Jobacf?: Job_Jobacf;
  Page_Aboutcta?: Page_Aboutcta;
  Page_Aboutcta_bullets?: Page_Aboutcta_bullets;
  Page_Contenthero?: Page_Contenthero;
  Page_Features?: Page_Features;
  Page_Features_features?: Page_Features_features;
  Page_Features_features_bullettPoints?: Page_Features_features_bullettPoints;
  Post_Postsacf?: Post_Postsacf;
  Team_member_Team?: Team_member_Team;
  Work_Workacf?: Work_Workacf;
}

export interface $Block {
  CoreArchivesBlock?: CoreArchivesBlock;
  CoreAudioBlock?: CoreAudioBlock;
  CoreAvatarBlock?: CoreAvatarBlock;
  CoreBlock?: CoreBlock;
  CoreButtonBlock?: CoreButtonBlock;
  CoreButtonsBlock?: CoreButtonsBlock;
  CoreCalendarBlock?: CoreCalendarBlock;
  CoreCategoriesBlock?: CoreCategoriesBlock;
  CoreCodeBlock?: CoreCodeBlock;
  CoreColumnBlock?: CoreColumnBlock;
  CoreColumnsBlock?: CoreColumnsBlock;
  CoreCommentAuthorNameBlock?: CoreCommentAuthorNameBlock;
  CoreCommentContentBlock?: CoreCommentContentBlock;
  CoreCommentDateBlock?: CoreCommentDateBlock;
  CoreCommentEditLinkBlock?: CoreCommentEditLinkBlock;
  CoreCommentReplyLinkBlock?: CoreCommentReplyLinkBlock;
  CoreCommentTemplateBlock?: CoreCommentTemplateBlock;
  CoreCommentsBlock?: CoreCommentsBlock;
  CoreCommentsPaginationBlock?: CoreCommentsPaginationBlock;
  CoreCommentsPaginationNextBlock?: CoreCommentsPaginationNextBlock;
  CoreCommentsPaginationNumbersBlock?: CoreCommentsPaginationNumbersBlock;
  CoreCommentsPaginationPreviousBlock?: CoreCommentsPaginationPreviousBlock;
  CoreCommentsTitleBlock?: CoreCommentsTitleBlock;
  CoreCoverBlock?: CoreCoverBlock;
  CoreDetailsBlock?: CoreDetailsBlock;
  CoreEmbedBlock?: CoreEmbedBlock;
  CoreFileBlock?: CoreFileBlock;
  CoreFootnotesBlock?: CoreFootnotesBlock;
  CoreFreeformBlock?: CoreFreeformBlock;
  CoreGalleryBlock?: CoreGalleryBlock;
  CoreGroupBlock?: CoreGroupBlock;
  CoreHeadingBlock?: CoreHeadingBlock;
  CoreHomeLinkBlock?: CoreHomeLinkBlock;
  CoreHtmlBlock?: CoreHtmlBlock;
  CoreImageBlock?: CoreImageBlock;
  CoreLatestCommentsBlock?: CoreLatestCommentsBlock;
  CoreLatestPostsBlock?: CoreLatestPostsBlock;
  CoreLegacyWidgetBlock?: CoreLegacyWidgetBlock;
  CoreListBlock?: CoreListBlock;
  CoreListItemBlock?: CoreListItemBlock;
  CoreLoginoutBlock?: CoreLoginoutBlock;
  CoreMediaTextBlock?: CoreMediaTextBlock;
  CoreMissingBlock?: CoreMissingBlock;
  CoreMoreBlock?: CoreMoreBlock;
  CoreNavigationBlock?: CoreNavigationBlock;
  CoreNavigationLinkBlock?: CoreNavigationLinkBlock;
  CoreNavigationSubmenuBlock?: CoreNavigationSubmenuBlock;
  CoreNextpageBlock?: CoreNextpageBlock;
  CorePageListBlock?: CorePageListBlock;
  CorePageListItemBlock?: CorePageListItemBlock;
  CoreParagraphBlock?: CoreParagraphBlock;
  CorePatternBlock?: CorePatternBlock;
  CorePostAuthorBiographyBlock?: CorePostAuthorBiographyBlock;
  CorePostAuthorBlock?: CorePostAuthorBlock;
  CorePostAuthorNameBlock?: CorePostAuthorNameBlock;
  CorePostCommentsFormBlock?: CorePostCommentsFormBlock;
  CorePostContentBlock?: CorePostContentBlock;
  CorePostDateBlock?: CorePostDateBlock;
  CorePostExcerptBlock?: CorePostExcerptBlock;
  CorePostFeaturedImageBlock?: CorePostFeaturedImageBlock;
  CorePostNavigationLinkBlock?: CorePostNavigationLinkBlock;
  CorePostTemplateBlock?: CorePostTemplateBlock;
  CorePostTermsBlock?: CorePostTermsBlock;
  CorePostTitleBlock?: CorePostTitleBlock;
  CorePreformattedBlock?: CorePreformattedBlock;
  CorePullquoteBlock?: CorePullquoteBlock;
  CoreQueryBlock?: CoreQueryBlock;
  CoreQueryNoResultsBlock?: CoreQueryNoResultsBlock;
  CoreQueryPaginationBlock?: CoreQueryPaginationBlock;
  CoreQueryPaginationNextBlock?: CoreQueryPaginationNextBlock;
  CoreQueryPaginationNumbersBlock?: CoreQueryPaginationNumbersBlock;
  CoreQueryPaginationPreviousBlock?: CoreQueryPaginationPreviousBlock;
  CoreQueryTitleBlock?: CoreQueryTitleBlock;
  CoreQuoteBlock?: CoreQuoteBlock;
  CoreReadMoreBlock?: CoreReadMoreBlock;
  CoreRssBlock?: CoreRssBlock;
  CoreSearchBlock?: CoreSearchBlock;
  CoreSeparatorBlock?: CoreSeparatorBlock;
  CoreShortcodeBlock?: CoreShortcodeBlock;
  CoreSiteLogoBlock?: CoreSiteLogoBlock;
  CoreSiteTaglineBlock?: CoreSiteTaglineBlock;
  CoreSiteTitleBlock?: CoreSiteTitleBlock;
  CoreSocialLinkBlock?: CoreSocialLinkBlock;
  CoreSocialLinksBlock?: CoreSocialLinksBlock;
  CoreSpacerBlock?: CoreSpacerBlock;
  CoreTableBlock?: CoreTableBlock;
  CoreTagCloudBlock?: CoreTagCloudBlock;
  CoreTemplatePartBlock?: CoreTemplatePartBlock;
  CoreTermDescriptionBlock?: CoreTermDescriptionBlock;
  CoreTextColumnsBlock?: CoreTextColumnsBlock;
  CoreVerseBlock?: CoreVerseBlock;
  CoreVideoBlock?: CoreVideoBlock;
  CoreWidgetGroupBlock?: CoreWidgetGroupBlock;
  GenesisCustomBlocksHeroBlock?: GenesisCustomBlocksHeroBlock;
  GravityformsFormBlock?: GravityformsFormBlock;
  IdeaboxTocBlock?: IdeaboxTocBlock;
  YoastFaqBlock?: YoastFaqBlock;
  YoastHowToBlock?: YoastHowToBlock;
  YoastSeoBreadcrumbsBlock?: YoastSeoBreadcrumbsBlock;
}

export interface $BlockEditorContentNode {
  Job?: Job;
  Page?: Page;
  Post?: Post;
  ReusableBlock?: ReusableBlock;
  Service?: Service;
  Specialty?: Specialty;
  Team_member?: Team_member;
  Work?: Work;
}

export interface $Commenter {
  CommentAuthor?: CommentAuthor;
  User?: User;
}

export interface $ContentNode {
  BlockEditorPreview?: BlockEditorPreview;
  Job?: Job;
  MediaItem?: MediaItem;
  Page?: Page;
  Post?: Post;
  ReusableBlock?: ReusableBlock;
  Service?: Service;
  Specialty?: Specialty;
  Team_member?: Team_member;
  Work?: Work;
}

export interface $ContentRevisionUnion {
  Page?: Page;
  Post?: Post;
  ReusableBlock?: ReusableBlock;
}

export interface $ContentTemplate {
  DefaultTemplate?: DefaultTemplate;
  Template_About?: Template_About;
  Template_Blog?: Template_Blog;
  Template_Contact?: Template_Contact;
  Template_Home?: Template_Home;
  Template_Work?: Template_Work;
}

export interface $CoreAudioBlockAttributesUnion {
  CoreAudioBlockAttributes?: CoreAudioBlockAttributes;
  CoreAudioBlockDeprecatedV1Attributes?: CoreAudioBlockDeprecatedV1Attributes;
}

export interface $CoreButtonBlockAttributesUnion {
  CoreButtonBlockAttributes?: CoreButtonBlockAttributes;
  CoreButtonBlockDeprecatedV1Attributes?: CoreButtonBlockDeprecatedV1Attributes;
  CoreButtonBlockDeprecatedV2Attributes?: CoreButtonBlockDeprecatedV2Attributes;
  CoreButtonBlockDeprecatedV3Attributes?: CoreButtonBlockDeprecatedV3Attributes;
  CoreButtonBlockDeprecatedV4Attributes?: CoreButtonBlockDeprecatedV4Attributes;
  CoreButtonBlockDeprecatedV5Attributes?: CoreButtonBlockDeprecatedV5Attributes;
  CoreButtonBlockDeprecatedV6Attributes?: CoreButtonBlockDeprecatedV6Attributes;
  CoreButtonBlockDeprecatedV7Attributes?: CoreButtonBlockDeprecatedV7Attributes;
  CoreButtonBlockDeprecatedV8Attributes?: CoreButtonBlockDeprecatedV8Attributes;
  CoreButtonBlockDeprecatedV9Attributes?: CoreButtonBlockDeprecatedV9Attributes;
  CoreButtonBlockDeprecatedV10Attributes?: CoreButtonBlockDeprecatedV10Attributes;
  CoreButtonBlockDeprecatedV11Attributes?: CoreButtonBlockDeprecatedV11Attributes;
}

export interface $CoreButtonsBlockAttributesUnion {
  CoreButtonsBlockAttributes?: CoreButtonsBlockAttributes;
  CoreButtonsBlockDeprecatedV1Attributes?: CoreButtonsBlockDeprecatedV1Attributes;
  CoreButtonsBlockDeprecatedV2Attributes?: CoreButtonsBlockDeprecatedV2Attributes;
}

export interface $CoreColumnBlockAttributesUnion {
  CoreColumnBlockAttributes?: CoreColumnBlockAttributes;
  CoreColumnBlockDeprecatedV1Attributes?: CoreColumnBlockDeprecatedV1Attributes;
}

export interface $CoreColumnsBlockAttributesUnion {
  CoreColumnsBlockAttributes?: CoreColumnsBlockAttributes;
  CoreColumnsBlockDeprecatedV1Attributes?: CoreColumnsBlockDeprecatedV1Attributes;
  CoreColumnsBlockDeprecatedV2Attributes?: CoreColumnsBlockDeprecatedV2Attributes;
  CoreColumnsBlockDeprecatedV3Attributes?: CoreColumnsBlockDeprecatedV3Attributes;
}

export interface $CoreCommentAuthorNameBlockAttributesUnion {
  CoreCommentAuthorNameBlockAttributes?: CoreCommentAuthorNameBlockAttributes;
  CoreCommentAuthorNameBlockDeprecatedV1Attributes?: CoreCommentAuthorNameBlockDeprecatedV1Attributes;
}

export interface $CoreCommentDateBlockAttributesUnion {
  CoreCommentDateBlockAttributes?: CoreCommentDateBlockAttributes;
  CoreCommentDateBlockDeprecatedV1Attributes?: CoreCommentDateBlockDeprecatedV1Attributes;
}

export interface $CoreCommentsBlockAttributesUnion {
  CoreCommentsBlockAttributes?: CoreCommentsBlockAttributes;
  CoreCommentsBlockDeprecatedV1Attributes?: CoreCommentsBlockDeprecatedV1Attributes;
}

export interface $CoreCommentsTitleBlockAttributesUnion {
  CoreCommentsTitleBlockAttributes?: CoreCommentsTitleBlockAttributes;
  CoreCommentsTitleBlockDeprecatedV1Attributes?: CoreCommentsTitleBlockDeprecatedV1Attributes;
}

export interface $CoreCoverBlockAttributesUnion {
  CoreCoverBlockAttributes?: CoreCoverBlockAttributes;
  CoreCoverBlockDeprecatedV1Attributes?: CoreCoverBlockDeprecatedV1Attributes;
  CoreCoverBlockDeprecatedV2Attributes?: CoreCoverBlockDeprecatedV2Attributes;
  CoreCoverBlockDeprecatedV3Attributes?: CoreCoverBlockDeprecatedV3Attributes;
  CoreCoverBlockDeprecatedV4Attributes?: CoreCoverBlockDeprecatedV4Attributes;
  CoreCoverBlockDeprecatedV5Attributes?: CoreCoverBlockDeprecatedV5Attributes;
  CoreCoverBlockDeprecatedV6Attributes?: CoreCoverBlockDeprecatedV6Attributes;
  CoreCoverBlockDeprecatedV7Attributes?: CoreCoverBlockDeprecatedV7Attributes;
  CoreCoverBlockDeprecatedV8Attributes?: CoreCoverBlockDeprecatedV8Attributes;
  CoreCoverBlockDeprecatedV9Attributes?: CoreCoverBlockDeprecatedV9Attributes;
  CoreCoverBlockDeprecatedV10Attributes?: CoreCoverBlockDeprecatedV10Attributes;
  CoreCoverBlockDeprecatedV11Attributes?: CoreCoverBlockDeprecatedV11Attributes;
}

export interface $CoreEmbedBlockAttributesUnion {
  CoreEmbedBlockAttributes?: CoreEmbedBlockAttributes;
  CoreEmbedBlockDeprecatedV1Attributes?: CoreEmbedBlockDeprecatedV1Attributes;
  CoreEmbedBlockDeprecatedV2Attributes?: CoreEmbedBlockDeprecatedV2Attributes;
}

export interface $CoreFileBlockAttributesUnion {
  CoreFileBlockAttributes?: CoreFileBlockAttributes;
  CoreFileBlockDeprecatedV1Attributes?: CoreFileBlockDeprecatedV1Attributes;
  CoreFileBlockDeprecatedV2Attributes?: CoreFileBlockDeprecatedV2Attributes;
  CoreFileBlockDeprecatedV3Attributes?: CoreFileBlockDeprecatedV3Attributes;
}

export interface $CoreGalleryBlockAttributesUnion {
  CoreGalleryBlockAttributes?: CoreGalleryBlockAttributes;
  CoreGalleryBlockDeprecatedV1Attributes?: CoreGalleryBlockDeprecatedV1Attributes;
  CoreGalleryBlockDeprecatedV2Attributes?: CoreGalleryBlockDeprecatedV2Attributes;
  CoreGalleryBlockDeprecatedV3Attributes?: CoreGalleryBlockDeprecatedV3Attributes;
  CoreGalleryBlockDeprecatedV4Attributes?: CoreGalleryBlockDeprecatedV4Attributes;
  CoreGalleryBlockDeprecatedV5Attributes?: CoreGalleryBlockDeprecatedV5Attributes;
  CoreGalleryBlockDeprecatedV6Attributes?: CoreGalleryBlockDeprecatedV6Attributes;
  CoreGalleryBlockDeprecatedV7Attributes?: CoreGalleryBlockDeprecatedV7Attributes;
}

export interface $CoreGroupBlockAttributesUnion {
  CoreGroupBlockAttributes?: CoreGroupBlockAttributes;
  CoreGroupBlockDeprecatedV1Attributes?: CoreGroupBlockDeprecatedV1Attributes;
  CoreGroupBlockDeprecatedV2Attributes?: CoreGroupBlockDeprecatedV2Attributes;
  CoreGroupBlockDeprecatedV3Attributes?: CoreGroupBlockDeprecatedV3Attributes;
  CoreGroupBlockDeprecatedV4Attributes?: CoreGroupBlockDeprecatedV4Attributes;
  CoreGroupBlockDeprecatedV5Attributes?: CoreGroupBlockDeprecatedV5Attributes;
}

export interface $CoreHeadingBlockAttributesUnion {
  CoreHeadingBlockAttributes?: CoreHeadingBlockAttributes;
  CoreHeadingBlockDeprecatedV1Attributes?: CoreHeadingBlockDeprecatedV1Attributes;
  CoreHeadingBlockDeprecatedV2Attributes?: CoreHeadingBlockDeprecatedV2Attributes;
  CoreHeadingBlockDeprecatedV3Attributes?: CoreHeadingBlockDeprecatedV3Attributes;
  CoreHeadingBlockDeprecatedV4Attributes?: CoreHeadingBlockDeprecatedV4Attributes;
  CoreHeadingBlockDeprecatedV5Attributes?: CoreHeadingBlockDeprecatedV5Attributes;
}

export interface $CoreImageBlockAttributesUnion {
  CoreImageBlockAttributes?: CoreImageBlockAttributes;
  CoreImageBlockDeprecatedV1Attributes?: CoreImageBlockDeprecatedV1Attributes;
  CoreImageBlockDeprecatedV2Attributes?: CoreImageBlockDeprecatedV2Attributes;
  CoreImageBlockDeprecatedV3Attributes?: CoreImageBlockDeprecatedV3Attributes;
  CoreImageBlockDeprecatedV4Attributes?: CoreImageBlockDeprecatedV4Attributes;
  CoreImageBlockDeprecatedV5Attributes?: CoreImageBlockDeprecatedV5Attributes;
  CoreImageBlockDeprecatedV6Attributes?: CoreImageBlockDeprecatedV6Attributes;
}

export interface $CoreLatestPostsBlockAttributesUnion {
  CoreLatestPostsBlockAttributes?: CoreLatestPostsBlockAttributes;
  CoreLatestPostsBlockDeprecatedV1Attributes?: CoreLatestPostsBlockDeprecatedV1Attributes;
}

export interface $CoreListBlockAttributesUnion {
  CoreListBlockAttributes?: CoreListBlockAttributes;
  CoreListBlockDeprecatedV1Attributes?: CoreListBlockDeprecatedV1Attributes;
  CoreListBlockDeprecatedV2Attributes?: CoreListBlockDeprecatedV2Attributes;
}

export interface $CoreMediaTextBlockAttributesUnion {
  CoreMediaTextBlockAttributes?: CoreMediaTextBlockAttributes;
  CoreMediaTextBlockDeprecatedV1Attributes?: CoreMediaTextBlockDeprecatedV1Attributes;
  CoreMediaTextBlockDeprecatedV2Attributes?: CoreMediaTextBlockDeprecatedV2Attributes;
  CoreMediaTextBlockDeprecatedV3Attributes?: CoreMediaTextBlockDeprecatedV3Attributes;
  CoreMediaTextBlockDeprecatedV4Attributes?: CoreMediaTextBlockDeprecatedV4Attributes;
  CoreMediaTextBlockDeprecatedV5Attributes?: CoreMediaTextBlockDeprecatedV5Attributes;
  CoreMediaTextBlockDeprecatedV6Attributes?: CoreMediaTextBlockDeprecatedV6Attributes;
}

export interface $CoreNavigationBlockAttributesUnion {
  CoreNavigationBlockAttributes?: CoreNavigationBlockAttributes;
  CoreNavigationBlockDeprecatedV1Attributes?: CoreNavigationBlockDeprecatedV1Attributes;
  CoreNavigationBlockDeprecatedV2Attributes?: CoreNavigationBlockDeprecatedV2Attributes;
  CoreNavigationBlockDeprecatedV3Attributes?: CoreNavigationBlockDeprecatedV3Attributes;
  CoreNavigationBlockDeprecatedV4Attributes?: CoreNavigationBlockDeprecatedV4Attributes;
  CoreNavigationBlockDeprecatedV5Attributes?: CoreNavigationBlockDeprecatedV5Attributes;
  CoreNavigationBlockDeprecatedV6Attributes?: CoreNavigationBlockDeprecatedV6Attributes;
}

export interface $CoreNavigationLinkBlockAttributesUnion {
  CoreNavigationLinkBlockAttributes?: CoreNavigationLinkBlockAttributes;
  CoreNavigationLinkBlockDeprecatedV1Attributes?: CoreNavigationLinkBlockDeprecatedV1Attributes;
}

export interface $CoreParagraphBlockAttributesUnion {
  CoreParagraphBlockAttributes?: CoreParagraphBlockAttributes;
  CoreParagraphBlockDeprecatedV1Attributes?: CoreParagraphBlockDeprecatedV1Attributes;
  CoreParagraphBlockDeprecatedV2Attributes?: CoreParagraphBlockDeprecatedV2Attributes;
  CoreParagraphBlockDeprecatedV3Attributes?: CoreParagraphBlockDeprecatedV3Attributes;
  CoreParagraphBlockDeprecatedV4Attributes?: CoreParagraphBlockDeprecatedV4Attributes;
  CoreParagraphBlockDeprecatedV5Attributes?: CoreParagraphBlockDeprecatedV5Attributes;
  CoreParagraphBlockDeprecatedV6Attributes?: CoreParagraphBlockDeprecatedV6Attributes;
}

export interface $CorePostDateBlockAttributesUnion {
  CorePostDateBlockAttributes?: CorePostDateBlockAttributes;
  CorePostDateBlockDeprecatedV1Attributes?: CorePostDateBlockDeprecatedV1Attributes;
}

export interface $CorePostTitleBlockAttributesUnion {
  CorePostTitleBlockAttributes?: CorePostTitleBlockAttributes;
  CorePostTitleBlockDeprecatedV1Attributes?: CorePostTitleBlockDeprecatedV1Attributes;
}

export interface $CorePullquoteBlockAttributesUnion {
  CorePullquoteBlockAttributes?: CorePullquoteBlockAttributes;
  CorePullquoteBlockDeprecatedV1Attributes?: CorePullquoteBlockDeprecatedV1Attributes;
  CorePullquoteBlockDeprecatedV2Attributes?: CorePullquoteBlockDeprecatedV2Attributes;
  CorePullquoteBlockDeprecatedV3Attributes?: CorePullquoteBlockDeprecatedV3Attributes;
  CorePullquoteBlockDeprecatedV4Attributes?: CorePullquoteBlockDeprecatedV4Attributes;
  CorePullquoteBlockDeprecatedV5Attributes?: CorePullquoteBlockDeprecatedV5Attributes;
  CorePullquoteBlockDeprecatedV6Attributes?: CorePullquoteBlockDeprecatedV6Attributes;
}

export interface $CoreQueryBlockAttributesUnion {
  CoreQueryBlockAttributes?: CoreQueryBlockAttributes;
  CoreQueryBlockDeprecatedV1Attributes?: CoreQueryBlockDeprecatedV1Attributes;
  CoreQueryBlockDeprecatedV2Attributes?: CoreQueryBlockDeprecatedV2Attributes;
  CoreQueryBlockDeprecatedV3Attributes?: CoreQueryBlockDeprecatedV3Attributes;
  CoreQueryBlockDeprecatedV4Attributes?: CoreQueryBlockDeprecatedV4Attributes;
  CoreQueryBlockDeprecatedV5Attributes?: CoreQueryBlockDeprecatedV5Attributes;
}

export interface $CoreQueryPaginationBlockAttributesUnion {
  CoreQueryPaginationBlockAttributes?: CoreQueryPaginationBlockAttributes;
  CoreQueryPaginationBlockDeprecatedV1Attributes?: CoreQueryPaginationBlockDeprecatedV1Attributes;
}

export interface $CoreQueryTitleBlockAttributesUnion {
  CoreQueryTitleBlockAttributes?: CoreQueryTitleBlockAttributes;
  CoreQueryTitleBlockDeprecatedV1Attributes?: CoreQueryTitleBlockDeprecatedV1Attributes;
}

export interface $CoreQuoteBlockAttributesUnion {
  CoreQuoteBlockAttributes?: CoreQuoteBlockAttributes;
  CoreQuoteBlockDeprecatedV1Attributes?: CoreQuoteBlockDeprecatedV1Attributes;
  CoreQuoteBlockDeprecatedV2Attributes?: CoreQuoteBlockDeprecatedV2Attributes;
  CoreQuoteBlockDeprecatedV3Attributes?: CoreQuoteBlockDeprecatedV3Attributes;
  CoreQuoteBlockDeprecatedV4Attributes?: CoreQuoteBlockDeprecatedV4Attributes;
}

export interface $CoreSeparatorBlockAttributesUnion {
  CoreSeparatorBlockAttributes?: CoreSeparatorBlockAttributes;
  CoreSeparatorBlockDeprecatedV1Attributes?: CoreSeparatorBlockDeprecatedV1Attributes;
}

export interface $CoreSiteTaglineBlockAttributesUnion {
  CoreSiteTaglineBlockAttributes?: CoreSiteTaglineBlockAttributes;
  CoreSiteTaglineBlockDeprecatedV1Attributes?: CoreSiteTaglineBlockDeprecatedV1Attributes;
}

export interface $CoreSiteTitleBlockAttributesUnion {
  CoreSiteTitleBlockAttributes?: CoreSiteTitleBlockAttributes;
  CoreSiteTitleBlockDeprecatedV1Attributes?: CoreSiteTitleBlockDeprecatedV1Attributes;
}

export interface $CoreSocialLinksBlockAttributesUnion {
  CoreSocialLinksBlockAttributes?: CoreSocialLinksBlockAttributes;
  CoreSocialLinksBlockDeprecatedV1Attributes?: CoreSocialLinksBlockDeprecatedV1Attributes;
}

export interface $CoreSpacerBlockAttributesUnion {
  CoreSpacerBlockAttributes?: CoreSpacerBlockAttributes;
  CoreSpacerBlockDeprecatedV1Attributes?: CoreSpacerBlockDeprecatedV1Attributes;
}

export interface $CoreTableBlockAttributesUnion {
  CoreTableBlockAttributes?: CoreTableBlockAttributes;
  CoreTableBlockDeprecatedV1Attributes?: CoreTableBlockDeprecatedV1Attributes;
  CoreTableBlockDeprecatedV2Attributes?: CoreTableBlockDeprecatedV2Attributes;
  CoreTableBlockDeprecatedV3Attributes?: CoreTableBlockDeprecatedV3Attributes;
}

export interface $CoreVerseBlockAttributesUnion {
  CoreVerseBlockAttributes?: CoreVerseBlockAttributes;
  CoreVerseBlockDeprecatedV1Attributes?: CoreVerseBlockDeprecatedV1Attributes;
  CoreVerseBlockDeprecatedV2Attributes?: CoreVerseBlockDeprecatedV2Attributes;
}

export interface $CoreVideoBlockAttributesUnion {
  CoreVideoBlockAttributes?: CoreVideoBlockAttributes;
  CoreVideoBlockDeprecatedV1Attributes?: CoreVideoBlockDeprecatedV1Attributes;
}

export interface $CoreWidgetGroupBlockAttributesUnion {
  CoreWidgetGroupBlockAttributes?: CoreWidgetGroupBlockAttributes;
  CoreWidgetGroupBlockDeprecatedV1Attributes?: CoreWidgetGroupBlockDeprecatedV1Attributes;
}

export interface $DatabaseIdentifier {
  BlockEditorPreview?: BlockEditorPreview;
  Category?: Category;
  Comment?: Comment;
  GfForm?: GfForm;
  GfSubmittedEntry?: GfSubmittedEntry;
  Job?: Job;
  MediaItem?: MediaItem;
  Menu?: Menu;
  MenuItem?: MenuItem;
  Page?: Page;
  Post?: Post;
  PostFormat?: PostFormat;
  ReusableBlock?: ReusableBlock;
  Service?: Service;
  Specialty?: Specialty;
  Tag?: Tag;
  Team_member?: Team_member;
  User?: User;
  Work?: Work;
}

export interface $EnqueuedAsset {
  EnqueuedScript?: EnqueuedScript;
  EnqueuedStylesheet?: EnqueuedStylesheet;
}

export interface $FormField {
  AddressField?: AddressField;
  CaptchaField?: CaptchaField;
  CheckboxField?: CheckboxField;
  ConsentField?: ConsentField;
  DateField?: DateField;
  EmailField?: EmailField;
  FileUploadField?: FileUploadField;
  HiddenField?: HiddenField;
  HtmlField?: HtmlField;
  ListField?: ListField;
  MultiSelectField?: MultiSelectField;
  NameField?: NameField;
  NumberField?: NumberField;
  PageField?: PageField;
  PasswordField?: PasswordField;
  PhoneField?: PhoneField;
  PostCategoryCheckboxField?: PostCategoryCheckboxField;
  PostCategoryMultiSelectField?: PostCategoryMultiSelectField;
  PostCategoryRadioField?: PostCategoryRadioField;
  PostCategorySelectField?: PostCategorySelectField;
  PostContentField?: PostContentField;
  PostCustomCheckboxField?: PostCustomCheckboxField;
  PostCustomDateField?: PostCustomDateField;
  PostCustomEmailField?: PostCustomEmailField;
  PostCustomFileuploadField?: PostCustomFileuploadField;
  PostCustomHiddenField?: PostCustomHiddenField;
  PostCustomListField?: PostCustomListField;
  PostCustomMultiSelectField?: PostCustomMultiSelectField;
  PostCustomNumberField?: PostCustomNumberField;
  PostCustomPhoneField?: PostCustomPhoneField;
  PostCustomRadioField?: PostCustomRadioField;
  PostCustomSelectField?: PostCustomSelectField;
  PostCustomTextAreaField?: PostCustomTextAreaField;
  PostCustomTextField?: PostCustomTextField;
  PostCustomTimeField?: PostCustomTimeField;
  PostCustomWebsiteField?: PostCustomWebsiteField;
  PostExcerptField?: PostExcerptField;
  PostImageField?: PostImageField;
  PostTagsCheckboxField?: PostTagsCheckboxField;
  PostTagsMultiSelectField?: PostTagsMultiSelectField;
  PostTagsRadioField?: PostTagsRadioField;
  PostTagsSelectField?: PostTagsSelectField;
  PostTagsTextField?: PostTagsTextField;
  PostTitleField?: PostTitleField;
  RadioField?: RadioField;
  SectionField?: SectionField;
  SelectField?: SelectField;
  SubmitField?: SubmitField;
  TextAreaField?: TextAreaField;
  TextField?: TextField;
  TimeField?: TimeField;
  WebsiteField?: WebsiteField;
}

export interface $GfEntry {
  GfDraftEntry?: GfDraftEntry;
  GfSubmittedEntry?: GfSubmittedEntry;
}

export interface $HierarchicalContentNode {
  MediaItem?: MediaItem;
  Page?: Page;
}

export interface $HierarchicalTermNode {
  Category?: Category;
}

export interface $MenuItemLinkable {
  Category?: Category;
  Job?: Job;
  Page?: Page;
  Post?: Post;
  Service?: Service;
  Specialty?: Specialty;
  Tag?: Tag;
  Team_member?: Team_member;
  Work?: Work;
}

export interface $MenuItemObjectUnion {
  Category?: Category;
  Job?: Job;
  Page?: Page;
  Post?: Post;
  Service?: Service;
  Specialty?: Specialty;
  Tag?: Tag;
  Team_member?: Team_member;
  Work?: Work;
}

export interface $Node {
  BlockEditorPreview?: BlockEditorPreview;
  Category?: Category;
  Comment?: Comment;
  CommentAuthor?: CommentAuthor;
  ContentType?: ContentType;
  EnqueuedScript?: EnqueuedScript;
  EnqueuedStylesheet?: EnqueuedStylesheet;
  GfDraftEntry?: GfDraftEntry;
  GfForm?: GfForm;
  GfSubmittedEntry?: GfSubmittedEntry;
  Job?: Job;
  MediaItem?: MediaItem;
  Menu?: Menu;
  MenuItem?: MenuItem;
  Page?: Page;
  Plugin?: Plugin;
  Post?: Post;
  PostFormat?: PostFormat;
  ReusableBlock?: ReusableBlock;
  Service?: Service;
  Specialty?: Specialty;
  Tag?: Tag;
  Taxonomy?: Taxonomy;
  Team_member?: Team_member;
  Theme?: Theme;
  User?: User;
  UserRole?: UserRole;
  Work?: Work;
}

export interface $NodeWithAuthor {
  BlockEditorPreview?: BlockEditorPreview;
  MediaItem?: MediaItem;
  Page?: Page;
  Post?: Post;
}

export interface $NodeWithComments {
  MediaItem?: MediaItem;
  Page?: Page;
  Post?: Post;
}

export interface $NodeWithContentEditor {
  BlockEditorPreview?: BlockEditorPreview;
  Job?: Job;
  Page?: Page;
  Post?: Post;
  ReusableBlock?: ReusableBlock;
  Service?: Service;
  Specialty?: Specialty;
  Team_member?: Team_member;
  Work?: Work;
}

export interface $NodeWithExcerpt {
  Post?: Post;
}

export interface $NodeWithFeaturedImage {
  Page?: Page;
  Post?: Post;
  Service?: Service;
  Specialty?: Specialty;
  Team_member?: Team_member;
  Work?: Work;
}

export interface $NodeWithForm {
  GfDraftEntry?: GfDraftEntry;
  GfSubmittedEntry?: GfSubmittedEntry;
}

export interface $NodeWithPageAttributes {
  Page?: Page;
}

export interface $NodeWithRevisions {
  Page?: Page;
  Post?: Post;
  ReusableBlock?: ReusableBlock;
}

export interface $NodeWithTemplate {
  BlockEditorPreview?: BlockEditorPreview;
  Job?: Job;
  MediaItem?: MediaItem;
  Page?: Page;
  Post?: Post;
  ReusableBlock?: ReusableBlock;
  Service?: Service;
  Specialty?: Specialty;
  Team_member?: Team_member;
  Work?: Work;
}

export interface $NodeWithTitle {
  BlockEditorPreview?: BlockEditorPreview;
  Job?: Job;
  MediaItem?: MediaItem;
  Page?: Page;
  Post?: Post;
  ReusableBlock?: ReusableBlock;
  Service?: Service;
  Specialty?: Specialty;
  Team_member?: Team_member;
  Work?: Work;
}

export interface $NodeWithTrackbacks {
  Post?: Post;
}

export interface $PostCategoryField {
  PostCategoryCheckboxField?: PostCategoryCheckboxField;
  PostCategoryMultiSelectField?: PostCategoryMultiSelectField;
  PostCategoryRadioField?: PostCategoryRadioField;
  PostCategorySelectField?: PostCategorySelectField;
}

export interface $PostCustomField {
  PostCustomCheckboxField?: PostCustomCheckboxField;
  PostCustomDateField?: PostCustomDateField;
  PostCustomEmailField?: PostCustomEmailField;
  PostCustomFileuploadField?: PostCustomFileuploadField;
  PostCustomHiddenField?: PostCustomHiddenField;
  PostCustomListField?: PostCustomListField;
  PostCustomMultiSelectField?: PostCustomMultiSelectField;
  PostCustomNumberField?: PostCustomNumberField;
  PostCustomPhoneField?: PostCustomPhoneField;
  PostCustomRadioField?: PostCustomRadioField;
  PostCustomSelectField?: PostCustomSelectField;
  PostCustomTextAreaField?: PostCustomTextAreaField;
  PostCustomTextField?: PostCustomTextField;
  PostCustomTimeField?: PostCustomTimeField;
  PostCustomWebsiteField?: PostCustomWebsiteField;
}

export interface $PostObjectUnion {
  BlockEditorPreview?: BlockEditorPreview;
  Job?: Job;
  MediaItem?: MediaItem;
  Page?: Page;
  Post?: Post;
  ReusableBlock?: ReusableBlock;
  Service?: Service;
  Specialty?: Specialty;
  Team_member?: Team_member;
  Work?: Work;
}

export interface $PostTagsField {
  PostTagsCheckboxField?: PostTagsCheckboxField;
  PostTagsMultiSelectField?: PostTagsMultiSelectField;
  PostTagsRadioField?: PostTagsRadioField;
  PostTagsSelectField?: PostTagsSelectField;
  PostTagsTextField?: PostTagsTextField;
}

export interface $TermNode {
  Category?: Category;
  PostFormat?: PostFormat;
  Tag?: Tag;
}

export interface $UniformResourceIdentifiable {
  BlockEditorPreview?: BlockEditorPreview;
  Category?: Category;
  ContentType?: ContentType;
  Job?: Job;
  MediaItem?: MediaItem;
  Page?: Page;
  Post?: Post;
  PostFormat?: PostFormat;
  ReusableBlock?: ReusableBlock;
  Service?: Service;
  Specialty?: Specialty;
  Tag?: Tag;
  Team_member?: Team_member;
  User?: User;
  Work?: Work;
}

export interface $YoastFaqBlockAttributesUnion {
  YoastFaqBlockAttributes?: YoastFaqBlockAttributes;
  YoastFaqBlockDeprecatedV1Attributes?: YoastFaqBlockDeprecatedV1Attributes;
}

export interface $YoastHowToBlockAttributesUnion {
  YoastHowToBlockAttributes?: YoastHowToBlockAttributes;
  YoastHowToBlockDeprecatedV1Attributes?: YoastHowToBlockDeprecatedV1Attributes;
  YoastHowToBlockDeprecatedV2Attributes?: YoastHowToBlockDeprecatedV2Attributes;
}

export interface GeneratedSchema {
  query: Query;
  mutation: Mutation;
  subscription: Subscription;
}

export type MakeNullable<T> = {
  [K in keyof T]: T[K] | undefined;
};

export interface ScalarsEnums extends MakeNullable<Scalars> {
  AddressFieldCountryEnum: AddressFieldCountryEnum | undefined;
  AddressFieldTypeEnum: AddressFieldTypeEnum | undefined;
  AmPmEnum: AmPmEnum | undefined;
  AvatarRatingEnum: AvatarRatingEnum | undefined;
  BlockEditorPreviewIdType: BlockEditorPreviewIdType | undefined;
  CaptchaFieldBadgePositionEnum: CaptchaFieldBadgePositionEnum | undefined;
  CaptchaFieldThemeEnum: CaptchaFieldThemeEnum | undefined;
  CaptchaFieldTypeEnum: CaptchaFieldTypeEnum | undefined;
  CategoryIdType: CategoryIdType | undefined;
  CommentsConnectionOrderbyEnum: CommentsConnectionOrderbyEnum | undefined;
  ConditionalLogicActionTypeEnum: ConditionalLogicActionTypeEnum | undefined;
  ConditionalLogicLogicTypeEnum: ConditionalLogicLogicTypeEnum | undefined;
  ContentNodeIdTypeEnum: ContentNodeIdTypeEnum | undefined;
  ContentTypeEnum: ContentTypeEnum | undefined;
  ContentTypeIdTypeEnum: ContentTypeIdTypeEnum | undefined;
  ContentTypesOfCategoryEnum: ContentTypesOfCategoryEnum | undefined;
  ContentTypesOfPostFormatEnum: ContentTypesOfPostFormatEnum | undefined;
  ContentTypesOfTagEnum: ContentTypesOfTagEnum | undefined;
  DateFieldFormatEnum: DateFieldFormatEnum | undefined;
  DateFieldTypeEnum: DateFieldTypeEnum | undefined;
  DraftEntryIdTypeEnum: DraftEntryIdTypeEnum | undefined;
  EntryIdTypeEnum: EntryIdTypeEnum | undefined;
  EntryStatusEnum: EntryStatusEnum | undefined;
  EntryTypeEnum: EntryTypeEnum | undefined;
  FieldFiltersModeEnum: FieldFiltersModeEnum | undefined;
  FieldFiltersOperatorEnum: FieldFiltersOperatorEnum | undefined;
  FormButtonTypeEnum: FormButtonTypeEnum | undefined;
  FormConfirmationTypeEnum: FormConfirmationTypeEnum | undefined;
  FormDescriptionPlacementEnum: FormDescriptionPlacementEnum | undefined;
  FormFieldCalendarIconTypeEnum: FormFieldCalendarIconTypeEnum | undefined;
  FormFieldDescriptionPlacementEnum:
    | FormFieldDescriptionPlacementEnum
    | undefined;
  FormFieldLabelPlacementEnum: FormFieldLabelPlacementEnum | undefined;
  FormFieldRequiredIndicatorEnum: FormFieldRequiredIndicatorEnum | undefined;
  FormFieldSizeEnum: FormFieldSizeEnum | undefined;
  FormFieldSubLabelPlacementEnum: FormFieldSubLabelPlacementEnum | undefined;
  FormFieldTypeEnum: FormFieldTypeEnum | undefined;
  FormFieldVisibilityEnum: FormFieldVisibilityEnum | undefined;
  FormIdTypeEnum: FormIdTypeEnum | undefined;
  FormLabelPlacementEnum: FormLabelPlacementEnum | undefined;
  FormLimitEntriesPeriodEnum: FormLimitEntriesPeriodEnum | undefined;
  FormNotificationToTypeEnum: FormNotificationToTypeEnum | undefined;
  FormPageProgressStyleEnum: FormPageProgressStyleEnum | undefined;
  FormPageProgressTypeEnum: FormPageProgressTypeEnum | undefined;
  FormRetentionPolicyEnum: FormRetentionPolicyEnum | undefined;
  FormRuleOperatorEnum: FormRuleOperatorEnum | undefined;
  FormStatusEnum: FormStatusEnum | undefined;
  FormSubLabelPlacementEnum: FormSubLabelPlacementEnum | undefined;
  FormSubmitButtonLocationEnum: FormSubmitButtonLocationEnum | undefined;
  FormSubmitButtonWidthEnum: FormSubmitButtonWidthEnum | undefined;
  GfCurrencyEnum: GfCurrencyEnum | undefined;
  JobIdType: JobIdType | undefined;
  MediaItemIdType: MediaItemIdType | undefined;
  MediaItemSizeEnum: MediaItemSizeEnum | undefined;
  MediaItemStatusEnum: MediaItemStatusEnum | undefined;
  MenuItemNodeIdTypeEnum: MenuItemNodeIdTypeEnum | undefined;
  MenuLocationEnum: MenuLocationEnum | undefined;
  MenuNodeIdTypeEnum: MenuNodeIdTypeEnum | undefined;
  MimeTypeEnum: MimeTypeEnum | undefined;
  NumberFieldFormatEnum: NumberFieldFormatEnum | undefined;
  OrderEnum: OrderEnum | undefined;
  PageIdType: PageIdType | undefined;
  PasswordFieldMinStrengthEnum: PasswordFieldMinStrengthEnum | undefined;
  PhoneFieldFormatEnum: PhoneFieldFormatEnum | undefined;
  PluginStatusEnum: PluginStatusEnum | undefined;
  PostFormatIdType: PostFormatIdType | undefined;
  PostFormatTypeEnum: PostFormatTypeEnum | undefined;
  PostIdType: PostIdType | undefined;
  PostObjectFieldFormatEnum: PostObjectFieldFormatEnum | undefined;
  PostObjectsConnectionDateColumnEnum:
    | PostObjectsConnectionDateColumnEnum
    | undefined;
  PostObjectsConnectionOrderbyEnum:
    | PostObjectsConnectionOrderbyEnum
    | undefined;
  PostStatusEnum: PostStatusEnum | undefined;
  RelationEnum: RelationEnum | undefined;
  ReusableBlockIdType: ReusableBlockIdType | undefined;
  SEOCardType: SEOCardType | undefined;
  ServiceIdType: ServiceIdType | undefined;
  SpecialtyIdType: SpecialtyIdType | undefined;
  SubmittedEntryIdTypeEnum: SubmittedEntryIdTypeEnum | undefined;
  TagIdType: TagIdType | undefined;
  TaxonomyEnum: TaxonomyEnum | undefined;
  TaxonomyIdTypeEnum: TaxonomyIdTypeEnum | undefined;
  Team_memberIdType: Team_memberIdType | undefined;
  TermNodeIdTypeEnum: TermNodeIdTypeEnum | undefined;
  TermObjectsConnectionOrderbyEnum:
    | TermObjectsConnectionOrderbyEnum
    | undefined;
  TimeFieldFormatEnum: TimeFieldFormatEnum | undefined;
  UserNodeIdTypeEnum: UserNodeIdTypeEnum | undefined;
  UserRoleEnum: UserRoleEnum | undefined;
  UsersConnectionOrderbyEnum: UsersConnectionOrderbyEnum | undefined;
  UsersConnectionSearchColumnEnum: UsersConnectionSearchColumnEnum | undefined;
  WorkIdType: WorkIdType | undefined;
}
